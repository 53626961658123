import React, {
	Component,
	Fragment
} from 'react';

//import { Route }	from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	login,
	loginSuccess,
	loginFailure,
	logout,
	logoutSuccess,
	logoutFailure
}
from '../store/modules/userAuthentication';

import {
	getUserFavorite,
	getUserFavoriteSuccess,
	getUserFavoriteFailure,
	addUserFavorite,
	addUserFavoriteSuccess,
	addUserFavoriteFailure,
	removeUserFavorite,
	removeUserFavoriteSuccess,
	removeUserFavoriteFailure,
} from '../store/modules/userFavoriteManager';

import {
//	increasePageDepth,
//	decreasePageDepth,
	setPageDepth,
} from '../store/modules/currentPageSync';

import axios from 'axios';
import queryString from 'query-string';

import {
	isMobileOnly,
	isTablet,
	isIPad13,
} from 'react-device-detect';

import clsx from 'clsx';

import Gesture from 'rc-gesture';

import {
	
	Container,
	//IconButton,
	Slide,
	//useScrollTrigger,
	
} from '@material-ui/core';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
	Favorite		as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
	MoreVert		as MoreVertIcon,
} from '@material-ui/icons';

import CustomAlert		from '../components/Utility/Alert/CustomAlert.js';

import IPickPageContainer	from "../components/Utility/IPickPage/IPickPageContainer.js";

import MarkerInfoHeader	from '../components/Marker/MarkerInfoHeader.js';
import MarkerInfoBody	from '../components/Marker/MarkerInfoBody.js';
import MarkerInfoFooter	from '../components/Marker/MarkerInfoFooter.js';


import {
	getFavorite,
} from '../components/User/IPickUser.js';


class Marker extends Component {
	
	constructor( props ) {
		
		super( props );
		
		//console.log( this.props.history );
		//console.log( this.props.location );
		//console.log( this.props.match );
		
		const query	= queryString.parse( this.props.location.search );
		
		//console.log( query.type );
		//console.log( query.id_ );
		
		let type	= undefined;
		let id_		= undefined;
		let latitude	= undefined;
		let longitude	= undefined;
		let addressDepth1	= undefined;
		let addressDepth2	= undefined;
		
		if (
			undefined	!== query.type	&&
			undefined	!== query.id_
		) {
			type	= query.type;
			id_		= query.id_;
			latitude	= query.latitude;
			longitude	= query.longitude;
			addressDepth1	= query.addressDepth1;
			addressDepth2	= query.addressDepth2;
		}
		
		this.state	= {
			type	: type,
			id_		: id_,
			latitude	: latitude,
			longitude	: longitude,
			addressDepth1	: addressDepth1,
			addressDepth2	: addressDepth2,
			titleViewChangeYN:false,
			markerData:null,
			favoriteYN: false,
			
			
			userInfo:{
				loginYN	:this.props.userInfo.loginYN,
				userId	:this.props.userInfo.userId,
				userEmail:this.props.userInfo.userEmail,
				userNickname:this.props.userInfo.userNickname,
				friendShareLink:this.props.userInfo.friendShareLink,
				nicknameConfirmYN:this.props.userInfo.nicknameConfirmYN,
			},
			favoriteList:[],
			
			//pageDepth:this.props.currentPage.depth,
			
			errMsg:"",
			errMsgType:"warning",
		};
		
		
		this.getMarkerInfoDetail	= this.getMarkerInfoDetail.bind(this);
		this.createMarkerData		= this.createMarkerData.bind(this);
		this.getMarkerInfo			= this.getMarkerInfo.bind(this);	// 내부 오류메시지 출력 수정 필요
		this.getDetailDataUlzip		= this.getDetailDataUlzip.bind(this);
		this.getDetailDataKinder	= this.getDetailDataKinder.bind(this);
		this.getKinderSummaryInfo	= this.getKinderSummaryInfo.bind(this);
		this.getKinderAuthInfo		= this.getKinderAuthInfo.bind(this);
		this.getPeopleDetailInfo	= this.getPeopleDetailInfo.bind(this);
		this.getUlzipCurriculum		= this.getUlzipCurriculum.bind(this);
		this.getKinderCostInfo		= this.getKinderCostInfo.bind(this);
		
		this.getUserFavorite		= this.getUserFavorite.bind(this);
		this.checkFavoriteYN		= this.checkFavoriteYN.bind(this);
		
		this.closeMarkerInfo		= this.closeMarkerInfo.bind(this);
		this.changeTitleView		= this.changeTitleView.bind(this);
		
		this.clickFavoriteBtn		= this.clickFavoriteBtn.bind(this);
		this.addFavorite			= this.addFavorite.bind(this);
		this.removeFavorite			= this.removeFavorite.bind(this);
		
		this.closeAlert				= this.closeAlert.bind(this);
		this.createErrorMsg			= this.createErrorMsg.bind(this);
		
	}
	
	
	componentDidMount( ) {
		
		this.getMarkerInfo();
		this.checkFavoriteYN();
		
		//console.log( this.props.currentPage );
		
		if ( 
			1			!==	this.props.currentPage.depth	&&
			"PAGE_HOME"	===	this.props.currentPage.data
		) {
			this.props.actions.setPageDepth(
				{
					data	: this.props.currentPage.data,
					depth	: 1,
				}
			);
		}
		
	}
	
	
	componentDidUpdate( prevProps, prevState ) {
		
		const query	= queryString.parse( this.props.location.search );
		
		this.checkFavoriteYN();
		
		if (
			query.type	!==	this.state.type		||
			query.id_	!== this.state.id_
		) {
			this.setState({
				type			: query.type,
				id_				: query.id_,
				latitude		: query.latitude,
				longitude		: query.longitude,
				addressDepth1	: query.addressDepth1,
				addressDepth2	: query.addressDepth2,
				titleViewChangeYN	: false,
				markerData			: null,
				favoriteYN			: false,
			});
		} 
		
		if (
			prevState.type	!== this.state.type	||
			prevState.id_	!== this.state.id_
		) {
			this.getMarkerInfo();
			
			if ( 
				1			!==	this.props.currentPage.depth	&&
				"PAGE_HOME"	===	this.props.currentPage.data
			) {
				this.props.actions.setPageDepth(
					{
						data	: this.props.currentPage.data,
						depth	: 1,
					}
				);
			}
		}
		
	}
	
	
	componentWillUnmount ( ) { 
		
		
		
	}
	
	
	static getDerivedStateFromProps( nextProps, prevState ) {
		
		if ( 
			prevState.userInfo.loginYN				!== nextProps.userInfo.loginYN		||
			prevState.userInfo.userId				!== nextProps.userInfo.userId		||
			prevState.userInfo.userEmail			!== nextProps.userInfo.userEmail	||
			prevState.userInfo.userNickname			!== nextProps.userInfo.userNickname	||
			prevState.userInfo.nicknameConfirmYN	!== nextProps.userInfo.nicknameConfirmYN
		) {
			return {
				userInfo: {
					//...userInfo,
					loginYN: nextProps.userInfo.loginYN,
					userId: nextProps.userInfo.userId,
					userEmail: nextProps.userInfo.userEmail,
					userNickname: nextProps.userInfo.userNickname,
					friendShareLink: nextProps.userInfo.friendShareLink,
					nicknameConfirmYN : nextProps.userInfo.nicknameConfirmYN,
				}
			};
		}
		
		if ( prevState.favoriteList !== nextProps.favoriteData.favoriteList ) {
			return {
				favoriteList : nextProps.favoriteData.favoriteList
			};
		}
		
		//if ( prevState.pageDepth !== nextProps.currentPage.depth ) {
		//	return {
		//		pageDepth : nextProps.currentPage.depth
		//	};
		//}
		
		return null;
		
	}
	
	
	async getMarkerInfo ( ) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		
		try {
			
			await this.getMarkerInfoDetail();	// await 삭제하면 안돼!
			
			if ( 'ulzip' === this.state.type ) {
				
				this.getDetailDataUlzip();
				this.getUlzipCurriculum();
				
			} else if ('kinder' === this.state.type) {
				
				this.getDetailDataKinder();
				this.getKinderCostInfo();
				
			} else if( 'kids_cafe' === this.state.type ) {
				//throw new Error('getDetailDataFailed');
			} else {
				
			}
			
			this.getPeopleDetailInfo();
			
		} catch ( err ) {
			
			console.log( err );
			
			if( 'ulzip' === this.state.type ) {
				errMsg	= "어린이집 상세 데이터 로드 오류";
			} else if( 'kinder' === this.state.type ) {
				errMsg	= "유치원 상세 데이터 로드 오류";
			} else if( 'kids_cafe' === this.state.type ) {
				errMsg	= "키즈카페 상세 데이터 로드 오류";
			} else {
				
			}
			
			errMsgType	= "error";
			
		} finally {
			
			if ( "" !== errMsg ) {
				this.createErrorMsg( 
					errMsg,
					errMsgType
				);
			}
			
			//console.log( this.state.markerData );
			
		}
		
		
	}
	
	
	async getMarkerInfoDetail ( ) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		
		let commonInfoData	= null;
		
		try {
			
			const commonInfo	= await axios.get(
				"/main/api/getCommonInfo/?type=" + this.state.type + 
				"&code=" + this.state.id_
			);
			
			//console.log( commonInfo.data.data );
			/*
			// 유치원
_id: "60641f5b7906c1cfd25ed9d9"
addr: "서울특별시 마포구 대흥로24바길 27"
badgpstest: false
clcnt3: "1"
clcnt4: "1"
clcnt5: "1"
code: "eb8cde61-5718-4f14-ab59-7df432e254ee"
crstatusname: "정상"
edate: "20141230"
establish: "공립(병설)"
gpsmerge: "37.55_126.95"
gpsupdated: false
hpaddr: "http://www.hanseo.es.kr"
id: "eb8cde61-5718-4f14-ab59-7df432e254ee"
key: "27"
kindercode: "eb8cde61-5718-4f14-ab59-7df432e254ee"
kindername: "서울한서초등학교병설유치원"
la: "37.553648607331"
ldgrname: "김경래"
lo: "126.949779139532"
mixclcnt: "0"
mixppcnt: "0"
name: "서울한서초등학교병설유치원"
odate: "20150301"
officeedu: "서울특별시교육청"
opertime: "09시00분~20시00분"
parsedAddr: "서울특별시 마포구 대흥로24바길 27"
pbnttmng: "20202"
ppcnt3: "11"
ppcnt4: "22"
ppcnt5: "22"
rppnname: "김경래"
search_target: "서울한서초등학교병설유치원 서울특별시 마포구 대흥로24바길 27 서울한서초등학교병설유치원"
sggcode: "11440"
shclcnt: null
shppcnt: null
subofficeedu: "서부교육지원청"
telno: "02-717-0954"
			*/
			
			if (
				null		!== commonInfo.data	&&
				undefined	!== commonInfo.data	&&
				null		!== commonInfo.data.data	&&
				undefined	!== commonInfo.data.data	&&
				"success"	=== commonInfo.data.retcode
			) {
				//console.log( "success" );
				commonInfoData	= commonInfo.data.data;
			} else {
				//msg	= "마커 공통정보 로드 중 오류가 발생했습니다.";
				errMsg		= commonInfo.data.msg;
				errMsgType	= "error";
			}
			
			
			
		} catch ( err ) {
			console.log( err );
			errMsg		= "마커 공통정보 로드 중 오류가 발생했습니다.";
			errMsgType	= "error";
		} finally {
			
			let markerData	= null;
			
			if ( "" !== errMsg ) {
				this.createErrorMsg ( 
					errMsg,
					errMsgType
				);
			} else {
				
				markerData	= this.createMarkerData( this.state.type, commonInfoData );
				
				//console.log( markerData );
				
				this.setState({
					markerData : markerData
				});
				
			}
			
			return markerData;
		}
		
	}
	
	
	createMarkerData ( type, data ) {
		
		let retObj	= null;
		
		
		if ( "ulzip" === type ) {
			
			retObj	= {
				
				"dataType"	: type,
				"name"		: data.crname,
				"kind"		: data.crtypename,
				"address"	: data.craddr,
				"addressDepth1"	: this.state.addressDepth1,
				"addressDepth2"	: this.state.addressDepth2,
				"id_"		: data.stcode,
				"homepage"	: data.crhome,
				"status"	: data.crstatusname,
				"phone"		: data.crtelno,
				"student"	: undefined,
				"teacher"	: undefined,
				"quota"		: data.crcapat,
				"numOfStudents"	: data.crchcnt,
				"numOfTeachers"	: 0,
				"ratioTeachersToStudents"	: 0,
				"opertime"	: "",
				"extraService"	: 
					(
						undefined	!== data.crspec	&&
						null		!== data.crspec	&&
						""			!== data.crspec
					) ?
						data.crspec : "정보 없음",
				"schoolCarYN"	:
					(
						'undefined'	!== typeof data.crcargbname	&&
						'undefined'	!== data.crcargbname		&&
						null		!== data.crcargbname
					) ? 
						( data.crcargbname ) : ( '정보 없음' ),
				
				"detailInfo"	: {
					"numOfPlayground"	: data.plgrdco,
					"numOfStaffs"		: data.chcrtescnt,
					"areaOfSchool"		: data.nrtrroomsize,
					"numOfClassroom"	: data.nrtrroomcnt,
					"dateOfAccreditation"	: data.crcnfmdt,
					"abolitionDate"				: data.crabldt,
					"closedDurationStartDate"	: data.crpausebegindt,
					"closedDurationEndDate"		: data.crpauseenddt,
					"fax"		: data.crfaxno,
					"zipCode"	: data.zipcode,
					
				}
				
			};
			
		} else if ( "kinder" === type ) {
			
			retObj	= {
				
				"dataType"	: type,
				"name"		: data.kindername,
				"kind"		: data.establish,
				"address"	: data.addr,
				"addressDepth1"	: this.state.addressDepth1,
				"addressDepth2"	: this.state.addressDepth2,
				"id_"		: data.id,
				"homepage"	: data.hpaddr,
				"status"	: data.crstatusname,
				"phone"		: data.telno,
				"student"	: undefined,
				"teacher"	: undefined,
				"quota"		: 0,
				"numOfStudents"	: 0,
				"numOfTeachers"	: 0,
				"ratioTeachersToStudents"	: 0,
				"opertime"	: data.opertime,
				"extraService"	: "정보 없음",
				"schoolCarYN"	: '',
				
				"detailInfo"	: {
					"clcnt3"	: data.clcnt3, // 만3세학급수
					"clcnt4"	: data.clcnt4, // 만4세학급수
					"clcnt5"	: data.clcnt5, // 만5세학급수
					"mixclcnt"	: data.mixclcnt, // 혼합학급수
					"shclcnt"	: data.shclcnt, // 특수학급수
					"ppcnt3"	: data.ppcnt3, // 만3세유아수
					"ppcnt4"	: data.ppcnt4, // 만4세유아수
					"ppcnt5"	: data.ppcnt5, // 만5세유아수
					"mixppcnt"	: data.mixppcnt, // 혼합유아수
					"shppcnt"	: data.shppcnt, // 특수유아수
				}
				
			};
			
		} else if ( "kids_cafe" === type ) {
			
		} else {
			
		}
		
		//console.log( retObj );
		
		return retObj;
		
	}
	
	
	async getDetailDataUlzip() {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		
		let ulzipDetailInfo	= null;
		
		if ( 
			"ulzip"		!== this.state.type	||
			undefined	=== this.state.id_	||
			null		=== this.state.id_	||
			""			=== this.state.id_
		) {
			return;
		}
		
		try {
			const ulzip_detail_info = await axios.get(
				'/api/getAuthInfo?stcode=' + this.state.id_
			);
			
			//console.log( ulzip_detail_info.data );
			
			ulzipDetailInfo	= ulzip_detail_info;
			
		} catch (e) {
			console.log(e);
			errMsg		= "어린이집 상세정보 로드 오류";
			errMsgType	= "error";
			//throw new Error('getDetailDataUlzipFailed');
		} finally {
			
			if ( "" !== errMsg ) {
				
				let homepage	= "";
				
				if (
					null		!== this.state.markerData			&&
					undefined	!== this.state.markerData			&&
					null		!== this.state.markerData.homepage	&&
					undefined	!== this.state.markerData.homepage	&&
					"undefined"	!== this.state.markerData.homepage	&&
					""			!== this.state.markerData.homepage
				) {
					let tmp_homepage_address = this.state.markerData.homepage;
					
					if ( -1 === tmp_homepage_address.indexOf( 'http' ) ) {
						tmp_homepage_address = 'http://' + tmp_homepage_address;
						homepage	= tmp_homepage_address;
					} else {
						homepage	= tmp_homepage_address;
					}
				}
				
				this.setState({
					markerData	: {
						...this.state.markerData,
						homepage: homepage,
						opertime: '정보 없음',
						detailInfo:{
							...this.state.markerData.detailInfo,
							authHistory: null,
							authInfo: null,
							authUrl: '',
							scoreInfo: null,
							
						}
					}
				});
				
				this.createErrorMsg(
					errMsg,
					errMsgType
				);
				
			} else {
				
				let openClose	= "";
				let homepage	= "";
				
				if (
					null		!== ulzipDetailInfo.data[0].summary[8][1]			&&
					'undefined'	!== typeof ulzipDetailInfo.data[0].summary[8][1]	&&
					""			!==  ulzipDetailInfo.data[0].summary[8][1]
				) {
					openClose	= ulzipDetailInfo.data[0].summary[8][1].replace('평일기준시간 : ', '') + ' (평일기준)' ;
				} else {
					openClose = '정보 없음';
				}
				
				if (
					null		!== ulzipDetailInfo.data[0].summary[7][1]			&&
					'undefined'	!== typeof ulzipDetailInfo.data[0].summary[7][1]	&&
					0			<	ulzipDetailInfo.data[0].summary[7][1].length
				) {
					let tmp_homepage_address = ulzipDetailInfo.data[0].summary[7][1];
					
					if ( -1 === tmp_homepage_address.indexOf( 'http' ) ) {
						tmp_homepage_address = 'http://' + tmp_homepage_address;
						homepage	= tmp_homepage_address;
					} else {
						homepage	= tmp_homepage_address;
					}
				}
				
				
				this.setState({
					markerData	: {
						...this.state.markerData,
						homepage: homepage,
						opertime:openClose,
						detailInfo:{
							...this.state.markerData.detailInfo,
							authHistory: ulzipDetailInfo.data[1].auth_history,
							authInfo: ulzipDetailInfo.data[1].auth_info,
							authUrl: ulzipDetailInfo.data[1].auth_url,
							scoreInfo: ulzipDetailInfo.data[1].score_info,
							
						}
					}
				});
			}
			
			//console.log( this.state.markerData );
			
		}
	}

    
    // ss 수정 230719
// 	async getDetailDataUlzip() {
		
// 		let errMsg		= "";
// 		let errMsgType	= "warning";
		
// 		let ulzipDetailInfo	= null;
		
// 		if ( 
// 			"ulzip"		!== this.state.type	||
// 			undefined	=== this.state.id_	||
// 			null		=== this.state.id_	||
// 			""			=== this.state.id_
// 		) {
// 			return;
// 		}
		
// 		try {
// 			const ulzip_detail_info = await axios.get(
// 				'/api/getAuthInfo?stcode=' + this.state.id_
// 			);
			
// 			//console.log( ulzip_detail_info.data );
			
// 			ulzipDetailInfo	= ulzip_detail_info;
			
// 		} catch (e) {
// 			console.log(e);
// 			errMsg		= "어린이집 상세정보 로드 오류";
// 			errMsgType	= "error";
// 			//throw new Error('getDetailDataUlzipFailed');
// 		} finally {
			
// 			if ( "" !== errMsg ) {
				
// 				let homepage	= "";
				
// 				if (
// 					null		!== this.state.markerData			&&
// 					undefined	!== this.state.markerData			&&
// 					null		!== this.state.markerData.homepage	&&
// 					undefined	!== this.state.markerData.homepage	&&
// 					"undefined"	!== this.state.markerData.homepage	&&
// 					""			!== this.state.markerData.homepage
// 				) {
// 					let tmp_homepage_address = this.state.markerData.homepage;
					
// 					if ( -1 === tmp_homepage_address.indexOf( 'http' ) ) {
// 						tmp_homepage_address = 'http://' + tmp_homepage_address;
// 						homepage	= tmp_homepage_address;
// 					} else {
// 						homepage	= tmp_homepage_address;
// 					}
// 				}
				
// 				this.setState({
// 					markerData	: {
// 						...this.state.markerData,
// 						homepage: homepage,
// 						opertime: '정보 없음',
// 						detailInfo:{
// 							...this.state.markerData.detailInfo,
// 							authHistory: null,
// 							authInfo: null,
// 							authUrl: '',
// 							scoreInfo: null,
							
// 						}
// 					}
// 				});
				
// 				this.createErrorMsg(
// 					errMsg,
// 					errMsgType
// 				);
				
// 			} else {
				
// 				let openClose	= "";
// 				let homepage	= "";
				
// 				if (
// 					null		!== ulzipDetailInfo.data[0].summary[1][8]			&&
// 					'undefined'	!== typeof ulzipDetailInfo.data[0].summary[1][8]	&&
// 					""			!==  ulzipDetailInfo.data[0].summary[1][8]
// 				) {
// 					openClose	= ulzipDetailInfo.data[0].summary[1][8].replace('평일기준시간 : ', '') + ' (평일기준)' ;
// 				} else {
// 					openClose = '정보 없음';
// 				}
				
// 				if (
// 					null		!== ulzipDetailInfo.data[0].summary[1][7]			&&
// 					'undefined'	!== typeof ulzipDetailInfo.data[0].summary[1][7]	&&
// 					0			<	ulzipDetailInfo.data[0].summary[1][7].length
// 				) {
// 					let tmp_homepage_address = ulzipDetailInfo.data[0].summary[1][7];
					
// 					if ( -1 === tmp_homepage_address.indexOf( 'http' ) ) {
// 						tmp_homepage_address = 'http://' + tmp_homepage_address;
// 						homepage	= tmp_homepage_address;
// 					} else {
// 						homepage	= tmp_homepage_address;
// 					}
// 				}
				
				
// 				this.setState({
// 					markerData	: {
// 						...this.state.markerData,
// 						homepage: homepage,
// 						opertime:openClose,
// 						detailInfo:{
// 							...this.state.markerData.detailInfo,
// 							authHistory: ulzipDetailInfo.data[1].auth_history,
// 							authInfo: ulzipDetailInfo.data[1].auth_info,
// 							authUrl: ulzipDetailInfo.data[1].auth_url,
// 							scoreInfo: ulzipDetailInfo.data[1].score_info,
							
// 						}
// 					}
// 				});
// 			}
			
// 			//console.log( this.state.markerData );
			
// 		}
// 	}

	
	getDetailDataKinder ( ) {
		
		try {
			this.getKinderSummaryInfo();
			this.getKinderAuthInfo();
		} catch ( err ) {
			console.log( err );
		}
		
	}
	
	
	async getKinderSummaryInfo ( ) {
		
		let kinderSummaryInfo	= null;
		let errMsg		= "";
		let errMsgType	= "warning";
		
		try {
			const kinder_summary_info = await axios.get(
				'/api/getKinderSummaryInfo?ittId=' + this.state.id_
			);
			
			//console.log( kinder_summary_info.data );
			// 21.4.3 summary type 
			//{
			//	iitId : ""
			//	, summary : {
			//		addr		// homepage url
			//		, ceoname		// 사장이름
			//		, crspec  --> array 추가제공서비스
			//		, edate
			//		, establish
			//		, gwanhaloffice // 관할오피스?
			//		, kindername
			//		, odate			
			//		, tonghakcar
			//		, wonjangname // 원장이름
			//	}
			//}
			//
			// tonghakcar ???? where???
			
			
			if ( 
				'undefined'	!== typeof kinder_summary_info.data			&&
				null		!== kinder_summary_info.data				&&
				'undefined'	!== typeof kinder_summary_info.data.summary	&&
				null		!== kinder_summary_info.data.summary
			) {
				
				kinderSummaryInfo	= kinder_summary_info.data.summary;
				
			} else {
				errMsg		= "유치원 요약정보 로드 오류";
				errMsgType	= "warning";
			}
			
		} catch (e) {
			console.log(e);
			errMsg	= "유치원 요약정보 로드 오류";
			errMsgType	= "error";
			//throw new Error('getKinderSummaryInfoFailed');
		} finally {
			
			if ( "" !== errMsg ) {
				
				this.createErrorMsg(
					errMsg,
					errMsgType
				);
				
				this.setState({
					
					markerData	: {
						...this.state.markerData,
						schoolCarYN	: "정보 없음",
						detailInfo	: {
							...this.state.markerData.detailInfo,
							"summary"	: null
						}
					}
					
				});
				
			} else {
				
				if (
					'undefined'	!==	typeof	kinderSummaryInfo.crspec	&&
					null		!==			kinderSummaryInfo.crspec	&&
					''			!==			kinderSummaryInfo.crspec
				) {
					this.setState({
						markerData	: {
							...this.state.markerData,
							schoolCarYN		: kinderSummaryInfo.tonghakcar,
							extraService	:kinderSummaryInfo.crspec, 
							detailInfo	: {
								...this.state.markerData.detailInfo,
								"summary"	: kinderSummaryInfo
							}
						}
					});
				} else {
					this.setState({
						markerData	: {
							...this.state.markerData,
							schoolCarYN		: kinderSummaryInfo.tonghakcar,
							detailInfo	: {
								...this.state.markerData.detailInfo,
								"summary"	: kinderSummaryInfo
							}
						}
					});
				}
				
				
			}
			
		}
	}

	async getKinderAuthInfo () {
		
		let kinderAuthInfo	= null;
		let errMsg		= "";
		let errMsgType	= "warning";
		
		try {
			const kinder_auth_info = await axios.get(
				'/api/getKinderAuthInfo?ittId=' + this.state.id_
			);
			
			//console.log( kinder_auth_info.data );
			
			//// 21.4.3 Auth info
			//iitId
			//, authArray : [
			//	{
			//		authDate
			//		, authYn
			//		, period
			//	}
			//]
			//, authDetailFileArray : [
			//	{
			//		confirmPeople
			//		, fileNm
			//		, fileSize
			//		, regDate
			//		, regPeople
			//		, seq
			//		, url
			//	}
			//]
			//, violationArray : [
			//	{
			//		
			//	}
			//]
			
			kinderAuthInfo	= kinder_auth_info.data;
			
		} catch (e) {
			console.log(e);
			
			errMsg		= "유치원 인증정보 로드 오류"
			errMsgType	= "error";
			//throw new Error('getKinderAuthInfoFailed');
		} finally {
			
			let auth_info_kinder	= '미인증';
			let authUrl				= "";
			
			if ( "" !== errMsg ) {
				
				this.createErrorMsg(
					errMsg,
					errMsgType
				);
				
				this.setState({
					
					markerData	: {
						...this.state.markerData,
						detailInfo	: {
							...this.state.markerData.detailInfo,
							"authInfo"		: auth_info_kinder,
							"authInfoList"	: null,
							"authInfoFile"	: null,
							"authUrl"		: "",
							"violationInfo"	: null,
						}
					}
					
				});
				
			} else {
				
				if (
					//null		!==	kinderAuthInfo.data.authArray			&&
					//'undefined'	!==	typeof kinderAuthInfo.data.authArray	&&
					//0			<	kinderAuthInfo.data.authArray.length
					null		!==	kinderAuthInfo.authArray			&&
					'undefined'	!==	typeof kinderAuthInfo.authArray	&&
					0			<	kinderAuthInfo.authArray.length
				) {
					//const tmp_auth_info_kinder_list = kinderAuthInfo.data.authArray.filter(
					const tmp_auth_info_kinder_list = kinderAuthInfo.authArray.filter(
						data => true === data.authYn
					);
					
					auth_info_kinder =
						tmp_auth_info_kinder_list[tmp_auth_info_kinder_list.length - 1].period;
				}
				
				
				if (
					//null		!==	kinderAuthInfo.data.authDetailFileArray &&
					//undefined	!==	kinderAuthInfo.data.authDetailFileArray &&
					//0			<	kinderAuthInfo.data.authDetailFileArray.length
					null		!==	kinderAuthInfo.authDetailFileArray &&
					'undefined'	!==	typeof kinderAuthInfo.authDetailFileArray &&
					0			<	kinderAuthInfo.authDetailFileArray.length
				) {
					let target_idx = 0;
					
					if ( 
						//1 < kinderAuthInfo.data.authDetailFileArray.length 
						1 < kinderAuthInfo.authDetailFileArray.length 
					) {
						let max_value = Number(
							//kinderAuthInfo.data.authDetailFileArray[0].regDate.replace(/[^0-9]/g, '')
							kinderAuthInfo.authDetailFileArray[0].regDate.replace(/[^0-9]/g, '')
						);
						
						
						//for ( let i = 1 ; i < kinderAuthInfo.data.authDetailFileArray.length ; i++ ) {
						for ( let i = 1 ; i < kinderAuthInfo.authDetailFileArray.length ; i++ ) {
							if (
								max_value <
								Number(
									//kinderAuthInfo.data.authDetailFileArray[i].regDate.replace(
									kinderAuthInfo.authDetailFileArray[i].regDate.replace(
										/[^0-9]/g,
										''
									)
								)
							) {
								max_value = Number(
									//kinderAuthInfo.data.authDetailFileArray[i].regDate.replace(
									kinderAuthInfo.authDetailFileArray[i].regDate.replace(
										/[^0-9]/g,
										''
									)
								);
								target_idx = i;
							}
						}
					}
					
					//authUrl	= kinderAuthInfo.data.authDetailFileArray[target_idx].url;
					authUrl	= kinderAuthInfo.authDetailFileArray[target_idx].url;
				}
				
				//console.log( kinderAuthInfo.authDetailFileArray );
				//console.log( authUrl );
				
				this.setState({
					
					markerData	: {
						...this.state.markerData,
						detailInfo	: {
							...this.state.markerData.detailInfo,
							"authInfo"		: auth_info_kinder,
							//"authInfoList"	: kinderAuthInfo.data.authArray,
							"authInfoList"	: kinderAuthInfo.authArray,
							//"authInfoFile"	: kinderAuthInfo.data.authDetailFileArray,
							"authInfoFile"	: kinderAuthInfo.authDetailFileArray,
							"authUrl"		: authUrl,
							//"violationInfo"	: kinderAuthInfo.data.violationArray,
							"violationInfo"	: kinderAuthInfo.violationArray,
						}
					}
					
				});
				
			}
			
		}
	}
	
	
	async getUlzipCurriculum() {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		let result	= null;
		
		try {
			const ulzipCurriculum = await axios.get(
				'/api/getUlzipCurriculum?stcode=' + this.state.id_
			);
			
			result	= ulzipCurriculum.data;
			
			//console.log( result );
			
		} catch (err) {
			console.log(err);
			errMsg		= '어린이집 교과과정 로드 중 오류가 발생했습니다.';
			errMsgType	= "error";
		} finally {
			
			if ( '' !== errMsg ) {
				this.createErrorMsg(
					errMsg,
					errMsgType
				);
				//throw new Error('getUlzipCurriculumFailed');
				this.setState({
					
					markerData	: {
						...this.state.markerData,
						detailInfo	: {
							...this.state.markerData.detailInfo,
							curriculum: null
						}
					}
					
				});
			} else {
				this.setState({
					
					markerData	: {
						...this.state.markerData,
						detailInfo	: {
							...this.state.markerData.detailInfo,
							curriculum: result
						}
					}
					
				});
			}
			
		}
		
	}

	async getKinderCostInfo() {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		let result	= null;
		
		try {
			const kinderCostInfo = await axios.get(
				'/api/getKinderCostInfo?ittId=' + this.state.id_
			);
			
			//console.log(kinderCostInfo.data);
			result	= kinderCostInfo.data;
			
			
		} catch (err) {
			console.log(err);
			errMsg		= '유치원 비용정보 로드 중 오류가 발생했습니다.';
			errMsgType	= "error";
		} finally {
			if ( '' !== errMsg ) {
				this.createErrorMsg(
					errMsg,
					errMsgType
				);
				//throw new Error('getKinderCostInfoFailed');
				this.setState({
					
					markerData	: {
						...this.state.markerData,
						detailInfo	: {
							...this.state.markerData.detailInfo,
							costInfo	: null
						}
					}
					
				});
			} else {
				this.setState({
					
					markerData	: {
						...this.state.markerData,
						detailInfo	: {
							...this.state.markerData.detailInfo,
							costInfo	: result
						}
					}
					
				});
			}
		}
	}
	
	
	getPeopleDetailInfo() {
		
		try {
			if ( 'ulzip' === this.state.type ) {
				this.getPeopleDetailInfoForUlzip();
			} else if ( 'kinder' === this.state.type ) {
				this.getPeopleDetailInfoForKinder();
			} else if ( 'kids_cafe' === this.state.type ) {
				//
			} else {
				////throw new Error('getPeopleDetailInfoFailed');
			}
		} catch ( err ) {
			console.log( err );
		}
		
	}

	async getPeopleDetailInfoForUlzip() {
		
		let result	= null;
		let errMsg		= "";
		let errMsgType	= "warning";
		
		try {
			const ulzip_people_info = await axios.get(
				'/api/getPeopleDetailInfoByStcode?stcode=' + this.state.id_
			);
			
			//console.log( ulzip_people_info.data );
			
			result	= ulzip_people_info.data;
			
		} catch (e) {
			console.log(e);
			errMsg		= "어린이집 인원정보 로드 오류";
			errMsgType	= "error";
			//throw new Error('getPeopleDetailInfoForUlzipFailed');
		} finally {
			
			if ( "" !== errMsg ) {
				
				this.createErrorMsg(
					errMsg,
					errMsgType
				)
				
				this.setState({
					
					markerData:{
						...this.state.markerData,
						student: null,
						teacher: null,
					}
					
				});
				
			} else {
				
				let num_of_students	= 0;
				let num_of_teachers	= 0;
				let quota			= 0;
				let ratio_teachers_to_students = 0;
				
				//if (
				//	null !== result.teacher && null !== result.student
				//) {
				//	
				//	for ( let i = 1 ; i < result.teacher.length ; i++ ) {
				//		num_of_teachers = num_of_teachers + result.teacher[i][1];
				//	}
				//	
				//	for (let i = 1; i < result.student.length; i++) {
				//		num_of_students = num_of_students + result.student[i][1];
				//		quota = quota + result.student[i][4];
				//	}
				//	
				//	quota = quota + num_of_students;
				//	
				//	if (
				//		Number( num_of_teachers ) <= 0 ||
				//		//null		=== this.props.data.info.num_of_students ||
				//		//'undefined'	=== typeof this.props.data.info.num_of_students
				//		num_of_students <= 0
				//	) {
				//		ratio_teachers_to_students = '-';
				//	} else {
				//		//ratio_teachers_to_students = (Number(this.props.data.info.num_of_students) /
				//		//Number(num_of_teachers)).toFixed(1);
				//		ratio_teachers_to_students = (Number(num_of_students) /
				//			Number(num_of_teachers)).toFixed(1);
				//	}
				//}
				
				//console.log( result.teacher );
				//console.log( result.student );
			
				// `22.2.9 이용준
				// 아래 2개 null 할당 코드를 주석처리 하면 차트가 나옵니다.
				// 앞으로 이부분에서 null을 0으로 바꾸어주는 코드를 추가하려고 합니다.
				// result.teacher	= null;
				// result.student	= null;
				
				if (
					null !== result.teacher  
				) {
					
					for ( let i = 1 ; i < result.teacher.length ; i++ ) {
						num_of_teachers = num_of_teachers + result.teacher[i][1];
					}
				}
				
				if (
					null !== result.student
				) {
					for (let i = 1; i < result.student.length; i++) {
						num_of_students = num_of_students + result.student[i][1];
						quota = quota + result.student[i][4];
					}
				}
				
				if (
					null !== result.teacher && null !== result.student
				) {	
					quota = quota + num_of_students;
					
					if (
						Number( num_of_teachers ) <= 0 ||
						//null		=== this.props.data.info.num_of_students ||
						//'undefined'	=== typeof this.props.data.info.num_of_students
						num_of_students <= 0
					) {
						ratio_teachers_to_students = '-';
					} else {
						//ratio_teachers_to_students = (Number(this.props.data.info.num_of_students) /
						//Number(num_of_teachers)).toFixed(1);
						ratio_teachers_to_students = (Number(num_of_students) /
							Number(num_of_teachers)).toFixed(1);
					}
				}
				
				
				this.setState({
					
					markerData:{
						...this.state.markerData,
						student: result.student,
						teacher: result.teacher,
						numOfStudents: num_of_students,
						numOfTeachers: num_of_teachers,
						ratioTeachersToStudents: ratio_teachers_to_students,
						quota:quota
					}
					
				});
				
			}
			
		}
	}

	
	async getPeopleDetailInfoForKinder() {
		
		let result	= null;
		let errMsg		= "";
		let errMsgType	= "warning";
		
		
		try {
			const kinder_people_info = await axios.get(
				'/api/getKinderPeopleDetailInfoByStcode?ittId=' + this.state.id_
			);
			
			//console.log( kinder_people_info );
			
			result	= kinder_people_info.data;
			
		} catch (e) {
			console.log(e);
			errMsg		= "유치원 인원정보 로드 오류";
			errMsgType	= "error";
			//throw new Error('getPeopleDetailInfoForKinderFailed');
		} finally {
			
			if ( "" !== errMsg ) {
				this.createErrorMsg(
					errMsg,
					errMsgType
				);
				
				this.setState({
					
					markerData:{
						...this.state.markerData,
						student: null,
						teacher: null,
					}
					
				});
			} else {
				
				let num_of_students	= 0;
				let num_of_teachers	= 0;
				let quota			= 0;
				let ratio_teachers_to_students = 0;
				
				if (
					null		!== result.teacher && 
					undefined	!== result.teacher &&
					null		!== result.student &&
					undefined	!== result.student
				) {
					
					for ( let i = 1 ; i < result.teacher.length ; i++ ) {
						num_of_teachers = num_of_teachers + result.teacher[i][1];
					}
					
					for ( let i = 1 ; i < result.student.length ; i++ ) {
						num_of_students = num_of_students + result.student[i][1];
						quota = quota + result.student[i][4];
					}
					
					quota = quota + num_of_students;
					
					if (
						Number( num_of_teachers ) <= 0 ||
						//null		=== this.props.data.info.num_of_students ||
						//'undefined'	=== typeof this.props.data.info.num_of_students
						num_of_students <= 0
					) {
						ratio_teachers_to_students = '-';
					} else {
						//ratio_teachers_to_students = (Number(this.props.data.info.num_of_students) /
						//Number(num_of_teachers)).toFixed(1);
						ratio_teachers_to_students = (Number(num_of_students) /
							Number(num_of_teachers)).toFixed(1);
					}
				}
				
				this.setState({
					
					markerData:{
						...this.state.markerData,
						student: result.student,
						teacher: result.teacher,
						numOfStudents: num_of_students,
						numOfTeachers: num_of_teachers,
						ratioTeachersToStudents: ratio_teachers_to_students,
						quota:quota
					}
					
				});
			}
			
		}
	}
	
	
	async getUserFavorite ( ) {
		
		let favoriteList	= [];
		let errorMsg		= "";
		let errorMsgType	= "warning";
		
		
		if ( false === this.state.userInfo.loginYN ) {
			return;
		}
		
		
		try {
			
			this.props.actions.getUserFavorite();
			
			const getFavoriteResult	= await getFavorite();
			
			if ( null === getFavoriteResult.data ) {
				errorMsg		= getFavoriteResult.msg;
				errorMsgType	= "warning";
			} else {
				favoriteList	= getFavoriteResult.data
			}
			
			//console.log( getFavoriteResult.data );
			
		} catch ( err ) {
			
			console.log( err );
			errorMsg		= "즐겨찾기 중에 오류가 발생했습니다.";
			errorMsgType	= "error";
			
		} finally {
			
			if ( "" === errorMsg ) {
				this.props.actions.getUserFavoriteSuccess( favoriteList );
			} else {
				
				this.createErrorMsg(
					errorMsg,
					errorMsgType
				);
				
				this.props.actions.getUserFavoriteFailure();
				
			}
			
		}
		
	}
	
	
	checkFavoriteYN ( ) {
		
		let favoriteYN = false;
		
		//console.log( this.state.userInfo.loginYN );
		//console.log( this.state.favoriteList );
		//console.log( favoriteList );
		
		if ( true === this.state.userInfo.loginYN ) {
			let favoriteMarker = null;
			
			favoriteMarker = this.state.favoriteList.filter(
				data => ( data.id_ === this.state.id_ )
			);
			
			
			if ( 0 < favoriteMarker.length ) {
				favoriteYN = true;
			} else {
				favoriteYN = false;
			}
			
			if ( favoriteYN !== this.state.favoriteYN ) {
				this.setState({
					favoriteYN: favoriteYN
				});
			}
			
		} else {
			
			if ( false !== this.state.favoriteYN ) {
				this.setState({
					favoriteYN: favoriteYN
				});
			}
			
		}
		
		
	}
	
	
	clickFavoriteBtn ( e ) {
		
		if ( false === this.state.userInfo.loginYN ) {
			this.createErrorMsg(
				"로그인이 필요한 기능입니다.",
				"warning"
			);
			return;
		}
		
		
		if ( 
			true === this.state.favoriteYN && 
			true === this.state.userInfo.loginYN 
		) {
			this.removeFavorite();
		} else if (
			false === this.state.favoriteYN && 
			true === this.state.userInfo.loginYN 
		) {
			this.addFavorite();
		} else {
			// do nothing...
		}
	}
	
	
	async addFavorite() {
		if ( false === this.state.userInfo.loginYN ) {
			this.createErrorMsg(
				'로그인이 필요한 기능입니다.',
				"warning"
			);
			return;
		}
		
		let favoriteYN = this.state.favoriteYN;
		let errMsg		= "";
		let errMsgType	= "warning";
		
		try {
			this.props.actions.addUserFavorite();
			
			const addFavoriteResult = await axios.post(
				'/user/addFavorite', {
				type: this.state.type,
				code: this.state.id_,
				addr_1: this.state.addressDepth1,
				addr_2: this.state.addressDepth2
			});
			
			//console.log( addFavoriteResult.data );
			
			if ( 'success' === addFavoriteResult.data.result ) {
				favoriteYN = true;
				errMsg		= addFavoriteResult.data.msg;
				errMsgType	= "success";
				
				this.props.actions.addUserFavoriteSuccess();
				this.getUserFavorite();
				
			} else {
				errMsg = addFavoriteResult.data.msg;
				errMsgType	= "warning";
				this.props.actions.addUserFavoriteFailure();
			}
		} catch ( err ) {
			console.log(err);
			errMsg		= '즐겨찾기 추가 도중 오류가 발생했습니다.';
			errMsgType	= "error";
			this.props.actions.addUserFavoriteFailure();
		} finally {
			
			if ( "" !== errMsg ) {
				this.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			//this.setState({
			//	favoriteYN: favoriteYN
			//});
			
			//this.checkFavoriteYN();
		}
	}

	
	async removeFavorite() {
		
		if ( false === this.state.userInfo.loginYN ) {
			this.createErrorMsg(
				'로그인이 필요한 기능입니다.',
				"warning"
			);
			return;
		}
		
		let favoriteYN = this.state.favoriteYN;
		let errMsg		= "";
		let errMsgType	= "warning";
		
		try {
			
			this.props.actions.removeUserFavorite();
			
			const removeFavoriteResult = await axios.post(
				'/user/removeFavorite', {
				type: this.state.type,
				code: this.state.id_
			});
			
			//console.log( removeFavoriteResult.data );
			
			if ( 'success' === removeFavoriteResult.data.result ) {
				favoriteYN = false;
				errMsg		= removeFavoriteResult.data.msg;
				errMsgType	= "success";
				
				const tempFavoriteList	= this.state.favoriteList.filter(
					data => ( this.state.id_ !== data.id_ )
				);
				
				this.props.actions.removeUserFavoriteSuccess( tempFavoriteList );
			} else {
				errMsg = removeFavoriteResult.data.msg;
				errMsgType	= "warning";
				this.props.actions.removeUserFavoriteFailure();
			}
		} catch ( err ) {
			console.log(err);
			errMsg		= '즐겨찾기 삭제 도중 오류가 발생했습니다.';
			errMsgType	= "error";
			this.props.actions.removeUserFavoriteFailure();
		} finally {
			if ( "" !== errMsg ) {
				this.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			//this.setState({
			//	favoriteYN: favoriteYN
			//});
			
			//this.checkFavoriteYN();
		}
		
	}
	
	
	closeMarkerInfo (e) {
		
		if (
			true	=== isMobileOnly	||
			true	===	isTablet		||
			true	===	isIPad13
		) {
			this.props.history.goBack();
		} else {
			this.props.history.replace("/")
		}
		
		//this.props.history.goBack();
		
	}
	
	
	changeTitleView ( bool ) {
		if (
			bool !== this.state.titleViewChangeYN
		) {
			this.setState({
				titleViewChangeYN: bool
			});
		}
	}
	
	
	/**
	 *	에러 메시지 닫기 버튼 클릭 시 호출되는 메소드...
	 */
	closeAlert() {
		this.setState({
			errMsg: ''
		});
	}

	/**
	 *	에러 메시지를 띠우는 메소드...
	 */
	createErrorMsg(
		errorMsg,
		errorMsgType
	) {
		this.setState({
			errMsg:errorMsg,
			errMsgType:errorMsgType,
		});
		
		//setTimeout(this.closeAlert, 3000);
	}
	
	
	render ( ) {
		
		//console.log( this.state.markerData );
		//console.log( this.props.currentPage );
		
		const { markerData }	= this.state;
		let markerName	= "";
		
		if ( 
			null		!== markerData	&&
			undefined	!== markerData	&&
			""			!== markerData.name
		) {
			markerName	= markerData.name;
		}
		
		
		return (
			<Fragment>
				<Slide 
					direction={
						( isMobileOnly ) ?
							"left" : "right"
					}
					
					in={true}
					
					//style={{ transitionDelay: '100ms' }}
				>
					<Gesture
						onSwipeRight={
							( gestureStatus ) => {
								if ( true === isMobileOnly ) {
									if ( 1 === this.props.currentPage.depth ) {
										this.props.history.goBack();	
									}
								}
							}
						}
						
						onSwipeLeft={
							( gestureStatus ) => {
								if ( true !== isMobileOnly ) {
									if ( 1 === this.props.currentPage.depth ) {
										this.props.history.goBack();
									}
								}
							}
						}
						
						direction="horizontal"
					>
						<Container
							fixed={true}
							maxWidth="xs"
							
							style={
								(
									true	=== isMobileOnly	||
									true	===	isTablet		||
									true	===	isIPad13
								) ?
									(
										{
											//height:"calc( 100vh - 3.25rem )",
											height:"calc( 100vh - 3rem )",
											margin:0,
											padding:0,
											
											
											display:"flex",
											flexDirection:"column",
											overflow:"hidden",
											//zIndex:2,
										}
									) :
									(
										{
											position:"fixed",
											top:"0",
											left:"3.75rem",
											height:"100vh",
											margin:0,
											padding:0,
											
											
											display:"flex",
											flexDirection:"column",
											overflow:"hidden",
											//zIndex:2,
										}
									)
							}
						>
							<CustomAlert
								open={this.state.errMsg.length > 0}
								data={this.state.errMsg} 
								option={this.state.errMsgType}
								closeAlert={this.closeAlert} 
							/>
							
							<IPickPageContainer
								maxWidth="xs"
								zIndex={1}
								type={this.state.type}
								id={this.state.id_}
								data={this.state.markerData}
								pageTitle={markerName}
								others={undefined}
								
								closePage={this.closeMarkerInfo}
								createErrorMsg={this.createErrorMsg}
								
								buttonList={
									[
										{
											type		: this.state.type,
											buttonType	: "binary",
											dataType	: "binary",
											
											data		: this.state.favoriteYN,
											function	: [
												{
													funName		: "clickFavoriteBtn",
													function	: this.clickFavoriteBtn
												}
											],
											
											icon		: [
												{
													type 	: "binary",
													value	: true,
													icon	: (
														<FavoriteIcon 
															
														/> 
													),
												},
												{
													type 	: "binary",
													value	: false,
													icon	: (
														<FavoriteBorderIcon 
															
														/> 
													),
												},
											],
										},
									]
									
									// 홈페이지 링크까지 할 때는 아래 주석으로 해야하니 지우지 말기!!!
									
									//(  
									//	null		!==	this.state.markerData						&&
									//	undefined	!==	this.state.markerData						&&
									//	null		!==	this.state.markerData						&&
									//	undefined	!==	this.state.markerData.homepage				&&
									//	null		!==	this.state.markerData.homepage				&&
									//	""			!==	this.state.markerData.homepage
									//	//null		!==	this.state.markerData.detailInfo			&&
									//	//undefined	!==	this.state.markerData.detailInfo			&&
									//	//null		!==	this.state.markerData.detailInfo.homepage	&&
									//	//undefined	!==	this.state.markerData.detailInfo.homepage	&&
									//	//""			!==	this.state.markerData.detailInfo.homepage
									//) ?
									//	(
									//		[
									//			{
									//				type		: this.state.type,
									//				buttonType	: "binary",
									//				dataType	: "binary",
									//				
									//				data		: this.state.favoriteYN,
									//				function	: [
									//					{
									//						funName		: "clickFavoriteBtn",
									//						function	: this.clickFavoriteBtn
									//					}
									//				],
									//				
									//				icon		: [
									//					{
									//						type 	: "binary",
									//						value	: true,
									//						icon	: (
									//							<FavoriteIcon 
									//								
									//							/> 
									//						),
									//					},
									//					{
									//						type 	: "binary",
									//						value	: false,
									//						icon	: (
									//							<FavoriteBorderIcon 
									//								
									//							/> 
									//						),
									//					},
									//				],
									//			},
									//			{
									//				type		: this.state.type,
									//				buttonType	: "link",
									//				dataType	: "link",
									//				
									//				data		: 
									//					(  
									//						null		!==	this.state.markerData						&&
									//						undefined	!==	this.state.markerData						&&
									//						undefined	!==	this.state.markerData.homepage				&&
									//						null		!==	this.state.markerData.homepage				&&
									//						""			!==	this.state.markerData.homepage
									//						//null		!==	this.state.markerData.detailInfo			&&
									//						//undefined	!==	this.state.markerData.detailInfo			&&
									//						//null		!==	this.state.markerData.detailInfo.homepage	&&
									//						//undefined	!==	this.state.markerData.detailInfo.homepage	&&
									//						//""			!==	this.state.markerData.detailInfo.homepage
									//					) ? 
									//						//this.state.markerData.detailInfo.homepage : "",
									//						this.state.markerData.homepage : "",
									//				function	: null,
									//				
									//				icon		: [
									//					{
									//						type 	: "link",
									//						value	: (  
									//								null		!==	this.state.markerData						&&
									//						undefined	!==	this.state.markerData						&&
									//						undefined	!==	this.state.markerData.homepage				&&
									//						null		!==	this.state.markerData.homepage				&&
									//						""			!==	this.state.markerData.homepage
									//						//null		!==	this.state.markerData.detailInfo			&&
									//						//undefined	!==	this.state.markerData.detailInfo			&&
									//						//null		!==	this.state.markerData.detailInfo.homepage	&&
									//						//undefined	!==	this.state.markerData.detailInfo.homepage	&&
									//						//""			!==	this.state.markerData.detailInfo.homepage
									//							) ? 
									//							//this.state.markerData.detailInfo.homepage : "",
									//							this.state.markerData.homepage : "",
									//						icon	: (
									//							<Icon
									//								className={
									//									clsx(
									//										"fas fa-home",
									//									)
									//								}
									//								
									//								fontSize="small"
									//								
									//								style={{
									//									display: 'flex',
									//									alignItems: 'center',
									//									justifyContent: 'center',
									//									//margin:1
									//								}}
									//							/>
									//						),
									//					},
									//				],
									//			},
									//		]
									//	) :
									//	(
									//		[
									//			{
									//				type		: this.state.type,
									//				buttonType	: "binary",
									//				dataType	: "binary",
									//				
									//				data		: this.state.favoriteYN,
									//				function	: [
									//					{
									//						funName		: "clickFavoriteBtn",
									//						function	: this.clickFavoriteBtn
									//					}
									//				],
									//				
									//				icon		: [
									//					{
									//						type 	: "binary",
									//						value	: true,
									//						icon	: (
									//							<FavoriteIcon 
									//								
									//							/> 
									//						),
									//					},
									//					{
									//						type 	: "binary",
									//						value	: false,
									//						icon	: (
									//							<FavoriteBorderIcon 
									//								
									//							/> 
									//						),
									//					},
									//				],
									//			},
									//		]
									//	)
								}
								
								
								pageHeader={
									<MarkerInfoHeader
										type={this.state.type}
										data={this.state.markerData}
									/>
								}
								pageBody={
									<MarkerInfoBody
										type={this.state.type}
										data={this.state.markerData}
										id={this.state.id_}
										createErrorMsg={this.createErrorMsg}
									/>
								}
								pageFooter={
									<MarkerInfoFooter 
										
									/>
								}
							/>
							
						</Container>
					</Gesture>
				</Slide>
			</Fragment>
		);
	}
	
}


// props 로 넣어줄 스토어 상태값
function mapStateToProps ( state ) {
	return {
		userInfo	: state.userAuthentication.userInfo,
		favoriteData: state.userFavoriteManager.favoriteData,
		currentPage	: state.currentPageSync.currentPage
	};
}

// props 로 넣어줄 액션 생성함수
function mapDispatchToProps( dispatch ) {
	return {
		actions: {
			login: bindActionCreators( login, dispatch ),
			loginSuccess: bindActionCreators( userInfo => ( loginSuccess( userInfo ) ), dispatch ),
			loginFailure: bindActionCreators( loginFailure, dispatch ),
			logout: bindActionCreators( logout, dispatch ),
			logoutSuccess: bindActionCreators( logoutSuccess, dispatch ),
			logoutFailure: bindActionCreators( logoutFailure, dispatch ),
			
			getUserFavorite				: bindActionCreators( getUserFavorite, dispatch ),
			getUserFavoriteSuccess		: bindActionCreators( favoriteData => ( getUserFavoriteSuccess( favoriteData ) ), dispatch ),
			getUserFavoriteFailure		: bindActionCreators( getUserFavoriteFailure, dispatch ),
			addUserFavorite				: bindActionCreators( addUserFavorite, dispatch ),
			//addUserFavoriteSuccess		: bindActionCreators( favoriteData => ( addUserFavoriteSuccess( favoriteData ) ), dispatch ),
			addUserFavoriteSuccess		: bindActionCreators( addUserFavoriteSuccess, dispatch ),
			addUserFavoriteFailure		: bindActionCreators( addUserFavoriteFailure, dispatch ),
			removeUserFavorite			: bindActionCreators( removeUserFavorite, dispatch ),
			removeUserFavoriteSuccess	: bindActionCreators( favoriteData => ( removeUserFavoriteSuccess( favoriteData ) ), dispatch ),
			removeUserFavoriteFailure	: bindActionCreators( removeUserFavoriteFailure, dispatch ),
			
			//increasePageDepth	: bindActionCreators( increasePageDepth, dispatch ),
			//decreasePageDepth	: bindActionCreators( decreasePageDepth, dispatch ),
			setPageDepth	: bindActionCreators( currentPage => ( setPageDepth( currentPage ) ), dispatch ),
		},
	};
}

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( Marker );