import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';

import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';


import { 
	fade,
	makeStyles,
	withStyles
} from '@material-ui/core/styles';

import {
	Box,
	
	Switch,
	Button,
	Fab,
	IconButton,
	Grid,
	TextField,
	InputBase,
	
	FormControlLabel
} from '@material-ui/core';


import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
	Favorite		as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
	Explore			as ExploreIcon,
} from '@material-ui/icons';



const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginSmall:{
		margin: theme.spacing(0.5)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textCenter:{
		textAlign:"center"
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	textLightGrey:{
		color:"#6c757d !important"
	},
	textPrimary:{
		color:"#3f51b5"
	}
}));



export default function SideBar ( props ) {
	
	const classes	= useStyles();
	//const [index, setIndex] = React.useState(0);
	
	return (
		<Fragment>
			<Grid
				className={
					clsx(
						classes.noGutters
					)
				}
				container
				direction="column"
				justify="flex-start"
				alignItems="center"
				
				spacing={2}
				
				style={
					( true === isMobileOnly ) ?
						{
							position:"fixed",
							maxWidth:"3.5rem",
							right:"0.1rem",	
							zIndex:"2",
							
							
							//top:"5rem",
							bottom:"6rem",
						} :
						{
							position:"fixed",
							maxWidth:"5rem",
							right:"0.1rem",	
							zIndex:"2",	
							
							//top:"6rem",
							bottom:"7rem",	
						}
				}
			>
				
				<Grid
					item
				>
					<Fab
						aria-label="favorite" 
						className={classes.fab}
						
						//color="primary"
						
						//size="medium"
						size="small"
						
						onClick={
							(e) => {
								props.toggleFavoritePanel(e);
							}
						}
						
						style={{
							backgroundColor:"white"
						}}
					>
						<
						//FavoriteBorderIcon
						FavoriteIcon
							color="primary"
						/>
					</Fab>
				</Grid>
				
				<Grid
					item
				>
					<Fab
						aria-label="check_square" 
						className={classes.fab}
						
						color="primary"
						
						//size="medium"
						size="small"
						
						onClick={
							(e) => {
								//console.log( e );
								props.toggleFilterPanel(e);
							}
						}
						
						style={{
							backgroundColor:"white"
						}}
					>
						<Icon
							className={
								clsx(
									//classes.textPrimary,
									//"far fa-check-square"
									"fa fa-filter",
									classes.marginSmall,
								)
							}
							fontSize="small"
							color="primary"
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								//margin:1
							}}
						/>
						
					</Fab>
				</Grid>
				
				
				<Grid
					item
				>
					<Fab 
						aria-label="send" 
						className={classes.fab}
						
						//color="primary"
						
						//size="medium"
						size="small"
						
						onClick={
							(e) => {
								props.toggleSuggestions();
								props.closeControlPanel("suggestionBtn");
							}
						}
						
						style={{
							backgroundColor:"white"
						}}
					>
						<Icon
							className={
								clsx(
									//classes.textPrimary,
									"fas fa-paper-plane",
									classes.marginSmall,
								)
							}
							fontSize="small"
							color="primary"
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								//margin:1
							}}
						/>
						
					</
						//IconButton
						Fab
					>
				</Grid>
			</Grid>
		</Fragment>
	);
	
}
