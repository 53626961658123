import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';


import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import axios from 'axios';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Box,
	Collapse,
	Card,
	CardContent,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon		from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
	Favorite		as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
} from '@material-ui/icons';


import CardList		from '../Utility/CardList.js';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		third		: {
			main:"#616161",	// lightGrey
			contrastText:"#fff",
		},
		fourth		: {
			main:"rgb(0,0,0,0.54)",
			contrastText:"#fff",
		},
		error		: {
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
}));



export default function FavoriteItemDetail ( props ) {
	
	const classes	= useStyles();
	
	//console.log( props );
	
	
	let naverCafeList	= null;
	
	if(
		null		!== props.communityInfo			&&
		'undefined'	!== typeof props.communityInfo	&&
		0			<	props.communityInfo.length
	) {
		
		naverCafeList	= props.communityInfo;
		
	} else {
		naverCafeList	= null;
	}
	
	
	
	return (
		<Fragment>
			<ThemeProvider
				theme={markerInfoTheme}
			>
				<Box
					bgcolor={
						( "ulzip" === props.data.type ) ?
							"secondary.main" :
							(
								( "kinder" === props.data.type ) ?
									"primary.main" : ""
							)
					}
					
					color={
						( "ulzip" === props.data.type ) ?
							"secondary.contrastText" :
							(
								( "kinder" === props.data.type ) ?
									"primary.contrastText" : ""
							)
					}
					
					borderRadius={"borderRadius"}
					
					className={
						clsx(
							classes.marginSmallY,
						)
					}
					
					style={{
						width:"100%",
					}}
				>
					<Grid
						container
						direction="row"
						//justify="center"
						justify="flex-start"
						alignItems="center"
					>
						<Grid
							item
							
							xs={2}
							sm={2}
							md={2}
							lg={2}
							xl={2}
							
							className={
								clsx(
									classes.textCenter,
								)
							}
						>
							<IconButton
								//color="inherit"
								variant="contained"
								
								className={
									clsx(
										classes.button,
									)
								}
								
								onClick={
									(e) => {
										props.removeFavorite({ 
											type:props.data.type, 
											id_:props.data.id_ 
										});
									}
								}
							>
								<ThemeProvider
									theme={markerInfoTheme}
								>
									<Box
										color={"warning.light"}
										
										clone={true}
									>
											<FavoriteIcon 
												
											/> 
										</Box>
								</ThemeProvider>
							</IconButton>
						</Grid>
						<Grid
							item
							
							xs={8}
							sm={8}
							md={8}
							lg={8}
							xl={8}
							
							className={
								clsx(
									classes.textCenter,
								)
							}
						>
							<Button
								color={"inherit"}
								fullWidth={true}
								
								onClick={
									( e ) => {
										
										props.openMarkerFromAutoSearchResult( 
											( "ulzip" === props.data.type ) ?
												{  
													data_type:props.data.type ,
													ulzip_code:props.data.id_
												} :
												(
													( "kinder" === props.data.type ) ?
														{
															data_type:props.data.type ,
															kinder_code:props.data.id_
														} : null
												)
										);
										
										props.moveCenterLoc(props.data.address, props.data.address, props.data.lat, props.data.lon);
									}
								}
							>
								<Typography
									variant="h6"
									color="inherit"
								>
									{
										( 
											( true === isMobileOnly ) ?
												(
													( "ulzip" === props.data.type ) ?
														(
															( 8 < props.data.name.indexOf( "어린이집" ) ) ?
																(
																	<Fragment>
																		{
																			props.data.name.substring( 0, props.data.name.indexOf( "어린이집" ) )
																		}
																		<br/>
																		{
																			"어린이집"
																		}
																	</Fragment>
																) :
																(
																	props.data.name
																)
														) :
														(
															( "kinder" === props.data.type ) ?
																(
																	( 7 < props.data.name.indexOf( "병설유치원" ) ) ?
																		(
																			<Fragment>
																				{
																					props.data.name.substring( 0, props.data.name.indexOf( "병설유치원" ) )
																				}
																				<br/>
																				{
																					"병설유치원"
																				}
																			</Fragment>
																		) :
																		(
																			( 9 < props.data.name.indexOf( "유치원" ) ) ?
																				(
																					<Fragment>
																						{
																							props.data.name.substring( 0, props.data.name.indexOf( "유치원" ) )
																						}
																						<br/>
																						{
																							"유치원"
																						}
																					</Fragment>
																				) :
																				(
																					props.data.name
																				)
																		)
																) :
																(
																	props.data.name
																)
														)
												) :
												(
													props.data.name
												)
										)
									}
									
									{
										//props.data.name 
									}
								</Typography>
							</Button>
						</Grid>
						<Grid
							item
							
							xs={2}
							sm={2}
							md={2}
							lg={2}
							xl={2}
							
							className={
								clsx(
									classes.textCenter,
								)
							}
						>
							{
							// 2019.12.13 즐겨찾기에서 카페검색결과 안보여주기로 결정...
							
							//<IconButton
							//	color="inherit"
							//	variant="contained"
							//	
							//	className={
							//		clsx(
							//			classes.button,
							//		)
							//	}
							//	
							//	onClick={
							//		(e) => {
							//			props.toggleFavoriteSchoolDetail();
							//		}
							//	}
							//>
							//	<Icon
							//		className={
							//			( false === props.isOpen ) ?
							//				"fas fa-plus" : "fas fa-minus"
							//		}
							//	/>
							//</IconButton>
							}
						</Grid>
					</Grid>
				</Box>
			</ThemeProvider>
			
			{
			//<Collapse
			//	in={props.isOpen}
			//	//style={{
			//	//	width:"100%"
			//	//}}
			//>
			//	{
			//		( null === naverCafeList ) ?
			//			(
			//				<Grid
			//					container
			//					//direction="column"
			//					direction="row"
			//					justify="center"
			//					alignItems="center"
			//				>
			//					<Grid
			//						item
			//						
			//						xs={12}
			//						sm={12}
			//						md={12}
			//						lg={12}
			//						xl={12}
			//					>
			//						<Card 
			//							
			//						>
			//							<CardContent
			//								
			//								style={{
			//									//color:"#212529",
			//									textAlign:"center",
			//								}}
			//							>
			//								<Typography
			//									
			//									variant="body2"
			//									color="textSecondary"
			//									component="p"
			//								>
			//									{
			//										"리뷰가 존재하지 않습니다."
			//									}
			//								</Typography>
			//							</CardContent>
			//						</Card>
			//					</Grid>
			//				</Grid>
			//			) :
			//			(
			//				<CardList
			//					id={props.data.id_}
			//					data={naverCafeList}
			//					cardType={"basic"}
			//				/>
			//			)
			//	}
			//</Collapse>
			}
		</Fragment>
	);
	
}