import React, {
	Component,
	Fragment
} from 'react';

import { connect } from 'react-redux';

import {
	login,
	loginSuccess,
	loginFailure,
	logout,
	logoutSuccess,
	logoutFailure
} from '../store/modules/userAuthentication';

import { 
	isMobile, 
	isIOS, 
	isMobileOnly, 
	isTablet, 
	isAndroid, 
	isBrowser,
	isIPad13,
} from 'react-device-detect';


import CustomAlert		from '../components/Utility/Alert/CustomAlert';

import {
	Paper,
	Typography,
	Box,
	Grid,
	Button,
	Divider,
	Container,
} from '@material-ui/core';

import {
	getLoginInfo,
	userLogin,
} from '../components/User/IPickUser.js';

import LoginPanel		from '../components/User/LoginPanel.js';
import MyPage			from '../components/User/MyPage.js';


class Profile extends Component {
	
	constructor ( props ) {
		
		super( props );
		
		
		this.state	= {
			
			userInfo:{
				loginYN	: this.props.userInfo.loginYN,
				userId	: this.props.userInfo.userId,
				userEmail:this.props.userInfo.userEmail,
				userNickname:this.props.userInfo.userNickname,
				nicknameConfirmYN:this.props.userInfo.nicknameConfirmYN,
				friendShareLink:this.props.userInfo.friendShareLink,
			},
			
			email:"",
			isValidEmail:false,
			
			password:"",
			isValidPassword:true,
			
			errMsg:"",
			errMsgType:"warning",
			
		};
		
		
		//this.getUserLoginInfo	= this.getUserLoginInfo.bind(this);
		
		this.typeEmail			= this.typeEmail.bind(this);
		this.typePassword		= this.typePassword.bind(this);
		this.login				= this.login.bind(this);
		
		this.closeLoginPanel	= this.closeLoginPanel.bind(this);
		
		this.closeAlert			= this.closeAlert.bind(this);
		this.createErrorMsg		= this.createErrorMsg.bind(this);
		
	}
	
	
	componentDidMount ( ) {
		
	}
	
	
	componentDidUpdate ( prevProps, prevState ) {
		
	}
	
	
	componentWillUnmount() {
		
	}
	
	
	static getDerivedStateFromProps( nextProps, prevState ) {
		
		if ( 
			prevState.userInfo.loginYN				!== nextProps.userInfo.loginYN		||
			prevState.userInfo.userId				!==	nextProps.userInfo.userId		||
			prevState.userInfo.userEmail			!== nextProps.userInfo.userEmail	||
			prevState.userInfo.userNickname			!== nextProps.userInfo.userNickname	||
			prevState.userInfo.nicknameConfirmYN	!== nextProps.userInfo.nicknameConfirmYN
		) {
			return { 
				userInfo: {
					//...userInfo,
					loginYN	: nextProps.userInfo.loginYN,
					userId	: nextProps.userInfo.userId,
					userEmail: nextProps.userInfo.userEmail,
					userNickname: nextProps.userInfo.userNickname,
					friendShareLink: nextProps.userInfo.friendShareLink,
					nicknameConfirmYN : nextProps.userInfo.nicknameConfirmYN,
				}
			};
		}
		
		return null;
		
	}
	
	
	typeEmail( e ) {
		
		//e.preventDefault();
		
		const regexp_for_self	= /[a-zA-Z0-9]+([._-]?[a-zA-Z0-9])*@[a-zA-Z0-9]+[-]?[a-zA-Z0-9]+[.][a-zA-Z]+([.][a-zA-Z]+)?/;
		
		let tmp_email	= e.target.value;
			
		tmp_email	= tmp_email.replace( regexp_for_self, "" );
		
		if( ""  !== tmp_email ) {
			this.setState({
				email: e.target.value,
				isValidEmail : false,
				errMsg:""
			});
		} else {
			this.setState({
				email: e.target.value,
				isValidEmail : true,
				errMsg:""
			});
		}
		
	}
	
	
	typePassword( e ) {
		//e.preventDefault();
		
		this.setState({
			password:e.target.value,
			errMsg:""
		});
	}
	
	
	async login ( e ) {
		
		let result	= false;
		let errMsg		= "";
		let errMsgType	= "warning";
		let userLoginResultUserInfo	= null;
		
		if ( 
			true	!==	this.state.isValidEmail		||
			0		>=	this.state.password.length
		) {
			
			if ( 0	>=	this.state.password.length ) {
				
				this.setState({
					isValidPassword : false,
				});
				
				this.createErrorMsg(
					"비밀번호를 입력해주세요."
					, "warning"
				);
				
			}
			
			return;
		}
		
		try {
			
			this.props.login();
			
			const userLoginResult	= await userLogin ( this.state.email, this.state.password );
			
			//console.log( userLoginResult );
			
			result	= userLoginResult.result;
			errMsg	= userLoginResult.msg;
			
			if ( true === result ) {
				errMsgType	= "success";
				userLoginResultUserInfo	= userLoginResult.userInfo;
			} else {
				if ( '일치하는 정보가 없습니다.' === errMsg ) {
					errMsgType	= "warning";
				} else {
					errMsgType	= "error";
				}
			}
			
		} catch ( err ) {
			
			result	= false;
			errMsg	= "로그인 도중 오류가 발생했습니다.";
			errMsgType	= "error";
			
		} finally {
			
			this.createErrorMsg(
				errMsg,
				errMsgType
			);
			
			if ( false === result ) {
				this.props.loginFailure();
				
				this.setState({
					isValidPassword:false,
				});
			} else {
				
				this.props.loginSuccess(
					{
						loginYN	: userLoginResultUserInfo.loginYN,
						userId	: userLoginResultUserInfo.userId,
						userEmail: userLoginResultUserInfo.userEmail,
						userNickname: userLoginResultUserInfo.userNickname,
						friendShareLink	: userLoginResultUserInfo.friendShareLink,
						nicknameConfirmYN: userLoginResultUserInfo.nicknameConfirmYN,
					}
				);
				
				setTimeout(this.closeLoginPanel(), 2000);
				//this.closeLoginPanel();
			}
			
		}
		
	}
	
	
	closeLoginPanel ( ) {
		
		if ( true === this.props.isPopUp ) {
			this.props.toggleLoginPanelOpen();
		} else {
			
			if (
				true	=== isMobileOnly	||
				true	=== isTablet		||
				true	===	isIPad13	
			) {
				//this.props.history.replace( "/m" );
				this.props.history.goBack( );
			} else {
				this.props.history.replace( "/" );
			}
			
		}
		
	}
	
	
	/**
	 *	에러 메시지 닫기 버튼 클릭 시 호출되는 메소드...
	 */
	closeAlert ( ) {
		if ( true === this.props.isPopUp ) {
			this.props.createErrorMsg(
				"",
				"warning"
			);
		} 
		
		this.setState({
			errMsg:""
		});
	}

	
	/**
	 *	에러 메시지를 띠우는 메소드...
	 */
	createErrorMsg ( 
		errMsg,
		errMsgType, 
	) {
		if ( true === this.props.isPopUp ) {
			this.props.createErrorMsg(
				errMsg,
				errMsgType
			);
		} 
		
		this.setState({
			errMsg: errMsg,
			errMsgType:errMsgType,
		});
	}
	
	
	render ( ) {
		
		let login_btn_style	= false;
		
		if( 
			false	===	this.state.isValidEmail		||
			0		>=	this.state.password.length
		) {
			login_btn_style	= true;
		}
		
		//console.log( this.props.userInfo );
		//console.log( this.state.userInfo );
		
		return (
			<Fragment>
				
				{
					// isPopUp 는 undefined 여도 된다...
					// true 로 전달 할 때는 /User 경로로 온게 아니라 그냥 로그인 팝업!
					( true === this.props.isPopUp ) ?
						null :
						(
							<CustomAlert
								open={this.state.errMsg.length > 0}
								data={this.state.errMsg} 
								option={this.state.errMsgType}
								closeAlert={this.closeAlert}
							/>
						)
				}
				
				{
					( 
						true		=== this.state.userInfo.loginYN 
					) ?
						(
							<MyPage
								closeLoginPanel={this.closeLoginPanel}
								userInfo={this.state.userInfo}
							/>
						) :
						(
							<LoginPanel
								isOpen={true}
								typeEmail={this.typeEmail}
								typePassword={this.typePassword}
								login={this.login}
								closeLoginPanel={this.closeLoginPanel}
								
								loginBtnStyle={login_btn_style}
								errMsg={this.state.errMsg}
								isValidEmail={this.state.isValidEmail}
								isValidPassword={this.state.isValidPassword}
							/>
						)
				}
				
			</Fragment>
		);
		
	}
	
}


//export default Profile;

// props 로 넣어줄 스토어 상태값
const mapStateToProps = state => ({
	userInfo: state.userAuthentication.userInfo
});

// props 로 넣어줄 액션 생성함수
const mapDispatchToProps = dispatch => ({
	//userAuthentication: userInfo => dispatch( userAuthentication( userInfo ) ),
	login			: ( ) => dispatch( login( ) ) ,
	loginSuccess	: userInfo => dispatch( loginSuccess( userInfo ) ) ,
	loginFailure	: ( ) => dispatch( loginFailure( ) ) ,
	logout			: ( ) => dispatch( logout( ) ) ,
	logoutSuccess	: ( ) => dispatch( logoutSuccess( ) ) ,
	logoutFailure	: ( ) => dispatch( logoutFailure( ) ) ,
});

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( Profile );