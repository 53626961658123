import React, { Component, Fragment } from 'react';

import clsx from 'clsx';

import PropTypes from 'prop-types';

import AdSense from 'react-adsense';
import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	isIPad13,
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	makeStyles, 
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	AppBar,
	Toolbar,
	Tabs,
	Tab,
	Paper,
	Typography,
	Box,
	CircularProgress,
	Grid,
	GridList,
	Card,
	CardActions,
	CardActionArea,
	CardContent,
	Button,
	CardMedia,
	Divider,
	Container,
	IconButton,
} from '@material-ui/core';

import {
	ChevronLeft	as ChevronLeftIcon,
} from '@material-ui/icons';

import { 
	red 
} from '@material-ui/core/colors';

import { Event, PageView, initGA } from '../Utility/Tracking.js';

import CommunityCurrentTitle from './CommunityCurrentTitle';

const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	paddingBottom2:{
		paddingBottom: theme.spacing(2),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginBottom2:{
		marginBottom: theme.spacing(2),
	},
	marginBottom3:{
		marginBottom: theme.spacing(3),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRightSmall:{
		marginRight:theme.spacing(0.5),
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink	: 0,
		backgroundColor	: "#3f51b5",	// primary.main
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	
	dialogPaper: 
		( true === isMobileOnly ) ?
			{
				margin	: theme.spacing(0),
				minWidth: "90vw",
			} :
			{
				margin: theme.spacing(0),
			},
	
}));


const itemToShow		= 3;
const itemMoreToShow	= 10;


const TabContent = ({ type, isLoading, contents, contentsLen }) => {
	
	const classes = useStyles();

	function strip(html) {
		let tmp = document.createElement('DIV');
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || '';
	}
	
	
	let content	= null;
	
	
	return (
		<Fragment>
			{
				( !isLoading ) ? 
					(
						(
							null !== contents &&
							undefined !== contents &&
							null !== contents.items &&
							undefined !== contents.items &&
							contents.items.length > 0 
						) ? 
							(
								contents.items.slice(0, contentsLen).map((post, idx) => {
									
									//const { title, link, description } = post;
									const { title, link, description, cafename, cafeurl } = post;
									
									return (
										<Grid
											key={
												( 0 === type ) ?
													link + '_cafe_item_key_' + idx.toString() : 
													link + '_news_item_key_' + idx.toString()
											}
											className={
												clsx(
													classes.padding
												)
											}
											
											item
										>
											
											<Card 
												className={
													clsx(
														classes.padding
													)
												}
												
												//style={{
												//	width:"100%"
												//}}
											>
												<a
													target="_blank"
													href={
														( 0 === type ) ?
															(
																'http://search.naver.com/search.naver?sm=top_hty&fbm=1&ie=utf8&query=' +
																'어린이집' +
																'&url=' +
																encodeURIComponent(link)
															) :
															(
																link
															)
													}
													rel="noopener noreferrer"
													style={{
														textDecoration: 'none'
													}}
													onClick={
														(e) => {
															
															if ( 0 === type ) {
																Event(
																	'Community',
																	'[CMMNTY CAFE] Detail Clicked',
																	'[CMMNTY CAFE] '+strip(title)
																);
															} else if( 1 === type ) {
																Event(
																	'Community',
																	'[CMMNTY NEWS] Detail Clicked',
																	'[CMMNTY NEWS] '+strip(title)
																);
															} else {
																Event(
																	'Community',
																	'[CMMNTY CAFE] Detail Clicked',
																	'[CMMNTY CAFE] '+strip(title)
																);
															}
															
														}
													}
												>
													<CardContent
														className={
															clsx(
																classes.padding
															)
														}
														
														style={{
															color:"#212529"
														}}
													>
														<Typography 
															
															gutterBottom 
															variant="h6" 
															component="h3"
															
														>
															{
																strip(title)
															}
														</Typography>
														<Typography
															
															variant="body2"
															color="textSecondary"
															component="p"
														>
															{
																strip(description)
															}
														</Typography>
														<Typography
															className={
																clsx(
																	classes.marginTop
																)
															}
															variant="body2"
															color="textSecondary"
															component="p"
															
															style={{
																textAlign:"center"
															}}
														>
															{
																"더 보기"
															}
														</Typography>
													</CardContent>
													
												</a>
												{
													( 0 === type ) ?
														(
															<CardActions
															>
																<Button 
																	target="_blank"
																	href={cafeurl}
																	rel="noopener noreferrer"
																	
																	size="small"
																	
																	style={{
																		textDecoration: 'none'
																	}}
																>
																	{cafename}
																</Button>
															</CardActions>
														) : null
												}
											</Card>
										</Grid>
									);
								})
							) : 
							(
								//<Fragment>
								<Grid
									key={
										( 0 === type ) ?
											'_cafe_item_key_0' : 
											'_news_item_key_0'
									}
									className={
										clsx(
											classes.padding
										)
									}
									item
									
									//style={{
									//	width:"100vw"
									//}}
								>
									<Card 
										className={
											clsx(
												classes.padding
											)
										}
										
										//style={{
										//	width:"100%"
										//}}
									>
										<CardContent
											className={
												clsx(
													classes.padding,
												)
											}
										>
											<Typography 
												className={
													clsx(
														classes.textCenter,
													)
												}
												variant="body2" 
												color="textSecondary" 
												component="p"
											>
												{'조회된 결과가 없습니다.'}
											</Typography>
										</CardContent>
									</Card>
								</Grid>
							)
					) : 
					(
						<CircularProgress 
							className="spin_center" 
							color="secondary" 
						/>
					)
			}
		</Fragment>
	);
	
}


function TabPanel( props ) {
	
	const { 
		children, 
		value, 
		index, 
		totalContentsLen,
		contentsLen,
		currentContentsLen,
		setContentsLength,
		...other 
	} = props;
	
	const classes	= useStyles();	
	

	//console.log( props );
	
	
	return (
		
		<Fragment>
			
			<Box
				component="div"
				role="tabpanel"
				hidden={value !== index}
				id={`nav-tabpanel-${index}`}
				aria-labelledby={`nav-tab-${index}`}
				{...other}
				
				flexGrow={1}
				
				style={{
					maxHeight:"calc( 100vh - 6rem )",
				}}
			>
				{
					value === index && 
					(
						<Box
							bgcolor="white"
							borderRadius={14}
							//flexGrow={1}
							
							//display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="flex-start"
							
							m={0}
							p={0}
							
							//clone={true}
							
							style={{
								overflowY:"scroll",
								maxHeight:"calc( 100vh - 6rem )",
							}}
							
						>
							{
								children
							}
							
							{
								( 2	=== totalContentsLen ) ? 
									(
										<Grid 
											className={
												clsx(
													//classes.textCenter,
													classes.padding,
													classes.marginBottom3,
												)
											}
											item
											
											//style={{
											//	width:"100%",
											//	padding:"8px"
											//}}
										>
											<Card 
												className={
													clsx(
														//classes.padding
														classes.noGutters,
													)
												}
												
												//style={{
												//	width:"100%"
												//}}
											>
												<Button
													className={
														clsx(
															classes.button,
															classes.fontWeightBold,
														)
													}
													
													size="medium"
													variant="contained"
													fullWidth={true}
													disabled
												>
													{
														"마지막"
													}
												</Button>
											</Card>
										</Grid>
									) : 
									(
										( 1	=== totalContentsLen ) ?
											(
												<Grid 
													className={
														clsx(
															//classes.textCenter,
															classes.padding,
															classes.marginBottom3,
														)
													}
													item
													
													//style={{
													//	width:"100%",
													//	padding:"8px"
													//}}
												>
													<Card 
														className={
															clsx(
																//classes.padding
																classes.noGutters,
															)
														}
														
														//style={{
														//	width:"100%"
														//}}
													>
														<Button
															className={
																clsx(
																	classes.button
																)
															}
															
															size="medium"
															variant="contained"
															fullWidth={true}
															
															onClick={
																
																(e) => {
																	if( contentsLen < currentContentsLen ) {
																		if ( contentsLen + itemMoreToShow <= currentContentsLen ) {
																			setContentsLength( contentsLen + itemMoreToShow );
																		} else {
																			setContentsLength( currentContentsLen );
																		}
																	} else {
																		setContentsLength( itemToShow );
																	}
																}
																
															}
														>
															{
																( 
																	( currentContentsLen - contentsLen ) > itemMoreToShow 
																) ?
																	( itemMoreToShow ) :
																	( currentContentsLen - contentsLen )
															} 
															{
																"건 더 보기"
															}
														</Button>
													</Card>
												</Grid>
											) : null
									)
							}
						</Box>
					)
				}
			</Box>
			
		</Fragment>
		
	);
}


function TabPanelList( props ) {
	const classes	= useStyles();
	
	const { numOfViews, value, isLoading, contents, contentsLen, setContentsLength, location, view } = props;
	
	let tabPanelList	= [];
	
	//console.log( numOfViews );
	
	for ( let i = 0 ; i < numOfViews ; i++ ) {
		
		let key_name	= "";
		
		switch ( i ) {
			case 0 :
				key_name	= "cafe";
				break;
			case 1 :
				key_name	= "news";
				break;
			default :
				key_name	= "cafe";
				break;
		}
		
		tabPanelList.push(
			<TabPanel 
				
				key={ 
					"community_tab_panel_" + key_name
				}
				value={value}
				index={i}
				totalContentsLen={
					//	0 : 데이터가 없는 경우
					//	1 : 데이터는 존재하지만 더보기 출력할 데이터가 더이상 없는 경우
					//	2 : 데이터도 존재하고 더보기 출력할 데이터도 더 있는 경우
					(
						null		=== contents		||
						undefined	=== contents		||
						null		=== contents.items	||
						undefined	=== contents.items	||
						0	=== contents.items.length
					) ?
						(0) :
						(
							(
								null		!== contents		&&
								undefined	!== contents		&&
								null		!== contents.items	&&
								undefined	!== contents.items	&&
								(
									0	< contents.items.length	&&
									contentsLen >= contents.items.length
								)
							) ?
								2 : 1
						)
					
				}
				
				contentsLen={contentsLen}
				setContentsLength={setContentsLength}
				currentContentsLen={
					(
						null		=== contents		||
						undefined	=== contents		||
						null		=== contents.items	||
						undefined	=== contents.items	||
						0	=== contents.items.length
					) ?
						(0) : contents.items.length
				}
			>
				
				<CommunityCurrentTitle
					location={location}
					view={view}
				/>
				
				<TabContent
					type={i}
					isLoading={isLoading}
					contents={contents}
					contentsLen={contentsLen}
					
					//isLoading={true}
					//contents={[]}
					//contentsLen={0}
				/>
				
			</TabPanel>
		)
	}
	
	
	return (
		
		<Fragment>
			
			{
				tabPanelList
			}
			
		</Fragment>
		
	);
	
}


TabPanel.propTypes = {
	//children: PropTypes.node,
	children: PropTypes.node.isRequired,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};


function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}



export default function CommunityMain ( props ) {
	
	
	const classes	= useStyles();
	const [index, setIndex] = React.useState( props.view );
	const [contentsLength, setContentsLength]	= React.useState(itemToShow);
	const { isLoading, contents } = props.data;
	
 	
	return (
		
		<Fragment>
			<AppBar 
				position="relative"
				
				className={
					clsx(
						classes.topMenuBar,
						classes.noGutters,
					)
				}
			>
				{
					(
						true	=== isMobileOnly	||
						true	=== isTablet		||
						true	===	isIPad13
					) ?
						(
							<Tabs 
								className={
									clsx(
										classes.topMenuBarList,
									)
								}
								
								variant={"fullWidth"}
								
								value={index} 
								onChange={
									( event, newIndex ) => {
										
										props.changeView(newIndex);
										setIndex(newIndex);
										
										if( itemToShow !== contentsLength ) {
											setContentsLength( itemToShow )
										}
										
									}
								}
								//aria-label="new_cafe_tab"
								
							>
								<Tab 
									label="카페" 
									{...a11yProps(0)}
								/>
								<Tab 
									label="뉴스" 
									{...a11yProps(1)} 
								/>
							</Tabs>
						) :
						(
							<Toolbar
								className={
									clsx(
										classes.topMenuBarList,
									)
								}
								disableGutters={false}
								
								style={{
									display:"flex",
								}}
							>
								<IconButton
									className={
										clsx(
											classes.smallButton,
											classes.shrink,
										)
									}
									
									color={
										"inherit"
									}
									
									onClick={
										(e) => {
											props.goToMainPage();
										}
									}
								>
									<ChevronLeftIcon
										fontSize="large"
									/>
								</IconButton>
								<Tabs 
									variant={
										//"standard"
										"fullWidth"
									}
									
									className={
										clsx(
											classes.grow,
											classes.padding3X,
										)
									}
									
									value={index} 
									onChange={
										( event, newIndex ) => {
											
											props.changeView(newIndex);
											setIndex(newIndex);
											
											if( itemToShow !== contentsLength ) {
												setContentsLength( itemToShow )
											}
											
										}
									}
									
									//flexContainer
								>
									<Tab 
										label="카페" 
										{...a11yProps(0)}
									/>
									<Tab 
										label="뉴스" 
										{...a11yProps(1)} 
									/>
								</Tabs>
								<IconButton
									className={
										clsx(
											classes.smallButton,
											classes.shrink,
										)
									}
									
									//color={
									//	"inherit"
									//}
									
									style={{
										visibility:"hidden",
									}}
									
									onClick={
										(e) => {
											//props.history.replace("/m");
										}
									}
								>
									<ChevronLeftIcon
										fontSize="large"
										style={{
											visibility:"hidden",
										}}
									/>
								</IconButton>
							</Toolbar>
						)
				}
			</AppBar>
			
			<TabPanelList
				numOfViews={props.numOfViews}
				value={index}
				
				isLoading={isLoading}
				contents={contents}
				contentsLen={contentsLength}
				setContentsLength={setContentsLength}
				
				location={props.location}
				view={props.view}
			/>
		</Fragment>
		
	);
	
}