import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';

//import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

//import { 
//	ThemeProvider 
//} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	//IconButton,
	Box,
	//CircularProgress,
} from '@material-ui/core';


import Chart		from '../Utility/Chart.js';

import MarkerUlzip	from './MarkerUlzip.js';
import MarkerKinder	from './MarkerKinder.js';

import UserReview	from '../UserReview/UserReview';

//import AdSense			from 'react-adsense';
import Advertisement	from '../Ads/Advertisement';



const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textCenter:{
		textAlign:"center",
	},
	textRight:{
		textAlign:"right",	
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	//success: {
	//	backgroundColor: green[600],
	//	color:theme.palette.primary.contrastText
	//},
	//successOpposition: {
	//	backgroundColor: theme.palette.primary.contrastText,
	//	color:green[600]
	//},
	//primary:{
	//	backgroundColor: theme.palette.primary.main,
	//	color:theme.palette.primary.contrastText
	//},
	//primaryOpposition:{
	//	backgroundColor: theme.palette.primary.contrastText,
	//	color:theme.palette.primary.main
	//},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
}));



export default function MarkerInfoBody ( props ) {
	
	const classes	= useStyles();
	
	const [teacherChartType, changeChartType]	= React.useState("BarChart");
	
	let typeDetailInfo	= null;
	
	if ( "ulzip" === props.type ) {
		
		if (
			undefined	!== props.data.detailInfo.curriculum	&&
			undefined	!== props.data.detailInfo.authInfo		&&
			undefined	!== props.data.detailInfo.authHistory	&&
			undefined	!== props.data.detailInfo.authUrl		&&
			undefined	!== props.data.detailInfo.scoreInfo
		) {
			typeDetailInfo	= (
				<MarkerUlzip
					dataType={props.type}
					data={props.data.detailInfo}
				/>	
			);
		}
		
	} else if ( "kinder" === props.type ) {
		
		if (
			undefined	!== props.data.detailInfo.summary		&&
			undefined	!== props.data.detailInfo.authInfo		&&
			undefined	!== props.data.detailInfo.authInfoList	&&
			undefined	!== props.data.detailInfo.authInfoFile	&&
			undefined	!== props.data.detailInfo.violationInfo	&&
			undefined	!== props.data.detailInfo.costInfo
		) {
			typeDetailInfo	= (
				<MarkerKinder
					dataType={props.type}
					data={props.data.detailInfo}
				/>	
			);
		}
		
	} else if ( "kids_cafe" === props.type ) {
		
	} else {
		
	}
	
	
	let extraService	= "";
	
	if ( true === Array.isArray( props.data.extraService ) ) {
		
		for( let idx = 0 ; idx < props.data.extraService.length ; idx++ ) {
			if( props.data.extraService.length - 1 === idx ) {
				extraService	= extraService + props.data.extraService[idx];
			} else {
				extraService	= extraService + props.data.extraService[idx] + ", ";
			}
		}
		
	} else {
		extraService	= props.data.extraService;
	}
	
	let group_width_student	= "80%";
	let group_width_teacher	= "80%";
	
	if ( true === Array.isArray( props.data.student ) ) {
		if ( props.data.student.length < 9 ) {
			group_width_student	= "80%";
		} else if (
			props.data.student.length < 13
		) {
			group_width_student	= "70%";
		} else {
			group_width_student	= "60%";
		}
	}
	
	
	if ( true === Array.isArray( props.data.teacher ) ) {
		if ( props.data.teacher.length < 9 ) {
			group_width_teacher	= "80%";
		} else if (
			props.data.teacher.length < 13
		) {
			group_width_teacher	= "70%";
		} else {
			group_width_teacher	= "60%";
		}	
	}
	
	
	
	
	
	
	return (
		<Fragment>
			
			<UserReview
				type={props.type}
				id={props.id}
				name={props.data.name}
				address1={props.data.addressDepth1}
				address2={props.data.addressDepth2}
				zIndex={1}	// UserReview 컴포넌트의 zIndex
				createErrorMsg={props.createErrorMsg}
			/>
			
			<Box
				borderTop={7}
				borderBottom={0}
				
				borderColor="grey.300"
				
				className={
					clsx(
						//classes.noMargin,
						//classes.paddingSmallY,
						
						classes.noGutters,
					)
				}
				
				style={{
					width:"100%",
					borderColor	: "rgb(224, 224, 224)",
				}}
			>
				<Advertisement
					type={""}
					position={"middle"}
				/>
			</Box>
			
			<Box
				borderTop={7}
				borderBottom={7}
				
				borderColor="grey.300"
				
				className={
					clsx(
						classes.noMargin,
						classes.paddingSmallY,
					)
				}
				
				style={{
					width:"100%",
					borderColor	: "rgb(224, 224, 224)",
				}}
			>
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
				>
					<Grid
						item
						container
						direction="row"
						justify="center"
						alignItems="center"
						
						className={
							clsx(
								classes.noMargin,
								//classes.paddingY,
								classes.padding2X,
							)
						}
					>
						<Grid
							item
							
							xs={4}
							sm={4}
							md={4}
							lg={4}
							xl={4}
							
							className={
								clsx(
									classes.textCenter
								)
							}
						>
							{"전화번호"}
						</Grid>
						<Grid
							item
							
							xs={8}
							sm={8}
							md={8}
							lg={8}
							xl={8}
							
							className={
								clsx(
									classes.fontWeightBold
								)
							}
						>
							{
								props.data.phone
							}
						</Grid>
					</Grid>
					<Grid
						item
						container
						direction="row"
						justify="center"
						alignItems="center"
						
						className={
							clsx(
								classes.noMargin,
								classes.paddingY,
								classes.padding2X,
							)
						}
					>
						<Grid
							item
							
							xs={4}
							sm={4}
							md={4}
							lg={4}
							xl={4}
							
							className={
								clsx(
									classes.textCenter
								)
							}
						>
							{"운영시간"}
						</Grid>
						<Grid
							item
							
							xs={8}
							sm={8}
							md={8}
							lg={8}
							xl={8}
							
							className={
								clsx(
									classes.fontWeightBold
								)
							}
						>
							{
								props.data.opertime
							}
						</Grid>
					</Grid>
					<Grid
						item
						container
						direction="row"
						justify="center"
						alignItems="center"
						
						className={
							clsx(
								classes.noMargin,
								//classes.paddingY,
								classes.padding2X,
							)
						}
					>
						<Grid
							item
							
							xs={4}
							sm={4}
							md={4}
							lg={4}
							xl={4}
							
							className={
								clsx(
									classes.textCenter
								)
							}
						>
							{"제공서비스"}
						</Grid>
						<Grid
							item
							
							xs={8}
							sm={8}
							md={8}
							lg={8}
							xl={8}
							
							className={
								clsx(
									classes.fontWeightBold
								)
							}
						>
							{
								//props.data.extraService
								extraService
							}
						</Grid>
					</Grid>
				</Grid>
			</Box>
			

			{
				( 
					undefined	!== props.data.student
					// student 는 초기에 undefined 로 되어 있다가,
					// api 호출 후 오류 시 null 로 다시 초기화
				) ?
					(
						<Chart
							chartType={"BarChart"}
							dataType={props.type}
							data={props.data.student}
							chartArea={
								{
									top:"5%",
									left:"15%",
									width:"62.5%",
									height:"80%"
								}
							}
							xLabel={"원아 수(명)"}
							colors={
								(
									null		!== props.data.student	&&
									undefined	!== props.data.student
								) ?
									[props.data.student[1][3], props.data.student[1][6]] : null
							}
							groupWidth={
								group_width_student
							}
							chartTitle={
								( 'ulzip' === props.type ) ? 
									(
										'어린이집 영유아 현황'
									) : 
									(
										( 'kinder' === props.type ) ?
											'유치원생 현황' : ""
									)
							}
							changeBtn={false}
							changeChartType={
								( type ) => {
									changeChartType( type );
								}
							}
						/>
					) : null
			}
			
			{
				( 
					undefined	!== props.data.teacher
					// teacher 는 초기에 undefined 로 되어 있다가,
					// api 호출 후 오류 시 null 로 다시 초기화
				) ?
					(
						( "BarChart" === teacherChartType ) ?
							(
								<Chart
									chartType={teacherChartType}
									dataType={props.type}
									data={props.data.teacher}
									chartArea={
										{
											top:"5%",
											left:"15%",
											//width:"62.5%",
											width:"60%",
											height:"80%"
										}
									}
									xLabel={"수(명)"}
									colors={
										(
											null		!== props.data.teacher	&&
											undefined	!== props.data.teacher
										) ?
											[props.data.teacher[1][3]] : null
									}
									groupWidth={
										//"80%"
										group_width_teacher
									}
									chartTitle={
										( 'ulzip' === props.type ) ? 
											(
												'보육교사 근속연수 현황'
											) : 
											(
												( 'kinder' === props.type ) ?
													'유치원교사 근속연수 현황' : ""
											)
									}
									changeBtn={true}
									changeChartType={
										( type ) => {
											changeChartType( type );
										}
									}
								/>
							) : null
					) : null
			}
			
			{
				( 
					undefined	!== props.data.teacher
					// teacher 는 초기에 undefined 로 되어 있다가,
					// api 호출 후 오류 시 null 로 다시 초기화
				) ?
					(
						( "PieChart" === teacherChartType ) ?
							(
								<Chart
									chartType={teacherChartType}
									dataType={props.type}
									data={props.data.teacher}
									chartArea={
										{
											top:"10%",
											left:"10%",
											//width:"80%",
											width:"90%",
											height:"80%"
										}
									}
									//xLabel={"수(명)"}
									//colors={
									//	(
									//		null		!== props.data.teacher	&&
									//		undefined	!== props.data.teacher
									//	) ?
									//		[props.data.teacher[1][3]] : null
									//}
									//groupWidth={"80%"}
									chartTitle={
										( 'ulzip' === props.type ) ? 
											(
												'보육교사 근속연수 현황'
											) : 
											(
												( 'kinder' === props.type ) ?
													'유치원교사 근속연수 현황' : ""
											)
									}
									changeBtn={true}
									changeChartType={
										( type ) => {
											changeChartType( type );
										}
									}
								/>
							) : null
					) : null
			}
			
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
			>
				<Grid
					item
					container
					direction="row"
					justify="center"
					alignItems="center"
					
					className={
						clsx(
							classes.padding4X,
						)
					}
				>
					<Grid
						item
						
						xs={8}
						sm={8}
						md={8}
						lg={8}
						xl={8}
						
						className={
							clsx(
								//classes.textCenter,
								
							)
						}
					>
						{
							( 'ulzip' === props.type ) ? 
								(
									'총 보육교사 수(명)'
								) : 
								(
									'총 유치원교사 수(명)'
								)
						}
					</Grid>
					<Grid
						item
						
						xs={4}
						sm={4}
						md={4}
						lg={4}
						xl={4}
						
						className={
							clsx(
								classes.textRight,
								classes.fontWeightBold,
							)
						}
					>
						{ props.data.numOfTeachers }
					</Grid>
				</Grid>
				<Grid
					item
					container
					direction="row"
					justify="center"
					alignItems="center"
					
					className={
						clsx(
							classes.padding4X,
						)
					}
				>
					<Grid
						item
						
						xs={8}
						sm={8}
						md={8}
						lg={8}
						xl={8}
						
						className={
							clsx(
								//classes.textCenter,
								
							)
						}
					>
						{
							( 'ulzip' === props.type ) ? 
								(
									'보육교사 1인당 원아 수(명)'
								) : 
								(
									'유치원교사 1인당 원아 수(명)'
								)
						}
					</Grid>
					<Grid
						item
						
						xs={4}
						sm={4}
						md={4}
						lg={4}
						xl={4}
						
						className={
							clsx(
								classes.textRight,
								classes.fontWeightBold,
							)
						}
					>
						{ props.data.ratioTeachersToStudents }
					</Grid>
				</Grid>
			</Grid>
			
			{
				typeDetailInfo
			}
		
			
		</Fragment>
	);
	
}