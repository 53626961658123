import React, { 
	Component,
	Fragment,
} from 'react';

import { //isMobile, 
	//isIOS, 
	isMobileOnly, 
	isTablet,
	//isAndroid, 
	//isBrowser,
	isIPad13, 
} from 'react-device-detect';

import clsx from 'clsx';

import CardList				from '../Utility/CardList';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Box,
	Dialog,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	//linkButtonHover:{
	//	'&:hover' : {
	//		textDecoration : "underline",
	//	},
	//},
	//linkButtonNone:{
	//	textDecoration : "none",
	//},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	overflowYVisible:{
		overflowY:"visible",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	avatar: {
		backgroundColor: red[500],
 	},
	
	dialogPaper: 
		( true === isMobileOnly ) ?
			{
				margin	: theme.spacing(0),
				minWidth: "90vw",
			} :
			{
				margin: theme.spacing(0),
			},
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const WAIT_INTERVAL = 500;




export default function HeadlineNewsDetail ( props ) {
	
	const classes = useStyles();
	
	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = props.cardList.length;
	
	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};
	
	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};
	
	const handleStepChange = step => {
		setActiveStep(step);
	};
	
	
	
	
	const [dialogComponent, setDialogComponent]	= React.useState(null);
	
	const [dialogOpenYN, setDialogOpenYN]	= React.useState(false);
	
	const openDialog = ( ) => {
		setDialogOpenYN(true);
	};
	
	const closeDialog = ( ) => {
		setDialogOpenYN(false);
		setDialogComponent( null );
	};
	
	
	
	
	return (
		<Fragment>
			<div 
				className={
					clsx(
						classes.noGutters,
					)
				}
				
				style={
					( true === isMobileOnly ) ?
						{
							position:"fixed",
							top:"3rem",
							right:0,
							zIndex:"2",
							
							//maxWidth:"400px",
							width : "100%",
						} :
						{
							position:"fixed",
							top:"3rem",
							right:0,
							zIndex:"2",
							
							maxWidth:"400px",
						}
				}
			>
				<AutoPlaySwipeableViews
					axis={
						//( theme.direction === 'rtl' ) ? 
						//	'x-reverse' : 'x'
						
						//'x-reverse'
						'x'
					}
					index={activeStep}
					onChangeIndex={handleStepChange}
					enableMouseEvents
				>
					{
						props.cardList.map((card, index) => {
							
							card.param	= {
								setDialogComponent	: setDialogComponent,
								openDialog	: openDialog,
								closeDialog	: closeDialog,
							};
							
							return (
								<Fragment 
									key={
										"headlineNewsList_" + index.toString()
									}
								>
									{
										( 2 >= Math.abs( activeStep - index ) ) ? 
											(
												<CardList
													id={"headlineNews"}
													data={
														[card]
													}
													cardType={"headlineNews"}
													overflowYScrollYN={false}
												/>
											) : null
									}
								</Fragment>
							);
							
						})
					}
				</AutoPlaySwipeableViews>
			</div>
			
			<Dialog
				open={dialogOpenYN}
				fullWidth={true}
				maxWidth="xs"
				scroll="paper"
				
				PaperProps={
					{ 
						classes	: {
							root: classes.dialogPaper 
						} 
					}
				}
				
				className={
					clsx(
						classes.noMargin,
						classes.padding,
					)
				}
				
				onClose={closeDialog}
			>
				<Fragment>
					{
						dialogComponent
					}
				</Fragment>
			</Dialog>
		</Fragment>
	);
	
}