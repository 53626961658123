import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { 
	//isMobile, 
	//isIOS, 
	isMobileOnly, 
	isTablet,
	//isAndroid, 
	//isBrowser 
} from 'react-device-detect';

//import { 
//	ThemeProvider 
//} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Box,
	//ButtonGroup,
	//Slide,
} from '@material-ui/core';


import {
	Close		as CloseIcon,
	UnfoldLess	as UnfoldLessIcon
} from '@material-ui/icons';

import AutoSearchKeywordResultList	from './AutoSearchKeywordResultList.js';

const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		error		: {
			main:"#c62828",
		},
		warning		: {
			main:"#ffa000",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textCenter:{
		textAlign:"center"
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
}));


function AutoSearchKeywordFooter ( props ) {
	
	const classes	= useStyles();
	
	return (
		<Fragment>
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				
				className={
					clsx(
						classes.marginY,
						classes.shrink,
					)
				}
			>
				<Grid
					item
					
					xs={2}
					sm={2}
					md={2}
					lg={2}
					xl={2}
					
					
					className={
						clsx(
							classes.textCenter
						)
					}
					
					style={{
						lineHeight:"100%",
					}}
				>
					<IconButton
						className={
							clsx(
								classes.button,
								classes.noGutters,
							)
						}
						
						size="small"
						
						onClick={
							(e)=>{
								props.clearSearchKeyword();
							}
						}
					>
						<UnfoldLessIcon
							//CloseIcon
							fontSize="small"
						/>
					</IconButton>
				</Grid>
			</Grid>
		</Fragment>
	);
	
}


export default function AutoSearchKeywordMain ( props ) {
	
	const classes	= useStyles();
	
	//console.log( props.data );
	
	return (
		<Fragment>
			
			<AutoSearchKeywordResultList
				data={props.data}
				moveCenterLoc={props.moveCenterLoc}
				openMarkerFromAutoSearchResult={props.openMarkerFromAutoSearchResult}
			/>
			
			<AutoSearchKeywordFooter
				clearSearchKeyword={props.clearSearchKeyword}
			/>
			
		</Fragment>
	);
	
}

AutoSearchKeywordMain.defaultProps = {
	data : []
};