import React, { Component, Fragment } from 'react';

import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { 
	fade,
	makeStyles,
	withStyles
} from '@material-ui/core/styles';



import {
	Container,
	Grid,
	Button,
	IconButton,
	Typography
} from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	fab: {
		margin: theme.spacing(0),
		
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	padding2Y:{
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	padding2:{
		padding: theme.spacing(2)
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	marginBottom:{
		marginBottom: theme.spacing(1)
	},
	textCenter:{
		textAlign:"center"
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	textLightGrey:{
		color:"#6c757d !important"
	},
	textPrimary:{
		color:"#3f51b5"
	}
}));



export default function CommunityCurrentTitle ( props ) {
	
	const classes	= useStyles();
	
	let communityTabName	= "";
	
	switch ( props.view ) {
		
		case 0 :	// cafe, 카페
			communityTabName	= props.location + '카페';
			communityTabName	= communityTabName.split(' ').slice(1,).join(' ');
			break;
		case 1 :	// news, 뉴스
			communityTabName	= props.location + '뉴스';
			communityTabName	= communityTabName.split(' ').slice(-3,-2).join(' ') + ' 뉴스';
			break;
		default :	// cafe, 카페
			communityTabName	= props.location + '카페';
			communityTabName	= communityTabName.split(' ').slice(1,).join(' ');
			break;
	}
	
	
	//console.log( props.view );
	
	
	return (
		
		<Fragment>
			
			<Container
				className={
					clsx(
						classes.padding2Y
					)
				}
			>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid
						item
						
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						
						className={
							clsx(
								classes.textCenter
							)
						}
					>
						<Typography 
							variant="h6" 
							component="h6"
						>
							{
								communityTabName
							}
						</Typography>
					</Grid>
				</Grid>
			</Container>
			
			
		</Fragment>
		
	);
	
	
}
