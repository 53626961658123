import React, { Component, Fragment } from 'react';

import { 
	isMobileOnly, 
	isTablet,
	isIPad13,
} from 'react-device-detect';

import clsx		from "clsx";

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Box,
	Card,
	CardContent,
	CardHeader,
	CardActions,
	Divider,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
	Favorite		as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
	MoreVert		as MoreVertIcon,
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	avatar: {
		backgroundColor: red[500],
 	},
	
	dialogPaper: 
		( true === isMobileOnly ) ?
			{
				margin	: theme.spacing(0),
				minWidth: "90vw",
			} :
			{
				margin: theme.spacing(0),
			},
}));



export default function PrivacyDetail ( props ) {
	
	const classes	= useStyles();
	
	
	return (
		<Fragment>
			<Box
				//clone={true}
				
				m={0}
				p={0}
				
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				
				bgcolor="white"
				
				style={{
					//width:"100%",
					height:
						(
							true	=== isMobileOnly	||
							true	=== isTablet		||
							true	===	isIPad13	
						) ? "calc( 100vh - 3rem )" : "100vh",
				}}
			>
				{
				//<Container
				//	className={
				//		clsx(
				//			classes.noGutters,
				//		)
				//	}
				//	
				//	style={{
				//		width:"100%",
				//		height:"100%",
				//	}}
				//>
				}
					<div
						className={
							clsx(
								classes.padding,
								classes.shrink,
								//classes.overflowYScroll,
								classes.textCenter,
							)
						}
						
						style={{
							width:"100%",
						}}
					>
						<Typography
							component="h4"
							variant="h4"
							
							className={
								clsx(
									classes.margin2Y,
								)
							}
						>
							{
								"개인정보 처리방침"
							}
						</Typography>
					</div>
					<div
						className={
							clsx(
								classes.padding2,
								classes.grow,
								classes.overflowYScroll,
							)
						}
						
						//style={{
						//	width:"100%",
						//}}
					>
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											"1. 개인정보의 처리 목적"
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"<아이픽>(‘https://i-pick.co.kr’이하 ‘아이픽’) 은(는) 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다."
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"- 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급.배송 등"
									}
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											"2. 개인정보의 처리 및 보유 기간"
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"① <아이픽>(‘https://i-pick.co.kr’이하 ‘아이픽’) 은(는) 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유․ 이용기간 또는 법령에 따른 개인정보 보유․ 이용기간 내에서 개인정보를 처리․보유합니다."	
									}
								</Typography>
								
								<br/>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다."
									}
								</Typography>
								
								<br/>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"☞ 아래 예시를 참고하여 개인정보 처리업무와 개인정보 처리업무에 대한 보유기간 및 관련 법령, 근거 등을 기재합니다."
									}
								</Typography>
								
								<br/>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										"(예시)"
									}
								</Typography>
								
								<br/>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding4X,
										)
									}
								>
									{
										"- 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지"
									}
								</Typography>
								
								<br/>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding4X,
										)
									}
								>
									{
										"- 전자상거래에서의 계약․청약철회, 대금결제, 재화 등 공급기록 : 5년 "	
									}
								</Typography>
								
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											"3. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다."
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"① 정보주체는 아이픽(‘https://i-pick.co.kr’이하 ‘아이픽) 에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다."
									}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"1. 개인정보 열람요구"
									}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"2. 오류 등이 있을 경우 정정 요구"
									}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"3. 삭제요구"
									}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"4. 처리정지 요구"
									}
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											"4. 처리하는 개인정보의 항목 작성"
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"① <아이픽>('https://i-pick.co.kr'이하 '아이픽')은(는) 다음의 개인정보 항목을 처리하고 있습니다."
									}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"1. <회원관리 및 서비스 운영>"
									}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										"- 필수항목 : 이메일, 비밀번호, 로그인ID, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보"
									}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										"- 선택항목 : 생년월일, 이름"
									}
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											"5. 개인정보의 파기<아이픽>('아이픽')은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다."
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"- 파기절차"
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다."
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"- 파기기한"
									}
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다."
									}
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											"6. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항"
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"① 아이픽 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를 사용합니다."
									}
								</Typography>
								<br/>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다."
									}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다."
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다."
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다."
									}
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											"7. 개인정보 보호책임자 작성"
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"① 아이픽(‘https://i-pick.co.kr’이하 ‘아이픽) 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다."
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"▶ 개인정보 보호책임자 "
									}
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
										{
											"성명 :아이픽"
										}
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
										{
											"연락처 :ipick.helpdesk@gmail.com"
										}
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
										{
											"※ 개인정보 보호 담당부서로 연결됩니다."
										}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"② 정보주체께서는 아이픽(‘https://i-pick.co.kr’이하 ‘아이픽) 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 아이픽(‘https://i-pick.co.kr’이하 ‘아이픽) 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다."
									}
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											"8. 개인정보 처리방침 변경"
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										"①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다."
									}
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											"9. 개인정보의 안전성 확보 조치 <아이픽>('아이픽')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다."
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"1. 정기적인 자체 감사 실시"
									}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										"개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다."
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"2. 개인정보 취급 직원의 최소화 및 교육"
									}
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										"개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다."
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"3. 해킹 등에 대비한 기술적 대책"
									}
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										"<아이픽>('아이픽')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다."
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"4. 개인정보의 암호화"
									}
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										"이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다."
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"5. 접속기록의 보관 및 위변조 방지"
									}
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										"개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다."
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding2X,
										)
									}
								>
									{
										"6. 개인정보에 대한 접근 제한"
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										"개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다."
									}
								</Typography>
							</CardContent>
						</Card>
						
					</div>
				{
				//</Container>
				}
				
			</Box>
		</Fragment>
	);
	
}