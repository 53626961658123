import React, {
	Component,
	Fragment
} from 'react';


import { Link } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Fab,
	Box,
	Modal,
	Backdrop,
	Divider,
	TextField,
	InputAdornment,
	FormControl,
	FormLabel,
	FormHelperText,
	FormGroup,
	FormControlLabel,
	InputLabel,
	Input,
	Switch,
	Slide,
	Collapse,
	Grow,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
} from '@material-ui/icons';

import CustomAlert		from '../components/Utility/Alert/CustomAlert.js';

import NewPasswordRegisterDetail	from '../components/User/NewPasswordRegisterDetail.js';

class NewPasswordRegister extends Component {
	
	constructor( props ) {
		
		super( props );
		
		this.state	= {
			
			password:"",
			isValidPassword:false,
			
			passwordConfirm:"",
			isValidPasswordConfirm:false,
			
			isComplete:false,
			msg:"",
			errMsg:"",
			errMsgType:"warning",
			
		};
		
		
		this.typePassword			= this.typePassword.bind(this);
		this.typePasswordConfirm	= this.typePasswordConfirm.bind(this);
		this.passwordReset			= this.passwordReset.bind(this);
		
		this.closeAlert				= this.closeAlert.bind(this);
		this.createErrorMsg			= this.createErrorMsg.bind(this);
		this.setMsg					= this.setMsg.bind(this);
		
	}
	
	
	typePassword( e ) {
		
		e.preventDefault();
		
		
		const regexp_for_password	= /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
		
		
		let tmp_password	= e.target.value;
			
		tmp_password	= tmp_password.replace( regexp_for_password, "" );
		
		if( ""  !== tmp_password ) {
			this.setState({
				password: e.target.value,
				isValidPassword : false
			});
		} else {
			this.setState({
				password: e.target.value,
				isValidPassword : true
			});
		}
		
	}
	
	
	typePasswordConfirm( e ) {
		e.preventDefault();
		
		let flag	= false;
		
		if( this.state.password === e.target.value ) {
			flag	= true;
		} else {
			flag	= false;
		}
		
		this.setState({
			passwordConfirm : e.target.value,
			isValidPasswordConfirm : flag
		});
		
	}
	
	
	async passwordReset( e ) {
		
		let isComplete	= false;
		let msg			= "";
		let errMsg		= "";
		let errMsgType	= "warning";
		
		
		if( 
			false === this.state.isValidPassword	||
			false === this.state.isValidPasswordConfirm 
		) {
			return;
		}
		
		try {
			
			const passwordResetResult	= await axios.post(
				
				"/auth/passwordReset",
				{
					password:this.state.password
				}
				
			);
			
			
			if( "success" === passwordResetResult.data.result ) { 
				
				isComplete	= true;
				msg			= passwordResetResult.data.msg;
				errMsgType	= "success";
				
			} else {
				isComplete	= false;
				errMsg		= passwordResetResult.data.msg;
				errMsgType	= "warning";
			}
			
		} catch( err ) {
			
			console.log( err );
			
			isComplete	= false;
			errMsg			= "새로운 비밀번호 전송 중 오류가 발생했습니다.";
			errMsgType	= "warning";
			
		} finally {
			
			this.setState({
				isComplete:isComplete,
			});
			
			if ( true === isComplete ) {
				this.createErrorMsg(
					msg,
					errMsgType
				);
				
				this.props.history.replace("/m");
				
			} else {
				if ( "" !== errMsg ) {
					this.createErrorMsg(
						errMsg,
						errMsgType
					);
				}
			}
			
		}
		
	}
	
	
	/**
	 *	에러 메시지 닫기 버튼 클릭 시 호출되는 메소드...
	 */
	closeAlert ( ) {
		this.setState({
			errMsg: ''
		});
	}

	/**
	 *	에러 메시지를 띠우는 메소드...
	 */
	createErrorMsg ( 
		errMsg,
		errMsgType, 
	) {
		this.setState({
			errMsg: errMsg,
			errMsgType:errMsgType,
		});
		
		//setTimeout(this.closeAlert, 3000);
	}
	
	
	setMsg ( msg ) {
		this.setState({
			msg: msg
		});
	}
	
	
	render ( ) {
		
		
		return(
			<Container
				fixed={true}
				maxWidth="xl"
				
				style={{
					//height:"calc( 100vh - 3.25rem )",
					height:"calc( 100vh - 3rem )",
					margin:0,
					padding:0,
					
					
					display:"flex",
					flexDirection:"column",
					//overflow:"hidden",
					//zIndex:2,
				}}
			>
				<CustomAlert
					open={this.state.errMsg.length > 0}
					data={this.state.errMsg} 
					option={this.state.errMsgType}
					closeAlert={this.closeAlert} 
				/>
				
				<NewPasswordRegisterDetail
					typePassword={this.typePassword}
					typePasswordConfirm={this.typePasswordConfirm}
					
					errMsg={this.state.errMsg}
					msg={this.state.msg}
					
					isValidPassword={this.state.isValidPassword}
					isValidPasswordConfirm={this.state.isValidPasswordConfirm}
				/>
				
			</Container>
		);
	}
	
}

export default NewPasswordRegister;