import 
	React, 
	{ 
		Fragment
	} 
from 'react';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

import {
	Event,
} from '../Utility/Tracking.js';

import KakaoLink			from '../Utility/SNS/KakaoLink.js';
import FacebookShare		from '../Utility/SNS/FacebookShare.js';
import { CopyToClipboard }	from 'react-copy-to-clipboard';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Box,
	Grid,
	Button,
	Typography,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon		from '@material-ui/core/Icon';

//import {
//	//Close		as CloseIcon,
//	//ChevronLeft		as ChevronLeftIcon,
//	//ChevronRight	as ChevronRightIcon,
//	//Visibility,
//	//VisibilityOff,
//	//UnfoldLess	as UnfoldLessIcon,
//	//ExpandMore	as ExpandMoreIcon,
//	//Favorite		as FavoriteIcon,
//	//FavoriteBorder	as FavoriteBorderIcon,
//	//ArrowBackIos	as ArrowBackIosIcon
//} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRightSmall:{
		marginRight:theme.spacing(0.5),
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
}));



const SharingInfoCardContent	= withStyles({
	root	: {
		"&:last-child"	: {
			paddingBottom	: 0,
		}
	},
})(CardContent);

export default function SharingInfoWithFriend ( props ) {
	
	const classes	= useStyles();
	
	//console.log(window.location.protocol);	//https:
	//console.log(window.location.hostname);	//ulziphow.run.goorm.io
	
	return (
		<Fragment>
			
			{
				( 
					false	===	props.ratingYN	&&
					true	===	props.userInfo.loginYN	// 로그인 안했을 때도 보여주면 후기작성 하면 더 많이 볼 수 있다는 글과 중복으로 느껴져서...
				) ?
					(
						<ThemeProvider
							theme={markerInfoTheme}
						>
							<Box
								border={2}
								borderRadius={16}
								borderColor={
									( "ulzip" === props.type ) ?
										"secondary.main" :
										(
											( "kinder" === props.type ) ?
												"primary.main" : ""
										)
								}
								
								clone={true}
							>
								<Card
									className={
										clsx(
											//classes.noMargin,
											classes.margin,
											classes.padding,
										)
									}
								>
									<SharingInfoCardContent
										//CardContent
										className={
											clsx(
												//classes.noMargin,
												//classes.padding,
												classes.noGutters,
											)
										}
									>
										<Grid
											container
											direction="row"
											justify="flex-end"
											alignItems="center"
										>
											{
												( false === props.userInfo.loginYN ) ?
													(
														<Fragment>
															<Grid
																item
																
																xs={9}
																sm={9}
																md={9}
																lg={9}
																xl={9}
																
																className={
																	clsx(
																		classes.paddingSmallX,
																	)
																}
															>
																<Typography
																	variant="body2"
																	color="inherit"
																	component="p"
																	
																>
																	{
																		"로그인을 하시고 '아이픽'을 공유해 주시면, 더 많은 후기를 확인하실 수 있어요!"
																		//( "ulzip" === props.type ) ?
																		//	"로그인 후 지인에게 어린이집을 공유하면 더 많은 후기를 확인하실 수 있어요!"	:
																		//	(
																		//		( "kinder" === props.type ) ?
																		//			"로그인 후 지인에게 유치원을 공유하면 더 많은 후기를 확인하실 수 있어요!" : 
																		//			"로그인 후 지인에게 공유하면 더 많은 후기를 확인하실 수 있어요!"
																		//	)
																	}
																</Typography>
															</Grid>
															
															<Grid
																item
																
																xs={3}
																sm={3}
																md={3}
																lg={3}
																xl={3}
																
																className={
																	clsx(
																		classes.textCenter,
																	)
																}
															>
																<Button
																	variant="outlined"
																	
																	className={
																		clsx(
																			classes.smallButton,
																		)
																	}
																>
																	{"로 그 인"}
																</Button>
															</Grid>
														</Fragment>
													) :
													(
														<Fragment>
															<Grid
																item
																
																xs={
																	(
																		true	===	isMobileOnly	||
																		true	===	isTablet
																	) ?
																		8 : 9
																}
																sm={
																	(
																		true	===	isMobileOnly	||
																		true	===	isTablet
																	) ?
																		8 : 9
																}
																md={
																	(
																		true	===	isMobileOnly	||
																		true	===	isTablet
																	) ?
																		8 : 9
																}
																lg={
																	(
																		true	===	isMobileOnly	||
																		true	===	isTablet
																	) ?
																		8 : 9
																}
																xl={
																	(
																		true	===	isMobileOnly	||
																		true	===	isTablet
																	) ?
																		8 : 9
																}
															>
																<Typography
																	variant="body2"
																	color="inherit"
																	component="p"
																	
																>
																	{
																		"아이픽을 공유해 주시면 더 많은 후기를 확인하실 수 있어요!"
																				//( "ulzip" === props.type ) ?
																				//	"어린이집을 공유하면 더 많은 후기를 확인하실 수 있어요!" :
																				//	(
																				//		( "kinder" === props.type ) ?
																				//			"유치원을 공유하면 더 많은 후기를 확인하실 수 있어요!" : 
																				//			"지인에게 공유하면 더 많은 후기를 확인하실 수 있어요!"
																				//	)
																	}
																</Typography>
															</Grid>
															
															{
																( 
																	true	===	isMobileOnly	||
																	true	===	isTablet
																) ?
																	(
																		<Fragment>
																			<Grid
																				item
																				
																				xs={2}
																				sm={2}
																				md={2}
																				lg={2}
																				xl={2}
																			>
																				<KakaoLink
																					mobileWebUrl={
																						//window.location.href +
																						window.location.protocol + "//"+window.location.hostname+'/'+
																						'friendShare?share=' +
																						props.userInfo.friendShareLink+"&link=kakao"
																					}
																					
																					webUrl={
																						//window.location.href +
																						window.location.protocol + "//"+window.location.hostname+'/'+
																						'friendShare?share=' +
																						props.userInfo.friendShareLink+"&link=kakao"
																					}
																				/>
																			</Grid>
																			<Grid
																				item
																				
																				xs={2}
																				sm={2}
																				md={2}
																				lg={2}
																				xl={2}
																			>
																				<FacebookShare
																					url={
																						window.location.protocol + "//"+window.location.hostname+'/'+
																						'friendShare?share=' +
																						props.userInfo.friendShareLink+"&link=facebook"
																					}
																					title={'어린이집/유치원 후기가 궁금할땐? 아이픽!'}
																				/>
																			</Grid>
																		</Fragment>
																	) :
																	(
																		<Grid
																			item
																			
																			xs={3}
																			sm={3}
																			md={3}
																			lg={3}
																			xl={3}
																		>
																			<CopyToClipboard
																				text={
																					//window.location.href +
																					window.location.protocol + "//" + 
																					window.location.hostname+'/' +
																					'friendShare?share=' +
																					props.userInfo.friendShareLink+"&link=direct"
																				}
																			>
																				{
																				//<ThemeProvider
																				//	theme={markerInfoTheme}
																				//>
																				}
																					<Button 
																						variant="outlined" 
																						color={
																							( "ulzip" === props.type ) ?
																								"secondary" : "primary"
																						}
																						
																						className={
																							clsx(
																								classes.button,
																							)
																						}
																						onClick={
																							(e) => {
																								Event("ACTION", "Friend Share", "Friend Share Link Copy");
																								
																								props.createErrorMsg(
																									"링크가 복사되었습니다. 지인에게 공유하세요.",
																									"info"
																								);
																							}
																						}
																					>
																						링크 COPY
																					</Button>
																				{
																				//</ThemeProvider>
																				}
																			</CopyToClipboard>
																		</Grid>
																	)
															}
														</Fragment>
													)
											}
										</Grid>
									</SharingInfoCardContent>
								</Card>
							</Box>
						</ThemeProvider>
					) : null
			}
			
		</Fragment>
	);
	
}