import React, {
	Component,
	Fragment
} from 'react';

import axios from 'axios';

import clsx from 'clsx';

import { 
	isMobile, 
	isIOS, 
	isMobileOnly, 
	isTablet, 
	isAndroid, 
	isBrowser,
	isIPad13,
} from 'react-device-detect';

import {
	
	Box,
	Container,
	
} from '@material-ui/core';

import AdSense			from 'react-adsense';
// import {Adsense} from '@ctrl/react-adsense';
import AdvertisementDetail	from './AdvertisementDetail';

import GoogleAdvertise from './GoogleAdvertise';

//const AdTimeInterval	= 10000;
const AdTimeInterval	= 5000;

class Advertisement extends Component {
	
	constructor( props ) {
		
		super( props );
		
		this.state	= {
			
			ads_list	: null,
			current_ad	: null,
			
		};
		
		this.getAdvertisement	= this.getAdvertisement.bind(this);
		this.selectAd			= this.selectAd.bind(this);
		
	}
	
	async componentDidMount ( ) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		let result		= null;
		
		try {
			const ads_ret	= await this.getAdvertisement(  
				{
					type		: this.props.type,
					position	: this.props.position,
				}
			);
			
			//console.log( ads_ret.data );
			
			if (
				"success" === ads_ret.data.result
			) {
				errMsgType	= "success";
				result	= ads_ret.data.data;
			} else {
				errMsgType	= "warning";
			}
			
		} catch ( err ) {
			console.log( err );
			errMsgType	= "error";
		} finally {
			
			//console.log( result );
			
			
			//console.log( errMsgType );
			//console.log( this.props.type );
			//console.log( this.props.position );
			
			if ( 
				null		!==	result	&&
				undefined	!==	result	&&
				0			<	result.length	&&
				"success"	===	errMsgType
			) {
				
				if (
					"middle" === this.props.position
				) {
					
					//console.log( result );
					result	= result.filter( ( data ) => ( true === data.status ) );
					
					result	= result.map( ( data ) => {
						
						const temp_ads_array	= data.ads_array.filter(
							( data ) => ( 0 === data.rank && true === data.status )
						);
						
						return (
							{
								ads_array	: temp_ads_array,
								expose_rate	: data.expose_rate,
								status		: data.status,
								_id			: data._id,
								company_name: data.company_name,
								start_date	: data.start_date, 
								end_date	: data.end_date,
								ads_position: data.ads_position,
								ads_type	: data.ads_type,
							}
						);
						
					});
					
					result	= result.map( ( data ) => {
						
						//console.log( data );
						
						return (
							{	
								name		: data.ads_array[0].name,
								width		: data.ads_array[0].width,
								height		: data.ads_array[0].height,
								image_url	: data.ads_array[0].image_url,
								link		: data.ads_array[0].banner_link,
								rank		: data.ads_array[0].rank,
								status		: data.ads_array[0].status,
								
								company_name: data.company_name,
								
								_id			: data._id,
								start_date	: data.start_date,
								end_date	: data.end_date,
								ads_position: data.ads_position,
								ads_type	: data.ads_type,
								
								expose_rate	: data.expose_rate,
							}
						);
						
					});
					
					//console.log( result );
					
					this.setState({
						ads_list	: result,
						current_ad	: result[0],
					});
					
				} else if (
					"bottom" === this.props.position
				) {
					result	= result.filter( ( data ) => ( true === data.status ) );
					
					result	= result.map( ( data ) => {
						
						const temp_ads_array	= data.ads_array.filter(
							( data ) => ( true === data.status )
						);
						
						return (
							{
								ads_array	: temp_ads_array,
								expose_rate	: data.expose_rate,
								status		: data.status,
								_id			: data._id,
								company_name: data.company_name,
								start_date	: data.start_date, 
								end_date	: data.end_date,
								ads_position: data.ads_position,
								ads_type	: data.ads_type,
							}
						);
						
					});
					
					//console.log( result );
					
					
					result	= result.map( ( data ) => {
						
						//console.log( data );
						const temp_ads_array	= data.ads_array.map(
							( data ) => (
								{
									name 		: data.name,
									width		: data.width,
									height		: data.height,
									image_url	: data.image_url,
									link		: data.banner_link,
									rank		: data.rank,
									status		: data.status,
								}
							)
						);
						
						return (
							{
								ads_array	: temp_ads_array,
								
								_id			: data._id,
								company_name: data.company_name,
								start_date	: data.start_date,
								end_date	: data.end_date,
								ads_position: data.ads_position,
								ads_type	: data.ads_type,
								
								expose_rate	: data.expose_rate,
							}
						);
						
					});
					
					for ( let i = 0 ; i < result.length ; i++ ) {
						
						if ( 0 === i ) {
							result[i].expose_rate_index	= Math.floor( result[i].expose_rate * 10 );
						} else {
							result[i].expose_rate_index	= 
								result[i-1].expose_rate_index + Math.floor( result[i].expose_rate * 10 );
						}
						
					}
					
					this.setState({
						ads_list	: result,
						current_ad	: {
							name		: result[0].ads_array[0].name,
							width		: result[0].ads_array[0].width,
							height		: result[0].ads_array[0].height,
							image_url	: result[0].ads_array[0].image_url,
							link		: result[0].ads_array[0].link,
							rank		: result[0].ads_array[0].rank,
							status		: result[0].ads_array[0].status,
							
							company_name: result[0].company_name,
							_id			: result[0]._id,
							start_date	: result[0].start_date,
							end_date	: result[0].end_date,
							ads_position: result[0].ads_position,
							ads_type	: result[0].ads_type,
							
							expose_rate	: result[0].expose_rate,
						},
					});
					
					//console.log( this.state.current_ad );
					
				} else {
					
				}
				
				
				if (
					"bottom" === this.props.position
				) {
					setTimeout( 
						this.selectAd, 
						AdTimeInterval
					);
				}
				
			}
			
		}
		
		
	}
	
	componentDidUpdate( prevProps, prevState ) {
		
		if ( 
			null						!==	prevState.current_ad		&&
			prevState.current_ad._id	!== this.state.current_ad._id	&&
			"bottom" === this.props.position
		) {
			setTimeout( this.selectAd, AdTimeInterval );
		}
		
	}
	
	async getAdvertisement ( param ) {
		
		//param	= {
		//	type		: ""
		//	position	: "middle", "bottom",
		//	others		: null,
		//}
		
		let errMsg		= "";
		let errMsgType	= "warning";
		let result		= null;

		
		if (  
			null		!==	param	&&
			undefined	!==	param	&&
			""			!==	param	&&
			(
				"middle"	===	param.position	||
				"bottom"	===	param.position	
			)
		) {
			
			try {
				
				if ( "middle" === param.position ) {
					
					return await axios.get(
						
						"/api/getAds?ads_position=" + "app_middle"
						
					);
					
				} else if ( "bottom"	===	param.position	 ) {
					
					return await axios.get(
						
						"/api/getAds?ads_position=" + "app_bottom"
						
					);
					
				} else {
					return null;
				}
				
			} catch ( err ) {
				console.log( err );
				//errMsg		= "광고 다운로드 중 오류가 발생했습니다."
				errMsgType	= "error";
				
				return null;
			} 
			
		} else {
			//errMsg		= "광고 다운로드 중 오류가 발생했습니다."
			//errMsgType	= "error";
			return null;
		}
		
	}
	
	selectAd ( ) {

		const randomNum	= Math.floor(Math.random() * 10) + 1;
		// 1 <= randomNum <= 10
		let current_ad	= null;
		
		//console.log( this.state.ads_list );
		
		for ( let i = 0 ; i < this.state.ads_list.length ; i++  ) {
			
			if (
				0 === i
			) {
				if ( randomNum <= this.state.ads_list[i].expose_rate_index ) {
					const temp_current_ad	= this.state.ads_list[i];
					//current_ad	= temp_current_ad;
					
					current_ad	= {
						name 		: temp_current_ad.ads_array[0].name,
						width		: temp_current_ad.ads_array[0].width,
						height		: temp_current_ad.ads_array[0].height,
						image_url	: temp_current_ad.ads_array[0].image_url,
						link		: temp_current_ad.ads_array[0].link,
						rank		: temp_current_ad.ads_array[0].rank,
						status		: temp_current_ad.ads_array[0].status,
						
						
						//company_name: temp_current_ad.company_name,
						_id			: temp_current_ad._id,
						start_date	: temp_current_ad.start_date,
						end_date	: temp_current_ad.end_date,
						ads_position: temp_current_ad.ads_position,
						ads_type	: temp_current_ad.ads_type,
						
						expose_rate	: temp_current_ad.expose_rate,
					};
					
					break;
				}
			} else {
				if (
					this.state.ads_list[i-1].expose_rate_index < randomNum	&&
					randomNum <= this.state.ads_list[i].expose_rate_index 
				) {
					const temp_current_ad	= this.state.ads_list[i];
					//current_ad	= temp_current_ad;
					
					current_ad	= {
						name		: temp_current_ad.ads_array[0].name,
						width		: temp_current_ad.ads_array[0].width,
						height		: temp_current_ad.ads_array[0].height,
						image_url	: temp_current_ad.ads_array[0].image_url,
						link		: temp_current_ad.ads_array[0].link,
						rank		: temp_current_ad.ads_array[0].rank,
						status		: temp_current_ad.ads_array[0].status,
						
						
						//company_name: temp_current_ad.company_name,
						_id			: temp_current_ad._id,
						start_date	: temp_current_ad.start_date,
						end_date	: temp_current_ad.end_date,
						ads_position: temp_current_ad.ads_position,
						ads_type	: temp_current_ad.ads_type,
						
						expose_rate	: temp_current_ad.expose_rate,
					};
					
					break;
				}
			}
			
		}
		
		if ( 
			null			!==	current_ad &&
			current_ad._id	!==	this.state.current_ad._id
		) {
			console.log ( 
				"current_ad id : " + current_ad._id 
			);
			console.log ( 
				"current_ad img url : " + current_ad.image_url 
			);
			//console.log( this.state.current_ad );
			this.setState({
				current_ad : current_ad,
			});
		} else {
			console.log ( "current_ad is not changed..." );
			console.log ( 
				"current_ad img url : " + current_ad.image_url 
			);
			setTimeout( this.selectAd, AdTimeInterval );
		}
		
	}
	
	

	render ( ) {
		
		//console.log( this.state.current_ad );
		const iframePart_coupang = () => {
            return {
              __html: '<iframe src="https://ads-partners.coupang.com/widgets.html?id=563719&template=carousel&trackingCode=AF4472397&subId=&width=320&height=100" width="320" height="100" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>',
            };
          };
       const GAds = () => { return ( <div>  <GoogleAdvertise client="ca-pub-9712191476596348" slot="6333843012" format="auto" responsive="true" layoutKey="" /> </div> ); };



		return (
			<Fragment>
				{
					(
						null		!==	this.state.current_ad	&&
						undefined	!==	this.state.current_ad
					) ? 
						(
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								flexDirection="column"
								
								m={0}
								p={0}
							>
								<Box
									flexGrow={1}
									
									m={0}
									p={0}
									
									style={{
										width:"100%",
										
										//width:"calc( 100% - 16px )",
										//padding:"8px",
									}}
								>
									<AdvertisementDetail
										type={this.props.type}
										position={this.props.position}
										data={
											this.state.current_ad
										}
									/>
								</Box>
							</Box>
						) :
						(
							<Box
								//상세탭중간배너
								//borderTop={7}
								//borderColor="grey.300"
								
								style={{
                                display: 'flex',  justifyContent:'center', alignItems:'center',//추가
									margin:0,
									//height:'100px',
									//width:"100%",
									borderColor	: "rgb(224, 224, 224)",
								}}
							>
                          {
                            
						
								// <Adsense
								// 	client='ca-pub-9712191476596348'
								// 	slot='1388569643'
								// 	style={{ display: 'block' }}
								// layout="in-article"
								// format="fluid"
								// />
                                
                                

                                
                                   //           <div
                                    //style={{display: 'flex',  justifyContent:'center', alignItems:'center', margin: 'auto', position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}
                                    //dangerouslySetInnerHTML={iframePart()}
                                    //></div>                  
                                
                                /*                     	 <Adsense
								 	client='ca-pub-9712191476596348'
								 	slot='1388569643'
								 	style={{ display: 'block' }}
								 layout="in-article"
								 format="fluid"
								 />*/
                                
                                //기존에 쓰던 광고 20220304 lss
                                /*
                                <AdSense.Google
								 	client='ca-pub-9712191476596348'
								 	slot='1388569643'
								 	format=''
									style={{ 
								 		width: 450, 
								 		height: 50, 
								 		float:'left',
								 		display: 'inline-block' 
								 	}}
								 />   */
                                
                                /*
                                 최신광고 운영에 안나옴 240815
                                <AdSense.Google
								 	client='ca-pub-9712191476596348'
								 	slot='5373708815'
								 	format='fluid'
									style={{ 
								 		width: '100%', 
								 		float:'left',
								 		display: 'block' 
								 	}} 
                            		
                                layoutKey="-fb+5w+4e-db+86"
								 /> 
                                */
                            }
                     <AdSense.Google
								 	client='ca-pub-9712191476596348'
								 	slot='5373708815'
								 	format='fluid'
									style={{ 
								 		width: '100%', 
								 		float:'left',
								 		display: 'block' 
								 	}} 
                            		
                                layoutKey="-fb+5w+4e-db+86"
								 /> 		
                            
                            
                  
                              
                        </Box>
                            
						)
				}
			</Fragment>
		);
		
	}
	
}


export default Advertisement;