import React, { Component, Fragment } from 'react';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import {
	Card,
	CardActions,
	CardContent,
	Button,
	Typography,
	CardActionArea,
	CardMedia,
} from '@material-ui/core';

import CommunityMenuOld from '../components/Community/CommunityMenuOld';


const useStyles = makeStyles({
	card: {},
	media: {
		height: 140
	}
});

const NewsBody = ({ isLoading, contents }) => {
	const classes = useStyles();
	function strip(html)
	{
	   var tmp = document.createElement("DIV");
	   tmp.innerHTML = html;
	   return tmp.textContent || tmp.innerText || "";
	}
	return (
		<div>
			{
				( !isLoading ) ? 
					(
						contents.by_sigungu.items.map(
							( post ) => {
								const { title, link, description, originallink } = post;
								return (
									<Card 
										className={classes.card}
									>
										<CardActionArea 
											onClick={()=> window.open(link, "_blank")}
										>
											<CardContent>
												<Typography 
													gutterBottom variant="h5" 
													component="h2"
												>
													{strip(title)}
												</Typography>
												<Typography 
													variant="body2" 
													color="textSecondary" 
													component="p"
												>
													{strip(description+' 더 보기')}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								);
							}
						)
					) : 
					(
						<p>Loading...</p>
					)
			}
		</div>
	);
};


class News extends Component {
	
	constructor( props ) {
		
		super( props );
		
		this.state	= {
			contents: [],
			isLoading: true,
			errors: null
		};
		
	}
	
	// Let's our app know we're ready to render the data
	componentDidMount() {
		this.getPosts();
	}
	
	
	// Now we're going to make a request for data using axios
	getPosts() {
		axios
			// This is where the data is hosted
			.get('/api/getNews?address_depth_1=서울특별시&address_depth_2=강서구&name=어린이집&limit=20')
			// Once we get a response and store data, let's change the loading state
			.then(response => {
				this.setState({
					contents: response.data.content,
					isLoading: false
				});
				console.log(this.state.contents);
			})
			// If we catch any errors connecting, let's update accordingly
			.catch(error => this.setState({ error, isLoading: false }));
	}
	

	strip_tags(input, allowed) {
		allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
		const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
		return input.replace(
			tags,
			($0, $1) => (allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '')
		);
	}

	render() {
		const { isLoading, contents } = this.state;
		
		return (
			
			<Fragment>
				<CommunityMenuOld 
					name={"우리동네 어린이집 뉴스"}
				/>
				<NewsBody 
					isLoading={isLoading} 
					contents={contents} 
				/>
			</Fragment>
			
		);
	}
}

export default News;