import React, { Component, Fragment } from 'react';

import clsx from 'clsx';

import {
	isMobileOnly,
	isTablet
} from 'react-device-detect';

import {

	Box,
	Container,
	Grid,
	FormControl,
	
	Slide,
	FormGroup,
	FormControlLabel,
	Checkbox,
	
	Paper,
	Typography,
	
	IconButton
	
} from '@material-ui/core';

import {
	Close		as CloseIcon,
	UnfoldLess	as UnfoldLessIcon,
	DoubleArrow	as DoubleArrowIcon,
} from '@material-ui/icons';



class FilterPanel extends Component {
	
	
	constructor( props ) {
		
		super( props );
		
		
		this.state	=	{
			
			// 유형
			all_type : true,	// 전체
			ulzip_type : false,	// 얼집
			kinder_type : false,	// 유치원
			
			ulzip_type_all		: true,		// 전체
			ulzip_type_gov		: false,	// 국공립
			ulzip_type_civil	: false,	// 민간
			ulzip_type_home		: false,	// 가정
			ulzip_type_cooper	: false,	// 협동
			ulzip_type_etc		: false,	// 기타
			
			kinder_type_all			: true,
			kinder_type_gov			: false,	// 공립(병설)
			kinder_type_gov_alone	: false,	// 공립(단설)
			kinder_type_pri_indv	: false,	// 사립(사인)	private_individual
			kinder_type_pri_comp	: false,	// 사립(법인)	private_company
			kinder_type_etc			: false		// 기타
			
		};
		
		
		this.clickType			= this.clickType.bind(this);
		this.clickUlzipType		= this.clickUlzipType.bind(this);
		this.clickKinderType	= this.clickKinderType.bind(this);
		
	}
	
	
	
	clickType( e ) {
		
		//e.preventDefault();
		
		//console.log( e.target.value );
		
		if( "all_type" === e.target.value ) {
			
			const tmp_all_type	= e.target.checked;
			
			//console.log( tmp_all_type );
			
			if( tmp_all_type ) {
				
				if( 
					false === this.state.ulzip_type		&&
					true === this.state.kinder_type
				) {
					this.setState({
						all_type : e.target.checked,
						ulzip_type : false,
						kinder_type : false,
						
						ulzip_type_all		: true,	
						ulzip_type_gov		: false,	
						ulzip_type_civil	: false,
						ulzip_type_home		: false,
						ulzip_type_cooper	: false,
						ulzip_type_etc		: false
						
					});
					
					this.props.filteringMarkerByType( { "ulzip_type" : e.target.checked } );
					
				} else if (
					true === this.state.ulzip_type		&&
					false === this.state.kinder_type
				) {
					this.setState({
						all_type : e.target.checked,
						ulzip_type : false,
						kinder_type : false,
						
						kinder_type_all		: true,
						kinder_type_pri_indv	: false,	// 사립(사인)	private_individual
						kinder_type_pri_comp	: false,	// 사립(법인)	private_company
						kinder_type_gov			: false,	// 공립(병설)
						kinder_type_gov_alone	: false,	// 공립(단설)
						kinder_type_etc			: false		// 기타
					});
					
					this.props.filteringKinderMarkerByType( { "kinder_type" : e.target.checked } );
					
				} else {
					this.setState({
						all_type : e.target.checked,
						ulzip_type : false,
						kinder_type : false
					});
					
				}
				
			} else {
				
				this.setState({
					all_type:true
				});
				
			}
		
		} else {
			
			const tmp_not_all_type	= e.target.checked;
			
			// 전체가 아닌 것을 클릭한 것이 true가 될 때
			if( tmp_not_all_type ) {
				
				if( false !== this.state.all_type ) {
					
					if( "ulzip_type" === e.target.value ) {
						
						this.setState({
							ulzip_type : e.target.checked,
							all_type : false
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type" : !e.target.checked } );
						
					} else {
						
						this.setState({
							kinder_type : e.target.checked,
							all_type : false
						});
						
						this.props.filteringMarkerByType( { "ulzip_type" : !e.target.checked } );
						
					}
					
					
				} else {
					
					
					if( "ulzip_type" === e.target.value ) {
						
						this.setState({
							ulzip_type : e.target.checked,
							
							ulzip_type_all		: true,	
							ulzip_type_gov		: false,	
							ulzip_type_civil	: false,
							ulzip_type_home		: false,
							ulzip_type_cooper	: false,
							ulzip_type_etc		: false
						});
						
						this.props.filteringMarkerByType( { "ulzip_type" : e.target.checked } );
						
					} else {
						
						this.setState({
							kinder_type : e.target.checked,
							
							kinder_type_all		: true,
							kinder_type_pri_indv	: false,	// 사립(사인)	private_individual
							kinder_type_pri_comp	: false,	// 사립(법인)	private_company
							kinder_type_gov			: false,	// 공립(병설)
							kinder_type_gov_alone	: false,	// 공립(단설)
							kinder_type_etc			: false		// 기타
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type" : e.target.checked } );
						
					}
					
				}
				
			} else {
				// 전체가 아닌 것을 클릭한 것이 false가 될 때
				
				if( "ulzip_type" === e.target.value ) {
					
					if(  
						false === this.state.all_type	&&	
						false === e.target.checked		&&
						false === this.state.kinder_type	
					) {
						this.setState({
							ulzip_type : true
						});
						
					} else {
						this.setState({
							ulzip_type : e.target.checked,
							
							ulzip_type_all		: false,	
							ulzip_type_gov		: false,	
							ulzip_type_civil	: false,
							ulzip_type_home		: false,
							ulzip_type_cooper	: false,
							ulzip_type_etc		: false
						});
						
						this.props.filteringMarkerByType( { "ulzip_type" : e.target.checked } );
					}
					
					
				} else {
					
					if(  
						false === this.state.all_type	&&	
						false === this.state.ulzip_type	&&
						false === e.target.checked	
					) {
						this.setState({
							kinder_type : true
						});
						
					} else {
						this.setState({
							kinder_type : e.target.checked,
							
							kinder_type_all		: false,
							kinder_type_pri_indv	: false,	// 사립(사인)	private_individual
							kinder_type_pri_comp	: false,	// 사립(법인)	private_company
							kinder_type_gov			: false,	// 공립(병설)
							kinder_type_gov_alone	: false,	// 공립(단설)
							kinder_type_etc			: false		// 기타
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type" : e.target.checked } );
					}
					
				}
				
			}
			
		}
		
	}
	
	
	clickUlzipType( e ) {
		
		//e.preventDefault();
		//console.log( e.target.value );
		//console.log( e.target.checked );
		
		//all_type : true,	// 전체
		//ulzip_type : false,	// 얼집
		//kinder_type : false,	// 유치원
		if (  
			true	!==	this.state.all_type	&&
			true	!==	this.state.ulzip_type
		) {
			return;
		}
		
		if( "ulzip_type_all" === e.target.value ) {
			
			const tmp_ulzip_type_all	= e.target.checked;
			
			//console.log( tmp_ulzip_type_all );
			
			if( tmp_ulzip_type_all ) {
				
				this.setState({
				
					ulzip_type_all : e.target.checked,
					ulzip_type_gov : false,
					ulzip_type_civil : false,
					ulzip_type_home : false,
					ulzip_type_cooper : false,
					ulzip_type_etc : false
				
				});
				
				this.props.filteringMarkerByType( { "ulzip_type_all" : e.target.checked } );
				
			} else {
				
				
				this.setState({
					ulzip_type_all:true
				});
				
				this.props.createErrorMsg( 
					"적어도 하나의 유형을 선택해 주세요 ^^",
					"warning"
				);
				
			}
			
		} else {
			
			const tmp_ulzip_type_not_all	= e.target.checked;
			
			
			if( tmp_ulzip_type_not_all ) {
				
				if( false !== this.state.ulzip_type_all ) {
					
					if( "ulzip_type_gov" === e.target.value ) {
						
						this.setState({
							ulzip_type_gov : e.target.checked,
							ulzip_type_all : false
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_gov" : e.target.checked } );
						
					} else if( "ulzip_type_civil" === e.target.value ) {
						
						this.setState({
							ulzip_type_civil : e.target.checked,
							ulzip_type_all : false
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_civil" : e.target.checked } );
						
					} else if( "ulzip_type_home" === e.target.value ) {
						
						this.setState({
							ulzip_type_home : e.target.checked,
							ulzip_type_all : false
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_home" : e.target.checked } );
						
					} else if( "ulzip_type_cooper" === e.target.value ) {
						
						this.setState({
							ulzip_type_cooper : e.target.checked,
							ulzip_type_all : false
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_cooper" : e.target.checked } );
						
					} else {
						
						this.setState({
							ulzip_type_etc : e.target.checked,
							ulzip_type_all : false
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_etc" : e.target.checked } );
						
					}
					
					
					
				} else {
					
					
					if( "ulzip_type_gov" === e.target.value ) {
						
						this.setState({
							ulzip_type_gov : e.target.checked
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_gov" : e.target.checked } );
						
					} else if( "ulzip_type_civil" === e.target.value ) {
						
						this.setState({
							ulzip_type_civil : e.target.checked
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_civil" : e.target.checked } );
						
					} else if( "ulzip_type_home" === e.target.value ) {
						
						this.setState({
							ulzip_type_home : e.target.checked
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_home" : e.target.checked } );
						
					} else if( "ulzip_type_cooper" === e.target.value ) {
						
						this.setState({
							ulzip_type_cooper : e.target.checked
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_cooper" : e.target.checked } );
						
					} else {
						
						this.setState({
							ulzip_type_etc : e.target.checked
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_etc" : e.target.checked } );
						
					}
					
				}
				
			} else {
				
				
				if( "ulzip_type_gov" === e.target.value ) {
					
					if(  
						false === this.state.ulzip_type_all		&&	
						false === e.target.checked		&&
						false === this.state.ulzip_type_civil	&&
						false === this.state.ulzip_type_home	&&
						false === this.state.ulzip_type_cooper	&&
						false === this.state.ulzip_type_etc		
					) {
						this.setState({
							ulzip_type_gov : true
						});
						
						this.props.createErrorMsg( 
							"적어도 하나의 유형을 선택해 주세요^^",
							"warning"
						);
						
					} else {
						this.setState({
							ulzip_type_gov : e.target.checked
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_gov" : e.target.checked } );
					}
					
					
				} else if( "ulzip_type_civil" === e.target.value ) {
					
					if(  
						false === this.state.ulzip_type_all		&&	
						false === this.state.ulzip_type_gov	&&
						false === e.target.checked		&&
						false === this.state.ulzip_type_home	&&
						false === this.state.ulzip_type_cooper	&&
						false === this.state.ulzip_type_etc		
					) {
						this.setState({
							ulzip_type_civil : true
						});
						
						this.props.createErrorMsg( 
							"적어도 하나의 유형을 선택해 주세요^^" ,
							"warning"
						);
					} else {
						this.setState({
							ulzip_type_civil : e.target.checked
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_civil" : e.target.checked } );
					}	
					
					
				} else if( "ulzip_type_home" === e.target.value ) {
					
					if(  
						false === this.state.ulzip_type_all		&&	
						false === this.state.ulzip_type_gov	&&
						false === this.state.ulzip_type_civil		&&
						false === e.target.checked	&&
						false === this.state.ulzip_type_cooper	&&
						false === this.state.ulzip_type_etc		
					) {
						this.setState({
							ulzip_type_home : true
						});
						
						this.props.createErrorMsg( 
							"적어도 하나의 유형을 선택해 주세요^^",
							"warning"
						);
					} else {
						this.setState({
							ulzip_type_home : e.target.checked
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_home" : e.target.checked } );
					}
					
				} else if( "ulzip_type_cooper" === e.target.value ) {
					
					if(  
						false === this.state.ulzip_type_all		&&	
						false === this.state.ulzip_type_gov	&&
						false === this.state.ulzip_type_civil		&&
						false === this.state.ulzip_type_home	&&
						false === e.target.checked	&&
						false === this.state.ulzip_type_etc		
					) {
						this.setState({
							ulzip_type_cooper : true
						});
						
						this.props.createErrorMsg( 
							"적어도 하나의 유형을 선택해 주세요^^",
							"warning" 
						);
					} else {
						this.setState({
							ulzip_type_cooper : e.target.checked
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_cooper" : e.target.checked } );
					}
					
				} else {
					
					if(  
						false === this.state.ulzip_type_all		&&	
						false === this.state.ulzip_type_gov	&&
						false === this.state.ulzip_type_civil		&&
						false === this.state.ulzip_type_home	&&
						false === this.state.ulzip_type_cooper	&&
						false === e.target.checked		
					) {
						this.setState({
							ulzip_type_etc : true
						});
						
						this.props.createErrorMsg( 
							"적어도 하나의 유형을 선택해 주세요^^",
							"warning"
						);
					} else {
						this.setState({
							ulzip_type_etc : e.target.checked
						});
						
						this.props.filteringMarkerByType( { "ulzip_type_etc" : e.target.checked } );
					}
					
				}
				
			}
			
		}
		
		
	}
	
	
	clickKinderType( e ) {
		
		//all_type : true,	// 전체
		//ulzip_type : false,	// 얼집
		//kinder_type : false,	// 유치원
		if (  
			true	!==	this.state.all_type	&&
			true	!==	this.state.kinder_type
		) {
			return;
		}
		
		if( "kinder_type_all" === e.target.value ) {
			
			const tmp_kinder_type_all	= e.target.checked;
			
			//console.log( tmp_kinder_type_all );
			
			if( tmp_kinder_type_all ) {
				
				this.setState({
				
					kinder_type_all : e.target.checked,
					kinder_type_pri_indv : false,
					kinder_type_pri_comp : false,
					kinder_type_gov			: false,	// 공립(병설)
					kinder_type_gov_alone	: false,	// 공립(단설)
					kinder_type_etc : false
				
				});
				
				this.props.filteringKinderMarkerByType( { "kinder_type_all" : e.target.checked } );
				
			} else {
				this.setState({
					kinder_type_all:true
				});
				
				this.props.createErrorMsg( 
					"적어도 하나의 유형을 선택해 주세요 ^^",
					"warning"
				);
			}
			
		} else {
			
			const tmp_kinder_type_not_all	= e.target.checked;
			
			if( tmp_kinder_type_not_all ) {
				
				if( false !== this.state.kinder_type_all ) {
					
					if( "kinder_type_pri_indv" === e.target.value ) {
						
						this.setState({
							kinder_type_pri_indv : e.target.checked,
							kinder_type_all : false,
							
							kinder_type_pri_comp : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_pri_indv" : e.target.checked } );
						
					} /*else if( "kinder_type_pri_comp" === e.target.value ) {
						
						this.setState({
							kinder_type_pri_comp : e.target.checked,
							kinder_type_all : false
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_pri_comp" : e.target.checked } );
						
					} */else if( "kinder_type_gov" === e.target.value ) {
						
						this.setState({
							kinder_type_gov : e.target.checked,
							kinder_type_all : false,
							
							kinder_type_gov_alone : e.target.checked
							
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_gov" : e.target.checked } );
						
					} /*else if( "kinder_type_gov_alone" === e.target.value ) {
						
						this.setState({
							kinder_type_gov_alone : e.target.checked,
							kinder_type_all : false
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_gov_alone" : e.target.checked } );
						
					} */else {
						
						this.setState({
							kinder_type_etc : e.target.checked,
							kinder_type_all : false
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_etc" : e.target.checked } );
						
					}
					
					
				} else {
					
					if( "kinder_type_pri_indv" === e.target.value ) {
						
						this.setState({
							kinder_type_pri_indv : e.target.checked,
							kinder_type_pri_comp : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_pri_indv" : e.target.checked } );
						
					} /*else if( "kinder_type_pri_comp" === e.target.value ) {
						
						this.setState({
							kinder_type_pri_comp : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_pri_comp" : e.target.checked } );
						
					} */else if( "kinder_type_gov" === e.target.value ) {
						
						this.setState({
							kinder_type_gov : e.target.checked,
							
							
							kinder_type_gov_alone : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_gov" : e.target.checked } );
						
					} /*else if( "kinder_type_gov_alone" === e.target.value ) {
						
						this.setState({
							kinder_type_gov_alone : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_gov_alone" : e.target.checked } );
						
					} */else {
						
						this.setState({
							kinder_type_etc : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_etc" : e.target.checked } );
						
					}
					
				}
				
			} else {
				
				//console.log( e.target.value );
				//console.log( e.target.checked );
				
				if ( "kinder_type_pri_indv" === e.target.value ) {
					
					if(  
						false === this.state.kinder_type_all		&&	
						false === e.target.checked					&&
						//false === this.state.kinder_type_pri_comp	&&
						false === this.state.kinder_type_gov		&&
						//false === this.state.kinder_type_gov_alone	&&
						false === this.state.kinder_type_etc
					) {
						this.setState({
							kinder_type_pri_indv : true,
							kinder_type_pri_comp : true
						});
						
						this.props.createErrorMsg( 
							"적어도 하나의 유형을 선택해 주세요 ^^",
							"warning"
						);
					} else {
						this.setState({
							kinder_type_pri_indv : e.target.checked,
							kinder_type_pri_comp : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_pri_indv" : e.target.checked } );
					}
					
					
				} /*else if ( "kinder_type_pri_comp" === e.target.value ) {
					
					if(  
						false === this.state.kinder_type_all		&&	
						false === this.state.kinder_type_pri_indv	&&
						false === e.target.checked					&&
						false === this.state.kinder_type_gov		&&
						//false === this.state.kinder_type_gov_alone	&&
						false === this.state.kinder_type_etc
					) {
						this.setState({
							kinder_type_pri_comp : true
						});
						
						this.props.createErrorMsg( 
							"적어도 하나의 유형을 선택해 주세요 ^^",
							"warning"
						);
					} else {
						this.setState({
							kinder_type_pri_comp : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_pri_comp" : e.target.checked } );
					}
					
				} */else if ( "kinder_type_gov" === e.target.value ) {
					
					if(  
						false === this.state.kinder_type_all		&&	
						false === this.state.kinder_type_pri_indv	&&
						//false === this.state.kinder_type_pri_comp	&&
						false === e.target.checked					&&
						//false === this.state.kinder_type_gov_alone	&&
						false === this.state.kinder_type_etc
					) {
						this.setState({
							kinder_type_gov : true,
							
							kinder_type_gov_alone:true
						});
						
						this.props.createErrorMsg( 
							"적어도 하나의 유형을 선택해 주세요 ^^",
							"warning"
						);
					} else {
						this.setState({
							kinder_type_gov : e.target.checked,
							
							kinder_type_gov_alone : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_gov" : e.target.checked } );
					}
					
				} /*else if ( "kinder_type_gov_alone" === e.target.value ) {
					
					if(  
						false === this.state.kinder_type_all		&&	
						false === this.state.kinder_type_pri_indv	&&
						false === this.state.kinder_type_pri_comp	&&
						false === this.state.kinder_type_gov		&&
						false === e.target.checked					&&
						false === this.state.kinder_type_etc
					) {
						this.setState({
							kinder_type_gov_alone : true
						});
						
					} else {
						this.setState({
							kinder_type_gov_alone : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_gov_alone" : e.target.checked } );
					}
					
				} */else {
					
					if(  
						false === this.state.kinder_type_all		&&	
						false === this.state.kinder_type_pri_indv	&&
						//false === this.state.kinder_type_pri_comp	&&
						false === this.state.kinder_type_gov		&&
						//false === this.state.kinder_type_gov_alone	&&
						false === e.target.checked					
					) {
						this.setState({
							kinder_type_etc : true
						});
						
						this.props.createErrorMsg( 
							"적어도 하나의 유형을 선택해 주세요 ^^",
							"warning"
						);
					} else {
						this.setState({
							kinder_type_etc : e.target.checked
						});
						
						this.props.filteringKinderMarkerByType( { "kinder_type_etc" : e.target.checked } );
					}
					
				}
				
			}
			
		}
		
	}
	
	
	
	render ( ) {
		
		
		const isOpen	= this.props.isOpen;
		
		
		return(
			
			<Fragment>
				
				<Slide 
					direction={
						( isMobileOnly ) ?
							//"up" : 
							"down" : 
							//"right"
							"left"
					}
					in={isOpen} 
					//mountOnEnter
					//unmountOnExit
				>
					
					<Container
						maxWidth="xs"
						
						style={
							( isMobileOnly ) ?
								{
									position:"fixed",
									//top:"0",
									//zIndex:"2",
									zIndex:"3",
									
									maxHeight:"calc( 100% - 6rem )",
									
									margin:0,
									padding:0,
								} :
								{
									position:"fixed",
									//top:"3rem",
									//right:0,
									right:"4px",
									
									//zIndex:"2",
									zIndex:"3",	
									
									maxHeight:"calc( 100% - 6rem )",
									
									margin:0,
									padding:0,
								}
						}
					>
						<Box
							borderRadius={12}
									
							style={{
								margin:0,
								//padding:"0.5rem",
								
								paddingTop:"0.5rem",
								paddingLeft:"0.5rem",
								paddingRight:"0.5rem",
								paddingBottom:0,
								
								backgroundColor:"white"
							}}
						>
							<Grid 
								container
								direction="column"
								justify="center"
								alignItems="center"
							>
								<Grid
									item
									
									style={{
										width:"100%",
										padding:"0.25rem"
									}}
									
								>
									<Paper
										style={{
											backgroundColor:"#ffa000"
										}}
									>
										<Typography
											variant="h5" 
											component="h3"
											style={{
												textAlign:"center",
												color:"white"
											}}
										>
											{
												"유 형"
											}
										</Typography>
									</Paper>
								</Grid>
								
								<Grid
									
									item
									container
									direction="row"
									justify="space-between"
									alignItems="center"
									
									style={{
										paddingLeft:"0.25rem",
										paddingRight:"0.25rem"
									}}
								>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.all_type} 
													 onChange={this.clickType} 
													 value={"all_type"}
												/>
											}
											label="전체"
										/>
									</Grid>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.ulzip_type} 
													 onChange={this.clickType} 
													 value={"ulzip_type"}
												/>
											}
											label="어린이집"
										/>
									</Grid>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.kinder_type} 
													 onChange={this.clickType} 
													 value={"kinder_type"}
												/>
											}
											label="유치원"
										/>
									</Grid>
									
								</Grid>
								
								<Grid
									item
									style={{
										width:"100%",
										padding:"0.25rem"
									}}
								>
									<Paper
										style={{
											backgroundColor:"#43a047"
										}}
									>
										<Typography
											variant="h5" 
											component="h3"
											style={{
												textAlign:"center",
												color:"white"
											}}
										>
											{
												"어린이집"
											}
										</Typography>
									</Paper>
								</Grid>
								
								<Grid
									item
									container
									direction="row"
									justify="space-between"
									alignItems="center"
									
									style={{
										paddingLeft:"0.25rem",
										paddingRight:"0.25rem"
									}}
								>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.ulzip_type_all} 
													 onChange={this.clickUlzipType} 
													 value={"ulzip_type_all"}
												/>
											}
											label="전체"
										/>
										
									</Grid>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.ulzip_type_home} 
													 onChange={this.clickUlzipType} 
													 value={"ulzip_type_home"}
												/>
											}
											label="가정"
										/>
										
									</Grid>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.ulzip_type_civil} 
													 onChange={this.clickUlzipType} 
													 value={"ulzip_type_civil"}
												/>
											}
											label="민간"
										/>
									</Grid>
								</Grid>
								
								<Grid
									item
									container
									direction="row"
									justify="space-between"
									alignItems="center"
									
									style={{
										paddingLeft:"0.25rem",
										paddingRight:"0.25rem"
									}}
								>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.ulzip_type_cooper} 
													 onChange={this.clickUlzipType} 
													 value={"ulzip_type_cooper"}
												/>
											}
											label="협동"
										/>
										
									</Grid>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.ulzip_type_gov} 
													 onChange={this.clickUlzipType} 
													 value={"ulzip_type_gov"}
												/>
											}
											label="국공립"
										/>
										
									</Grid>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.ulzip_type_etc} 
													 onChange={this.clickUlzipType} 
													 value={"ulzip_type_etc"}
												/>
											}
											label="기타"
										/>
									</Grid>
								</Grid>
								
								<Grid
									item
									style={{
										width:"100%",
										padding:"0.25rem"
									}}
									
								>
									<Paper
										style={{
											backgroundColor:"#1976d2"
										}}
									>
										<Typography
											variant="h5" 
											component="h3"
											style={{
												textAlign:"center",
												color:"white"
											}}
										>
											{
												"유치원"
											}
										</Typography>
									</Paper>
								</Grid>
								
								<Grid
									item
									container
									direction="row"
									justify="space-between"
									alignItems="center"
									
									style={{
										paddingLeft:"0.25rem",
										paddingRight:"0.25rem"
									}}
								>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.kinder_type_all} 
													 onChange={this.clickKinderType} 
													 value={"kinder_type_all"}
												/>
											}
											label="전체"
										/>
										
									</Grid>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.kinder_type_gov} 
													 onChange={this.clickKinderType} 
													 value={"kinder_type_gov"}
												/>
											}
											label="공립"
										/>
										
									</Grid>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.kinder_type_pri_indv} 
													 onChange={this.clickKinderType} 
													 value={"kinder_type_pri_indv"}
												/>
											}
											label="사립"
										/>
									</Grid>
								</Grid>
								
								<Grid
									item
									container
									direction="row"
									justify="space-between"
									alignItems="center"
									
									style={{
										paddingLeft:"0.25rem",
										paddingRight:"0.25rem"
									}}
								>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									>
										<FormControlLabel
											control={
												<Checkbox 
													 checked={this.state.kinder_type_etc} 
													 onChange={this.clickKinderType} 
													 value={"kinder_type_etc"}
												/>
											}
											label="기타"
										/>
									</Grid>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									/>
									<Grid
										item
										xs={4}
										sm={4}
										md={4}
										lg={4}
										xl={4}
									/>
								</Grid>
								
								<Grid
									item
									container
									direction="row"
									justify="flex-end"
									alignItems="center"
									
									style={{
										paddingLeft:"0.25rem",
										paddingRight:"0.25rem"
									}}
								>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}
										
										style={{
											//textAlign:"right",
											textAlign:"center",
										}}
									>
										<IconButton
											size="small"
											
											onClick={this.props.toggleFilterPanel}
										>
											{
												(
													true === isMobileOnly
												) ?
													(
														<UnfoldLessIcon
															//CloseIcon
															fontSize="small"
														/>
													) :
													(
														<DoubleArrowIcon
															//CloseIcon
															fontSize="small"
														/>
													)
											}
										</IconButton>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Container>
				</Slide>
			
			</Fragment>
			
		);
		
	}
}


export default FilterPanel;