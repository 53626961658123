import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';

import UserReviews			from './UserReviews.js';

//import CardList				from '../Utility/CardList';
import IPickPageContainer	from "../Utility/IPickPage/IPickPageContainer.js";

//import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	isIPad13,
	//isAndroid, isBrowser 
} from 'react-device-detect';

import Gesture from 'rc-gesture';

import {
	Container,
	//Box,
	//Grid,
	//Dialog,
	//Button,
	//IconButton,
	//Typography,
	//CircularProgress,
	Slide,
} from '@material-ui/core';

//import Icon		from '@material-ui/core/Icon';
//import {
//	Close		as CloseIcon,
//	ChevronLeft	as ChevronLeftIcon,
//	Visibility,
//	VisibilityOff,
//	UnfoldLess	as UnfoldLessIcon,
//	ExpandMore	as ExpandMoreIcon,
//	Favorite		as FavoriteIcon,
//	FavoriteBorder	as FavoriteBorderIcon,
//	ArrowBackIos	as ArrowBackIosIcon
//} from '@material-ui/icons';


class UserReviewPage extends Component {

	constructor( props ) {
		super( props );
		
		this.state	= {
			userInfo	:{
				loginYN:this.props.userInfo.loginYN,
				userId:this.props.userInfo.userId,
				userEmail:this.props.userInfo.userEmail,
				userNickname:this.props.userInfo.userNickname,
				friendShareLink:this.props.userInfo.friendShareLink,
				nicknameConfirmYN:this.props.userInfo.nicknameConfirmYN,
			},
			type	: this.props.dataType
		};
		
	}
	
	static getDerivedStateFromProps( nextProps, prevState ) {
		
		if ( 
			prevState.userInfo.loginYN				!== nextProps.userInfo.loginYN		||
			prevState.userInfo.userId				!==	nextProps.userInfo.userId		||
			prevState.userInfo.userEmail			!== nextProps.userInfo.userEmail	||
			prevState.userInfo.userNickname			!== nextProps.userInfo.userNickname	||
			prevState.userInfo.nicknameConfirmYN	!== nextProps.userInfo.nicknameConfirmYN
		) {
			return { 
				userInfo: {
					//...userInfo,
					loginYN	: nextProps.userInfo.loginYN,
					userId	: nextProps.userInfo.userId,
					userEmail: nextProps.userInfo.userEmail,
					userNickname: nextProps.userInfo.userNickname,
					friendShareLink: nextProps.userInfo.friendShareLink,
					nicknameConfirmYN : nextProps.userInfo.nicknameConfirmYN,
				}
			};
		}
		
		if ( 
			prevState.type				!== nextProps.dataType
		) {
			return {
				type	: nextProps.dataType
			};
		}
		
		return null;
	}
	
	render ( ) {
		
		
		return (
			<Fragment>
				{
					( "" !== this.state.type ) ?
						(
							<Slide 
								direction={
									( isMobileOnly ) ?
										"left" : "right"
								}
								
								in={true}
								
								//style={{ transitionDelay: '100ms' }}
							>
								<Gesture
									onSwipeRight={
										( gestureStatus ) => {
											if ( true === isMobileOnly ) {
												this.props.closeUserReviewPage();
											}
										}
									}
									
									onSwipeLeft={
										( gestureStatus ) => {
											if ( true !== isMobileOnly ) {
												this.props.closeUserReviewPage();
											}
										}
									}
									
									direction="horizontal"
								>
									<Container
										fixed={true}
										maxWidth="xs"
										
										style={
											(
												true	=== isMobileOnly	||
												true	===	isTablet		||
												true	===	isIPad13
											) ?
												(
													{
														position:"fixed",
														top:"0",
														left:"0",
														height:"calc( 100vh - 3rem )",
														margin:0,
														padding:0,
														
														
														display:"flex",
														flexDirection:"column",
														overflow:"hidden",
														zIndex:this.props.zIndex,
													}
												) :
												(
													{
														position:"fixed",
														top:"0",
														left:"3.75rem",
														height:"100vh",
														margin:0,
														padding:0,
														
														
														display:"flex",
														flexDirection:"column",
														overflow:"hidden",
														zIndex:this.props.zIndex,
													}
												)
										}
									>
										<IPickPageContainer
											maxWidth="xs"
											zIndex={this.props.zIndex}
											type={this.props.type}
											//id={this.state.id_}
											data={this.props.data}
											pageTitle={this.props.title}
											others={undefined}
											
											closePage={this.props.closeUserReviewPage}
											createErrorMsg={this.props.createErrorMsg}
											
											buttonList={
												undefined
											}
											
											pageHeader={
												( true === this.props.headerInfoYN ) ?
													this.props.headerInfo : undefined
											}
											pageBody={
												//<CardList
												//	id={this.state.type}
												//	data={cardList}
												//	cardType={cardType}
												///>
												<UserReviews
													type={this.props.type}	// ulzip, kinder
													userInfo={this.state.userInfo}
													
													reviewType={this.props.dataType}	// ratingReviews, communityInfo
													userReviews={
														this.props.data
														//props.userReviews
														//(
														//	undefined	===	props.userReviews	||
														//	null		===	props.userReviews	||
														//	0			>=	props.userReviews.length
														//) ?
														//	[] : props.userReviews.slice(0, 3)
													}
													
													overflowYScrollYN={true}
													
													submitUserRatingInfo	= {this.props.submitUserRatingInfo}
													submitUserSharingInfo	= {this.props.submitUserSharingInfo}
													modifyUserRatingInfo	= {this.props.modifyUserRatingInfo}
													removeUserRatingInfo	= {this.props.removeUserRatingInfo}
												/>
											}
											pageFooter={
												( 
													true	=== isMobileOnly	||
													true	=== isTablet		||
													true	===	isIPad13
												) ?
													(
														<div
															style={{
																height:"3rem",
																margin:0,
																padding:0,
															}}
														/>
													) : 
													(
														<div
															style={{
																height:"1rem",
																margin:0,
																padding:0,
															}}
														/>
													)
											}
										/>
									</Container>
								</Gesture>
							</Slide>
						) : null
				}
			</Fragment>
			
		);
		
	}
	
}

export default UserReviewPage;