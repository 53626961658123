import React, {
	Component,
	Fragment
} from 'react';
import { Event, PageView, initGA } from '../Utility/Tracking.js';
import axios from 'axios';

import clsx from 'clsx';



import { 
	isMobile, 
	isIOS, 
	isMobileOnly, 
	isTablet, 
	isAndroid, 
	isBrowser,
	isIPad13,
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';



import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	
	Link,
	Card,
	CardMedia,
	CardActionArea,
	
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	//linkButtonHover:{
	//	'&:hover' : {
	//		textDecoration : "underline",
	//	},
	//},
	//linkButtonNone:{
	//	textDecoration : "none",
	//},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	overflowYVisible:{
		overflowY:"visible",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	avatar: {
		backgroundColor: red[500],
 	},
	
	dialogPaper: 
		( true === isMobileOnly ) ?
			{
				margin	: theme.spacing(0),
				minWidth: "90vw",
			} :
			{
				margin: theme.spacing(0),
			},
}));



export default function AdvertisementDetail ( props ) {
	
	const classes	= useStyles();
	
	console.log( props.data.company_name );
	const ad_id	= props.data._id;
	
	
	return (
		<Fragment>
			{
				( 
					null		!==	props.data	&&
					undefined	!==	props.data
				) ?
					(
						(
							"middle" === props.type
						) ?
							(
								<Card
									className={
										clsx(
											classes.noGutters
										)
									}
									
								>
									<CardActionArea
										target="_blank" 
										href={
											//props.data.ads_array[0].banner_link
											props.data.link
										} 
										onClick={
													() => Event("ADS", "Ads Link Clicked", '['+props.data.company_name+'] '+props.data.name)
										}
										rel="noopener noreferrer"	
										//component={"a"}
										color={"inherit"}
										
										underline={"none"}
										style={{
											textDecoration:"none",
										}}
									>
										<CardMedia
											component="img"
											
											image={
												//props.data.ads_array[0].image_url
												props.data.image_url
											}
											
											style={{
												//width:props.data.ads_array[0].width,
												//height:props.data.ads_array[0].height,
												
												//minWidth:props.data.width,
												height:props.data.height,
											}}
										/>
									</CardActionArea>
								</Card>
							) :
							(
								<Card
									className={
										clsx(
											classes.noGutters
										)
									}
									
								>
									<CardActionArea
										target="_blank" 
										onClick={
													() => Event("ADS", "Ads Link Clicked", '['+props.company_name+'] '+props.data.name)
												}
										href={
											//props.data.ads_array[0].banner_link
											props.data.link
										} 
										rel="noopener noreferrer"	
										//component={"a"}
										color={"inherit"}
										
										underline={"none"}
										style={{
											textDecoration:"none",
										}}
									>
										<CardMedia
											component="img"
											
											image={
												//props.data.ads_array[0].image_url
												props.data.image_url
											}
											
											style={{
												//width:props.data.ads_array[0].width,
												//height:props.data.ads_array[0].height,
												
												//minWidth:props.data.width,
												height:props.data.height,
											}}
										/>
									</CardActionArea>
								</Card>
							)
					) : null
			}
		</Fragment>
	);
	
}