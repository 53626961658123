import React, {
	Fragment
} from 'react';

import clsx from 'clsx';

import { 
	isMobile, 
	isIOS, 
	isMobileOnly, 
	isTablet, 
	isAndroid, 
	isBrowser,
	isIPad13,
} from 'react-device-detect';


import { 
	ThemeProvider 
} from '@material-ui/styles';


import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';


import {
	Box,
	Container,
	Button,
	IconButton,
	Grid,
	Typography,
	
	GridList,
	Paper,
	Collapse
} from '@material-ui/core';


import {
	AddBox		as AddBoxIcon,
	AddIndeterminateCheckBoxBox		as IndeterminateCheckBoxIcon,
	Close		as CloseIcon,
	UnfoldLess		as UnfoldLessIcon,
} from '@material-ui/icons';

import IllegalInfoDetailUlzip	from './IllegalInfoDetailUlzip.js';
import IllegalInfoDetailPeople	from'./IllegalInfoDetailPeople.js';



const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		third		: {
			main:"#616161"	// lightGrey
		},
		error		: {
			main:"#c62828",
			light:"#f44336"
		},
		warning		: {
			main:"#ffa000",
		},
	}
});


const useStyles = makeStyles( (theme) => (
	{
		grow: {
			flexGrow: 1,
		},
		shrink:{
			flexShrink:0
		},
		button:{
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		fab: {
			margin: theme.spacing(0),
			//padding: theme.spacing(1)
			//display: 'flex',
			//alignItems: 'center',
			//justifyContent: 'center'
		},
		noPadding:{
			padding: theme.spacing(0)
		},
		noMargin:{
			margin: theme.spacing(0)
		},
		noGutters:{
			margin: theme.spacing(0),
			padding: theme.spacing(0)
		},
		margin:{
			margin: theme.spacing(1),
		},
		marginSmall:{
			margin:theme.spacing(0.5),	
		},
		marginY:{
			marginTop:theme.spacing(1),
			marginBottom:theme.spacing(1),
		},
		marginBottom:{
			marginBottom:theme.spacing(1),
		},
		padding:{
			padding: theme.spacing(1),
		},
		padding2:{
			padding: theme.spacing(2),
		},
		paddingX:{
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
		paddingY:{
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		paddingTop:{
			paddingTop: theme.spacing(1),
		},
		paddingSmall:{
			padding:theme.spacing(0.5)
		},
		paddingSmallX:{
			paddingLeft:theme.spacing(0.5),
			paddingRight:theme.spacing(0.5),
		},
		paddingSmallY:{
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(0.5),
		},
		textLeft:{
			textAlign:"left"
		},
		textCenter:{
			textAlign:"center"
		},
		fontWeightBold:{
			fontWeight:700
		},
		fontWeightLight:{
			fontWeight:300
		},
		textSmall:{
			fontSize:"80%",
		},
		overflowYScroll:{
			overflowY:"scroll",
		},
		overflowYHidden:{
			overflowY:"hidden",	
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: theme.spacing(0),
			padding: theme.spacing(1),
		},
		modalBox: {
			margin: theme.spacing(0),
			padding: theme.spacing(1),
			maxWidth:"95%",
			backgroundColor:"white"
		},
		card:{
			margin: theme.spacing(0),
			padding: theme.spacing(1),
			maxWidth:476
		},
		cardHeader:{
			textAlign:"center",
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		cardContent:{
			margin: theme.spacing(0),
			paddingLeft:theme.spacing(2),
			paddingRight:theme.spacing(2),
			paddingTop:theme.spacing(1),
			paddingBottom:theme.spacing(1),
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1)
		},
		bgLight:{
			backgroundColor:"#f8f9fa !important"
		},
		borderTop:{
			borderTop:"1px solid #e0e0e0 !important",
		},
		borderRight:{
			borderRight:"1px solid #6c757d !important",
		},
		borderColorError:{
			borderColor:"#d32f2f"
		},
		backgroundColorError:{
			backgroundColor:"#d32f2f"
		},
		textWhite:{
			color:"white"
		}
	}
));



function IllegalInfoDetail ( props ) {

	const classes	= useStyles();	
	
	
	//console.log( props.data.illegal_type );
	
	let detail_data	= null;
	
	switch ( props.data.illegal_type ) {
		
		case "sisul" :
			detail_data	= (
				<IllegalInfoDetailUlzip
					key={props.data._id + "_sisul_"}
					data={props.data}
					closeOtherIllegalUlzip={props.closeOtherIllegalUlzip}
					openingIllegalUlzipId={props.openingIllegalUlzipId}
					openMarkerFromAutoSearchResult={props.openMarkerFromAutoSearchResult}
					moveCenterLoc={props.moveCenterLoc}
				/>
			);
			break;
		case "people" :
			detail_data	= (
				<IllegalInfoDetailPeople
					key={props.data._id + "_people_"}
					data={props.data}
					closeOtherIllegalUlzip={props.closeOtherIllegalUlzip}
					openingIllegalUlzipId={props.openingIllegalUlzipId}
					openMarkerFromAutoSearchResult={props.openMarkerFromAutoSearchResult}
					moveCenterLoc={props.moveCenterLoc}
				/>
			);
			break;
		default :
			detail_data	= (
				<IllegalInfoDetailUlzip
					key={props.data._id + "_sisul_"}
					data={props.data}
					closeOtherIllegalUlzip={props.closeOtherIllegalUlzip}
					openingIllegalUlzipId={props.openingIllegalUlzipId}
					openMarkerFromAutoSearchResult={props.openMarkerFromAutoSearchResult}
					moveCenterLoc={props.moveCenterLoc}
				/>
			);
			break;
			
	}
	

	return (
		<Fragment>
			
			<Box
				className={
					clsx(
						classes.marginSmall,
						classes.paddingSmall
					)
				}
				
				border={1}
				borderRadius="borderRadius"
				borderColor="secondary.main"
			>
				{
					detail_data
				}
			</Box>
			
		</Fragment>
	);
	
}



export default function IllegalUlzipList ( props ) {
	
	const classes	= useStyles();
	
	//const [isOpen, toggleIllegalInfo]	= React.useState(false);
	
	let data_list	= null;
	
	
	if (
		null		!== props.data	&&
		undefined	!== props.data
	) {
		data_list	= props.data.map( ( data, idx ) => (
			<IllegalInfoDetail
				key={data._id + "_" + idx.toString()}
				data={data}
				closeOtherIllegalUlzip={props.closeOtherIllegalUlzip}
				openingIllegalUlzipId={props.openingIllegalUlzipId}
				openMarkerFromAutoSearchResult={props.openMarkerFromAutoSearchResult}
				moveCenterLoc={props.moveCenterLoc}
			/>
		));
	}
	
	//console.log( props.data );
	
	return (
		<Fragment>
			<Container
				maxWidth="sm"
				
				className={
					clsx(
						classes.grow,
						classes.noMargin,
						classes.paddingSmallX,
						classes.overflowYHidden,
					)
				}
				
				style={{
					display:"flex",
					flexDirection:"column",
				}}
			>
				<div
				//Container
					className={
						clsx(
							classes.noGutters,
							classes.overflowYScroll,
							
							classes.grow,
						)
					}
					
				>
					{
						( 
							null	===	data_list	||
							0		>=	data_list.length
						) ?
							(
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid
										item
										
										className={
											clsx(
												classes.padding2,
											)
										}
									>
										<b>
											{
												"'" + props.currentLocInfo.trim() + "'"
											}
										</b>
										{
											"에는 위반 행위자/시설이 없습니다."
										}
									</Grid>
								</Grid>
								
							) :
							(
								data_list
							)
					}
				</div
					//Container
				>
				
				
				{
					( 
						true	=== isMobileOnly	||
						true	=== isTablet		||
						true	===	isIPad13
					) ?
						(
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										//classes.marginY,
										classes.paddingTop,
										classes.marginBottom,
										classes.borderTop,
										classes.shrink,
									)
								}
							>
								<Grid
									item
									
									xs={2}
									sm={2}
									md={2}
									lg={2}
									xl={2}
									
									
									className={
										clsx(
											classes.textCenter
										)
									}
									
									style={{
										lineHeight:"100%",
									}}
								>
									<IconButton
										className={
											clsx(
												classes.button,
												classes.noGutters,
											)
										}
										
										size="small"
										
										onClick={
											(e)=>{
												
												if (  
													null		!==	props.toggleIllegalInfo	&&
													undefined	!==	props.toggleIllegalInfo
												) {
													props.toggleIllegalInfo();
												}
												
											}
										}
									>
										<UnfoldLessIcon
											//CloseIcon
											fontSize="small"
										/>
									</IconButton>
								</Grid>
							</Grid>
						) : null
				}
				
			</Container>
		</Fragment>
	);
}