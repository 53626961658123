import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Box,
	CircularProgress,
	Modal,
	Backdrop,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		third		: {
			main:"#616161"	// lightGrey
		},
		error		: {
			main:"#c62828",
			contrastText:"#ffffff",
		},
		warning		: {
			main:"#ffa000",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding: theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textCenter:{
		textAlign:"center"
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	//success: {
	//	backgroundColor: green[600],
	//	color:theme.palette.primary.contrastText
	//},
	//successOpposition: {
	//	backgroundColor: theme.palette.primary.contrastText,
	//	color:green[600]
	//},
	//primary:{
	//	backgroundColor: theme.palette.primary.main,
	//	color:theme.palette.primary.contrastText
	//},
	//primaryOpposition:{
	//	backgroundColor: theme.palette.primary.contrastText,
	//	color:theme.palette.primary.main
	//},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
	},
	card:{
		margin: theme.spacing(0),
		padding: theme.spacing(1),
		maxWidth:476
	},
	cardHeader:{
		textAlign:"center",
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	cardContent:{
		margin: theme.spacing(0),
		paddingLeft:theme.spacing(2),
		paddingRight:theme.spacing(2),
		paddingTop:theme.spacing(1),
		paddingBottom:theme.spacing(1),
	},
}));


export default function MarkerGpsDupList ( props ) {
	
	const classes	= useStyles();
	
	let dup_markers_list	= null;
	
	
	dup_markers_list = props.data.map( data => 
		(
			<Grid
				key={data.name + '_' + data.code}
				
				item
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid
					item
					
					xs={3}
					sm={3}
					md={2}
					lg={2}
					xl={2}
					
					className={
						clsx(
							classes.textCenter,
							classes.paddingSmall,
							//classes.paddingSmallY,
						)
					}
				>
					<Box
						border={1}
						borderRadius={16}
						borderColor={
							( "ulzip" === data.dataType )? "secondary" : "primary"
						}
						className={
							clsx(
								classes.textCenter,
								
							)
						}
						
						style={{
							textOverflow: "ellipsis",
							lineHeight:"100%",
						}}
					>
						<Typography 
							variant="caption"
							
							className={
								clsx(
									classes.fontWeightBold,
								)
							}
						>
							{
								( "ulzip" === data.dataType ) ? 
									data.marker.ulzip_type : data.marker.kinder_type
							}
						</Typography>
					</Box>
				</Grid>
				<Grid 
					item
					
					xs={9}
					sm={9}
					md={10}
					lg={10}
					xl={10}
					
					className={
						clsx(
							classes.textCenter,
							classes.padding,
						)
					}
				>
					<Button
						color={
							( "ulzip" === data.dataType )? "secondary" : "primary"
						}
						variant="contained"
						value={data.code}
						fullWidth={true}
						className={
							clsx(
								classes.fontWeightBold,
								
							)
						}
						
						onClick={ 
							( e ) => { 
								//console.log( data );
								//console.log( e.target.value );
								props.clickInnerMarkerInDupMarkersList( data.code ); 
							} 
						}
					>
						{data.name}
					</Button>
				</Grid>
			</Grid>
		)
	);
	
	
	return(
		
		<Modal
			className={classes.modal}
			open={props.isOpen}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			
		>
			
			<Container
				maxWidth="xs"
				
				className={
					clsx(
						classes.noGutters,
						classes.overflowYScroll,
					)
				}
			>
				<ThemeProvider
					theme={markerInfoTheme}
				>
					<Box
						className={
							clsx(
								classes.modalBox,
							)
						}
						
						bgcolor={
							( 
								true	=== props.isOpen
								
							) ?
								(
									( "ulzip" === props.data[0].dataType ) ?
										"secondary.contrastText" : 
										(
											( "kinder" === props.data[0].dataType ) ?
												"primary.contrastText" : ""
										)
								) : ""
						}
						
						color={
							( 
								true	=== props.isOpen
								
							) ?
								(
									( "ulzip" === props.data[0].dataType ) ?
										"secondary.main" : 
										(
											( "kinder" === props.data[0].dataType ) ?
												"primary.main" : ""
										)
								) : ""
						}
						
						borderRadius="borderRadius"
					>
						
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
							>
								<Grid
									item
									
									style={{
										textAlign:"center"
									}}
								>
									<IconButton
										className={
											clsx(
												classes.button,
												classes.noGutters,
											)
										}
										
										size="small"
										
										onClick={
											(e) => {
												props.closeDupMarkersPanel( e );
											}
										}
									>
										<CloseIcon 
											fontSize="small"
										/>
									</IconButton>
								</Grid>
							</Grid>
							
							{ dup_markers_list }
							
						</Grid>
					</Box>
				</ThemeProvider>
			</Container>
		</Modal>
		
	);	
}