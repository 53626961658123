import axios from 'axios';


const AUTH_LOGIN			= "AUTH_LOGIN";
const AUTH_LOGIN_SUCCESS	= "AUTH_LOGIN_SUCCESS";
const AUTH_LOGIN_FAILURE	= "AUTH_LOGIN_FAILURE";

const AUTH_LOGOUT			= "AUTH_LOGOUT";
const AUTH_LOGOUT_SUCCESS	= "AUTH_LOGOUT_SUCCESS";
const AUTH_LOGOUT_FAILURE	= "AUTH_LOGOUT_FAILURE";


export const login = ( ) => {
	return {
		type: AUTH_LOGIN
	};
}

export const loginSuccess = ( userInfo ) => {
	return {
		type: AUTH_LOGIN_SUCCESS,
		userInfo:{
			loginYN:true,
			userId:userInfo.userId,
			userEmail:userInfo.userEmail,
			userNickname:userInfo.userNickname,
			friendShareLink:userInfo.friendShareLink,
			nicknameConfirmYN:userInfo.nicknameConfirmYN,
		}
	};
}

export const loginFailure = ( ) => {
	return {
		type: AUTH_LOGIN_FAILURE
	};
}

export const logout = ( ) => {
	return {
		type : AUTH_LOGOUT
	};
}

export const logoutSuccess = ( ) => {
	return {
		type: AUTH_LOGOUT_SUCCESS,
		userInfo:{
			loginYN:false,
			userId:"",
			userEmail: '',
			userNickname: '',
			friendShareLink:"",
			nicknameConfirmYN:true,
		}
	};
}

export const logoutFailure = ( ) => {
	return {
		type: AUTH_LOGOUT_FAILURE
	};
}

// **** 초기상태 정의
const initialState = {
	type:AUTH_LOGOUT_SUCCESS,
	userInfo:{
		loginYN:false,
		userId:"",
		userEmail: '',
		userNickname: '',
		friendShareLink:"",
		nicknameConfirmYN:true,
	}
};



export default function userAuthentication ( state = initialState, action ) {
	
	//console.log( action );
	//console.log( state );
	
	switch ( action.type ) {
		case AUTH_LOGIN				:
			return {
				...state
			};
		case AUTH_LOGIN_SUCCESS		:
			return {
				...state,
				userInfo : {
					...state.userInfo,
					loginYN			: action.userInfo.loginYN,
					userId			: action.userInfo.userId,
					userEmail		: action.userInfo.userEmail,
					userNickname	: action.userInfo.userNickname,
					friendShareLink	: action.userInfo.friendShareLink,
					nicknameConfirmYN	: action.userInfo.nicknameConfirmYN,
				}
			};
		case AUTH_LOGIN_FAILURE		:
			return {
				...state
			};
		case AUTH_LOGOUT			:
			return {
				...state
			};
		case AUTH_LOGOUT_SUCCESS	:
			
			//console.log( action.userInfo );
			
			return {
				...state,
				userInfo : {
					...state.userInfo,
					loginYN		: action.userInfo.loginYN,
					userId		: action.userInfo.userId,
					userEmail	: action.userInfo.userEmail,
					userNickname: action.userInfo.userNickname,
					friendShareLink		: action.userInfo.friendShareLink,
					nicknameConfirmYN	: action.userInfo.nicknameConfirmYN,
				}
			};
		case AUTH_LOGOUT_FAILURE	:
			return {
				...state
			};
		default :
			return state;
	}
	
}

