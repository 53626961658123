import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

//import { 
//	ThemeProvider 
//} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	//Fab,
	Box,
	//Modal,
	//Backdrop,
	//Divider,
	TextField,
	InputAdornment,
	FormControl,
	//FormLabel,
	FormHelperText,
	//FormGroup,
	//FormControlLabel,
	//InputLabel,
	//Input,
	//Switch,
	//Slide,
	//Collapse,
	//Grow,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		third		: {
			main:"#616161",	// lightGrey
			contrastText:"#fff",
		},
		fourth		: {
			main:"rgb(0,0,0,0.54)",
			contrastText:"#fff",
		},
		error		: {
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			main:"#ffa000",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		marginBottom:theme.spacing(0),
		marginLeft:theme.spacing(0),
		marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
}));



export default function NewPasswordRegisterDetail ( props ) {
	
	const classes	= useStyles();
	const [values, setValues] = React.useState({
		password: "",
		passwordConfirm: "",
		showPassword: false,
	});
	
	const handleChange = prop => event => {
		
		setValues({ 
			...values, 
			[prop]: event.target.value 
		});
		
		
		if ( "password" === prop ) {
			props.typePassword( event );
		} else if ( "passwordConfirm" === prop ) {
			props.typePasswordConfirm( event );
		} else {
			
		}
		
	};
		
	const handleClickShowPassword = () => {
		setValues({ 
			...values, 
			showPassword: !values.showPassword 
		});
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};
	
	
	return (
		<Fragment>
			<Container
				maxWidth="xl"
				
				
				className={
					clsx(
						//classes.noGutters,
						//classes.padding,
						classes.padding2,
						classes.grow,
						classes.bgWhite,
						classes.overflowYScroll,
					)
				}
				
				style={{
					zIndex:1,
					width:"100vw",
				}}
			>
				<Box 
					display="flex"
					justifyContent="center"
					alignItems="center"
					alignContent="center"
					//flexGrow={1}
					
					className={
						clsx(
							classes.noGutters,
						)
					}
					
					style={{
						height:"100%",
					}}
				>
					<Container
						maxWidth="sm"
						
						className={
							clsx(
								classes.noGutters,
							)
						}
					>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="center"
							
							//style={{
							//	width:"100%",
							//}}
						>
							<Grid
								item
								
								//xs={12}	
								//sm={12}
								//md={12}
								//lg={12}
								//xl={12}
							>
								
								<Typography 
									className={
										clsx(
											classes.paddingX,
											classes.marginTop,
											classes.marginBottom2,
											//classes.fontWeightBold,
											classes.textCenter,
											classes.marginBottom,
										)
									}
									variant="h3" 
									
								>
									{
										"아이픽"
									}
								</Typography>
							</Grid>
							
							<Grid
								item
								
								//xs={12}	
								//sm={12}
								//md={12}
								//lg={12}
								//xl={12}
							>
								
								<Typography 
									className={
										clsx(
											classes.paddingX,
											classes.margin2Y,
											classes.textCenter,
										)
									}
									//variant="subtitle1" 
									variant="h6" 
								>
									{
										"비밀번호 재설정"
									}
								</Typography>
							</Grid>
						</Grid>
						
						<TextField
							className={
								clsx(
									//classes.marginX,
									//classes.textField
								)
							}
							label="비밀번호"
							value={values.password}
							type={values.showPassword ? 'text' : 'password'}
							//placeholder={"비밀번호"}
							
							//margin="dense"
							margin="none"
							variant="outlined"
							
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											edge="end"
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{values.showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
							fullWidth={true}
							
							onChange={handleChange("password")}
						/>
						
						{
							( 0 === values.password.length ) ?
								(
									<FormControl
												className={
											clsx(
												classes.formControl,
											)
										} 
										error={false}
									>
										<FormHelperText 
											className={
												clsx(
													classes.formHelperText,
												)
											} 
											error={false}
										>
											{
												""
											}
										</FormHelperText>
									</FormControl>
								) :
								(
									<FormControl
										className={
											clsx(
												classes.formControl,
											)
										} 
										error={!props.isValidPassword}
									>
										<FormHelperText 
											className={
												clsx(
													classes.formHelperText,
												)
											} 
											error={!props.isValidPassword}
										>
											{
												( props.isValidPassword )?
													"유효한 비밀번호 형식입니다." : "영문, 숫자, 특수문자를 조합하여 8-15자리 이내로 입력해주세요."
											}
										</FormHelperText>
									</FormControl>
								)
						}
						
						<TextField
							
							className={
								clsx(
									//classes.marginX,
									//classes.textField
									classes.marginTop,
								)
							}
							label="비밀번호 확인"
							value={values.passwordConfirm}
							type={values.showPassword ? 'text' : 'password'}
							//placeholder={"비밀번호 확인"}
							
							//margin="dense"
							margin="none"
							variant="outlined"
							fullWidth={true}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											edge="end"
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{values.showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
							
							onChange={handleChange("passwordConfirm")}
						/>
						
						{
							( 0 === values.passwordConfirm.length )?
								(
									<FormControl
										className={
											clsx(
												classes.formControl,
											)
										} 
										error={false}
									>
										<FormHelperText 
											className={
												clsx(
													classes.formHelperText,
												)
											} 
											error={false}
										>
											{
												""
											}
										</FormHelperText>
									</FormControl>
								) :
								(
									<FormControl
										className={
											clsx(
												classes.formControl,
											)
										} 
										error={
											( !props.isValidPasswordConfirm )
										}
									>
										<FormHelperText 
											className={
												clsx(
													classes.formHelperText,
												)
											} 
											error={
												( !props.isValidPasswordConfirm )
											}
										>
											{
												( true === props.isValidPasswordConfirm )?
													"비밀번호와 일치합니다." : "비밀번호와 일치하지 않습니다."
											}
										</FormHelperText>
									</FormControl>
								)
						}
						
						<Grid
							container
							direction="row"
							justify="space-around"
							alignItems="center"
							
							className={
								clsx(
									classes.marginTop,
								)
							}
						>
							<Grid
								item
								
								xs={5}
								sm={5}
								md={5}
								lg={5}
								xl={5}
								
							>
								<Button 
									component={Link}
									to="/m" 
									variant="outlined"
									fullWidth={true}
								>
									{ "홈으로" }
								</Button>
							</Grid>
							<Grid
								item
								
								xs={5}
								sm={5}
								md={5}
								lg={5}
								xl={5}
							>
								<Button 
									disabled={
										false === props.isValidPassword	||
										false === props.isValidPasswordConfirm
									}
									variant="outlined"
									fullWidth={true}
									onClick={props.passwordReset}
								>
									{
										"비밀번호 변경"
									}
								</Button>
							</Grid>
						</Grid>
						
					</Container>
				</Box>
			</Container>
		</Fragment>
	);
	
}