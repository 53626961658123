import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser, isIPad13 } from 'react-device-detect';

//import classnames from 'classnames';
import clsx from 'clsx';

import { 
	fade,
	makeStyles,
	withStyles
} from '@material-ui/core/styles';

import {
	Box,
	Button,
	IconButton,
	Grid,
	Tooltip,
	Typography
} from '@material-ui/core';


import MyLocationIcon from '@material-ui/icons/MyLocation';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

const useStyles = makeStyles( (theme) => (
	{
		button:{
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		bgLight:{
			backgroundColor:"#f8f9fa !important"
		},
		textSmall:{
			fontSize:"80%",
			fontWeight:"400"
		}
	}
));


const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);



export default function ControlMapPanel ( props ) {
	
	const classes	= useStyles();
	
	const my_str	= String(window.location).split("/");
	let mobile_main_open	= false;
	
	if( 
		null		!== my_str	&&
		undefined	!== my_str	&&
		true		=== Array.isArray( my_str )
	) {
		for( let i = 0 ; i < my_str.length ; i++ ) {
			if( "m" === my_str[i] ) {
				mobile_main_open	= true;
				break;
			}
		}
	}
	
	
	
	return(
		<Grid
			container
			direction="column"
			justify="center"
			alignItems="center"
			
			style={
				( isMobileOnly )?
					(
						{
							position:"fixed",
							//bottom:"4rem",
							bottom:( true === mobile_main_open ) ? "3.5rem" : "4rem",
							
							left:"50%",
							maxWidth:"250px",
							minWidth:"100px",
							transform:"translate(-50%,0)",
							zIndex:"2"
						}
					) :
				(
					( 
						true	===	isTablet	||
						true	===	isIPad13
					)? 
						{
							position:"fixed",
							//bottom:"4.5rem",
							bottom:"4rem",
							
							left:"50%",
							maxWidth:"250px",
							minWidth:"100px",
							transform:"translate(-50%,0)",
							zIndex:"2"
						} : 
						{
							position:"absolute",
							bottom:"1.25rem",
							
							left:"50%",
							maxWidth:"250px",
							minWidth:"100px",
							transform:"translate(-50%,0)",
							zIndex:"2"
						}
				)
			}
			
			onClick={
				(e)=>{
					
					if(
						undefined	=== props.menuBarPanelMouseOutEventHandler	||
						undefined	=== props.duplicatedMarkersPanelMouseOutEventHandler
					) {
						return;
					} else {
						props.menuBarPanelMouseOutEventHandler(e);
						props.duplicatedMarkersPanelMouseOutEventHandler(e);
					}
					
				}
			}
		>
			{
				( isMobileOnly )?
					null :
					(
						<Grid
							container
							item
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
							>
								
								<IconButton
									className={classes.button}
									
									aria-label="ZoomIn"
									
									onClick={
										(e)=>{
											props.magnifyMap( );
										}
									} 
								>
									<ZoomInIcon 
										fontSize="large"
									/>
								</IconButton>
								
							</Grid>
							
							<Grid
								item
								//justify="center"
								//alignItems="center"
								
								
							>
								<IconButton
									className={classes.button}
									aria-label="ZoomOut"
									
									onClick={
										(e)=>{
											props.minifyMap( );
										}
									} 
								>
									<ZoomOutIcon 
										fontSize="large"
									/>
								</IconButton>
							</Grid>
						</Grid>
					)
			}
			
			<Box
				m={0}
				p={0}
				
				borderRadius={"50rem"}
				
				className={
					clsx(
						classes.bgLight,
					)
				}
				
				clone={true}
			>
				<Grid
					container
					item
					direction="row"
					justify="space-between"
					alignItems="center"
					
				>
					<Grid
						item
						
						xs={2}
						sm={2}
						md={2}
						lg={2}
						xl={2}
						
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							textAlign:"center"
						}}
					>
						
						<HtmlTooltip
								
							title={
								<React.Fragment>
									<Typography 
										color="inherit"
									>
										{
											"접속하신 위치로 이동할 수 있어요."
										}
									</Typography>
								</React.Fragment>
							}
							placement="top"
						>
							<IconButton
								className={classes.button} 
								aria-label="MyLocation"
								
								onClick={
									(e)=>{
										props.moveToUserLocation();
									}
								} 
							>
								<MyLocationIcon />
							</IconButton>
						</HtmlTooltip>
						
						
					</Grid>
					<Grid
						className={
							clsx(
								classes.textSmall
							)
						}
						item
						
						xs={10}
						sm={10}
						md={10}
						lg={10}
						xl={10}
						
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							textAlign:"center"
						}}
					>
						{props.currentWatchingLocation}
					</Grid>
				</Grid>
			</Box>
		</Grid>
	);
	
}