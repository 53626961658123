import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';

import PropTypes from 'prop-types';
import clsx		from 'clsx';

import { 
	//isMobile, 
	//isIOS, 
	isMobileOnly, 
	isTablet,
	//isAndroid, 
	//isBrowser,
	isIPad13,
} from 'react-device-detect';


import IllegalInfoPanel			from '../IllegalInfo/IllegalInfoPanel.js'
import PlaceList				from '../Search/PlaceList.js';
import AutoSearchKeyword		from '../Search/AutoSearchKeyword.js';
import HeadlineNews				from '../News/HeadlineNews';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	withStyles,
	createMuiTheme
} from '@material-ui/core/styles';

import {
	Box,
	Container,
	AppBar,
	Toolbar,
	Snackbar,
	Button,
	IconButton,
	//Grid,
	//TextField,
	Input,
	//InputBase,
	InputLabel,
	InputAdornment,
	FormControl,
	//FormGroup,
	Typography,
	Badge,
	Tab,
	Tabs,
} from '@material-ui/core';


//import { borders } from '@material-ui/system';


import SearchIcon from '@material-ui/icons/Search';
import BackspaceIcon from '@material-ui/icons/Backspace';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink0:{
		flexShrink:0,
	},
	shrink1:{
		flexShrink:1,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0),
	},
	padding:{
		padding: theme.spacing(1),
	},
	
	paddingSmall : {
		padding: theme.spacing(0.5),
	},
	
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding1_5X:{
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	
	
	paddingRight:{
		paddingRight:theme.spacing(1),
	},
	paddingRight2X:{
		paddingRight:theme.spacing(2),
	},
	
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	paddingBottomSmall:{
		paddingBottom: theme.spacing(0.5),
	},
	paddingBottom1_5 : {
		paddingBottom : theme.spacing(1.5),
	},
	padding2:{
		padding:theme.spacing(2),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	margin2:{
		margin:theme.spacing(2),
	},
	margin3:{
		margin:theme.spacing(3),
	},
	//textField: {
	//	flexBasis: 200,
	//},
	textCenter:{
		textAlign:"center",
	},
	textRight:{
		textAlign:"right",	
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLightGrey:{
		backgroundColor:"#e0e0e0",
	},
	bgLightGreyDark:{
		backgroundColor:"#9c9c9c",
	},
	bgGrey:{
		backgroundColor:"#616161",
	},
	bgTransparent:{
		backgroundColor:"transparent",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowXHidden:{
		overflowX:"hidden",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	overflowYHidden:{
		overflowY:"hidden",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
}));


//const StyledBadge = withStyles(theme => ({
//	badge: {
//		//right: -3,
//		right: "-1rem",
//	},
//}))(Badge);




function TabPanel( props ) {
	
	const { children, value, index, ...other } = props;
	const classes	= useStyles();
	
	return (
		<Fragment>
			<Box
				component="div"
				role="tabpanel"
				hidden={value !== index}
				id={`nav-tabpanel-${index}`}
				aria-labelledby={`nav-tab-${index}`}
				{...other}
				
			>
				{
					value === index && 
					(
						<Box
							bgcolor="white"
							borderRadius={14}
							display="flex"
							flexGrow={1}
							flexDirection="column"
							justifyContent="center"
							alignItems="flex-start"
							
							m={0}
							p={0}
							
							className={
								clsx(
									classes.paddingSmall,
								)
							}
							
							style={{
								overflowY:"hidden",
								maxHeight:"calc( 97vh - 3rem + 4px )"
							}}
						>
							{
								children
							}
						</Box>
					)
				}
			</Box>
		</Fragment>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps( index ) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}


export default function MenuBar ( props ) {
	
	const classes	= useStyles();
	
	const [value, setValue] = React.useState(0);

	const setTabIndex = (event, newValue) => {
		
		if ( value !== newValue ) {
			setValue(newValue);
		}
		
 	};
	
	//const [numOfCurrentLocIllegal, setNumOfCurrentLocIllegal]	= React.useState(0);
	//
	//if ( numOfCurrentLocIllegal !== props.numOfCurrentLocIllegal ) {
	//	setNumOfCurrentLocIllegal( props.numOfCurrentLocIllegal );
	//}
	
	//console.log( props.numOfCurrentLocIllegal );
	//console.log( numOfCurrentLocIllegal );
	
	return (
		
		<Fragment>
			{
				( 
					true	=== isMobileOnly	||
					true	=== isTablet		||
					true	===	isIPad13
				) ?
					(
						<Fragment>
							<IllegalInfoPanel
								isOpen={ props.illegalInfoOpen }
								data={props.illegalData}
								currentAddressDepth1={props.currentAddressDepth1}
								currentAddressDepth2={props.currentAddressDepth2}
								currentLocationName={props.currentLocationName}
								setNumOfCurLocIllegalUlzip={props.setNumOfCurLocIllegalUlzip}
								openMarkerFromAutoSearchResult={props.openMarkerFromAutoSearchResult}
								moveCenterLoc={props.moveCenterLoc}
								toggleIllegalInfo={props.toggleIllegalInfo}
							/>
							
							<PlaceList
								isOpen={props.isPlaceListOpen}
								data={props.PlaceListData}
								pages={props.pages}
								moveCenterLoc={props.moveCenterLoc}
								searchResultClose={props.searchResultClose}
							/>
							
							<AutoSearchKeyword
								isOpen={props.isAutoSearchKeywordOpen}
								targetWords={props.targetWords}
								moveCenterLoc={props.moveCenterLoc}
								openMarkerFromAutoSearchResult={props.openMarkerFromAutoSearchResult}
								clearSearchKeyword={props.clearSearchKeyword}
								geocoder={props.geocoder}
								daum={props.daum}
								toggleAutoSearchExistsYN={props.toggleAutoSearchExistsYN}
							/>
							
							<HeadlineNews
								isOpen={
									false	===	props.illegalInfoOpen	&&
									false	===	props.isPlaceListOpen	&&
									false	===	props.isAutoSearchKeywordOpen
								}
								
								headlineNews={props.headlineNews}
							/>
						</Fragment>
					) :
					(
						<Fragment>
							<Box
								borderRadius={14}
								clone={true}
								m={0}
								p={0}
								
								display="flex"
								flexDirection="column"
								
								className={
									clsx( 
										classes.bgTransparent,
									)
								}
							>
								<Container
									className={
										clsx(
											classes.noGutters,
											classes.overflowXHidden,
											classes.overflowYHidden,
										)
									}
									
									//fixed={true}
									disableGutters={true}
									
									maxWidth="xs"
									
									style={{
										position:"fixed",
										top: '1.5vh',
										right:"4rem",	
										zIndex: '2',
										
										maxHeight:"97vh",
										
										//display:"flex",
										//flexDirection:"column",
									}}
								>
									<AppBar
										className={
											clsx(
												classes.topMenuBar,
												//classes.bgWhite,
												classes.bgTransparent,
												classes.padding1_5X,
											)
										}
										
										//color="inherit"
										//color="transparent"
										
										position="relative"
										
										style={{
											height:"3rem",
											boxShadow:"none",
										}}
									>
										<Tabs
											variant="standard"
											value={value}
											onChange={setTabIndex} 
											aria-label="simple tabs example"
											
											style={{
												color:"black",
											}}
										>
											<Tab
												className={
													clsx(
														( 0 === value ) ?
															classes.bgWhite : classes.bgLightGreyDark,
														classes.fontWeightBold,
													)
												}
												
												style={{
													borderTopLeftRadius:"14px",
													borderTopRightRadius:"14px",
													minWidth:"5rem",
												}}
												
												label="검 색"
												{...a11yProps(0)}
												onClick={
													(e) => {
														//if ( 1 === value ) {
														//	props.toggleIllegalInfo();
														//}
													}
												}
											/>
											
											<Tab
												className={
													clsx(
														( 1 === value ) ?
															classes.bgWhite : classes.bgLightGreyDark,
														classes.fontWeightBold,
													)
												}
												
												style={{
													borderTopLeftRadius:"14px",
													borderTopRightRadius:"14px",
													minWidth:"5rem",
												}}
												
												label={
													//<StyledBadge
													//	badgeContent={
													//		//props.numOfCurrentLocIllegal
													//		numOfCurrentLocIllegal
													//	} 
													//	showZero={false}
													//	
													//	color="error"
													//>
													//	{
															"위 반"
													//	}
													//</StyledBadge>
												}
												{...a11yProps(1)}
												onClick={
													(e) => {
														//if ( 1 !== value ) {
														//	props.toggleIllegalInfo();
														//}
													}
												}
											/>
										</Tabs>
									</AppBar>
									<TabPanel 
										
										value={value} 
										index={0}
										
									>
										<Box
											display="flex"
											flexDirection="column"
											
											style={{
												width:"100%",
											}}
										>
											<form
												
												noValidate 
												autoComplete="off"
												
												className={
													clsx(
														//classes.paddingY,
														classes.paddingTop,
														classes.paddingBottomSmall,
														classes.padding2X,
														classes.grow,
													)
												}
												
												style={
													{
														//width:"100%",
														height:"calc( 4px + 2rem )",
													}
												}
												
												onSubmit={props.searchKeywordSubmit}
											>
												<FormControl 
													className={
														clsx(
															//classes.paddingTop,
															//classes.padding2X,
															//classes.paddingBottom1_5,
															//classes.noGutters, 
															//classes.textField,
														)
													}
													
													style={
														{
															//width:"100%",
															display:"flex",
														}
													}
												>
													<Input
														className={
															clsx(
																classes.grow,
															)
														}
														autoComplete="off"
														type={'text'}
														placeholder="어린이집, 유치원, 지역명" 
														value={props.searchText}
														onChange={props.searchKeywordChange}
														endAdornment={
															<InputAdornment 
																position="end"
															>
																{
																	( props.searchText.length > 0 ) ?
																		(
																			<IconButton
																				aria-label="clear"
																				onClick={props.clearSearchKeyword}
																				size="small"
																			>
																				<BackspaceIcon 
																					fontSize="default"
																				/>
																			</IconButton>
																		) : 
																		(
																			" "
																		)
																}
																
																<IconButton 
																	className={classes.button}
																	
																	aria-label="search"
																	//size="medium"
																	type="submit"
																>
																	<SearchIcon
																		fontSize="default"
																	/>
																</IconButton>
															</InputAdornment>
														}
														
														style={
															{
																//width:"100%",
																minHeight:"2rem",
															}
														}
													/>
												</FormControl>
												
											</form>
										</Box>
										
										
										{
											( true === props.isPlaceListOpen ) ?
												(
													<PlaceList
														isOpen={props.isPlaceListOpen}
														data={props.PlaceListData}
														pages={props.pages}
														moveCenterLoc={props.moveCenterLoc}
														searchResultClose={props.searchResultClose}
													/>
												) : null
										}
										
										{
											( true === props.isAutoSearchKeywordOpen ) ?
												(
													<AutoSearchKeyword
														isOpen={props.isAutoSearchKeywordOpen}
														targetWords={props.targetWords}
														moveCenterLoc={props.moveCenterLoc}
														openMarkerFromAutoSearchResult={props.openMarkerFromAutoSearchResult}
														clearSearchKeyword={props.clearSearchKeyword}
														geocoder={props.geocoder}
														daum={props.daum}
														toggleAutoSearchExistsYN={props.toggleAutoSearchExistsYN}
													/>
												) : null
										}
									
									</TabPanel>
									<TabPanel 
										
										value={value} 
										index={1}
										
									>
										<IllegalInfoPanel
											isOpen={ 
												//props.illegalInfoOpen 
												true
											}
											data={props.illegalData}
											currentAddressDepth1={props.currentAddressDepth1}
											currentAddressDepth2={props.currentAddressDepth2}
											currentLocationName={props.currentLocationName}
											setNumOfCurLocIllegalUlzip={props.setNumOfCurLocIllegalUlzip}
											openMarkerFromAutoSearchResult={props.openMarkerFromAutoSearchResult}
											moveCenterLoc={props.moveCenterLoc}
											toggleIllegalInfo={
												//props.toggleIllegalInfo
												null
											}
										/>
									</TabPanel>
								</Container>
							</Box>
							
						</Fragment>
					)
			}
		</Fragment>
		
	);
	
}