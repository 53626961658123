import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';

import clsx from 'clsx';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser, isIPad13 } from 'react-device-detect';

import IPickPageTitle	from "./IPickPageTitle.js";

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Box,
	Container,
	useScrollTrigger,
	CircularProgress,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
	Favorite		as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
	MoreVert		as MoreVertIcon,
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	overflowXHidden:{
		overflowX:"hidden",
	},
	overflowYHidden:{
		overflowY:"hidden",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	avatar: {
		backgroundColor: red[500],
 	},
}));



export default function IPickPageContainer ( props ) {
	
	const classes	= useStyles();

	const [scrollTarget, setScrollTarget]		= React.useState(undefined) ;
    const scrollTrigger							= useScrollTrigger({ target: scrollTarget });
	
	
	return (
		<Fragment>
			<IPickPageTitle
				maxWidth={props.maxWidth}
				
				zIndex={props.zIndex}
				
				scrollTrigger={scrollTrigger}
				type={props.type}
				
				pageTitleOnly={props.pageTitleOnly}	//// true인 경우 buttonList 는 출력하지 않음...
				pageTitle={props.pageTitle}
				closePage={props.closePage}
				
				closeButtonShowYN={props.closeButtonShowYN}
				
				buttonList={props.buttonList}
			/>
			<Container
				className={
					clsx(
						classes.noGutters,
						classes.grow,
						classes.bgWhite,
						
						//classes.overflowYScroll,
						classes.overflowXHidden,
						//classes.overflowYHidden,
					)
				}
				
				style={
					{
						zIndex:
							( 
								undefined	===	props.zIndex	||
								null		===	props.zIndex	||
								""			===	props.zIndex	||
								0			>=	props.zIndex
							) ?
								1 : props.zIndex,
						
					}
				}
				
				ref={
					( node ) => {
						if ( 
							null		!==	node	&&
							undefined	!==	node
						) {
							setScrollTarget( node );
						}
					}
				}
				
			>
				{
					( null !== props.data ) ?
						(
							<Fragment>
								{
									( 
										undefined	!==	props.pageHeader	&&
										null		!==	props.pageHeader
									) ?
										props.pageHeader : null
								}
								
								{
									( 
										undefined	!==	props.pageBody	&&
										null		!==	props.pageBody
									) ?
										props.pageBody : null
								}
								
								{
									( 
										undefined	!==	props.pageFooter	&&
										null		!==	props.pageFooter
									) ?
										props.pageFooter : null
								}
							</Fragment>
						) :
						(
							<ThemeProvider
								theme={markerInfoTheme}
							>
								<CircularProgress 
									className={
										clsx(
											"spin_center",
										)
									}
									
									style={
										{
											//position:"fixed",
											//
											//marginLeft:
											//	( isMobileOnly ) ?
											//		//"calc(50vw - 1.5rem)" : "calc(50vw - 2.5rem)",
											//		"calc(50vw - 1.5rem)" : "calc(50vw - 1.5rem)",
											//marginRight:
											//	( isMobileOnly ) ?
											//		//"calc(50vw - 1.5rem)" : "calc(50vw - 2.5rem)",
											//		"calc(50vw - 1.5rem)" : "calc(50vw - 1.5rem)",
											//marginTop:"50%",
											//marginBottom:"50%",
											//display:"inline-block",
											zIndex:"2",
										}
									}
									
									size={
										"3rem"
									}
									
									color={
										( "ulzip" === props.type ) ?
											"secondary" : 
											(
												( "kinder" === props.type ) ?
													"primary" : "primary"
											)
									}
								/>
							</ThemeProvider>
						)
				}
			</Container>
		</Fragment>
	);
	
}