import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import {
	isMobileOnly,
	isTablet,
	isIPad13,
} from 'react-device-detect';

//import { 
//	ThemeProvider 
//} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	//IconButton,
	//Fab,
	Box,
	//Modal,
	//Backdrop,
	//Divider,
	TextField,
	//InputAdornment,
	FormControl,
	//FormLabel,
	FormHelperText,
	//FormGroup,
	//FormControlLabel,
	//InputLabel,
	//Input,
	//Switch,
	//Slide,
	//Collapse,
	//Grow,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginBottom2:{
		marginBottom: theme.spacing(2),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		marginBottom:theme.spacing(0),
		marginLeft:theme.spacing(0),
		marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	
	containerItemCenter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));



export default function ForgetMyPasswordDetail ( props ) {
	
	const classes	= useStyles();
	
	const [values, setValues] = React.useState({
		email:"",
		
		
		//password: "",
		//passwordConfirm: "",
		//showPassword: false,
	});
	
	const handleChange = prop => event => {
		
		setValues({ 
			...values, 
			[prop]: event.target.value 
		});
		
		
		if ( "email" === prop ) {
			props.typeEmail( event );
		} //else if ( "password" === prop ) {
		//	props.typePassword( event );
		//} else if ( "passwordConfirm" === prop ) {
		//	props.typePasswordConfirm( event );
		//} 
		else {
			
		}
		
	};
	
	
	//const handleClickShowPassword = () => {
	//	setValues({ 
	//		...values, 
	//		showPassword: !values.showPassword 
	//	});
	//};
	//
	//const handleMouseDownPassword = event => {
	//	event.preventDefault();
	//};
	
	
	return (
		<Fragment>
			<Box
				//borderRadius="borderRadius"
				borderRadius={14}
				clone={true}
				
				m={0}
				p={0}
			>
				<Container
					maxWidth="sm"
					
					
					className={
						clsx(
							//classes.noGutters,
							//classes.padding,
							classes.padding2,
							classes.grow,
							classes.bgWhite,
							classes.overflowYScroll,
						)
					}
					
					style={{
						zIndex:1,
					}}
				>
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
						
						//style={{
						//	width:"100%",
						//}}
					>
						<Grid
							item
							
							//xs={12}	
							//sm={12}
							//md={12}
							//lg={12}
							//xl={12}
						>
							
							<Typography 
								className={
									clsx(
										classes.paddingX,
										classes.marginTop,
										classes.marginBottom2,
										//classes.fontWeightBold,
										classes.textCenter,
										classes.marginBottom,
									)
								}
								variant="h3" 
								
							>
								{
									"아이픽"
								}
							</Typography>
						</Grid>
						
						<Grid
							item
							
							//xs={12}	
							//sm={12}
							//md={12}
							//lg={12}
							//xl={12}
						>
							
							<Typography 
								className={
									clsx(
										classes.paddingX,
										classes.margin2Y,
										classes.textCenter,
									)
								}
								//variant="subtitle1" 
								variant="h6" 
							>
								{
									"비밀번호 재설정"
								}
							</Typography>
						</Grid>
					</Grid>
					
					
					<form
						
						//style={{
						//	width:"100%",
						//}}
					>
						{
							( false === props.isSuccessSendingMail ) ?
								(
									<Fragment>
										<Grid
											container
											direction="row"
											justify="center"
											alignItems="center"
										>
											<Grid
												item
												
												xs={12}	
												sm={12}
												md={12}
												lg={12}
												xl={12}
												
												className={
													clsx(
														classes.textCenter,
														classes.textSmall,
													)
												}
											>
												{
													"가입한 이메일 주소를 입력해주세요."
												}
												<br/>
												{
													"이메일 인증 완료 후 비밀번호를 재설정할 수 있습니다."
												}
											</Grid>
										</Grid>
										
										<TextField
											
											className={
												clsx(
													classes.marginTop2,
													classes.textField
												)
											}
											label="이메일"
											type="email"
											name="email"
											autoComplete="email"
											value={values.email}
											//margin="dense"
											margin="none"
											variant="outlined"
											disabled={false}
											fullWidth={true}
											
											onChange={handleChange("email")}
										/>
										
										{
											( "" === props.errMsg ) ?
												(
													<FormControl
														className={
															clsx(
																classes.formControl,
															)
														} 
														error={!props.isValidEmail}
													>
														<FormHelperText 
															className={
																clsx(
																	classes.formHelperText,
																)
															} 
															error={!props.isValidEmail}
														>
															{
																( 0 === values.email.length )?
																	"" :
																	(
																		( true === props.isValidEmail )?
																			"유효한 이메일 형식입니다." : "이메일 형식이 유효하지 않습니다."
																	)
															}
														</FormHelperText>
													</FormControl>
												) :
												(
													<FormControl
														className={
															clsx(
																classes.formControl,
															)
														} 
														error={true}
													>
														<FormHelperText 
															className={
																clsx(
																	classes.formHelperText,
																)
															} 
															error={true}
														>
															{
																props.errMsg
															}
														</FormHelperText>
													</FormControl>
												)
										}
										
										
										<Grid
											container
											direction="row"
											justify="space-around"
											alignItems="center"
											
											className={
												clsx(
													classes.marginTop,
												)
											}
										>
											<Grid
												item
												
												xs={5}
												sm={5}
												md={5}
												lg={5}
												xl={5}
												
											>
												<Button 
													component={Link}
													to={
														(
															true	=== isMobileOnly	||
															true	===	isTablet		||
															true	===	isIPad13
														) ?
															"/m" : "/"
													}
													variant="outlined"
													fullWidth={true}
												>
													{ "홈으로" }
												</Button>
											</Grid>
											<Grid
												item
												
												xs={5}
												sm={5}
												md={5}
												lg={5}
												xl={5}
											>
												<Button 
													disabled={!props.isValidEmail}
													variant="outlined"
													fullWidth={true}
													onClick={props.sendResetPasswordMail}
												>
													{
														"인증 메일 받기"
													}
												</Button>
											</Grid>
										</Grid>
										
									</Fragment>
								) :
								(
									<FormControl
										className={
											clsx(
												classes.formControl,
											)
										} 
										error={false}
									>
										<FormHelperText 
											className={
												clsx(
													classes.formHelperText,
												)
											} 
											error={false}
										>
											{
												props.msg
											}
										</FormHelperText>
									</FormControl>
								)
						}
					</form>
				</Container>
			</Box>
		</Fragment>
	);
}
