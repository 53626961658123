import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';

import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { //isMobile, 
	//isIOS, 
	isMobileOnly, 
	isTablet,
	//isAndroid, 
	//isBrowser,
	isIPad13, 
} from 'react-device-detect';


import IllegalInfoList		from './IllegalInfoList.js';
import IllegalInfoTitle		from './IllegalInfoTitle.js';


import { 
	fade,
	makeStyles,
	withStyles
} from '@material-ui/core/styles';

import {
	Slide,
	Box,
	Container,
	Grid,
	
	Paper,
	Typography,
	
	Button,
	//Badge
} from '@material-ui/core';


//import FavoriteIcon from '@material-ui/icons/Favorite';
//import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
//import ExploreIcon from '@material-ui/icons/Explore';

import {
	Favorite	as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
	Explore	as ExploreIcon,
}	from '@material-ui/icons';


class IllegalInfoPanel extends Component {
	
	constructor(props) {
		super(props);
		
		this.state	= {
			
			//isOpen : false,
			opening_illegal_ulzip_id	: "",
			
			current_loc_name			: "",
			
			current_loc_info			: "",
			current_loc_info_detail		: [],
			current_loc_info_title		: "",
			
			illegal_ulzip_query_all_yn : false,
            click_count : 0
			
		};
		
		this.illgalList = null;
		
		
		this.closeOtherIllegalUlzip		= this.closeOtherIllegalUlzip.bind(this);
		this.toggleIllegalQueryMode		= this.toggleIllegalQueryMode.bind(this);
	}
	
	
	componentDidMount ( ) {
		
		//console.log( "hihihi" );
        //console.log( this.props );
        
        
		if( 
			null	!==	this.props.currentLocationName	&&
			0		<	this.props.currentLocationName.length
		) {
			
			const currentLocationName	= this.props.currentLocationName;
			let cur_loc_name			= this.props.currentLocationName;
			cur_loc_name			= cur_loc_name.split( " " );
			let currentLocInfoDetail	= [];
			
			if( "세종특별자치시" === cur_loc_name[0] ) {
				
				currentLocInfoDetail.push( cur_loc_name[0] );
				
			} else {
				
				for ( let i = 0 ; i < cur_loc_name.length ; i++ ) {
					
					if( i < 2 ) {
						currentLocInfoDetail.push( cur_loc_name[i] );
					} else {
						
						if( "구" === cur_loc_name[i][ cur_loc_name[i].length - 1 ] ) {
							currentLocInfoDetail.push( cur_loc_name[i] );
						}
						
						break;
						
					}
					
				}
				
			}
			
			let currentLocInfo		= "";
			let currentLocInfoTitle	= "";
			
			
			if( "세종특별자치시" === cur_loc_name[0] ) {
				
				currentLocInfoTitle	= currentLocInfoDetail[0];
				
			} else {
				
				for( let i = 1 ; i < currentLocInfoDetail.length ; i++ ) {
					//currentLocInfoTitle	= currentLocInfoTitle + currentLocInfoDetail[i] + " ";
					currentLocInfoTitle	= currentLocInfoTitle + currentLocInfoDetail[i];
					
					if( i < currentLocInfoDetail.length - 1 ) {
						currentLocInfoTitle	= currentLocInfoTitle + " ";
					}
				}
				
			}
			
			for( let i = 0 ; i < currentLocInfoDetail.length ; i++ ) {
				//currentLocInfo	= currentLocInfo + currentLocInfoDetail[i] + " ";
				currentLocInfo	= currentLocInfo + currentLocInfoDetail[i];
				
				if( i < currentLocInfoDetail.length - 1 ) {
					currentLocInfo	= currentLocInfo + " ";
				}
			}
			
			this.setState({
				//isOpen : false,
				opening_illegal_ulzip_id	: "",
				
				
				current_loc_name		: currentLocationName,
				current_loc_info		: currentLocInfo,	
				current_loc_info_detail	: currentLocInfoDetail,
				current_loc_info_title	: currentLocInfoTitle
			});
				
			
		}
		
	}
	
	
	
	static getDerivedStateFromProps( nextProps, prevState ) { 
		
		
		if( 
			prevState.current_loc_name !== nextProps.currentLocationName 
		) {
			//console.log('change');
			//console.log(prevState.current_loc_name);
			//console.log(nextProps.currentLocationName);
			
			const currentLocationName	= nextProps.currentLocationName;
			
			//let cur_loc_name			= nextProps.currentLocationName.split( " " );
			let cur_loc_name			= currentLocationName.split( " " );
			//console.log(cur_loc_name);
			let currentLocInfoDetail	= [];
			
			
			let currentLocInfo		= "";
			let currentLocInfoTitle	= "";
			
			if( "세종특별자치시" === cur_loc_name[0] ) {
				
				currentLocInfoDetail.push( cur_loc_name[0] );
				currentLocInfoDetail.push( "" ); //  detail 1,2 를 공백을 줌 
				currentLocInfoDetail.push( "" );
				
			} else {
				if( "제주특별자치도" === cur_loc_name[0] ) {
		
					cur_loc_name[0]  = '제주도';
				} 
				
				for ( let i = 0 ; i < cur_loc_name.length ; i++ ) {
					
					if( i < 2 ) {
						currentLocInfoDetail.push( cur_loc_name[i] );
					} else {
						
						if( "구" === cur_loc_name[i][ cur_loc_name[i].length - 1 ] ) {
							currentLocInfoDetail.push( cur_loc_name[i] );
						}
						
						break;
						
					}
					
				}
				
			}
			
			
			if( "세종특별자치시" === cur_loc_name[0] ) {
				
				//currentLocInfoTitle	= currentLocInfoDetail[0] + " ";
				currentLocInfoTitle	= currentLocInfoDetail[0];
				
			} else {
				
				for( let i = 1 ; i < currentLocInfoDetail.length ; i++ ) {
					//currentLocInfoTitle	= currentLocInfoTitle + currentLocInfoDetail[i] + " ";
					currentLocInfoTitle	= currentLocInfoTitle + currentLocInfoDetail[i];
					
					if( i < currentLocInfoDetail.length - 1 ) {
						currentLocInfoTitle	= currentLocInfoTitle + " ";
					}
				}
				
			}
			
			for( let i = 0 ; i < currentLocInfoDetail.length ; i++ ) {
				//currentLocInfo	= currentLocInfo + currentLocInfoDetail[i] + " ";
				currentLocInfo	= currentLocInfo + currentLocInfoDetail[i];
				
				if( i < currentLocInfoDetail.length - 1 ) {
					currentLocInfo	= currentLocInfo + " ";
				}
			}
			
			
			let opening_illegal_ulzip_id	= prevState.opening_illegal_ulzip_id;
			
			if( 
				//prevState.isOpen	!== nextProps.isOpen	&&
				false				=== nextProps.isOpen	&&
				""					!== prevState.opening_illegal_ulzip_id
			) { 
				
				opening_illegal_ulzip_id	= "";
				
			} 
			
			return { 
				opening_illegal_ulzip_id	: opening_illegal_ulzip_id,
				
				//isOpen					: nextProps.isOpen,
				current_loc_name		: currentLocationName,
				current_loc_info		: currentLocInfo,	
				current_loc_info_detail	: currentLocInfoDetail,
				current_loc_info_title	: currentLocInfoTitle
			}; 
			
		} else {
			
			if( 
				//prevState.isOpen	!== nextProps.isOpen	&&
				false				=== nextProps.isOpen	&&
				""					!== prevState.opening_illegal_ulzip_id
			) { 
				
				return { 
					opening_illegal_ulzip_id	: ""//,
					//isOpen						: nextProps.isOpen
				};
				
			}
			
		}
		
		return null;
	}
	
	
	closeOtherIllegalUlzip ( illegalUlzipId ) {
        
        //console.log('where');
        
        //console.log(this.state.click_count);
        try {
            if( 
                this.state.click_count == 2 && 
                false 
            ) { 
                    window.Native.showInAppReviewPopup('inappreview');
            }
        } catch (e) {
              console.error(e);
        }
        
		this.setState(prevState => {
           return {
               opening_illegal_ulzip_id:illegalUlzipId , 
               click_count: prevState.click_count + 1
                  }
        });
	
		
	}
	
	
	toggleIllegalQueryMode(e) {	
		this.setState({
			illegal_ulzip_query_all_yn:!this.state.illegal_ulzip_query_all_yn
		});
	}
	
	
	
	render ( ) {
		
		
		let illegal_ulzip_list_sigungu	= null;
		let illegal_ulzip_list_sido		= null;
		
		
		let currentLocationName	= this.props.currentLocationName;
		let cur_loc_name			= currentLocationName.split( " " );
		let currentLocInfoDetail	= [];
		let currentLocInfo		= "";
		let currentLocInfoTitle	= "";
			
		if( "세종특별자치시" === cur_loc_name[0] ) {
				
			currentLocInfoDetail.push( cur_loc_name[0] );
			currentLocInfoDetail.push( "" ); //  detail 1,2 를 공백을 줌 
			currentLocInfoDetail.push( "" );
			
		} else {
			if( "제주특별자치도" === cur_loc_name[0] ) {
			
				cur_loc_name[0]  = '제주도';
			} 
			
			for ( let i = 0 ; i < cur_loc_name.length ; i++ ) {
				
				if( i < 2 ) {
					currentLocInfoDetail.push( cur_loc_name[i] );
				} else {
					
					if( "구" === cur_loc_name[i][ cur_loc_name[i].length - 1 ] ) {
						currentLocInfoDetail.push( cur_loc_name[i] );
					}
					
					break;
					
				}
				
			}
			
		}
			
		
		if( "세종특별자치시" === cur_loc_name[0] ) {
				
			//currentLocInfoTitle	= currentLocInfoDetail[0] + " ";
			currentLocInfoTitle	= currentLocInfoDetail[0];
			
		} else {
			
			for( let i = 1 ; i < currentLocInfoDetail.length ; i++ ) {
				//currentLocInfoTitle	= currentLocInfoTitle + currentLocInfoDetail[i] + " ";
				currentLocInfoTitle	= currentLocInfoTitle + currentLocInfoDetail[i];
				
				if( i < currentLocInfoDetail.length - 1 ) {
					currentLocInfoTitle	= currentLocInfoTitle + " ";
				}
			}
			
		}
		
		for( let i = 0 ; i < currentLocInfoDetail.length ; i++ ) {
			//currentLocInfo	= currentLocInfo + currentLocInfoDetail[i] + " ";
			currentLocInfo	= currentLocInfo + currentLocInfoDetail[i];
			
			if( i < currentLocInfoDetail.length - 1 ) {
				currentLocInfo	= currentLocInfo + " ";
			}
		}
		
		
		
		
		if (
			null !==	this.props.data	&&
			0	 <		this.props.data.length
		) {
			
			
			illegal_ulzip_list_sigungu	= this.props.data.filter(
				( data ) => {
					
					let ret_obj	= null;
					
					let dataAddr2		= data.sido;
					let dataAddr		= data.sigungu;
					let currentSido		= currentLocInfoDetail[0];
					let currentSigungu	=	currentLocInfoDetail[1];
					
					
					dataAddr2	= dataAddr2.replace( ' ' ,'' );	// 특별시/광영시/도
					dataAddr	= dataAddr.replace(' ','');		// 시/군/구
					
					
					if( 
						""			!==	currentLocInfoDetail[2]	&&
						undefined	!== currentLocInfoDetail[2]
					) {
						currentSigungu	= currentSigungu + currentLocInfoDetail[2];
					}
					
					currentSigungu = currentSigungu.replace(' ','');
					
					
					
					if( 
						(
							""			!== currentSigungu	&&
							undefined	!== currentSigungu	&& 
							dataAddr	=== currentSigungu
						) &&
						(
							""			!== currentSido	&&
							undefined	!== currentSido	&&
							dataAddr2	=== currentSido
						)
					) {
						ret_obj = data;
					}
					
					return ret_obj;
					
				}
			);
			
			
			
			illegal_ulzip_list_sido	= this.props.data.filter(
				( data ) => {
					
					let dataAddr2	= data.sido;
					
					dataAddr2	= dataAddr2.replace( ' ' ,'' );
					
					let currentSido		= currentLocInfoDetail[0];
					
					let ret_obj	= null;
					
					if( 
						""			!== currentSido	&&
						undefined	!== currentSido	&&
						//dataAddr2.indexOf( currentSido ) >= 0 
						dataAddr2	=== currentSido
					) {
						ret_obj	= data;
					}
					
					return ret_obj;
					
				}
			);
			
			
			if( true === this.state.illegal_ulzip_query_all_yn ) {
				this.props.setNumOfCurLocIllegalUlzip( illegal_ulzip_list_sido.length );
			} else {
				this.props.setNumOfCurLocIllegalUlzip( illegal_ulzip_list_sigungu.length );
			}
			
		} else {
			
			illegal_ulzip_list_sigungu = null;
			illegal_ulzip_list_sido = null;
			
			this.props.setNumOfCurLocIllegalUlzip( 0 );
			
		}
		
        //console.log("hihi");
        //console.log( illegal_ulzip_list_sigungu );
		
		
		return (
			<Slide
				direction={
					( isMobileOnly ) ?
						"down" : 
						//"right"
						"left"
				}
				in={this.props.isOpen} 
				//mountOnEnter
				//unmountOnExit
			>
				
				<Container
					maxWidth="sm"
					
					
					style={
						( 
							true	=== isMobileOnly	||
							true	=== isTablet		||
							true	===	isIPad13
						) ?
							{
								position:"fixed",
								right:0,
								zIndex:"3",
								//zIndex:"2",
								margin:0,
								padding:0,
								
								maxHeight:"calc( 100% - 6rem )",
								borderRadius:"0.25rem",
								backgroundColor:"white",
								overflow:"hidden",
								display:"flex",
  								flexDirection:"column",
							} :
							{
								position:"relative",
								zIndex:"3",
								
								margin:0,
								padding:0,
								
								width:"100%",
								height:"100%",
								//maxHeight:"93vh",
								maxHeight:"calc( 93vh - 3rem )",
								
								borderRadius:"0.25rem",
								backgroundColor:"white",
								overflow:"hidden",
								display:"flex",
  								flexDirection:"column",
							}
					}
				>
					
					<IllegalInfoTitle
						allYN={this.state.illegal_ulzip_query_all_yn}
						currentAddressDepth1={this.props.currentAddressDepth1}
						currentLocInfoDetail={this.state.current_loc_info_detail}
						currentLocInfoTitle={this.state.current_loc_info_title}
						toggleIllegalQueryMode={this.toggleIllegalQueryMode}
						numOfData={
							( 
								true === this.state.illegal_ulzip_query_all_yn
							) ?
								(
									( null !== illegal_ulzip_list_sigungu ) ?
										illegal_ulzip_list_sigungu.length : 0
								) : 
								(
									( null !== illegal_ulzip_list_sido ) ?
										 illegal_ulzip_list_sido.length : 0
								)
						}
					/>	
					
					<IllegalInfoList
						currentLocInfo={this.state.current_loc_info}
						data={
							( true === this.state.illegal_ulzip_query_all_yn  ) ?
								illegal_ulzip_list_sido : illegal_ulzip_list_sigungu
						}
						closeOtherIllegalUlzip={this.closeOtherIllegalUlzip}
						openingIllegalUlzipId={this.state.opening_illegal_ulzip_id}
						openMarkerFromAutoSearchResult={this.props.openMarkerFromAutoSearchResult}
						moveCenterLoc={this.props.moveCenterLoc}
						toggleIllegalInfo={this.props.toggleIllegalInfo}
					/>
					
				</Container>
				
			</Slide>
		);
		
	}
	
}


export default IllegalInfoPanel;