import React, {
	Fragment
} from 'react';

import clsx from 'clsx';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';


import {
	Box,
	Container,
	Button,
	IconButton,
	Grid,
	Typography,
	
	GridList,
	Paper,
	Collapse
} from '@material-ui/core';


import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CloseIcon from '@material-ui/icons/Close';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		third		: {
			main:"#616161"	// lightGrey
		},
		error		: {
			main:"#c62828",
			light:"#f44336"
		},
		warning		: {
			main:"#ffa000",
		},
	}
});

const useStyles = makeStyles( (theme) => (
	{
		grow: {
			flexGrow: 1,
		},
		button:{
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		fab: {
			margin: theme.spacing(0),
			//padding: theme.spacing(1)
			//display: 'flex',
			//alignItems: 'center',
			//justifyContent: 'center'
		},
		noPadding:{
			padding: theme.spacing(0)
		},
		noMargin:{
			margin: theme.spacing(0)
		},
		noGutters:{
			margin: theme.spacing(0),
			padding: theme.spacing(0)
		},
		margin:{
			margin: theme.spacing(1),
		},
		marginSmall:{
			margin:theme.spacing(0.5),	
		},
		padding:{
			padding: theme.spacing(1),
		},
		paddingX:{
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
		paddingY:{
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		paddingSmall:{
			padding:theme.spacing(0.5)
		},
		paddingSmallX:{
			paddingLeft:theme.spacing(0.5),
			paddingRight:theme.spacing(0.5),
		},
		paddingSmallY:{
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(0.5),
		},
		textLeft:{
			textAlign:"left"
		},
		textCenter:{
			textAlign:"center"
		},
		textRight:{
			textAlign:"right",	
		},
		fontWeightBold:{
			fontWeight:700
		},
		fontWeightLight:{
			fontWeight:300
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: theme.spacing(0),
			padding: theme.spacing(1),
		},
		modalBox: {
			margin: theme.spacing(0),
			padding: theme.spacing(1),
			maxWidth:"95%",
			backgroundColor:"white"
		},
		card:{
			margin: theme.spacing(0),
			padding: theme.spacing(1),
			maxWidth:476
		},
		cardHeader:{
			textAlign:"center",
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		cardContent:{
			margin: theme.spacing(0),
			paddingLeft:theme.spacing(2),
			paddingRight:theme.spacing(2),
			paddingTop:theme.spacing(1),
			paddingBottom:theme.spacing(1),
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1)
		},
		bgLight:{
			backgroundColor:"#f8f9fa !important"
		},
		borderBasic:{
			border:"1"
		},
		rounded:{
			borderRadius:"0.25rem !important"
		},
		roundedPill:{
			borderRadius:"50rem !important"
		},
		borderColorError:{
			borderColor:"#d32f2f"
		},
		backgroundColorError:{
			backgroundColor:"#d32f2f"
		},
		textWhite:{
			color:"white"
		}
	}
));



export default function IllegalInfoDetailPeople ( props ) {
	
	const classes	= useStyles();	
	
	const [isOpen, toggleIllegalInfoDetail]	= React.useState(false);
	
	if( 
		props.data._id !== props.openingIllegalUlzipId	&&
		true === isOpen
	) {
	   toggleIllegalInfoDetail( false );
	}
	
	let measure_list	= null;
		
		
	if( 
		null		!== props.data.measure		&&
		undefined	!== props.data.measure
	) {
		measure_list	= props.data.measure.split("\n");
		
		measure_list	= measure_list.map(
			( data, idx ) => (
				
				<Grid
					key={"measure" + idx.toString()}
					className={
						clsx(
							classes.paddingX,
							classes.paddingSmallY
						)
					}
					item
					container
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid
						item
						
						className={
							clsx(
								classes.textLeft
							)
						}
						
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
					>
						{
							data
						}
					</Grid>
				</Grid>
				
			)
		);
	}
	
	
	let offense_list	= null;
	
	
	if( 
		null		!== props.data.offense		&&
		undefined	!== props.data.offense
	) {
		offense_list	= props.data.offense.split("\n");
		
		offense_list	= offense_list.map(
			( data, idx ) => (
				
				<Grid
					key={"offense" + idx.toString()}
					className={
						clsx(
							classes.paddingX,
							classes.paddingSmallY
						)
					}
					container
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid
						item
						
						className={
							clsx(
								classes.textLeft
							)
						}
						
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
					>
						{
							data
						}
					</Grid>
				</Grid>
				
			)
		);
	}
	
	
	return(
		<Fragment>
			<Grid
				className={
					clsx(
						classes.noMargin,
						//classes.paddingX,
						classes.paddingSmallX,
					)
				}
				
				container
				direction="column"
				justify="center"
				alignItems="center"
				
			>
				<Grid
					item
					container
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid
						item
						
						xs={2}
						sm={2}
						md={2}
						lg={2}
						xl={2}
					>
						<ThemeProvider
							theme={markerInfoTheme}
						>
							<Box
								color="error.contrastText"
								bgcolor={
									//"error.main"
									"error.light"
								}
								
								borderRadius={16}
								
								
								className={
									clsx(
										//classes.noGutters,
										classes.noMargin,
										classes.paddingSmall,
										classes.textCenter,
									)
								}
								
								style={{
									lineHeight:"100%",
								}}
							>
								<Typography
									//color="error.contrastText"
									color="inherit"
									variant="caption"
									noWrap={true}
									
									className={
										clsx(
											classes.fontWeightBold,
										)
									}
								>
									{props.data.sigungu}
								</Typography>
							</Box>
						</ThemeProvider>
					</Grid>
					<Grid
						item
						container
						direction="row"
						justify="space-between"
						alignItems="center"
						
						xs={8}
						sm={8}
						md={8}
						lg={8}
						xl={8}
						
						onClick={
							( e ) => {
								
								props.openMarkerFromAutoSearchResult( 
									{  
										data_type:"ulzip",
										ulzip_code:props.data.code
									} 
								);
								
								props.moveCenterLoc(props.data.addr, props.data.addr, 0, 0);
								
							}
						}
					>
						<Grid
							item
							
							className={
								clsx(
									classes.textCenter
								)
							}
							
							xs={5}
							sm={5}
							md={5}
							lg={4}
							xl={4}
						>
							<Typography
								className={
									clsx(
										classes.paddingSmall
									)
								}
								
								variant="h6" 
								component="h6"
								
							>
								{props.data.people_name}
							</Typography>
						</Grid>
						<Grid
							item
							
							className={
								clsx(
									classes.textCenter
								)
							}
							
							xs={7}
							sm={7}
							md={7}
							lg={8}
							xl={8}
						>
							{props.data.name_past}
						</Grid>
					</Grid>
					<Grid
						item
						
						className={
							clsx(
								//classes.textCenter,
								classes.textRight,
							)
						}
						
						xs={2}
						sm={2}
						md={2}
						lg={2}
						xl={2}
					>
						<ThemeProvider
							theme={markerInfoTheme}
						>
							<Box
								color={
									//"error.main" 
									"error.light" 
								}
								bgcolor="error.contrastText"
								
								className={
									clsx(
										classes.noGutters,
									)
								}
							>
								<IconButton
									className={
										clsx(
											//classes.paddingX,
											//classes.paddingSmallY,
											classes.noPadding,
											classes.noMargin,
										)
									}
									
									color="inherit"
									
									onClick={
										(e) => {
											if( false === isOpen ) {
												props.closeOtherIllegalUlzip( props.data._id );
											}
											
											toggleIllegalInfoDetail( !isOpen );
										}
									}
									
									//size="small"
									//size="medium"
								>
									{
										( false === isOpen ) ?
											(
												<AddBoxIcon 
													//fontSize="default"
													//fontSize="medium"
													fontSize="large"
													className={
														clsx(
															classes.noGutters,
														)
													}
												/>
											) :
											(
												<IndeterminateCheckBoxIcon
													//fontSize="default"
													//fontSize="medium"
													fontSize="large"
													className={
														clsx(
															classes.noGutters,
														)
													}
												/>
											)
									}
								</IconButton>
							</Box>
						</ThemeProvider>
					</Grid>
				</Grid>
				<Collapse
					in={isOpen}
					style={{
						width:"100%"
					}}
				>
					<Grid
						className={
							clsx(
								classes.paddingY
							)
						}
						item
						container
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Grid
							className={
								clsx(
									classes.textCenter
								)
							}
							
							item
							
							xs={3}
							sm={3}
							md={3}
							lg={3}
							xl={3}
						>
							{
								"어린이집명"
							}
						</Grid>
						<Grid
							item
							
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							xs={9}
							sm={9}
							md={9}
							lg={9}
							xl={9}
						>
							<Grid
								item
								
								className={
									clsx(
										classes.textCenter
									)
								}
								
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
							>
								{
									"위반당시"
								}
							</Grid>
							<Grid
								item
								
								className={
									clsx(
										classes.textCenter
									)
								}
								
								xs={8}
								sm={8}
								md={8}
								lg={8}
								xl={8}
							>
								{props.data.name_past}
							</Grid>
						</Grid>
					</Grid>
					
					<Grid
						className={
							clsx(
								classes.paddingY
							)
						}
						item
						container
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Grid
							item
							
							className={
								clsx(
									classes.textCenter
								)
							}
							
							xs={3}
							sm={3}
							md={3}
							lg={3}
							xl={3}
						>
							{
								"위반 이력"
							}
						</Grid>
						<Grid
							item
							
							className={
								clsx(
									classes.textCenter
								)
							}
							
							xs={9}
							sm={9}
							md={9}
							lg={9}
							xl={9}
						>
							{props.data.history}
						</Grid>
					</Grid>
					
					<Grid
						className={
							clsx(
								classes.paddingY
							)
						}
						item
						container
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Grid
							item
							
							className={
								clsx(
									classes.textCenter
								)
							}
							
							xs={3}
							sm={3}
							md={3}
							lg={3}
							xl={3}
						>
							{
								"주 소"
							}
						</Grid>
						<Grid
							item
							
							className={
								clsx(
									classes.textCenter
								)
							}
							
							xs={9}
							sm={9}
							md={9}
							lg={9}
							xl={9}
						>
							{props.data.addr}
						</Grid>
					</Grid>
					
					<Grid
						className={
							clsx(
								classes.paddingY,
								classes.backgroundColorError,
								classes.textWhite,
								classes.rounded
							)
						}
						item
						container
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Grid
							className={
								clsx(
									classes.textCenter
								)
							}
							
							item
							
							xs={3}
							sm={3}
							md={3}
							lg={3}
							xl={3}
						>
							{"위반 행위"}
						</Grid>
						<Grid
							//className={
							//	clsx(
							//		classes.textCenter
							//	)
							//}
							
							item
							
							container
							direction="column"
							justify="center"
							alignItems="center"
							
							xs={9}
							sm={9}
							md={9}
							lg={9}
							xl={9}
						>
							{offense_list}
						</Grid>
					</Grid>
					<Grid
						className={
							clsx(
								classes.paddingY
							)
						}
						item
						container
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Grid
							className={
								clsx(
									classes.textCenter
								)
							}
							
							item
							
							xs={3}
							sm={3}
							md={3}
							lg={3}
							xl={3}
						>
							{"처분 내용"}
						</Grid>
						<Grid
							//className={
							//	clsx(
							//		classes.textCenter
							//	)
							//}
							
							item
							
							container
							direction="column"
							justify="center"
							alignItems="center"
							
							xs={9}
							sm={9}
							md={9}
							lg={9}
							xl={9}
						>
							{measure_list}
						</Grid>
					</Grid>
				</Collapse>
			</Grid>
		</Fragment>
	);
	
}