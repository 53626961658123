import axios		from 'axios';


	
export const getLoginInfo	= async ( ) => {
	
	let msg				= "";
	
	let user_id			= "";
	let user_email 		= '';
	let user_nickname 	= '';
	let login_yn 		= false;
	let nickname_confirm_yn	= true;
	let friend_share_link	= "";
	let	result			= false;
	
	try {
		
		const my_info = await axios.get( '/auth/myinfo' );
		
		//console.log( my_info.data );
		
		if ( "success" === my_info.data.result ) {
			
			if (
				null		!== my_info.data &&
				undefined	!== my_info.data &&
				null		!== my_info.data.user_info &&
				undefined	!== my_info.data.user_info &&
				(
					'' !== my_info.data.user_info.nickname || 
					'' !== my_info.data.user_info.email
				)
			) {
				login_yn 		= true;
				user_id			= my_info.data.user_info.user_id;
				user_email 		= my_info.data.user_info.email;
				user_nickname 	= my_info.data.user_info.nickname;
				friend_share_link	=  my_info.data.user_info.friend_share_link;
				nickname_confirm_yn	= my_info.data.user_info.nickname_confirm_yn;
				
				result		= true;
				
			} else {
				
				user_id			= "";
				user_email		= '';
				user_nickname	= '';
				login_yn		= false;
				nickname_confirm_yn	= true;
				friend_share_link	= "";
				msg	= "로그인 정보 조회 중에 오류가 발생했습니다.";
				
				result		= false;
			}
			
		} else {
			//console.log( my_info.data );
			user_id			= "";
			user_email		= '';
			user_nickname	= '';
			login_yn		= false;
			nickname_confirm_yn	= true;
			friend_share_link	= "";
			result			= false;
			
			if (
				"로그인 전 입니다."	 === my_info.data.msg	&&
				"error"				=== my_info.data.result
			) {
				msg		= "로그인 전 입니다.";
			} else {
				msg	= "로그인 정보 조회 중에 오류가 발생했습니다.";
				
			} 
			
		}
		
	} catch ( err ) {
		
		console.log(err);
		
		user_id			= "";
		user_email 		= '';
		user_nickname 	= '';
		login_yn 		= false;
		nickname_confirm_yn	= true;
		friend_share_link	= "";
		
		msg	= "로그인 정보 조회 중에 오류가 발생했습니다.";
		result		= false;
		
	} finally {
		
		return (
			{
				loginYN	: login_yn,
				userId	: user_id,
				userEmail: user_email,
				userNickname: user_nickname,
				nicknameConfirmYN : nickname_confirm_yn,
				friendShareLink : friend_share_link,
				
				msg:msg,
				result:result,
			}
		);
		
	}
}


export const getFavorite	= async ( ) => {
	
	let favorite_list	= [];
	let msg				= "";
	
	try {
		const getFavoriteResult = await axios.get('/user/getFavorite');
		
		if ( 'success' === getFavoriteResult.data.result ) {
			if ( 0 < getFavoriteResult.data.content.length ) {
				favorite_list = getFavoriteResult.data.content.map( ( data ) => {
					return {
						id_: data.code,
						name: data.name,
						type: data.type,
						address: data.addr,
						addressDepth1: data.addr_1,
						addressDepth2: data.addr_2,
						lat: data.la,
						lon: data.lo,
						gpsupdated: data.gpsupdated
					};
				});
			} //else {
				
			//}
		} else {
			msg	= getFavoriteResult.data.msg;
		}
		
	} catch ( err ) {
		
		console.log(err);
		msg	= "즐겨찾기 목록 조회 도중 오류가 발생했습니다.";
		
	} finally {
		
		if ( "" === msg ) {
			return {
				data	: favorite_list,
				msg		: msg,
			};
		} else {
			return {
				data	: null,
				msg		: msg,
			};;
		}
		
	}
}


export const userLogin	= async ( email, password ) => {
	
	let msg		= "";
	let result	= false;
	let userInfo	= {
			loginYN:false,
			userId	: "",
			userEmail: '',
			userNickname: '',
			nicknameConfirmYN:true,
			friendShareLink:"",
		};
	
	try {
		
		const ipick_login_result	= await axios.post( 
			"/auth/login",
			{
				email:email,
				password:password,
			}
		);
		
		//console.log( ipick_login_result.data );
		
		if( '401' === ipick_login_result.data.status ) {
			
			result	= false;
			msg		= '일치하는 정보가 없습니다.';
			
		} else {
			
			if( "success" === ipick_login_result.data.result ) { 
				
				result	= true;
				
				userInfo	= {
					loginYN:true,
					userId:ipick_login_result.data.content.user_id,
					userEmail: ipick_login_result.data.content.email,
					userNickname: ipick_login_result.data.content.nickname,
					nicknameConfirmYN:ipick_login_result.data.content.nickname_confirm_yn,
					friendShareLink: ipick_login_result.data.content.friend_share_link,
				};
				
			} else {
				
				result	= false;
				msg		= ipick_login_result.data.msg;
				
			}
		}
			
	} catch( err ) {
		
		console.log( err );
		
		result	= false;
		msg		= "로그인 시도 중 오류가 발생했습니다."
		
	} finally {
		
		return {
			result		: result,
			msg			: msg,
			userInfo	: userInfo
		};
		
	}
	
}
	

export const userLogout	= async ( e ) => {
	
	let result	= false;
	let msg		= "";
	
	try {
		
		const logoutResult	= await axios.get(
			"/auth/logout"
		);
		
		//console.log( logoutResult.data );
		
		if( "success" === logoutResult.data.result ) {
			
			result	= true;
			msg		= logoutResult.data.msg;
			
		} else {
			
			result	= false;
			msg		= logoutResult.data.msg;
			
		}
		
	} catch( err ) {
		
		console.log( err );
		result	= false;
		msg		= "로그아웃 도중 오류가 발생했습니다.";
		
	} finally {
		
		return {
			result	: result,
			msg		: msg,
		}
		
	}
}


export const withdrawUser	= async ( e ) => {
	
	let errorMsg		= "";
	let errorMsgType	= "warning";
	let result		= null;
	
	
	try {
		
		const withdrawResult	= await axios.get(
			"/user/withdraw?reason_type=default&reason=회원탈퇴"
		);
		
		//console.log( withdrawResult.data );
		
		//msg: "회원탈퇴가 정상적으로 처리되었습니다."
		//result: "success"
		
		result	= withdrawResult.data;
		
		if (
			"success" !== result.result
		) {
			errorMsg		= result.msg;
			errorMsgType	= "error";
		} else {
			errorMsg		= result.msg;
			errorMsgType	= "success";
		}
		
	} catch ( err ) {
		
		console.log( err );
		errorMsg		= "회원탈퇴 처리 중에 오류가 발생했습니다."
		errorMsgType	= "error";
		
	} finally {
		
		if (
			"success" !== result.result
		) {
			return {
				result	: "error",
				msg		: errorMsg,
				msgType	: errorMsgType
			};
		} else {
			return {
				result	: result.result,
				msg		: errorMsg,
				msgType	: errorMsgType
			};
		}
		
	}
	
}