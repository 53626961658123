import { combineReducers } from 'redux';


import currentPageSync		from './currentPageSync';
import userAuthentication	from './userAuthentication';
import userFavoriteManager	from './userFavoriteManager';

export default combineReducers({
	currentPageSync,
	userAuthentication,
	userFavoriteManager,
});