import React, { Component, Fragment } from 'react';

import { 
	isMobile, 
	isIOS, 
	isMobileOnly, 
	isTablet, 
	isAndroid, 
	isBrowser,
	isIPad13,
} from 'react-device-detect';

import queryString from 'query-string';



class FriendShare extends Component {
	
	
	constructor(props) {
		super(props);

		this.callFriendShare = this.callFriendShare.bind(this);
	}

	
	componentDidMount() {
		
		this.callFriendShare();
		
	}

	
	async callFriendShare( ) {
		
		if ( 
			null		!== localStorage.getItem( 'last_visit_date' )	&&
			undefined	!== localStorage.getItem( 'last_visit_date' )
		) {
			
			console.log('already visited');
			
		} else {
			
			console.log('new visitor');
			
			try {
				
				const url					= this.props.location.search;
				const friend_share_request	= await fetch(
					'friendShare?share=' + queryString.parse( url ).share
				);
				
				const friend_share_result = await friend_share_request.json();
				
				console.log(friend_share_result);
				
			} catch (e) {
				
				console.log(e);
				throw new Error('FriendShareFailed');
				
			} finally {
				this.props.history.push('/');		// props 은 읽기만 가능...
			}
			
		}
		
		this.props.history.push('/');		// props 은 읽기만 가능...
		//동작됨
		// 오잉? 그래? 이상하네
	}

	
	
	render ( ) {
		
		
		const url	= this.props.location.search;
		let params	= queryString.parse(url);
		
		//console.log(url);
		//console.log(params);
		
		
		return ( 
			<Fragment>
			</Fragment>
		);
		
		
	}
}

export default FriendShare;