import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { 
	isMobile, 
	isIOS, 
	isMobileOnly, 
	isTablet, 
	isAndroid, 
	isBrowser,
	isIPad13,
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	//Fab,
	Box,
	//Modal,
	//Backdrop,
	//Divider,
	TextField,
	InputAdornment,
	FormControl,
	FormLabel,
	FormHelperText,
	FormGroup,
	FormControlLabel,
	//InputLabel,
	//Input,
	Switch,
	//Slide,
	Collapse,
	Grow,
	AppBar,
	Toolbar,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';

import {
	//Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	//UnfoldLess	as UnfoldLessIcon,
	//ExpandMore	as ExpandMoreIcon,
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	largeButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(2)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	padding2Y:{
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	paddingBottom2:{
		paddingBottom: theme.spacing(2),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		marginBottom:theme.spacing(0),
		marginLeft:theme.spacing(0),
		marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
}));



export default function MyPageDetail ( props ) {
	
	const classes	= useStyles();
	const [values, setValues] = React.useState({
		nickName:"",
		
		
		password: "",
		passwordConfirm: "",
		showPassword: false,
	});
	
	const handleChange = prop => event => {
		
		setValues({ 
			...values, 
			[prop]: event.target.value 
		});
		
		
		if ( "nickName" === prop ) {
			props.typeNickName( event );
		} else if ( "password" === prop ) {
			props.typePassword( event );
		} else if ( "passwordConfirm" === prop ) {
			props.typePasswordConfirm( event );
		} else {
			
		}
		
	};
	
	const [checkedValues, setChecked] = React.useState({
		changeNickNameYN:false,
		changePasswordYN:false,
	});
	
	const toggleChecked = name => event => {
		
		setChecked({ 
			...checkedValues, 
			[name]: event.target.checked 
		});
		
		if ( "changeNickNameYN" === name ) {
			props.toggleNickNameChangeStatus( event );
			
			if ( false === event.target.checked ) {
				setValues({
					...values,
					["nickName"]:"" 
				});
			}
			
		} else if ( "changePasswordYN" === name ) {
			props.togglePasswordChangeStatus( event );
			
			if ( false === event.target.checked ) {
				setValues({
					...values,
					["password"]:"",
					["passwordConfirm"]:"" 
				});
			}
		} else {
			
		}
		
	};
	
	

	const handleClickShowPassword = () => {
		setValues({ 
			...values, 
			showPassword: !values.showPassword 
		});
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};
	
	
	if ( 
		"success"						===	props.errMsgType	&&
		"회원정보가 정상적으로 변경되었습니다."	===	props.msg			//&&
	) {
		if ( true === checkedValues.changeNickNameYN ) {
			
			if ( "" !== values.nickName ) {
				setValues({ 
					...values, 
					["nickName"]: ""
				});
			}
			
			setChecked({ 
				...checkedValues, 
				["changeNickNameYN"]: false
			});
			
		}
		
		
		if ( true === checkedValues.changePasswordYN ) {
			
			if ( "" !== values.password ) {
				setValues({ 
					...values, 
					["password"]: ""
				});
			}
			
			if ( "" !== values.passwordConfirm ) {
				setValues({ 
					...values, 
					["passwordConfirm"]: ""
				});
			}
			
			if ( true === values.showPassword ) {
				setValues({ 
					...values, 
					["showPassword"]: false
				});
			}
			
			//setTimeout(
			//	setChecked({ 
			//		...checkedValues, 
			//		["changePasswordYN"]: false
			//	}),
			//	2000
			//);
			
			//setChecked({ 
			//	...checkedValues, 
			//	["changePasswordYN"]: false
			//});
			
		}
		
		
		props.setMsg( "" );
	}
	
	
	return (
		<Fragment>
			
			<AppBar
				position="relative"
				
				className={
					clsx(
						classes.noGutters,
						classes.topMenuBar,
						//classes.bgWhite,
					)
				}
				
				style={{
					zIndex:2
				}}
			>
				<Toolbar
					className={
						clsx(
							classes.topMenuBarList,
						)
					}
					
					disableGutters={true}
				>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						
					>
						<Grid
							item
							
							xs={2}
							sm={2}
							md={2}
							lg={2}
							xl={2}
							
							className={
								clsx(
									classes.textCenter,
								)
							}
						>
							{
							//<ThemeProvider
							//	theme={markerInfoTheme}
							//>
							//	<Box
							//		bgcolor={
							//			"fourth.contrastText"
							//		}
							//		
							//		color={
							//			"fourth.dark"
							//		}
							//		
							//		//clone={true}
							//		
							//		className={
							//			clsx(
							//				classes.noGutters,
							//			)
							//		}
							//	>
							}
									{
										(
											true	=== isMobileOnly	||
											true	=== isTablet		||
											true	===	isIPad13	
										) ?
											( " " ) :
											(
												<IconButton
													className={
														clsx(
															classes.smallButton,
														)
													}
													
													//size="medium"
													color={
														"inherit"
														//"fourth.main"
													}
													
													onClick={
														(e) => {
															props.closeLoginPanel();
														}
													}
												>
													<ChevronLeftIcon
														fontSize="large"
													/>
												</IconButton>
											)
									}
							{
							//	</Box>
							//</ThemeProvider>
							}
						</Grid>
						<Grid
							item
							xs={8}
							sm={8}
							md={8}
							lg={8}
							xl={8}
							
							className={
								clsx(
									classes.textCenter
								)
							}
						>
							<Typography
								className={
									clsx(
										
									)
								}
								color={"inherit"}
								variant="h6" 
							>
								{
									"내 정보 변경"
								}
							</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sm={2}
							md={2}
							lg={2}
							xl={2}
							
							className={
								clsx(
									classes.textCenter
								)
							}
						/>
					</Grid>
				</Toolbar>
			</AppBar>
			
			<Container
				className={
					clsx(
						//classes.noGutters,
						//classes.padding,
						classes.padding2X,
						
						//classes.paddingBottom2,
						//classes.paddingTop,
						classes.padding2Y,
						
						classes.grow,
						classes.bgWhite,
						classes.overflowYScroll,
					)
				}
				
				style={{
					zIndex:1,
				}}
			>
				<Grid
					container
					direction="row"
					//justify="center"
					justify="space-between"
					alignItems="center"
				>
					<Grid
						item
						
						className={
							clsx(
								classes.textCenter,
							)
						}
					>
						<ThemeProvider
							theme={markerInfoTheme}
						>
							<Box
								bgcolor={
									"fourth.contrastText"
								}
								
								color={
									"fourth.dark"
								}
								
								//clone={true}
								
								className={
									clsx(
										classes.noGutters,
									)
								}
							>
								<Icon
									className={"fas fa-user-cog"}
									color="inherit" 
									//fontSize="large"
									
									style={{
										overflow:"visible",
									}}
								/>
							</Box>
						</ThemeProvider>
					</Grid>
					<Grid
						item
						
						className={
							clsx(
								classes.grow,
								classes.padding3X,
							)
						}
					>
						<ThemeProvider
							theme={markerInfoTheme}
						>
							<Box
								bgcolor={
									"fourth.contrastText"
								}
								
								color={
									"fourth.dark"
								}
								
								//clone={true}
								
								className={
									clsx(
										classes.noGutters,
									)
								}
							>
								<Typography
									className={
										clsx(
											
										)
									}
									color={"inherit"}
									variant="h6" 
								>
									{
										//"내 정보"
									}
								</Typography>
							</Box>
						</ThemeProvider>
					</Grid>
					<Grid
						item
						
						className={
							clsx(
								classes.textCenter,
							)
						}
					>
						<ThemeProvider
							theme={markerInfoTheme}
						>
							<Box
								//bgcolor={
								//	"fourth.contrastText"
								//}
								
								color={
									"third.main"
								}
								
								clone={true}
								
								className={
									clsx(
										//classes.noGutters,
									)
								}
							>
								<Button
									variant="contained"
									
									color={"inherit"}
									
									className={
										clsx(
											classes.button,
										)
									}
									
									onClick={props.logout}
								>
									{"로그아웃"}
								</Button>
							</Box>
						</ThemeProvider>
					</Grid>
				</Grid>
				
				<FormGroup
					row={true}
					className={
						clsx(
							classes.padding,
						)
					}
				>
					<Grid
						item
						
						xs={9}	
						sm={9}
						md={9}
						lg={9}
						xl={9}
						
						className={
							clsx(
								//classes.paddingY,
								classes.paddingRight,
							)
						}
						
						//style={{
						//	display:"flex",
						//	flexDirection:"row",
						//}}
					>
						<TextField
							
							className={
								clsx(
									//classes.marginX,
									//classes.textField
								)
							}
							label={
								//(
								//	true	=== isMobileOnly	||
								//	true	=== isTablet		||
								//	true	===	isIPad13
								//) ? 
									"이메일"// : ""
							}
							type="email"
							value={
								( "" === props.userInfo.userEmail ) ?
									"미등록" : props.userInfo.userEmail
							}
							//margin="dense"
							margin="none"
							variant="outlined"
							disabled={true}
							fullWidth={true}
						/>
					</Grid>
					<Grid
						item
						
						xs={3}	
						sm={3}
						md={3}
						lg={3}
						xl={3}
						
						//className={
						//	clsx(
						//		//classes.paddingY,
						//		classes.paddingRight,
						//	)
						//}
					/>
				</FormGroup>
				
				<form
					className={
						clsx(
							classes.noMargin,
							classes.padding,
						)
					}
				>
					<FormControl
						className={
							clsx(
								classes.formControl,
								//classes.marginTop2,
							)
						} 
					>
						<FormGroup 
							//aria-label="position" 
							row={true}
						>
							<FormControlLabel
								//name="닉네임_스위치"
								control={
									<Switch
										checked={checkedValues.changeNickNameYN}
										color="primary"
										
										//disabled={ !props.changeYN }
										
										onChange={toggleChecked("changeNickNameYN")}
									/>	
								}
								label="닉네임 변경"
								labelPlacement="end"
							/>
						</FormGroup>
					</FormControl>
					
					{
					//<Collapse
					//	in={checkedValues.changeNickNameYN}
					//>
					}
					
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={9}	
								sm={9}
								md={9}
								lg={9}
								xl={9}
								
								className={
									clsx(
										//classes.paddingY,
										classes.paddingRight,
									)
								}
							>
								<TextField
									className={
										clsx(
											
										)
									}
									label=""
									type="text"
									value={
										( 
											!checkedValues.changeNickNameYN 
										) ?
											props.userInfo.userNickname : values.nickName
									}
									
									placeholder={
										(
											true === checkedValues.changeNickNameYN
										) ?
											"새로운 닉네임 입력" : ""
									}
									
									//margin="dense"
									margin="none"
									variant="outlined"
									disabled={
										//!props.changeYN	||
										//!props.changeNickNameYN
										!checkedValues.changeNickNameYN
									}
									onChange={handleChange("nickName")}
									
									fullWidth={true}
								/>
							</Grid>
							
							<Grid
								item
								
								xs={3}	
								sm={3}
								md={3}
								lg={3}
								xl={3}
								
								className={
									clsx(
										classes.textRight,
										classes.paddingSmall,
									)
								}
							>
								<Collapse
									in={checkedValues.changeNickNameYN}
								>
									<Button
										className={
											clsx(
												classes.smallButton,
											)
										}
										
										size="small"
										variant="outlined"
										
										disabled={
											( true === props.isValidNickName && values.nickName.length > 0 ) ?
												false : true
										}
										
										onClick={props.checkNickName}
									>
										{"중복확인"}
									</Button>
								</Collapse>
							</Grid>
						</Grid>
						
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={12}	
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
								className={
									clsx(
										//classes.paddingY,
										classes.paddingRight,
									)
								}
							>
								{
									( values.nickName.length <= 0 ) ?
										(
											<FormControl
												className={
													clsx(
														classes.formControl,
													)
												} 
												error={false}
											>
												<FormHelperText 
													className={
														clsx(
															classes.formHelperText,
														)
													} 
													error={false}
												>
													{
														""
													}
												</FormHelperText>
											</FormControl>
										) :
										(
											( 0 >= props.msg.length ) ?
												(
													<FormControl
														className={
															clsx(
																classes.formControl,
															)
														} 
														error={!props.isValidNickName}
													>
														<FormHelperText 
															className={
																clsx(
																	classes.formHelperText,
																)
															} 
															error={!props.isValidNickName}
														>
															{
																( true === props.isValidNickName ) ?
																	"사용 가능한 닉네임 형식입니다." : 
																	//"사용 불가능한 닉네임 형식입니다."
																	"사용 불가능한 닉네임 형식입니다. ('문자', '숫자', '밑줄 문자' 포함 2자 ~ 16자 이내)"
															}
														</FormHelperText>
													</FormControl>
												) :
												(
													<FormControl
														className={
															clsx(
																classes.formControl,
															)
														} 
														error={
															( "success" === props.userNickNameCheckResult ) ?
																false : true
														}
													>
														<FormHelperText 
															className={
																clsx(
																	classes.formHelperText,
																)
															} 
															error={
																( "success" === props.userNickNameCheckResult ) ?
																	false : true
															}
														>
															{
																props.msg
															}
														</FormHelperText>
													</FormControl>
												)
										)
								}
								
							</Grid>
						</Grid>
						
					{
					//</Collapse>
					}
					
					
					{
					//<Grid
					//	container
					//	direction="row"
					//	justify="center"
					//	alignItems="center"
					//>
					//	<Grid
					//		item
					//		
					//		xs={12}	
					//		sm={12}
					//		md={12}
					//		lg={12}
					//		xl={12}
					//		
					//		className={
					//			clsx(
					//				classes.textCenter,
					//			)
					//		}
					//	>
					//		<Grow
					//			appear={false} 
					//			//direction="down"
					//			in={!checkedValues.changeNickNameYN}
					//		>
					//			<ExpandMoreIcon
					//				//UnfoldLessIcon
					//				//CloseIcon
					//				fontSize="small"
					//			/>
					//			
					//		</Grow>
					//	</Grid>
					//</Grid>
					}
					
					
					<FormControl
						className={
							clsx(
								//classes.formControl,
								classes.paddingSmall,
							)
						} 
					>
						<FormGroup 
							//aria-label="position" 
							row={true}
						>
							<FormControlLabel
								//name="비밀번호"
								control={
									<Switch
										checked={checkedValues.changePasswordYN}
										color="primary"
										
										//disabled={ !props.changeYN }
										
										onChange={toggleChecked("changePasswordYN")}
									/>	
								}
								label="비밀번호 변경"
								labelPlacement="end"
							/>
						</FormGroup>
					</FormControl>
					
					<Collapse
						in={checkedValues.changePasswordYN}
					>
						<TextField
							className={
								clsx(
									//classes.marginX,
									//classes.textField
								)
							}
							label="비밀번호"
							value={values.password}
							type={values.showPassword ? 'text' : 'password'}
							//placeholder={"비밀번호"}
							
							//margin="dense"
							margin="none"
							variant="outlined"
							disabled={
								//!props.changeYN	|| 
								//!props.changePasswordYN
								!checkedValues.changePasswordYN
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											edge="end"
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{values.showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
							fullWidth={true}
							
							onChange={handleChange("password")}
						/>
						
						{
							( 0 === values.password.length ) ?
								(
									<FormControl
												className={
											clsx(
												classes.formControl,
											)
										} 
										error={false}
									>
										<FormHelperText 
											className={
												clsx(
													classes.formHelperText,
												)
											} 
											error={false}
										>
											{
												""
											}
										</FormHelperText>
									</FormControl>
								) :
								(
									<FormControl
										className={
											clsx(
												classes.formControl,
											)
										} 
										error={!props.isValidPassword}
									>
										<FormHelperText 
											className={
												clsx(
													classes.formHelperText,
												)
											} 
											error={!props.isValidPassword}
										>
											{
												( props.isValidPassword )?
													"유효한 비밀번호 형식입니다." : "영문, 숫자, 특수문자를 조합하여 8-15자리 이내로 입력해주세요."
											}
										</FormHelperText>
									</FormControl>
								)
						}
						
						<TextField
							
							className={
								clsx(
									//classes.marginX,
									//classes.textField
									classes.marginTop,
								)
							}
							label="비밀번호 확인"
							value={values.passwordConfirm}
							type={values.showPassword ? 'text' : 'password'}
							//placeholder={"비밀번호 확인"}
							
							//margin="dense"
							margin="none"
							variant="outlined"
							disabled={
								//!props.changeYN	||
								//!props.changePasswordYN
								!checkedValues.changePasswordYN
							}
							
							fullWidth={true}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											edge="end"
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{
												( true === values.showPassword ) ? 
													(
														<VisibilityOff /> 
													) : 
													(
														<Visibility />
													)
											}
										</IconButton>
									</InputAdornment>
								),
							}}
							
							onChange={handleChange("passwordConfirm")}
						/>
						
						{
							( 0 === values.passwordConfirm.length )?
								(
									<FormControl
										className={
											clsx(
												classes.formControl,
											)
										} 
										error={false}
									>
										<FormHelperText 
											className={
												clsx(
													classes.formHelperText,
												)
											} 
											error={false}
										>
											{
												""
											}
										</FormHelperText>
									</FormControl>
								) :
								(
									<FormControl
										className={
											clsx(
												classes.formControl,
											)
										} 
										error={
											( !props.isValidPasswordConfirm )
										}
									>
										<FormHelperText 
											className={
												clsx(
													classes.formHelperText,
												)
											} 
											error={
												( !props.isValidPasswordConfirm )
											}
										>
											{
												( true === props.isValidPasswordConfirm )?
													"비밀번호와 일치합니다." : "비밀번호와 일치하지 않습니다."
											}
										</FormHelperText>
									</FormControl>
								)
						}
						
					</Collapse>
					
					{
					//<Grid
					//	container
					//	direction="row"
					//	justify="center"
					//	alignItems="center"
					//>
					//	<Grid
					//		item
					//		
					//		xs={12}	
					//		sm={12}
					//		md={12}
					//		lg={12}
					//		xl={12}
					//		
					//		className={
					//			clsx(
					//				classes.textCenter,
					//			)
					//		}
					//	>
					//		<Grow
					//			appear={false} 
					//			//direction="down"
					//			in={!checkedValues.changePasswordYN}
					//		>
					//			<ExpandMoreIcon
					//				fontSize="small"
					//			/>
					//		</Grow>
					//	</Grid>
					//</Grid>
					}
					
					
					<Grow
						appear={true} 
						in={
							true	=== checkedValues.changeNickNameYN	||
							true	=== checkedValues.changePasswordYN
						}
					>
						<FormGroup 
							//aria-label="position" 
							row={true}
							
							className={
								clsx(
									classes.marginTop,
								)
							}
						>
							<Button 
								fullWidth={true}
								disabled={
									( !props.changeableYN )	||
									(
										true	=== checkedValues.changeNickNameYN	&&
										""		=== values.nickName
									)	||
									(
										true	=== checkedValues.changePasswordYN	&&
										(
											""		=== values.password			||
											""		=== values.passwordConfirm
										)
									)
								}
								variant="outlined"
								onClick={props.changeMyInfo}
							>
								{
									"회원정보 변경"
								}
							</Button>
						</FormGroup>
					</Grow>
					
					<Grow
						appear={true} 
						in={
							false	=== checkedValues.changeNickNameYN	&&
							false	=== checkedValues.changePasswordYN
						}
					>
						<FormGroup 
							//aria-label="position" 
							row={true}
							
							className={
								clsx(
									classes.marginTop,
								)
							}
							
							style={{
								justifyContent:"flex-end"
							}}
						>
							<ThemeProvider
								theme={markerInfoTheme}
							>
								<Box
									color={
										"third.light"
									}
									
									className={
										clsx(
											classes.noGutters,
										)
									}
								>
									<FormControlLabel
										//name="회원탈퇴_스위치"
										control={
											<ThemeProvider
												theme={markerInfoTheme}
											>
												<Box
													color={
														"third.light"
													}
													
													clone={true}
													
													className={
														clsx(
															//classes.noGutters,
														)
													}
												>
													<Button 
														fullWidth={false}
														variant="outlined"
														
														color={"inherit"}
														
														className={
															clsx(
																classes.smallButton,
																classes.marginLeft,
															)
														}
														
														onClick={props.toggleWithdrawUserPanelOpen}
													>
														{
															"회원 탈퇴"
														}
													</Button>
												</Box>
											</ThemeProvider>
										}
										label="탈퇴를 원하시나요?"
										labelPlacement="start"
										
										className={
											clsx(
												classes.noGutters,
											)
										}
									/>
								</Box>
							</ThemeProvider>
						</FormGroup>
					</Grow>
					
				</form>
			</Container>
		</Fragment>
	);
}


//MyPageDetail.propTypes = {
//  msg: PropTypes.string
//};