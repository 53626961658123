import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Box,
	//ButtonGroup,
	//Slide,
} from '@material-ui/core';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
}));


export default function MarkerUlzip ( props ) {
	
	const classes	= useStyles();
	
	let standardCurriculum	= null;
	let ulzipBasicFee		= null;
	let ulzipEtcFee			= null;
	let ulzipSpecialFee		= null;
	
	let authHistory			= [];
	let authHistoryList		= null;
	let authUrl				= null;
	
	//console.log( props );
	
	if( null !== props.data.curriculum ) {
		
		if ( 
			null		!== props.data.curriculum.standard_curriculum	&&
			undefined	!== props.data.curriculum.standard_curriculum
		) {
			
			//console.log( props.data.curriculum.standard_curriculum );
			
			standardCurriculum	= props.data.curriculum.standard_curriculum.map(
				( data, idx ) => (
					<Grid
						key={ props.id + "standard_curriculum" + idx }
						
						container
						direction="row"
						justify="center"
						alignItems="center"
						
						className={
							clsx(
								classes.padding2X,
								classes.paddingSmallY,
							)
						}
					>
						<Grid
							item
							
							xs={6}
							sm={6}
							md={7}
							lg={7}
							xl={7}
							
							className={
								clsx(
									classes.paddingX,
								)
							}
						>
							{
								data[0].replace("(필수)", "").replace("(자율)", "")
							}
						</Grid>
						<Grid
							item
							
							xs={6}
							sm={6}
							md={5}
							lg={5}
							xl={5}
							
							className={
								clsx(
									classes.textRight,
									classes.fontWeightBold,
									classes.paddingX,
								)
							}
						>
							{
								( -1 !== data[1].indexOf("info.childcare.go.kr") ) ?
									(
										<ThemeProvider
											theme={markerInfoTheme}
										>
											<Button
												color={
													( "ulzip" === props.dataType ) ?
														"secondary" : "primary"
												}
												
												variant="contained"
												
												size="medium"
												//size="small"
												
												target="_blank"
												href={ data[1] }
												rel="noopener noreferrer"
												
												fullWidth={true}
											>
												{
													data[0].replace("(필수)", "").replace("(자율)", "")// + " 링크"
												}
											</Button>
										</ThemeProvider>
									) :
									(
										data[1]
									)
							}
						</Grid>
					</Grid>
				)
			);
			
		} else {
			
			standardCurriculum = (
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					
					className={
						clsx(
							classes.paddingSmallY,
						)
					}
				>
					<Grid
						item
						
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						
						className={
							clsx(
								classes.textCenter,
								
							)
						}
					>
						{'정보없음'}
					</Grid>
				</Grid>
			);
			
		}
		
		
		if( 
			null		!== props.data.curriculum.ulzip_fee	&&
			undefined	!== props.data.curriculum.ulzip_fee
		) {
			
			ulzipBasicFee	= props.data.curriculum.ulzip_fee.map(
				( data, idx ) => {
					
					if( 0 === idx && -1 !== data[0].indexOf( "항목" ) ) {
						
						return (
							<Grid
								key={ props.id + "ulzip_fee" + idx }
								
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.paddingSmallY,
										classes.borderBottom,
										classes.padding2X,
									)
								}
							>
								<Grid
									item
									
									xs={6}
									sm={6}
									md={6}
									lg={6}
									xl={6}
									
									className={
										clsx(
											classes.textCenter,
											classes.paddingX,
										)
									}
								>
									{
										data[0]
									}
								</Grid>
								<Grid
									item
									
									xs={6}
									sm={6}
									md={6}
									lg={6}
									xl={6}
									
									className={
										clsx(
											classes.textCenter,
											classes.paddingX,
										)
									}
								>
									{
										data[1]
									}
								</Grid>
							</Grid>
						);
						
					} else {
						
						return (
							<Grid
								key={ props.id + "ulzip_fee" + idx }
								
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.paddingSmallY,
										classes.padding2X,
									)
								}
							>
								<Grid
									item
									
									xs={6}
									sm={6}
									md={6}
									lg={6}
									xl={6}
									
									className={
										clsx(
											classes.textCenter,
											classes.paddingX,
										)
									}
								>
									{
										data[0]
									}
								</Grid>
								<Grid
									item
									
									xs={6}
									sm={6}
									md={6}
									lg={6}
									xl={6}
									
									className={
										clsx(
											classes.textCenter,
											classes.paddingX,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[1]
									}
								</Grid>
							</Grid>
						);
						
					}
					
				}
			);
			
		} else {
			
			ulzipBasicFee = (
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					
					className={
						clsx(
							classes.paddingSmallY,
						)
					}
				>
					<Grid
						item
						
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
					>
						{'정보없음'}
					</Grid>
				</Grid>
			);
			
		}
		
		
		if( 
			null		!== props.data.curriculum.etc_fee	&&
			undefined	!== props.data.curriculum.etc_fee
		) {
			
			ulzipEtcFee	= props.data.curriculum.etc_fee.map(
				( data, idx ) => {
					
					if( 0 === idx && -1 !== data[1].indexOf( "항목" ) ) {
						
						return (
							<Grid
								key={ props.id + "ulzip_etc_fee" + idx }
								
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.paddingSmallY,
										classes.borderBottom,
									)
								}
							>
								<Grid
									item
									
									xs={6}
									sm={6}
									md={6}
									lg={6}
									xl={6}
									
									className={
										clsx(
											classes.paddingX,
											classes.textCenter,
										)
									}
								>
									{
										//data[1] + "(원, 결제주기)"
										"항목"// + "(원, 결제주기)"
									}
								</Grid>
								<Grid
									item
									
									xs={6}
									sm={6}
									md={6}
									lg={6}
									xl={6}
									
									className={
										clsx(
											classes.paddingX,
											classes.textCenter,
										)
									}
								>
									{
										//data[2] + " (" + data[3] + ")"
										"수납액(결제주기)"
									}
								</Grid>
							</Grid>
						);
						
					} else {
						
						return (
							<Grid
								key={ props.id + "ulzip_etc_fee" + idx }
								
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.paddingSmallY,
										classes.padding2X,
									)
								}
							>
								<Grid
									item
									
									xs={6}
									sm={6}
									md={6}
									lg={6}
									xl={6}
									
									className={
										clsx(
											classes.paddingX,
											classes.textCenter,
										)
									}
								>
									{
										data[1]
									}
								</Grid>
								<Grid
									item
									
									xs={6}
									sm={6}
									md={6}
									lg={6}
									xl={6}
									
									className={
										clsx(
											classes.paddingX,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										( "분기" === data[3] ) ?
											(
												data[2] + " ( " + data[3] + " )"
											) :
											(
												data[2] + " (" + data[3] + ")"
											)
									}
								</Grid>
							</Grid>
						);
						
					}
					
				}
			);
			
		} else {
			ulzipEtcFee = (
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					
					className={
						clsx(
							classes.paddingSmallY,
						)
					}
				>
					<Grid
						item
						
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						
						className={
							clsx(
								classes.textCenter,
							)
						}
					>
						{'정보없음'}
					</Grid>
				</Grid>
			);	
		}
		
		
		if( 
			null		!== props.data.curriculum.special_fee	&&
			undefined	!== props.data.curriculum.special_fee
		) {
			
			ulzipSpecialFee	= props.data.curriculum.special_fee.map(
				( data, idx ) => {
					
					if( 0 === idx && -1 !== data[0].indexOf( "구분" ) ) {
						
						return (
							<Grid
								key={ props.id + "ulzip_special_fee" + idx }
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.borderBottom,
									)
								}
							>
								<Grid
									item
									
									xs={2}
									sm={2}
									md={2}
									lg={2}
									xl={2}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										( -1	!== data[0].indexOf( "구분" ) ) ?
											"구 분" : data[0]
									}
								</Grid>
								<Grid
									item
									
									xs={4}
									sm={4}
									md={4}
									lg={4}
									xl={4}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[2]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										//data[4] + "/"
										"주 횟수/"
									}
									<br/>
									{
										//data[5]
										"1회 시간"
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										( -1	!== data[6].indexOf( "프로그램단가" ) ) ?
											"비 용" : data[6]
									}
								</Grid>
							</Grid>
						);
						
					} else {
						return (
							<Grid
								key={ props.id + "ulzip_special_fee" + idx }
								
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Grid
									item
									
									xs={2}
									sm={2}
									md={2}
									lg={2}
									xl={2}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										//data[0]
										( -1 !== data[0].indexOf( "미운영" ) ) ?
											"미운영" : data[0]
									}
								</Grid>
								<Grid
									item
									
									xs={4}
									sm={4}
									md={4}
									lg={4}
									xl={4}
									
									className={
										clsx(
											classes.textCenter,
											classes.fontWeightBold,
										)
									}
								>
									{
										//data[2].replace(/\(.+[분시]/g, '')
										( -1 !== data[2].replace(/\(.+[분시]/g, '').indexOf( "미운영" ) ) ?
											"미운영" : data[2].replace(/\(.+[분시]/g, '')
									}
									<br/>
									{
										//"(" + data[1] + ")"
										( -1 !== data[1].indexOf( "미운영" ) ) ?
											"미운영" : "(" + data[1] + ")"
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										( -1 !== data[4].indexOf( "미운영" ) ) ?
											(
												"미운영"
											) :
											(
												( -1 !== data[4].indexOf("회") ) ?
													(
														data[4] + "/" + data[5]
													) :
													(
														data[4] + "회/" + data[5]
													)
											)
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
											classes.fontWeightBold,
										)
									}
								>
									{
										( -1 !== data[6].indexOf( "미운영" ) ) ?
											"미운영" :
											(
												data[6]
											)
									}
								</Grid>
							</Grid>
						);
					}
					
				}
			);
			
		} else {
			ulzipSpecialFee = (
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid
						item
						
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
					>
						{'정보없음'}
					</Grid>
				</Grid>
			);
		}
		
	}
	
	
	if (
		null		!== props.data.authHistory &&
		'undefined'	!== typeof props.data.authHistory
	) {
		if ( props.data.authHistory.re_array.length > 0 ) {
			authHistory = authHistory.concat(
				props.data.authHistory.re_array
			);
		}

		if ( props.data.authHistory.new_array.length > 0 ) {
			authHistory = authHistory.concat(
				props.data.authHistory.new_array
			);
		}

		if ( 1 > authHistory.length ) {
			authHistoryList = (
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					
					className={
						clsx(
							classes.padding2X,
							classes.paddingSmallY,
						)
					}
				>
					<Grid
						item
						
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						
						className={
							clsx(
								classes.textCenter,
								classes.paddingX,
							)
						}
					>
						{'이력정보 없음'}
					</Grid>
				</Grid>
			);
		} else {
			authHistoryList = authHistory.map((data, idx) => (
				<Grid
					key={data[0] + '_' + data[1] + '_' + idx}
					
					container
					direction="row"
					justify="center"
					alignItems="center"
					
					className={
						clsx(
							classes.padding2X,
							classes.paddingSmallY,
						)
					}
				>
					<Grid
						item
						
						xs={7}
						sm={7}
						md={7}
						lg={7}
						xl={7}
						
						className={
							clsx(
								classes.paddingX,
								//classes.textCenter,
							)
						}
					>
						{
							( -1 !== data[1].indexOf('미인증') ) ? 
								'미인증' : data[1]
						}
						
						{
							( 
								-1				!==	data[1].indexOf('미인증') &&
								data[1].length	>	'미인증'.length 
							) ? 
								(
									data[1].replace('미인증', '')
								) : null
						}
					</Grid>
					<Grid
						item
						
						xs={5}
						sm={5}
						md={5}
						lg={5}
						xl={5}
						
						className={
							clsx(
								classes.paddingX,
								classes.textRight,
								classes.fontWeightBold,
							)
						}
					>
						{data[0]}
					</Grid>
				</Grid>
			));
		}
	} else {
		authHistoryList = (
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					
					className={
						clsx(
							classes.paddingX,
							classes.textCenter,
						)
					}
				>
					{'이력정보 없음'}
				</Grid>
			</Grid>
		);
	}
	
	
	let score_list_ulzip	= null;
	let score_grade_ulzip	= null;
	//console.log(props.data.scoreInfo);
	if (
		null		!== props.data.scoreInfo &&
		'undefined'	!== typeof props.data.scoreInfo
	) {
		let tmp_score_list = props.data.scoreInfo.factors;
		
		if (
			"미인증" === props.data.scoreInfo.grade.value	&&
			(
				undefined	===	props.data.scoreInfo.factors	||
				null		===	props.data.scoreInfo.factors	||
				0			>=	 props.data.scoreInfo.factors.length
			)
		) {
			score_list_ulzip = (
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					
					className={
						clsx(
							classes.padding2X,
							classes.paddingSmallY,
						)
					}
				>
					<Grid 
						item
						
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						
						className={
							clsx(
								classes.textCenter,
							)
						}
					>
						
						{'정보 없음'}
					</Grid>
				</Grid>
			);
		} else {
			if (
				'인증' === props.data.scoreInfo.grade.value &&
				'구분' !== props.data.scoreInfo.factors[0].text
			) {
				const tmp_head = [{ text: '구분', value: '영역' }];
				
				tmp_score_list = tmp_head.concat(tmp_score_list);
				
				//console.log( tmp_score_list );
			}
			
			score_list_ulzip = tmp_score_list.map(
				( score, idx ) => {
					
					let ret_obj	= null;
					
					if ( '인증' === props.data.scoreInfo.grade.value ) {
						
						ret_obj	 = (
							<Grid
								key={score.text + '_' + score.value + '_' + idx}
								
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									( 
										0		 === idx		&&
										"구분"	=== score.text
									) ?
										(
											clsx(
												//classes.padding2X,
												classes.paddingSmallY,
												//classes.borderBottom,
											)
										) :
										(
											clsx(
												classes.padding2X,
												classes.paddingSmallY,
												//classes.borderBottom,
											)
										)
								}
							>
								<Grid
									item
									
									xs={5}
									sm={5}
									md={5}
									lg={5}
									xl={5}
									
									className={
										( 
											0		 === idx		&&
											"구분"	=== score.text
										) ?
											(
												clsx(
													classes.paddingX,
													classes.textCenter,
													classes.borderBottom,
												)
											) :
											(
												clsx(
													classes.paddingX,
													classes.textCenter,
												)
											)
									}
								>
									{score.text}
								</Grid>
								<Grid
									item
									
									xs={7}
									sm={7}
									md={7}
									lg={7}
									xl={7}
									
									className={
										( 
											0		 === idx		&&
											"구분"	=== score.text
										) ?
											(
												clsx(
													classes.paddingX,
													classes.textCenter,
													classes.borderBottom,
												)
											) :
											(
												clsx(
													classes.paddingX,
													//classes.textCenter,
													classes.fontWeightBold,
													classes.textRight,
												)
											)
									}
								>
									{score.value.replace('.00', '')}
								</Grid>
							</Grid>
						);
					} else {
						
						ret_obj	 = (
							<Grid
								key={score.text + '_' + score.value + '_' + idx}
								
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									( 
										0		 === idx		&&
										"구분"	=== score.text
									) ?
										(
											clsx(
												//classes.padding2X,
												classes.paddingSmallY,
												//classes.borderBottom,
											)
										) :
										(
											clsx(
												classes.padding2X,
												classes.paddingSmallY,
												//classes.borderBottom,
											)
										)
								}
							>
								<Grid
									item
									
									xs={8}
									sm={8}
									md={8}
									lg={8}
									xl={8}
									
									className={
										clsx(
											classes.paddingX,
											classes.textCenter,
										)
									}
								>
									{score.text}
								</Grid>
								<Grid
									item
									
									xs={4}
									sm={4}
									md={4}
									lg={4}
									xl={4}
									
									className={
										( 0 === idx ) ?
											(
												clsx(
													classes.paddingX,
													//classes.textCenter,
													classes.fontWeightBold,
													classes.textRight,
												)
											) :
											(
												clsx(
													classes.paddingX,
													//classes.textCenter,
													classes.fontWeightBold,
													classes.textRight,
												)
											)
									}
								>
									{score.value.replace('.00', '')}
								</Grid>
							</Grid>
						);
					}
					
					return ret_obj;
				}
			);
		}
		
		
		score_grade_ulzip = props.data.scoreInfo.type
			.replace('cha', '차')
			.replace('score', '점수')
			.replace('grade', '등급');
		
	} else {
		score_list_ulzip = (
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				
				className={
					clsx(
						classes.padding2X,
						classes.paddingSmallY,
					)
				}
			>
				<Grid 
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					
					className={
						clsx(
							classes.textCenter,
						)
					}
				>
					
					{'정보 없음'}
				</Grid>
			</Grid>
		);

		score_grade_ulzip = '정보 없음';
	}

	
	if (
		null		!== props.data.authUrl &&
		'undefined'	!== typeof props.data.authUrl &&
		''			!==  props.data.authUrl
	) {
		//console.log(  props.data.authUrl );
		
		authUrl = (
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid 
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					
					className={
						clsx(
							//classes.paddingX,
							classes.padding,
						)
					}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							bgcolor={
								( "ulzip" === props.dataType ) ?
									"secondary.main" : "primary.main"
							}
							color={
								( "ulzip" === props.dataType ) ?
									"secondary.contrastText" : "primary.contrastText"
							}
							
							borderRadius={16}
						>
							<Button
								color={
									//( "ulzip" === props.dataType ) ?
									//	"secondary.main" : "primary.main"
									"inherit"
								}
								//size="medium"
								//size="medium"
								
								fullWidth={true}
								target="_blank"
								href={props.data.authUrl}
								rel="noopener noreferrer"
								
							>
								<Typography
									//variant="h6"
									variant="subtitle1"
								>
									{
										//"인증 상세정보 보러가기"
										'인증정보 상세보기'
									}
								</Typography>
							</Button>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
		);
	}
	
	
	
	return (
		
		<Fragment>
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid 
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							//bgcolor="fourth.main"
							bgcolor="grey.200"
						>
							<Typography
								variant="subtitle1"
								//color="textSecondary"
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								{"표준보육과정"}
							</Typography>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
			{
				standardCurriculum
			}
			
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid 
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							//bgcolor="fourth.main"
							bgcolor="grey.200"
						>
							<Typography
								variant="subtitle1"
								//color="textSecondary"
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								{"보육료 현황"}
							</Typography>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
			{
				ulzipBasicFee
			}
			
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid 
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							//bgcolor="fourth.main"
							bgcolor="grey.200"
						>
							<Typography
								variant="subtitle1"
								//color="textSecondary"
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								{"기타필요경비"}
							</Typography>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
			{
				ulzipEtcFee
			}
			
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid 
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							//bgcolor="fourth.main"
							bgcolor="grey.200"
						>
							<Typography
								variant="subtitle1"
								//color="textSecondary"
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								{"특별활동"}
							</Typography>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
			{
				ulzipSpecialFee
			}
			
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid 
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							//bgcolor="fourth.main"
							bgcolor="grey.200"
						>
							<Typography
								variant="subtitle1"
								//color="textSecondary"
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								{'인증 정보'}
							</Typography>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				
				className={
					clsx(
						classes.paddingSmallY,
						classes.padding2X,
					)
				}
			>
				<Grid
					item
					
					xs={5}
					sm={5}
					md={5}
					lg={5}
					xl={5}
					
					className={
						clsx(
							classes.paddingX,
						)
					}
				>
					{'인증 상태'}
				</Grid>
				<Grid
					item
					
					xs={7}
					sm={7}
					md={7}
					lg={7}
					xl={7}
					
					className={
						clsx(
							classes.paddingX,
							classes.textRight,
							classes.fontWeightBold,
						)
					}
				>
					{
						( 
							(
								null		=== props.data.authInfo ||
								undefined	=== props.data.authInfo
							) 
							||
							(
								"-"	=== props.data.authInfo.type	||
								""	=== props.data.authInfo.type	||
								null	=== props.data.authInfo.type	||
								undefined	=== props.data.authInfo.type
							)
							||
							(
								( -1 !== props.data.authInfo.type.indexOf( "미인증" ) )
							)
							||
							(
								( -1 !== props.data.authInfo.date.indexOf( "미인증" ) )
							)
						) ?
							"미인증" :  props.data.authInfo.type
					}
				</Grid>
			</Grid>
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				
				className={
					clsx(
						classes.padding2X,
						classes.paddingSmallY,
					)
				}
			>
				<Grid
					item
					
					xs={5}
					sm={5}
					md={5}
					lg={5}
					xl={5}
					
					className={
						clsx(
							classes.paddingX,
						)
					}
				>
					{'인증지표 구분'}
				</Grid>
				<Grid
					item
					
					xs={7}
					sm={7}
					md={7}
					lg={7}
					xl={7}
					
					className={
						clsx(
							classes.paddingX,
							classes.textRight,
							classes.fontWeightBold,
						)
					}
				>
					{
						( 
							(
								null		=== props.data.authInfo ||
								undefined	=== props.data.authInfo 
							) 
							||
							(
								"-"	=== props.data.authInfo.type	||
								""	=== props.data.authInfo.type	||
								null	=== props.data.authInfo.type	||
								undefined	=== props.data.authInfo.type
							)
							||
							(
								( 0 <= props.data.authInfo.type.indexOf( "미인증" ) )
							)
							||
							(
								( -1 !== props.data.authInfo.date.indexOf( "미인증" ) )
							)
						) ?
							"-" : score_grade_ulzip
					}
				</Grid>
			</Grid>
		
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				
				className={
					clsx(
						classes.padding2X,
						classes.paddingSmallY,
					)
				}
			>
				<Grid
					item
					
					xs={5}
					sm={5}
					md={5}
					lg={5}
					xl={5}
					
					className={
						clsx(
							classes.paddingX,
						)
					}
				>
					{'인증 일자'}
				</Grid>
				<Grid
					item
					
					xs={7}
					sm={7}
					md={7}
					lg={7}
					xl={7}
					
					className={
						clsx(
							classes.paddingX,
							classes.textRight,
							classes.fontWeightBold,
						)
					}
				>
					{
						( 
							( "미인증" === props.data.authInfo.date )
							||
							(
								( -1 !== props.data.authInfo.date.indexOf( "미인증" ) )
							)
						)?
							"-" : props.data.authInfo.date
					}
				</Grid>
			</Grid>
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				
				className={
					clsx(
						classes.padding2X,
						classes.paddingSmallY,
					)
				}
			>
				<Grid
					item
					
					xs={4}
					sm={4}
					md={4}
					lg={4}
					xl={4}
					
					className={
						clsx(
							classes.paddingX,
						)
					}
				>
					{'유효기간'}
				</Grid>
				<Grid
					item
					
					xs={8}
					sm={8}
					md={8}
					lg={8}
					xl={8}
					
					className={
						clsx(
							classes.paddingX,
							classes.textRight,
							classes.fontWeightBold,
						)
					}
				>
					{ props.data.authInfo.duration }
				</Grid>
			</Grid>
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid 
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							//bgcolor="fourth.main"
							bgcolor="grey.200"
						>
							<Typography
								variant="subtitle1"
								//color="textSecondary"
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								{'인증 항목'}
							</Typography>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
			
			{ 
				score_list_ulzip 
			}
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							//bgcolor="fourth.main"
							bgcolor="grey.200"
						>
							<Typography
								variant="subtitle1"
								//color="textSecondary"
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								{'인증 이력'}
							</Typography>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
			
			{
				authHistoryList
			}
			
			{
				authUrl
			}
			
		</Fragment>
		
	);
	
}