import React, { Component, Fragment } from 'react';

import { 
	Link,
	Redirect
} from 'react-router-dom';

import queryString from 'query-string';

import { makeStyles } from '@material-ui/core/styles';


import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser, isIPad13 } from 'react-device-detect';
import axios from 'axios';

import CommunityMain from '../components/Community/CommunityMain';


import {
	Container,
	Grid
} from '@material-ui/core';


import Gesture from 'rc-gesture';

import { Event, PageView, initGA } from '../components/Utility/Tracking.js';


String.prototype.replaceAll = function(org, dest) {
	return this.split(org).join(dest);
};

const limit = 100;


class Community extends Component {
	
	constructor( props ) {
		
		super( props );
		
		
		//console.log( queryString.parse( window.location.search ) );
		//console.log( window.location.pathname );
		
		const query	= queryString.parse( window.location.search );
		
		
		const viewIndexList	= {
			"cafe" : 0,
			"news" : 1
		};
		
		let viewIndex	= 0;
		
		let paramYN		= false;
		
		if( 
			undefined	=== query	||
			null		=== query	||
			""			=== query
		) {
			
			viewIndex	= 0;
			paramYN	= false;
			
		} else {
			if(
				"cafe"	=== query.view	||
				"news"	=== query.view
			) {
				viewIndex	= viewIndexList[ query.view ];
				
				if (
					undefined	=== window.location.pathname	||
					null		=== window.location.pathname	||
					""			=== window.location.pathname
				) {
					paramYN	= false;
				} else {
					paramYN	= true;
				}
				
			} else {
				viewIndex	= 0;
				paramYN	= false;
			}
		}
		
		
		
		this.state = {
			contents: [],
			isLoading: true,
			errors: null,
			current_location: 
				( true === paramYN ) ?
					(
						decodeURI(window.location.pathname)
							.split('/')
							.slice(2)
					) :
					(
						["","",""]
					),
			
			//current_location_string: decodeURI(window.location.pathname)
			//	.split('/')
			//	.slice(2)
			//	.join(' '),
			
			view: viewIndex,
			numOfViews:Object.keys( viewIndexList ).length,
			
			paramYN:paramYN,
		};
		
		
		this.getAddressByGeoLoc	= this.getAddressByGeoLoc.bind(this);
		this.getNews = this.getNews.bind(this);
		this.getCafe = this.getCafe.bind(this);
		this.changeView = this.changeView.bind(this);
		this.goToMainPage	= this.goToMainPage.bind(this);
		
	}

	
	componentDidMount() {
		
		if( 
			false	=== this.state.paramYN	&&
			""		=== this.state.current_location[0]
		) {
			//console.log( this.state );
			this.getAddressByGeoLoc();
		}
		
		
		for( let i = 0 ; i < this.state.current_location.length ; i++ ) {
			
			if( "" === this.state.current_location[i] ) {
				return;
			}
			
		}
		
		//console.log( this.state.current_location );
		
		if ( 0 === this.state.view ) {
			this.getCafe();
		} else if ( 1 === this.state.view ) {
			this.getNews();
		} else {
			this.getCafe();
		}
		
	}
	
	
	async getAddressByGeoLoc ( ) {
		
		let user_position = null;
		
		user_position = await new Promise((resolve, reject) => {
			
			const tmp_last_geo_loc_info = JSON.parse( localStorage.getItem( 'last_geo_loc_info' ) );
			
			if ( 
				null		!== tmp_last_geo_loc_info	&&
				undefined	!== tmp_last_geo_loc_info 
			) {
				
				resolve( 
					{
						"lat" : Number( tmp_last_geo_loc_info.lat ), 
						"lon" : Number( tmp_last_geo_loc_info.lon )
					}
				);
				
			} else {
				
				if ( navigator.geolocation ) {
					//console.log('[+] Loding');
					navigator.geolocation.getCurrentPosition(
						( position ) => {
							const 
								lat = position.coords.latitude, // 위도
								lon = position.coords.longitude; // 경도
							
							resolve(
								{
									"lat" : lat, 
									"lon" : lon
								}
							);
						},
						( ) => {
							resolve(
								{
									"lat" : 37.55336, 
									"lon" : 126.956602
								}
							);
						}
					);
				} else {
					resolve(
						{
							"lat" : 37.55336, 
							"lon" : 126.956602
						}
					);
				}
				
			}
			
		});
		
		
		axios
			.get(
				'/main/getCoordToRegion?' +
					"y=" + user_position.lat + "&" +
					"x=" + user_position.lon
			)
			.then(response => {
				
				const data	= response.data.documents.filter(
					( data ) => ( "H" === data.region_type )
				);
				
				//console.log( data[0] );
				
				this.setState({
					current_location:data[0].address_name.split(" ")
				});
				
				//console.log(data[0].address_name.split(" "));
				
				//console.log( this.state.current_location );
				
				this.changeView( this.state.view );
				
			})
			.catch(error => this.setState({ error, isLoading: false }));
	}
	
	
	getCafe() {
		
		Event("Community", "Talk Cafe Page View", "Talk Cafe Page View Action");
		
		axios
			.get(
				'/api/getNaverLocalCafeSearch?location=' +
					encodeURIComponent(this.state.current_location.join(' ')) +
					'&type=어린이집&limit=' +
					limit
			)
			.then(response => {
				
				//console.log(response);
				this.setState({
					contents: response.data,
					isLoading: false,
					view: 0
					//view: 'cafe'
				});
				
			})
			.catch(error => this.setState({ error, isLoading: false }));
	}
	
	
	getNews() {
		//console.log(window.location.pathname);
		Event("Community", "Talk News Page View", "Talk News Page View Action");
		
		axios
			// This is where the data is hosted
			.get(
				'/api/getNaverLocalNewsSearch?location=' +
					encodeURIComponent(this.state.current_location.join(' ')) +
					'&type=어린이집&limit=' +
					limit
			)
			// Once we get a response and store data, let's change the loading state
			.then(response => {
				//console.log( response.data );
				this.setState({
					contents: response.data,
					isLoading: false,
					view: 1
					//view: 'news'
				});
			})
			// If we catch any errors connecting, let's update accordingly
			.catch(error => this.setState({ error, isLoading: false }));
	}
	

	changeView( val ) {
		
		if ( 0 === val ) {
			this.getCafe();
		} else if( 1 === val ) {
			this.getNews();
		} else {
			
			this.setState({
				view:0
			});
			
			this.getCafe();
		}
		
	}
	
	
	goToMainPage ( ) {
		
		if (
			true	=== isMobileOnly	||
			true	===	isTablet		||
			true	===	isIPad13
		) {
			this.props.history.replace("/m");
		} else {
			this.props.history.replace("/");
		}
		
	}
	
	
	render ( ) {
		
		const { isLoading, contents } = this.state;
		
		const temp_current_location	= this.state.current_location.join(' ') + ' ';
		
		return (
			
			<Container
				
				//maxWidth="xl"
				maxWidth="sm"
				
				style={
					(
						true	=== isMobileOnly	||
						true	===	isTablet		||
						true	===	isIPad13
					) ?
						(
							{
								position:"fixed",
								top:0,
								left:0,
								width:"100%",
								height:"calc( 100vh - 3rem )",
								margin:0,
								padding:0,
								
								display:"flex",
								flexDirection:"column",
								
								overflow:"hidden",
								
								backgroundColor:"white"
							}
						) :
						(
							{
								position:"fixed",
								top:"0",
								left:"3.75rem",
								height:"100vh",
								margin:0,
								padding:0,
								
								display:"flex",
								flexDirection:"column",
								
								overflow:"hidden",
								
								backgroundColor:"white"
							}
						)
				}
				
			>
				<CommunityMain
					data={this.state}
					changeView={this.changeView}
					location={temp_current_location}
					view={this.state.view}
					numOfViews={this.state.numOfViews}
					goToMainPage={this.goToMainPage}
				/>
			</Container>
			
		);
	}
}

export default Community;