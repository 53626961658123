import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';

import queryString from 'query-string';
import clsx from 'clsx';

import { connect } from 'react-redux';

import {
	increasePageDepth,
	decreasePageDepth,
	setPageDepth,
} from '../../store/modules/currentPageSync';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	isIPad13,
	//isAndroid, isBrowser 
} from 'react-device-detect';

import Gesture from 'rc-gesture';

import {
	Container,
	//Box,
	//Grid,
	//Dialog,
	//Button,
	//IconButton,
	//Typography,
	//CircularProgress,
	Slide,
} from '@material-ui/core';

//import Icon		from '@material-ui/core/Icon';
//import {
//	Close		as CloseIcon,
//	ChevronLeft	as ChevronLeftIcon,
//	Visibility,
//	VisibilityOff,
//	UnfoldLess	as UnfoldLessIcon,
//	ExpandMore	as ExpandMoreIcon,
//	Favorite		as FavoriteIcon,
//	FavoriteBorder	as FavoriteBorderIcon,
//	ArrowBackIos	as ArrowBackIosIcon
//} from '@material-ui/icons';

import ITubePlayerDetail	from '../../components/iTube/iTubePlayer/ITubePlayerDetail.js';
import IPickPageContainer	from "../../components/Utility/IPickPage/IPickPageContainer.js";



class ITubePlayer extends Component {

	constructor( props ) {
		super( props );
		
		const query	= queryString.parse( this.props.location.search );
		
		//console.log( query.type );
		//console.log( query.videoId );
		//console.log( query.title );
		//console.log( query.description );
		
		let type	= undefined;
		let videoId	= undefined;
		let title	= undefined;
		let description	= undefined;
		
		type	= query.type;
		videoId	= query.videoId;
		title	= query.title;
		description	= query.description;
		
		this.state	= {
			currentPage	: this.props.currentPage,
			
			type	: type,
			videoId	: videoId,
			title	: title,
			description	:description,
			
			errMsg: "",
			errMsgType:"warning",
		};
		
		this.closePlayer	= this.closePlayer.bind(this);
		this.createErrorMsg		= this.createErrorMsg.bind(this);
		this.closeAlert			= this.closeAlert.bind(this);
	}
	
	
	static getDerivedStateFromProps( nextProps, prevState ) {
		
		if ( 
			prevState.currentPage.data	!== nextProps.currentPage.data	||
			prevState.currentPage.depth	!== nextProps.currentPage.depth
		) {
			return {
				currentPage	: nextProps.currentPage
			};
		}
		
		return null;
	}
	
	
	componentDidMount ( ) {
		this.props.setPageDepth(
			{
				data	: this.state.currentPage.data,
				depth	: 2,
			}
		);
	}
	
	
	componentDidUpdate ( prevProps, prevState ) {
		if ( 
			2				!==	this.props.currentPage.depth	&&
			"PAGE_ITUBE"	===	this.props.currentPage.data
		) {
			this.props.setPageDepth(
				{
					data	: this.props.currentPage.data,
					depth	: 2,
				}
			);
		}
	}
	
	
	closePlayer ( ) {
		
		this.props.setPageDepth(
			{
				data	: this.state.currentPage.data,
				depth	: 1,
			}
		);
		
		if (
			true	=== isMobileOnly	||
			true	===	isTablet		||
			true	===	isIPad13
		) {
			this.props.history.goBack();
		} else {
			this.props.history.replace("/iTube");
		}
	}
	
	
	createErrorMsg ( errMsg, errMsgType ) {
		this.setState({
			errMsg: errMsg,
			errMsgType:errMsgType,
		});
	}
	
	closeAlert ( ) {
		this.setState({
			errMsg	:""
		});
	}
	
	
	
	render ( ) {
		
		return (
			<Fragment>
				{
					( "" !== this.state.type ) ?
						(
							<Slide 
								direction={
									( isMobileOnly ) ?
										"left" : "right"
								}
								
								in={true}
								
								//style={{ transitionDelay: '100ms' }}
							>
								<Gesture
									onSwipeRight={
										( gestureStatus ) => {
											if ( 
												//true === isMobileOnly 
												true	=== isMobileOnly	||
												true	=== isTablet		||
												true	===	isIPad13	
											) {
												this.closePlayer();
											}
										}
									}
									
									onSwipeLeft={
										( gestureStatus ) => {
											if ( 
												//true !== isMobileOnly 
												true	!== isMobileOnly	&&
												true	!== isTablet		&&
												true	!==	isIPad13
											) {
												this.closePlayer();
											}
										}
									}
									
									direction="horizontal"
								>
									<Container
										fixed={true}
										maxWidth="sm"
										
										style={
											(
												true	=== isMobileOnly	||
												true	===	isTablet		||
												true	===	isIPad13
											) ?
												(
													{
														position:"fixed",
														top:"0",
														left:"0",
														height:"calc( 100vh - 3rem )",
														margin:0,
														padding:0,
														
														
														display:"flex",
														flexDirection:"column",
														overflow:"hidden",
														zIndex:2,
													}
												) :
												(
													{
														position:"fixed",
														top:"0",
														left:"3.75rem",
														height:"100vh",
														margin:0,
														padding:0,
														
														
														display:"flex",
														flexDirection:"column",
														overflow:"hidden",
														zIndex:2,
													}
												)
										}
									>
										<IPickPageContainer
											maxWidth={"sm"}
											
											zIndex={2}
											type={"kinder"}
											
											data={
												//this.props.data
												{
													"type"		: this.state.type,
													"videoId"	: this.state.videoId,
													"title"		: this.state.title,
													"description"	: this.state.description,
												}
											}
											pageTitle={
												""
											}
											others={
												undefined
												//this.props.others
											}
											
											closeButtonShowYN={
												//(
												//	true	=== isMobileOnly	||
												//	true	=== isTablet		||
												//	true	===	isIPad13	
												//) ?
												//	false : true
												true
											}
											
											closePage={this.closePlayer}
											createErrorMsg={this.createErrorMsg}
											
											buttonList={
												undefined
											}
											
											pageHeader={
												undefined
											}
											pageBody={
												<ITubePlayerDetail
													type={this.state.type}	
													dataType={this.state.type}
													data={
														{
															"type"		: this.state.type,
															"videoId"	: this.state.videoId,
															"title"		: this.state.title,
															"description"	: this.state.description,
														}
													}
												/>
											}
											pageFooter={
												null
												//( 
												//	true	=== isMobileOnly	||
												//	true	=== isTablet		||
												//	true	===	isIPad13
												//) ?
												//	(
												//		<div
												//			style={{
												//				height:"3rem",
												//				margin:0,
												//				padding:0,
												//			}}
												//		/>
												//	) : 
												//	(
												//		<div
												//			style={{
												//				height:"1rem",
												//				margin:0,
												//				padding:0,
												//			}}
												//		/>
												//	)
											}
										/>
									</Container>
								</Gesture>
							</Slide>
						) : null
				}
			</Fragment>
			
		);
		
	}
	
}

//export default ITubePlayer;

const mapStateToProps = state => ({
	currentPage: state.currentPageSync.currentPage
});

// props 로 넣어줄 액션 생성함수
const mapDispatchToProps = dispatch => ({
	increasePageDepth	: ( ) => dispatch( increasePageDepth( ) ),
	decreasePageDepth	: ( ) => dispatch( decreasePageDepth( ) ),
	setPageDepth		: ( currentPage ) => dispatch( setPageDepth( currentPage ) ),
});

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( ITubePlayer );