import React, 
{ 
	//Component,
	Fragment
} from 'react';

import {
	isMobileOnly,
	isTablet
} from 'react-device-detect';

import PropTypes from 'prop-types';
import clsx from 'clsx';


import {
	Button,
	Snackbar,
	SnackbarContent
} from '@material-ui/core';


import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';

import { 
	amber, 
	green 
} from '@material-ui/core/colors';

import { makeStyles } from '@material-ui/core/styles';


const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};


const useStyles1 = makeStyles(theme => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}));



function MySnackbarContentWrapper(props) {
	const classes = useStyles1();
	const { className, message, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];

	return (
		<SnackbarContent
			className={
				clsx(
					classes[variant], 
					className
				)
			}
			aria-describedby="client-snackbar"
			message={
				<span 
					id="client-snackbar" 
					className={classes.message}
				>
					<Icon 
						className={
							clsx(
								classes.icon, 
								classes.iconVariant)
						} 
					/>
					
					{
						message
					}
				</span>
			}
			action={[
				<IconButton 
					key="close" 
					aria-label="close" 
					color="inherit" 
					onClick={onClose}
				>
					<CloseIcon 
						//className={classes.icon}
						//fontSize="medium"
						fontSize="small"
					/>
				</IconButton>,
			]}
			
			{...other}
		/>
  );
}


MySnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const useStyles2 = makeStyles(theme => ({
	margin: {
		margin: theme.spacing(1),
	},
	mobileWidth : {
		minWidth:"90vw"
	},
	tabletWidth : {
		minWidth:"50vw"
	},
	pcWidth : {
		minWidth:"30vw"
	}
}));


export default function CustomizedSnackbars( props ) {
	
	const classes = useStyles2();
	
	return (
		<Fragment
		>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={props.open}
				
				autoHideDuration={3000} // 안해도 어차피 밖에서 메시지를 제거하긴 함...
				
				onClose={
					(e) => {
						props.closeAlert();
					}
				}
			>
				<MySnackbarContentWrapper
					
					className={
						( isMobileOnly ) ?
							classes.mobileWidth :
							(
								( isTablet ) ?
									classes.tabletWidth : classes.pcWidth
							)
					}
					
					variant={props.option}
					//"error"
					//"warning"
					//"info"
					//"success"
					
					message={props.data}
					
					onClose={
						(e) => {
							props.closeAlert();
						}
					}
				/>
			</Snackbar>
		</Fragment>
	);
}