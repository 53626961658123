import React, { Component, Fragment } from 'react';

import { 
	Route, 
	Switch,
	//Redirect,
} from 'react-router-dom';

import { 
	isMobile, 
	isIOS, 
	isMobileOnly, 
	isTablet, 
	isAndroid, 
	isBrowser,
	isIPad13,
} from 'react-device-detect';

import { 
	IPickHome,
	Community,
	//iTube,
	Profile,
	Marker,
	FriendShare,
	Interest,
	News,
	ForgetMyPassword,
	NewPasswordRegister,
	RegisterUserId,
	NotFound,
} from 'pages';

import iTube from 'pages/ITube/iTube';
import ITubePlayer from 'pages/ITube/ITubePlayer';

import Terms	from 'pages/Terms/Terms';
import Privacy	from 'pages/Privacy/Privacy';

import IPickNavBar from '../components/Navigation/IPickNavBar';

import IPickHomeMobile	from 'pages/m/IPickHomeMobile';



const IPick = ( props ) => {
	
	
	return (
		<div
			style={{
				position:"fixed",
				top:0,
				left:0,
				//width:"100vw",
				//height:"100vh",	
				width:"100%",
				height:"100%",
				
				//overflow:"hidden",
				//display:"flex",
  				//flexDirection:"column"
			}}
		>	
			<Fragment>
				<Route 
					path="/" component={IPickHome}
				/>
				<Switch
				>
					<Route 
						exact path="/Community" component={Community}
					/>
					<Route 
						exact path="/iTube" component={iTube}
					/>
					<Route 
						exact path="/iTube/Player" component={ITubePlayer}
					/>
					<Route 
						exact path="/User" component={Profile}
					/>
					<Route
						exact path="/Marker" component={Marker}
					/>
					
					<Route
						exact path="/RegisterUserId" component={RegisterUserId}
					/>
					<Route
						exact path="/ForgetMyPassword" component={ForgetMyPassword}
					/>
					<Route
						exact path="/NewPasswordRegister" component={NewPasswordRegister}
					/>
					
					<Route 
						exact path="/FriendShare" component={FriendShare} 
					/>
					<Route 
						exact path="/Interest" component={Interest}
					/>
					
					<Route 
						exact path="/News" component={News} 
					/>
					<Route 
						exact path="/Terms" component={Terms} 
					/>
					<Route 
						exact path="/Privacy" component={Privacy}
					/>
					
					{
					//<Route component={NotFound}/>
					}
					
				</Switch>
			</Fragment>
			
			<Fragment>
				<Route 
					path="/m" component={IPickHomeMobile}
					//path="/m" component={IPickHome}
				/>
				<Switch
				>
					<Route
						exact path="/m/Marker" component={Marker}
					/>
					
					<Route 
						exact path="/m/User" component={Profile}
					/>
					<Route
						exact path="/m/RegisterUserId" component={RegisterUserId}
					/>
					<Route
						exact path="/m/ForgetMyPassword" component={ForgetMyPassword}
					/>
					<Route
						exact path="/m/NewPasswordRegister" component={NewPasswordRegister}
					/>
					
					<Route 
						exact path="/m/Community" component={Community}
					/>
					<Route 
						exact path="/m/iTube" component={iTube}
					/>
					<Route 
						exact path="/m/iTube/Player" component={ITubePlayer}
					/>
					
					<Route 
						exact path="/m/Terms" component={Terms}
					/>
					<Route
						exact path="/m/Privacy" component={Privacy}
					/>
					
					<Route 
						exact path="/m/News" component={News} 
					/>
					<Route 
						exact path="/m/FriendShare" component={FriendShare} 
					/>
					<Route 
						exact path="/m/Interest" component={Interest} 
					/>
					
				</Switch>
			</Fragment>
			
			<IPickNavBar 
			/>
			
		</div>
	);
	
};


export default IPick;