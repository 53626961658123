import ReactGA from "react-ga";


export const initGA = ( trackingID ) => { 
	//ReactGA.initialize('UA-137510932-1');
	if( String(window.location).includes('i-pick') ) {
		ReactGA.initialize(trackingID); 	
	}
   
};

export const PageView = () => {  
	
	if( String(window.location).includes('i-pick') ) {
		ReactGA.pageview(window.location.pathname + window.location.search); 	
	}

};


/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const Event = ( category, action, label ) => {
	
	if( String(window.location).includes('i-pick') ) {
		ReactGA.event({
			category: category,
			action: action,
			label: label
		});	
	}

};

