import React, {
	Component,
	Fragment
} from 'react';

import classnames from 'classnames';
import clsx from 'clsx';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser, isIPad13 } from 'react-device-detect';

import axios from 'axios';

import { 
	fade,
	makeStyles,
	withStyles
} from '@material-ui/core/styles';


import {
	Box,
	//Container,
	Modal,
	Backdrop,
	Button,
	IconButton,
	Grid,
	Input,
	TextField,
	Typography,
	FormControl,
	FormControlLabel,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	Collapse,
	FormGroup,
	FormHelperText,
} from '@material-ui/core';

import {
	Search		as SearchIcon,
	Backspace	as BackspaceIcon,
	Close		as CloseIcon,
} from '@material-ui/icons';


const useStyles = makeStyles( (theme) => (
	{
		button:{
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		noPadding:{
			padding: theme.spacing(0)
		},
		noMargin:{
			margin: theme.spacing(0)
		},
		noGutters:{
			margin: theme.spacing(0),
			padding: theme.spacing(0)
		},
		margin:{
			margin: theme.spacing(1),
		},
		padding:{
			padding: theme.spacing(1),
		},
		paddingX:{
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
		paddingTopSmall:{
			paddingTop:theme.spacing(0.5),	
		},
		textCenter:{
			textAlign:"center",
		},
		textRight:{
			textAlign:"right",
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: theme.spacing(0),
			padding: theme.spacing(1),
		},
		modalBox: {
			margin: theme.spacing(0),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(1),
			maxWidth:"95%",
			backgroundColor:"white"
		},
		card:{
			margin: theme.spacing(0),
			padding: theme.spacing(1),
			maxWidth:476
		},
		cardHeader:{
			textAlign:"center",
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		cardContent:{
			margin: theme.spacing(0),
			paddingLeft:theme.spacing(2),
			paddingRight:theme.spacing(2),
			paddingTop:theme.spacing(1),
			paddingBottom:theme.spacing(1),
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1)
		},
		bgLight:{
			backgroundColor:"#f8f9fa !important"
		},
		roundedPill:{
			borderRadius:"50rem !important"
		}
	}
));




//class Suggestions extends Component {
export default function Suggestions ( props ) {
	
	const classes	= useStyles();
	
	const [suggestionsTitleText, setSuggestionsTitleText] = React.useState("");
	const [suggestionsMainText, setSuggestionsMainText] = React.useState("");
	
	
	
	const [validValues, setValidValues]	= React.useState({
		isValidEmail:false,
	});
	
	
	
	const [values, setValues] = React.useState({
		email:"",
		emailDomain: "직접 작성",
	});

	
	const handleChange = prop => event => {
		
		setValues({ 
			...values, 
			[prop]: event.target.value 
		});
		
		
		const regexp_for_self	= /[a-zA-Z0-9]+([._-]?[a-zA-Z0-9])*@[a-zA-Z0-9]+[-]?[a-zA-Z0-9]+[.][a-zA-Z]+([.][a-zA-Z]+)?/;
		const regexp_for_non_self	= /[a-zA-Z0-9]+([._-]?[a-zA-Z0-9])*/;
		
		
		if ( "email" === prop ) {
			
			if ( "직접 작성" == values.emailDomain ) {
				
				let tmp_email	= event.target.value;
				
				tmp_email	= tmp_email.replace( regexp_for_self, "" );
				
				if( ""  !== tmp_email ) {
					setValidValues({
						...validValues,
						["isValidEmail"] : false
					});
				} else {
					setValidValues({
						...validValues,
						["isValidEmail"] : true
					});
				}
				
			} else {
				
				let tmp_email	= event.target.value;
				
				tmp_email	= tmp_email.replace( regexp_for_non_self, "" );
				
				
				if( ""  !== tmp_email ) {
					setValidValues({
						...validValues,
						["isValidEmail"] : false
					});
				} else {
					setValidValues({
						...validValues,
						["isValidEmail"] : true
					});
				}
				
			}
			
		} else if ( "emailDomain" === prop ) {
			
			if ( "직접 작성" == event.target.value ) {
				
				let tmp_email	= values.email;
				
				tmp_email	= tmp_email.replace( regexp_for_self, "" );
				
				if( ""  !== tmp_email ) {
					setValidValues({
						...validValues,
						["isValidEmail"] : false
					});
				} else {
					setValidValues({
						...validValues,
						["isValidEmail"] : true
					});
				}
				
			} else {
				
				let tmp_email	= values.email;
				
				tmp_email	= tmp_email.replace( regexp_for_non_self, "" );
				
				
				if( ""  !== tmp_email ) {
					setValidValues({
						...validValues,
						["isValidEmail"] : false
					});
				} else {
					setValidValues({
						...validValues,
						["isValidEmail"] : true
					});
				}
				
			}
		
		} else {
			
		}
		
		
		
	};
	
	
	
	
	const [checkedValues, setCheckedValues]	= React.useState({
		emailReplyYN:false,
	});

	const handleChangeCheckedValues = prop => event => {
		
		setCheckedValues({ 
			...checkedValues, 
			[prop]: event.target.checked 
		});
		
		//console.log( event.target.checked );
		
		if ( "emailReplyYN" === prop ) {
			//props.consentTerms( event );
			
			if ( "" != values.email ) {
				setValues({ 
					...values, 
					["email"]: "" 
				});
			}
			
			if ( "직접 작성" != values.emailDomain ) {
				setValues({ 
					...values, 
					["emailDomain"]: "직접 작성" 
				});
			}
			
		} else {
			
		}
		
		
	};
	
	
	//console.log( values.email );
	//console.log( values.emailDomain );
	
	

	
	
	
	return(
		<Fragment>
			<Modal
				
				className={classes.modal}
				open={props.isOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				
			>
				<Box 
					className={
						clsx(
							classes.modalBox,
						)
					}
					borderRadius="borderRadius"
					
				>
					<form
						
					>
						
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
							>
								<Grid
									item
									
									style={{
										textAlign:"center"
									}}
								>
									<IconButton
										className={
											clsx(
												classes.button,
												classes.noGutters,
											)
										}
										
										size="small"
										
										onClick={props.toggleSuggestions}
									>
										<CloseIcon 
											fontSize="small"
										/>
									</IconButton>
								</Grid>
							</Grid>
							
							<Grid
								item
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Grid
									item
									
									xs={1}	
									sm={1}
									md={1}
									lg={1}
									xl={1}
									
								/>
								
								<Grid
									item
									
									xs={10}	
									sm={10}
									md={10}
									lg={10}
									xl={10}
								>
									<Typography 
										className={
											clsx(
												classes.paddingX,
												classes.noMargin
											)
										}
										variant="h5" 
										
										style={{
											textAlign:"center"
										}}
									>
										{
											"개선/건의사항 보내기"
										}
									</Typography>
									
								</Grid>
								<Grid
									item
									
									xs={1}	
									sm={1}
									md={1}
									lg={1}
									xl={1}
									
									style={{
										textAlign:"center"
									}}
								>
									{
									//<IconButton
									//	className={
									//		clsx(
									//			classes.button,
									//			classes.noGutters
									//		)
									//	}
									//	
									//	onClick={props.toggleSuggestions}
									//>
									//	<CloseIcon 
									//		fontSize="small"
									//	/>
									//</IconButton>
									}
								</Grid>
							</Grid>
							
							<Grid
								item
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Grid
									item
									
									//xs={10}	
									//sm={10}
									//md={10}
									//lg={10}
									//xl={10}
								>
									<TextField
										label="제 목"
										value={suggestionsTitleText}
										onChange={
											(e) => {
												e.preventDefault();
												
												setSuggestionsTitleText(e.target.value);
											}
										}
										className={classes.textField}
										margin="normal"
										variant="outlined"
										
										style={
											( isMobileOnly ) ?
												(
													{
														minWidth:"80vw" 
													} 
												) :
												(
													( 
														true	===	isTablet	||
														true	===	isIPad13
													) ?
														(
															{
																minWidth:"50vw"
															}
														) :
														(
															{
																minWidth:"35vw"
															}
														)
												)
										}
									/>
									
								</Grid>
							</Grid>
							
							
							<Grid
								item
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Grid
									item
									
									//xs={10}	
									//sm={10}
									//md={10}
									//lg={10}
									//xl={10}
								>
									<TextField
										label="본 문"
										multiline
										rows="10"
										rowsMax="100"
										value={suggestionsMainText}
										onChange={
											(e) => {
												e.preventDefault();
												
												setSuggestionsMainText(e.target.value);
											}
										}
										className={classes.textField}
										margin="normal"
										variant="outlined"
										
										style={
											( isMobileOnly ) ?
												(
													{
														minWidth:"80vw" 
													} 
												) :
												(
													( 
														true	===	isTablet	||
														true	===	isIPad13
													) ?
														(
															{
																minWidth:"50vw"
															}
														) :
														(
															{
																minWidth:"35vw"
															}
														)
												)
										}
									/>
									
								</Grid>
							</Grid>
							
							{
							//<Grid
							//	item
							//	container
							//	direction="row"
							//	//justify="center"
							//	justify="flex-start"
							//	alignItems="center"
							//	
							//	className={
							//		clsx(
							//			classes.paddingX,
							//		)
							//	}
							//>
							//	<Grid
							//		item
							//		
							//		xs={12}
							//		sm={12}
							//		md={12}
							//		lg={12}
							//		xl={12}
							//	>
							//		<FormControlLabel 
							//			control={
							//				<Checkbox 
							//					name="emailReplyYN"
							//					checked={checkedValues.emailReplyYN}	
							//					onChange={handleChangeCheckedValues("emailReplyYN")}
							//				/>
							//			} 
							//			label="이메일로 회신 받으시겠습니까?" 
							//		/>
							//	</Grid>
							//</Grid>
							}
							
							{
							//<Collapse 
							//	in={checkedValues.emailReplyYN}
							//>
							}
								<Grid
									item
									container
									direction="row"
									justify="center"
									alignItems="center"
									
									className={
										clsx(
											classes.paddingX,
										)
									}
								>	
									<Grid
										item
										
										xs={7}
										sm={7}
										md={7}
										lg={7}
										xl={7}
									>
										<TextField
											className={
												clsx(
													//classes.marginTop2,
													//classes.textField
													classes.noMargin,
												)
											}
											label="이메일"
											type="email"
											name="email"
											autoComplete="email"
											value={values.email}
											margin="none"
											variant="outlined"
											
											disabled={
												//false	=== checkedValues.emailReplyYN
												false
											}
											
											fullWidth={true}
											
											onChange={
												handleChange("email")
											}
										/>
									</Grid>
									
									
									<Grid
										item
										
										xs={5}
										sm={5}
										md={5}
										lg={5}
										xl={5}
										
										className={
											clsx(
												classes.paddingX,
											)
										}
									>
										<FormControl 
											variant="outlined" 
											className={
												clsx(
												)
											}
											
											style={{
												minWidth:"120px",
												marginLeft:"8px",
												
												width:"100%",
											}}
											
											disabled={
												//false	=== checkedValues.emailReplyYN
												false
											}
										>
											<Select
												variant="outlined"
												
												value={values.emailDomain}
												defaultValue={"직접 작성"}
												
												//labelWidth={labelWidth}
												//inputProps={{
												//	id: "email-domain-select",
												//}}
												onChange={
													handleChange("emailDomain")
												}
											>
												<MenuItem value={"직접 작성"}>직접 작성</MenuItem>
												<MenuItem value={"@naver.com"}>@naver.com</MenuItem>
												<MenuItem value={"@daum.net"}>@daum.net</MenuItem>
												<MenuItem value={"@hanmail.net"}>@hanmail.net</MenuItem>
												<MenuItem value={"@gmail.com"}>@gmail.com</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<Grid
									item
									container
									direction="row"
									justify="center"
									alignItems="center"
									
									className={
										clsx(
											classes.paddingX,
										)
									}
								>	
									<Grid
										item
										
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}
										
										className={
											clsx(
												//classes.paddingX,
											)
										}
									>
										<FormControl
											className={
												clsx(
													//classes.paddingSmall,
												)
											} 
											error={
												false == validValues.isValidEmail
											}
										>
											<FormHelperText 
												className={
													clsx(
														classes.formHelperText,
													)
												} 
											>
												{
													( 0 === values.email.length ) ?
														" " :
														(
															( validValues.isValidEmail ) ?
																"유효한 이메일 형식입니다." : "이메일 형식이 유효하지 않습니다."
														)
												}
											</FormHelperText>
										</FormControl>
									</Grid>
								</Grid>
								{
								//<FormGroup
								//	row={true}
								//	
								//	className={
								//		clsx(
								//			classes.paddingX,
								//		)
								//	}
								//	
								//	style={{
								//		width:"100%",
								//	}}
								//>
								//</FormGroup>
								}
							{
							//</Collapse>
							}
							
							
							<Grid
								item
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
							>
								<Grid
									item
									
									xs={4}	
									sm={4}
									md={4}
									lg={3}
									xl={3}
									
									className={
										clsx(
											//classes.paddingRight
											classes.paddingX,
											classes.textRight,
										)
									}
								>
									<Button
										//variant="contained"
										variant="outlined"
										
										
										disabled={
											0	>=	suggestionsTitleText.length	||
											0	>=	suggestionsMainText.length	||
											true	!==	validValues.isValidEmail
										}
										
										
										onClick={
											async () => {
												
												let errorMsg		= "";
												let errorMsgType	= "warning";
												let submitSuccessYN	= false;
												
												
												let tmp_suggestions_title_text	= suggestionsTitleText;
												let tmp_suggestions_main_text	= suggestionsMainText;
												
												tmp_suggestions_title_text	= tmp_suggestions_title_text.replace( /\s/g, "" ).trim();
												tmp_suggestions_main_text	= tmp_suggestions_main_text.replace( /\s/g, "" ).trim();
												
												
												if( tmp_suggestions_title_text.length < 2 ) {
													
													props.createErrorMsg(
														'공백을 제외하고 적어도 2자 이상 건의사항 제목을 작성해야 합니다.',
														"warning"
													);
													return;
													
												}
												
												if( tmp_suggestions_main_text.length < 5) {
													
													props.createErrorMsg(
														'공백을 제외하고 적어도 5자 이상 건의사항을 작성해야 합니다.',
														"warning"
													);
													return;
													
												}
												
												if ( 0 >= values.email.length ) {
													props.createErrorMsg(
														'회신받을 이메일주소를 입력해주세요.',
														"warning"
													);
													return;
												}
												
												if ( true != validValues.isValidEmail ) {
													props.createErrorMsg(
														'유효한 이메일주소가 아닙니다.',
														"warning"
													);
													return;
												}
												
												
												try {
													
													const submitSuggestionsResult	= await axios.post(
														'/user/submitSuggestion', {
															suggestion_type:'suggest',
															title:suggestionsTitleText,
															content:suggestionsMainText,
															email:values.email,
														}
													);
													
													
													if( "success" === submitSuggestionsResult.data.result ) {
														errorMsg		= submitSuggestionsResult.data.msg;
														errorMsgType	= "success";
														submitSuccessYN	= true;
													} else {
														errorMsg		= submitSuggestionsResult.data.msg;
														errorMsgType	= "warning";
													}
													
												} catch ( err ) {
													
													console.log( err );
													errorMsg		= "건의사항 제출 도중에 오류가 발생했습니다.";
													errorMsgType	= "error";
													
												} finally {
													if ( "" !== errorMsg ) {
														props.createErrorMsg( 
															errorMsg,
															errorMsgType
														);
													}
													
													if( true === submitSuccessYN ) {
														props.toggleSuggestions();
													}
												}
												
											}
										}
									>
										<Box
											//clone={true}
											fontWeight={"fontWeightBold"}
										>
										{
											"제출하기"
										}
										</Box>
									</Button>
								</Grid>
								
							</Grid>
						</Grid>
						
					</form>
					
				</Box>
			</Modal>
		</Fragment>
	);
	
}