import React, { 
	Fragment 
} from 'react';

import classnames from 'classnames';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';

import { Event, PageView, initGA } from './Utility/Tracking.js';

//import { ThemeProvider } from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme, 
	responsiveFontSizes
} from '@material-ui/core/styles';

import {
	Modal, 
	Fade,
	Card,
	CardHeader,
	CardActions,
	CardContent,
	Typography,
	Button,
	Grid,
	Backdrop,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';


//let theme = createMuiTheme();
//theme = responsiveFontSizes(theme);


const useStyles = makeStyles( (theme) => (
	{
		button:{
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		noPadding:{
			padding: theme.spacing(0)
		},
		noMargin:{
			margin: theme.spacing(0)
		},
		noGutters:{
			margin: theme.spacing(0),
			padding: theme.spacing(0)
		},
		textCenter:{
			textAlign:"center"
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		card:{
			margin: theme.spacing(0),
			padding: theme.spacing(1),
			maxWidth:476
		},
		cardHeader:{
			textAlign:"center",
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		cardContent:{
			margin: theme.spacing(0),
			paddingLeft:theme.spacing(2),
			paddingRight:theme.spacing(2),
			paddingTop:theme.spacing(1),
			paddingBottom:theme.spacing(1),
		}
	}
));



// export default function Notice ( props ) {
	
// 	const classes	= useStyles();
	
// 	return (
		
// 		<Fragment>
// 			{
			 
// 				(
// 					false
// 				) ?
// 					null :
// 					(
// 						<Modal
// 							className={classes.modal}
// 							open={props.open}
// 							closeAfterTransition
// 							BackdropComponent={Backdrop}
// 							BackdropProps={{
// 								timeout: 500,
// 							}}
							
// 						>  
							
// 							<Fade 
// 								in={props.in}
// 							>
// 								<Card
// 									className={classes.card}
// 								>
// 									<CardHeader
// 										className={classes.cardHeader}
										
// 										title={
// 											"유치원 정보 미출력 공지"
// 										}
// 										//subheader=""
// 									/>
									
// 									<CardContent
// 										className={classes.cardContent}
// 									>
// 										<Typography 
// 											//variant="body2" 
// 											variant="body1" 
// 											component="p"
// 										>
// 											{
// 												"안녕하세요. 아이픽입니다."	
// 											}
// 										</Typography>
										
										
// 										<br/>
// 														<Typography 
// 											//variant="body2" 
// 											variant="body1" 
// 											component="p"
// 										>
// 											{
// 												"현재 유치원 정보가 정상적으로 출력되지 않고 있습니다."	
// 											}
// 										</Typography>
// 														<br/>
// 														<Typography 
// 											//variant="body2" 
// 											variant="body1" 
// 											component="p"
// 										>
// 											{
// 												"이용에 불편을 드려서 죄송합니다."	
// 											}
// 										</Typography>
// 										<br/>
// 														<Typography 
// 											//variant="body2" 
// 											variant="body1" 
// 											component="p"
// 										>
// 											{
// 												"빠른 시일내에 정상적으로 이용할 수 있도록 노력하겠습니다."	
// 											}
// 										</Typography>
// 										<br/>
// 														<Typography 
// 											//variant="body2" 
// 											variant="body1" 
// 											component="p"
// 										>
// 											{
// 												"항상 아이픽을 이용해주셔서 감사합니다."	
// 											}
// 										</Typography>
									
											
// 									</CardContent>
									
									
// 									<CardActions
										
// 									>
// 										<Grid
// 											container
											
// 											direction="row"
// 											justify="flex-end"
// 											alignItems="center"
											
// 											spacing={1}
// 										>
											
// 											<Grid
// 												className={classes.textCenter}
												
// 												item
// 												//
// 												//xs={7}
// 												//sm={7}
// 												//md={6}
// 												//lg={6}
// 												//xl={5}
// 											>
												
// 												<FormControlLabel 
// 													className={classes.noMargin}
// 													control={
// 														<Checkbox 
// 															value={props.value}
// 															onChange={props.toggleTodayNoShow}
// 															color="primary"
// 														/>
// 													} 
// 													label="다음에 보지 않기" 
// 												/>
												
// 											</Grid>
// 											<Grid
// 												className={classes.textCenter}
												
// 												item
// 												//
// 												//xs={3}
// 												//sm={3}
// 												//md={2}
// 												//lg={2}
// 												//xl={2}
// 											>
// 												<Button
// 													className={classes.button}
													
// 													size="large"
// 													onClick={
// 														(e) => {
// 															props.closeReleaseNoticeYN();
// 														}
// 													}
// 												>
// 													{
// 														"닫 기"
// 													}
// 												</Button>
// 											</Grid>
// 										</Grid>	
										
// 									</CardActions>
// 								</Card>
// 							</Fade>
							
// 						</Modal>
// 					)
// 			}
// 		</Fragment>
		
// 	);
	
// }



export default function Notice ( props ) {
	
	const classes	= useStyles();
	
	return (
		
		<Fragment>
			{
			 
				(
					(
						true		=== isAndroid		&& 
						navigator.userAgent.indexOf('IPICK_AND') >= 0
					)	||
					(
						true		=== isAndroid		&& 
						'and'		=== props.mode
					)	||
					(
						navigator.userAgent.indexOf('IPICK_IOS') >= 0
					) 
				) ?
					null :
					(
						<Modal
							className={classes.modal}
							open={props.open}
							closeAfterTransition
							BackdropComponent={Backdrop}
							BackdropProps={{
								timeout: 500,
							}}
							
						>  
							
							<Fade 
								in={props.in}
							>
								<Card
									className={classes.card}
								>
									<CardHeader
										className={classes.cardHeader}
										
										title={
											"아이픽 모바일 앱 출시"
										}
										//subheader=""
									/>
									
									<CardContent
										className={classes.cardContent}
									>
										<Typography 
											//variant="body2" 
											variant="body1" 
											component="p"
										>
											{
												"'아이픽' 모바일 어플리케이션이 출시되었습니다."
											}
										</Typography>
										<br/>
										<Typography 
											//variant="body2" 
											variant="body1" 
											component="p"
										>
											{
												"애플 앱스토어 및 구글 플레이스토어에서"
											}
										
										</Typography>
										
											<Typography 
											//variant="body2" 
											variant="body1" 
											component="p"
										>
									
											<b>
												{
													' 어린이집 아이픽'
												}
											</b>
											{
												"을 검색해 보세요."
											}
										</Typography>
										<br/>
										<Typography 
											//variant="body2" 
											variant="body1" 
											component="p"
										>
											{ "많은 이용 부탁드립니다. 감사합니다."	
											}
										</Typography>
										
										<br/>
										
										<Typography 
											//variant="body2" 
											variant="body1" 
											component="p"
											align="center"
										>
											<Button
												className={classes.button}
												
												target="_blank"
												rel="noopener noreferrer"
												href={"https://apps.apple.com/kr/app/%EC%95%84%EC%9D%B4%ED%94%BD/id1474195534"}
												
												onClick={
													() => Event("NOTICE", "Go to iOS Appstore", "iOS Install")
												}
												
											>
												<img
													className="m-0 p-0"
													src="/img/logos/apple_appstore_logo.svg"
													alt="애플 앱스토어 설치 바로가기"
													
													style={{
														width:"8rem",
														height:"3.5rem"
													}}
												/>
											</Button>
											<Button
												className={
													classnames(
														classes.button,
														classes.noPadding
													)
												}
												
												target="_blank"
												rel="noopener noreferrer"
												href={"https://play.google.com/store/apps/details?id=kr.co.ipick"}
												
												onClick={
													() => Event("NOTICE", "Go to Android Appstore", "Android Install")
												}
											>
												<img
													//className="w-100 h-100 m-0 p-0"
													className="m-0 p-0"
													//className="w-100 m-0 p-0"
													
													src="/img/logos/google-play-badge.png"
													//src="/img/logos/google-play-badge.eps"
													
													alt="구글 플레이스토어 설치 바로가기"
													
													style={{
														width:"10rem",
														height:"3.75rem"
													}}
												/>
											</Button>
										</Typography>
											
									</CardContent>
									
									
									<CardActions
										
									>
										<Grid
											container
											
											direction="row"
											justify="flex-end"
											alignItems="center"
											
											spacing={1}
										>
											
											<Grid
												className={classes.textCenter}
												
												item
												//
												//xs={7}
												//sm={7}
												//md={6}
												//lg={6}
												//xl={5}
											>
												
												<FormControlLabel 
													className={classes.noMargin}
													control={
														<Checkbox 
															value={props.value}
															onChange={props.toggleTodayNoShow}
															color="primary"
														/>
													} 
													label="다음에 보지 않기" 
												/>
												
											</Grid>
											<Grid
												className={classes.textCenter}
												
												item
												//
												//xs={3}
												//sm={3}
												//md={2}
												//lg={2}
												//xl={2}
											>
												<Button
													className={classes.button}
													
													size="large"
													onClick={
														(e) => {
															props.closeReleaseNoticeYN();
														}
													}
												>
													{
														"닫 기"
													}
												</Button>
											</Grid>
										</Grid>	
										
									</CardActions>
								</Card>
							</Fade>
							
						</Modal>
					)
			}
		</Fragment>
		
	);
	
}