import React, {
	Component,
	Fragment
} from 'react';

import { connect } from 'react-redux';

import {
	login,
	loginSuccess,
	loginFailure,
	logout,
	logoutSuccess,
	logoutFailure
}
from '../../store/modules/userAuthentication';

import {
	isMobileOnly,
	isTablet,
	isIPad13,
} from 'react-device-detect';

import Gesture from 'rc-gesture';

import axios from 'axios';

import CustomAlert			from '../Utility/Alert/CustomAlert';

import {
	Paper,
	Typography,
	Box,
	Grid,
	Button,
	Divider,
	Container,
	Slide,
} from '@material-ui/core';

import {
	userLogout,
} from './IPickUser.js';

import MyPageDetail			from './MyPageDetail.js';
import WithdrawUserPanel	from './WithdrawUserPanel.js';

class MyPage extends Component {
	
	constructor ( props ) {
		
		super( props );
		
		this.state	= {
			
			//changeYN:false,
			
			password:"",
			isValidPassword:false,
			
			passwordConfirm:"",
			isValidPasswordConfirm:false,
			
			userNickName:"",			// 사용자가 입력한 닉네임
			isValidNickName:false,		// 사용자가 입력한 닉네임이 정규식에 유효한 경우 true
			userNickNameCheckResult:"",	// 서버에 중복확인 요청 후 중복이 아니면서 유효한 경우 success 리턴
			confirmnNickName:false,		// success 리턴 후 해당 닉네임을 사용하겠다는 사용자의 명시적 확인을 한 번 더 받으면 true로 지정하여
										// 다른 닉네임으로 변경하지 못하도록 설정
			msg:"",
			errMsg:"",
			errMsgType:"warning",
			
			changeNickNameYN:false,
			changePasswordYN:false,
			
			withdrawUserPanelOpen:false,
			
		};
		
		
		this.logout					= this.logout.bind(this);
		//this.toggleInfoChange		= this.toggleInfoChange.bind(this);
		
		
		this.typeNickName			= this.typeNickName.bind(this);
		this.typePassword			= this.typePassword.bind(this);
		this.typePasswordConfirm	= this.typePasswordConfirm.bind(this);
		this.checkNickName			= this.checkNickName.bind(this);
		this.toggleNickNameChangeStatus		= this.toggleNickNameChangeStatus.bind(this);
		this.togglePasswordChangeStatus		= this.togglePasswordChangeStatus.bind(this);
		this.changeMyInfo			= this.changeMyInfo.bind(this);
		
		
		this.toggleWithdrawUserPanelOpen	= this.toggleWithdrawUserPanelOpen.bind(this);
		
		this.closeAlert				= this.closeAlert.bind(this);
		this.createErrorMsg			= this.createErrorMsg.bind(this);
		this.setMsg					= this.setMsg.bind(this);
		
	}
	
	
	componentDidMount ( ) {
		
	}
	
	
	async logout ( ) {
		
		let result	= false;
		let msg		= "";
		let errMsg	= "";
		let errMsgType	= "warning";
		
		try {
			
			this.props.logout();
			
			const logoutResult	= await userLogout( );
			
			if ( false === logoutResult.result ) {
				result	= false;
				errMsg	= logoutResult.msg
				errMsgType	= "warning";
			} else {
				result	= true;
				msg			= logoutResult.msg;
				errMsgType	= "success";
			}
			
		} catch ( err ) {
			
			console.log( err );
			result	= false;
			errMsg	= "로그아웃 도중 오류가 발생했습니다.";
			errMsgType	= "error";
			
		} finally {
			
			if ( false === result ) {
				
				if ( ""  !== errMsg ) {
					
					this.createErrorMsg( errMsg, errMsgType );
					
				}
				
				this.props.loginFailure();
				
			} else {
				
				if ( ""  !== msg ) {
					
					// error 는 아니지만 상단에 표시해 준다.
					this.createErrorMsg( 
						msg,
						errMsgType,
					);
					
				}
				
				this.props.logoutSuccess();
				
			}
			
		}
		
	}
	
	
	async changeMyInfo( ) {
		
		let changeableYN	= false;
		let msg			= "";
		let errMsg		= "";
		let errMsgType	= "";
		
		const newUserNickName	= this.state.userNickName;
		
		if( 
			// 닉네임만 입력한 경우
			this.state.userNickName.length > 0 &&
			(
				this.state.password.length <= 0	&&
				this.state.passwordConfirm.length <= 0 
			)
		) {
			if( true === this.state.confirmnNickName ) {
				changeableYN	= true;
			} else {
				changeableYN	= false;
				
				if( true === this.state.isValidNickName ) {
					errMsg		= "닉네임 중복확인이 필요합니다.";
					errMsgType	= "warning";
					
					this.createErrorMsg(
						errMsg,
						errMsgType
					);
				}
				
			}
		} else if(
			// 닉네임, 비번 모두 입력한 경우
			this.state.userNickName.length > 0  &&
			(
				this.state.password.length > 0	&&
				this.state.passwordConfirm.length > 0 
			)
		) {
			if( 
				true === this.state.confirmnNickName	&&
				true === this.state.isValidPassword		&&
				true === this.state.isValidPasswordConfirm
			) {
				changeableYN	= true;
			} else {
				changeableYN	= false;
				
				if(
					true === this.state.isValidNickName	&&
					false === this.state.confirmnNickName
				) {
					errMsg		= "닉네임 중복확인이 필요합니다.";
					errMsgType	= "warning";
					
					this.createErrorMsg(
						errMsg,
						errMsgType
					);
				}
				
			}
		} else if(
			// 비번만 입력한 경우
			this.state.userNickName.length <= 0  &&
			(
				this.state.password.length > 0	&&
				this.state.passwordConfirm.length > 0 
			)
		) {
			if( 
				//true === this.state.confirmnNickName	&&
				true === this.state.isValidPassword		&&
				true === this.state.isValidPasswordConfirm
			) {
				changeableYN	= true;
			} else {
				changeableYN	= false;
			}
		} else {
			changeableYN	= false;
		}
		
		
		
		if( false  === changeableYN ) {
			//console.log( "false" );
			return;
		}
		
		
		msg			= "";
		errMsg		= "";
		errMsgType	= "";
		
		
		try {
			
			if (
				0		<	newUserNickName.length		&&
				true	===	this.state.changeNickNameYN	&&
				true	===	this.state.isValidNickName	&&
				true	===	this.state.confirmnNickName
			) {
				this.props.login();
			}
			
			const saveMyinfoResult	= await axios.post(
					'/user/saveMyinfo',
					{
						nickname:this.state.userNickName,
						password:this.state.password
					}
				);
			
			//console.log( saveMyinfoResult.data );
			
			if( "success" === saveMyinfoResult.data.result ) {
				
				msg			= saveMyinfoResult.data.msg;
				errMsgType	= "success";
				//this.props.getUserLoginInfo();
				
			} else {
				errMsg		= saveMyinfoResult.data.msg;
				errMsgType	= "warning";
			}
			
		} catch( err ) {
			console.log( err );
			errMsg		= "회원정보 저장에 실패하였습니다.";
			errMsgType	= "error";
		} finally {
			
			//if ( ""  !== errMsg ) {
			if ( "success"  !== errMsgType ) {
				
				if (
					0		<	newUserNickName.length		&&
					true	===	this.state.changeNickNameYN	&&
					true	===	this.state.isValidNickName	&&
					true	===	this.state.confirmnNickName
				) {
					this.props.loginFailure();
				}
				
				this.createErrorMsg( 
					errMsg,
					errMsgType,
				);
			} else {
				
				if (
					0		<	newUserNickName.length		&&
					true	===	this.state.changeNickNameYN	&&
					true	===	this.state.isValidNickName	&&
					true	===	this.state.confirmnNickName
				) {
					this.props.loginSuccess(
						{
							loginYN	: this.props.userInfo.loginYN,
							userId	: this.props.userInfo.userId,
							userEmail: this.props.userInfo.userEmail,
							userNickname: newUserNickName,
							nicknameConfirmYN	: this.props.userInfo.nicknameConfirmYN,
							friendShareLink		: this.props.userInfo.friendShareLink,
						}
					);
				}
				
				if ( ""  !== msg ) {
					this.createErrorMsg( 
						msg,
						errMsgType,
					);
					
					this.setMsg( msg );
				}
				
				
				this.setState({
					password:"",
					isValidPassword:false,
					
					passwordConfirm:"",
					isValidPasswordConfirm:false,
					
					userNickName:"",
					isValidNickName:false,	
					userNickNameCheckResult:"",
					confirmnNickName:false,	
					
					changeNickNameYN:false,
					changePasswordYN:false,
				});
			}
			
		}
	}
	
	
	async checkNickName() {
		
		if( false === this.state.isValidNickName ) {
			return;
		}
		
		let userNickNameCheckResult	= "";
		let confirmnNickName	= false;
		
		
		let msg			= "";
		let errMsg		= "";
		let errMsgType	= "";
		
		
		try {
			
			const nicknameDupCheckResult	= await axios.post(
					'/user/nicknameDupCheck',
					{
						nickname:this.state.userNickName
					}
				);
			
			console.log( nicknameDupCheckResult.data );
			
			if( "success" === nicknameDupCheckResult.data.result ) {
				userNickNameCheckResult	= nicknameDupCheckResult.data.result;
				msg			= nicknameDupCheckResult.data.msg;
				confirmnNickName	= true;
			} else {
				userNickNameCheckResult	= nicknameDupCheckResult.data.result;
				errMsg		= nicknameDupCheckResult.data.msg;
				errMsgType	= "warning";
				confirmnNickName	= false;
			}
			
		} catch ( err ) {
			
			console.log( err );
			userNickNameCheckResult	= "error";
			errMsg	= "닉네임 중복확인 중에 에러가 발생했습니다.";
			errMsgType	= "warning";
			confirmnNickName	= false;
			
		} finally {
			
			if ( false === confirmnNickName ) {
				
				if ( "" !== errMsg ) {
					this.createErrorMsg( 
						errMsg,
						errMsgType
					);
					
					this.setMsg( errMsg );
				}
				
			} else {
				
				if ( "" !== msg ) {
					this.setMsg( msg );
				}
				
			}
			
			this.setState({
				userNickNameCheckResult:userNickNameCheckResult,
				confirmnNickName:confirmnNickName,
			});
			
			
			
		}
		
	}
	
	
	typeNickName ( e ) {
		
		e.preventDefault();
		
		//const nickNamePattern	= /^[\w가-힣]{2,20}$/;
		const nickNamePattern	= /^[가-힣|a-z|A-Z|0-9][가-힣|a-z|A-Z|0-9|_]{2,15}$/;
		
		let tmp_nick_name	= e.target.value;
		
		tmp_nick_name		= tmp_nick_name.replace( nickNamePattern, "" );
		
		
		if( ""  !== tmp_nick_name ) {
			this.setState({
				userNickName: e.target.value,
				isValidNickName : false,
				userNickNameCheckResult:"",
				confirmnNickName:false,
				msg:""
			});
		} else {
			this.setState({
				userNickName: e.target.value,
				isValidNickName : true,
				userNickNameCheckResult:"",
				confirmnNickName:false,
				msg:""
			});
		}
		
	}
	
	
	//toggleInfoChange ( e ) {
	//	
	//	//if( true === this.state.changeYN ) {
	//		
	//		this.setState({
	//			//changeYN:!this.state.changeYN,
	//			
	//			password:"",
	//			passwordConfirm:"",
	//			isValidPassword:false,
	//			isValidPasswordConfirm:false,
	//			msg:"",
	//			userNickName:"",
	//			userNickNameCheckResult:"",
	//			confirmnNickName:false,
	//			changeNickNameYN:false,
	//			changePasswordYN:false,
	//		});
	//		
	//	//} else {
	//	//	this.setState({
	//	//		changeYN:!this.state.changeYN
	//	//	});
	//	//}
	//	
	//}
	
	
	typePassword( e ) {
		
		//e.preventDefault();
		
		const regexp_for_password	= /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
		
		
		let tmp_password	= e.target.value;
			
		tmp_password	= tmp_password.replace( regexp_for_password, "" );
		
		if( ""  !== tmp_password ) {
			this.setState({
				password: e.target.value,
				isValidPassword : false
			});
		} else {
			this.setState({
				password: e.target.value,
				isValidPassword : true
			});
		}
		
	}
	
	
	typePasswordConfirm( e ) {
		//e.preventDefault();
		
		let flag	= false;
		
		if( this.state.password === e.target.value ) {
			flag	= true;
		} else {
			flag	= false;
		}
		
		this.setState({
			passwordConfirm : e.target.value,
			isValidPasswordConfirm : flag
		});
		
	}
	
	
	toggleNickNameChangeStatus( e ) {
		
		let userNickName			= this.state.userNickName;
		let userNickNameCheckResult	= this.state.userNickNameCheckResult;
		let confirmnNickName		= this.state.confirmnNickName;
		//console.log( e.target.value );
		//console.log( e.target.checked );
		if( 
			false === e.target.checked
		) {
			userNickName	= "";
			
			userNickNameCheckResult	= "";
			confirmnNickName		= false;
		}
		
		this.setState({
			changeNickNameYN: e.target.checked,
			userNickName:userNickName,
			
			userNickNameCheckResult:userNickNameCheckResult,
			confirmnNickName:confirmnNickName
		});
		
	}
	
	
	togglePasswordChangeStatus( e ) {
		
		let password			= this.state.password;
		let isValidPassword		= this.state.isValidPassword;
		let passwordConfirm		= this.state.passwordConfirm;
		let isValidPasswordConfirm	= this.state.isValidPasswordConfirm;
		//console.log( e.target.value );
		console.log( e.target.checked );
		if( 
			false === e.target.checked
		) {
			password			= "";
			isValidPassword		= false;
			passwordConfirm	= "";
			isValidPasswordConfirm	= false;
		}
		
		this.setState({
			changePasswordYN: e.target.checked,
			
			password:password,
			isValidPassword:isValidPassword,
			passwordConfirm:passwordConfirm,
			isValidPasswordConfirm:isValidPasswordConfirm
		});
		
	}
	
	
	toggleWithdrawUserPanelOpen ( e ) {
		
		this.setState({
			withdrawUserPanelOpen:!this.state.withdrawUserPanelOpen
		});
		
	}
	
	
	/**
	 *	에러 메시지 닫기 버튼 클릭 시 호출되는 메소드...
	 */
	closeAlert ( ) {
		this.setState({
			errMsg: ''
		});
	}

	/**
	 *	에러 메시지를 띠우는 메소드...
	 */
	createErrorMsg ( 
		errMsg,
		errMsgType, 
	) {
		this.setState({
			errMsg: errMsg,
			errMsgType:errMsgType,
		});
		
		//setTimeout(this.closeAlert, 3000);
	}
	
	
	setMsg ( msg ) {
		this.setState({
			msg: msg
		});
	}
	
	
	render ( ) {
		
		const { errMsgType } = this.state;
		
		//console.log( this.props.userInfo );
		//console.log( this.state.userInfo );
		
		let tmpErrMsgType	= "warning";
		
		if (
			"success"	=== errMsgType	||
			"warning"	=== errMsgType	||
			"error"		=== errMsgType	||
			"info"		=== errMsgType
		) {
			tmpErrMsgType	= errMsgType;
		}
		
		let changeableYN	= false;
		
		
		if( 
			// 닉네임만 입력한 경우
			this.state.userNickName.length > 0 &&
			(
				this.state.password.length <= 0	&&
				this.state.passwordConfirm.length <= 0 
			)
		) {
			if( true === this.state.confirmnNickName ) {
				changeableYN	= true;
			} else {
				changeableYN	= false;
			}
		} else if(
			// 닉네임, 비번 모두 입력한 경우
			this.state.userNickName.length > 0  &&
			(
				this.state.password.length > 0	&&
				this.state.passwordConfirm.length > 0 
			)
		) {
			if( 
				true === this.state.confirmnNickName	&&
				true === this.state.isValidPassword		&&
				true === this.state.isValidPasswordConfirm
			) {
				changeableYN	= true;
			} else {
				changeableYN	= false;
			}
		}
		else if(
			// 비번만 입력한 경우
			this.state.userNickName.length <= 0  &&
			(
				this.state.password.length > 0	&&
				this.state.passwordConfirm.length > 0 
			)
		) {
			if( 
				//true === this.state.confirmnNickName	&&
				true === this.state.isValidPassword		&&
				true === this.state.isValidPasswordConfirm
			) {
				changeableYN	= true;
			} else {
				changeableYN	= false;
			}
		} else {
			changeableYN	= false;
		}
		
		//console.log(changeableYN);
		
		return (
			<Fragment>
				<Slide 
					direction={
						( isMobileOnly ) ?
							"left" : "right"
					}
					in={true}
					
					//style={{ 
					//	transitionDelay: '100ms' 
					//}}
				>
					<Gesture
						onSwipeRight={
							( gestureStatus ) => {
								if (
									true === isMobileOnly	||
									true === isTablet
								) {
									this.props.closeLoginPanel();
								}
							}
						}
						direction="horizontal"
					>
						<Container
							fixed={true}
							//maxWidth="xs"
							maxWidth="sm"
							
							style={
								(
									true	=== isMobileOnly	||
									true	===	isTablet		||
									true	===	isIPad13
								) ?
									(
										{
											height:"calc( 100vh - 3rem )",
											margin:0,
											padding:0,
											
											
											display:"flex",
											flexDirection:"column",
											overflow:"hidden",
										}
									) :
									(
										{
											position:"fixed",
											top:"0",
											left:"3.75rem",
											height:"100vh",
											margin:0,
											padding:0,
											
											
											display:"flex",
											flexDirection:"column",
											overflow:"hidden",
										}
									)
							}
						>
							<CustomAlert
								open={
									undefined	!==	this.state.errMsg		&&
									null		!==	this.state.errMsg		&&
									0			<	this.state.errMsg.length
								}
								data={this.state.errMsg} 
								option={tmpErrMsgType}
								closeAlert={this.closeAlert} 
							/>
							
							<MyPageDetail
								logout={this.logout}
								//toggleInfoChange={this.toggleInfoChange}
								
								typeNickName={this.typeNickName}
								typePassword={this.typePassword}
								typePasswordConfirm={this.typePasswordConfirm}
								
								toggleNickNameChangeStatus={this.toggleNickNameChangeStatus}
								togglePasswordChangeStatus={this.togglePasswordChangeStatus}
								toggleWithdrawUserPanelOpen={this.toggleWithdrawUserPanelOpen}
								
								checkNickName={this.checkNickName}
								changeMyInfo={this.changeMyInfo}
								
								
								msg={
									( 
										undefined	===	this.state.msg	||
										null		=== this.state.msg
									) ?
										"" : this.state.msg
								}
								errMsgType={this.state.errMsgType}
								setMsg={this.setMsg}
								createErrorMsg={this.createErrorMsg}
								
								userInfo={this.props.userInfo}
								isValidNickName={this.state.isValidNickName}
								userNickNameCheckResult={this.state.userNickNameCheckResult}
								isValidPassword={this.state.isValidPassword}
								isValidPasswordConfirm={this.state.isValidPasswordConfirm}
								changeableYN={changeableYN}
								closeLoginPanel={this.props.closeLoginPanel}
							/>
							
							{
								( true === this.state.withdrawUserPanelOpen ) ?
									(
										<WithdrawUserPanel
											isOpen={this.state.withdrawUserPanelOpen}
											toggleWithdrawUserPanelOpen={this.toggleWithdrawUserPanelOpen}
											createErrorMsg={this.createErrorMsg}
											//getUserLoginInfo={this.props.getUserLoginInfo}
											
											closeLoginPanel={this.props.closeLoginPanel}
										/>
										//null
									) : null
							}
							
						</Container>
					</Gesture>
				</Slide>
			</Fragment>
		);
		
	}
	
}


//export default MyPage;

// props 로 넣어줄 스토어 상태값
const mapStateToProps = state => ({
	userInfo: state.userAuthentication.userInfo
});

// props 로 넣어줄 액션 생성함수
const mapDispatchToProps = dispatch => ({
	//userAuthentication: userInfo => dispatch( userAuthentication( userInfo ) ),
	login			: ( ) => dispatch( login( ) ) ,
	loginSuccess	: userInfo => dispatch( loginSuccess( userInfo ) ) ,
	loginFailure	: ( ) => dispatch( loginFailure( ) ) ,
	logout			: ( ) => dispatch( logout( ) ) ,
	logoutSuccess	: ( ) => dispatch( logoutSuccess( ) ) ,
	logoutFailure	: ( ) => dispatch( logoutFailure( ) ) ,
});

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( MyPage );