import React, { PropTypes } from 'react';

import { 
	BrowserRouter as Router,
	//Router,
} from 'react-router-dom';

import IPick from 'shared/IPick';

import { 
	createStore, 
	//applyMiddleware 
} from 'redux';
import rootReducer	from '../store/modules';
import { Provider }	from 'react-redux';

//import { 
//	isMobile, 
//	isIOS, 
//	isMobileOnly, 
//	isTablet, 
//	isAndroid, 
//	isBrowser,
//	isIPad13,
//} from 'react-device-detect';


// 리덕스 개발자도구 적용
const devTools	= window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = createStore( rootReducer, devTools )

//console.log( store.getState() );

const Root = ( ) => (
	
	<Provider
		store={store}
	>
		<Router
			
		>
			<IPick
			/>
		</Router>
	</Provider>
	
);


export default Root;