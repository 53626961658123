import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';


import TimeAgo from 'react-timeago'
import koreanStrings from 'react-timeago/lib/language-strings/ko'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import clsx from 'clsx';
import axios from 'axios';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';

import { 
	Event, 
	//PageView, 
	//initGA 
} from './Tracking.js';

import StarRatingContainer	from './StarRating/StarRatingContainer.js';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Link,
	Box,
	
	Collapse,
	Avatar,
	Chip,
	Card,
	CardContent,
	CardHeader,
	CardActions,
	CardActionArea,
	
	Menu,
	MenuItem,
	
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	
	useMediaQuery,
	TextField,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
	Favorite		as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
	MoreVert		as MoreVertIcon,
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	//linkButtonHover:{
	//	'&:hover' : {
	//		textDecoration : "underline",
	//	},
	//},
	//linkButtonNone:{
	//	textDecoration : "none",
	//},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	overflowYVisible:{
		overflowY:"visible",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	avatar: {
		backgroundColor: red[500],
 	},
	
	dialogPaper: 
		( true === isMobileOnly ) ?
			{
				margin	: theme.spacing(0),
				minWidth: "90vw",
			} :
			{
				margin: theme.spacing(0),
			},
}));


const formatter = buildFormatter(koreanStrings);
const ITEM_HEIGHT = 48;
const MAX_STAR_COUNT	= 5;



export default function CardList ( props ) {
	
	const classes	= useStyles();
	

	const [anchorEl, setAnchorEl]	= React.useState(null);
 	const menuOpen	= Boolean(anchorEl);
	
	const menuItemClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const menuItemClose = ( ) => {
		setAnchorEl(null);
	};
	
	const [menuItemComponent, setMenuItemComponent]	= React.useState(null);
	
	
	
	const [menuItemDialogOpenYN, setMenuItemDialogOpenYN]	= React.useState(false);
	
	const openMenuItemDialog = ( ) => {
		setMenuItemDialogOpenYN(true);
	};
	
	const closeMenuItemDialog = ( ) => {
		//console.log( "hi!!!" );
		
		setMenuItemDialogOpenYN(false);
		setMenuItemComponent( null );
	};
	
	
	//console.log( props.data );
	
	let cardList	= [];
	
	if ( 
		//undefined	===	props.cardType	||
		"basic"		=== props.cardType
	) {
		
		if ( 
			undefined	!==	props.data	&&
			null		!==	props.data	&&
			0			<	props.data.length
		) {
			cardList	= props.data.map(
				( card, idx ) => (
					<Fragment
						key={props.id + "_" + card.source_url + "_" + idx.toString()}
					>
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
								className={
									clsx(
										//classes.paddingSmall,
										classes.padding,
									)
								}
							>
								{
								//<Link
								//	target="_blank" 
								//	href={card.source_detail_url} 
								//	rel="noopener noreferrer"	
								//	//component={"a"}
								//	
								//	color={"inherit"}
								//	
								//	underline={"none"}
								//	
								//	//className={
								//	//	clsx(
								//	//		//classes.linkButtonNone,
								//	//	)
								//	//}
								//	
								//	style={{
								//		textDecoration:"none",
								//	}}
								//>
								}
									<Card 
										className={
											clsx(
												classes.padding,
											)
										}
										
										//style={{
										//	width:"100%"
										//}}
									>
										<CardActionArea
											target="_blank" 
											href={card.source_detail_url} 
											rel="noopener noreferrer"	
											//component={"a"}
											color={"inherit"}
											
											underline={"none"}
											style={{
												textDecoration:"none",
											}}
										>
											<CardHeader
												
												avatar={
													( undefined !== card.avatar ) ?
														(
															<ThemeProvider
																theme={markerInfoTheme}
															>
																<Chip
																	key={"card_avatar_" + idx.toString()}
																	label={card.avatar} 
																	color={
																		( 
																			"ulzip"		=== card.dataType	||
																			"kinder"	=== card.dataType
																		) ?
																			(
																				( "ulzip"		=== card.dataType ) ?
																					"secondary" : "primary"
																			) :
																			(
																				"default"
																			)
																	}
																	className={
																		clsx(
																			classes.fontWeightBold,
																		)
																	}
																	
																	style={{
																		margin:3,
																		lineHeight:"100%",
																	}} 
																/>
															</ThemeProvider>
														) : null
												}
												
												title={
													<Typography
														variant="h6"
														component="h6"
														className={
															clsx(
																classes.fontWeightBold,
															)
														}
													>
														{
															card.title
														}
													</Typography>
												}
												
												//action={}
												
												className={
													clsx(
														classes.noMargin,
														classes.paddingSmall,
													)
												}
												
											/>
											<CardContent
												className={
													clsx(
														classes.noMargin,
														classes.padding,
													)
												}
												
												style={{
													whiteSpace: 
														//'pre-line'
														'pre-wrap'
												}}
											>
												
												<Typography
													variant="body2"
													color="inherit"
													component="p"
													
												>
													{card.description}
												</Typography>
												
											</CardContent>
											<CardActions
												
											>
												<Typography
													variant="subtitle2"
													color="inherit"
													component="span"
													
													className={
														clsx(
															classes.textRight,
														)
													}
													
													style={{
														width:"100%",
													}}
												>
													{card.source_name}
												</Typography>
											</CardActions>
										</CardActionArea>
									</Card>
								{
								//</Link>
								}
							</Grid>
						</Grid>
					</Fragment>
				)
			);
		}
		
	} else if (
		"headlineNews"		=== props.cardType
	) {
		
		if ( 
			undefined	!==	props.data	&&
			null		!==	props.data	&&
			0			<	props.data.length
		) {
			cardList	= props.data.map(
				( card, idx ) => (
					<Fragment
						key={props.id + "_" + card.source_url + "_" + idx.toString()}
					>
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
								className={
									clsx(
										//classes.paddingSmall,
										classes.padding,
									)
								}
							>
								{
								//<Link
								//	target="_blank" 
								//	href={card.source_detail_url} 
								//	rel="noopener noreferrer"	
								//	//component={"a"}
								//	
								//	color={"inherit"}
								//	
								//	underline={"none"}
								//	
								//	//className={
								//	//	clsx(
								//	//		//classes.linkButtonNone,
								//	//	)
								//	//}
								//	
								//	style={{
								//		textDecoration:"none",
								//	}}
								//>
								}
									<Card 
										className={
											clsx(
												classes.padding,
											)
										}
										
										
									>
										<CardActionArea
											//target="_blank" 
											//href={card.source_detail_url} 
											//rel="noopener noreferrer"	
											
											
											//component={"a"}
											color={"inherit"}
											
											underline={"none"}
											style={{
												textDecoration:"none",
												//width:"100%",
											}}
											
											onClick={
												( e ) => {
													//console.log( card );
													//console.log( card.component );
													
													//setMenuItemComponent( 
													//	card.component( 
													//		{
													//			closeDialog	: closeMenuItemDialog,
													//		}
													//	)
													//);
													//openMenuItemDialog();
													card.param.setDialogComponent(
														card.component(
															{
																closeDialog	: card.param.closeDialog,
															}
														)
													);
													card.param.openDialog();
													
													Event("ACTION", "Headline News Detail Clicked", "HeadlineNews Action");
												}
											}
										>
											
											<CardContent
												className={
													clsx(
														classes.noMargin,
														classes.padding,
													)
												}
												
											>
												<Typography
													variant="body2"
													//component="h6"
													
													display={
														//"inline"
														"block"
													}
													noWrap={true}
													
													className={
														clsx(
															classes.fontWeightBold,
														)
													}
													
												>
													{
														card.title
													}
												</Typography>
											</CardContent>
											
										</CardActionArea>
									</Card>
								{
								//</Link>
								}
							</Grid>
						</Grid>
					</Fragment>
				)
			);
		}
		
	} else if (
		"userRating"		=== props.cardType
	) {
		if ( 
			undefined	!==	props.data	&&
			null		!==	props.data	&&
			0			<	props.data.length
		) {
			cardList	= props.data.map(
				( card, idx ) => (
					<Fragment
						key={props.id + "_" + card.source_url + "_" + idx.toString()}
					>
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
								className={
									clsx(
										//classes.paddingSmall,
										classes.padding,
									)
								}
							>
								<Card 
									className={
										clsx(
											classes.padding,
										)
									}
									
									//style={{
									//	width:"100%"
									//}}
								>
									<CardHeader
										
										avatar={
											( undefined !== card.avatar ) ?
												(
													<ThemeProvider
														theme={markerInfoTheme}
													>
														<Chip
															key={"card_avatar_" + idx.toString()}
															label={card.avatar} 
															color={
																( 
																	"rating"	=== card.dataType	||
																	"sharing"	=== card.dataType
																) ?
																	(
																		( "rating"	=== card.dataType ) ?
																			"secondary" : "primary"
																	) :
																	(
																		"default"
																	)
															}
															
															style={{
																margin:3,
																lineHeight:"100%",
															}}
														/>
													</ThemeProvider>
												) : null
										}
										
										title={
											<Grid
												container
												direction="row"
												justify="flex-start"
												alignItems="center"
											>
												<Grid
													item
													container
													direction="column"
													justify="center"
													alignItems={
														//"center"
														"flex-start"
													}
													
												>
													{
														(
															undefined	===	card.title	||
															null		===	card.title	||
															""			===	card.title	||
															"0"			===	card.title	||
															0			>=	Number( card.title )
														) ?
															(
																null
															) :
															(
																<Grid
																	item
																>
																	<Fragment>
																		<StarRatingContainer
																			fontSize={"inherit"}
																			ratingMaxCount={MAX_STAR_COUNT}
																			ratingValue={ Number( card.title ) }
																			
																			editable={false}
																		/>
																	</Fragment>
																</Grid>
															)
													}
													<Grid
														item
													>
														{
															card.nickname
														}
													</Grid>
												</Grid>
											</Grid>
										}
										
										action={
											( 
												undefined	!==	card.menu	&&
												null		!==	card.menu	&&
												0			<	card.menu.length
											) ?
												(
													<Fragment>
														<IconButton 
															//aria-label="settings"
															className={
																clsx(
																	classes.button,
																)
															}
															
															onClick={menuItemClick}
														>
															<MoreVertIcon
																
															/>
														</IconButton>
														<Menu
															id="long-menu"
															anchorEl={anchorEl}
															keepMounted
															open={menuOpen}
															onClose={menuItemClose}
															PaperProps={{
																style: {
																	maxHeight: ITEM_HEIGHT * 4.5,
																	width: 200,
																},
															}}
														>
															{
																card.menu.map( 
																	item => (
																		<MenuItem 
																			key={item.name} 
																			//selected={} 
																			onClick={
																				(e) => {
																					setMenuItemComponent( 
																						item.component( 
																							{
																								closeDialog	: closeMenuItemDialog,
																							}
																						) 
																					);
																					openMenuItemDialog();
																					menuItemClose();
																				}
																			}
																		>
																			{ item.nameKor }
																		</MenuItem>
																	)
																)
															}
														</Menu>
													</Fragment>
												) : null
										}
										
										className={
											clsx(
												classes.noMargin,
												classes.paddingSmall,
											)
										}
										
									/>
									<CardContent
										className={
											clsx(
												classes.noMargin,
												classes.padding,
											)
										}
										
										style={{
											whiteSpace: 
												//'pre-line'
												'pre-wrap'
										}}
									>
										{
											( 
												undefined	===	card.source_detail_url	||
												null		===	card.source_detail_url	||
												""			===	card.source_detail_url
											) ?
												(
													<Typography
														variant="body2"
														color="inherit"
														component="p"
														
													>
														{card.description}
													</Typography>
												) :
												(
												//카페 검색 클릭
													<Button
														target="_blank" 
														href={card.source_detail_url} 
														rel="noopener noreferrer"	
														component={"a"}
														fullWidth={true}
														
														className={
															clsx(
																//classes.button
																classes.noGutters,
															)
														}
														onClick={
															(e) => {
																Event("ACTION", "Cafe Detail Clicked", "Cafe Action");
															}
														}
													>
														<Typography
															variant="body2"
															color="inherit"
															component="p"
														>
															{card.description}
														</Typography>
													</Button>
												)
										}
									</CardContent>
									
									{
										(
											(
												undefined	===	card.source_url		||
												null		===	card.source_url		||
												""			=== card.source_url
											) &&
											(
												undefined	===	card.update_date	||
												null		===	card.update_date	||
												""			=== card.update_date
											)
										) ?
											(
												null
											) :
											(
												<CardActions
													
													className={
														clsx(
															//classes.textRight,
														)
													}
													
													style={{
														flexDirection:"row-reverse"
													}}
													
												>
													{
														( 
															undefined	!==	card.source_url	&&
															null		!==	card.source_url	&&
															""			!== card.source_url
														) ?
															(
																<ThemeProvider
																	theme={markerInfoTheme}
																>
																	<Button
																		color={
																			//( "ulzip" === props.type ) ?
																			( "ulzip" === card.dataType ) ?
																				"secondary" :
																				(
																					//( "kinder" === props.type ) ?
																					( "kinder" === card.dataType ) ?
																						"primary" : "inherit"
																				)
																		}
																		variant="contained"
																		
																		target="_blank" 
																		href={card.source_url} 
																		rel="noopener noreferrer"	
																		component={"a"}
																		fullWidth={true}
																		
																		className={
																			clsx(
																				classes.button
																			)
																		}
																	>
																		{card.source_name}
																	</Button>
																</ThemeProvider>
															) :
															(
																(
																	undefined	!==	card.update_date	&&
																	null		!==	card.update_date	&&
																	""			!== card.update_date
																) ?
																	(
																		//<ThemeProvider
																		//	theme={markerInfoTheme}
																		//>
																		//	<Box
																		//		bgcolor={
																		//			"fourth.main"
																		//		}
																		//		
																		//		color={
																		//			"fourth.contrastText"
																		//		}
																		//	>
																				<Typography
																					variant="subtitle2"
																					//color="inherit"
																					component="span"
																				>
																					<TimeAgo 
																						date={card.update_date} 
																						formatter={formatter}
																					/>
																				</Typography>
																		//	</Box>
																		//</ThemeProvider>
																	) :
																	(
																		null
																	)
																
															)
													}
												</CardActions>
											)
									}
								</Card>
							</Grid>
						</Grid>
					</Fragment>
				)
			);
		}
	} else {
		if ( 
			undefined	!==	props.data	&&
			null		!==	props.data	&&
			0			<	props.data.length
		) {
			cardList	= props.data.map(
				( card, idx ) => (
					<Fragment
						key={props.id + "_" + card.source_url + "_" + idx.toString()}
					>
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
								className={
									clsx(
										//classes.paddingSmall,
										classes.padding,
									)
								}
							>
								<Card 
									className={
										clsx(
											classes.padding,
										)
									}
									
									//style={{
									//	width:"100%"
									//}}
								>
									<CardActionArea
										target="_blank" 
										href={card.source_detail_url} 
										rel="noopener noreferrer"	
										//component={"a"}
										color={"inherit"}
										
										underline={"none"}
										style={{
											textDecoration:"none",
										}}
									>
										<CardHeader
											
											avatar={
												null
											}
											
											title={
												<Typography
													variant="h6"
													component="h6"
													className={
														clsx(
															classes.fontWeightBold,
														)
													}
												>
													{
														card.title
													}
												</Typography>
											}
											
											//action={}
											
											className={
												clsx(
													classes.noMargin,
													classes.paddingSmall,
												)
											}
											
										/>
										<CardContent
											className={
												clsx(
													classes.noMargin,
													classes.padding,
												)
											}
											
											style={{
												whiteSpace: 
													//'pre-line'
													'pre-wrap'
											}}
										>
											
											<Typography
												variant="body2"
												color="inherit"
												component="p"
												
											>
												{card.description}
											</Typography>
											
										</CardContent>
										<CardActions
											
										>
											<Typography
												variant="subtitle2"
												color="inherit"
												component="span"
												
												className={
													clsx(
														classes.textRight,
													)
												}
												
												style={{
													width:"100%",
												}}
											>
												{card.source_name}
											</Typography>
										</CardActions>
									</CardActionArea>
								</Card>
							</Grid>
						</Grid>
					</Fragment>
				)
			);
		}
	}
	
	
	
	return (
		<Fragment>
			
			{
				( 0 < cardList.length ) ?
					(
						<Container
							maxWidth="xs"
							
							className={
								clsx(
									classes.noGutters,
									(  
										false === props.overflowYScrollYN
									) ?
										classes.overflowYVisible : classes.overflowYScroll,
								)
							}
						>
							
							{ cardList }
							
						</Container>
					) : null
			}
			
			<Dialog
				open={menuItemDialogOpenYN}
				fullWidth={true}
				maxWidth="xs"
				scroll="paper"
				
				PaperProps={
					{ 
						classes	: {
							root: classes.dialogPaper 
						} 
					}
				}
				
				className={
					clsx(
						classes.noMargin,
						classes.padding,
					)
				}
				
				onClose={closeMenuItemDialog}
			>
				<Fragment>
					{
						menuItemComponent
					}
				</Fragment>
			</Dialog>
			
		</Fragment>
	);
	
}