import React, { Component, Fragment } from 'react';

import queryString from 'query-string';
import clsx from 'clsx';
import axios from 'axios';

import { 
	//isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	isIPad13,
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { connect } from 'react-redux';

import {
	increasePageDepth,
	decreasePageDepth,
	setPageDepth,
} from '../../store/modules/currentPageSync';

import YouTube from 'react-youtube';

import ITubeMainHeader		from '../../components/iTube/iTubeMain/ITubeMainHeader.js';
import ITubeMainDetail		from '../../components/iTube/iTubeMain/ITubeMainDetail.js';
import ITubeMainFooter		from '../../components/iTube/iTubeMain/ITubeMainFooter.js';

import IPickPageContainer	from '../../components/Utility/IPickPage/IPickPageContainer.js';
import CustomAlert			from '../../components/Utility/Alert/CustomAlert';

import {
	Container,
	Grid,
	Box,
	Button,
	IconButton,
	Slide,
} from '@material-ui/core';

import {
    Close			as CloseIcon,
    KeyboardArrowUp	as KeyboardArrowUpIcon,
    ArrowBackIos	as ArrowBackIosIcon
} from '@material-ui/icons';


import { Event, PageView, initGA } from '../../components/Utility/Tracking.js';
import Gesture from 'rc-gesture';



class iTube extends Component {
	
	constructor( props ) {
		
		super( props );
		
		const	itubeQuery	= JSON.parse( localStorage.getItem( 'itubeQuery' ) );
		let		tempQuery	= "10개월 11개월 12개월";
		
		if ( 
			null		!== itubeQuery	&&
			undefined	!== itubeQuery 
		) {
			
			tempQuery	= itubeQuery;
			
		}
		
		//console.log(tempQuery);
		
		this.state = {
			currentPage	: this.props.currentPage,
			
			//query: "10개월 11개월 12개월",
			query: tempQuery,
			chipVal	: "",
			
			isLoading: true,
			contents: null,
			
			//iTubePlayerOpenYN: false,
			
			item: null,
			
			errMsg:"",
			errMsgType:"warning",
		};
		
		//console.log( this.state.query );
		
		this.getVideos			= this.getVideos.bind(this);
		this.iTubeClose			= this.iTubeClose.bind(this);
		
		this.chipClickCallback	= this.chipClickCallback.bind(this);
		this.iTubePlayerClickCallback	= this.iTubePlayerClickCallback.bind(this);
		
		this.setQuery			= this.setQuery.bind(this);
		
		this.createErrorMsg		= this.createErrorMsg.bind(this);
		this.closeAlert			= this.closeAlert.bind(this);
		
	}

	
	componentDidMount ( ) {
		this.getVideos( );
		
		if ( 
			1				!==	this.props.currentPage.depth	&&
			"PAGE_ITUBE"	===	this.props.currentPage.data
		) {
			this.props.setPageDepth(
				{
					data	: this.props.currentPage.data,
					depth	: 1,
				}
			);
		}
		
		Event("iTube", "iTube Page View", "iTube Page View Action");
	}

	componentDidUpdate ( prevProps, prevState ) {
		if ( prevState.query !== this.state.query ) {
			this.getVideos( );
		}
		
		if ( prevState.chipVal !== this.state.chipVal ) {
			this.getVideos( );
		}
		
		if ( 
			1				!==	this.props.currentPage.depth	&&
			"PAGE_ITUBE"	===	this.props.currentPage.data
		) {
			this.props.setPageDepth(
				{
					data	: this.props.currentPage.data,
					depth	: 1,
				}
			);
		}
	}
	
	static getDerivedStateFromProps( nextProps, prevState ) {
		
		if ( 
			prevState.currentPage.data	!== nextProps.currentPage.data	||
			prevState.currentPage.depth	!== nextProps.currentPage.depth
		) {
			return {
				currentPage	: nextProps.currentPage
			};
		}
		
		return null;
	}
	
	async getVideos( ) {
		//console.log(window.location.pathname);
		//https://ulziphow-back.run.goorm.io/main/api/videoSearch
		
		let errMsg = '';
		let errMsgType = 'warning';
		let videoResult = [];
		
		let myQuery	= "";
		
		if ( "" === this.state.query ) {
			myQuery	= "영유아 어린이 아기 애기 아이";
		} else {
			myQuery	= this.state.query;
		}
		
		myQuery	= myQuery + " " + this.state.chipVal;
		
		if (
			-1	===	myQuery.indexOf( "영유아" )	&&
			-1	===	myQuery.indexOf( "어린이" )	&&
			-1	===	myQuery.indexOf( "아기" )		&&
			-1	===	myQuery.indexOf( "애기" )		&&
			-1	===	myQuery.indexOf( "아이" )
		) {
			myQuery	= "아기 아이 " + myQuery;
		}
		
		//console.log( myQuery );
		
		try {
			
			const videoSearchResult = await axios.get(
				'/main/api/videoSearch?query=' + encodeURIComponent( myQuery )
			);
			
			//console.log(videoSearchResult.data);
			
			if ( 
				undefined	!==	videoSearchResult		&&
				null		!==	videoSearchResult		&&
				undefined	!==	videoSearchResult.data	&&
				null		!==	videoSearchResult.data	&&
				undefined	!==	videoSearchResult.data.data	&&
				null		!==	videoSearchResult.data.data	&&
				0			<	videoSearchResult.data.data.length	&&
				"success"	===	videoSearchResult.data.result
			) {
				videoResult = videoSearchResult.data.data;
				//errMsg		= videoSearchResult.data.msg
				errMsgType	= "success";
				
				//console.log( videoResult[0] );
				
			} else {
				errMsg		= videoSearchResult.data.msg
				errMsgType	= "warning";
			}
			
		} catch ( err ) {
		    console.log(err);
		    errMsg = 'videoSearch error...';
		    errMsgType = 'error';
		} finally {
			
			if ( "success" !== errMsgType ) {
				
				if ( "" !== errMsg ) {
					this.createErrorMsg(
						errMsg,
						errMsgType
					);
				}
				
			}
			
			this.setState({
				contents: videoResult,
				isLoading: false
			});
			
			
			//Event("iTube", "iTube Page View", "iTube Page View Action");
			
		}
		
	}

	chipClickCallback ( chipVal ) {
		
		if ( this.state.chipVal !== chipVal ) {
			
			if ( "전체" === chipVal ) {
				this.setState({
					chipVal	: "",
				});
			} else {
				this.setState({
					chipVal	: chipVal,
				});
			}
			
		}
		
	}

	iTubePlayerClickCallback ( item ) {
		
		// 비디오 클릭 이벤트
		Event(	'iTube','iTube Video Detail Clicked', '[iTube Video] ' + item.title	);
		
		//console.log( item );
		//console.log( item.type );
		//console.log( item.videoId );
		//console.log( item.title );
		//console.log( item.description );
		
		if (
			true	=== isMobileOnly	||
			true	===	isTablet		||
			true	===	isIPad13
		) {
			this.props.history.push( 
				"/m/iTube/Player?videoId=" + item.videoId + "&type=" + item.type + "&title=" + item.title + "&description=" + item.description
			);
		} else {
			this.props.history.push( 
				"/iTube/Player?videoId=" + item.videoId + "&type=" + item.type + "&title=" + item.title + "&description=" + item.description
			);
		}
		
	}
	
	setQuery ( param ) {
		
		let query	= "";
		//let tempQueryType	= 1;
		
		if ( 1 === param ) {
			query	= "1개월 2개월 3개월";
			//tempQueryType	= 1;
		} else if ( 2 === param ) {
			query	= "4개월 5개월 6개월";
			//tempQueryType	= [1,2];
		} else if ( 3 === param ) {
			query	= "7개월 8개월 9개월";
			//tempQueryType	= [2,3];
		} else if ( 4 === param ) {
			query	= "10개월 11개월 12개월";
			//tempQueryType	= [3,4];
		} else if ( 5 === param ) {
			query	= "13개월 14개월 15개월 16개월 17개월 18개월";
			//tempQueryType	= [4,5];
		} else if ( 6 === param ) {
			query	= "19개월 20개월 21개월 22개월 23개월 24개월";
			//tempQueryType	= [5,6];
		} //else if ( 7 === param ) {
		//	query	= "25개월 26개월 27개월 28개월 29개월 30개월 31개월 32개월 33개월 34개월 35개월 36개월";
		//} 
		else {
			query	= "";
			//tempQueryType	= [6,7];
		}
		
		localStorage.setItem(
			'itubeQuery', 
			JSON.stringify( query )
		);
		
		this.setState({
			query		: query,
		});
	}

	iTubeClose ( ) {
		
		if (
			true	=== isMobileOnly	||
			true	===	isTablet		||
			true	===	isIPad13
		) {
			this.props.history.goBack();
		} else {
			this.props.history.replace("/");
		}
		
	}
	
	createErrorMsg ( errMsg, errMsgType ) {
		this.setState({
			errMsg: errMsg,
			errMsgType:errMsgType,
		});
	}
	
	closeAlert ( ) {
		this.setState({
			errMsg	:""
		});
	}
	
	
	
    render ( ) {
		
		const { isLoading, contents, query } = this.state;
		
		
		return (
			<Fragment>
				<CustomAlert
					open={
						undefined	!==	this.state.errMsg		&&
						null		!==	this.state.errMsg		&&
						0			<	this.state.errMsg.length
					}
					data={this.state.errMsg} 
					option={this.state.errMsgType}
					closeAlert={this.closeAlert} 
				/>
				
				<Slide 
					direction={
						( isMobileOnly ) ?
							"left" : "right"
					}
					
					in={true}
					
					//style={{ transitionDelay: '100ms' }}
				>
					<Gesture
						onSwipeRight={
							( gestureStatus ) => {
								if ( 
									true === isMobileOnly 
								) {
									//if ( 1 === this.props.currentPage.depth ) {
									//	this.props.history.goBack();
									//}
									
									this.props.history.goBack();
								}
							}
						}
						
						onSwipeLeft={
							( gestureStatus ) => {
								if ( 
									true !== isMobileOnly 
								) {
									//if ( 1 === this.props.currentPage.depth ) {
									//	this.props.history.goBack();
									//}
									this.props.history.goBack();
								}
							}
						}
						
						direction="horizontal"
					>
						<Container
							fixed={true}
							//maxWidth="xs"
							maxWidth="sm"
							
							style={
								(
									true	=== isMobileOnly	||
									true	===	isTablet		||
									true	===	isIPad13
								) ?
									(
										{
											//height:"calc( 100vh - 3.25rem )",
											height:"calc( 100vh - 3rem )",
											margin:0,
											padding:0,
											
											
											display:"flex",
											flexDirection:"column",
											overflow:"hidden",
											//zIndex:2,
										}
									) :
									(
										{
											position:"fixed",
											top:"0",
											left:"3.75rem",
											height:"100vh",
											margin:0,
											padding:0,
											
											
											display:"flex",
											flexDirection:"column",
											overflow:"hidden",
											//zIndex:2,
										}
									)
							}
						>
							<IPickPageContainer
								//maxWidth={"xs"}
								maxWidth={"sm"}
								
								zIndex={1}
								type={
									"kinder"
									//this.state.type
								}
								id={
									undefined
									//this.state.id_
								}
								data={
									{
										//"birth"		: this.state.birth, 
										"contents"	: this.state.contents,
										"videoData"	: {
											"type"	: this.state.query,
											"data"	: this.state.item,
										},
									}
								}
								
								pageTitleOnly={true}	// true인 경우 buttonList 는 출력하지 않음...
								pageTitle={
									( "" === this.state.query ) ?
										"육아 영상" : (
											this.state.query.split( ' ' )[0] + 
											"~" + 
											this.state.query.split( ' ' )[this.state.query.split( ' ' ).length-1] + ' 영상'
										)
								}
								others={undefined}
								
								closeButtonShowYN={
									(
										true	=== isMobileOnly	||
										true	=== isTablet		||
										true	===	isIPad13	
									) ?
										false : true
								}
								closePage={this.iTubeClose}
								createErrorMsg={this.createErrorMsg}
								
								buttonList={
									undefined
								}
								
								pageHeader={
									<ITubeMainHeader
										queryType={
											this.state.query
										}
										setQuery={this.setQuery}
										chipClickCallback={this.chipClickCallback}
									/>
								}
								pageBody={
									<ITubeMainDetail
										type={this.state.query}
										isLoading={isLoading}
										
										data={
											{
												//"birth"		: this.state.birth, 
												"contents"	: this.state.contents,
												"videoData"	: {
													"type"	: (  
														undefined	!==	this.state.item	&&
														null		!==	this.state.item	&&
														""			!==	this.state.item	&&
														""			!== this.state.item.videoId
													) ?
														this.state.item.videoId : "",
													"data"	: (
														undefined	!==	this.state.item	&&
														null		!==	this.state.item	&&
														""			!==	this.state.item	&&
														""			!== this.state.item.videoId
													) ?
														this.state.item : null,
												},
											}
										}
										
										//iTubePlayerOpenYN={this.state.iTubePlayerOpenYN}
										//toggleITubePlayerOpenYN={this.toggleITubePlayerOpenYN}
										
										iTubePlayerClickCallback={this.iTubePlayerClickCallback}
										iTubeClose={this.iTubeClose}
										createErrorMsg={this.createErrorMsg}
									/>
								}
								pageFooter={
									<ITubeMainFooter
									/>
								}
							/>
							
						</Container>
					</Gesture>
				</Slide>
			</Fragment>
        );
    }
}

//export default iTubeMain;
// props 로 넣어줄 스토어 상태값
const mapStateToProps = state => ({
	currentPage: state.currentPageSync.currentPage
});

// props 로 넣어줄 액션 생성함수
const mapDispatchToProps = dispatch => ({
	increasePageDepth	: ( ) => dispatch( increasePageDepth( ) ),
	decreasePageDepth	: ( ) => dispatch( decreasePageDepth( ) ),
	setPageDepth		: ( currentPage ) => dispatch( setPageDepth( currentPage ) ),
});

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( iTube );