import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';

import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';

import { 
	Chart as GoogleChart
} from 'react-google-charts';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme
} from '@material-ui/core/styles';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import {
	Typography,
	Grid,
	IconButton,
	Box,
	CircularProgress
} from '@material-ui/core';

import { 
	indigo,	// 500 : primary
	red,	// 500 : error
	green,	// 800 : secondary
	amber,	// 700 : warning
} from '@material-ui/core/colors';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';


const markerInfoTheme	= createMuiTheme({
	palette: {
		//primary		: indigo,
		//secondary	: green,
		//error		: red,
		//warning		: amber,
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		error		: {
			main:"#c62828",
		},
		warning		: {
			main:"#ffa000",
		},
	}
});

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	textCenter:{
		textAlign:"center"
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	
	//success: {
	//	backgroundColor: green[600],
	//	color:theme.palette.primary.contrastText
	//},
	//
	//successOpposition: {
	//	backgroundColor: theme.palette.primary.contrastText,
	//	color:green[600]
	//},
	//
	//primary:{
	//	backgroundColor: theme.palette.primary.main,
	//	color:theme.palette.primary.contrastText
	//},
	//
	//primaryOpposition:{
	//	backgroundColor: theme.palette.primary.contrastText,
	//	color:theme.palette.primary.main
	//},
	
	fontWeightBold:{
		fontWeight:700
	},
	
	fontWeightLight:{
		fontWeight:300
	},
	
	textSmall:{
		fontSize:"80%",
	}
	
}));


//const ulzipTheme	= createMuiTheme({
//	palette: {
//		primary: green,
//	},
//});


export default function Chart ( props ) {
	
	const classes	= useStyles();
	//const [barChartYn, changeBarChartYn]	= React.useState(true);
	
	//console.log( props );
	//console.log( props.data );
	
	let weighted_hegiht	= 1;
	
	
	if ( 
		//null		!== props.data	&& 
		//undefined	!== props.data	&& 
		true		=== Array.isArray( props.data ) 
	) {
		if ( true === isMobileOnly ) {
			if ( props.data.length < 9 ) {
				weighted_hegiht	= 1;
			} else if (
				props.data.length < 13
			) {
				weighted_hegiht	= 1.75;
			} else {
				weighted_hegiht	= 2.25;
			}	
		} else {
			if ( props.data.length < 9 ) {
				weighted_hegiht	= 1;
			} else if (
				props.data.length < 13
			) {
				weighted_hegiht	= 2.25;
			} else {
				weighted_hegiht	= 3.25;
			}	
		}
	}
	
	//let displayYN	= false;
	//
	//for ( let i = 1 ; i < props.data.length ; i++ ) {
	//	
	//	if (
	//		props.data[i][1] > 0 ||
	//		props.data[i][4] > 0
	//	) {
	//		displayYN = true;
	//		break;
	//	}
	//	
	//}
	
	return (
		
		<Fragment>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				
				className={
					clsx(
						classes.noGutters
					)
				}
				
				style={{
					overflowX:"hidden",
					overflowY:"hidden",
				}}
			>
				
				{
					( 
						""		!== props.chartTitle	//&&
						//null	!== props.data
					) ?
						(
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
							>
								<Typography
									className={
										clsx(
											classes.noPadding,
											//classes.marginY,
											classes.textCenter,
											//classes.fontWeightBold
										)
									}
									
									//variant="h6" 
									variant="subtitle1" 
								>
									{
										props.chartTitle
									}
								</Typography>
							</Grid>
						) : 
						(
							null
						)
				}
				
				
				{
					( null !== props.data ) ?
						(
							<Grid
								item
								container
								direction="row"
								justify="center"
								//alignItems="center"
								alignItems="stretch"
							>
								<Grid
									item
									xs={
										( true === props.changeBtn ) ?
											10 : 12
									}
									sm={
										( true === props.changeBtn ) ?
											10 : 12
									}
									md={
										( true === props.changeBtn ) ?
											10 : 12
									}
									lg={
										( true === props.changeBtn ) ?
											10 : 12
									}
									xl={
										( true === props.changeBtn ) ?
											10 : 12
									}
								>
									<GoogleChart
										chartType={props.chartType}
										width={'100%'}
										height={
											( isMobileOnly )?
												( 
													window.innerHeight * 0.5 * weighted_hegiht
													//250
												) : 
												(
													( isTablet )?
														( 
															window.innerHeight * 0.3 * weighted_hegiht
															//300
														) : 
														( 
															window.innerHeight * 0.35 * weighted_hegiht
															//3502
														)
												)
										} 
										data={props.data}
										options={
											
											( "BarChart" === props.chartType ) ?
												{
													chartArea: { 
														top:props.chartArea.top,
														left:props.chartArea.left,
														width:props.chartArea.width,  
														height: props.chartArea.height
													},
													isStacked: true,
													hAxis: {
														title: props.xLabel,
														minValue: 0
													},
													colors:props.colors,
													animation: {
														startup: true,
														easing: ( "BarChart" === props.chartType ) ? 'linear' : 'in',
														duration: 1000
													},
													bar: { 
														//groupWidth: '80%' 
														//groupWidth: '50%' 
														groupWidth:props.groupWidth
													}
												} :
												{
													chartArea: { 
														top:props.chartArea.top,
														left:props.chartArea.left,
														width:props.chartArea.width,  
														height: props.chartArea.height
													},
													animation: {
														startup: true,
														easing: ( "BarChart" === props.chartType ) ? 'linear' : 'in',
														duration: 1000
													},
												}
										}
									/>
								</Grid>
								{
									( true === props.changeBtn ) ?
										(
											<Grid
												item
												
												xs={2}
												sm={2}
												md={2}
												lg={2}
												xl={2}
												
												className={
													clsx(
														classes.textCenter
													)
												}
											>
											<ThemeProvider 
														theme={markerInfoTheme}
													>
												<Box 
													border={1}
													clone
												>
													
														<IconButton
															size="small"
															className={
																clsx(
																	classes.smallButton,
																)
															}
															
															color={
																( "ulzip" === props.dataType ) ?
																	"secondary" :
																	(
																		( "kinder" === props.dataType ) ?
																			"primary" : ""
																	)
															}
															
															onClick={
																(e) => {
																	
																	if ( "BarChart" === props.chartType ) {
																		props.changeChartType( "PieChart" );
																	} else {
																		props.changeChartType( "BarChart" );
																	}
																}
															}
														>
															<i
																className={
																	( "BarChart" === props.chartType ) ? 
																		(
																			'fas fa-chart-pie'
																		) : (
																			'fas fa-chart-bar'
																		)
																}
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center'
																}}
															/>
														</IconButton>
													
												</Box>
												</ThemeProvider>
											</Grid>
										) :
										null
								}
								
							</Grid>
						) : 
						(
							"정보가 존재하지 않습니다."
						)
				}
				
			</Grid>
		</Fragment>
			
	);
	
}