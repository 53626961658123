import 
	React, 
	{ 
		Fragment
	} 
from 'react';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

import UserReviewFormCreator	from './UserReviewFormCreator.js';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Dialog,
	Box,
	Grid,
	Button,
	Typography,
	Card,
	CardContent
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon		from '@material-ui/core/Icon';

//import {
//	//Close		as CloseIcon,
//	//ChevronLeft		as ChevronLeftIcon,
//	//ChevronRight	as ChevronRightIcon,
//	//Visibility,
//	//VisibilityOff,
//	//UnfoldLess	as UnfoldLessIcon,
//	//ExpandMore	as ExpandMoreIcon,
//	//Favorite		as FavoriteIcon,
//	//FavoriteBorder	as FavoriteBorderIcon,
//	//ArrowBackIos	as ArrowBackIosIcon
//} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRightSmall:{
		marginRight:theme.spacing(0.5),
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	
	dialogPaper: 
		( true === isMobileOnly ) ?
			{
				margin	: theme.spacing(0),
				minWidth: "90vw",
			} :
			{
				margin: theme.spacing(0),
			},
}));


const UserReviewSubmitCardContent	= withStyles({
	root	: {
		"&:last-child"	: {
			paddingBottom	: 0,
		}
	},
})(CardContent);


export default function UserReviewSubmit ( props ) {
	
	const classes	= useStyles();
	
	const [values, setValues] = React.useState({
		isOpen		: false,
	});

	const toggleIsOpen = ( ) => {
		setValues({ 
			...values, 
			isOpen: !values.isOpen 
		});
	};
	
	
	let tempStr	= "어린이집 ";
	
	if ( "ulzip" === props.type ) {
		tempStr	= "어린이집 ";
	} else if ( "kinder" === props.type ) {
		tempStr	= "유치원 ";
	} else {
		
	}
	
	
	return (
		<Fragment>
			{
				( 
					false	===	props.ratingThisYN
					//true
				) ?
					(
						<ThemeProvider
							theme={markerInfoTheme}
						>
							<Box
								border={2}
								borderRadius={16}
								borderColor={
									( "ulzip" === props.type ) ?
										"secondary.main" :
										(
											( "kinder" === props.type ) ?
												"primary.main" : ""
										)
								}
								
								clone={true}
							>
								<Card
									className={
										clsx(
											//classes.noMargin,
											classes.margin,
											classes.padding,
										)
									}
								>
									<UserReviewSubmitCardContent
										//CardContent
										className={
											clsx(
												//classes.noMargin,
												//classes.padding,
												classes.noGutters,
											)
										}
									>
										<Grid
											container
											direction="row"
											justify="center"
											alignItems="center"
											
											className={
												clsx(
													//classes.paddingY,
													//classes.paddingX,
												)
											}
										>
											<Grid
												item
												
												xs={
													( false === props.userInfo.loginYN ) ?
														9 : 8
												}
												sm={
													( false === props.userInfo.loginYN ) ?
														9 : 8
												}
												md={
													( false === props.userInfo.loginYN ) ?
														9 : 8
												}
												lg={
													( false === props.userInfo.loginYN ) ?
														9 : 8
												}
												xl={
													( false === props.userInfo.loginYN ) ?
														9 : 8
												}
											>
												{
													( false === props.userInfo.loginYN ) ?
														(
															<Typography
																variant="body2"
																color="inherit"
																component="p"
															>
																{
																	"로그인을 하시고 " + 
																	tempStr + //"어린이집 "
																	//"'등원후기' 또는 '상담후기'를 작성하시면 더 많은 후기 및 맘카페글을 보실 수 있어요."
																	"'후기'를 작성하시거나 '아이픽'을 공유해주시면 더 많은 후기 및 맘카페글을 보실 수 있어요."
																}
															</Typography>
														) :
														(
															//( false === props.ratingThisYN ) ?
															( false === props.ratingYN ) ?
																(
																	<Typography
																		variant="body2"
																		color="inherit"
																		component="p"
																	>
																		{
																			//"어린이집 "
																			tempStr +
																			"'등원후기' 또는 '상담후기'를 작성하시면 더 많은 후기 및 맘카페글을 보실 수 있어요."
																		}
																	</Typography>
																) : 
																(
																	<Typography
																		variant="body2"
																		color="inherit"
																		component="p"
																	>
																		{
																			//"아이가 " + props.title + "을 다니고 계신가요?"
																			"'"+ props.title + "'에 대해 후기를 남겨 주세요."
																		}
																	</Typography>
																)
														)
												}
											</Grid>
											<Grid
												item
												
												xs={
													( false === props.userInfo.loginYN ) ?
														3 : 4
												}
												sm={
													( false === props.userInfo.loginYN ) ?
														3 : 4
												}
												md={
													( false === props.userInfo.loginYN ) ?
														3 : 4
												}
												lg={
													( false === props.userInfo.loginYN ) ?
														3 : 4
												}
												xl={
													( false === props.userInfo.loginYN ) ?
														3 : 4
												}
												
												className={
													( false === props.userInfo.loginYN ) ?
														(
															clsx(
																classes.textCenter,
																classes.paddingLeft,
															)
														) :
														(
															clsx(
																classes.textCenter,
															)
														)
												}
											>
												<ThemeProvider
													theme={markerInfoTheme}
												>
													<Box
														color={
															( "ulzip" === props.type ) ?
																"secondary.main" :
																(
																	( "kinder" === props.type ) ?
																		"primary.main" : ""
																)
														}
														//clone={true}
													>
														<Button
															variant={"outlined"}
															color={
																"inherit"
															}
															
															className={
																
																( true === props.userInfo.loginYN ) ?
																	(
																		clsx(
																			classes.button,
																			classes.fontWeightBold,
																		)
																	) :
																	(
																		( false === props.userInfo.loginYN ) ?
																			clsx(
																				classes.smallButton,
																				classes.fontWeightBold,
																			) :
																			clsx(
																				classes.smallButton,
																				//classes.fontWeightBold,
																			)
																	)
															}
															
															onClick={
																(e) => {
																	if ( false === props.userInfo.loginYN ) {
																		
																		//props.createErrorMsg(
																		//	"로그인 후 후기를 작성하실 수 있습니다.",
																		//	"warning"
																		//);
																		props.toggleLoginPanelOpen();
																		
																	} else {
																		
																		// 후기 작성 팝업 오픈!!!
																		props.toggleUserReviewSubmitOpen();
																		
																	}
																}
															}
														>
															{
																( false === props.userInfo.loginYN ) ?
																	"로 그 인" :
																	(
																		<Fragment>
																			<Icon
																				fontSize="default"
																				
																				className={
																					clsx(
																						"fas fa-edit",
																						classes.marginRightSmall,
																					)
																				}
																			/>
																			{
																				"후기 작성"
																			}
																		</Fragment>
																	)
															}
														</Button>
													</Box>
												</ThemeProvider>
											</Grid>
										</Grid>
									</UserReviewSubmitCardContent>
								</Card>
							</Box>
						</ThemeProvider>
					) : null
			}
			
			<Dialog
				open={props.isUserReviewSubmitOpen}
				fullWidth={true}
				maxWidth="sm"
				scroll="paper"
				
				PaperProps={
					{ 
						classes	: {
							root: classes.dialogPaper 
						} 
					}
				}
				
				className={
					clsx(
						classes.noMargin,
						classes.padding,
					)
				}
				
				onClose={props.toggleUserReviewSubmitOpen}
			>
				<UserReviewFormCreator
					isTab={true}
					
					type={props.type}	// ulzip, kinder
					userInfo={props.userInfo}
					
					ratingTypes={
						[
							{
								type	: "rating",
								name	: "등원 후기"
							}, 
							{
								type	: "sharing",
								name	: "상담 후기"
							}
						]
					}
					activeFunctions={
						[
							props.submitUserRatingInfo,
							props.submitUserSharingInfo
						]
					}
					modeList={
						[
							"submit", "submit"
							// modify
						]
					}
					closeDialog={props.toggleUserReviewSubmitOpen}
				/>
			</Dialog>
			
		</Fragment>
	);
	
}