import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';

import {
	login,
	loginSuccess,
	loginFailure,
	logout,
	logoutSuccess,
	logoutFailure
} from '../../store/modules/userAuthentication';

//import clsx		from "clsx";
import axios from 'axios';

import {
	Event,
	//PageView, 
	//initGA,
} from '../Utility/Tracking.js';

//import { isMobile, isMobileOnly, isTablet, isIPad13 } from 'react-device-detect';

//import {
//	Container,
//	Typography,
//	Grid,
//	Button,
//	IconButton,
//} from '@material-ui/core';

//import { 
//	red 
//} from '@material-ui/core/colors';

//import Icon		from '@material-ui/core/Icon';
//import {
//	Close		as CloseIcon,
//	ChevronLeft	as ChevronLeftIcon,
//	Visibility,
//	VisibilityOff,
//	UnfoldLess	as UnfoldLessIcon,
//	ExpandMore	as ExpandMoreIcon,
//	Favorite		as FavoriteIcon,
//	FavoriteBorder	as FavoriteBorderIcon,
//} from '@material-ui/icons';

import TimeAgo			from 'react-timeago'
import koreanStrings	from 'react-timeago/lib/language-strings/ko'
import buildFormatter	from 'react-timeago/lib/formatters/buildFormatter'

import KakaoLink		from '../Utility/SNS/KakaoLink.js';
import FacebookShare	from '../Utility/SNS/FacebookShare.js';

import UserReviewDetail		from './UserReviewDetail.js';

import { KaKaoTalkButton, NaverBlogButton } from 'react-social-kr';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const formatter = buildFormatter(koreanStrings);

const MAX_STAR_COUNT	= 5;

class UserReview extends Component {
	
	constructor( props ) {
		
		super( props );
		
		this.state = {
			
			userInfo:{
				loginYN:this.props.userInfo.loginYN,
				userId:this.props.userInfo.userId,
				userEmail:this.props.userInfo.userEmail,
				userNickname:this.props.userInfo.userNickname,
				friendShareLink:this.props.userInfo.friendShareLink,
				nicknameConfirmYN:this.props.userInfo.nicknameConfirmYN,
			},
			
			ratingYN: false, // 현재 얼집이 아니더라도, 한 개 이상의 얼집을 평가한 경우
			ratingThisYN: false, // 현재 유저가 선택한 이 얼집을 평가했는지 여부
			
			ratingCount: 0,
			ratingAvg: 0,
			ratingReviews: [],
			
			community_info: null,
			
			showFriendShareView : false,
			
		};
		
		
		
		this.getSchoolRatingInfo	= this.getSchoolRatingInfo.bind(this);
		this.getUserRatingInfo		= this.getUserRatingInfo.bind(this);
		
		this.filterNaverCafeSearchResult	= this.filterNaverCafeSearchResult.bind(this);
		
		this.submitUserRatingInfo	= this.submitUserRatingInfo.bind(this);
		this.submitUserSharingInfo	= this.submitUserSharingInfo.bind(this);
		
		this.modifyUserRatingInfo	= this.modifyUserRatingInfo.bind(this);
		this.removeUserRatingInfo	= this.removeUserRatingInfo.bind(this);
		
		this.toggleReviewView		= this.toggleReviewView.bind(this);
		this.toggleFriendShareView	= this.toggleFriendShareView.bind(this);
		
	}

	
	componentDidMount ( ) {
		
		this.getUserRatingInfo();
		this.getSchoolRatingInfo();
		
	}

	componentDidUpdate( prevProps, prevState ) {
		
		if ( prevState.userInfo.loginYN !== this.state.userInfo.loginYN ) {
			this.getUserRatingInfo();
		}
		
	}

	static getDerivedStateFromProps( nextProps, prevState ) {
		
		if ( 
			prevState.userInfo.loginYN				!== nextProps.userInfo.loginYN		||
			prevState.userInfo.userId				!==	nextProps.userInfo.userId		||
			prevState.userInfo.userEmail			!== nextProps.userInfo.userEmail	||
			prevState.userInfo.userNickname			!== nextProps.userInfo.userNickname	||
			prevState.userInfo.nicknameConfirmYN	!== nextProps.userInfo.nicknameConfirmYN
		) {
			return { 
				userInfo: {
					//...userInfo,
					loginYN	: nextProps.userInfo.loginYN,
					userId	: nextProps.userInfo.userId,
					userEmail: nextProps.userInfo.userEmail,
					userNickname: nextProps.userInfo.userNickname,
					friendShareLink: nextProps.userInfo.friendShareLink,
					nicknameConfirmYN : nextProps.userInfo.nicknameConfirmYN,
				}
			};
		}
		
		return null;
	}

	async getSchoolRatingInfo ( ) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		let schoolRatingInfoResult	= null;
		let naverCafeResult	= null;
		
		//console.log( this.props.type );
		//console.log( this.props.id );
		
		try {
			const school_rating_info = await axios.post(
				'/user/getSchoolRating', 
				{
					type: this.props.type,
					code: this.props.id,
					name: this.props.name,
					address_depth_1: this.props.address1,
					address_depth_2: this.props.address2
				}
			);
			
			//console.log( school_rating_info.data );
			//console.log( school_rating_info.data.cafe_search );
			
			if ( 'success' === school_rating_info.data.result ) {
				schoolRatingInfoResult	= school_rating_info.data.school_rating;
				naverCafeResult			= school_rating_info.data.cafe_search;
				errMsgType	= "success";
			} else {
				errMsg		= school_rating_info.data.msg;
				errMsgType	= "warning";
			}
			
		} catch ( err ) {
			errMsg		= '어린이집 사용자 평가정보 로드 중에 오류가 발생했습니다.';
			errMsgType	= "error";
			console.log( err );
		} finally {
			if ( "success" !== errMsgType ) {
				
				if ( "" !== errMsg ) {
					this.props.createErrorMsg(
						errMsg,
						errMsgType
					);
				}
				
			} else {
				
				//console.log(naverCafeResult.cafeFilter);
				
				if (
					undefined	!==	naverCafeResult.cafeFilter	&&
					null		!==	naverCafeResult.cafeFilter	&&
					0			<	Object.keys( naverCafeResult.cafeFilter ).length
				) {
					this.filterNaverCafeSearchResult( naverCafeResult.cafeFilter );
				}
				
				this.setState({
					ratingCount	: schoolRatingInfoResult.count,
					ratingAvg		: schoolRatingInfoResult.star_avg,
					ratingReviews	: schoolRatingInfoResult.content,
				});
				
			}
		}
	}
	
	async getUserRatingInfo ( ) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		//let ratingYN	= this.state.ratingYN;
		let ratingYN	= false;
		//let ratingThisYN	= this.state.ratingThisYN;
		let ratingThisYN	= false;
		
		if ( false === this.state.userInfo.loginYN ) {
			if ( false !== this.state.ratingYN ) {
				this.setState({
					ratingYN: false,
					ratingThisYN:false
				});
			}
			
			return;
		}
		
		try {
			const userRatingInfo = await axios.get(
				'/user/getMyRating'
			);
			
			//console.log( userRatingInfo.data );
			//console.log('friend_share_count : ', userRatingInfo.data.friend_share_count );
			
			if ( "success" === userRatingInfo.data.result ) {
				
				if( 
					undefined	!==	userRatingInfo.data.friend_share_count	&&
					null		!==	userRatingInfo.data.friend_share_count	&&
					true		===	Number.isInteger( userRatingInfo.data.friend_share_count )	&&
					0			<	userRatingInfo.data.friend_share_count
				) {
					ratingYN	= true;	
				} 
				
				if ( 
					undefined	!==	userRatingInfo.data.content	&&
					null		!==	userRatingInfo.data.content	&&
					0			<	userRatingInfo.data.content.length
				) {
					
					const contentResult = userRatingInfo.data.content.filter(
						( data ) => ( data.code === this.props.id )
					);
					
					if ( contentResult.length > 0 ) {
						ratingThisYN	= true;
						ratingYN		= true;
					} else {
						ratingYN		= true;
					}
					
				}
				
				errMsgType	= "success";
				
			} else {
				errMsg		= userRatingInfo.data.msg;
				errMsgType	= "warning";
			}
			
		} catch (err) {
			console.log(err);
			errMsg		= "getMyRating error..."
			errMsgType	= "error";
		} finally {
			//console.log( this.state.ratingYN );
			
			if ( "success" !== errMsgType ) {
				if ( "" !== errMsg ) {
					this.props.createErrorMsg(
						errMsg,
						errMsgType
					);
				}
			} else {
				
				if ( ratingYN !== this.state.ratingYN ) {
					if ( ratingThisYN !== this.state.ratingThisYN ) {
						this.setState({
							ratingYN		: ratingYN,
							ratingThisYN	: ratingThisYN,
						});
					} else {
						this.setState({
							ratingYN		: ratingYN,
						});
					}
				} else {
					if ( ratingThisYN !== this.state.ratingThisYN ) {
						this.setState({
							ratingThisYN	: ratingThisYN,
						});
					}
				}
				
				//this.setState({
				//	ratingThisYN	: ratingThisYN,
				//	ratingYN		: ratingYN,
				//});
			}
			
		}
	}

	filterNaverCafeSearchResult ( cafeList ) {
		
		//console.log( cafeList );
		
		try {
			let filtered_data = [];
			
			for ( let key in cafeList ) {
				for ( let i = 0 ; i < cafeList[key].length ; i++ ) {
					
					const regex = /<\/?\s*[a-z]+[^>]*>/g;
					const regex2 = /&[a-z]+;/g;
					
					cafeList[key][i].title = cafeList[key][
						i
					].title.replace(regex, '');
					cafeList[key][i].title = cafeList[key][
						i
					].title.replace(regex2, '');
					
					cafeList[key][i].description = cafeList[
						key
					][i].description.replace(regex, '');
					cafeList[key][i].description = cafeList[
						key
					][i].description.replace(regex2, '');
					
					//카페 추가
					cafeList[key][i].link = 'http://search.naver.com/search.naver?sm=top_hty&fbm=1&ie=utf8&query='+encodeURIComponent(this.props.name)+'&url='+encodeURIComponent(cafeList[key][i].link);
					//카페 추가
					
					let tmp_key = key;
					
					tmp_key = tmp_key.replace(regex, '');
					tmp_key = tmp_key.replace(regex2, '');
					
					cafeList[key][i].cafename = tmp_key;
					
					//console.log( key );
					//console.log( tmp_key );
					
					filtered_data.push(cafeList[key][i]);
				}
			}
			
			this.setState({
				community_info: filtered_data,
			});
			
		} catch ( err ) {
			console.log(err);
			
			this.setState({
				community_info: null,
			});
			
			throw new Error('filterNaverCafeSearchResult');
		}
		
	}
	
	async toggleFriendShareView ( bool ) {
		
		this.setState({
			showFriendShareView: bool
		});
		
		let friendShareResult	= null;
		let errMsg		= "";
		let errMsgType	= "warning";
		
		try {
			const friendShare	= await axios.get(
				window.location.protocol + 
				"//"	+
				window.location.hostname +
				'/'		+
				'friendShare?share=' +
				this.state.userInfo.friendShareLink+"&link=default"
			);
			
			
			Event("ACTION", "Toggle Friend Share View", "Friend Share");
			// console.log( friendShare.data );
			// 이건 무엇을 하는 함수인지? 
			
		} catch (error) {
			console.error(error);
			errMsg		= "friendShare error";
			errMsgType	= "error";
		} finally {
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			} else {
				
			}
		}
	}

	async submitUserRatingInfo ( param ) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		
		if (
			"rating"	!==	param.ratingType	//&&
			//"sharing"	!==	param.ratingType	
		) {
			errMsg		= "후기 유형이 잘못되었습니다.";
			errMsgType	= "warning";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		}
		
		
		let tempReviewContent	= param.reviewContent;
		
		tempReviewContent	= tempReviewContent.replace( /\s/g, "" ).trim();
		
		if( tempReviewContent.length < 15 ) {
			errMsg		= "공백을 제외하고 적어도 15자 이상 후기를 남기셔야 합니다.";
			errMsgType	= "warning";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		} 
		
		if ( 
			"rating"	===	param.ratingType	&&
			(
				false		===	Number.isInteger( param.starRate )	||
				0			>=	Number( param.starRate )
			)
		) {
			errMsg		= "적어도 1점 이상 등록하셔야 합니다.";
			errMsgType	= "warning";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		}
		
		
		try {
			const addRatingResult = await axios.post(
				'/user/addRating', 
				{
					type: this.props.type,
					code: this.props.id,
					name: this.props.name,
					address_depth_1: this.props.address1,
					address_depth_2: this.props.address2,
					rating_type: 'rating',
					star_rate: param.starRate,
					review_content: param.reviewContent,
				}
			);
			
			//console.log( addRatingResult.data );
			
			if ( 'success' === addRatingResult.data.result ) {
				errMsg		= addRatingResult.data.msg;
				errMsgType	= "success";
			} else {
				errMsg		= addRatingResult.data.msg;
				errMsgType	= "warning";
			}
		} catch (err) {
			console.log(err);
			errMsg		= '후기 등록 중에 오류가 발생했습니다.';
			errMsgType	= "error";
		} finally {
			
			this.props.createErrorMsg(
				errMsg,
				errMsgType
			);
			
			if ( "success" === errMsgType ) {
				
				Event("ACTION", "UserRating", "rating");
				
				this.getUserRatingInfo();
				this.getSchoolRatingInfo();
				
				//this.toggleWriteSharingView(false);
				return "success";
			} else {
				return "error";
			}
		}
	}
	
	async submitUserSharingInfo( param ) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		
		if (
			//"rating"	!==	param.ratingType	//&&
			"sharing"	!==	param.ratingType	
		) {
			errMsg		= "후기 유형이 잘못되었습니다.";
			errMsgType	= "warning";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		}
		
		
		let tempReviewContent	= param.reviewContent;
		
		tempReviewContent	= tempReviewContent.replace( /\s/g, "" ).trim();
		
		if( tempReviewContent.length < 15 ) {
			errMsg		= "공백을 제외하고 적어도 15자 이상 후기를 남기셔야 합니다.";
			errMsgType	= "warning";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		} 
		
		
		try {
			const addRatingResult = await axios.post(
				'/user/addRating', 
				{
					type: this.props.type,
					code: this.props.id,
					name: this.props.name,
					address_depth_1: this.props.address1,
					address_depth_2: this.props.address2,
					rating_type: 'sharing',
					star_rate: 0,
					review_content: param.reviewContent,
				}
			);
			
			//console.log( addRatingResult.data );
			
			if ( 'success' === addRatingResult.data.result ) {
				errMsg		= addRatingResult.data.msg;
				errMsgType	= "success";
			} else {
				errMsg		= addRatingResult.data.msg;
				errMsgType	= "warning";
			}
		} catch (err) {
			console.log(err);
			errMsg		= '사용자 후기 등록 중에 오류가 발생했습니다.';
			errMsgType	= "error";
		} finally {
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			
			if( 'success' === errMsgType ) {
				
				Event("ACTION", "UserRating", "sharing");
				
				this.getUserRatingInfo();
				this.getSchoolRatingInfo();
				
				//this.toggleWriteSharingView(false);
				//this.toggleRatingScoreEditable();
				
				return "success";
			} else {
				return "error";
			}
			
		}
	}
	
	async modifyUserRatingInfo (  
		param
	) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		
		if (
			"rating"	!==	param.ratingType	&&
			"sharing"	!==	param.ratingType	
		) {
			errMsg		= "후기 유형이 잘못되었습니다.";
			errMsgType	= "warning";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		}
		
		
		let tempReviewContent	= param.reviewContent;
		
		tempReviewContent	= tempReviewContent.replace( /\s/g, "" ).trim();
		
		if( tempReviewContent.length < 15 ) {
			errMsg		= "공백을 제외하고 적어도 15자 이상 후기를 남기셔야 합니다.";
			errMsgType	= "warning";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		} 
		
		if ( 
			"rating"	===	param.ratingType	&&
			(
				false		===	Number.isInteger( param.starRate )	||
				0			>=	Number( param.starRate )
			)
		) {
			errMsg		= "적어도 1점 이상 등록하셔야 합니다.";
			errMsgType	= "warning";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		}
		
		if (
			""			===	param.contentId	||
			null		===	param.contentId	||
			undefined	===	param.contentId//	||
		) {
			errMsg		= "리뷰 ID가 잘못되었습니다.";
			errMsgType	= "error";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		}
		
		try {
			
			const modifyUserRatingInfoResult	= await axios.post(
				"/user/modifyRating",
				{
					type: this.props.type,
					code: this.props.id,		// Marker(얼집, 유치원)의 id 
					_id		: param.contentId,	// 리뷰글의 id
					rating_type: param.ratingType,
					review_content	: param.reviewContent, 
					star_rate		: param.starRate,
					mode			: "modify"
				}
			);
			
			//console.log( modifyUserRatingInfoResult.data );
			if ( "success" === modifyUserRatingInfoResult.data.result ) {
				errMsgType	= "success";
				errMsg		= modifyUserRatingInfoResult.data.msg;
			} else {
				errMsgType	= modifyUserRatingInfoResult.data.result;
				errMsg		= modifyUserRatingInfoResult.data.msg;
				
				if( 
					"error"		!==	errMsgType	&&
					"warning"	!==	errMsgType
				) {
					errMsgType	= "error";
				}
			}
			
		} catch ( err ) {
			console.log(err);
			errMsg		= '평가정보 수정 중에 오류가 발생했습니다.';
			errMsgType	= "error";
		} finally {
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			if ( "success" === errMsgType ) {
				
				Event("ACTION", "UserRating", "modify");
				
				this.getUserRatingInfo();
				this.getSchoolRatingInfo();
				
				return "success";
			} else {
				return "error";
			}
			
		}
		
	}
	
	
	async removeUserRatingInfo ( param ) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		
		if (
			"rating"	!==	param.ratingType	&&
			"sharing"	!==	param.ratingType	
		) {
			errMsg		= "후기 유형이 잘못되었습니다.";
			errMsgType	= "warning";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		}
		
		
		if (
			""			===	param.contentId	||
			null		===	param.contentId	||
			undefined	===	param.contentId//	||
		) {
			errMsg		= "리뷰 ID가 잘못되었습니다.";
			errMsgType	= "error";
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			return "error";
		}
		
		//console.log( param );
		
		try {
			
			const removeUserRatingInfoResult	= await axios.post(
				"/user/removeRating",
				{
					type	: this.props.type,
					code	: this.props.id,	// Marker(얼집, 유치원)의 id 
					_id		: param.contentId,	// 리뷰글의 id
					rating_type: param.ratingType,
					//review_content	: param.reviewContent, 
					//star_rate		: param.starRate,
					mode			: "remove"
				}
			);
			
			//console.log( removeUserRatingInfoResult.data );
			
			if ( "success" === removeUserRatingInfoResult.data.result ) {
				errMsgType	= "success";
				errMsg		= removeUserRatingInfoResult.data.msg;
			} else {
				errMsgType	= removeUserRatingInfoResult.data.result;
				errMsg		= removeUserRatingInfoResult.data.msg;
				
				if( 
					"error"		!==	errMsgType	&&
					"warning"	!==	errMsgType
				) {
					errMsgType	= "error";
				}
			}
			
		} catch ( err ) {
			console.log(err);
			errMsg		= '평가정보 삭제 중에 오류가 발생했습니다.';
			errMsgType	= "error";
		} finally {
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			}
			
			if ( "success" === errMsgType ) {
				
				Event("ACTION", "UserRating", "remove");
				
				this.getUserRatingInfo();
				this.getSchoolRatingInfo();
				
				return "success";
			} else {
				return "error";
			}
			
		}
		
	}
	
	toggleReviewView ( tab ) {
		if ( this.state.activeTab !== tab ) {
			this.setState({
				activeTab: tab
			});
		}
	}
	
	render ( ) {
		
		// 로그인안한사람 : 평가 3개 , 리뷰세개
		// 로그인한사람 평가전 : 평가 3개, 리뷰3개
		// 로그인한사람 평가후 : 평가 3개, 리뷰 3개 / 더보기 누르면 10개씩 추가로 보여줌
		
		
		//<UserRating
		//	type={props.type}
		//	id={props.id}
		//	name={props.data.name}
		//	address1={props.data.addressDepth1}
		//	address2={props.data.addressDepth2}
		//	
		//	createErrorMsg={props.createErrorMsg}
		///>
		
		return (
			<Fragment>
				
				<UserReviewDetail
					userInfo={this.state.userInfo}
					ratingYN={this.state.ratingYN}
					ratingThisYN={this.state.ratingThisYN}
					
					ratingReviews={this.state.ratingReviews}
					communityInfo={this.state.community_info}
					
					zIndex={this.props.zIndex}
					
					getSchoolRatingInfo={this.getSchoolRatingInfo}
					getUserRatingInfo={this.getUserRatingInfo}
					createErrorMsg={this.props.createErrorMsg}
					
					title={this.props.name}
					
					
					type={this.props.type}
					ratingInfo={
						{
							"ratingMaxCount"	: MAX_STAR_COUNT,
							"ratingCount"		: this.state.ratingCount,
							"ratingValue"		: this.state.ratingAvg,
						}
					}
					//infoSentence1={"건의 후기"}	// rating 정보가 존재하는 경우의 문장
					//infoSentence2={"건의 후기가 있습니다"}	// rating 정보가 존재하지 않는 경우의 문장
					
					submitUserRatingInfo	= {this.submitUserRatingInfo}
					submitUserSharingInfo	= {this.submitUserSharingInfo}
					modifyUserRatingInfo	= {this.modifyUserRatingInfo}
					removeUserRatingInfo	= {this.removeUserRatingInfo}
				/>
				
			</Fragment>
		);
	}
}


//export default UserReview;

// props 로 넣어줄 스토어 상태값
const mapStateToProps = state => ({
	userInfo: state.userAuthentication.userInfo
});

// props 로 넣어줄 액션 생성함수
const mapDispatchToProps = dispatch => ({
	login			: ( ) => dispatch( login( ) ) ,
	loginSuccess	: userInfo => dispatch( loginSuccess( userInfo ) ) ,
	loginFailure	: ( ) => dispatch( loginFailure( ) ) ,
	logout			: ( ) => dispatch( logout( ) ) ,
	logoutSuccess	: ( ) => dispatch( logoutSuccess( ) ) ,
	logoutFailure	: ( ) => dispatch( logoutFailure( ) ) ,
});

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( UserReview );