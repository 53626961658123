import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';

//import clsx from 'clsx';
import axios from 'axios';

//import { 
//	//isMobile, isIOS, 
//	isMobileOnly, 
//	isTablet, 
//	//isAndroid, isBrowser 
//} from 'react-device-detect';

//import { 
//	ThemeProvider 
//} from '@material-ui/styles';

//import { 
//	//fade,
//	makeStyles,
//	withStyles,
//	//createMuiTheme,
//} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Fab,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
} from '@material-ui/icons';


import FavoriteItemDetail		from './FavoriteItemDetail.js';


class FavoriteItem extends Component {
	
	constructor( props ) {
		super( props );
		
		this.state	= {
			isOpen:false,
			community_info:null,
			//community_info_filtered:null
		};
		
		
		this.toggleFavoriteSchoolDetail	= this.toggleFavoriteSchoolDetail.bind(this);
		this.getSocialData				= this.getSocialData.bind(this);
	}
	
	
	componentDidMount() {
		
		//console.log( this.props.data );
		
		
		// 2019.12.13 즐겨찾기에서 카페검색결과 안보여주기로 결정...
		
		//this.getSocialData( 
		//	this.props.data.name,
		//	this.props.data.addressDepth1,
		//	this.props.data.addressDepth2
		//);
		
	}
	
	
	componentDidUpdate( ) {
		
		if( 
			this.props.data.id_ !== this.props.openingSchoolId	&&
			true === this.state.isOpen
		) {
		   this.setState({
			   isOpen:false
		   });
		}
		
	}
	
	
	toggleFavoriteSchoolDetail() {
		
		//console.log( this.props.data.id_ );
		
		if( false === this.state.isOpen ) {
			this.props.closeOtherFavoriteSchools( this.props.data.id_ );
		}
		
		this.setState({
			isOpen:!this.state.isOpen
		});
		
	}
	
	
	async getSocialData( school_name, address_depth_1, address_depth_2 ) {
		
		let community_info	= null;
		let errMsg		= "";
		let errMsgType	= "warning";
		
		try {
			
			const school_social_data	= await axios.get(
				'/api/getNaverCafeSearch?query=' +
					encodeURIComponent(school_name) +
					'&address_depth_1=' +
					encodeURIComponent(address_depth_1) +
					'&address_depth_2=' +
					encodeURIComponent(address_depth_2)
			);
			
			
			console.log( school_social_data.data );
			//console.log( school_social_data.data.cafeFilter );
			
			
			if( 
				null		!== school_social_data.data			&&
				null		!== school_social_data.data.items	&&
				undefined	!== school_social_data.data			&&
				undefined	!== school_social_data.data.items	&&
				0			<	school_social_data.data.items.length
			) {
				school_social_data.data.items = school_social_data.data.items.map(
					( data ) => {
						const regex = /<\/?\s*[a-z]+[^>]*>/g;
						const regex2 = /&[a-z]+;/g;
						
						let ret_obj	= {
							dataType:"",	
							
							title:"",
							avatar:undefined,
							header:"",
							description:"",
							
							source_name:"",
							source_url:"",
							source_detail_url:"",
							
							source_img:"",
							source_detail_img:"",
							
							nickname:"",
							user_id:"",
							update_date:"",
							myWritingYN:false,
							
							activeFunction:undefined,
						};
						
						data.title = data.title.replace(regex, '');
						data.title = data.title.replace(regex2, '');
						ret_obj.title	= data.title;
						
						data.description = data.description.replace(regex, '');
						data.description = data.description.replace(regex2, '');
						ret_obj.description	= data.description;
						
						data.cafename = data.cafename.replace(regex, '');
						data.cafename = data.cafename.replace(regex2, '');
						ret_obj.source_name	= data.cafename;
						
						ret_obj.source_url			= data.cafeurl;
						ret_obj.source_detail_url	= data.link;
						
						ret_obj.dataType	= this.props.data.type;
						
						return ret_obj;
					}
				);
			}
			
			community_info	= school_social_data.data.items;
			
		} catch( err ) {
			
			console.log( err );
			errMsg		= "소셜 데이터 로드에 실패하였습니다.";
			errMsgType	= "error";
			
		} finally {
			
			if ( "" !== errMsg ) {
				this.props.createErrorMsg(
					errMsg,
					errMsgType
				);
			} else {
				this.setState({
					community_info: community_info,
				});
				
				//console.log( community_info );
			}
			
		}
		
	}
	
	
	render ( ) {
		
		
		return (
			<Fragment>
				
				<FavoriteItemDetail
					isOpen={this.state.isOpen}
					data={this.props.data}
					communityInfo={this.state.community_info}
					
					toggleFavoriteSchoolDetail={this.toggleFavoriteSchoolDetail}
					
					removeFavorite={this.props.removeFavorite}
					openMarkerFromAutoSearchResult={this.props.openMarkerFromAutoSearchResult}
					moveCenterLoc={this.props.moveCenterLoc}
					closeOtherFavoriteSchools={this.props.closeOtherFavoriteSchools}
					openingSchoolId={this.props.openingSchoolId}
					createErrorMsg={this.props.createErrorMsg}
				/>
				
			</Fragment>
		);
		
	}
	
}


export default FavoriteItem;