import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';

import clsx from 'clsx';

import { 
	Link,
	//Redirect,
} from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	login,
	loginSuccess,
	loginFailure,
	logout,
	logoutSuccess,
	logoutFailure
} from '../store/modules/userAuthentication';

import {
	getUserFavorite,
	getUserFavoriteSuccess,
	getUserFavoriteFailure,
	addUserFavorite,
	addUserFavoriteSuccess,
	addUserFavoriteFailure,
	removeUserFavorite,
	removeUserFavoriteSuccess,
	removeUserFavoriteFailure,
} from '../store/modules/userFavoriteManager';

import {
	viewHome,
	viewCOMMUNITY,
	viewITUBE,
	viewPROFILE,
} from '../store/modules/currentPageSync';

import axios from 'axios';

import {
	//CssBaseline,
	
	CircularProgress,
	Container,
} from '@material-ui/core';

import { 
	isMobile, 
	isIOS, 
	isMobileOnly, 
	isTablet, 
	isAndroid, 
	isBrowser,
	isIPad13,
} from 'react-device-detect';

import DaumMap from 'daum-maps-react';

import queryString from 'query-string';
import logo from '../images/ipicklogo.png';


import MenuBar					from '../components/MenuBar/MenuBar.js';
import MenuBarMobile			from '../components/MenuBar/MenuBarMobile.js';
import SideBar					from '../components/MenuBar/SideBar.js';
import Suggestions 				from '../components/Suggestions';
import Notice 					from '../components/Notice';
import ControlMapPanel 			from '../components/ControlMapPanel';
import CustomAlert 				from '../components/Utility/Alert/CustomAlert';
import FilterPanel 				from '../components/FilterPanel';
//import IllegalInfoPanel			from '../components/IllegalInfo/IllegalInfoPanel.js'
import MarkerGpsDupList			from '../components/Marker/MarkerGpsDupList.js';
//import PlaceList				from '../components/Search/PlaceList.js';
//import AutoSearchKeyword		from '../components/Search/AutoSearchKeyword.js';
import UserFavorite				from '../components/Favorite/UserFavorite.js';
import ChangeNickNamePopUp		from '../components/User/ChangeNickNamePopUp';

import {
	getLoginInfo,
	getFavorite,
} from '../components/User/IPickUser.js';

import { 
	Event, 
	PageView, 
	initGA 
} from '../components/Utility/Tracking.js';

import "../css/ulzip_basic_style.css"

const today = new Date().toISOString().slice(0, 10);

String.prototype.replaceAll = function(org, dest) {
    return this.split(org).join(dest);
}



class IPickHome extends Component {
	
	constructor( props ) {
		
		super( props );
		
		
		let url = this.props.location.search;
		let params = queryString.parse(url);
		
		const my_url_str	= String(window.location).split("/");
		
		let temp_mobile_view	= false;
		let isRedirectYN	= false;
		
		if( 
			null		!== my_url_str	&&
			undefined	!== my_url_str	&&
			true		=== Array.isArray( my_url_str )
		) {
			for( let i = 0 ; i < my_url_str.length ; i++ ) {
				if( "m" === my_url_str[i] ) {
					temp_mobile_view	= true;
					break;
				}
			}
		}
		
		if (
			false	=== temp_mobile_view	&&
			(
				true	===	isMobileOnly	||
				true	===	isTablet	||
				true	===	isAndroid	||
				true	===	isIOS		||
				true	===	isIPad13
			)
		) {
			isRedirectYN	= true;
			this.props.history.replace("/m");
		} else {
			isRedirectYN	= false;
		}
		
		/* Component inner variables declaration */
		// these variables are coded regardless of rendering...
		this.daum = null;
		this.map = null;
		this.ps = null;
		this.geocoder = null;
		
		this.database = null;
		
		this.location_list = new Map(); // db로 조회한 지역들의 list
		// 어떤 지역 조회 후 다른 지역으로 넘어가고 다시 기존의 지역으로 화면이
		// 돌아왔을 때 다시 search를 하면, overhead 이므로 중복조회 방지하기 위함...
		
		this.illegal_ulzip_location_list	= new Map();
		
		this.ulzip_type_list = new Map([['ulzip_type_all', true]]);
		this.kinder_type_list = new Map([['kinder_type_all', true]]);
		
		this.to_be_ulzip_markers	= [];
		this.to_be_kinder_markers	= [];
		
		this.mapWrap	= React.createRef();
		
		this.mode = params.mode;
		
		/* Component state variables declaration */
		// Before declaring state variables, you need to consider whether it is necessory!!!
		// setState method calling causes re-rending...
		this.state = {
			
			isRedirectYN	: isRedirectYN,
			mode : params.mode,
            // isIllegalOpen : params.isIllegalOpen == "true" ? true : false,
			user_lat: 37.55336,
			user_lon: 126.956602,
			
			
			userInfo:{
				loginYN:false,
				userId:"",
				userEmail: '',
				userNickname: '',
				nicknameConfirmYN:true,
				friendShareLink:"",
			},
			favoriteList:[],
			
			current_location_name: '',
			current_address_depth_1:"",
			current_address_depth_2:"",
			
			
			markers: [],
			kinder_markers: [],
			
			dup_markers_list: [],
			clicked_markers: [],
			
			
			release_notice_yn : false,
			
			errorMsg: "",
			errorMsgType:"warning",
			
			place_search_value: '',
			place_list: [],
			pages: [],
			
			auto_search_exists_yn: true,
			open_marker_queue: [],
			
			isSuggestionsOpen:false,
			release_notice_yn : false,
			today_no_show : false,
			
			num_of_current_loc_illegal : 0,
			illegal_ulzip_list_all:[],
			
			illegalInfoOpen:false,
            // illegalInfoOpen:params.isIllegalOpen == "true" ? true : false,
			
			
			filter_panel_open: false,
			favorite_panel_open:false,
			
			markers_loading_ulzip:false,
			markers_loading_kinder:false,
			markers_loading_kids_cafe:false,
			
			
			type	: undefined,
			id_		: undefined,
			
			currentPage:this.props.currentPage.data,
			
		};
		
		this.getUserLoginInfo		= this.getUserLoginInfo.bind(this);
		this.getUserFavorite		= this.getUserFavorite.bind(this);
		
		this.customRender			= this.customRender.bind(this);
		this.getGeoLocSuccess		= this.getGeoLocSuccess.bind(this);
		this.getGeoLocFail			= this.getGeoLocFail.bind(this);
		this.moveToUserLocation		= this.moveToUserLocation.bind(this);
		this.moveCenterLoc			= this.moveCenterLoc.bind(this);
		this.minifyMap				= this.minifyMap.bind(this);
		this.magnifyMap				= this.magnifyMap.bind(this);
		this.searchAddrFromCoords	= this.searchAddrFromCoords.bind(this);
		this.displayCenterInfo		= this.displayCenterInfo.bind(this);
		this.updateCurrentLocationInfo	= this.updateCurrentLocationInfo.bind(this);
		this.hasLocationInList		= this.hasLocationInList.bind(this);
		
		this.createErrorMsg 		= this.createErrorMsg.bind(this);
		this.closeAlert				= this.closeAlert.bind(this);
		
		this.placeSearchKeywordChange	= this.placeSearchKeywordChange.bind(this);
		this.placeSearchKeywordSubmit	= this.placeSearchKeywordSubmit.bind(this);
		this.placesSearchCB			= this.placesSearchCB.bind(this);
		this.displayPlaces			= this.displayPlaces.bind(this);
		this.searchResultClose		= this.searchResultClose.bind(this);
		this.searchResultCloseExceptsForKeywords	= this.searchResultCloseExceptsForKeywords.bind(this);
		this.clearSearchKeyword		= this.clearSearchKeyword.bind(this);
		this.toggleAutoSearchExistsYN	= this.toggleAutoSearchExistsYN.bind(this);
		
		
		this.closeControlPanel		= this.closeControlPanel.bind(this);
		this.toggleSuggestions			= this.toggleSuggestions.bind(this);
		this.closeReleaseNoticeYN		= this.closeReleaseNoticeYN.bind(this);
		this.toggleTodayNoShow			= this.toggleTodayNoShow.bind(this);
		
		
		this.toggleIllegalInfo	= this.toggleIllegalInfo.bind(this);
		this.getIllegalUlzipInfoAll	= this.getIllegalUlzipInfoAll.bind(this);
		this.setNumOfCurLocIllegalUlzip	= this.setNumOfCurLocIllegalUlzip.bind(this);
		this.sidoConverter		= this.sidoConverter.bind(this);
		
		this.toggleFavoritePanel	= this.toggleFavoritePanel.bind(this);
		
		this.toggleFilterPanel		= this.toggleFilterPanel.bind(this);
		this.filteringMarkerByType	= this.filteringMarkerByType.bind(this);
		this.filteringMarkerByTypeBackground	= this.filteringMarkerByTypeBackground.bind(this);
		this.filteringKinderMarkerByType		= this.filteringKinderMarkerByType.bind(this);
		this.filteringKinderMarkerByTypeBackground = this.filteringKinderMarkerByTypeBackground.bind(
			this
		);
		
		this.getArcode				= this.getArcode.bind(this);
		this.findAddrCode			= this.findAddrCode.bind(this);
		this.getMarkers				= this.getMarkers.bind(this);
		this.getMarkerList			= this.getMarkerList.bind(this);
		this.getMarkerListFromLocal	= this.getMarkerListFromLocal.bind(this);
		
		this.getUlzipMarkerListFromServer	= this.getUlzipMarkerListFromServer.bind(this);
		this.getKinderMarkerListFromServer	= this.getKinderMarkerListFromServer.bind(this);
		
		
		this.processUndefinedMarkers		= this.processUndefinedMarkers.bind(this);
		this.moveKinderMarkerWhenDupGpsWithUlzip	= this.moveKinderMarkerWhenDupGpsWithUlzip.bind(this);
		this.displayMarkerList				= this.displayMarkerList.bind(this); 
		this.displayMarker					= this.displayMarker.bind(this);
		this.displayDupMarker				= this.displayDupMarker.bind(this);
		this.isProcessingGpsForMarkers		= this.isProcessingGpsForMarkers.bind(this);
		this.storeMarkerData				= this.storeMarkerData.bind(this);
		this.loadMarkerData					= this.loadMarkerData.bind(this);
		this.groupingDupGpsMarkerList		= this.groupingDupGpsMarkerList.bind(this);
		this.storeMarkerDataToClientLocalFromApi = this.storeMarkerDataToClientLocalFromApi.bind(this);
		
		this.toggleMarkerLoading	= this.toggleMarkerLoading.bind(this);
		
		this.addVisitedLocation		= this.addVisitedLocation.bind(this);
		this.removeVisitedLocation	= this.removeVisitedLocation.bind(this);
		
		this.closeSchoolInfoTab		= this.closeSchoolInfoTab.bind(this);
		this.closeDupMarkersPanel	= this.closeDupMarkersPanel.bind(this);
		this.clickInnerMarkerInDupMarkersList = this.clickInnerMarkerInDupMarkersList.bind(this);
		
		this.openMarkerFromAutoSearchResult = this.openMarkerFromAutoSearchResult.bind(this);
		this.openAutoKeywordUlzipMarker		= this.openAutoKeywordUlzipMarker.bind(this);
		this.openAutoKeywordKinderMarker	= this.openAutoKeywordKinderMarker.bind(this);
		this.openSchoolInfoTab				= this.openSchoolInfoTab.bind(this);
		this.openScheduledMarker			= this.openScheduledMarker.bind(this);
		this.dequeueMarkerAtOpenMarkerQueue = this.dequeueMarkerAtOpenMarkerQueue.bind(this);
		
		this.closeChangeNickNamePopUp	= this.closeChangeNickNamePopUp.bind(this);
		
	}

	
	
	async componentDidMount() {
		
		initGA('UA-137510932-1');
		PageView();
		
		localStorage.setItem('last_visit_date', today);
		
		const tmp_last_login = JSON.parse( localStorage.getItem( 'last_login_date' ) );
		
		if ( 
			null		=== tmp_last_login	|| 
			undefined	=== tmp_last_login 
		) {
			
			localStorage.setItem('last_login_date', JSON.stringify(today));
			
		} else {
			
			if ( today !== tmp_last_login ) {
				
				let localStorageKeys	= Object.keys( localStorage );
				
				for ( let i = 0 ; i < localStorageKeys.length ; i++ ) {
					
					if (
						"last_visit_date" 		!== localStorageKeys[ i ]		&&
						"last_login_date" 		!== localStorageKeys[ i ]		&&
						"last_geo_loc_info"		!== localStorageKeys[ i ]		&&
						"releaseNotice"			!== localStorageKeys[ i ]		&&
						"itubeQuery"			!== localStorageKeys[ i ]
					) {
						localStorage.removeItem( localStorageKeys[ i ] );
					}
					
				}
				
				//console.log('localStorage clear...');
				
			}
			
		}
		
		
		const release_notice_y	= localStorage.getItem( "releaseNotice" );
		
		//console.log( release_notice_y );
		
		if(
			null		=== release_notice_y	||
			undefined	=== release_notice_y
		) {
			this.setState({
				release_notice_yn : true
			});
		}
		
		
		this.getUserLoginInfo();
		this.getUserFavorite();
        
        // if(this.state.isIllegalOpen){
        //     this.toggleIllegalInfo(null);
        // }
		
	}
	
	
	componentDidUpdate( prevProps, prevState ) {
		
		const query	= queryString.parse( this.props.location.search );
		
		if ( prevState.userInfo.loginYN !== this.state.userInfo.loginYN ) {
			this.getUserFavorite();
		}
		
		//console.log( query.type );
		//console.log( query.id_ );
		
		//const curr_center	= this.map.getCenter();
		
		
		// 앞/뒤 가기 할 때 마다 클릭했던 마커 위치로 이동하게 하는 부분
		if (
			undefined	!==	query.type	&&
			undefined	!==	query.id_	&&
			undefined	!==	query.lat	&&
			undefined	!==	query.lon	&&
			0			<	Number( query.lat )	&&
			0			<	Number( query.lon )	&&
			(
				//curr_center.getLat()	!== query.lat	||
				//curr_center.getLng()	!== query.lon
				prevState.type	!== query.type	||
				prevState.id_	!== query.id_
			)
		) {
			//console.log( curr_center.getLat() );
			//console.log( curr_center.getLng() );
			
			this.setState({
				type	: query.type,
				id_		: query.id_
			});
			
		} else {
			if(
				undefined	===	query.type	&&
				undefined	===	query.id_	&&
				undefined	===	query.lat	&&
				undefined	===	query.lon	&&
				(
					undefined	!== this.state.type	&&
					undefined	!== this.state.id_
				)
			) {
				this.setState({
					type	: undefined,
					id_		: undefined
				});
			}
		}
		
		
		if( 
			//prevState.currentPage	!== this.state.currentPage	&&
			"PAGE_HOME"				=== prevState.currentPage	&&
			"PAGE_HOME"				!== this.state.currentPage
		) {
			this.closeControlPanel();
		}
        
	}
	
	
	componentWillUnmount() {
		
	}
	
	
	static getDerivedStateFromProps( nextProps, prevState ) {
		
		if ( 
			prevState.userInfo.loginYN				!== nextProps.userInfo.loginYN		||
			prevState.userInfo.userId				!==	nextProps.userInfo.userId		||
			prevState.userInfo.userEmail			!== nextProps.userInfo.userEmail	||
			prevState.userInfo.userNickname			!== nextProps.userInfo.userNickname	||
			prevState.userInfo.nicknameConfirmYN	!== nextProps.userInfo.nicknameConfirmYN
		) {
  			return { 
				userInfo: {
					//...userInfo,
					loginYN: nextProps.userInfo.loginYN,
					userId: nextProps.userInfo.userId,
					userEmail: nextProps.userInfo.userEmail,
					userNickname: nextProps.userInfo.userNickname,
					friendShareLink: nextProps.userInfo.friendShareLink,
					nicknameConfirmYN : nextProps.userInfo.nicknameConfirmYN,
				}
			};
		}
		
		if ( prevState.currentPage !== nextProps.currentPage.data ) {
			return {
				currentPage : nextProps.currentPage.data
			};
		}
		
		if ( prevState.favoriteList !== nextProps.favoriteData.favoriteList ) {
			//console.log( "hey!" );
			return {
				favoriteList : nextProps.favoriteData.favoriteList
			}
		}
		
		return null;
	}
	
	
	async customRender( element, daum ) {
		let map				= null;
		let user_position	= null;
		
		map = new daum.maps.Map(element, {
			center: new daum.maps.LatLng(37.55336, 126.956602),
			level: 3
		});
        
		map.setMinLevel(2); // 최고 확대수준 지정	2
		map.setMaxLevel(8); // 최저 확대수준 지정	6
        
		this.daum		= daum;
		this.map		= map;
		this.ps			= new daum.maps.services.Places();
		this.geocoder	= new daum.maps.services.Geocoder();
        
		// 중심 좌표나 확대 수준이 변경될 때마다, 즉 중심좌표의 주소가 변경될 때마다 랜더링하도록 이벤트 등록...
		( ( daum, map, searchAddrFromCoords, displayCenterInfo ) => {
			
			// 중심 좌표나 확대 수준이 변경됐을 때 지도 중심 좌표에 대한 주소 정보를 표시하도록 이벤트를 등록합니다
			//daum.maps.event.addListener(map, 'bounds_changed', () => { //230209, 230210 -> 'drag'가 아닌 위치이동까지 포함시키기 위함
            daum.maps.event.addListener(map, 'dragend', () => { //230209
				searchAddrFromCoords(map.getCenter(), displayCenterInfo);
			});
			
		} )( daum, map, this.searchAddrFromCoords, this.displayCenterInfo );
		
        
        //user_position = await new Promise((resolve, reject) => {
		//	
		//	const tmp_last_geo_loc_info = JSON.parse( localStorage.getItem( 'last_geo_loc_info' ) );
		//	
		//	if ( 
		//		null		!== tmp_last_geo_loc_info	&&
		//		undefined	!== tmp_last_geo_loc_info 
		//	) {
		//		
		//		//console.log( tmp_last_geo_loc_info );
		//		
		//		resolve( new daum.maps.LatLng( Number( tmp_last_geo_loc_info.lat ), Number( tmp_last_geo_loc_info.lon ) ) );
		//		
		//	} else {
		//		
		//		if ( navigator.geolocation ) {
		//			//console.log('[+] Loding');
		//			navigator.geolocation.getCurrentPosition(
		//				( position ) => {
		//					const 
		//						lat = position.coords.latitude, // 위도
		//						lon = position.coords.longitude; // 경도
		//					
		//					resolve(new daum.maps.LatLng(lat, lon));
		//				},
		//				( ) => {
		//					resolve(new daum.maps.LatLng(37.55336, 126.956602));
		//				}
		//			);
		//		} else {
		//			resolve(new daum.maps.LatLng(37.55336, 126.956602));
		//		}
		//		
		//	}
		//	
		//});
		
		//console.log( user_position );
		
		// 위 이벤트는 이벤트 등록이지 실제 수행은 하지 않으므로 최초에 아래처럼 콜을 해주어야 한다.
		this.searchAddrFromCoords(map.getCenter(), this.displayCenterInfo);
		
        this.moveToUserLocation(); 
	}

	getGeoLocSuccess(position) {
		const lat = position.coords.latitude, // 위도
			lon = position.coords.longitude; // 경도
		
		if ( null !== this.map ) {
			this.map.setCenter(new this.daum.maps.LatLng(lat, lon));
            
            
            // '23.02.11 부대표 이용준
            // 과도한 카카오 지도 api 호출 방지를 위해 지도 이동에 대한 api 호출 기준을 "bounds_changed" 에서 "dragend" 로 변경
            // 그러나 "dragend"로 변경하면 "드래그" 외의 위치이동에 대한 api 호출이 발생하지 않으므로 이에 대한 보완 필요에 따라
            // 바로 아래 코드를 삽입
		    this.searchAddrFromCoords(this.map.getCenter(), this.displayCenterInfo);
		}
	}

	getGeoLocFail(error) {
		console.log('[-] Cannot access location');
		console.log(error);
		
		this.createErrorMsg(
			'현재 위치로 이동하시려면 위치 정보 접근 권한을 허용해 주세요.',
			"warning"
		);
	}
	
	
	moveToUserLocation() {
		if (navigator.geolocation) {
			//console.log('[+] Loding');
			navigator.geolocation.getCurrentPosition(this.getGeoLocSuccess, this.getGeoLocFail);
            
            
            //// '23.02.11 부대표 이용준
            //// 과도한 카카오 지도 api 호출 방지를 위해 지도 이동에 대한 api 호출 기준을 "bounds_changed" 에서 "dragend" 로 변경
            //// 그러나 "dragend"로 변경하면 "드래그" 외의 위치이동에 대한 api 호출이 발생하지 않으므로 이에 대한 보완 필요에 따라
            //// 바로 아래 코드를 삽입
		    //this.searchAddrFromCoords(this.map.getCenter(), this.displayCenterInfo);
		}
	}
	
	
	async moveCenterLoc(road_address, old_address, lat, lon) {
		const ulzip_home_obj = this;

		const coords = await new Promise((resolve, reject) => {
			if (
				undefined === lat ||
				undefined === lon ||
				null === lat ||
				null === lon ||
				0 >= lat ||
				0 >= lon
			) {
				this.geocoder.addressSearch(
					(
						( 
							null		!== road_address && 
							undefined	!== road_address && 
							''			!== road_address 
						) ? 
							road_address : old_address
					),
					(result, status) => {
						if (
							ulzip_home_obj.daum.maps.services.Status.OK === status // 정상적으로 검색이 완료됐으면
						) {
							resolve(new ulzip_home_obj.daum.maps.LatLng(result[0].y, result[0].x));
						} else {
							reject();
						}
					}
				);
			} else {
				resolve(new ulzip_home_obj.daum.maps.LatLng(lat, lon));
			}
		});
		
		//const coords = new this.daum.maps.LatLng(lat, lon);
		//console.log(coords);
		
		// 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
		this.map.setLevel(2);
		this.map.setCenter(coords);
		
		this.searchResultClose();
        
        // '23.02.11 부대표 이용준
        // 과도한 카카오 지도 api 호출 방지를 위해 지도 이동에 대한 api 호출 기준을 "bounds_changed" 에서 "dragend" 로 변경
        // 그러나 "dragend"로 변경하면 "드래그" 외의 위치이동에 대한 api 호출이 발생하지 않으므로 이에 대한 보완 필요에 따라
        // 바로 아래 코드를 삽입
		this.searchAddrFromCoords(this.map.getCenter(), this.displayCenterInfo);
	}

	
	magnifyMap() {
		// 최소값 2
		if (this.map.getLevel() > 2) {
			this.map.setLevel(this.map.getLevel() - 1);
		} else {
			console.log('더이상 축소할 수 없습니다.');
		}
	}

	minifyMap() {
		// 최대값 5
		if (this.map.getLevel() < 5) {
			this.map.setLevel(this.map.getLevel() + 1);
		} else {
			console.log('더이상 확대할 수 없습니다.');
		}
	}
	

	// 현재 지도 중심좌표로 주소를 검색해서 지도 중심 상단에 표시합니다
	searchAddrFromCoords( coords, callback ) {
		
		
		const last_lat	= coords.getLat();
		const last_lng	= coords.getLng();
		
		
		localStorage.setItem(
			"last_geo_loc_info",
			JSON.stringify(
				{
					lat : last_lat,
					lon : last_lng
				}
			)
		);
		
		
		//console.log('searchAddrFromCoords 호출!!');
		// 좌표로 행정동 주소 정보를 요청합니다
		this.geocoder.coord2RegionCode(coords.getLng(), coords.getLat(), callback);
	}
	
	
	async getUserLoginInfo ( ) {
		
		let userInfoResult	= null;
		let errorMsg		= "";
		let errorMsgType	= "warning";
		
		
		try {
			this.props.actions.login( );
			
			const userInfo		= await getLoginInfo();
			
			//console.log( userInfo );
			
			if ( false === userInfo.result ) {
				
				//if (
				//	"로그인 전 입니다."	 === my_info.data.msg	&&
				//	"error"				=== my_info.data.result
				//) {
				//	msg		= "로그인 전 입니다.";
				//	result	= true;
				//} else {
				//	msg	= "로그인 정보 조회 중에 오류가 발생했습니다.";
				//	result	= false;
				//} 
				
				if ( "로그인 전 입니다." === userInfo.msg ) {
					errorMsg		= "";
					errorMsgType	= "warning";	
				} else {
					
					if ( "" === userInfo.msg ) {
						errorMsg	= "로그인 정보 조회 중에 오류가 발생했습니다.";
					} else {
						errorMsg	= userInfo.msg;
					}
					
					errorMsgType	= "error";	
				}
				
			} else {
				userInfoResult	= userInfo;
				errorMsg		= userInfo.msg;
				errorMsgType	= "success";
			}
			
		} catch ( err ) {
			
			console.log( err );
			errorMsg		= "로그인 정보 조회 중에 오류가 발생했습니다.";
			errorMsgType	= "error";
			
		} finally {
			
			if ( "success" === errorMsgType ) {
				
				this.props.actions.loginSuccess( 
					{
						loginYN: userInfoResult.loginYN,
						userId: userInfoResult.userId,
						userEmail: userInfoResult.userEmail,
						userNickname: userInfoResult.userNickname,
						friendShareLink: userInfoResult.friendShareLink,
						nicknameConfirmYN : userInfoResult.nicknameConfirmYN,
					}
				);
				
				if ( "" !== errorMsg ) {
					this.createErrorMsg(
						errorMsg,
						errorMsgType
					);
				}
				
			} else {
				
				this.props.actions.loginFailure( );
				
				this.createErrorMsg(
					errorMsg,
					errorMsgType
				);
				
			}
			
		}
		
	}
	
	
	async getUserFavorite ( ) {
		
		let favoriteList	= [];
		let errorMsg		= "";
		let errorMsgType	= "warning";
		
		
		if ( false === this.state.userInfo.loginYN ) {
			return;
		}
		
		
		try {
			
			this.props.actions.getUserFavorite();
			
			const getFavoriteResult	= await getFavorite();
			
			if ( null === getFavoriteResult.data ) {
				errorMsg		= getFavoriteResult.msg;
				errorMsgType	= "warning";
			} else {
				favoriteList	= getFavoriteResult.data
			}
			
			//console.log( getFavoriteResult.data );
			
		} catch ( err ) {
			
			console.log( err );
			errorMsg		= "즐겨찾기 중에 오류가 발생했습니다.";
			errorMsgType	= "error";
			
		} finally {
			
			if ( "" === errorMsg ) {
				this.props.actions.getUserFavoriteSuccess( favoriteList );
			} else {
				
				this.createErrorMsg(
					errorMsg,
					errorMsgType
				);
				
				this.props.actions.getUserFavoriteFailure();
				
			}
			
		}
		
	}
	
	
	// 지도 하단에 지도 중심좌표에 대한 주소정보를 표출하는 함수입니다
	async displayCenterInfo(result, status) {
		
		let result_place = null;
		let user_location = '';
		
		// 현재 중심좌표의 행정동 정보를 추출한다.
		if ( this.daum.maps.services.Status.OK === status ) {
			
			if ( null === result || undefined === result ) {
				this.map.setCenter(new this.daum.maps.LatLng(37.566470, 126.977963));
				//'37.566470'
				//'126.977963'
				return;
			}
			
			//console.log(result);
			
			for ( let i = 0 ; i < result.length ; i++ ) {
				// 행정동의 region_type 값은 'H' 이므로
				if ('H' === result[i].region_type) {
					user_location = result[i].address_name;
					result_place = result[i];
					break;
				}
			}
			
		} else {
			this.map.setCenter(new this.daum.maps.LatLng(37.566470, 126.977963));
			return;
		}
		
		// 중심좌표의 행정동 정보 추출이 정상적으로 완료되면
		// 마커 관련 로직을 시작한다.
		if ( 
			null		!== result_place	&& 
			undefined	!== result_place
		) {
			
			let search_keyword_address_depth_1 = String(result_place.region_1depth_name);
			let search_keyword_address_depth_2 = String(result_place.region_2depth_name);
			let search_keyword_address_depth_2_no_depth_2_addr = String(
				result_place.region_2depth_name
			).substr(0, String(result_place.region_2depth_name).indexOf(' '));
			
			let query_type = 0;
			
			if (
				'서울특별시' === result_place.region_1depth_name		||
				'부산광역시' === result_place.region_1depth_name		||
				'대구광역시' === result_place.region_1depth_name		||
				'인천광역시' === result_place.region_1depth_name		||
				'광주광역시' === result_place.region_1depth_name		||
				'대전광역시' === result_place.region_1depth_name		||
				'울산광역시' === result_place.region_1depth_name		||
				'세종특별자치시' === result_place.region_1depth_name	||
				'제주특별자치도' === result_place.region_1depth_name
			) {
				// address_depth_1, address_depth_2 조회
				query_type = 0;
			} else {
				// address_depth_1, address_depth_2 조회
				query_type = 2;
			}
			
			if ( '' === result_place.region_2depth_name ) {
				// '세종특별자치시'가 현재 query_type '1'에 해당...
				query_type = 1;
				search_keyword_address_depth_2 = 'EMPTY';
			}
			
			
			// 어린이집 마커 로드
			this.getMarkers( 
				"ulzip",
				query_type,
				search_keyword_address_depth_1,
				search_keyword_address_depth_2,
				search_keyword_address_depth_2_no_depth_2_addr
			);
			
			
			// 유치원 마커 로드
			this.getMarkers( 
				"kinder",
				query_type,
				search_keyword_address_depth_1,
				search_keyword_address_depth_2,
				search_keyword_address_depth_2_no_depth_2_addr
			);
			
			
			//// 위반 어린이집 전체조회모드인 경우
			//this.getIllegalUlzipInfoAll(
			//	this.sidoConverter(search_keyword_address_depth_1), 
			//	search_keyword_address_depth_2, 
			//	user_location
			//);
			
			
			// 키즈카페 마커 로드
			//this.getMarkers( 
			//	"kids_cafe",
			//	query_type,
			//	search_keyword_address_depth_1,
			//	search_keyword_address_depth_2,
			//	search_keyword_address_depth_2_no_depth_2_addr
			//);
			
			
			// 현재 주소정보 변경
			this.updateCurrentLocationInfo(
				result_place.address_name,
				search_keyword_address_depth_1,
				search_keyword_address_depth_2
			);
			
		} // End of
		
		
		this.filteringMarkerByTypeBackground();
		this.filteringKinderMarkerByTypeBackground();
		
		return;
	}
	
	
	updateCurrentLocationInfo (
		current_location_name,
		current_address_depth_1,
		current_address_depth_2
	) {
		if ( 
			this.state.current_location_name	!== current_location_name	||
			this.state.current_address_depth_1	!== current_address_depth_1	||
			this.state.current_address_depth_2	!== current_address_depth_2
		) {
            if (
                this.state.current_address_depth_1 !== current_address_depth_1
            ) {
                // 위반 어린이집 전체조회모드인 경우
                this.getIllegalUlzipInfoAll(
            	    this.sidoConverter(current_address_depth_1), 
            	    current_address_depth_2, 
            	    current_location_name
                 );
            }
			
            this.setState({
				current_location_name: current_location_name,
				current_address_depth_1:current_address_depth_1,
				current_address_depth_2:current_address_depth_2
			});
		}
	}
	
	
	hasLocationInList( 
		address_depth_1, 
		address_depth_2,
		address_depth_2_no_depth_2_addr
	) {
		if (
			null	=== this.location_list	|| 
			0		=== this.location_list.size
		) {
			return false;
		} else {
			if ( true === this.location_list.has(address_depth_1) ) {
				const arr = this.location_list.get(address_depth_1);
				for ( let i = 0 ; i < arr.length ; i++ ) {
					if (
						address_depth_2 === arr[i]	||
						address_depth_2_no_depth_2_addr	=== arr[i]
					) {
						return true;
					}
				}
				
				return false;
			} else {
				return false;
			}
		}
	}
	
	
	/**
	 *	에러 메시지 닫기 버튼 클릭 시 호출되는 메소드...
	 */
	closeAlert() {
		this.setState({
			errorMsg: ''
		});
	}

	/**
	 *	에러 메시지를 띠우는 메소드...
	 */
	createErrorMsg(
		errorMsg,
		errorMsgType
	) {
		this.setState({
			errorMsg: errorMsg,
			errorMsgType:errorMsgType,
		});
		
		//setTimeout(this.closeAlert, 3000);
	}
	
	
	/**
	 *	검색창에 검색어 타이핑 할 때 호출되는 메소드
	 */
	placeSearchKeywordChange(e) {
		
		this.setState({
			place_search_value: e.target.value
		});
		
		this.searchResultCloseExceptsForKeywords();
		
	}
	

	/**
	 *	검색창에 검색어 입력 후 엔터 또는 돋보기 버튼 클릭 시 호출
	 */
	placeSearchKeywordSubmit(e) {
		//console.log(this.state.place_search_value);
		
		e.preventDefault();
		
		let keyword = this.state.place_search_value;
		
		if ( !keyword.replace(/^\s+|\s+$/g, '') ) {
			this.createErrorMsg(
				'키워드를 입력해주세요! ^^',
				"warning"
			);
			return false;
		}
		
		if (
			3	>	this.state.place_search_value.length ||
			(
				false	===	this.state.auto_search_exists_yn		&&
				3		<=	this.state.place_search_value.length
			)
		) {
			// 장소검색 객체를 통해 키워드로 장소검색을 요청합니다
			this.ps.keywordSearch( keyword, this.placesSearchCB );
			this.closeControlPanel( "searchPanel" );
		}
	}
	
	
	// 장소검색이 완료됐을 때 호출되는 콜백함수 입니다
	placesSearchCB(data, status, pagination) {
		if ( this.daum.maps.services.Status.OK === status ) {
			this.displayPlaces( data, pagination );
			
			this.setState({
				place_search_value: ''
			});
		} else if (this.daum.maps.services.Status.ZERO_RESULT === status) {
			this.createErrorMsg(
				"검색 결과가 존재하지 않습니다.",
				"warning"
			);
		} else if (this.daum.maps.services.Status.ERROR === status) {
			this.createErrorMsg(
				"검색 중 오류가 발생했습니다.",
				"error"
			);
		} else {
			console.log('검색창 검색결과값 : ' + status);
		}
	}

	
	/**
	 *	검색창을 통한 장소검색 완료 후 결과 리스트를 출력할 때 호출
	 */
	displayPlaces( data, pagination ) {
		
		//console.log( pagination );
		//console.log( data );
		
		let list = [];
		let page_list = [];
		
		for ( let i = 0 ; i < data.length ; i++ ) {
			list.push({
				info: data[i]
			});
		}
		
		for ( let i = 1 ; i <= pagination.last ; i++ ) {
			let tmpClassName = '';
			let onClickMethod = null;
			
			if ( i === pagination.current ) {
				tmpClassName = 'on';
			} else {
				onClickMethod = ((pageNum, pagination) => {
					return () => {
						//console.log( "i'm here!!! " + pageNum );
						pagination.gotoPage(pageNum);
					};
				})(i, pagination);
			}
			
			page_list.push({
				page_num: i,
				className: tmpClassName,
				onClickMethod: onClickMethod
			});
		}
		
		this.setState({
			place_list: list,
			pages: page_list,
		});
		
	}
	
	
	/**
	 *	검색어를 통해 검색 후 출력한 장소 리스트를 닫기 위해 
	 *	호출하는 메소드
	 */
	searchResultClose ( ) {
		this.setState({
			place_list: [],
			pages: [],
			place_search_value: ''
		});
	}
	
	
	clearSearchKeyword ( ) {
		if ( this.state.place_search_value.length > 0 ) {
			this.setState({
				place_search_value: ''
			});
		}
	}
	
	
	searchResultCloseExceptsForKeywords ( ) {
		this.setState({
			place_list: [],
			pages: []
		});
	}
	
	
	toggleAutoSearchExistsYN ( existsYN ) {
		//console.log( "AutoSearchExistsYN : " + existsYN );
		
		this.setState({
			auto_search_exists_yn: existsYN
		});
		
		if ( true === existsYN ) {
			this.closeControlPanel( "searchPanel" );
		}
		
	}
	
	
	toggleSuggestions () {
		
		this.setState({
			isSuggestionsOpen:!this.state.isSuggestionsOpen
		});
		
	}
	
	
	closeReleaseNoticeYN( ) {
		this.setState({
			release_notice_yn : false
		});
	}
	
	
	toggleTodayNoShow( e ) {
		
		this.setState({
			today_no_show:e.target.checked
		});
		
		if( true === e.target.checked ) {
			
			localStorage.setItem(
				"releaseNotice",
				JSON.stringify(
					{
						release_notice : true
					}
				)
			);
			
			//console.log( "true" );
			
		} else {
			
			localStorage.removeItem(
				"releaseNotice"
			);
			
			//console.log( "false" );
			
		}
		
		//console.log( localStorage.getItem( "releaseNotice" ) );
		
	}
	
	
	toggleIllegalInfo( e ) {
        console.log('toggleIllegalInfo');
		
		Event("ILLEGAL", "Illegal View Clicked", "[Illgl] Clicked");
		
		const cur_illegalInfoOpen	= this.state.illegalInfoOpen;
		
		this.setState({
			illegalInfoOpen : !this.state.illegalInfoOpen,
            isIllegalOpen : false
		});
		
		if( false === cur_illegalInfoOpen ) {
			this.closeControlPanel( "illegal_ulzip_panel_open" );
		}
		
	}
	
	
	closeControlPanel( panelName ) {
		
		if (
			true	=== isMobileOnly	||
			true	=== isTablet		||
			true	===	isIPad13
		) {
			if ( "filter_panel_open" === panelName ) {
				
				this.setState({
					dup_markers_list:[],
					favorite_panel_open: false,
					illegalInfoOpen:false,
					isSuggestionsOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
				
			} else if ( "favorite_panel_open" === panelName ) {
				this.setState({
					dup_markers_list:[],
					filter_panel_open: false,
					illegalInfoOpen:false,
					isSuggestionsOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
			} else if ( 
				"illegal_ulzip_panel_open" === panelName
			) {
				this.setState({
					dup_markers_list:[],
					filter_panel_open: false,
					favorite_panel_open:false,
					isSuggestionsOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
			} else if ( "dup_markers_list" === panelName ) {
				
				this.setState({
					illegalInfoOpen:false,
					filter_panel_open: false,
					favorite_panel_open:false,
					isSuggestionsOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
				
			} else if ( "suggestionBtn" === panelName ) {
				
				this.setState({
					dup_markers_list:[],
					filter_panel_open: false,
					favorite_panel_open: false,
					illegalInfoOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
				
			} else if ( "searchPanel" === panelName ) {
				
				this.setState({
					dup_markers_list:[],
					filter_panel_open: false,
					favorite_panel_open: false,
					illegalInfoOpen:false,
					isSuggestionsOpen:false
				});
				
			} else {
				this.setState({
					dup_markers_list:[],
					filter_panel_open: false,
					favorite_panel_open: false,
					illegalInfoOpen:false,
					isSuggestionsOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
			}
		} else {
			if ( "filter_panel_open" === panelName ) {
				
				this.setState({
					dup_markers_list:[],
					favorite_panel_open: false,
					//illegalInfoOpen:false,
					isSuggestionsOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
				
			} else if ( "favorite_panel_open" === panelName ) {
				this.setState({
					dup_markers_list:[],
					filter_panel_open: false,
					//illegalInfoOpen:false,
					isSuggestionsOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
			} else if ( 
				"illegal_ulzip_panel_open" === panelName
			) {
				this.setState({
					dup_markers_list:[],
					filter_panel_open: false,
					favorite_panel_open:false,
					isSuggestionsOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
			} else if ( "dup_markers_list" === panelName ) {
				
				this.setState({
					//illegalInfoOpen:false,
					filter_panel_open: false,
					favorite_panel_open:false,
					isSuggestionsOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
				
			} else if ( "suggestionBtn" === panelName ) {
				
				this.setState({
					dup_markers_list:[],
					filter_panel_open: false,
					favorite_panel_open: false,
					//illegalInfoOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
				
			} else if ( "searchPanel" === panelName ) {
				
				this.setState({
					dup_markers_list:[],
					filter_panel_open: false,
					favorite_panel_open: false,
					//illegalInfoOpen:false,
					isSuggestionsOpen:false
				});
				
			} else {
				this.setState({
					dup_markers_list:[],
					filter_panel_open: false,
					favorite_panel_open: false,
					//illegalInfoOpen:false,
					isSuggestionsOpen:false
				});
				
				this.searchResultClose();
				this.toggleAutoSearchExistsYN(false);
			}
		}
		
	}

	
	async getIllegalUlzipInfoAll ( address_depth_1, address_depth_2 ,user_location ) {
		
		if( 
			undefined	=== address_depth_1		||
			null		=== address_depth_1		||
			""			=== address_depth_1
		) {
			return;
		}
		
		// // 아래 절대 주석 노노!!!
		// if ( 
		// 	"TRUE"	=== this.illegal_ulzip_location_list.get( address_depth_1 ) 
		// ) {
		// 	return;
		// } else {
		// 	this.illegal_ulzip_location_list.set( address_depth_1, "TRUE" );
		// }
		
		//// 아래 절대 주석 노노!!! 20221214 ss 수정 시군구가 바뀔때마다 호출되도록 
		//if ( 
		//	"TRUE"	=== this.illegal_ulzip_location_list.get( address_depth_2 ) 
		//) {
		//	return;
		//} else {
		//	this.illegal_ulzip_location_list.set( address_depth_2, "TRUE" );
		//}
		
		let errorMsg 		= "";
		let errorMsgType	= "warning";
		let result = null;
		
		//api/getUlzipIllegal?sido=경기도
		
		try {
			
			const tmp_last_geo_loc_info = JSON.parse( localStorage.getItem( 'last_geo_loc_info' ) );
			
			const getIllegalUlzipListAll = await axios.get(
				'/api/getUlzipIllegal?sido='	+
				
				encodeURIComponent(address_depth_1)	+
				'&sigungu=' + 
				encodeURIComponent(address_depth_2) +//시군구는 실제로 안씀
				
				'&geo_lat=' +
				tmp_last_geo_loc_info.lat	+
				'&geo_lon=' +
				tmp_last_geo_loc_info.lon	+
				'&current_location_name='	+
				this.state.current_location_name +
				'&user_location_daum=' +
				encodeURIComponent(user_location)
				
			);
			
            //console.log("hihi!!");
			//console.log( getIllegalUlzipListAll.data );
			
			
			if (
				null		!== getIllegalUlzipListAll &&
				undefined	!== getIllegalUlzipListAll &&
				null		!== getIllegalUlzipListAll.data &&
				undefined	!== getIllegalUlzipListAll.data &&
				getIllegalUlzipListAll.data.length > 0
			) {
				result = getIllegalUlzipListAll.data;
			} else {
				
				result = [];
			}
			

		} catch (err) {
			console.log(err);
			errorMsg		= "어린이집 위반시설 전체조회 중 오류가 발생했습니다.";
			errorMsgType	= "error"
			result = null;
		} finally {
			
			if ( "" !== errorMsg ) {
				this.createErrorMsg(
					errorMsg,
					errorMsgType
				);
			}
			
			
			if ( 
				null !== result	
			) {
				
				//let tmp_illegal_ulzip_list_all = [];
				//
				//tmp_illegal_ulzip_list_all = tmp_illegal_ulzip_list_all.concat(
				//	this.state.illegal_ulzip_list_all
				//);
				
				this.setState({
					//illegal_ulzip_list_all: tmp_illegal_ulzip_list_all.concat( result )
					illegal_ulzip_list_all : result 
                });
				
				//this.illegal_ulzip_location_list.set( address_depth_1, "TRUE" );
				
			} //else {
			//	this.illegal_ulzip_location_list.delete( address_depth_1 );
			//}
			
			//console.log( this.state.illegal_ulzip_list_all );
		}
		
	}
	
	
	setNumOfCurLocIllegalUlzip ( num ) {
		
		if( num !== this.state.num_of_current_loc_illegal ) {
			this.setState({
				num_of_current_loc_illegal:num
			});
		}
		
	}
	
	
	sidoConverter( sido ){
		
		let result	= sido;
		
		if( '제주특별자치도' === sido  ) {
			result	= "제주도";
		}
		
		return result;
		
	}
	
	
	/**
	 *	유형 버튼 클릭 시 호출
	 */
	toggleFilterPanel(e) {
		
		const temp_filter_panel_open	= this.state.filter_panel_open;
		
		this.setState({
			filter_panel_open: !this.state.filter_panel_open
		});
		
		if ( false === temp_filter_panel_open ) {
			this.closeControlPanel('filter_panel_open');
		}
		
	}
	
	
	async toggleFavoritePanel ( e ) {
		
		if( true === this.state.favorite_panel_open ) {
			this.setState({
				favorite_panel_open: !this.state.favorite_panel_open
			});
			
			return;
		} else {
			
			if ( false === this.state.userInfo.loginYN ) {
				this.createErrorMsg(
					"로그인이 필요한 기능입니다.",
					"warning"
				);
			} else {
				
				this.setState({
					favorite_panel_open: !this.state.favorite_panel_open
				});
				
				this.closeControlPanel('favorite_panel_open');
			}
			
		}
		
	}
	
	
	/**
	 *	얼집 유형별로 필터링하는 메소드이다.
	 *	2018.12.21 기준으로 얼집유형은 전체를 제외한 총 5개이다.
	 *  ulzip_type_all		: 전체
	 *	ulzip_type_gov		: 국공립
	 *	ulzip_type_civil	: 민간
	 *	ulzip_type_home		: 가정
	 *	ulzip_type_cooper	: 협동
	 *	ulzip_type_etc		: 기타
	 *  
	 */
	filteringMarkerByType(ulzipType) {
		//console.log( ulzipType );
		
		if (ulzipType.hasOwnProperty('ulzip_type_all')) {
			if (true === ulzipType.ulzip_type_all) {
				this.ulzip_type_list.set('ulzip_type_all', true);
				this.ulzip_type_list.delete('ulzip_type_gov');
				this.ulzip_type_list.delete('ulzip_type_civil');
				this.ulzip_type_list.delete('ulzip_type_home');
				this.ulzip_type_list.delete('ulzip_type_cooper');
				this.ulzip_type_list.delete('ulzip_type_etc');
			} else {
				this.ulzip_type_list.delete('ulzip_type_all');
			}
		} else if (ulzipType.hasOwnProperty('ulzip_type_gov')) {
			if (true === ulzipType.ulzip_type_gov) {
				this.ulzip_type_list.set('ulzip_type_gov', true);
				this.ulzip_type_list.delete('ulzip_type_all');
			} else {
				this.ulzip_type_list.delete('ulzip_type_gov');
			}
		} else if (ulzipType.hasOwnProperty('ulzip_type_civil')) {
			if (true === ulzipType.ulzip_type_civil) {
				this.ulzip_type_list.set('ulzip_type_civil', true);
				this.ulzip_type_list.delete('ulzip_type_all');
			} else {
				this.ulzip_type_list.delete('ulzip_type_civil');
			}
		} else if (ulzipType.hasOwnProperty('ulzip_type_home')) {
			if (true === ulzipType.ulzip_type_home) {
				this.ulzip_type_list.set('ulzip_type_home', true);
				this.ulzip_type_list.delete('ulzip_type_all');
			} else {
				this.ulzip_type_list.delete('ulzip_type_home');
			}
		} else if (ulzipType.hasOwnProperty('ulzip_type_cooper')) {
			if (true === ulzipType.ulzip_type_cooper) {
				this.ulzip_type_list.set('ulzip_type_cooper', true);
				this.ulzip_type_list.delete('ulzip_type_all');
			} else {
				this.ulzip_type_list.delete('ulzip_type_cooper');
			}
		} else if (ulzipType.hasOwnProperty('ulzip_type_etc')) {
			if (true === ulzipType.ulzip_type_etc) {
				this.ulzip_type_list.set('ulzip_type_etc', true);
				this.ulzip_type_list.delete('ulzip_type_all');
			} else {
				this.ulzip_type_list.delete('ulzip_type_etc');
			}
		} else {
			if (true === ulzipType.ulzip_type) {
				this.ulzip_type_list.set('ulzip_type_all', true);
				this.ulzip_type_list.delete('ulzip_type_gov');
				this.ulzip_type_list.delete('ulzip_type_civil');
				this.ulzip_type_list.delete('ulzip_type_home');
				this.ulzip_type_list.delete('ulzip_type_cooper');
				this.ulzip_type_list.delete('ulzip_type_etc');
			} else {
				this.ulzip_type_list.delete('ulzip_type_all');
				this.ulzip_type_list.delete('ulzip_type_gov');
				this.ulzip_type_list.delete('ulzip_type_civil');
				this.ulzip_type_list.delete('ulzip_type_home');
				this.ulzip_type_list.delete('ulzip_type_cooper');
				this.ulzip_type_list.delete('ulzip_type_etc');
			}
		}

		//console.log(this.ulzip_type_list);

		// 필터 이벤트가 발생할 때마다 호출되는 부분!
		// 아래 같은 로직이 displayCenterInfo 콜백함수에서도 호출한다.
		// 아래 부분에서 해결하지 못한 부분을 displayCenterInfo 콜백에서
		// 화면이동마다 백그라운드로 실행...
		this.filteringMarkerByTypeBackground();
	}

	/**
	 *	실시간 필터링 정보 동기화를 위해 얼집 타입별로 마커를 필터링하는 기능을
	 *	백그라운드로 실행하는 메소드
	 */
	filteringMarkerByTypeBackground() {
		for (let i = 0; i < this.state.markers.length; i++) {
			let tmp_ulzip_type = '';

			if (
				'undefined' !== typeof this.state.markers[i].marker &&
				null !== this.state.markers[i].marker &&
				this.map.getBounds().contain(this.state.markers[i].marker.getPosition())
			) {
				if ('국공립' === this.state.markers[i].ulzip_type) {
					tmp_ulzip_type = 'ulzip_type_gov';
				} else if ('민간' === this.state.markers[i].ulzip_type) {
					tmp_ulzip_type = 'ulzip_type_civil';
				} else if ('가정' === this.state.markers[i].ulzip_type) {
					tmp_ulzip_type = 'ulzip_type_home';
				} else if ('협동' === this.state.markers[i].ulzip_type) {
					tmp_ulzip_type = 'ulzip_type_cooper';
				} else {
					tmp_ulzip_type = 'ulzip_type_etc';
				}

				if (this.ulzip_type_list.get('ulzip_type_all')) {
					this.state.markers[i].marker.setMap(this.map);
				} else {
					if (this.state.markers[i].child_marker_list.length > 0) {
						let target_value = null;

						let tmp_arr = [];

						tmp_arr.push(this.state.markers[i].ulzip_type);

						for (let j = 0; j < this.state.markers[i].child_marker_list.length; j++) {
							tmp_arr.push(this.state.markers[i].child_marker_list[j].ulzip_type);
						}

						//console.log(tmp_arr);

						for (let k = 0; k < tmp_arr.length; k++) {
							if ('국공립' === tmp_arr[k]) {
								tmp_arr[k] = 'ulzip_type_gov';
							} else if ('민간' === tmp_arr[k]) {
								tmp_arr[k] = 'ulzip_type_civil';
							} else if ('가정' === tmp_arr[k]) {
								tmp_arr[k] = 'ulzip_type_home';
							} else if ('협동' === tmp_arr[k]) {
								tmp_arr[k] = 'ulzip_type_cooper';
							} else {
								tmp_arr[k] = 'ulzip_type_etc';
							}
						}

						//console.log(tmp_arr);

						for (let l = 0; l < tmp_arr.length; l++) {
							if (this.ulzip_type_list.get(tmp_arr[l])) {
								target_value = this.map;
								console.log('다중마커 출력!!!');
								break;
							}
						}

						this.state.markers[i].marker.setMap(target_value);
					} else {
						if (this.ulzip_type_list.get(tmp_ulzip_type)) {
							this.state.markers[i].marker.setMap(this.map);
						} else {
							this.state.markers[i].marker.setMap(null);
						}
					}
				}
			}
		}
	}

	/**
	 *	kinder_type_all
	 *	kinder_type_pri_indv
	 *	kinder_type_pri_comp
	 *	kinder_type_gov
	 *	kinder_type_gov_alone
	 *	kinder_type_etc
	 */
	filteringKinderMarkerByType(kinderType) {
		console.log( kinderType );

		if (kinderType.hasOwnProperty('kinder_type_all')) {
			if (true === kinderType.kinder_type_all) {
				this.kinder_type_list.set('kinder_type_all', true);
				this.kinder_type_list.delete('kinder_type_pri_indv');
				this.kinder_type_list.delete('kinder_type_pri_comp');
				this.kinder_type_list.delete('kinder_type_gov');
				this.kinder_type_list.delete('kinder_type_gov_alone');
				this.kinder_type_list.delete('kinder_type_etc');
			} else {
				this.kinder_type_list.delete('kinder_type_all');
			}
		} else if (kinderType.hasOwnProperty('kinder_type_pri_indv')) {
			if (true === kinderType.kinder_type_pri_indv) {
				this.kinder_type_list.set('kinder_type_pri_indv', true);
				this.kinder_type_list.delete('kinder_type_all');

				this.kinder_type_list.set('kinder_type_pri_comp', true);
			} else {
				this.kinder_type_list.delete('kinder_type_pri_indv');

				this.kinder_type_list.delete('kinder_type_pri_comp');
			}
		} else if ( kinderType.hasOwnProperty('kinder_type_gov') ) {
			/*else if (kinderType.hasOwnProperty('kinder_type_pri_comp')) {
			if (true === kinderType.kinder_type_pri_comp) {
				this.kinder_type_list.set('kinder_type_pri_comp', true);
				this.kinder_type_list.delete('kinder_type_all');
			} else {
				this.kinder_type_list.delete('kinder_type_pri_comp');
			}
		} */ if (
				true === kinderType.kinder_type_gov
			) {
				this.kinder_type_list.set('kinder_type_gov', true);
				this.kinder_type_list.delete('kinder_type_all');

				this.kinder_type_list.set('kinder_type_gov_alone', true);
			} else {
				this.kinder_type_list.delete('kinder_type_gov');

				this.kinder_type_list.delete('kinder_type_gov_alone');
			}
		} else if ( kinderType.hasOwnProperty('kinder_type_etc') ) {
			/*else if (kinderType.hasOwnProperty('kinder_type_gov_alone')) {
			if (true === kinderType.kinder_type_gov_alone) {
				this.kinder_type_list.set('kinder_type_gov_alone', true);
				this.kinder_type_list.delete('kinder_type_all');
			} else {
				this.kinder_type_list.delete('kinder_type_gov_alone');
			}
		}*/ if (
				true === kinderType.kinder_type_etc
			) {
				this.kinder_type_list.set('kinder_type_etc', true);
				this.kinder_type_list.delete('kinder_type_all');
			} else {
				this.kinder_type_list.delete('kinder_type_etc');
			}
		} else {
			// kinder_type
			if (true === kinderType.kinder_type) {
				this.kinder_type_list.set('kinder_type_all', true);
				this.kinder_type_list.delete('kinder_type_pri_indv');
				this.kinder_type_list.delete('kinder_type_pri_comp');
				this.kinder_type_list.delete('kinder_type_gov');
				this.kinder_type_list.delete('kinder_type_gov_alone');
				this.kinder_type_list.delete('kinder_type_etc');
			} else {
				this.kinder_type_list.delete('kinder_type_all');
				this.kinder_type_list.delete('kinder_type_pri_indv');
				this.kinder_type_list.delete('kinder_type_pri_comp');
				this.kinder_type_list.delete('kinder_type_gov');
				this.kinder_type_list.delete('kinder_type_gov_alone');
				this.kinder_type_list.delete('kinder_type_etc');
			}
		}

		//console.log(this.kinder_type_list);

		// 필터 이벤트가 발생할 때마다 호출되는 부분!
		// 아래 같은 로직이 displayCenterInfo 콜백함수에서도 호출한다.
		// 아래 부분에서 해결하지 못한 부분을 displayCenterInfo 콜백에서
		// 화면이동마다 백그라운드로 실행...
		this.filteringKinderMarkerByTypeBackground();
	}

	filteringKinderMarkerByTypeBackground() {
		
		//console.log( this.kinder_type_list );
		
		for ( let i = 0 ; i < this.state.kinder_markers.length ; i++ ) {
			let tmp_kinder_type = '';
			
			if (
				'undefined' !== typeof this.state.kinder_markers[i].marker &&
				null !== this.state.kinder_markers[i].marker &&
				this.map.getBounds().contain(this.state.kinder_markers[i].marker.getPosition())
			) {
				if ('공립(병설)' === this.state.kinder_markers[i].kinder_type) {
					tmp_kinder_type = 'kinder_type_gov';
				} else if ('공립(단설)' === this.state.kinder_markers[i].kinder_type) {
					//tmp_kinder_type = 'kinder_type_gov_alone';
					tmp_kinder_type = 'kinder_type_gov';
				} else if ('사립(사인)' === this.state.kinder_markers[i].kinder_type) {
					tmp_kinder_type = 'kinder_type_pri_indv';
				} else if ('사립(법인)' === this.state.kinder_markers[i].kinder_type) {
					//tmp_kinder_type = 'kinder_type_pri_comp';
					tmp_kinder_type = 'kinder_type_pri_indv';
				} else {
					tmp_kinder_type = 'kinder_type_etc';
				}
				
				//console.log(tmp_kinder_type);
				
				if ( this.kinder_type_list.get( 'kinder_type_all' ) ) {
					this.state.kinder_markers[i].marker.setMap(this.map);
				} else {
					if ( this.state.kinder_markers[i].child_marker_list.length > 0 ) {
						let target_value = null;

						let tmp_arr = [];

						tmp_arr.push(this.state.kinder_markers[i].kinder_type);

						for (
							let j = 0;
							j < this.state.kinder_markers[i].child_marker_list.length;
							j++
						) {
							tmp_arr.push(
								this.state.kinder_markers[i].child_marker_list[j].kinder_type
							);
						}

						//console.log(tmp_arr);

						for ( let k = 0 ; k < tmp_arr.length ; k++ ) {
							if ('공립(병설)' === tmp_arr[k]) {
								tmp_arr[k] = 'kinder_type_gov';
							} else if ('공립(단설)' === tmp_arr[k]) {
								//tmp_arr[k] = 'kinder_type_gov_alone';
								tmp_arr[k] = 'kinder_type_gov';
							} else if ('사립(사인)' === tmp_arr[k]) {
								tmp_arr[k] = 'kinder_type_pri_indv';
							} else if ('사립(법인)' === tmp_arr[k]) {
								//tmp_arr[k] = 'kinder_type_pri_comp';
								tmp_arr[k] = 'kinder_type_pri_indv';
							} else {
								tmp_arr[k] = 'kinder_type_etc';
							}
						}

						//console.log(tmp_arr);

						for ( let l = 0 ; l < tmp_arr.length ; l++ ) {
							if ( this.kinder_type_list.get(tmp_arr[l]) ) {
								target_value = this.map;
								console.log('다중마커 출력!!!');
								break;
							}
						}

						this.state.kinder_markers[i].marker.setMap(target_value);
					} else {
						
						//console.log( tmp_kinder_type );
						
						if (this.kinder_type_list.get( tmp_kinder_type ) ) {
							this.state.kinder_markers[i].marker.setMap( this.map );
						} else {
							this.state.kinder_markers[i].marker.setMap(null);
						}
					}
				}
				
				//console.log( this.state.kinder_markers[i].marker.getMap() );
			}
		}
	}
	
	
	getArcode (  
		queryType,
		address_depth_1,
		address_depth_2
	) {
		const self = this;

		return new Promise( async (resolve, reject) => {
			
			let tmp_target = '';
			
			
			let arcode_list_from_local = JSON.parse(
				localStorage.getItem(address_depth_1)
			);
			
			//console.log( arcode_list_from_local );
			
			if ( Array.isArray( arcode_list_from_local ) ) {
				if ( 0 >= arcode_list_from_local.length ) {
					arcode_list_from_local = null;
				}
			} else {
				if (
					null !== arcode_list_from_local &&
					undefined !== arcode_list_from_local &&
					0 >= Object.keys(arcode_list_from_local).length
				) {
					arcode_list_from_local = null;
				}
			}
			
			
			if (
				null		=== arcode_list_from_local			|| 
				'undefined'	=== typeof arcode_list_from_local
			) {
				// 이미 로컬에 데이터가 있으면
				// 여기 안오고 로컬데이터를 불러옵니다.
				// 여기 들어오려면 일단 캐시삭제를 먼저 해야합니다.
				
				try {
					let area_code_list_from_api			= null;
					
					
					// 바로 아래 if는 아직 운영반영 못함..
					if(
						""			=== address_depth_1	||
						undefined	=== address_depth_1	||
						null		=== address_depth_1
					) {
						reject();
					}
					
					if (
						0				=== queryType		&& 
						'제주특별자치도'	=== address_depth_1
					) {
						area_code_list_from_api = await axios.get( '/api/getAreaSggCode?arname=제주' );
					} else {
						area_code_list_from_api = await axios.get(
							'/api/getAreaSggCode?arname=' +
								encodeURIComponent(address_depth_1)
						);
					}
					
					//console.log( area_code_list_from_api.data );
					
					localStorage.setItem(
						address_depth_1,
						JSON.stringify( area_code_list_from_api.data )
					);
					
					tmp_target = self.findAddrCode(
						queryType,
						address_depth_1,
						address_depth_2,
						area_code_list_from_api.data
					);
					
					resolve(tmp_target);
					
					//console.log('arcode_list save complete');
				} catch (e) {
					console.log(e);
					reject();
				}
			} else {
				//console.log('arcode_list load complete');
				
				tmp_target = self.findAddrCode(
					queryType,
					address_depth_1,
					address_depth_2,
					arcode_list_from_local
				);
				resolve(tmp_target);
			}
		});
	}
	
	
	findAddrCode(
		queryType,
		address_depth_1,
		address_depth_2,
		arcode_list
	) {
		
		let tmp_target = '';
		
		// query_type === 1 은 현재기준 세종특별자치시 처럼 어린이집정보공개포털에서
		//arcode	: 어린이집
		//sggcode	: 유치원
			
		if ( 1 === queryType ) {
			if (Array.isArray(arcode_list)) {
				for (let j = 0; j < arcode_list.length; j++) {
					if (address_depth_1 === arcode_list[j].sigunname) {
						//tmp_target = arcode_list[j].arcode;
						tmp_target = {
							arcode: arcode_list[j].arcode,
							sggcode: arcode_list[j].sggcode
						};
						break;
					}
				}
			} else {
				if (address_depth_1 === arcode_list.sigunname) {
					//tmp_target = arcode_list.arcode;
					tmp_target = {
						arcode: arcode_list.arcode,
						sggcode: arcode_list.sggcode
					};
				}
			}
		} else {
			const tmp_keyword = address_depth_2.substr(
				0,
				address_depth_2.indexOf(' ')
			);

			if (Array.isArray(arcode_list)) {
				//경상북도 포항시 남구
				//console.log( "시군(구포함) : " + search_keyword_address_depth_2 );
				//console.log( "시군(구제거) : " + tmp_keyword );

				for (let j = 0; j < arcode_list.length; j++) {
					if (
						address_depth_2 === arcode_list[j].sigunname ||
						address_depth_2.replace(' ', '') === arcode_list[j].sigunname
					) {
						//tmp_target = arcode_list[j].arcode;
						tmp_target = {
							arcode: arcode_list[j].arcode,
							sggcode: arcode_list[j].sggcode
						};
						break;
					}
				}

				if (
					'' === tmp_target.arcode ||
					null === tmp_target.arcode ||
					undefined === tmp_target.arcode
				) {
					for (let j = 0; j < arcode_list.length; j++) {
						if (tmp_keyword === arcode_list[j].sigunname) {
							tmp_target.arcode = arcode_list[j].arcode;

							break;
						}
					}
				}

				if (
					'' === tmp_target.sggcode ||
					null === tmp_target.sggcode ||
					undefined === tmp_target.sggcode
				) {
					for (let j = 0; j < arcode_list.length; j++) {
						if (tmp_keyword === arcode_list[j].sigunname) {
							tmp_target.sggcode = arcode_list[j].sggcode;

							break;
						}
					}
				}
			} else {
				if (
					address_depth_2 === arcode_list.sigunname ||
					address_depth_2.replace(' ', '') === arcode_list.sigunname
				) {
					//tmp_target = arcode_list.arcode;
					tmp_target = {
						arcode: arcode_list.arcode,
						sggcode: arcode_list.sggcode
					};
				}

				if (
					'' === tmp_target.arcode ||
					null === tmp_target.arcode ||
					undefined === tmp_target.arcode
				) {
					tmp_target.arcode = arcode_list.arcode;
				}

				if (
					'' === tmp_target.sggcode ||
					null === tmp_target.sggcode ||
					undefined === tmp_target.sggcode
				) {
					tmp_target.sggcode = arcode_list.sggcode;
				}
			}
		}
		
		return tmp_target;
	}
	
	
	addVisitedLocation ( address_depth_1, address_depth_2 ) {
		if ( this.location_list.has( address_depth_1 ) ) {
			let tmp_arr = this.location_list.get(address_depth_1);
			
			tmp_arr.push(address_depth_2);
			
			this.location_list.delete(address_depth_1);
			this.location_list.set(address_depth_1, tmp_arr);
		} else {
			this.location_list.set(address_depth_1, [address_depth_2]);
		}
	}
	
	
	removeVisitedLocation ( address_depth_1, address_depth_2 ) {
		let tmp_arr = this.location_list.get(address_depth_1);
			
		tmp_arr = tmp_arr.filter(data => data !== address_depth_2);
		
		this.location_list.delete(address_depth_1);
		this.location_list.set(address_depth_1, tmp_arr);
	}
	
	
	// queryType 
	// 0 : 특별시, 광역시, 제주
	// 1 : '구'가 없는 특별자치시 --> 세종시
	// 2 : 일반적인 도/시/구
	async getMarkers(
		markerType,
		queryType,
		address_depth_1,
		address_depth_2,
		address_depth_2_no_depth_2_addr
	) {
		
		const area_code = await this.getArcode(
			queryType,
			address_depth_1,
			address_depth_2
		);
		
		//console.log( area_code );
		
		if ( "ulzip" === markerType ) {
			
			if( 
				true ===	this.hasLocationInList(
								address_depth_1,
								address_depth_2,
								address_depth_2_no_depth_2_addr
							) 
			) {
				return;
			} else {
				this.addVisitedLocation( address_depth_1, address_depth_2 );
			}
			
			const ulzipAreaCode = area_code.arcode;
			
			this.getMarkerList(
				"ulzip",
				queryType,
				ulzipAreaCode,
				address_depth_1,
				address_depth_2,
				address_depth_2_no_depth_2_addr
			);
			
		} else if ( "kinder" === markerType ) {
			
			if( 
				true ===	this.hasLocationInList(
								address_depth_1,
								address_depth_2 + '_kinder_list',
								address_depth_2_no_depth_2_addr + '_kinder_list'
							) 
			) {
				return;
			} else {
				this.addVisitedLocation( address_depth_1, address_depth_2 + '_kinder_list' );
			}
			
			const kinderAreaCode = area_code.sggcode;
			
			this.getMarkerList(
				"kinder",
				queryType,
				kinderAreaCode,
				address_depth_1,
				address_depth_2,
				address_depth_2_no_depth_2_addr
			);
			
		} else if ( "kids_cafe" === markerType ) {
			
			if( 
				true ===	this.hasLocationInList(
								address_depth_1,
								address_depth_2 + '_kids_cafe',
								address_depth_2_no_depth_2_addr + '_kids_cafe'
							) 
			) {
				return;
			} else {
				this.addVisitedLocation( address_depth_1, address_depth_2 + '_kids_cafe' );
			}
			
		} else {
			if( 
				true ===	this.hasLocationInList(
								address_depth_1,
								address_depth_2,
								address_depth_2_no_depth_2_addr
							) 
			) {
				return;
			} else {
				this.addVisitedLocation( address_depth_1, address_depth_2 );
			}
			
			const ulzipAreaCode = area_code.arcode;
			
			this.getMarkerList(
				"ulzip",
				queryType,
				ulzipAreaCode,
				address_depth_1,
				address_depth_2,
				address_depth_2_no_depth_2_addr
			);
			
		}
		
	}
	
	
	async getMarkerList( 
		dataType,
		queryType,
		areaCode,
		address_depth_1,
		address_depth_2,
		address_depth_2_no_depth_2_addr
	) {
		
		if ( 
			"ulzip" 	!== dataType	&&
			"kinder"	!== dataType	&&
			"kids_cafe"	!== dataType
		) {
			return;
		}
		
		this.toggleMarkerLoading( dataType );
		
		
		let data_list	= null;
		
		data_list	= this.getMarkerListFromLocal(
			dataType,
			address_depth_1,
			address_depth_2,
			address_depth_2_no_depth_2_addr
		);
		
		
		if (  
			null		===	data_list	||
			undefined	===	data_list	||
			0			>=	data_list.length
		) {
			
			if ( "ulzip" === dataType ) {
				data_list	= await this.getUlzipMarkerListFromServer(
					address_depth_1,
					address_depth_2,
					areaCode
				);
			} else if ( "kinder" === dataType ) {
				data_list	= await this.getKinderMarkerListFromServer(
					address_depth_1,
					address_depth_2,
					areaCode
				);
			} else {
				
			}
			
			
			if (  
				null		===	data_list	||
				undefined	===	data_list	||
				0			>=	data_list.length
			) {
				
				this.removeVisitedLocation( address_depth_1, address_depth_2 );
				
			} else {
				
				if ( "ulzip" === dataType ) {
					this.to_be_ulzip_markers.push({
						address:
							address_depth_1 +
							'_' +
							address_depth_2,
						list: data_list,
						complete_yn: false
					});
				} else if ( "kinder" === dataType ) {
					this.to_be_kinder_markers.push({
						address:
							address_depth_1 +
							'_' +
							address_depth_2 +
							'_kinder_list',
						list: data_list,
						complete_yn: false
					});
				} else if ( "kids_cafe" === dataType ) {
					this.to_be_kids_cafe_markers.push({
						address:
							address_depth_1 +
							'_' +
							address_depth_2 +
							'_kids_cafe_list',
						list: data_list,
						complete_yn: false
					});
				} else {
					
				}
				
				
				let processedDataList	= this.processUndefinedMarkers(
					dataType,
					address_depth_1,
					address_depth_2,
					address_depth_2_no_depth_2_addr,
					queryType
				);
				
				//console.log( processedDataList );
				
				if (
					null		!== processedDataList	&&
					undefined	!== processedDataList	&&
					0			<	processedDataList.length
				) {
					this.displayMarkerList( processedDataList, dataType );
					this.moveKinderMarkerWhenDupGpsWithUlzip();
					this.openScheduledMarker( dataType );
				}
				
			}
			
		} else {
			
			this.displayMarkerList(
				data_list,
				dataType
			);
			
			this.moveKinderMarkerWhenDupGpsWithUlzip();
			this.openScheduledMarker( dataType );
		}
		
		this.toggleMarkerLoading( dataType );
		
	}
	
	
	getMarkerListFromLocal (
		dataType,
		address_depth_1,
		address_depth_2,
		address_depth_2_no_depth_2_addr
	) {
		
		let data		= null;
		let data_key_1	= "";
		let data_key_2	= "";
		let address_depth_2_for_markers_1	= "";
		let address_depth_2_for_markers_2	= "";
		
		switch ( dataType ) {
			
			case "ulzip" :
				data_key_1	= address_depth_1 + '_' + address_depth_2;
				data_key_2	= address_depth_1 + '_' + address_depth_2_no_depth_2_addr;
				address_depth_2_for_markers_1	= address_depth_2;
				address_depth_2_for_markers_2	= address_depth_2_no_depth_2_addr;
				break;
			case "kinder" :
				data_key_1	= address_depth_1 + '_' + address_depth_2 + '_kinder_list';
				data_key_2	= address_depth_1 + '_' + address_depth_2_no_depth_2_addr + '_kinder_list';
				address_depth_2_for_markers_1	= address_depth_2 + '_kinder_list';
				address_depth_2_for_markers_2	= address_depth_2_no_depth_2_addr + '_kinder_list';
				break;
			case "kids_cafe" :
				data_key_1	= address_depth_1 + '_' + address_depth_2 + '_kids_cafe_list';
				data_key_2	= address_depth_1 + '_' + address_depth_2_no_depth_2_addr + '_kids_cafe_list';
				address_depth_2_for_markers_1	= address_depth_2 + '_kids_cafe_list';
				address_depth_2_for_markers_2	= address_depth_2_no_depth_2_addr + '_kids_cafe_list';
				break;
			default : 
				data_key_1	= address_depth_1 + '_' + address_depth_2;
				data_key_2	= address_depth_1 + '_' + address_depth_2_no_depth_2_addr;
				address_depth_2_for_markers_1	= address_depth_2;
				address_depth_2_for_markers_2	= address_depth_2_no_depth_2_addr;
				break;
		}
		
		
		data	= JSON.parse( localStorage.getItem( data_key_1 ) );
		
		if (
			null		=== data		||
			'undefined'	=== typeof data	||
			0			>=	data.length
		) {
			data = JSON.parse( localStorage.getItem( data_key_2 ) );
			
			if (
				null		!== data		&&
				'undefined'	!== typeof data	&&
				0			<	data.length
			) {
				this.addVisitedLocation( address_depth_1, address_depth_2_for_markers_2 );
				this.removeVisitedLocation( address_depth_1, address_depth_2_for_markers_1 );
			}
		}
		
		return data;
	}
	
	
	async getUlzipMarkerListFromServer (
		address_depth_1,
		address_depth_2,
		areaCode
	) {
		let normal_list	= null;
		let ulzip_list	= null;
		
		try {
			const ulzip_list_data = await axios.get('/api/getCommonInfoByArcode?arcode=' + areaCode);
			
			if ( Array.isArray( ulzip_list_data.data ) ) {
				normal_list = ulzip_list_data.data.filter(
					( data ) => (
						null !== data &&
						undefined !== data &&
						data.hasOwnProperty('crstatusname') &&
						null !== data.crstatusname &&
						undefined !== data.crstatusname &&
						'폐지' !== data.crstatusname
					)
				);
			} else {
				const data_list = [ulzip_list_data.data];
				normal_list = data_list.filter(
					( data ) => (
						null !== data &&
						undefined !== data &&
						data.hasOwnProperty('crstatusname') &&
						null !== data.crstatusname &&
						undefined !== data.crstatusname &&
						'폐지' !== data.crstatusname
					)
				);
			}
			
			const ulzip_home_obj = this;
			
			ulzip_list = await Promise.all(
				normal_list.map( ( data ) => {
					
					//if( data.crname.indexOf( "가명" ) !== -1 ) {
					//	console.log( data );
					//}
					
					return new Promise((resolve, reject) => {
						if (
							(
								'undefined' === typeof data.la ||
								'undefined' === typeof data.lo 
							) ||
							(
								0 === data.la && 
								0 === data.lo
							) ||
							(
								'0' === data.la && '0' === data.lo
							) ||
							( 
								'' === data.la && '' === data.lo
							) ||
							(
								'37.566470' === data.la && 
								'126.977963' === data.lo
							) ||
							(
								false === data.gpsupdated
							)
						) {
							
							ulzip_home_obj.geocoder.addressSearch(data.craddr, (result, status) => {
								if (
									ulzip_home_obj.daum.maps.services.Status.OK === status // 정상적으로 검색이 완료됐으면
								) {
									
									let ret_obj = {
										data_type: 'ulzip',
										name: data.crname,
										ulzip_type: data.crtypename,
										postal_code: data.zipcode,
										
										address: data.craddr,
										address_depth_1: address_depth_1,
										address_depth_2: ( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,
										
										latitude: result[0].y,
										longitude: result[0].x,
										homepage: data.crhome,
										ulzip_status: data.crstatusname,
										phone: data.crtelno,
										fax: data.crfaxno,
										num_of_playground: data.plgrdco,
										num_of_staffs: data.chcrtescnt,
										area_of_school: data.nrtrroomsize,
										num_of_classroom: data.nrtrroomcnt,
										date_of_accreditation: data.crcnfmdt,
										quota: data.crcapat,
										num_of_students: data.crchcnt,
										school_car_yn:
											(
												'undefined' !== typeof data.crcargbname	&&
												'undefined' !== data.crcargbname		&&
												null		!== data.crcargbname 
											) ? data.crcargbname : '정보 없음',
										abolition_date: data.crabldt,
										closed_duration_start_date: data.crpausebegindt,
										closed_duration_end_date: data.crpauseenddt,
										extra_service: data.crspec,
										ulzip_code: data.stcode,
										child_marker_list: [],
										parent_code: null,
										rating_avg:
											( undefined !== data.rating_avg ) ? data.rating_avg : 0,
										rating_count:
											( undefined !== data.rating_count ) ? data.rating_count : 0
									};
									
									resolve(ret_obj);
								} else {
									const tmp_address = data.craddr.substring(
										0,
										data.craddr.lastIndexOf(' ') + 1
									);
									
									ulzip_home_obj.geocoder.addressSearch(
										tmp_address,
										(result, status) => {
											if (
												ulzip_home_obj.daum.maps.services.Status.OK ===
												status // 정상적으로 검색이 완료됐으면
											) {
												
												let ret_obj = {
													data_type: 'ulzip',
													name: data.crname,
													ulzip_type: data.crtypename,
													postal_code: data.zipcode,
													
													address: data.craddr,
													address_depth_1: address_depth_1,
													address_depth_2: ( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,
													latitude: result[0].y,
													longitude: result[0].x,
													homepage: data.crhome,
													ulzip_status: data.crstatusname,
													phone: data.crtelno,
													fax: data.crfaxno,
													num_of_playground: data.plgrdco,
													num_of_staffs: data.chcrtescnt,
													area_of_school: data.nrtrroomsize,
													num_of_classroom: data.nrtrroomcnt,
													date_of_accreditation: data.crcnfmdt,
													quota: data.crcapat,
													num_of_students: data.crchcnt,
													school_car_yn:
														(
															'undefined' !== typeof data.crcargbname	&&
															'undefined' !== data.crcargbname		&&
															null		!== data.crcargbname 
														) ? data.crcargbname : '정보 없음',
														
													abolition_date: data.crabldt,
													closed_duration_start_date: data.crpausebegindt,
													closed_duration_end_date: data.crpauseenddt,
													extra_service: data.crspec,
													ulzip_code: data.stcode,
													child_marker_list: [],
													parent_code: null,
													rating_avg:
														( undefined !== data.rating_avg ) ? data.rating_avg : 0,
													rating_count:
														( undefined !== data.rating_count ) ? data.rating_count : 0
												};
												
												resolve(ret_obj);
											} else {
												let ret_obj = {
													data_type: 'ulzip',
													name: data.crname,
													ulzip_type: data.crtypename,
													postal_code: data.zipcode,
													
													address: data.craddr,
													address_depth_1: address_depth_1,
													address_depth_2: ( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,
													
													latitude: '0',
													longitude: '0',
													homepage: data.crhome,
													ulzip_status: data.crstatusname,
													phone: data.crtelno,
													fax: data.crfaxno,
													num_of_playground: data.plgrdco,
													num_of_staffs: data.chcrtescnt,
													area_of_school: data.nrtrroomsize,
													num_of_classroom: data.nrtrroomcnt,
													date_of_accreditation: data.crcnfmdt,
													quota: data.crcapat,
													num_of_students: data.crchcnt,
													school_car_yn:
														(
															'undefined'	!== typeof data.crcargbname &&
															null		!== data.crcargbname &&
															'undefined' !== data.crcargbname
														) ? data.crcargbname : '정보 없음',
													abolition_date: data.crabldt,
													closed_duration_start_date: data.crpausebegindt,
													closed_duration_end_date: data.crpauseenddt,
													extra_service: data.crspec,
													ulzip_code: data.stcode,
													child_marker_list: [],
													parent_code: null,
													rating_avg:
														( undefined !== data.rating_avg ) ? data.rating_avg : 0,
													rating_count:
														( undefined !== data.rating_count ) ? data.rating_count : 0
												};
												
												resolve(ret_obj);
											}
										}
									);
								}
							});
							
						} else {
							
							//console.log( "ulzip normal" );
							
							let ret_obj = {
								data_type: 'ulzip',
								name: data.crname,
								ulzip_type: data.crtypename,
								postal_code: data.zipcode,
								
								address: data.craddr,
								address_depth_1: address_depth_1,
								address_depth_2: ( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,
								
								latitude: data.la,
								longitude: data.lo,
								homepage: data.crhome,
								ulzip_status: data.crstatusname,
								phone: data.crtelno,
								fax: data.crfaxno,
								num_of_playground: data.plgrdco,
								num_of_staffs: data.chcrtescnt,
								area_of_school: data.nrtrroomsize,
								num_of_classroom: data.nrtrroomcnt,
								date_of_accreditation: data.crcnfmdt,
								quota: data.crcapat,
								num_of_students: data.crchcnt,
								school_car_yn:
									(
										'undefined' !== typeof data.crcargbname &&
										null !== data.crcargbname &&
										'undefined' !== data.crcargbname
									) ? data.crcargbname : '정보 없음',
								abolition_date: data.crabldt,
								closed_duration_start_date: data.crpausebegindt,
								closed_duration_end_date: data.crpauseenddt,
								extra_service: data.crspec,
								ulzip_code: data.stcode,
								child_marker_list: [],
								parent_code: null,
								rating_avg: ( undefined !== data.rating_avg ) ? data.rating_avg : 0,
								rating_count: ( undefined !== data.rating_count ) ? data.rating_count : 0
							};
							
							resolve(ret_obj);
						}
					});
				})
			);
		} catch (e) {
			console.log(e);
		}
		
		//console.log( ulzip_list );
		
		return ulzip_list;
	}
	
	
	async getKinderMarkerListFromServer (
		address_depth_1,
		address_depth_2,
		areaCode
	) {
		let normal_list = null;
		let kinder_list = null;

		try {
			 const tmp_last_geo_loc_info = JSON.parse( localStorage.getItem( 'last_geo_loc_info' ) );
			const kinder_list_data = await fetch(
				'/api/getKinderListbyAreaCodeMerge?sggCode=' + areaCode+'&geo_lat=' +
				tmp_last_geo_loc_info.lat	+
				'&geo_lon=' +
				tmp_last_geo_loc_info.lon
			);


			const kinder_list_data_to_json = await kinder_list_data.json();

			if (Array.isArray(kinder_list_data_to_json)) {
				//normal_list = kinder_list_data_to_json;
				
				normal_list = kinder_list_data_to_json.filter(
					data =>
						data.hasOwnProperty('crstatusname') &&
						null !== data.crstatusname &&
						undefined !== data.crstatusname &&
						'폐원' !== data.crstatusname
				);
			} else {
				//normal_list = [kinder_list_data_to_json];
				
				normal_list = normal_list.filter(
					data =>
						data.hasOwnProperty('crstatusname') &&
						null !== data.crstatusname &&
						undefined !== data.crstatusname &&
						'폐원' !== data.crstatusname
				);
			}
			
			const ulzip_home_obj = this;
			
			
			
			kinder_list = await Promise.all(
				
				normal_list.map( ( data ) => {
					
					//if( data.kindername.indexOf( "가명" ) !== -1 ) {
					//	console.log( data );
					//}
					//la: "37.55915781948026"
					//la: "37.5591420997771"
					
					//lo: "126.96763081480185"
					//lo: "126.967621626777"
					
					return new Promise((resolve, reject) => {
						if (
							(
								'undefined' === typeof data.la ||
								'undefined' === typeof data.lo 
							) ||
							( 0 === data.la && 0 === data.lo ) ||
							( '0' === data.la && '0' === data.lo ) ||
							( '' === data.la && '' === data.lo ) ||
							( '37.566470' === data.la && '126.977963' === data.lo ) 
							// ||
							// ( 
							// 	false === data.gpsupdated
							// )
						) {
							
							ulzip_home_obj.geocoder.addressSearch(data.addr, (result, status) => {
								// 정상적으로 검색이 완료됐으면
								if (ulzip_home_obj.daum.maps.services.Status.OK === status) {
									let ret_obj = {
										data_type: 'kinder',
										
										//kinder_code: data.id,
										kinder_code: data.kindercode,
										
										name: data.kindername,
										area_code: areaCode,
										address: data.addr,

										latitude: result[0].y,
										longitude: result[0].x,
										
										address_depth_1: address_depth_1,
										address_depth_2:
											( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,
										
										kinder_status: data.crstatusname,

										kinder_type: data.establish,
										phone: data.telno,
										homepage: data.hpaddr,
										opertime: data.opertime,
										clcnt3: data.clcnt3, // 만3세학급수
										clcnt4: data.clcnt4, // 만4세학급수
										clcnt5: data.clcnt5, // 만5세학급수
										mixclcnt: data.mixclcnt, // 혼합학급수
										shclcnt: data.shclcnt, // 특수학급수

										ppcnt3: data.ppcnt3, // 만3세유아수
										ppcnt4: data.ppcnt4, // 만4세유아수
										ppcnt5: data.ppcnt5, // 만5세유아수
										mixppcnt: data.mixppcnt, // 혼합유아수
										shppcnt: data.shppcnt, // 특수유아수

										child_marker_list: [],
										parent_code: null,

										move_yn: false,
										rating_avg:
											( undefined !== data.rating_avg ) ? data.rating_avg : 0,
										rating_count:
											( undefined !== data.rating_count ) ? data.rating_count : 0
									};

									resolve(ret_obj);
								} else {
									const tmp_address = data.addr.substring(
										0,
										data.addr.lastIndexOf(' ') + 1
									);

									//console.log( tmp_address );

									ulzip_home_obj.geocoder.addressSearch(
										tmp_address,
										(result, status) => {
											// 정상적으로 검색이 완료됐으면
											if (
												ulzip_home_obj.daum.maps.services.Status.OK ===
												status
											) {
												let ret_obj = {
													data_type: 'kinder',
													
													//kinder_code: data.id,
													kinder_code: data.kindercode,
													
													name: data.kindername,
													area_code: areaCode,
													address: data.addr,

													latitude: result[0].y,
													longitude: result[0].x,

													address_depth_1: address_depth_1,
													address_depth_2:
														( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,

													kinder_status: data.crstatusname,

													kinder_type: data.establish,
													phone: data.telno,
													homepage: data.hpaddr,
													opertime: data.opertime,
													clcnt3: data.clcnt3, // 만3세학급수
													clcnt4: data.clcnt4, // 만4세학급수
													clcnt5: data.clcnt5, // 만5세학급수
													mixclcnt: data.mixclcnt, // 혼합학급수
													shclcnt: data.shclcnt, // 특수학급수

													ppcnt3: data.ppcnt3, // 만3세유아수
													ppcnt4: data.ppcnt4, // 만4세유아수
													ppcnt5: data.ppcnt5, // 만5세유아수
													mixppcnt: data.mixppcnt, // 혼합유아수
													shppcnt: data.shppcnt, // 특수유아수

													child_marker_list: [],
													parent_code: null,

													move_yn: false,
													rating_avg:
														( undefined !== data.rating_avg ) ? data.rating_avg : 0,
													rating_count:
														( undefined !== data.rating_count ) ? data.rating_count : 0
												};

												resolve(ret_obj);
											} else {
												let ret_obj = {
													data_type: 'kinder',
													
													//kinder_code: data.id,
													kinder_code: data.kindercode,
													
													name: data.kindername,
													area_code: areaCode,
													address: data.addr,

													latitude: '0',
													longitude: '0',

													address_depth_1: address_depth_1,
													address_depth_2:
														( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,

													kinder_status: data.crstatusname,

													kinder_type: data.establish,
													phone: data.telno,
													homepage: data.hpaddr,
													opertime: data.opertime,
													clcnt3: data.clcnt3, // 만3세학급수
													clcnt4: data.clcnt4, // 만4세학급수
													clcnt5: data.clcnt5, // 만5세학급수
													mixclcnt: data.mixclcnt, // 혼합학급수
													shclcnt: data.shclcnt, // 특수학급수

													ppcnt3: data.ppcnt3, // 만3세유아수
													ppcnt4: data.ppcnt4, // 만4세유아수
													ppcnt5: data.ppcnt5, // 만5세유아수
													mixppcnt: data.mixppcnt, // 혼합유아수
													shppcnt: data.shppcnt, // 특수유아수

													child_marker_list: [],
													parent_code: null,

													move_yn: false,
													rating_avg:
														( undefined !== data.rating_avg ) ? data.rating_avg : 0,
													rating_count:
														( undefined !== data.rating_count ) ? data.rating_count : 0
												};

												resolve(ret_obj);
											}
										}
									);
								}
							});
						} else {
							
							//console.log( "kinder normal" );
							
							let ret_obj = {
								data_type: 'kinder',
								
								//kinder_code: data.id,
								kinder_code: data.kindercode,
								
								name: data.kindername,
								area_code: areaCode,
								address: data.addr,
								
								// "81b1e057-d5ce-494e-b387-1e337416892a" : 오즈의마법사판교유치원
								// "b94b2a35-741e-4ded-ac76-226e376d1e94" : 위례한빛초등학교병설유치원
								
								latitude:
									( '81b1e057-d5ce-494e-b387-1e337416892a' === data.id ) ? 
											'37.38834319283787' : 
											(
												( 'b94b2a35-741e-4ded-ac76-226e376d1e94' === data.id ) ? '37.465526' : data.la
											),
								longitude:
									(
										'81b1e057-d5ce-494e-b387-1e337416892a' === data.id
									) ? 
										'127.11151095235391' : 
										(
											( 'b94b2a35-741e-4ded-ac76-226e376d1e94' === data.id ) ? '127.146881' : data.lo
										),

								address_depth_1: address_depth_1,
								address_depth_2:
									( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,

								kinder_status: data.crstatusname,

								kinder_type: data.establish,
								phone: data.telno,
								homepage: data.hpaddr,
								opertime: data.opertime,
								clcnt3: data.clcnt3, // 만3세학급수
								clcnt4: data.clcnt4, // 만4세학급수
								clcnt5: data.clcnt5, // 만5세학급수
								mixclcnt: data.mixclcnt, // 혼합학급수
								shclcnt: data.shclcnt, // 특수학급수

								ppcnt3: data.ppcnt3, // 만3세유아수
								ppcnt4: data.ppcnt4, // 만4세유아수
								ppcnt5: data.ppcnt5, // 만5세유아수
								mixppcnt: data.mixppcnt, // 혼합유아수
								shppcnt: data.shppcnt, // 특수유아수

								child_marker_list: [],
								parent_code: null,

								move_yn: false,
								rating_avg: ( undefined !== data.rating_avg ) ? data.rating_avg : 0,
								rating_count: ( undefined !== data.rating_count ) ? data.rating_count : 0
							};

							resolve(ret_obj);
						}
					});
				})
			);
		} catch (e) {
			console.log(e);
		}

		//console.log( kinder_list );

		return kinder_list;
	}
	
	
	processUndefinedMarkers(
		dataType,
		address_depth_1,
		address_depth_2,
		address_depth_2_no_depth_2_addr,
		queryType
	) {
		let data_list	= null;
		
		if ( 
			"ulzip"		!== dataType	&&
			"kinder"	!== dataType
		) {
			return null;
		}
		
		
		if (
			false ===
			this.isProcessingGpsForMarkers(
				dataType,
				address_depth_1,
				address_depth_2
			)
		) {
			this.storeMarkerData(
				dataType,
				queryType,
				address_depth_1,
				address_depth_2
			);
			
			data_list	= this.loadMarkerData(
				dataType,
				address_depth_1,
				address_depth_2
			);
			
			return data_list;
		}
		
		if (
			false ===
			this.isProcessingGpsForMarkers(
				dataType,
				address_depth_1,
				address_depth_2_no_depth_2_addr
			)
		) {
			this.storeMarkerData(
				dataType,
				queryType,
				address_depth_1,
				address_depth_2_no_depth_2_addr
			);
			
			data_list	= this.loadMarkerData(
				dataType,
				address_depth_1,
				address_depth_2_no_depth_2_addr
			);
			
			return data_list;
		}
		
		return null;
	}
	
	
	moveKinderMarkerWhenDupGpsWithUlzip ( ) {
		let kinder_list = this.state.kinder_markers;
		let flag = false;
		
		for ( let i = 0 ; i < kinder_list.length ; i++ ) {
			let target = kinder_list[i];
			
			for ( let j = 0 ; j < this.state.markers.length ; j++ ) {
				
				//console.log( target.latitude.substring(0,8) );
				
				if (
					target.move_yn === false &&
					Number(target.latitude) > 0 &&
					Number(target.longitude) > 0 &&
					(
						//target.latitude.substring(0, 8)		=== this.state.markers[j].latitude.substring(0, 8) &&
						//target.longitude.substring(0, 8)	=== this.state.markers[j].longitude.substring(0, 8)
						target.latitude.substring(0, 7)		=== this.state.markers[j].latitude.substring(0, 7)		||
						target.longitude.substring(0, 7)	=== this.state.markers[j].longitude.substring(0, 7)
					)
				) {
					const tmp_longitude = String(Number(target.longitude) + 0.0005);
					
					target.longitude = tmp_longitude;
					
					const tmp_coords = new this.daum.maps.LatLng(target.latitude, tmp_longitude);
					
					target.marker.setPosition(tmp_coords);
					target.overlayInfo.setPosition(tmp_coords);
					
					target.move_yn = true;
					
					flag = true;
					
					break;
				}
			}
		}
		
		if ( true === flag ) {
			this.setState({
				kinder_markers: kinder_list
			});
		}
	}
	
	
	isProcessingGpsForMarkers( dataType, address_depth_1, address_depth_2 ) {
		
		let non_exists_flag = true;
		let to_be_markers	= null;
		let target_address	= "";
		
		if ( "ulzip" === dataType ) {
			to_be_markers	= this.to_be_ulzip_markers;
			target_address	= address_depth_1 + "_" + address_depth_2;
		} else if ( "kinder" === dataType ) {
			to_be_markers	= this.to_be_kinder_markers;
			target_address	= address_depth_1 + "_" + address_depth_2 + "_kinder_list";
		} else {
			return true;
		}
		
		
		if (
			null		=== to_be_markers || 
			undefined	=== to_be_markers
		) {
			return true;
		}
		
		for ( let i = 0 ; i < to_be_markers.length ; i++ ) {
			
			if ( target_address === to_be_markers[i].address ) {
				non_exists_flag = false;
				break;
			}
			
		}
		
		// to_be_markers 에 target_address 주소가 없으면 true를 바로 리턴
		if ( true === non_exists_flag ) {
			return true;
		}
		
		for  ( let i = 0 ; i < to_be_markers.length ; i++ ) {
			if ( target_address === to_be_markers[i].address ) {
				let flag		= false;
				const tmp_arr	= to_be_markers[i].list;
				
				for ( let j = 0 ; j < tmp_arr.length ; j++ ) {
					
					if (
						'undefined' === typeof tmp_arr[j].latitude ||
						'undefined' === typeof tmp_arr[j].longitude
					) {
						//console.log('undefined marker is ' + tmp_arr[j]);
						flag = true;
						break;
					}
				}
				
				if ( false === flag ) {
					to_be_markers[i].complete_yn = true;
					
					if ( "ulzip" === dataType ) {
						this.to_be_ulzip_markers	= to_be_markers;
						//console.log( this.to_be_ulzip_markers );
					} else if ( "kinder" === dataType ) {
						this.to_be_kinder_markers	= to_be_markers;
						//console.log( this.to_be_kinder_markers );
					} 
					
				}
				
				return !to_be_markers[i].complete_yn;
			}
		}
		
	}
	
	
	/**
	 *	Local에 저장된 마커 리스트를 불러올 때 호출하는 메소드
	 *
	 */
	displayMarkerList( data, dataType ) {
		
		if ( 
			'ulzip' !== dataType	&& 
			'kinder' !== dataType 
		) {
			console.log('data type error... at displayMarkerList');
			return;
		}
		
		
		for ( let i = 0 ; i < data.length ; i++ ) {
			const tmp_coords = new this.daum.maps.LatLng(
				data[i].latitude,
				data[i].longitude
			);
			
			let overlay_content = '';
			let customOverlay = null;
			
			if (
				null		!==	data[i].child_marker_list			&&
				'undefined'	!==	typeof data[i].child_marker_list	&&
				0			<	data[i].child_marker_list.length
			) {
				// 커스텀 오버레이에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
				
				if ( 'ulzip' === dataType ) {
					overlay_content =
						'<div class="CustomOverlay">' +
							'<span class="CustomOverlayTitle">' +
								'어린이집 목록' +
							'</span>' +
							'<span class="CustomOverlayContent">' +
						data[i].name;
				} else if ( 'kinder' === dataType ) {
					overlay_content =
						'<div class="CustomOverlay">' +
							'<span class="CustomOverlayTitleForKinder">' +
								'유치원 목록' +
							'</span>' +
							'<span class="CustomOverlayContent">' +
						data[i].name;
				} else {
					return;
				}

				const child_marker_list = data[i].child_marker_list;

				for ( let j = 0 ; j < child_marker_list.length ; j++ ) {
					overlay_content = overlay_content + '<br/>' + child_marker_list[j].name;
				}
				
				overlay_content = overlay_content + '</span></div>';
				
				data[i].marker = this.displayDupMarker( tmp_coords, dataType );
				
				// marker click event listener 등록!!!
				((marker, daum) => {
					daum.maps.event.addListener(marker.marker, 'click', () => {
						this.searchResultClose();
						this.map.setCenter(
							new this.daum.maps.LatLng(marker.latitude, marker.longitude)
						);
						
						let tmp_dup_markers_list = [];
						
						if ( 'ulzip' === marker.data_type ) {
							tmp_dup_markers_list.push({
								dataType: marker.data_type,
								name: marker.name,
								code: marker.ulzip_code,
								marker: marker
							});
						} else if ( 'kinder' === marker.data_type ) {
							tmp_dup_markers_list.push({
								dataType: marker.data_type,
								name: marker.name,
								code: marker.kinder_code,
								marker: marker
							});
						} else if ( 'kids_cafe' === marker.data_type ) {
						
						} else {
							
						}
						
						const child_marker_list = marker.child_marker_list;
						
						
						for ( let j = 0 ; j < child_marker_list.length ; j++ ) {
							if ( 'ulzip' === marker.data_type ) {
								tmp_dup_markers_list.push({
									dataType: child_marker_list[j].data_type,
									name: child_marker_list[j].name,
									code: child_marker_list[j].ulzip_code,
									marker: child_marker_list[j]
								});
							} else if ( 'kinder' === marker.data_type ) {
								tmp_dup_markers_list.push({
									dataType: child_marker_list[j].data_type,
									name: child_marker_list[j].name,
									code: child_marker_list[j].kinder_code,
									marker: child_marker_list[j]
								});
							} else if ( 'kids_cafe' === marker.data_type ) {
							
							} else {
								
							}
						}
						
						this.setState({
							dup_markers_list: tmp_dup_markers_list
						});
						
						if( 
							null	!==	tmp_dup_markers_list	&&
							0		<	tmp_dup_markers_list.length
						) {
							this.closeControlPanel( "dup_markers_list" );
						}
						
					});
				})(data[i], this.daum);
			} else {
				const tmp_school_car_yn =
					(
						null		!== data[i].school_car_yn			&&
						'undefined'	!== typeof data[i].school_car_yn	&&
						'undefined'	!== data[i].school_car_yn
					) ? 
						data[i].school_car_yn : '정보 없음';
				
				// 커스텀 오버레이에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
				
				if ( 'ulzip' === dataType ) {
					overlay_content =
						'<div class="CustomOverlay">' +
							'<span class="CustomOverlayTitle">' +
								data[i].name +
							'</span>' +
							'<span class="CustomOverlayContent">' +
								'현/정원 : ' +
								data[i].num_of_students +
								'/' +
								data[i].quota +
								' 명<br/>' +
								'통학차량여부 : ' +
								tmp_school_car_yn +
							'</span>' +
						'</div>';
				} else if ( 'kinder' === dataType ) {
					overlay_content =
						'<div class="CustomOverlay">' +
							'<span class="CustomOverlayTitleForKinder">' +
								data[i].name +
							'</span>' +
							'<span class="CustomOverlayContent">' +
								'운영시간 : ' +
								data[i].opertime +
							'</span>' +
						'</div>';
				} else {
					
				}
				
				
				if ( 'ulzip' === dataType ) {
					data[i].marker = this.displayMarker(
						tmp_coords,
						data[i].ulzip_type,
						'ulzip'
					);
				} else if ( 'kinder' === dataType ) {
					data[i].marker = this.displayMarker(
						tmp_coords,
						data[i].kinder_type,
						'kinder'
					);
				} else {
					
				}
				
				
				// add marker listener...
				((marker, daum) => {
					daum.maps.event.addListener(marker.marker, 'click', () => {
						
						const query	= queryString.parse( this.props.location.search );
						
						
						if ( 'ulzip' === dataType ) {
							
							if (
								query.type	=== dataType	&&
								query.id_	=== marker.ulzip_code
							) {
								this.createErrorMsg(
									"이미 선택하신 '어린이집'이에요! ^^",
									"warning"
								);
								return false;
							}
							
							if ( 
								true	=== isMobileOnly	||
								true	=== isTablet		||
								true	===	isIPad13	
							) {
								this.props.history.push( 
									'/m/Marker/?type=' + dataType + 
									'&id_=' + marker.ulzip_code + 
									'&lat=' + marker.latitude + 
									'&lon=' + marker.longitude +
									'&addressDepth1=' + marker.address_depth_1 +
									'&addressDepth2=' + marker.address_depth_2
								);
							} else {
								this.props.history.push( 
									'/Marker/?type=' + dataType + 
									'&id_=' + marker.ulzip_code + 
									'&lat=' + marker.latitude + 
									'&lon=' + marker.longitude +
									'&addressDepth1=' + marker.address_depth_1 +
									'&addressDepth2=' + marker.address_depth_2
								);
							}
							
						} else if ( 'kinder' === dataType ) { 
							if (
								query.type	=== dataType	&&
								query.id_	=== marker.kinder_code
							) {
								this.createErrorMsg(
									"이미 선택하신 '유치원'이에요! ^^",
									"warning"
								);
								return false;
							}
							
							if ( 
								true	=== isMobileOnly	||
								true	=== isTablet		||
								true	===	isIPad13	
							) {
								this.props.history.push( 
									'/m/Marker/?type=' + dataType + 
									'&id_=' + marker.kinder_code + 
									'&lat=' + marker.latitude + 
									'&lon=' + marker.longitude +
									'&addressDepth1=' + marker.address_depth_1 +
									'&addressDepth2=' + marker.address_depth_2
								);
							} else {
								this.props.history.push( 
									'/Marker/?type=' + dataType + 
									'&id_=' + marker.kinder_code + 
									'&lat=' + marker.latitude + 
									'&lon=' + marker.longitude +
									'&addressDepth1=' + marker.address_depth_1 +
									'&addressDepth2=' + marker.address_depth_2
								);
							}
							
						} else if ( 'kids_cafe' === dataType ) {
							
						} else {
							
						}
						
						this.searchResultClose();
						
						this.map.setCenter(
							new this.daum.maps.LatLng(marker.latitude, marker.longitude)
						);
						
						this.closeControlPanel();
						
						Event(
							'ACTION',
							dataType + ' Detail clicked',
							dataType + ' Detail clicked'
						);
						
					});
				})(data[i], this.daum);
			}
			
			// 커스텀 오버레이를 생성합니다
			//console.log(tmp_coords);
			
			customOverlay = new this.daum.maps.CustomOverlay({
				map: this.map,
				position: tmp_coords,
				content: overlay_content,
				clickable: false,
				xAnchor: 0,
				yAnchor: 1,
				zIndex: 1
			});
			
			customOverlay.setVisible(false);
			
			data[i].overlayInfo = customOverlay;
			
			((marker, daum, map) => {
				const markerMouseOverEventHandler = () => {
					if ('undefined' !== typeof marker.overlayInfo) {
						if (false === marker.overlayInfo.getVisible()) {
							marker.overlayInfo.setVisible(true);
						}
					} else {
						console.log('undefined : ' + marker);
					}
				};
				const markerMouseOutEventHandler = () => {
					if ( 'undefined' !== typeof marker.overlayInfo ) {
						if ( true === marker.overlayInfo.getVisible() ) {
							marker.overlayInfo.setVisible(false);
						}
						
						if (
							false ===
							marker.marker.getPosition().equals(marker.overlayInfo.getPosition())
						) {
							marker.overlayInfo.setPosition(marker.marker.getPosition());
						}
					} else {
						console.log('undefined : ' + marker);
					}
				};
				
				daum.maps.event.addListener(
					marker.marker,
					'mouseover',
					markerMouseOverEventHandler
				);
				
				daum.maps.event.addListener(marker.marker, 'mouseout', markerMouseOutEventHandler);
				
				daum.maps.event.addListener(marker.marker, 'dragend', () => {
					if ('undefined' !== typeof marker.overlayInfo) {
						if (
							false ===
							marker.marker.getPosition().equals(marker.overlayInfo.getPosition())
						) {
							marker.overlayInfo.setPosition(marker.marker.getPosition());
						}
					} else {
						console.log('undefined : ' + marker);
					}
				});
				
				daum.maps.event.addListener(
					marker.overlayInfo,
					'display_custom_overlay',
					isOpen => {
						if (true === isOpen) {
							daum.maps.event.removeListener(
								marker.marker,
								'mouseover',
								markerMouseOverEventHandler
							);
							
							daum.maps.event.removeListener(
								marker.marker,
								'mouseout',
								markerMouseOutEventHandler
							);
							
							marker.overlayInfo.setVisible(true);
						} else {
							daum.maps.event.addListener(
								marker.marker,
								'mouseover',
								markerMouseOverEventHandler
							);
							
							daum.maps.event.addListener(
								marker.marker,
								'mouseout',
								markerMouseOutEventHandler
							);
							
							marker.overlayInfo.setVisible(false);
						}
					}
				);
			})(data[i], this.daum, this.map);
		} // End of "for( let i = 0 ; i < data.length ; i++ ) {"
		
		if ( 'ulzip' === dataType ) {
			this.setState({
				markers: this.state.markers.concat(data)
			});
		} else if ( 'kinder' === dataType ) {
			this.setState({
				kinder_markers: this.state.kinder_markers.concat(data)
			});
		} else {
			
		}
		
	}
	

	// 지도에 마커를 표시하는 함수입니다
	// data_type : ulzip_list 또는 kinder_list
	// marker_type : 얼집 또는 유치원의 세부구분을 뜻한다. 가령 얼집에서는 가정, 민간, 국공립 등이 있으며,
	//				 유치원에서는 사립(사인), 사립(법인), 공립(병설) 등이 존재...
	displayMarker( place, marker_type, data_type ) {
		let imageSrc = ''; // 마커이미지의 주소입니다
		//885 × 968 픽셀
		//const	imageSize = new this.daum.maps.Size(48, 51.75); // 마커이미지의 크기입니다
		const imageSize = new this.daum.maps.Size(52, 57); // 마커이미지의 크기입니다
		//const	imageOption = { offset: new this.daum.maps.Point(10, -12) }; // 마커이미지의 옵션입니다.
		const imageOption = { offset: new this.daum.maps.Point(10, 50) }; // 마커이미지의 옵션입니다.   아까 이걸로 함
		// ( -값일수록 우측으로 이동  ,   -값일수록 밑으로 내려옴)
		// ( -값일수록 우측으로 이동  ,   -값일수록 밑으로 내려옴)
		
		// 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
		//const markerImage = new this.daum.maps.MarkerImage(imageSrc, imageSize, imageOption);
		
		let tmp_map = null;
		let tmp_marker_type = '';
		
		if ( 'ulzip' === data_type ) {
			if ( '국공립' === marker_type ) {
				tmp_marker_type = 'ulzip_type_gov';
				
				if (
					String(window.location.origin).includes('ulziphow')
				) {
					imageSrc = 'https://ulziphow-back.run.goorm.io/img/u_guk.png';
				} else {
					imageSrc = String(window.location.origin) + '/img/u_guk.png';
				}
			} else if ('민간' === marker_type) {
				tmp_marker_type = 'ulzip_type_civil';
				
				if (
					String(window.location.origin).includes('ulziphow')
				) {
					imageSrc = 'https://ulziphow-back.run.goorm.io/img/u_min.png';
				} else {
					imageSrc = String(window.location.origin) + '/img/u_min.png';
				}
			} else if ('가정' === marker_type) {
				tmp_marker_type = 'ulzip_type_home';
				
				if (
					String(window.location.origin).includes('ulziphow')
				) {
					imageSrc = 'https://ulziphow-back.run.goorm.io/img/u_ga.png';
				} else {
					imageSrc = String(window.location.origin) + '/img/u_ga.png';
				}
			} else if ('협동' === marker_type) {
				tmp_marker_type = 'ulzip_type_cooper';
				
				if (
					String(window.location.origin).includes('ulziphow')
				) {
					imageSrc = 'https://ulziphow-back.run.goorm.io/img/u_hyup.png';
				} else {
					imageSrc = String(window.location.origin) + '/img/u_hyup.png';
				}
			} else {
				tmp_marker_type = 'ulzip_type_etc';
				
				if (
					String(window.location.origin).includes('ulziphow')
				) {
					imageSrc = 'https://ulziphow-back.run.goorm.io/img/u_gi.png';
				} else {
					imageSrc = String(window.location.origin) + '/img/u_gi.png';
				}
			}
			
			if (this.ulzip_type_list.get('ulzip_type_all')) {
				tmp_map = this.map;
			} else {
				if (this.ulzip_type_list.get(tmp_marker_type)) {
					tmp_map = this.map;
				} else {
					tmp_map = null;
				}
			}
		} else if ( 'kinder' === data_type ) {
			if ('공립(병설)' === marker_type) {
				tmp_marker_type = 'kinder_type_gov';
				
				if (
					String(window.location.origin).includes('ulziphow')
				) {
					imageSrc = 'https://ulziphow-back.run.goorm.io/img/k_guk_b.png';
				} else {
					imageSrc = String(window.location.origin) + '/img/k_guk_b.png';
				}
			} else if ('공립(단설)' === marker_type) {
				tmp_marker_type = 'kinder_type_gov_alone';
				if (
					String(window.location.origin).includes('ulziphow')
				) {
					imageSrc = 'https://ulziphow-back.run.goorm.io/img/k_guk_d.png';
				} else {
					imageSrc = String(window.location.origin) + '/img/k_guk_d.png';
				}
			} else if ('사립(사인)' === marker_type) {
				tmp_marker_type = 'kinder_type_pri_indv';
				
				if (
					String(window.location.origin).includes('ulziphow')
				) {
					imageSrc = 'https://ulziphow-back.run.goorm.io/img/k_sa_s.png';
				} else {
					imageSrc = String(window.location.origin) + '/img/k_sa_s.png';
				}
			} else if ('사립(법인)' === marker_type) {
				tmp_marker_type = 'kinder_type_pri_comp';
				
				if (
					String(window.location.origin).includes('ulziphow')
				) {
					imageSrc = 'https://ulziphow-back.run.goorm.io/img/k_sa_b.png';
				} else {
					imageSrc = String(window.location.origin) + '/img/k_sa_b.png';
				}
			} else {
				tmp_marker_type = 'kinder_type_etc';
				imageSrc = 'https://ulziphow-back.run.goorm.io/img/k_gi.png';
				
				if (
					String(window.location.origin).includes('ulziphow')
				) {
					imageSrc = 'https://ulziphow-back.run.goorm.io/img/k_gi.png';
				} else {
					imageSrc = String(window.location.origin) + '/img/k_gi.png';
				}
			}
			
			if ( this.kinder_type_list.get('kinder_type_all') ) {
				tmp_map = this.map;
			} else {
				if (this.kinder_type_list.get(tmp_marker_type)) {
					tmp_map = this.map;
				} else {
					tmp_map = null;
				}
			}
		} else {
			return null;
		}
		
		// 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
		const markerImage = new this.daum.maps.MarkerImage(imageSrc, imageSize, imageOption);
		
		// 마커를 생성하고 지도에 표시합니다
		return new this.daum.maps.Marker({
			map: 0 === place.getLat() && 0 === place.getLng() ? null : tmp_map,
			position: place,
			clickable: 0 === place.getLat() && 0 === place.getLng() ? false : true,
			//draggable: 0 === place.getLat() && 0 === place.getLng() ? false : true,
			draggable: false,
			//draggable: true,
			image: 0 === place.getLat() && 0 === place.getLng() ? null : markerImage //, // 마커이미지 설정
			//zIndex:2
		});
	}
	
	
	storeMarkerData (
		dataType,
		queryType,
		address_depth_1,
		address_depth_2
	) {
		let target_idx	= -1;
		let data_list	= null;
		
		
		if ( "ulzip" === dataType ) {
			data_list	= this.to_be_ulzip_markers;
		} else if ( "kinder" === dataType ) {
			data_list	= this.to_be_kinder_markers;
		} else {
			return;
		}
		
		
		for ( let i = 0 ; i < data_list.length ; i++ ) {
			if ( true === data_list[i].complete_yn ) {
				if ( data_list[i].list.length > 0 ) {
					data_list[i].list = this.groupingDupGpsMarkerList(
						data_list[i].list
					);
				}
				
				//console.log( data_list[i].list );
				
				if ( data_list[i].list.length > 0 ) {
					
					this.storeMarkerDataToClientLocalFromApi(
						queryType,
						dataType + "_list",
						data_list[i].list
					);
					
					target_idx = i;
					
					break;
				}
			}
		}
		
		
		if ( target_idx >= 0 ) {
			let array_head = data_list.slice(0, target_idx);
			let array_tail = null;
			let result_arr = null;
			
			// 현재 엘리먼트가 last 가 아니면 if문을 타고, last 이면 else문을 탄다.
			if ( target_idx < data_list.length - 1 ) {
				array_tail = data_list.slice(
					target_idx + 1,
					data_list.length
				);
				result_arr = array_head.concat(array_tail);
			} else {
				result_arr = array_head;
			}
			
			data_list = result_arr;
			
			if ( "ulzip" === dataType ) {
				this.to_be_ulzip_markers	= data_list;
			} else if ( "kinder" === dataType ) {
				this.to_be_kinder_markers	= data_list;
			} else {
				return;
			}
		}
		
	}
	
	
	loadMarkerData (
		dataType,
		address_depth_1,
		address_depth_2
	) {
		
		let target_address_depth_2	= "";
		
		switch ( dataType ) {
			case "ulzip" :
				target_address_depth_2	= address_depth_2;
				break;
			case "kinder" :
				target_address_depth_2	= address_depth_2 + "_kinder_list";
				break;
			default :
				break;
		}
		
		return(
			JSON.parse(
				localStorage.getItem(
					address_depth_1 +
					'_' +
					target_address_depth_2
				)
			)
		);
		
	}
	
	
	/**
	 *	파라미터로 GPS 정보가 있는 marker 리스트를 받고,
	 *	GPS가 중복인 marker를 하나이 marker로 그룹화하는 메소드
	 *	그룹화가 완료된 marker list를 리턴한다.
	 */
	groupingDupGpsMarkerList( data_list ) {
		let dup_gps_checker = new Map();
		
		//console.log( "before : " + data_list.length );
		
		for ( let j = 0; j < data_list.length; j++ ) {
			if (
				dup_gps_checker.has(
					String(data_list[j].latitude).substring(0, 7) +
						'_' +
						String(data_list[j].longitude).substring(0, 7)
				)
			) {
				let tmp_value = dup_gps_checker.get(
					String(data_list[j].latitude).substring(0, 7) +
						'_' +
						String(data_list[j].longitude).substring(0, 7)
				);

				const tmp_idx = Number(tmp_value.idx);

				let tmp_index_list = tmp_value.index_list;

				tmp_index_list.push(j);

				dup_gps_checker.delete(
					String(data_list[j].latitude).substring(0, 7) +
						'_' +
						String(data_list[j].longitude).substring(0, 7)
				);

				dup_gps_checker.set(
					String(data_list[j].latitude).substring(0, 7) +
						'_' +
						String(data_list[j].longitude).substring(0, 7),
					{
						idx: tmp_idx,
						index_list: tmp_index_list,
						dup_check_yn: true
					}
				);

				let bs_marker = data_list[tmp_idx];
				let copied = data_list[j];

				data_list[j].delete_yn = true;
				data_list[j].parent_code =
					( 'ulzip' === bs_marker.data_type ) ? bs_marker.ulzip_code : bs_marker.kinder_code;

				bs_marker.child_marker_list.push(copied);

				let array_head = data_list.slice(0, tmp_idx);
				let array_tail = null;
				let result_arr = null;

				// 현재 엘리먼트가 last 가 아니면 if문을 타고, last 이면 else문을 탄다.
				if (tmp_idx < data_list.length - 1) {
					array_tail = data_list.slice(tmp_idx + 1, data_list.length);
					result_arr = array_head.concat(bs_marker).concat(array_tail);
				} else {
					result_arr = array_head.concat(bs_marker);
				}

				data_list = result_arr;
			} else {
				dup_gps_checker.set(
					String(data_list[j].latitude).substring(0, 7) +
						'_' +
						String(data_list[j].longitude).substring(0, 7),
					{
						idx: j,
						index_list: [j],
						dup_check_yn: false
					}
				);
				
				data_list[j].delete_yn = false;
			}
		}
		
		data_list = data_list.filter(data => data.delete_yn === false);
		
		return data_list;
	}
	
	
	/**
	 *	클라이언트 로컬 브라우저에 marker 정보를 저장할 때 호출한다.
	 *	파라미터 두 개 모두 필수!
	 */
	storeMarkerDataToClientLocalFromApi( queryType, dataType, dataList ) {
		
		if (
			0 !== queryType && // address_depth_1, address_depth_2 조회, 서울특별시, 광역시
			1 !== queryType && // address_depth_1 조회, 세종특별자치시
			2 !== queryType		// 일반적인 도/시/구
		) {
			console.log('query type error!!! at storeMarkerDataToClientLocalFromApi');
			return;
		}
		
		if ('ulzip_list' !== dataType && 'kinder_list' !== dataType) {
			console.log('data_type error!!! at storeMarkerDataToClientLocalFromApi');
			return;
		}
		
		//console.log( this.location_list );
		
		for ( let [k, v] of this.location_list ) {
			for ( let i = 0 ; i < v.length ; i++ ) {
				
				let tmp_key = k + '_' + v[i];
				
				if (
					'ulzip_list' === dataType && 
					-1 !== tmp_key.indexOf('_kinder_list')
				) {
					continue;
				}
				
				if (
					'kinder_list' === dataType && 
					-1 === tmp_key.indexOf('_kinder_list')
				) {
					continue;
				}
				
				const tmp_local_storage_data = localStorage.getItem(tmp_key);
				
				if (
					(
						null		=== tmp_local_storage_data			||
						'undefined'	=== typeof tmp_local_storage_data
					) &&
					dataList.length > 0
				) {
					let insertData = null;
					
					
					if ( 1 === queryType ) {
						// '구'가 없는 특별자치시
						insertData = dataList.filter( data => data.address_depth_1 === k );
					} else {
						
						if ( 'ulzip_list' === dataType ) {
							insertData = dataList.filter(
								data => data.address_depth_1 === k && data.address_depth_2 === v[i]
							);
						} else if ( 'kinder_list' === dataType ) {
							insertData = dataList.filter(
								data =>
									data.address_depth_1 === k &&
									data.address_depth_2 === v[i].replace('_kinder_list', '')
							);
						} else {
							return;
						}
						
						if ( insertData.length <= 0 ) {
							const tmp_address_depth_2 = v[i].replace(' ', '');
							
							if ( 'ulzip_list' === dataType ) {
								insertData = dataList.filter(
									data =>
										data.address_depth_1 === k &&
										data.address_depth_2 === tmp_address_depth_2
								);
							} else if ( 'kinder_list' === dataType ) {
								insertData = dataList.filter(
									data =>
										data.address_depth_1 === k &&
										data.address_depth_2 ===
											tmp_address_depth_2.replace('_kinder_list', '')
								);
							} else {
								return;
							}
						}
						
						if ( insertData.length <= 0 ) {
							let tmp_address_depth_2 = v[i].substr(0, v[i].indexOf(' '));
							
							if ( 'ulzip_list' === dataType ) {
								insertData = dataList.filter(
									data =>
										data.address_depth_1 === k &&
										data.address_depth_2 === tmp_address_depth_2
								);
								
								tmp_key = k + '_' + tmp_address_depth_2;
							} else if ( 'kinder_list' === dataType ) {
								tmp_address_depth_2 = tmp_address_depth_2 + '_kinder_list';
								
								insertData = dataList.filter(
									data =>
										data.address_depth_1 === k &&
										data.address_depth_2 ===
											tmp_address_depth_2.replace('_kinder_list', '')
								);
								
								tmp_key = k + '_' + tmp_address_depth_2;
							} else {
								return;
							}
						}
						
					}
					
					if ( insertData.length > 0 ) {
						localStorage.setItem( tmp_key, JSON.stringify( insertData ) );
					} 
					
				}
			}
		}
	}
	
	
	displayDupMarker( place, dataType ) {
		let imageSrc = ''; // 마커이미지의 주소입니다
		
		const imageSize = new this.daum.maps.Size(52, 57); // 마커이미지의 크기입니다
		const imageOption = { offset: new this.daum.maps.Point(10, 50) }; // 마커이미지의 옵션입니다.   아까 이걸로 함
		
		if ( 'ulzip' === dataType ) {
			imageSrc = String(window.location.origin) + '/img/u_multi.png';
		} else if ( 'kinder' === dataType ) {
			imageSrc = String(window.location.origin) + '/img/k_mul.png';
		} else {
			console.log('data type error');
		}
		
		// 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
		const markerImage = new this.daum.maps.MarkerImage(imageSrc, imageSize, imageOption);
		
		// 마커를 생성하고 지도에 표시합니다
		return new this.daum.maps.Marker({
			map: ( 0 === place.getLat() && 0 === place.getLng() ) ? null : this.map,
			position: place,
			clickable: ( 0 === place.getLat() && 0 === place.getLng() ) ? false : true,
			//draggable: 0 === place.getLat() && 0 === place.getLng() ? false : true,
			draggable: false,
			//draggable: true,
			image: ( 0 === place.getLat() && 0 === place.getLng() ) ? null : markerImage //, // 마커이미지 설정
			//zIndex:2
		});
	}
	
	
	toggleMarkerLoading ( dataType ) {
		
		if ( "ulzip" === dataType ) {
			this.setState({
				markers_loading_ulzip : !this.state.markers_loading_ulzip
			});
		} else if ( "kinder" === dataType ) {
			this.setState({
				markers_loading_kinder : !this.state.markers_loading_kinder
			});
		} else {
			
		}
		
	}
	
	
	/**
	 *	열려있는 얼집 상세창을 닫아주는 메소드
	 *
	 *	얼집 상세창은 생성될 때 마다 고유의 id 값이 부여된다.
	 *	id 값을 생성 순서로 순차적으로 부여되며, 그 id 값은
	 *  this.clicked_marker_id_gen 클래수 내부 변수이다. state 변수는 아님!
	 *	상세창마다 고유하게 부여한 이 id 값을 파라미터로 받아 상세창 배열에서
	 *	해당 id값과 동일한 엘리먼트를 filter 배열함수를 통해 제거하면서 닫는다.
	 */
	closeSchoolInfoTab(tab_id) {
		
		let target_marker = null;
		
		for (let i = 0; i < this.state.clicked_markers.length; i++) {
			if (tab_id === this.state.clicked_markers[i].id) {
				target_marker = this.state.clicked_markers[i].info;
				
				if (
					'undefined' !== typeof target_marker.overlayInfo &&
					null !== target_marker.overlayInfo
				) {
					this.daum.maps.event.trigger(
						target_marker.overlayInfo,
						'display_custom_overlay',
						false
					);
				} else {
					if (
						null !== target_marker.parent_code &&
						undefined !== target_marker.parent_code &&
						'' !== target_marker.parent_code
					) {
						let parentMarker = null;
						const parent_code = target_marker.parent_code;
						
						//다중마커인 경우...
						if ('ulzip' === target_marker.data_type) {
							parentMarker = this.state.markers.filter(
								data => parent_code === data.ulzip_code
							);
						} else {
							parentMarker = this.state.kinder_markers.filter(
								data => parent_code === data.kinder_code
							);
						}
						
						this.daum.maps.event.trigger(
							parentMarker[0].overlayInfo,
							'display_custom_overlay',
							false
						);
					}
				}
				
				break;
			}
		}
		
		const result_arr = this.state.clicked_markers.filter(marker => marker.id !== tab_id);
		
		this.setState({
			clicked_markers: result_arr
		});
		
	}
	
	
	closeDupMarkersPanel(e) {
		this.setState({
			dup_markers_list: []
		});
	}
	
	
	clickInnerMarkerInDupMarkersList( code ) {
		//console.log( e.target.value );
		//e.preventDefault();
		
		let marker = null;
		let target_flag = false;
		
		//const target = e.target.value;
		const target = code;
		
		
		let dataType = '';
		
		if ( this.state.dup_markers_list.length <= 0 ) {
			return;
		} else {
			dataType = this.state.dup_markers_list[0].dataType;
		}
		
		for ( let i = 0 ; i < this.state.dup_markers_list.length ; i++ ) {
			if ( target === this.state.dup_markers_list[i].code ) {
				marker = this.state.dup_markers_list[i].marker;
				target_flag = true;
				break;
			}
		}
		//console.log( dataType );
		if ( false === target_flag ) {
			return false;
		} else {
			this.openSchoolInfoTab( marker, dataType );
			
			Event(
				'ACTION',
				dataType + ' Detail clicked',
				dataType + ' Detail clicked GPS DUP'
			);
		}
		
	}
	
	
	openMarkerFromAutoSearchResult(data) {
		//console.log( data );
		//console.log( this.state.open_marker_queue );
		if ( 'ulzip' === data.data_type ) {
			if ( false === this.openAutoKeywordUlzipMarker( data.ulzip_code ) ) {
				this.setState({
					open_marker_queue: this.state.open_marker_queue.concat([
						{
							type: 'ulzip',
							id: data.ulzip_code
						}
					])
				});
			}
		} else if ( 'kinder' === data.data_type ) {
			if ( false === this.openAutoKeywordKinderMarker( data.kinder_code ) ) {
				this.setState({
					open_marker_queue: this.state.open_marker_queue.concat([
						{
							type: 'kinder',
							id: data.kinder_code
						}
					])
				});
			}
		} else if ( 'kids_cafe' === data.data_type ) {
			
		} else {
			
		}
		
		//console.log( this.state.open_marker_queue );
		
		if ( true === this.state.favorite_panel_open ) {
			this.toggleFavoritePanel();
		}
	} 
	
	
	openAutoKeywordUlzipMarker( ulzip_code ) {
		let target_marker = null;
		const target_code = ulzip_code;

		for ( let i = 0 ; i < this.state.markers.length ; i++ ) {
			if (target_code === this.state.markers[i].ulzip_code) {
				target_marker = this.state.markers[i];

				break;
			} else {
				if (
					null		!==	this.state.markers[i].child_marker_list &&
					undefined	!==	this.state.markers[i].child_marker_list &&
					0			<	this.state.markers[i].child_marker_list.length
				) {
					for ( let j = 0 ; j < this.state.markers[i].child_marker_list.length ; j++ ) {
						if ( target_code === this.state.markers[i].child_marker_list[j].ulzip_code ) {
							target_marker = this.state.markers[i].child_marker_list[j];
							break;
						}
					}
					
					if ( null !== target_marker ) {
						break;
					}
				}
			}
		}
		
		//console.log( target_marker );
		
		if ( null === target_marker ) {
			return false;
		}
		
		this.moveCenterLoc(
			target_marker.address,
			target_marker.address,
			target_marker.latitude,
			target_marker.longitude
		);
		
		Event(
			'ACTION',
			target_marker.data_type + ' Detail clicked',
			target_marker.data_type + ' Detail clicked openAutoKeyword'
		);
		
		this.openSchoolInfoTab( target_marker, target_marker.data_type );
		
		return true;
	}
	
	
	openAutoKeywordKinderMarker( kinder_code ) {
		//console.log('openAutoKeywordKinderMarker');
		let target_marker = null;
		const target_code = kinder_code;

		for ( let i = 0 ; i < this.state.kinder_markers.length ; i++ ) {
			if ( target_code === this.state.kinder_markers[i].kinder_code ) {
				target_marker = this.state.kinder_markers[i];

				break;
			} else {
				if (
					null		!==	this.state.kinder_markers[i].child_marker_list &&
					undefined	!==	this.state.kinder_markers[i].child_marker_list &&
					0			<	this.state.kinder_markers[i].child_marker_list.length
				) {
					for (
						let j = 0 ;
						j < this.state.kinder_markers[i].child_marker_list.length ;
						j++
					) {
						if (
							target_code ===
							this.state.kinder_markers[i].child_marker_list[j].kinder_code
						) {
							target_marker = this.state.kinder_markers[i].child_marker_list[j];
							break;
						}
					}

					if ( null !== target_marker ) {
						break;
					}
				}
			}
		}

		if ( null === target_marker ) {
			return false;
		}

		this.moveCenterLoc(
			target_marker.address,
			target_marker.address,
			target_marker.latitude,
			target_marker.longitude
		);
		
		Event(
			'ACTION',
			target_marker.data_type + ' Detail clicked',
			target_marker.data_type + ' Detail clicked openAutoKeyword'
		);
		
		this.openSchoolInfoTab(target_marker, target_marker.data_type);
		
		return true;
	}
	
	
	openScheduledMarker ( markerType ) {
		const scheduledOpenMarker	= this.state.open_marker_queue.filter(
			( data ) => ( markerType === data.type )
		);
		
		//console.log( scheduledOpenMarker );
		
		if (
			null		!==	scheduledOpenMarker &&
			undefined	!==	scheduledOpenMarker &&
			0			<	scheduledOpenMarker.length
		) {
			for ( let i = 0 ; i < scheduledOpenMarker.length ; i++ ) {
				
				let scheduledOpenMarkerResult	= false;
				
				if ( "ulzip" === markerType ) {
					scheduledOpenMarkerResult	= this.openAutoKeywordUlzipMarker( scheduledOpenMarker[i].id );
				} else if ( "kinder" === markerType ) {
					scheduledOpenMarkerResult	= this.openAutoKeywordKinderMarker(scheduledOpenMarker[i].id);
				} else if ( "kids_cafe" === markerType ) {
				} else {
					
				}
				
				//console.log( scheduledOpenMarkerResult );
				
				if ( true === scheduledOpenMarkerResult ) {
					this.dequeueMarkerAtOpenMarkerQueue( scheduledOpenMarker[i].id );
				}
			}
		}
	}
	
	
	dequeueMarkerAtOpenMarkerQueue(id) {
		const open_marker_queue = this.state.open_marker_queue.filter(data => data.id !== id);
		
		this.setState({
			open_marker_queue: open_marker_queue
		});
	}
	
	
	openSchoolInfoTab ( marker, dataType ) {
		
		const query	= queryString.parse( this.props.location.search );
		
		//console.log( marker );
		//console.log( dataType );
		
		if ( 'ulzip' === dataType ) {
			
			if (
				query.type	=== dataType	&&
				query.id_	=== marker.ulzip_code
			) {
				this.createErrorMsg(
					"이미 선택하신 '어린이집'이에요! ^^",
					"warning"
				);
				return false;
			}
			
			if (
				true	=== isMobileOnly	||
				true	=== isTablet		||
				true	===	isIPad13
			) {
				this.props.history.push( 
					'/m/Marker/?type=' + dataType + 
					'&id_=' + marker.ulzip_code + 
					'&lat=' + marker.latitude + 
					'&lon=' + marker.longitude +
					'&addressDepth1=' + marker.address_depth_1 +
					'&addressDepth2=' + marker.address_depth_2
				);
			} else {
				this.props.history.push( 
					'/Marker/?type=' + dataType + 
					'&id_=' + marker.ulzip_code + 
					'&lat=' + marker.latitude + 
					'&lon=' + marker.longitude +
					'&addressDepth1=' + marker.address_depth_1 +
					'&addressDepth2=' + marker.address_depth_2
				);
			}
			
		} else if ( 'kinder' === dataType ) { 
			if (
				query.type	=== dataType	&&
				query.id_	=== marker.kinder_code
			) {
				this.createErrorMsg(
					"이미 선택하신 '유치원'이에요! ^^",
					"warning"
				);
				return false;
			}
			
			if (
				true	=== isMobileOnly	||
				true	=== isTablet		||
				true	===	isIPad13
			) {
				this.props.history.push( 
					'/m/Marker/?type=' + dataType + 
					'&id_=' + marker.kinder_code + 
					'&lat=' + marker.latitude + 
					'&lon=' + marker.longitude +
					'&addressDepth1=' + marker.address_depth_1 +
					'&addressDepth2=' + marker.address_depth_2
				);
			} else {
				this.props.history.push( 
					'/Marker/?type=' + dataType + 
					'&id_=' + marker.kinder_code + 
					'&lat=' + marker.latitude + 
					'&lon=' + marker.longitude +
					'&addressDepth1=' + marker.address_depth_1 +
					'&addressDepth2=' + marker.address_depth_2
				);
			}
			
		} else if ( 'kids_cafe' === dataType ) {
			
		} else {
			
		}
		
		this.searchResultClose();
		
		this.map.setCenter(
			new this.daum.maps.LatLng(marker.latitude, marker.longitude)
		);
		
		this.closeControlPanel();
		
	}
	
	
	closeChangeNickNamePopUp ( ) {
		
		this.setState({
			userInfo:{
				...this.state.userInfo,
				nicknameConfirmYN:true,
			}
		});
		
	}
	
	
	
	render ( ) {
		
		//console.log( this.props.favoriteData );
		//console.log( this.props.userInfo );
		//console.log( this.state.userInfo );
		//console.log( this.props.currentPage );
		
		if ( 
			true === this.state.isRedirectYN	||
			(
				true	=== isMobileOnly	||
				true	=== isTablet		||
				true	===	isIPad13
			)
		) {
			return null;
		}
		
		return (
			
			<Fragment>
				
				<CustomAlert
					open={
						undefined	!==	this.state.errorMsg		&&
						null		!==	this.state.errorMsg		&&
						0			<	this.state.errorMsg.length
					}
					data={this.state.errorMsg} 
					//option={this.state.errorMsgOption}
					option={this.state.errorMsgType}
					closeAlert={this.closeAlert} 
				/>
				
				<Notice 
					open={this.state.release_notice_yn}
					in={this.state.release_notice_yn}
					mode={this.state.mode}
					value={this.state.today_no_show}
					toggleTodayNoShow={this.toggleTodayNoShow}
					closeReleaseNoticeYN={this.closeReleaseNoticeYN}
				/>
				
				<Suggestions
					isOpen={this.state.isSuggestionsOpen}
					toggleSuggestions={this.toggleSuggestions}
					createErrorMsg={this.createErrorMsg}
				/>
				
				<MarkerGpsDupList
					isOpen={
						null	!==	this.state.dup_markers_list			&&
						0		<	this.state.dup_markers_list.length 
					}
					data={this.state.dup_markers_list}
					closeDupMarkersPanel={this.closeDupMarkersPanel}
					clickInnerMarkerInDupMarkersList={this.clickInnerMarkerInDupMarkersList}
				/>
				
				<ChangeNickNamePopUp
					isOpen={ ( false === this.state.userInfo.nicknameConfirmYN ) ? true : false }
					userNickName={this.state.userInfo.userNickName}
					
					closeChangeNickNamePopUp={this.closeChangeNickNamePopUp}
					getLoginInfo={this.getUserLoginInfo}
					createErrorMsg={this.createErrorMsg}
				/>
				
				{
					//<CssBaseline />
				}
				
				<
				//Container
				div
					
					//maxWidth="xl"
					
					style={
						(
							true	=== isMobileOnly	||
							true	=== isTablet		||
							true	===	isIPad13	
						) ?
							(
								{
									position:"fixed",
									
									top:0,
									left:0,
									height:"calc( 100vh - 3rem )",
									
									margin:0,
									padding:0,
									
									overflow:"hidden",
								}
							) :
							(
								{
									position:"fixed",
									
									top:0,
									right:0,
									width:"calc( 100vw - 3.75rem )",
									height:"100vh",
									
									margin:0,
									padding:0,
									
									overflow:"hidden",
								}
							)
					}
				>
					{
						( 
							true	=== isMobileOnly	||
							true	=== isTablet		||
							true	===	isIPad13
						) ?
							(
								<MenuBarMobile 
									searchText={this.state.place_search_value}
									searchKeywordSubmit={this.placeSearchKeywordSubmit}
									searchKeywordChange={this.placeSearchKeywordChange}
									clearSearchKeyword={this.clearSearchKeyword}
									
									
									numOfCurrentLocIllegal={this.state.num_of_current_loc_illegal}
									toggleIllegalInfo={this.toggleIllegalInfo}
									illegalInfoOpen={this.state.illegalInfoOpen}
								/>
							) :
							(
								null
							)
					}
					
					
					<div
						ref={this.mapWrap}
						
						style={
							(
								true	=== isMobileOnly	||
								true	=== isTablet		||
								true	===	isIPad13	
							) ?
								(
									{
										width:"100%",
										//height:"calc( 100% - 3.5rem )",
										//height:"calc( 100% - 3.25rem )",
										height:"calc( 100% - 3rem )",
										
										margin:0,
										padding:0,
										zIndex:"1",
									}
								) :
								(
									{
										width:"100%",
										//height:"calc( 100% - 3.5rem )",
										//height:"calc( 100% - 3.25rem )",
										//height:"calc( 100% - 3rem )",
										height:"100%",
										
										margin:0,
										padding:0,
										zIndex:"1",
										
										//overflow:"hidden",
									}
								)
						}
					>
						
						<MenuBar
							searchText={this.state.place_search_value}
							searchKeywordSubmit={this.placeSearchKeywordSubmit}
							searchKeywordChange={this.placeSearchKeywordChange}
							clearSearchKeyword={this.clearSearchKeyword}
							
							
							numOfCurrentLocIllegal={this.state.num_of_current_loc_illegal}
							toggleIllegalInfo={this.toggleIllegalInfo}
							illegalInfoOpen={this.state.illegalInfoOpen}
							illegalData={this.state.illegal_ulzip_list_all}
							
							currentAddressDepth1={ this.sidoConverter(this.state.current_address_depth_1)}
							currentAddressDepth2={this.state.current_address_depth_2}
							currentLocationName={this.state.current_location_name}
							setNumOfCurLocIllegalUlzip={this.setNumOfCurLocIllegalUlzip}
							openMarkerFromAutoSearchResult={this.openMarkerFromAutoSearchResult}
							moveCenterLoc={this.moveCenterLoc}
							
							
							isPlaceListOpen={
								( this.state.place_list.length > 0 ) ? 
									true : false
							}
							PlaceListData={this.state.place_list}
							pages={this.state.pages}
							searchResultClose={this.searchResultClose}
							
							
							isAutoSearchKeywordOpen={
								(
									0	>=	this.state.place_list.length			&&
									2	<	this.state.place_search_value.length
								) ? (
									true
								) : (
									false
								)
							}
							targetWords={this.state.place_search_value}
							
							geocoder={this.geocoder}
							daum={this.daum}
							toggleAutoSearchExistsYN={this.toggleAutoSearchExistsYN}
						/>
						
						<FilterPanel
							isOpen={this.state.filter_panel_open}
							createErrorMsg={this.createErrorMsg}
							filteringMarkerByType={this.filteringMarkerByType}
							filteringKinderMarkerByType={this.filteringKinderMarkerByType}
							
							toggleFilterPanel={this.toggleFilterPanel}
						/>
						
						<UserFavorite
							createErrorMsg={this.createErrorMsg}
							toggleFavoritePanel={this.toggleFavoritePanel}
							openMarkerFromAutoSearchResult={this.openMarkerFromAutoSearchResult}
							moveCenterLoc={this.moveCenterLoc}
							
							getUserFavorite={this.getUserFavorite}
							isOpen={this.state.favorite_panel_open}
							loginYN={this.state.userInfo.loginYN}
						/>
						
						<ControlMapPanel
							magnifyMap={this.magnifyMap}
							minifyMap={this.minifyMap}
							currentWatchingLocation={this.state.current_location_name}
							moveToUserLocation={this.moveToUserLocation}
						/>
						
						<SideBar 
							toggleFilterPanel={this.toggleFilterPanel}
							toggleSuggestions={this.toggleSuggestions}
							toggleFavoritePanel={this.toggleFavoritePanel}
							closeControlPanel={this.closeControlPanel}
						/>
						
						<CircularProgress 
							className={
								clsx(
									(
										true	=== this.state.markers_loading_ulzip	||
										true	=== this.state.markers_loading_kinder
										//true
									) ?
										"spin_center" : "hide",
								)
							}
							
							
							size={
								"3rem"
							}
							
							style={
								(
									true	=== this.state.markers_loading_ulzip	||
									true	=== this.state.markers_loading_kinder
									//true
								) ?
									{
										zIndex:"2",
									} : 
									{
										display:"none"
									}
							}
						/>
						
						<DaumMap
							apiKey="d9406dc12d2fb65de2369f01a413e03c"
							//apiKey="e991411ca8309d73b139dd6f93561f99"
							mapId={'daum-map'}
							render={this.customRender}
						/>
						
					</div>
					
				</
					//Container
					div
				>
				
			</Fragment>
			
		
		);
	}
}


//export default IPickHome;


// props 로 넣어줄 스토어 상태값
function mapStateToProps ( state ) {
	return {
		userInfo	: state.userAuthentication.userInfo,
		favoriteData: state.userFavoriteManager.favoriteData,
		currentPage	: state.currentPageSync.currentPage
	};
}

// props 로 넣어줄 액션 생성함수
function mapDispatchToProps( dispatch ) {
	return {
		actions: {
			login: bindActionCreators( login, dispatch ),
			loginSuccess: bindActionCreators( userInfo => ( loginSuccess( userInfo ) ), dispatch ),
			loginFailure: bindActionCreators( loginFailure, dispatch ),
			logout: bindActionCreators( logout, dispatch ),
			logoutSuccess: bindActionCreators( logoutSuccess, dispatch ),
			logoutFailure: bindActionCreators( logoutFailure, dispatch ),
			
			getUserFavorite				: bindActionCreators( getUserFavorite, dispatch ),
			getUserFavoriteSuccess		: bindActionCreators( favoriteData => ( getUserFavoriteSuccess( favoriteData ) ), dispatch ),
			getUserFavoriteFailure		: bindActionCreators( getUserFavoriteFailure, dispatch ),
			addUserFavorite				: bindActionCreators( addUserFavorite, dispatch ),
			//addUserFavoriteSuccess		: bindActionCreators( favoriteData => ( addUserFavoriteSuccess( favoriteData ) ), dispatch ),
			addUserFavoriteSuccess		: bindActionCreators( addUserFavoriteSuccess, dispatch ),
			addUserFavoriteFailure		: bindActionCreators( addUserFavoriteFailure, dispatch ),
			removeUserFavorite			: bindActionCreators( removeUserFavorite, dispatch ),
			removeUserFavoriteSuccess	: bindActionCreators( favoriteData => ( removeUserFavoriteSuccess( favoriteData ) ), dispatch ),
			removeUserFavoriteFailure	: bindActionCreators( removeUserFavoriteFailure, dispatch ),
			
			viewHome: bindActionCreators(viewHome, dispatch),
			viewCOMMUNITY: bindActionCreators(viewCOMMUNITY, dispatch),
			viewITUBE: bindActionCreators(viewITUBE, dispatch),
			viewPROFILE: bindActionCreators(viewPROFILE, dispatch),
		},
	};
}

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( IPickHome );