import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { //isMobile, 
	//isIOS, 
	isMobileOnly, 
	isTablet,
	//isAndroid, 
	//isBrowser,
	isIPad13, 
} from 'react-device-detect';

//import { 
//	ThemeProvider 
//} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Box,
	ButtonGroup,
	Slide,
	Divider,
} from '@material-ui/core';

import {
	Close		as CloseIcon,
	UnfoldLess	as UnfoldLessIcon
} from '@material-ui/icons';

import Place	from './Place.js';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	padding2:{
		padding:theme.spacing(2),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	margin2:{
		margin:theme.spacing(2),
	},
	margin3:{
		margin:theme.spacing(3),
	},
	textCenter:{
		textAlign:"center",
	},
	textRight:{
		textAlign:"right",	
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	
	//success: {
	//	backgroundColor: green[600],
	//	color:theme.palette.primary.contrastText
	//},
	//successOpposition: {
	//	backgroundColor: theme.palette.primary.contrastText,
	//	color:green[600]
	//},
	//primary:{
	//	backgroundColor: theme.palette.primary.main,
	//	color:theme.palette.primary.contrastText
	//},
	//primaryOpposition:{
	//	backgroundColor: theme.palette.primary.contrastText,
	//	color:theme.palette.primary.main
	//},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
}));


export default function PlaceList ( props ) {
	
	const { data, pages }	= props;
	const classes	 		= useStyles();
	
	const search_result_list = data.map(
		( info, id ) => ( 
			<Fragment
				key={id.toString() + "_place"} 
			>
				<Place 
					info={info.info} 
					moveCenterLoc={props.moveCenterLoc} 
				/> 
				<Divider />
			</Fragment>
		)
	);
	
	
	//console.log( data );
	//console.log( pages );
	
	
	const search_result_pages = pages.map(
		( page, id ) => ( 
			<Button
				key={id.toString() + "_page_btn"} 
				href="#"
				tabIndex={page.page_num}
				
				onClick={page.onClickMethod}
				
				style={
					( "on" === page.className ) ?
						{
							color:"white",
							backgroundColor:"#3f51b5",
						} : null
				}
			>
				{ page.page_num }
			</Button>
		)
    );
	
	
	return (
		
		<Fragment>
			<Slide
				direction={
					( isMobileOnly ) ?
						"down" : 
						//"right"
						"left"
				}
				in={props.isOpen} 
				//mountOnEnter
				//unmountOnExit
			>
				
				<Container
					maxWidth="xs"
					
					style={
						( 
							true	=== isMobileOnly	||
							true	=== isTablet		||
							true	===	isIPad13
						) ?
							{
								position:"fixed",
								right:0,
								
								zIndex:"3",
								margin:0,
								padding:0,
								//maxHeight:"calc( 100% - 8rem )",
								maxHeight:"calc( 100% - 6rem )",
								//maxHeight:" 100%",
								
								
								overflow:"hidden",
								display:"flex",
  								flexDirection:"column",
								
								borderRadius:"0.25rem",
								backgroundColor:"white"
							} :
							{
								position:"relative",
								zIndex:"3",
								margin:0,
								padding:0,
								
								//maxHeight:"calc( 97vh - 16px - 2rem - 3rem )",
								maxHeight:"calc( 97vh - 24px - 2rem - 3rem )",
								
								//overflowY:"scroll",
								overflow:"hidden",
								display:"flex",
  								flexDirection:"column",	
								
								borderRadius:"0.25rem",
								//backgroundColor:"white"
							}
					}
				>
					<Container
						maxWidth="xs"
						
						className={
							
							( 
								true	=== isMobileOnly	||
								true	=== isTablet		||
								true	===	isIPad13
							) ?
								
								clsx(
									classes.noGutters,
									classes.overflowYScroll,
									classes.grow
								) :
								clsx(
									classes.noGutters,
									classes.overflowYScroll,
									classes.grow
								)
						}
					>
						{
							search_result_list
						}
					</Container>
					
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						
						className={
							clsx(
								classes.marginY,
								classes.shrink,
							)
						}
					>
						<Grid
							item
							
							xs={2}
							sm={2}
							md={2}
							lg={2}
							xl={2}
							
							className={
								clsx(
									classes.textCenter
								)
							}
						/>
						<Grid
							item
							
							xs={8}
							sm={8}
							md={8}
							lg={8}
							xl={8}
							
							className={
								clsx(
									classes.textCenter
								)
							}
						>
							<ButtonGroup 
								size="small" 
								aria-label="small outlined button group"
							>
								{ search_result_pages }
							</ButtonGroup>
						</Grid>
						<Grid
							item
							
							xs={2}
							sm={2}
							md={2}
							lg={2}
							xl={2}
							
							className={
								clsx(
									classes.textCenter
								)
							}
							
							style={{
								lineHeight:"100%",
							}}
						>
							<IconButton
								className={
									clsx(
										classes.button,
										classes.noGutters,
									)
								}
								
								size="small"
								
								onClick={()=>{props.searchResultClose();}}
							>
								<UnfoldLessIcon
									//CloseIcon
									fontSize="small"
								/>
							</IconButton>
						</Grid>
					</Grid>
				</Container>
			</Slide>
		</Fragment>
		
	);
}