import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';

import { connect } from 'react-redux';

import clsx from 'clsx';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser, isIPad13 } from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	AppBar,
	Toolbar,
	Box,
	Container,
	Grid,
	IconButton,
	Typography,
	Slide,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon		from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
	Favorite		as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
	MoreVert		as MoreVertIcon,
	Share			as ShareIcon,	// PC share
	MobileScreenShare	as MobileScreenShareIcon,	// mobile share
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
		//width:"100%",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	avatar: {
		backgroundColor: red[500],
 	},
}));




function IPickPageTitle ( props ) {
	
	const classes	= useStyles();
	
	//console.log( props.buttonList );
	//console.log( props.zIndex );
	//console.log( props.currentPage );
	//String( props.zIndex !== props.currentPage.depth )
	
	
	return (
		<Fragment>
			<Slide 
				appear={false} 
				direction="down" 
				//direction="up" 
				in={!props.scrollTrigger}
			>
				<AppBar
					position="relative"
					
					className={
						clsx(
							classes.noGutters,
							classes.topMenuBar,
							classes.bgWhite,
						)
					}
					
					style={
						//{
						//	zIndex:props.zIndex
						//}
						( props.zIndex !== props.currentPage.depth ) ?
							{
								display:"none"
							} : {
								zIndex:props.zIndex
							}
					}
				>
					<Toolbar
						className={
							clsx(
								classes.topMenuBarList,
							)
						}
						
						disableGutters={true}
					>
						<Grid
							container
							direction="row"
							//justify="center"
							justify="flex-start"
							alignItems="center"
							
						>
							<Grid
								item
								xs={2}
								sm={2}
								md={2}
								lg={2}
								xl={2}
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								<ThemeProvider
									theme={markerInfoTheme}
								>
									<IconButton
										className={
											clsx(
												classes.smallButton,
											)
										}
										
										//size="medium"
										color={
											( "ulzip" === props.type ) ?
												"secondary" :
												(
													( "kinder" === props.type ) ?
														"primary" : ""
												)
										}
										
										onClick={
											(e) => {
												
												if ( false === props.closeButtonShowYN ) {
													
												} else {
													props.closePage(e);
												}
												
											}
										}
									>
										<ChevronLeftIcon
											//color={bgColor}
											fontSize="large"
											
											style={
												( false === props.closeButtonShowYN ) ?
													(
														{
															visibility: "hidden",
														}
													) :
													(
														null
													)
											}
										/>
									</IconButton>
								</ThemeProvider>
							</Grid>
							
							{
								( true === props.pageTitleOnly ) ?
									(
										<Fragment>
											<Grid
												item
												xs={8}
												sm={8}
												md={8}
												lg={8}
												xl={8}
												
												className={
													clsx(
														classes.textCenter,
													)
												}
											>
												<Typography 
													variant="h6" 
													//component="h3"
													color={"textPrimary"}
												>
													{
														props.pageTitle
													}
												</Typography>
											</Grid>
											<Grid
												item
												xs={2}
												sm={2}
												md={2}
												lg={2}
												xl={2}
												
												className={
													clsx(
														classes.textCenter,
													)
												}
											/>
										</Fragment>
									) :
									(
										(
											true	===	Array.isArray( props.buttonList )	&&
											0		<	props.buttonList.length
										) ?
											(
												//{
												//	type		: this.state.type,
												//	buttonType	: "binary",
												//	dataType	: "binary",
												//	
												//	data		: this.state.favoriteYN,
												//	function	: [
												//		{
												//			funName		: "clickFavoriteBtn",
												//			function	: this.clickFavoriteBtn
												//		}
												//	],
												//	
												//	icon		: [
												//		{
												//			type 	: "binary",
												//			value	: true,
												//			icon	: (
												//				<FavoriteIcon 
												//					
												//				/> 
												//			),
												//		},
												//		{
												//			type 	: "binary",
												//			value	: false,
												//			icon	: (
												//				<FavoriteBorderIcon 
												//					
												//				/> 
												//			),
												//		},
												//	],
												//}
												props.buttonList.map( ( button, idx ) => {
													if ( "binary" === button.buttonType ) {
														return (
															<Fragment
																key={"ipick_page_title_button_" + idx.toString()}
															>
																<Grid
																	item
																	xs={2}
																	sm={2}
																	md={2}
																	lg={2}
																	xl={2}
																	
																	className={
																		clsx(
																			classes.textCenter
																		)
																	}
																>
																	<ThemeProvider
																		theme={markerInfoTheme}
																	>
																		<IconButton
																			className={
																				clsx(
																					classes.smallButton,
																					classes.marginLeft4X,
																				)
																			}
																			
																			color={
																				( "ulzip" === button.type ) ?
																					"secondary" :
																					(
																						( "kinder" === button.type ) ?
																							"primary" : ""
																					)
																			}
																			
																			onClick={button.function[0].function}
																		>
																			{
																				( true === button.data ) ?
																					(
																						button.icon[0].icon
																					) :
																					(
																						button.icon[1].icon
																					)
																			}
																		</IconButton>
																	</ThemeProvider>
																</Grid>
															</Fragment>
														);
													} else if ( "link" === button.buttonType ) {
														return (
															<Fragment
																key={"ipick_page_title_button_" + idx.toString()}
															>
																<Grid
																	item
																	xs={2}
																	sm={2}
																	md={2}
																	lg={2}
																	xl={2}
																	
																	className={
																		clsx(
																			classes.textCenter
																		)
																	}
																>
																	<ThemeProvider
																			theme={markerInfoTheme}
																		>
																			<IconButton
																				className={
																					clsx(
																						classes.smallButton,
																						classes.marginLeft4X,
																					)
																				}
																				
																				color={
																					( "ulzip" === button.type ) ?
																						"secondary" :
																						(
																							( "kinder" === button.type ) ?
																								"primary" : ""
																						)
																				}
																				
																				component="a"
																				target="_blank"
																				rel="noopener noreferrer"
																				href={button.data}
																			>
																				{
																					button.icon[0].icon
																				}
																			</IconButton>
																		</ThemeProvider>
																</Grid>
															</Fragment>
														);
													} else {
														return (
															<Fragment
																key={"ipick_page_title_button_" + idx.toString()}
															>
																<Grid
																	item
																	xs={2}
																	sm={2}
																	md={2}
																	lg={2}
																	xl={2}
																	
																	className={
																		clsx(
																			classes.textCenter
																		)
																	}
																>
																	<ThemeProvider
																			theme={markerInfoTheme}
																		>
																			<IconButton
																				className={
																					clsx(
																						classes.smallButton,
																						classes.marginLeft4X,
																					)
																				}
																				
																				color={
																					( "ulzip" === button.type ) ?
																						"secondary" :
																						(
																							( "kinder" === button.type ) ?
																								"primary" : ""
																						)
																				}
																				
																				onClick={button.function[0].function}
																			>
																				{ button.icon[0] }
																			</IconButton>
																		</ThemeProvider>
																</Grid>
															</Fragment>
														);
													}
												})
												
												//props.buttonList.map( ( button, idx ) => {
												//		if ( true === button.isBinary ) {
												//			return (
												//				<Fragment
												//					key={"ipick_page_title_button_" + idx.toString()}
												//				>
												//					<Grid
												//						item
												//						xs={2}
												//						sm={2}
												//						md={2}
												//						lg={2}
												//						xl={2}
												//						
												//						className={
												//							clsx(
												//								classes.textCenter
												//							)
												//						}
												//					>
												//						<ThemeProvider
												//								theme={markerInfoTheme}
												//							>
												//								<IconButton
												//									className={
												//										clsx(
												//											classes.smallButton,
												//											classes.marginLeft4X,
												//										)
												//									}
												//									
												//									color={
												//										( "ulzip" === button.type ) ?
												//											"secondary" :
												//											(
												//												( "kinder" === button.type ) ?
												//													"primary" : ""
												//											)
												//									}
												//									
												//									onClick={button.toggleFunction}
												//								>
												//									{
												//										( true === button.binaryValue ) ?
												//											(
												//												button.iconY
												//											) :
												//											(
												//												button.iconN
												//											)
												//									}
												//								</IconButton>
												//							</ThemeProvider>
												//					</Grid>
												//				</Fragment>
												//			);
												//		} else {
												//			return (
												//				<Fragment
												//					key={"ipick_page_title_button_" + idx.toString()}
												//				>
												//					<Grid
												//						item
												//						xs={2}
												//						sm={2}
												//						md={2}
												//						lg={2}
												//						xl={2}
												//						
												//						className={
												//							clsx(
												//								classes.textCenter
												//							)
												//						}
												//					>
												//						<ThemeProvider
												//								theme={markerInfoTheme}
												//							>
												//								<IconButton
												//									className={
												//										clsx(
												//											classes.smallButton,
												//											classes.marginLeft4X,
												//										)
												//									}
												//									
												//									color={
												//										( "ulzip" === button.type ) ?
												//											"secondary" :
												//											(
												//												( "kinder" === button.type ) ?
												//													"primary" : ""
												//											)
												//									}
												//									
												//									onClick={button.function}
												//								>
												//									{ button.icon }
												//								</IconButton>
												//							</ThemeProvider>
												//					</Grid>
												//				</Fragment>
												//			);
												//		}
												//		
												//	}
												//)
											) : null
									)
							}
							
						</Grid>
					</Toolbar>
				</AppBar>
			</Slide>
			
			<Slide 
				appear={false} 
				direction="up" 
				//direction="down" 
				in={props.scrollTrigger}
			>
				<AppBar
					className={
						clsx(
							classes.noGutters,
							classes.topMenuBar,
							classes.bgWhite,
						)
					}
					
					style={{
						zIndex:props.zIndex,
						//zIndex:
						//	( props.zIndex < values.currentPageDepth ) ?
						//		-1 : props.zIndex,
						
						left: 
							(  
								true	===	isMobileOnly	||
								true	===	isTablet		||
								true	===	isIPad13
							) ?
								0 : "3.75rem",
						
						maxWidth:
							( "sm" === props.maxWidth ) ?
								"600px" : "444px"
						,
					}}
				>
					<Toolbar
						className={
							clsx(
								classes.topMenuBarList,
							)
						}
						
						disableGutters={true}
						
						style={
							( props.zIndex !== props.currentPage.depth ) ?
								{
									display:"none"
								} : null
						}
					>
						<ThemeProvider
							theme={markerInfoTheme}
						>
							<Box
								bgcolor={
									( "ulzip" === props.type ) ?
										"secondary.main" :
										(
											( "kinder" === props.type ) ?
												"primary.main" : ""
										)
								}
								
								color={
									( "ulzip" === props.type ) ?
										"secondary.contrastText" :
										(
											( "kinder" === props.type ) ?
												"primary.contrastText" : ""
										)
								}
								
								flexDirection="row"
								style={{
									width:"100%",
									height:"100%",
								}}
							>
								
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
									
									style={{
										//width:"100%",
										height:"100%",
									}}
								>
									<Grid
										item
										xs={2}
										sm={2}
										md={2}
										lg={2}
										xl={2}
										
										className={
											clsx(
												classes.textCenter
											)
										}
									>
										<ThemeProvider
											theme={markerInfoTheme}
										>
											<Box
												className={
													clsx(
														classes.noGutters,
													)
												}
												
												clone={true}
												
												color={
													( "ulzip" === props.type ) ?
														"secondary.dark" :
														(
															( "kinder" === props.type ) ?
																"primary.dark" : ""
														)
												}
											>
												<IconButton
													className={
														clsx(
															classes.smallButton,
															classes.textCenter,
														)
													}
													
													//size="medium"
													color={
														"inherit"
														//( "ulzip" === props.type ) ?
														//	"secondary.dark" :
														//	(
														//		( "kinder" === props.type ) ?
														//			"primary.dark" : ""
														//	)
													}
													
													onClick={
														(e) => {
															if ( false === props.closeButtonShowYN ) {
																
															} else {
																props.closePage(e);
															}
														}
													}
												>
													<ChevronLeftIcon
														//color={bgColor}
														fontSize="large"
														
														style={
															( false === props.closeButtonShowYN ) ?
																(
																	{
																		visibility: "hidden",
																	}
																) :
																(
																	null
																)
														}
													/>
												</IconButton>
											</Box>
										</ThemeProvider>
									</Grid>
									<Grid
										item
										
										xs={8}
										sm={8}
										md={8}
										lg={8}
										xl={8}
										
										className={
											clsx(
												classes.textCenter,
											)
										}
									>
										<Typography 
											variant="h6" 
											//component="h3"
											
											
										>
											{
												( 
													null		=== props.pageTitle	||
													undefined	=== props.pageTitle	||
													""			=== props.pageTitle
												) ? 
													(
														""
													) :
													(
														( props.pageTitle.length > 12  ) ?
															(
																props.pageTitle.replace('어린이집', '').replace('유치원', '')
															) : (
																props.pageTitle
															)
													)
											}
										</Typography>
									</Grid>
									<Grid
										item
										
										xs={2}
										sm={2}
										md={2}
										lg={2}
										xl={2}
									/>
								</Grid>
							</Box>
						</ThemeProvider>
					</Toolbar>
				</AppBar>
			</Slide>
			
		</Fragment>
	);
	
}

// props 로 넣어줄 스토어 상태값
const mapStateToProps = state => ({
	currentPage: state.currentPageSync.currentPage
});


// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps
)( IPickPageTitle );