import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { 
	//isMobile, isIOS, 
	isMobileOnly, 
	isTablet,
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	//Container,
	Typography,
	Grid,
	Button,
	//IconButton,
	Box,
	//ButtonGroup,
	//Slide,
} from '@material-ui/core';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingLeft2X:{
		paddingLeft: theme.spacing(2),
	},
	paddingRight2X:{
		paddingRight: theme.spacing(2),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
}));



export default function MarkerKinder ( props ) {
	
	const classes	= useStyles();

	
	let curriculumCostBasic		= null;
	let curriculumCostOption	= null;
	
	let afterSchoolCostBasic	= null;
	let afterSchoolCostOption	= null;
	
	let specialActivity		= null;
	let specialActivity3	= null;
	let specialActivity4	= null;
	let specialActivity5	= null;
	
	let authUrl				= null;
	let authInfoList	= null;
	
	//console.log( props );
	
	
	if(
		undefined	!==	props.data.costInfo	&&
		null		!==	props.data.costInfo
	) {
		
		if( 
			undefined	!==	props.data.costInfo.curriculumCost	&&
			null		!==	props.data.costInfo.curriculumCost	&&
			0			<	props.data.costInfo.curriculumCost.length
		) {
			curriculumCostBasic	= props.data.costInfo.curriculumCost.map( 
				( data, idx ) => {
					
					//console.log( data );
					
					if( 0 === idx ) {
						
						return (
							<Grid
								key={props.data.costInfo._id + idx.toString() + "basic_fee"}
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.paddingSmallY,
										classes.borderBottom,
									)
								}
							>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										//"항 목(기본)"
										"항 목"
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[2]
									}
								
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[3]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[4]
									}
								</Grid>
							</Grid>
						);
						
					} else {
						
						if( "기본경비" !== data[0] ) {
							return null;
						}
						
						return (
							<Grid
								key={props.data.costInfo._id + idx.toString() + "basic_fee"}
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.paddingSmallY,
									)
								}
							>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												data[1].substr( 0, 6 )
											) :
											(
												data[1]
											)
									}
									
									
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												<br/>
											) :
											(
												null
											)
									}
									
									
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												data[1].substr( 6 )
											) :
											(
												null
											)
									}
									
									
									{
										( 
											null		===	data[5]	||
											undefined	===	data[5]	||
											0			>=	data[5].length
										) ? 
											null : 
											(
												<br/>
											)
									}
									{
										( 
                                            
                                            '-'		===	data[5]	||
											null		===	data[5]	||
											undefined	===	data[5]	||
											0			>=	data[5].length
										) ? 
											null : 
											(
												"(" + data[5] + ")"
											)
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[2]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[3]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[4]
									}
								</Grid>
							</Grid>
						);
						
					}
					
				}
			);
			
			
			curriculumCostBasic	= curriculumCostBasic.filter(
				( data ) => ( null !== data )
			);
			
			
			curriculumCostOption	= props.data.costInfo.curriculumCost.map( 
				( data, idx ) => {
					
					if( 0 === idx ) {
						
						return (
							<Grid
								key={props.data.costInfo._id + idx.toString() + "option_fee"}
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.paddingSmallY,
										classes.borderBottom,
									)
								}
							>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										//"항 목(선택)"
										"항 목"
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[2]
									}
								
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[3]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[4]
									}
								</Grid>
							</Grid>
						);
						
					} else {
						
						if( "선택경비" !== data[0] ) {
							return null;
						}
						
						return (
							<Grid
								key={props.data.costInfo._id + idx.toString() + "option_fee"}
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.paddingSmallY,
									)
								}
							>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												data[1].substr( 0, 6 )
											) :
											(
												data[1]
											)
									}
									
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												<br/>
											) :
											(
												null
											)
									}
									
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												data[1].substr( 6 )
											) :
											(
												null
											)
									}
									
									{
										( 
											null		===	data[5]	||
											undefined	===	data[5]	||
											0			>=	data[5].length
										) ? 
											null : 
											(
												<br/>
											)
									}
									
									{
										( 
                                            '-'		===	data[5]	||
											null		===	data[5]	||
											undefined	===	data[5]	||
											0			>=	data[5].length
										) ? 
											null : 
											(
												"(" + data[5] + ")"
											)
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[2]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[3]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[4]
									}
								</Grid>
							</Grid>
						);
						
					}
					
				}
			);
			
			
			curriculumCostOption	= curriculumCostOption.filter(
				( data ) => ( null !== data )
			);
			
		}
		
		if(
			undefined	!==	props.data.costInfo.afterSchoolCost	&&
			null		!==	props.data.costInfo.afterSchoolCost	&&
			0			<	props.data.costInfo.afterSchoolCost.length
		) {
			afterSchoolCostBasic	= props.data.costInfo.afterSchoolCost.map( 
				( data, idx ) => {
					
					//console.log( data );
					
					if( 0 === idx ) {
						
						return (
							<Grid
								key={props.data.costInfo._id + idx.toString() + "basic_fee_after"}
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.paddingSmallY,
										classes.borderBottom,
									)
								}
							>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										//"항 목(기본)"
										"항 목"
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[2]
									}
								
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[3]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[4]
									}
								</Grid>
							</Grid>
						);
						
					} else {
						
						if( "기본경비" !== data[0] ) {
							return null;
						}
						
						return (
							<Grid
								key={props.data.costInfo._id + idx.toString() + "basic_fee_after"}
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.paddingSmallY,
									)
								}
							>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												data[1].substr( 0, 6 )
											) :
											(
												data[1]
											)
									}
									
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												<br/>
											) :
											(
												null
											)
									}
									
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												data[1].substr( 6 )
											) :
											(
												null
											)
									}
									
									{
										( 
											null		===	data[5]	||
											undefined	===	data[5]	||
											0			>=	data[5].length
										) ? 
											null : 
											(
												<br/>
											)
									}
									
									{
										( 
                                            
                                            '-'		===	data[5]	||
											null		===	data[5]	||
											undefined	===	data[5]	||
											0			>=	data[5].length
										) ? 
											null : 
											(
												"(" + data[5] + ")"
											)
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[2]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[3]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[4]
									}
								</Grid>
							</Grid>
						);
						
					}
					
				}
			);
			
			
			afterSchoolCostBasic	= afterSchoolCostBasic.filter(
				( data ) => ( null !== data )
			);
			
			
			afterSchoolCostOption	= props.data.costInfo.afterSchoolCost.map( 
				( data, idx ) => {
					
					if( 0 === idx ) {
						
						return (
							<Grid
								key={props.data.costInfo._id + idx.toString() + "option_fee_after"}
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.paddingSmallY,
										classes.borderBottom,
									)
								}
							>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										//"항 목(선택)"
										"항 목"
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[2]
									}
								
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[3]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										data[4]
									}
								</Grid>
							</Grid>
						);
						
					} else {
						
						if( "선택경비" !== data[0] ) {
							return null;
						}
						
						return (
							<Grid
								key={props.data.costInfo._id + idx.toString() + "option_fee_after"}
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.paddingSmallY,
									)
								}
							>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textCenter,
										)
									}
								>
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												data[1].substr( 0, 6 )
											) :
											(
												data[1]
											)
									}
									
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												<br/>
											) :
											(
												null
											)
									}
									
									{
										(
											null		!== data[1]	&&
											undefined	!== data[1]	&&
											6			<	data[1].length
										) ?
											(
												data[1].substr( 6 )
											) :
											(
												null
											)
									}
									
									{
										( 
											null		===	data[5]	||
											undefined	===	data[5]	||
											0			>=	data[5].length
										) ? 
											null : 
											(
												<br/>
											)
									}
									
									{
										( 
                                            
                                            '-'		===	data[5]	||
											null		===	data[5]	||
											undefined	===	data[5]	||
											0			>=	data[5].length
										) ? 
											null : 
											(
												"(" + data[5] + ")"
											)
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[2]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[3]
									}
								</Grid>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={3}
									xl={3}
									
									className={
										clsx(
											classes.textSmall,
											classes.textRight,
											classes.fontWeightBold,
										)
									}
								>
									{
										data[4]
									}
								</Grid>
							</Grid>
						);
						
					}
					
				}
			);
			
			
			afterSchoolCostOption	= afterSchoolCostOption.filter(
				( data ) => ( null !== data )
			);
		}
		
		
		if(
			undefined	!==	props.data.costInfo.specialActivity	&&
			null		!==	props.data.costInfo.specialActivity	&&
			0			<	props.data.costInfo.specialActivity.length
		) {
			specialActivity3	= props.data.costInfo.specialActivity.filter( 
				( data ) => ( -1	!==	data[0].indexOf( "3세" ) )
			);
			
			specialActivity4	= props.data.costInfo.specialActivity.filter( 
				( data ) => ( -1	!==	data[0].indexOf( "4세" ) )
			);
			
			specialActivity5	= props.data.costInfo.specialActivity.filter( 
				( data ) => ( -1	!==	data[0].indexOf( "5세" ) )
			);
			
			specialActivity	= props.data.costInfo.specialActivity.filter( 
				( data ) => ( 
					-1	===	data[0].indexOf( "3세" )	&&
					-1	===	data[0].indexOf( "4세" )	&&
					-1	===	data[0].indexOf( "5세" )
				)
			);
			
			//console.log( specialActivity );
			//console.log( specialActivity3 );
			//console.log( specialActivity4 );
			//console.log( specialActivity5 );
			
			if( 0	<	specialActivity3.length ) {
				specialActivity3	= specialActivity3.map(
					( data, idx ) => (
						<Grid
							key={props.data.costInfo._id + "_" + idx.toString() + + "_specialActivity3"}
							
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									//classes.padding2X,
									classes.paddingSmallY,
								)
							}
						>
							<Grid
								item
								
								xs={6}
								sm={6}
								md={6}
								lg={5}
								xl={5}
							>
								{
									data[3]
								}
							</Grid>
							<Grid
								item
								
								xs={6}
								sm={6}
								md={6}
								lg={7}
								xl={7}
								
								className={
									clsx(
										classes.textSmall,
										classes.fontWeightBold,
									)
								}
							>
								{
									data[7] + " 원(" + Number( data[5] ).toFixed(0) + "회/" + Number( data[6] ).toFixed(0) + "분)"
								}
							</Grid>
						</Grid>
					)
				);
			} else {
				specialActivity3	= null;
			}
			
			
			
			if( 0	<	specialActivity4.length ) {
				specialActivity4	= specialActivity4.map(
					( data, idx ) => (
						<Grid
							key={props.data.costInfo._id + "_" + idx.toString() + + "_specialActivity4"}
							
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									//classes.padding2X,
									classes.paddingSmallY,
								)
							}
						>
							<Grid
								item
								
								xs={6}
								sm={6}
								md={6}
								lg={5}
								xl={5}
							>
								{
									data[3]
								}
							</Grid>
							<Grid
								item
								
								xs={6}
								sm={6}
								md={6}
								lg={7}
								xl={7}
								
								className={
									clsx(
										classes.textSmall,
										classes.fontWeightBold,
									)
								}
							>
								{
									data[7] + " 원(" + Number( data[5] ).toFixed(0) + "회/" + Number( data[6] ).toFixed(0) + "분)"
								}
							</Grid>
						</Grid>
					)
				);
			} else {
				specialActivity4	= null;
			}
			
			
			if( 0	<	specialActivity5.length ) {
				specialActivity5	= specialActivity5.map(
					( data, idx ) => (
						<Grid
							key={props.data.costInfo._id + "_" + idx.toString() + + "_specialActivity5"}
							
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									//classes.padding2X,
									classes.paddingSmallY,
								)
							}
						>
							<Grid
								item
								
								xs={6}
								sm={6}
								md={6}
								lg={5}
								xl={5}
							>
								{
									data[3]
								}
							</Grid>
							<Grid
								item
								
								xs={6}
								sm={6}
								md={6}
								lg={7}
								xl={7}
								
								className={
									clsx(
										classes.textSmall,
										classes.fontWeightBold,
									)
								}
							>
								{
									data[7] + " 원(" + Number( data[5] ).toFixed(0) + "회/" + Number( data[6] ).toFixed(0) + "분)"
								}
							</Grid>
						</Grid>
					)
				);
			} else {
				specialActivity5	= null;
			}
			
		}
	}
	

	
	
	if (
		null		!== props.data.authInfoList &&
		'undefined'	!== typeof props.data.authInfoList //&&
		//0			< props.data.authInfoList.length
	) {
		if ( 0 < props.data.authInfoList.length ) {
			const tmpAuthInfoList = props.data.authInfoList.filter(
				data => true === data.authYn
			);
			
			authInfoList = tmpAuthInfoList.map((data, idx) => {
				return (
					<Fragment
						key={
							props.data.costInfo._id +
							'_' +
							data.authDate +
							'_' +
							data.period +
							'_' +
							idx.toString()
						}
					>
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									classes.padding2X,
									classes.paddingSmallY,
								)
							}
						>
							<Grid
								item
								
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
								
								className={
									clsx(
										classes.textCenter,
										classes.fontWeightBold,
									)
								}
							>
								{
									data.period
								}
								
								{
									( 
										'3주기' === data.period	||
										'4주기' === data.period
									) ? 
										(
											<br/>
										) : null
								}
								
								{
									( '3주기' === data.period ) ? 
										(
											<span
												className={
													clsx(
														classes.noGutters,
														classes.textSmall,
														classes.fontWeightLight
													)
												}
											>
												{
													'2014.03 ~ 2017.02'
													//'기간 : 2014.03 ~ 2017.02'
												}
											</span>
										) : 
										(
											( '4주기' === data.period ) ? 
												(
													<span
														className={
															clsx(
																classes.noGutters,
																classes.textSmall,
																classes.fontWeightLight
															)
														}
													>
														{
															//'기간 : 2017.03 ~ 2020.02'
															'2017.03 ~ 2020.02'
														}
													</span>
												) : null
										)
								}
							</Grid>
							
							<Grid 
								item
								
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
								
								className={
									clsx(
										classes.textCenter,
										classes.fontWeightBold,
									)
								}
							>
								{
									( true === data.authYn ) ? 
										'실시' : '미실시'
								}
							</Grid>
							
							<Grid 
								item
								
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
								
								className={
									clsx(
										classes.textCenter,
										classes.fontWeightBold,
									)
								}
							>
								{data.authDate}
							</Grid>
						</Grid>
						
					</Fragment>
				);
			});
		}
	}
	
	
	
//유치원 인증정보 다운이 안되서 주석처리 202020511
//유치원 인증정보 다운 가능 2021-04-10

	//console.log( props.data.authInfoFile );
	
	if (
		null		!== props.data.authUrl &&
		'undefined'	!== typeof props.data.authUrl &&
		''			!==  props.data.authUrl
	) {
		
		//console.log(  props.data.authUrl );
		
		authUrl = (
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				
			>
				<Grid 
					item
			
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
			
					className={
						clsx(
							//classes.paddingX,
							classes.padding,
						)
					}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							bgcolor={
								( "ulzip" === props.dataType ) ?
									"secondary.main" : "primary.main"
							}
							color={
								( "ulzip" === props.dataType ) ?
									"secondary.contrastText" : "primary.contrastText"
							}
					
							borderRadius={16}
						>
							<Button
								color={
									//( "ulzip" === props.dataType ) ?
									//	"secondary.main" : "primary.main"
									"inherit"
								}
								//size="medium"
								//size="medium"
						
								fullWidth={true}
								target="_blank"
								href={props.data.authUrl}
								rel="noopener noreferrer"
						
							>
								<Typography
									//variant="h6"
									variant="subtitle1"
								>
									{
										//"인증 상세정보 보러가기"
										'인증정보 상세보기'
									}
								</Typography>
							</Button>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
		);
	}
	
	
	let violationInfo	= null;
	
	//console.log( props.data.violationInfo );
	
	if ( 
		undefined	===	props.data.violationInfo ||
		null		===	props.data.violationInfo ||
		0			>=	props.data.violationInfo.length
	) {
		violationInfo	= (
			//<Grid
			//	container
			//	direction="row"
			//	justify="center"
			//	alignItems="center"
			//	
			//	className={
			//		clsx(
			//			classes.padding2X,
			//			classes.paddingSmallY,
			//		)
			//	}
			//>
				<Grid 
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					
					className={
						clsx(
							classes.padding2X,
							classes.paddingSmallY,
							
							classes.textCenter,
							classes.fontWeightBold,
						)
					}
				>
					{ "위반 정보 없음" }
				</Grid>
			//</Grid>
		);
	} else {
		violationInfo	= props.data.violationInfo.map(
			( data, idx ) => {
				
				//console.log( data.violation_downloadLink );
				//console.log( data.result_downloadLink );
				
				if (
					""			!=	data.violation_downloadLink	&&
					null		!=	data.violation_downloadLink	&&
					undefined	!=	data.violation_downloadLink
				) {
					return (
						<Fragment
							key={data.seq + "_" + data.regDate + "_" + idx.toString()}
						>
							<Grid 
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
								className={
									clsx(
										//classes.paddingX,
										classes.padding,
									)
								}
							>
								<ThemeProvider
									theme={markerInfoTheme}
								>
									<Box
										bgcolor={
											//( "ulzip" === props.dataType ) ?
											//	"secondary.main" : "primary.main"
											"error.main"
										}
										color={
											//( "ulzip" === props.dataType ) ?
											//	"secondary.contrastText" : "primary.contrastText"
											"error.contrastText"
										}
										
										borderRadius={16}
									>
										<Button
											color={
												//( "ulzip" === props.dataType ) ?
												//	"secondary.main" : "primary.main"
												"inherit"
											}
											//size="medium"
											//size="medium"
											
											fullWidth={true}
											target="_blank"
											href={data.violation_downloadLink}
											rel="noopener noreferrer"
											
										>
											<Typography
												//variant="h6"
												variant="subtitle1"
											>
												{
													data.fileName.replace(".pdf","").replace(".PDF","").replace(
														props.data.summary.kindername, ""
													)
												}
											</Typography>
										</Button>
									</Box>
								</ThemeProvider>
							</Grid>
							
							{
								(
									""			!=	data.result_downloadLink	&&
									null		!=	data.result_downloadLink	&&
									undefined	!=	data.result_downloadLink
								) ?
									(
										<Grid 
											item
											
											xs={12}
											sm={12}
											md={12}
											lg={12}
											xl={12}
											
											className={
												clsx(
													//classes.marginTop,
													//classes.paddingX,
													classes.padding,
												)
											}
										>
											<ThemeProvider
												theme={markerInfoTheme}
											>
												<Box
													bgcolor={
														//( "ulzip" === props.dataType ) ?
														//	"secondary.main" : "primary.main"
														"secondary.main"
													}
													color={
														//( "ulzip" === props.dataType ) ?
														//	"secondary.contrastText" : "primary.contrastText"
														"secondary.contrastText"
													}
													
													borderRadius={16}
												>
													<Button
														color={
															//( "ulzip" === props.dataType ) ?
															//	"secondary.main" : "primary.main"
															"inherit"
														}
														//size="medium"
														//size="medium"
														
														fullWidth={true}
														target="_blank"
														href={data.result_downloadLink}
														rel="noopener noreferrer"
														
													>
														<Typography
															//variant="h6"
															variant="subtitle1"
														>
															{
																//data.fileName.replace(".pdf","").replace(".PDF","")
																"조치결과"
															}
														</Typography>
													</Button>
												</Box>
											</ThemeProvider>
										</Grid>
									) : null
							}
						</Fragment>
					);
				} else {
					return null;
				}
				
			}
		);
	}
	
	
	
	return (
		
		<Fragment>
			{
				( null		!== curriculumCostBasic ) ?
					(
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
							>
								<ThemeProvider
									theme={markerInfoTheme}
								>
									<Box
										//bgcolor="fourth.main"
										bgcolor="grey.200"
									>
										<Typography
											variant="subtitle1"
											//color="textSecondary"
											
											className={
												clsx(
													classes.textCenter,
												)
											}
										>
											{'교육과정 기본경비'}
										</Typography>
									</Box>
								</ThemeProvider>
							</Grid>
						</Grid>
					) : null
			}
			
			{
				( null		!== curriculumCostBasic ) ?
					curriculumCostBasic : null
			}
			
			{
				( null		!== curriculumCostOption ) ?
					(
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
							>
								<ThemeProvider
									theme={markerInfoTheme}
								>
									<Box
										//bgcolor="fourth.main"
										bgcolor="grey.200"
									>
										<Typography
											variant="subtitle1"
											//color="textSecondary"
											
											className={
												clsx(
													classes.textCenter,
												)
											}
										>
											{'교육과정 선택경비11'}
										</Typography>
									</Box>
								</ThemeProvider>
							</Grid>
						</Grid>
					) : null
			}
			
			{
				( null		!== curriculumCostOption ) ?
					curriculumCostOption : null
			}
			
			{
				( null		!== afterSchoolCostBasic ) ?
					(
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
							>
								<ThemeProvider
									theme={markerInfoTheme}
								>
									<Box
										//bgcolor="fourth.main"
										bgcolor="grey.200"
									>
										<Typography
											variant="subtitle1"
											//color="textSecondary"
											
											className={
												clsx(
													classes.textCenter,
												)
											}
										>
											{'방과후 기본경비'}
										</Typography>
									</Box>
								</ThemeProvider>
							</Grid>
						</Grid>
					) : null
			}
			
			{
				( null		!== afterSchoolCostBasic ) ?
					afterSchoolCostBasic : null
			}
			
			{
				( null		!== afterSchoolCostOption ) ?
					(
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
							>
								<ThemeProvider
									theme={markerInfoTheme}
								>
									<Box
										//bgcolor="fourth.main"
										bgcolor="grey.200"
									>
										<Typography
											variant="subtitle1"
											//color="textSecondary"
											
											className={
												clsx(
													classes.textCenter,
												)
											}
										>
											{'방과후 선택경비'}
										</Typography>
									</Box>
								</ThemeProvider>
							</Grid>
						</Grid>
					) : null
			}
			
			{
				( null		!== afterSchoolCostOption ) ?
					afterSchoolCostOption : null
			}
			
			{
				( 
					null		!== specialActivity3	||
					null		!== specialActivity4	||
					null		!== specialActivity5
					//true
				) ?
					(
						
						<Fragment
						>
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Grid
									item
									
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
								>
									<ThemeProvider
										theme={markerInfoTheme}
									>
										<Box
											//bgcolor="fourth.main"
											bgcolor="grey.200"
										>
											<Typography
												variant="subtitle1"
												//color="textSecondary"
												
												className={
													clsx(
														classes.textCenter,
													)
												}
											>
												{'특성화 활동비'}
											</Typography>
										</Box>
									</ThemeProvider>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										//classes.paddingSmallY,
										classes.borderBottom,
									)
								}
							>
								<Grid
									item
									
									xs={3}
									sm={3}
									md={3}
									lg={2}
									xl={2}
									
									className={
										clsx(
											classes.textCenter,
											//classes.borderRight,
											classes.paddingRight2X
										)
									}
								>
									{
										"구 분"
									}
								</Grid>
								<Grid
									item
									container
									direction="row"
									justify="center"
									alignItems="center"
									
									xs={9}
									sm={9}
									md={9}
									lg={10}
									xl={10}
									
									className={
										clsx(
											//classes.textCenter,
											classes.borderLeft,
										)
									}
								>
									<Grid
										item
										
										xs={6}
										sm={6}
										md={6}
										lg={5}
										xl={5}
										
										className={
											clsx(
												classes.textCenter,
											)
										}
									>
										{
											"프로그램명"
										}
									</Grid>
									
									<Grid
										item
										
										xs={6}
										sm={6}
										md={6}
										lg={7}
										xl={7}
										
										className={
											clsx(
												classes.textCenter,
											)
										}
									>
										{
											"월 부담금"
										}
										<br/>
										{
											"주당 운영횟수/"
										}
										<br/>
										{
											"1회 운영시간(분)"
										}
									</Grid>
								</Grid>
							</Grid>
						</Fragment>
						
					) : 
					(
						<Fragment
						>
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Grid
									item
									
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
								>
									<ThemeProvider
										theme={markerInfoTheme}
									>
										<Box
											//bgcolor="fourth.main"
											bgcolor="grey.200"
										>
											<Typography
												variant="subtitle1"
												//color="textSecondary"
												
												className={
													clsx(
														classes.textCenter,
													)
												}
											>
												{'특성화 활동비'}
											</Typography>
										</Box>
									</ThemeProvider>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.paddingSmallY,
									)
								}
							>
								<Grid 
									item
									
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									
									className={
										clsx(
											classes.textCenter,
											classes.fontWeightBold,
										)
									}
								>
									{
										'정보 없음'
									}
								</Grid>
							</Grid>
						</Fragment>
					)
			}
			
			{
				( null		!== specialActivity3 ) ?
					(
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								//( null	!== specialActivity4 ) ?
								//	(
										clsx(
											classes.padding2X,
											classes.borderBottom,
										)
								//	) : 
								//	(
								//		clsx(
								//			classes.padding2X,
								//		)
								//	)
							}
						>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={2}
								xl={2}
								
								className={
									clsx(
										classes.paddingRight2X,
										classes.textCenter,
									)
								}
							>
								{
									"만 3세"
								}
							</Grid>
							<Grid
								item
								
								xs={9}
								sm={9}
								md={9}
								lg={10}
								xl={10}
								
								className={
									clsx(
										classes.textCenter,
										classes.borderLeft,
									)
								}
							>
								{
									specialActivity3
								}
							</Grid>
						</Grid>
					) : null
			}
			
			{
				( null		!== specialActivity4 ) ?
					(
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								//( null	!== specialActivity5 ) ?
								//	(
										clsx(
											classes.padding2X,
											classes.borderBottom,
										)
								//	) : 
								//	(
								//		clsx(
								//			classes.padding2X,
								//		)
								//	)
							}
						>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={2}
								xl={2}
								
								className={
									clsx(
										classes.paddingRight2X,
										classes.textCenter,
									)
								}
							>
								{
									"만 4세"
								}
							</Grid>
							<Grid
								item
								
								xs={9}
								sm={9}
								md={9}
								lg={10}
								xl={10}
								
								className={
									clsx(
										classes.textCenter,
										classes.borderLeft,
									)
								}
							>
								{
									specialActivity4
								}
							</Grid>
						</Grid>
					) : null
			}
			
			{
				( null		!== specialActivity5 ) ?
					(
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									classes.padding2X,
									classes.borderBottom,
								)
							}
						>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={2}
								xl={2}
								
								className={
									clsx(
										classes.paddingRight2X,
										classes.textCenter,
									)
								}
							>
								{
									"만 5세"
								}
								
							</Grid>
							<Grid
								item
								
								xs={9}
								sm={9}
								md={9}
								lg={10}
								xl={10}
								
								className={
									clsx(
										classes.textCenter,
										classes.borderLeft,
									)
								}
							>
								{
									specialActivity5
								}
							</Grid>
						</Grid>
					) : null
			}
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							//bgcolor="fourth.main"
							bgcolor="grey.200"
						>
							<Typography
								variant="subtitle1"
								//color="textSecondary"
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								{'위반 정보'}
							</Typography>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
			
			
			<Fragment
				//key={data.seq + '_' + data.regDate}
			>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
				>
					{
						violationInfo
					}
				</Grid>
			</Fragment>
			
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<ThemeProvider
						theme={markerInfoTheme}
					>
						<Box
							//bgcolor="fourth.main"
							bgcolor="grey.200"
						>
							<Typography
								variant="subtitle1"
								//color="textSecondary"
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								{'유치원 평가정보'}
							</Typography>
						</Box>
					</ThemeProvider>
				</Grid>
			</Grid>
			
			{
				( 
					//null !== props.data.authInfoList 
					null		!==	authInfoList	&&
					undefined	!==	authInfoList	&&
					0			<	authInfoList.length
				)? 
					(
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									classes.padding2X,
									classes.paddingSmallY,
									classes.borderBottom,
								)
							}
						>
							<Grid 
								item
								
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
								
								className={
									clsx(
										classes.textCenter,
										
									)
								}
							>
								{ "주기" }
							</Grid>
						
							<Grid 
								item
								
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
								
								className={
									clsx(
										classes.textCenter,
										
									)
								}
							>
								{ "평가실시 여부" }
							</Grid>
						
							
							<Grid 
								item
								
								xs={4}
								sm={4}
								md={4}
								lg={4}
								xl={4}
								
								className={
									clsx(
										classes.textCenter,
										
									)
								}
							>
								{ "평가연월" }
							</Grid>
						</Grid>
					) :
					(
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									classes.padding3X,
									classes.paddingSmallY,
								)
							}
							
						>
							<Grid 
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
								className={
									clsx(
										classes.textCenter,
										classes.fontWeightBold,
									)
								}
							>
								{ "평가정보 없음" }
							</Grid>
						</Grid>
					)
			}
			
			{
				( 
					null		!==	authInfoList	&&
					undefined	!==	authInfoList	&&
					0			<	authInfoList.length
				) ? 
					authInfoList : null
			}
			
			{ authUrl }
			
		</Fragment>
		
	);	
}