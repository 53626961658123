import React, { 
	Component,
	Fragment,
} from 'react';

import { //isMobile, 
	//isIOS, 
	isMobileOnly, 
	isTablet,
	//isAndroid, 
	//isBrowser,
	isIPad13, 
} from 'react-device-detect';


import HeadlineNewsDetail	from './HeadlineNewsDetail.js';
import NewsDialog			from './NewsDialog.js';

class HeadlineNews extends Component {
	
	constructor( props ) {
		
		super( props );
		
		this.state	= {
			isOpen	: this.props.isOpen,
			headlineNews: this.props.headlineNews,
			
			//errorMsg: "",
			//errorMsgType:"warning",
		};
		
		
	}
	
	componentDidMount() {
		//console.log( this.state.headlineNews );
	}
	
	static getDerivedStateFromProps( nextProps, prevState ) {
		
		if ( 
			prevState.isOpen	!== nextProps.isOpen	||
			prevState.headlineNews	!== nextProps.headlineNews
		) {
			return {
				isOpen			: nextProps.isOpen,
				headlineNews	: nextProps.headlineNews,
			};
		}
		
		return null;
	}
	
	componentDidUpdate( prevProps, prevState ) {
		//console.log( this.state.headlineNews );
	}
	
	render() {
		
		let cardList	= null;
		
		if (
			null		!==	this.state.headlineNews	&&
			undefined	!== this.state.headlineNews	&&
			0			<	this.state.headlineNews.length
		) {
			cardList	= this.state.headlineNews.map(
				( card, idx ) => {
					
					let ret_obj	= {
						dataType:"",	
						
						label:"",
						
						title:"",
						avatar:undefined,
						header:"",
						description:"",
						
						source_name:"",
						source_url:"",
						source_detail_url:"",
						
						source_img:"",
						source_detail_img:"",
						
						component:null,
					};
					
					ret_obj.label		= "_headlineNews_" + idx.toString();
					
					ret_obj.dataType	= "headlineNews";
					ret_obj.title		= card.title
						.replace( /<[^>]*>/g, "" )
						.replace( /&[a-z]+;/g, "" );
					ret_obj.avatar		= "";
					ret_obj.description	= card.description
						.replace( /<[^>]*>/g, "" )
						.replace( /&[a-z]+;/g, "" );
					ret_obj.source_name	= "";
					ret_obj.source_url			= card.link;
					ret_obj.source_detail_url	= card.originallink;
					
					ret_obj.component	= (
						( param ) => {
							return	(
								<NewsDialog
									title={ret_obj.title}
									label={ret_obj.label}
									description={ret_obj.description}
									url={ret_obj.source_url}
									detailUrl={ret_obj.source_detail_url}
									
									others={param}
								/>
							);
						}
					);
					
					//console.log( ret_obj );
					
					return ret_obj;
				}
			);
			
			//console.log( cardList );
			
			cardList	= cardList.filter(
				( card ) => ( 
					-1	!==	card.title.indexOf("어린이")	||
					-1	!==	card.title.indexOf("유치원")	||
					-1	!==	card.title.indexOf("영유아")	||
					-1	!==	card.title.indexOf("보육")	//||
					
					//-1	!==	card.description.indexOf("어린이집")	||
					//-1	!==	card.description.indexOf("유치원")
				)
			);
		}
		
		return (
			<Fragment>
				{
					( 
						null		!==	cardList	&&
						undefined	!==	cardList	&&
						0			<	cardList.length
					) ?
						(
							<HeadlineNewsDetail
								cardList={cardList}
							/>
						) : null
				}
				
			</Fragment>
		);
	}
}

export default HeadlineNews;