import React, {
	Component,
	Fragment
} from 'react';


import { Link } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser, isIPad13, } from 'react-device-detect';

import {
	Container,
	Box,
	Modal,
	Backdrop,
} from '@material-ui/core';

import CustomAlert				from '../components/Utility/Alert/CustomAlert.js';

import RegisterUserIdDetail		from '../components/User/RegisterUserIdDetail.js';



class RegisterUserId extends Component {
	
	constructor( props ) {
		
		super( props );
		
		
		this.state={
			
			email:"",
			selfEmailYN:true,
			emailDomain:"",
			isValidEmail:false,
			
			password:"",
			isValidPassword:false,
			
			passwordConfirm:"",
			isValidPasswordConfirm:false,
			
			
			emailAuthYN : false,
			emailAuthResult : "",
			//emailAuthResultMsg : "",
			
			termConsentYN:false,
			
			isCompleteYN:false,
			
			msg:"",
			errMsg:"",
			errMsgType:"warning",
			
		};
		
		
		this.typeEmail			= this.typeEmail.bind(this);
		this.selectEmailType	= this.selectEmailType.bind(this);
		this.typePassword			= this.typePassword.bind(this);
		this.typePasswordConfirm	= this.typePasswordConfirm.bind(this);
		this.consentTerms		= this.consentTerms.bind(this);
		
		
		this.sendAuthEmail		= this.sendAuthEmail.bind(this);
		this.registerUser		= this.registerUser.bind(this);
		
		
		this.createErrorMsg			= this.createErrorMsg.bind(this);
		this.setMsg					= this.setMsg.bind(this);
		this.closeAlert				= this.closeAlert.bind(this);
		
	}
	
	
	componentDidMount( ) {
		
	}
	
	
	componentDidUpdate( prevProps, prevState ) {
		//console.log( this.state.isValidEmail );
	}
	
	
	typeEmail( e ) {
		
		e.preventDefault();
		
		const regexp_for_self	= /[a-zA-Z0-9]+([._-]?[a-zA-Z0-9])*@[a-zA-Z0-9]+[-]?[a-zA-Z0-9]+[.][a-zA-Z]+([.][a-zA-Z]+)?/;
		
		const regexp_for_non_self	= /[a-zA-Z0-9]+([._-]?[a-zA-Z0-9])*/;
		
		
		if( true === this.state.selfEmailYN ) {
			
			let tmp_email	= e.target.value;
			
			tmp_email	= tmp_email.replace( regexp_for_self, "" );
			
			if( ""  !== tmp_email ) {
				this.setState({
					email: e.target.value,
					isValidEmail : false
				});
			} else {
				this.setState({
					email: e.target.value,
					isValidEmail : true
				});
			}
			
		} else {
			
			
			let tmp_email	= e.target.value;
			
			tmp_email	= tmp_email.replace( regexp_for_non_self, "" );
			
			
			if( ""  !== tmp_email ) {
				this.setState({
					email: e.target.value,
					isValidEmail : false
				});
			} else {
				this.setState({
					email: e.target.value,
					isValidEmail : true
				});
			}
			
		}
		
	}
	
	
	selectEmailType( e ) {
		
		const regexp_for_self	= /[a-zA-Z0-9]+([._-]?[a-zA-Z0-9])*@[a-zA-Z0-9]+[-]?[a-zA-Z0-9]+[.][a-zA-Z]+([.][a-zA-Z]+)?/;
		
		const regexp_for_non_self	= /[a-zA-Z0-9]+([._-]?[a-zA-Z0-9])*/;
		
		
		if( "직접 작성" === e.target.value ) {
			
			let tmp_email	= this.state.email;
			
			tmp_email	= tmp_email.replace( regexp_for_self, "" );
			
			if( true !== this.state.selfEmailYN ) {
				
				if( ""  !== tmp_email ) {
					this.setState({
						isValidEmail : false,
						selfEmailYN : true,
						emailDomain:""
					});
					
				} else {
					this.setState({
						isValidEmail : true,
						selfEmailYN : true,
						emailDomain:""
					});
				}
				
			} else {
				
				if( ""  !== tmp_email ) {
					this.setState({
						isValidEmail : false,
						emailDomain:""
					});
					
				} else {
					this.setState({
						isValidEmail : true,
						emailDomain:""
					});
				}
				
			}
			
		} else {
			
			let tmp_email	= this.state.email;
			
			tmp_email	= tmp_email.replace( regexp_for_non_self, "" );
			
			
			if( false !== this.state.selfEmailYN ) {
				
				if( ""  !== tmp_email ) {
					this.setState({
						isValidEmail : false,
						selfEmailYN : false,
						emailDomain:e.target.value
					});
				} else {
					this.setState({
						isValidEmail : true,
						selfEmailYN : false,
						emailDomain:e.target.value
					});
				}
				
			} else {
				
				if( ""  !== tmp_email ) {
					this.setState({
						isValidEmail : false,
						emailDomain:e.target.value
					});
				} else {
					this.setState({
						isValidEmail : true,
						emailDomain:e.target.value
					});
				}
				
			}
		}
		
	}
	
	
	async sendAuthEmail( e ) {
		
		let emailAuthYN			= false;
		let emailAuthResult		= "";
		
		let msg		= "";
		let errMsg	= "";
		let errMsgType	= "warning";
		
		
		if( false === this.state.isValidEmail ) {
			return;
		}
		
		
		try {
			const auth_mail_result	= await axios.post( 
				"/mailSend",
				{
					email:this.state.email + this.state.emailDomain
				}
			);
			
			
			//console.log( auth_mail_result.data );
			
			if( "success" === auth_mail_result.data.result ) {
				emailAuthYN			= true;
				emailAuthResult		= auth_mail_result.data.result;
				
				//errMsg		= auth_mail_result.data.msg;
				//errMsgType	= "success";
				msg			= auth_mail_result.data.msg;
			} else {
				emailAuthYN			= false;
				emailAuthResult		= auth_mail_result.data.result;
				
				errMsg		= auth_mail_result.data.msg;
				errMsgType	= "warning";
				msg			= auth_mail_result.data.msg;
			}
			
		} catch( err ) {
			
			console.log( err );
			
			emailAuthYN	= false;
			errMsg		= "인증메일 발송 중 오류가 발생했습니다.";
			errMsgType	= "warning";
			
		} finally {
			
			if ( true === emailAuthYN ) {
				if ( "" !== msg ) {
					this.setMsg( msg );
				}
			} else {
				
				if ( "" !== errMsg ) {
					this.createErrorMsg(
						errMsg,
						errMsgType
					);
				}
				
				if ( "" !== msg ) {
					this.setMsg( msg );
				}
				
			}
			
			this.setState({
				emailAuthYN:emailAuthYN,
				emailAuthResult:emailAuthResult,
			});
			
		}
		
	}
	
	
	async registerUser( e ) {
		
		let msg		= "";
		let errMsg	= "";
		let errMsgType	= "warning";
		
		let isCompleteYN	= false;
		
		if( 
			false === this.state.isValidEmail			||
			false === this.state.isValidPassword		||
			false === this.state.isValidPasswordConfirm	||
			false === this.state.termConsentYN
		) {
			return;
		}
		
		
		try {
			
			const registerUserResult	= await axios.post(
				"/auth/register",
				{
					email:this.state.email + this.state.emailDomain,
					password:this.state.password
				}
			);
			
			//console.log( registerUserResult.data );
			
			if( "success" === registerUserResult.data.result ) {
				isCompleteYN	= true;
				msg				= registerUserResult.data.msg;
				//errMsg		= registerUserResult.data.msg;
				errMsgType	= "success";
			} else {
				isCompleteYN	= false;
				//msg				= registerUserResult.data.msg;
				errMsg		= registerUserResult.data.msg;
				errMsgType	= "warning";
			}
			
		} catch ( err ) {
			
			console.log( err );
			isCompleteYN	= false;
			errMsg			= "회원가입 도중 오류가 발생했습니다.";
			errMsgType		= "warning";
			
		} finally {
			
			if ( false === isCompleteYN ) {
				if ( "" !== errMsg ) {
					this.createErrorMsg(
						errMsg,
						errMsgType
					);
				}
			} else {
				
				if ( "" !== msg ) {
					this.createErrorMsg(
						msg,
						errMsgType
					);
				}
				
			}
			
			this.setState({
				isCompleteYN:isCompleteYN,
				//msg:msg
			});
		}
		
	}
	
	
	typePassword( e ) {
		
		e.preventDefault();
		
		
		const regexp_for_password	= /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
		
		
		let tmp_password	= e.target.value;
			
		tmp_password	= tmp_password.replace( regexp_for_password, "" );
		
		if( ""  !== tmp_password ) {
			this.setState({
				password: e.target.value,
				isValidPassword : false
			});
		} else {
			this.setState({
				password: e.target.value,
				isValidPassword : true
			});
		}
		
	}
	
	
	typePasswordConfirm( e ) {
		e.preventDefault();
		
		let flag	= false;
		
		if( this.state.password === e.target.value ) {
			flag	= true;
		} else {
			flag	= false;
		}
		
		this.setState({
			passwordConfirm : e.target.value,
			isValidPasswordConfirm : flag
		});
		
	}
	
	
	consentTerms( e ) {
		
		this.setState({
			termConsentYN : !this.state.termConsentYN
		});
		
	}
	
	
	/**
	 *	에러 메시지 닫기 버튼 클릭 시 호출되는 메소드...
	 */
	closeAlert ( ) {
		this.setState({
			errMsg: ""
		});
	}

	/**
	 *	에러 메시지를 띠우는 메소드...
	 */
	createErrorMsg ( 
		errMsg,
		errMsgType, 
	) {
		this.setState({
			errMsg: errMsg,
			errMsgType:errMsgType,
		});
		
		//setTimeout(this.closeAlert, 3000);
	}
	
	
	setMsg ( msg ) {
		this.setState({
			msg: msg
		});
	}
	
	
	
	render ( ) {
		
		
		return (
			<Fragment>
				
				<CustomAlert
					open={this.state.errMsg.length > 0}
					data={this.state.errMsg} 
					option={this.state.errMsgType}
					closeAlert={this.closeAlert} 
				/>
				
				<Modal
					//className={classes.modal}
					//open={props.isOpen}
					open={true}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
					
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						margin: 0,
						padding: "8px",
					}}
				>
					<Box 
						//className={
						//	clsx(
						//		classes.modalBox,
						//	)
						//}
						display="flex"
						borderRadius="borderRadius"
						//bgcolor="white"
						
						clone={true}
						
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						
						//style={{
						//	minWidth:"350px",
						//	maxHeight:"95vh",
						//}}
					>
						<Container
							maxWidth="xs"
							className={
								clsx(
									//classes.noGutters,
									//classes.noMargin,
									//classes.padding2X,
								)
							}
							
							style={{
								padding:0,
								margin:0,
								backgroundColor:"white",
								minWidth:"350px",
								maxHeight:"95vh",
								//overflowY:"scroll"
							}}
						>
							<RegisterUserIdDetail
								typeEmail={this.typeEmail}
								typePassword={this.typePassword}
								typePasswordConfirm={this.typePasswordConfirm}
								selectEmailType={this.selectEmailType}
								sendAuthEmail={this.sendAuthEmail}
								consentTerms={this.consentTerms}
								registerUser={this.registerUser}
								
								
								msg={this.state.msg}
								errMsg={this.state.errMsg}
								errMsgType={this.state.errMsgType}
								
								
								isValidEmail={this.state.isValidEmail}
								isValidPassword={this.state.isValidPassword}
								isValidPasswordConfirm={this.state.isValidPasswordConfirm}
								emailAuthYN={this.state.emailAuthYN}
								emailAuthResult={this.state.emailAuthResult}
								termConsentYN={this.state.termConsentYN}
								selfEmailYN={this.state.selfEmailYN}
								emailDomain={this.state.emailDomain}
								isCompleteYN={this.state.isCompleteYN}
							/>
						</Container>
					</Box>
				</Modal>
				
				{
				//<Container
				//	fixed={true}
				//	maxWidth="xl"
				//	
				//	style={
				//		(
				//			true	=== isMobileOnly	||
				//			true	=== isTablet		||
				//			true	===	isIPad13	
				//		) ?
				//			(
				//				{
				//					position:"fixed",
				//					top:0,
				//					left:0,
				//					//height:"calc( 100vh - 3.25rem )",
				//					height:"calc( 100vh - 3rem )",
				//					
				//					
				//					margin:0,
				//					padding:0,
				//					
				//					backgroundColor:"white",
				//					
				//					overflow:"hidden",
				//				}
				//			) :
				//			(
				//				{
				//					position:"fixed",
				//					top:0,
				//					//left:0,
				//					left:"3.75rem",
				//					//right:0,
				//					
				//					width:"calc( 100vw - 3.75rem )",
				//					height:"100vh",
				//					
				//					margin:0,
				//					padding:0,
				//					
				//					backgroundColor:"white",
				//					
				//					overflow:"hidden",
				//				}
				//			)
				//	}
				//>
				//	<CustomAlert
				//		open={this.state.errMsg.length > 0}
				//		data={this.state.errMsg} 
				//		option={this.state.errMsgType}
				//		closeAlert={this.closeAlert} 
				//	/>
				//	
				//	<RegisterUserIdDetail
				//		typeEmail={this.typeEmail}
				//		typePassword={this.typePassword}
				//		typePasswordConfirm={this.typePasswordConfirm}
				//		selectEmailType={this.selectEmailType}
				//		sendAuthEmail={this.sendAuthEmail}
				//		consentTerms={this.consentTerms}
				//		registerUser={this.registerUser}
				//		
				//		
				//		msg={this.state.msg}
				//		errMsg={this.state.errMsg}
				//		errMsgType={this.state.errMsgType}
				//		
				//		
				//		isValidEmail={this.state.isValidEmail}
				//		isValidPassword={this.state.isValidPassword}
				//		isValidPasswordConfirm={this.state.isValidPasswordConfirm}
				//		emailAuthYN={this.state.emailAuthYN}
				//		emailAuthResult={this.state.emailAuthResult}
				//		termConsentYN={this.state.termConsentYN}
				//		selfEmailYN={this.state.selfEmailYN}
				//		emailDomain={this.state.emailDomain}
				//		isCompleteYN={this.state.isCompleteYN}
				//	/>
				//	
				//</Container>
				}
				
			</Fragment>
		);
		
	}
	
}

export default RegisterUserId;