import React, { Component, Fragment } from 'react';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	isIPad13,
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Paper,
    Card,
    CardActions,
    CardActionArea,
    CardContent,
    CardMedia,
    Chip,
    Button,
    Typography,
    Avatar,
    ButtonBase,
    List,
	Grid,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon		from '@material-ui/core/Icon';

//import {
//	//Close		as CloseIcon,
//	//ChevronLeft		as ChevronLeftIcon,
//	//ChevronRight	as ChevronRightIcon,
//	//Visibility,
//	//VisibilityOff,
//	//UnfoldLess	as UnfoldLessIcon,
//	//ExpandMore	as ExpandMoreIcon,
//	//Favorite		as FavoriteIcon,
//	//FavoriteBorder	as FavoriteBorderIcon,
//	//ArrowBackIos	as ArrowBackIosIcon
//} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRightSmall:{
		marginRight:theme.spacing(0.5),
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink	: 0,
		backgroundColor	: "#3f51b5",	// primary.main
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	
	dialogPaper: 
		( true === isMobileOnly ) ?
			{
				margin	: theme.spacing(0),
				minWidth: "90vw",
			} :
			{
				margin: theme.spacing(0),
			},
	
}));



export default function ITubeVideoList ( props ) {
    
	const classes = useStyles();

	const { 
		//birth, 
		contents,
	} = props.data;

	const handleITubeClick = data => {
		// console.log(data);
		props.iTubePlayerClickCallback(data);
	};
	// console.log(contents);
	
	
    return (
		
		<Fragment>
			{
				( 
					undefined	!==	contents	&&
					null		!==	contents	&&
					0			<	contents.length 
				) ? 
					(
						contents.map(
							( item, idx ) => {
								return (
									<Fragment
										key={item.title + "_" + idx.toString()}
									>
										<Grid
											container
											direction="row"
											justify="center"
											alignItems="center"
											
											className={
												clsx(
													classes.padding,
												)
											}
										>
											<Grid
												item
												
												xs={12}
												sm={12}
												md={12}
												lg={12}
												xl={12}
												
												className={
													clsx(
														classes.padding,
													)
												}
											>
												<Card 
													className={
														clsx(
															classes.padding,
														)
													}
													
													//style={{
													//	width:"100%"
													//}}
												>
													<CardActionArea
														onClick={event => {
															handleITubeClick(item);
														}}
													>
														<CardMedia
															component="img"
															alt="itube_image"
															image={item.thumbnailsUrl}
															title={item.title.replace(/&#39;/g,"'")}
														/>
														
														<CardContent
															className={
																clsx(
																	classes.noMargin,
																	classes.padding,
																)
															}
														>
															<Typography 
																gutterBottom 
																variant="h6"
																component="h2"
															>
																{
																	item.title.replace(/&#39;/g,"'")
																}
															</Typography>
															<Typography
																variant="body2"
																color="textSecondary"
																component="p"
															>
																{
																	item.description.replace(/&#39;/g,"'")}
																}
															</Typography>
														</CardContent>
													</CardActionArea>
													{
													//<CardActions>
													//	<Button size="small" color="primary">
													//		Share
													//	</Button>
													//</CardActions>
													}
												</Card>
											</Grid>
										</Grid>
									</Fragment>
									
									//</ButtonBase>
								);
							}
						)
					) : 
					(
						( false === props.isLoading ) ?
							(
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
									
								>
									<Grid
										item
										
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}
										
										className={
											clsx(
												classes.padding,
											)
										}
									>
										<Card 
											className={
												clsx(
													classes.padding,
												)
											}
											
											//style={{
											//	width:"100%"
											//}}
										>
											<CardContent
												className={
													clsx(
														classes.noMargin,
														classes.padding,
														classes.textCenter,
													)
												}
											>
												<Typography
													//variant="body2"
													variant="body1"
													color="inherit"
													component="p"
													
												>
													{
														"조회된 동영상이 없습니다."
													}
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							) : null
					)
			}
		</Fragment>
    );
}