import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';

import { connect } from 'react-redux';

import {
	getUserFavorite,
	getUserFavoriteSuccess,
	getUserFavoriteFailure,
	addUserFavorite,
	addUserFavoriteSuccess,
	addUserFavoriteFailure,
	removeUserFavorite,
	removeUserFavoriteSuccess,
	removeUserFavoriteFailure,
} from '../../store/modules/userFavoriteManager';

import clsx from 'clsx';
import axios from 'axios';

import { 
	//isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Slide,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
} from '@material-ui/icons';

import UserFavoriteItemList	from './UserFavoriteItemList.js';



class UserFavorite extends Component {
	
	constructor( props ) {
		super( props );
		
		this.state	= {
			favoriteList	: [],
			openingSchoolId:""
		};
		
		this.removeFavorite				= this.removeFavorite.bind(this);
		this.closeOtherFavoriteSchools	= this.closeOtherFavoriteSchools.bind(this);
	}
	
	
	static getDerivedStateFromProps( nextProps, prevState ) { 
		
		if( 
			prevState.favoriteList.length !== nextProps.favoriteData.favoriteList.length
		) { 
			
			if ( 
				null		!== nextProps.favoriteData.favoriteList	&&
				undefined	!== nextProps.favoriteData.favoriteList	&&
				0			<	nextProps.favoriteData.favoriteList.length
			) {
				
				return { 
					favoriteList:nextProps.favoriteData.favoriteList
				}; 
				
			} else {
				return { 
					favoriteList:[]
				}; 
			}
			
		} else {
			
			let flag	= false;
			
			for( let i = 0 ; i < prevState.favoriteList.length ; i++ ) {
				
				if( prevState.favoriteList[i].id_ !== nextProps.favoriteData.favoriteList[i].id_ ) {
					flag	= true;
					break;
				}
				
			}
			
			if( true === flag ) {
				
				return { 
					favoriteList:nextProps.favoriteData.favoriteList
				}; 
				
			}
			
		}
		
		return null;
		
	}
	
	
	componentDidUpdate( prevProps, prevState ) {
		
		if( 
			prevProps.isOpen	!== this.props.isOpen	&&
			false				=== this.props.isOpen	&&
			""					!== this.state.openingSchoolId
		) { 
			
			this.setState({
				openingSchoolId:""	
			});
			
		}
		
	}
	
	
	async removeFavorite( data ) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		
		const removedDataId	= data.id_;
		
		try {
			
			this.props.removeUserFavorite();
			
			const removeFavoriteResult	= await axios.post(
				'/user/removeFavorite',
				{
					type:data.type,
					code:data.id_
				}
			);
			
			
			//console.log( removeFavoriteResult.data );
			
			if( "success" === removeFavoriteResult.data.result ) {
				
				errMsg		= removeFavoriteResult.data.msg;
				errMsgType	= "success";
				
				const tempFavoriteList	= this.state.favoriteList.filter(
					data => ( removedDataId !== data.id_ )
				);
				
				this.props.removeUserFavoriteSuccess( tempFavoriteList );
				
			} else {
				errMsg	= removeFavoriteResult.data.msg;
				errMsgType	= "error";
				this.props.removeUserFavoriteFailure();
			}
			
		} catch( err ) {
			console.log( err );
			errMsg			= "즐겨찾기 삭제 도중 오류가 발생했습니다.";
			errMsgType	= "error";
			this.props.removeUserFavoriteFailure();
		} finally {
			if ( "" !== errMsg ) {
				this.props.createErrorMsg( 
					errMsg,
					errMsgType
				);
			} else {
				
			}
		}
	}
	
	
	
	closeOtherFavoriteSchools( id_ ) {
		
		this.setState({
			openingSchoolId:id_
		});
		
	}
	
	
	
	render ( ) {
		
		//console.log( this.state.favoriteList );
		
		
		return(
			<Slide 
				direction={
					( isMobileOnly ) ?
						//"up" : 
						"down" : 
						//"right"
						"left"
				}
				in={this.props.isOpen} 
				//mountOnEnter
				//unmountOnExit
			>
				<Container
					//maxWidth="sm"
					maxWidth="xs"
					
					style={
						( isMobileOnly ) ?
							{
								position:"fixed",
								//top:"0",
								zIndex:"3",
								//zIndex:"2",
								
								maxHeight:"calc( 100% - 6rem )",
								
								margin:0,
								//padding:0,
								padding:"0.5rem",
								
								borderRadius:"0.25rem",
								overflowX:"hidden",
								overflowY:"scroll",
								backgroundColor:"white",
							} :
							{
								position:"fixed",
								//top:"3rem",
								right:0,
								zIndex:"3",
								//zIndex:"2",
								
								maxHeight:"calc( 100% - 6rem )",
								
								margin:0,
								//padding:0,
								padding:"0.5rem",
								
								borderRadius:"0.25rem",
								overflowX:"hidden",
								overflowY:"scroll",
								backgroundColor:"white",
							}
					}
				>
					
						{
							( false === this.props.loginYN ) ?
								(
									<Grid
										container
										direction="column"
										justify="center"
										alignItems="center"
										
										spacing={2}
									>
										<Grid
											item
										>
											<Typography
												className={
													clsx(
														
													)
												}
												//color={"inherit"}
												variant="h6" 
											>
												{
													'로그인이 필요한 기능입니다.'
												}
											</Typography>
										</Grid>
									</Grid>
								) :
								(
									( 
										0 < this.state.favoriteList.length
									) ?
										(
											<UserFavoriteItemList
												favoriteList={this.state.favoriteList}
												removeFavorite={this.removeFavorite}
												openMarkerFromAutoSearchResult={this.props.openMarkerFromAutoSearchResult}
												moveCenterLoc={this.props.moveCenterLoc}
												closeOtherFavoriteSchools={this.closeOtherFavoriteSchools}
												openingSchoolId={this.state.openingSchoolId}
												createErrorMsg={this.props.createErrorMsg}
												toggleFavoritePanel={this.props.toggleFavoritePanel}
											/>
										) : 
										(
											<Grid
												container
												direction="column"
												justify="center"
												alignItems="center"
												
												spacing={2}
											>
												<Grid
													item
												>
													<Typography
														className={
															clsx(
																
															)
														}
														//color={"inherit"}
														variant="h6" 
													>
														{
															(
																true === isMobileOnly	||
																true === isTablet
															) ?
																"관심있는 어린이집/유치원을 터치하고" : "관심있는 어린이집/유치원을 클릭하고"
														}
													</Typography>
												</Grid>
												<Grid
													item
												>
													<Typography
														className={
															clsx(
																
															)
														}
														//color={"inherit"}
														variant="h6" 
													>
														{
															"즐겨찾기에 추가하세요."
														}
													</Typography>
												</Grid>
											</Grid>
										)
								)
						}
					
				</Container>
			</Slide>
		);
	}
	
}


//export default UserFavorite;

// props 로 넣어줄 스토어 상태값
const mapStateToProps = state => ({
	favoriteData: state.userFavoriteManager.favoriteData
});

// props 로 넣어줄 액션 생성함수
const mapDispatchToProps = dispatch => ({
	getUserFavorite				: ( ) => dispatch( getUserFavorite( ) ),
	getUserFavoriteSuccess		: favoriteData => dispatch( getUserFavoriteSuccess( favoriteData ) ),
	getUserFavoriteFailure		: ( ) => dispatch( getUserFavoriteFailure( ) ),
	addUserFavorite				: ( ) => dispatch( addUserFavorite( ) ),
	//addUserFavoriteSuccess		: bindActionCreators( favoriteData => ( addUserFavoriteSuccess( favoriteData ) ), dispatch ),
	addUserFavoriteSuccess		: ( ) => dispatch( addUserFavoriteSuccess( ) ),
	addUserFavoriteFailure		: ( ) => dispatch( addUserFavoriteFailure( ) ),
	removeUserFavorite			: ( ) => dispatch( removeUserFavorite( ) ),
	removeUserFavoriteSuccess	: favoriteData => dispatch( removeUserFavoriteSuccess( favoriteData ) ),
	removeUserFavoriteFailure	: ( ) => dispatch( removeUserFavoriteFailure( ) ),
});

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( UserFavorite );