import React, { Component, Fragment } from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';


import {
	Grid,
	Button,
	IconButton
} from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


class CommunityMenu extends Component {
	
	constructor( props ) {
		
		super( props );
		
	}
	
	render() {
		return (
			<Grid
				className="w-100 h-100 m-0 p-0"
				
				container
				
				direction="row"
				justify="space-between"
				alignItems="center"
				
				//spacing={1}
			>
				<Grid
					className="text-center"
					item
					//direction="column"
					//justify="center"
					//alignItems="center"
					
					xs={2}
					sm={2}
					md={2}
					lg={2}
					xl={2}
				>
					<IconButton
						className={
							"m-0 p-0 h-100"
						}
						color="primary"
						//href={"/"}
					>
						<Link 
							to="/"
						>
							<ChevronLeftIcon
								fontSize="large"
							/>
						</Link>
					</IconButton>
				</Grid>	
				
				<Grid
					className="text-center"
					
					item
					//direction="column"
					//justify="center"
					//alignItems="center"
					
					xs={8}
					sm={8}
					md={8}
					lg={8}
					xl={8}
				>
					{
						( 'cafe' === this.props.view )?
							String(this.props.name.split(' ').slice(1,).join(' '))  :	
							String(this.props.name.split(' ').slice(-3,-2).join(' ')) + ' 뉴스'
					}
				</Grid>	
					
				<Grid
					item
					//direction="column"
					//justify="center"
					//alignItems="center"
					
					xs={2}
					sm={2}
					md={2}
					lg={2}
					xl={2}
				>
					{
						" "
					}
				</Grid>	
			
			</Grid>
		);
	}
	
}

export default CommunityMenu;