import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';

import StarRatingContainer	from '../Utility/StarRating/StarRatingContainer.js';
import CardList				from '../Utility/CardList';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Box,
	Container,
	Grid,
	Typography,
	Button,
	IconButton,
	
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	
	TextField,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon		from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
	Favorite		as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
	ArrowBackIos	as ArrowBackIosIcon
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
}));


const MAX_STAR_COUNT	= 5;

function UserReviewModification ( props ) {
	
	const classes	= useStyles();
	
	const [starRate, setStarRate]			= React.useState(0);
	
	const handleStarRate = ( value ) => {
		
		if ( 0 > value ) {
			setStarRate( 0 );
		} else if ( 5 < value ) {
			setStarRate( MAX_STAR_COUNT );
		} else {
			setStarRate( value );
		}
		
	};
	
	
	const [reviewContent, setReviewContent]			= React.useState("");
	
	const handleReviewContent	= ( e )	=>	{
		e.preventDefault();
		setReviewContent( e.target.value );
	};
	
	//console.log( props );
	//console.log( starRate );
	
	
	return (
		<Fragment>
			<DialogTitle
				className={
					clsx(
						classes.noMargin,
						classes.paddingY,
						classes.padding2X,
						//classes.paddingX,
						//classes.paddingSmallY,
					)
				}
			>
				{
					props.title
				}
			</DialogTitle>
			<DialogContent 
				dividers
				
				className={
					clsx(
						//classes.noMargin,
						//classes.paddingX,
						classes.noGutters,
					)
				}
			>
				{
					( "rating" === props.ratingType ) ?
						(
							<ThemeProvider
								theme={markerInfoTheme}
							>
								<Box
									bgcolor={
										( "ulzip" === props.type ) ?
											"secondary.main" :
											(
												( "kinder" === props.type ) ?
													"primary.main" : ""
											)
									}
									
									//borderTop={2}
									
									borderColor={
										( "ulzip" === props.type ) ?
											"secondary.contrastText" :
											(
												( "kinder" === props.type ) ?
													"primary.contrastText" : ""
											)
									}
									
									color={
										( "ulzip" === props.type ) ?
											"secondary.contrastText" :
											(
												( "kinder" === props.type ) ?
													"primary.contrastText" : ""
											)
									}
								>
									<Grid
										container
										direction="row"
										justify="center"
										alignItems="center"
									>
										<Grid
											item
											
											xs={12}
											sm={12}
											md={12}
											lg={12}
											xl={12}
											
											className={
												clsx(
													classes.textCenter,
												)
											}
										>
											<StarRatingContainer
												fontSize={"large"}
												handleStarRate={handleStarRate}
												editable={true}
												
												ratingMaxCount={MAX_STAR_COUNT}
											/>
										</Grid>
									</Grid>
								</Box>
							</ThemeProvider>
						) : null
				}
				
				<Container
					maxWidth="sm"
					
					className={
						clsx(
							classes.noMargin,
							classes.paddingX,
						)
					}
				>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Grid
							item
							
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							
							<TextField
								//label="본 문"
								label=""
								multiline
								rows="10"
								rowsMax="100"
								
								placeholder={
									"악의적인 비방이나 욕설, 허위사실을 포함한 후기는 자동으로 삭제됩니다. 다음과 같은 내용으로 작성해주시면 다른 학부모님께 도움이 됩니다. (학부모님의 생생한 후기, 아이의 호감도, 원장 선생님 경력, 부엌/화장실/장난감 위생상태, 커리큘럼, 야외활동, 어린이집 놀이터 보유 여부, 식사분위기, 원장/대표 일치여부, 보육교사 경력/연륜, 조리사 존재여부, 차량운행 여부, 식자재 등)"
								}
								
								value={reviewContent}
								onChange={handleReviewContent}
								
								//margin="normal"
								margin="dense"
								variant="outlined"
								
								className={classes.textField}
								
								style={{
									width:"100%",
								}}
							/>
							
						</Grid>
					</Grid>
				</Container>
				
			</DialogContent>
			<DialogActions
				className={
					clsx(
						classes.noMargin,
						classes.paddingX,
						classes.paddingSmallY,
						classes.textRight,
					)
				}
			>
				<ThemeProvider
					theme={markerInfoTheme}
				>
					<Box
						color={
							"third.light"
							//"third.main"
							//"fourth.main"
						}
					>
						<Button 
							color={"inherit"}
							className={
								clsx(
									classes.smallButton,
								)
							}
							onClick={props.others.closeDialog}
						>
							{
								"취 소"
							}
						</Button>
					</Box>
					
					
					<Button 
						//autoFocus 
						
						//variant="contained"
						variant="outlined"
						color="primary"
						
						className={
							clsx(
								classes.smallButton,
								classes.marginLeftSmall,
							)
						}
						onClick={
							async (e)	=> {
								
								//console.log(
								//	{
								//		ratingType		: props.ratingType,
								//		reviewContent	: reviewContent,
								//		starRate		: starRate,
								//	}
								//);
								
								const activeFunctionResult	= await props.activeFunction(  
									{
										contentId		: props.contentId,
										ratingType		: props.ratingType,
										reviewContent	: reviewContent,
										starRate		: starRate,
									}
								);
								
								//console.log( activeFunctionResult );
								
								if ( "success" === activeFunctionResult ) {
									props.others.closeDialog();
								}
								
							}
						} 
					>
						{
							"수 정"
						}
					</Button>
				</ThemeProvider>
			</DialogActions>
		</Fragment>
	);
	
}


function UserReviewRemoval ( props ) {
	
	const classes	= useStyles();
	
	//console.log( props );
	//console.log( starRate );
	
	
	return (
		<Fragment>
			<DialogTitle
				className={
					clsx(
						classes.noMargin,
						classes.paddingY,
						classes.padding2X,
						//classes.paddingX,
						//classes.paddingSmallY,
					)
				}
			>
				{
					props.title
				}
			</DialogTitle>
			<DialogContent 
				dividers
				
				className={
					clsx(
						//classes.noMargin,
						//classes.paddingX,
						classes.noGutters,
					)
				}
			>
				
				<Container
					maxWidth="sm"
					
					className={
						clsx(
							classes.noMargin,
							classes.paddingX,
						)
					}
				>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Grid
							item
							
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
						>
							
							<Typography
								component="p"
								variant="body2"
								color="inherit"
							>
								{
									"우리 아이의 후기는 이웃 부모님과 아이에게 큰 도움이 됩니다. 정말로 삭제하시겠어요?"
								}
							</Typography>
							
						</Grid>
					</Grid>
				</Container>
				
			</DialogContent>
			<DialogActions
				className={
					clsx(
						classes.noMargin,
						classes.paddingX,
						classes.paddingSmallY,
						classes.textRight,
					)
				}
			>
				<ThemeProvider
					theme={markerInfoTheme}
				>
					<Box
						color={
							"third.light"
							//"third.main"
							//"fourth.main"
						}
					>
						<Button 
							color={"inherit"}
							className={
								clsx(
									classes.smallButton,
								)
							}
							onClick={props.others.closeDialog}
						>
							{
								"취 소"
							}
						</Button>
					</Box>
				</ThemeProvider>
				
				<ThemeProvider
					theme={markerInfoTheme}
				>
					<Box
						color={
							"error.light"
							//"third.main"
							//"fourth.main"
						}
						clone={true}
					>
						<Button 
							//autoFocus 
							
							//variant="contained"
							variant="outlined"
							color="inherit"
							
							className={
								clsx(
									classes.smallButton,
									classes.marginLeftSmall,
								)
							}
							onClick={
								async (e)	=> {
									
									//console.log(
									//	{
									//		ratingType		: props.ratingType,
									//		reviewContent	: reviewContent,
									//		starRate		: starRate,
									//	}
									//);
									
									const activeFunctionResult	= await props.activeFunction(  
										{
											contentId		: props.contentId,
											ratingType		: props.ratingType,
											//reviewContent	: reviewContent,
											//starRate		: starRate,
										}
									);
									
									//console.log( activeFunctionResult );
									
									if ( "success" === activeFunctionResult ) {
										props.others.closeDialog();
									}
									
								}
							} 
						>
							{
								"삭 제"
							}
						</Button>
					</Box>
				</ThemeProvider>
			</DialogActions>
		</Fragment>
	);
	
}



export default function UserReviews ( props ) {
	
	const classes	= useStyles();
	
	//console.log( props );
	
	
	let cardList	= [];
	let cardType	= "basic";
	
	if ( "communityInfo" === props.reviewType ) {
		
		if (
			null		!==	props.userReviews	&&
			undefined	!== props.userReviews	&&
			0			<	props.userReviews.length
		) {
			cardList	= props.userReviews.map(
				( card ) => {
					
					let ret_obj	= {
						dataType:"",	
						
						title:"",
						avatar:undefined,
						header:"",
						description:"",
						
						source_name:"",
						source_url:"",
						source_detail_url:"",
						
						source_img:"",
						source_detail_img:"",
					};
					
					ret_obj.dataType	= "naverCafe";
					ret_obj.title		= card.title;
					ret_obj.avatar		= "카페검색";
					ret_obj.description	= card.description;
					ret_obj.source_name	= card.cafename;
					ret_obj.source_url			= card.cafeurl;
					ret_obj.source_detail_url	= card.link;
					
					//console.log( card );
					
					return ret_obj;
				}
			);
		}
		
	} else if ( "ratingReviews" === props.reviewType ) {
		
		cardType	= "userRating";
		
		if (
			null		!==	props.userReviews	&&
			undefined	!== props.userReviews	&&
			0			<	props.userReviews.length
		) {
			cardList	= props.userReviews.map(
				( card ) => {
					
					let ret_obj	= {
						dataType:"",	
						
						title:"",
						avatar:undefined,
						header:"",
						description:"",
						
						source_name:"",
						source_url:"",
						source_detail_url:"",
						
						source_img:"",
						source_detail_img:"",
						
						contentId:undefined,
						
						nickname:"",
						user_id:"",
						update_date:"",
						myWritingYN:false,
						
						activeFunction:undefined,
						param_for_active_function:undefined,
						
						menu	: [],
					};
					
					//ret_obj.dataType	= "naverCafe";
					//ret_obj.avatar		= "카페검색";
					
					//console.log(card);
					
					if ( "rating" === card.rating_type ) {
						ret_obj.dataType	= card.rating_type;
						ret_obj.avatar		= "등원후기";
					} else if ( "sharing" === card.rating_type ) {
						ret_obj.dataType	= card.rating_type;
						ret_obj.avatar		= "상담후기";
					} else {
						
						if ( 
							true	===	Number.isInteger( card.star_rate )	&&
							0		<	Number( card.star_rate )
						) {
							ret_obj.dataType	= "rating";
							ret_obj.avatar		= "등원후기";
							//card.rating_type	= "rating";
						} else {
							ret_obj.dataType	= "sharing";
							ret_obj.avatar		= "상담후기";
							//card.rating_type	= "sharing";
						}
						
					}
					
					ret_obj.title		= card.star_rate;
					ret_obj.description	= card.review_content;
					
					//ret_obj.source_name	= card.cafename;
					//ret_obj.source_url			= card.cafeurl;
					//ret_obj.source_detail_url	= card.link;
					
					ret_obj.contentId	= card._id;
					
					
					// `20.11.16 이용준
					// 상식형 요청으로 인하여 상세페이지 등원,상담후기에서 닉네임 제거
					//ret_obj.nickname	= "";
					// `20.11.17 이용준
					// 상식형 협의 후에 닉네임 제거 시 후기의 신뢰가 떨어지는거 같아 다시 복원
					ret_obj.nickname	= card.nickname;
					
					
					
					ret_obj.user_id		= card.user_id;
					ret_obj.update_date	= card.update_date;
					
					//console.log( ret_obj );
					//console.log( card );
					
					if ( card.user_id === props.userInfo.userId ) {
						ret_obj.myWritingYN	= true;
						
						//ret_obj.activeFunction	= ( 
						//	( e ) => {
						//		
						//		console.log( e );
						//		
						//	}
						//);
						
						//const modifyUserRating	= ( e )	=> {
						//	console.log( "modifyUserRating!!!" );
						//};
						
						ret_obj.menu.push(
							{
								name	: "modifyUserRating",
								nameKor	: "내 후기 수정",
								activeFunction	: props.modifyUserRatingInfo,
								//component	: null,
								component	: (
									( param ) => {
										return	(
											<UserReviewModification
												type={props.type}	// ulzip, kinder
												userInfo={props.userInfo}
												
												title={
													//"내 후기 수정"
													( "rating" === ret_obj.dataType ) ?
														"등원 후기" : (
															( "sharing" === ret_obj.dataType ) ?
																"상담 후기" : "내 후기 수정"
														)
												}
												ratingType={
													//card.rating_type
													ret_obj.dataType
												}
												
												contentId={ret_obj.contentId}
												
												activeFunction={props.modifyUserRatingInfo}
												others={param}
											/>
										);
									}
								),
							}
						);
						
						ret_obj.menu.push(
							{
								name	: "removeUserRating",
								nameKor	: "내 후기 지우기",
								activeFunction	: props.removeUserRatingInfo,
								//component	: null,
								component	: (
									( param ) => {
										return	(
											<UserReviewRemoval
												type={props.type}	// ulzip, kinder
												userInfo={props.userInfo}
												
												title={
													"내 후기 지우기"
													//( "rating" === ret_obj.dataType ) ?
													//	"등원 후기" : (
													//		( "sharing" === ret_obj.dataType ) ?
													//			"상담 후기" : "내 후기 지우기"
													//	)
												}
												ratingType={
													//card.rating_type
													ret_obj.dataType
												}
												
												contentId={ret_obj.contentId}
												
												activeFunction={props.removeUserRatingInfo}
												others={param}
											/>
										);
									}
								),
							}
						);
						
					}
					//console.log( card );
					
					return ret_obj;
				}
			);
		}
	} else {
		
	}
	
	//console.log( cardType );
	//console.log( cardList );
	
	return (
		<Fragment>
			<CardList
				id={props.reviewType}
				data={
					//cardList.slice(0, 3)
					cardList
				}
				cardType={cardType}
				overflowYScrollYN={props.overflowYScrollYN}
			/>
		</Fragment>
	);
	
}