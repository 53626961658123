import React, {
	Component,
	Fragment
} from 'react';

import clsx from 'clsx';
import axios from 'axios';

import { connect } from 'react-redux';

import {
	login,
	loginSuccess,
	loginFailure,
	logout,
	logoutSuccess,
	logoutFailure
}
from '../../store/modules/userAuthentication';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';


import { 
	fade,
	makeStyles,
	withStyles
} from '@material-ui/core/styles';


import {
	Box,
	Modal,
	Backdrop,
	Button,
	IconButton,
	Grid,
	Input,
	TextField,
	Typography,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CloseIcon from '@material-ui/icons/Close';

import {
	withdrawUser,
} from './IPickUser.js';

const useStyles = makeStyles( (theme) => (
	{
		button:{
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		smallButton:{
			margin: theme.spacing(0),
			padding: theme.spacing(0.5)
		},
		noPadding:{
			padding: theme.spacing(0)
		},
		noMargin:{
			margin: theme.spacing(0)
		},
		noGutters:{
			margin: theme.spacing(0),
			padding: theme.spacing(0)
		},
		margin:{
			margin: theme.spacing(1),
		},
		padding:{
			padding: theme.spacing(1),
		},
		paddingX:{
			paddintLeft: theme.spacing(1),
			paddintRight: theme.spacing(1),
		},
		paddingTopSmall:{
			paddingTop:theme.spacing(0.5),	
		},
		textCenter:{
			textAlign:"center"
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: theme.spacing(0),
			padding: theme.spacing(1),
		},
		modalBox: {
			margin: theme.spacing(0),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(1),
			maxWidth:"95%",
			backgroundColor:"white"
		},
		card:{
			margin: theme.spacing(0),
			padding: theme.spacing(1),
			maxWidth:476
		},
		cardHeader:{
			textAlign:"center",
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		cardContent:{
			margin: theme.spacing(0),
			paddingLeft:theme.spacing(2),
			paddingRight:theme.spacing(2),
			paddingTop:theme.spacing(1),
			paddingBottom:theme.spacing(1),
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1)
		},
		bgLight:{
			backgroundColor:"#f8f9fa !important"
		},
		roundedPill:{
			borderRadius:"50rem !important"
		},
		textSmall:{
			fontSize:"80%"
		}
	}
));


function WithdrawUserPanel ( props ) {
//export default function WithdrawUserPanel ( props ) {
	
	const classes	= useStyles();
	
	const [checkWithdrawUser, toggleCheckWithdrawUser] = React.useState(false);
	
	
	return(
		
		<Fragment>
			<Modal
				
				className={classes.modal}
				open={props.isOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				
			>
				<Box 
					className={
						clsx(
							classes.modalBox,
						)
					}
					borderRadius="borderRadius"
					
				>
					<form
						
					>
						
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
							>
								<Grid
									item
									
									style={{
										textAlign:"center"
									}}
								>
									<IconButton
										className={
											clsx(
												classes.button,
												classes.noGutters,
											)
										}
										
										size="small"
										
										onClick={props.toggleWithdrawUserPanelOpen}
									>
										<CloseIcon 
											fontSize="small"
										/>
									</IconButton>
								</Grid>
							</Grid>
							<Grid
								item
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Grid
									item
									
									xs={12}	
									sm={12}
									md={12}
									lg={12}
									xl={12}
								>
									<Typography 
										component="p"
									>
										{
											"정말로 탈퇴하시겠습니까?"
										}
									</Typography>
								</Grid>
							</Grid>
							
							<Grid
								item
								container
								
								direction="row"
								justify="flex-end"
								//justify="center"
								alignItems="center"
								
								spacing={1}
							>
								
								<Grid
									className={
										clsx(
											//classes.textCenter,
										)
									}
									
									item
									
									//xs={10}
									//sm={10}
									//md={10}
									//lg={10}
									//xl={10}
								>
									
									<FormControlLabel 
										className={
											clsx(
												classes.noMargin,
											)
										}
										control={
											<Checkbox 
												value={checkWithdrawUser}
												onChange={
													(e)=>{
														toggleCheckWithdrawUser( !checkWithdrawUser );
													}
												}
												color="primary"
											/>
										} 
										label="네. 탈퇴하고 싶어요" 
									/>
									
								</Grid>
								<Grid
									className={classes.textCenter}
									
									item
									
									//xs={2}
									//sm={2}
									//md={2}
									//lg={2}
									//xl={2}
								>
									<Button
										className={
											clsx(
												classes.smallButton,
											)
										}
										
										variant="outlined"
										
										size="small"
										onClick={
											async (e) => {
												
												let msg			= "";
												let errMsgType	= "warning";
												let result	= null;
												
												if ( true !== checkWithdrawUser ) {
													
													msg			= "체크박스에 동의가 필요합니다."
													errMsgType	= "warning";
													
													props.createErrorMsg(
														msg,
														errMsgType
													);
													
													return;
												}
												
												
												try {
													
													props.logout();
													
													const withdrawResult	= await withdrawUser();
													
													//console.log( withdrawResult.data );
													
													result		= withdrawResult.result;
													msg			= withdrawResult.msg;
													errMsgType	= withdrawResult.msgType;
													
												} catch ( err ) {
													
													console.log( err );
													msg			= "회원탈퇴 처리 중에 오류가 발생했습니다."
													errMsgType	= "error";
													
												} finally {
													
													if ( "" !== msg ) {
														props.createErrorMsg(
															msg,
															errMsgType
														);
													} 
													
													if ( "success" === result ) {
														
														props.logoutSuccess();
														
														//setTimeout(
														//	props.getUserLoginInfo()
														//	, 2000
														//);
														
														props.closeLoginPanel();
														
													} else {
														props.logoutFailure();
													}
												}
												
											}
										}
									>
										{
											"탈퇴"
										}
									</Button>
								</Grid>
							</Grid>	
							
						</Grid>
						
					</form>
					
				</Box>
			</Modal>
		</Fragment>
		
	);
	
}


// props 로 넣어줄 스토어 상태값
const mapStateToProps = state => ({
	userInfo: state.userAuthentication.userInfo
});

// props 로 넣어줄 액션 생성함수
const mapDispatchToProps = dispatch => ({
	login			: ( ) => dispatch( login( ) ) ,
	loginSuccess	: userInfo => dispatch( loginSuccess( userInfo ) ) ,
	loginFailure	: ( ) => dispatch( loginFailure( ) ) ,
	logout			: ( ) => dispatch( logout( ) ) ,
	logoutSuccess	: ( ) => dispatch( logoutSuccess( ) ) ,
	logoutFailure	: ( ) => dispatch( logoutFailure( ) ) ,
});

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( WithdrawUserPanel );