import React, { Component, Fragment } from 'react';

import { 
	isMobileOnly, 
	isTablet,
	isIPad13,
} from 'react-device-detect';

import clsx		from "clsx";

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Box,
	Card,
	CardContent,
	CardHeader,
	CardActions,
	Divider,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
	Favorite		as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
	MoreVert		as MoreVertIcon,
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	avatar: {
		backgroundColor: red[500],
 	},
	
	dialogPaper: 
		( true === isMobileOnly ) ?
			{
				margin	: theme.spacing(0),
				minWidth: "90vw",
			} :
			{
				margin: theme.spacing(0),
			},
}));



export default function TermsDetail ( props ) {
	
	const classes	= useStyles();
	
	return (
		<Fragment>
			<Box
				//clone={true}
				
				m={0}
				p={0}
				
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				
				bgcolor="white"
				
				style={{
					//width:"100%",
					height:
						(
							true	=== isMobileOnly	||
							true	=== isTablet		||
							true	===	isIPad13	
						) ? "calc( 100vh - 3rem )" : "100vh",
				}}
			>
				{
				//<Container
				//	className={
				//		clsx(
				//			classes.noGutters,
				//		)
				//	}
				//	
				//	style={{
				//		width:"100%",
				//		height:"100%",
				//	}}
				//>
				}
					<div
						className={
							clsx(
								classes.padding,
								classes.shrink,
								//classes.overflowYScroll,
								classes.textCenter,
							)
						}
						
						style={{
							width:"100%",
						}}
					>
						<Typography
							component="h4"
							variant="h4"
							
							className={
								clsx(
									classes.margin2Y,
								)
							}
						>
							{'아이픽 이용약관'}
						</Typography>
					</div>
					<div
						className={
							clsx(
								classes.padding2,
								classes.grow,
								classes.overflowYScroll,
							)
						}
						
						//style={{
						//	width:"100%",
						//}}
					>
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											'제1조. 목적'
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										 '이 약관은 주식회사 아이픽(이하 “회사”)이 운영하는 아이픽 서비스(이하 “서비스”)를 이용함에 있어 회사와 회원간의 권리ㆍ의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.'
									}
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											'제2조. 용어의 정의'
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{'이 약관에서 사용하는 용어의 의미는 다음과 같습니다.'}
								</Typography>
								
								<br/>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									 {'"서비스"라 함은 아이픽 브랜드를 사용하는 서비스를 의미합니다.'}
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										'"회원"이라 함은 이 약관에 따라 회사와 이용 계약을 체결하고 회사로부터 서비스 이용 자격을 부여 받은 자를 의미합니다.'
									}
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
								        '"아이디(ID)"라 함은 아이픽 서비스에서 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인한 전자우편주소를 의미합니다.'
								    }
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										'"비밀번호"라 함은 아이픽 서비스에서 회원의 비밀보호를 위하여 회원이 설정한 문자, 숫자, 기호의 조합을 의미합니다.'
								    }
								</Typography>

								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										'"게시물"이라 함은 서비스 내에 회사와 회원이 게시한 글, 사진, 그림, 영상, 프로젝트, 각종 파일과 링크, 답글, 댓글 등 정보의 총칭을 의미합니다.'
								    }
								</Typography>
								
								<br />
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											//classes.padding3X,
										)
									}
								>
									{
										'이 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 서비스 안내 및 관련법령 또는 상관례에서 정하는 바에 따릅니다.'
									}
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											'제3조. 약관의 게시와 개정'
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										'회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스의 초기화면 또는 링크로 연결된 화면에 게시합니다.'
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										'회사는 “콘텐츠산업진흥법”, “전자상거래등에서의 소비자보호에 관한 법률”, “약관의규제에 관한 법률” 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.'
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										'회사가 이 약관을 개정할 경우에는 약관의 적용일자 및 개정내용, 개정 사유 등을 명시하여 현행 약관과 함께 서비스 초기화면에 그 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하거나 중대한 사항을 변경하는 경우에는 그 적용일자 30일 전부터 적용일자 전일까지 공지하고, 회원의 전자우편, 로그인 시 동의 창 등의 전자적 수단을 통해 별도로 통지합니다.'
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										'회사가 전 항에 따라 개정약관을 공지 또는 통지하면서 회원에게 적용일자 전까지 의사표시를 하지 않으면 약관의 개정에 동의한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의사를 표명하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.'
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										'회원이 개정약관의 적용에 동의하지 않으면 회사는 해당 회원에 대해 개정 약관의 내용을 적용할 수 없으며, 이 경우 회사 또는 회원은 이용계약을 해지할 수 있습니다.'
									}
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											'제4조. 약관 외 준칙'
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										'회사는 개별 서비스 별로 별도의 이용약관 및 정책(이하 “개별 약관 등”)을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 개별 약관 등이 우선하여 적용됩니다.'
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
 									'이 약관에 명시되지 않은 사항은 개별 약관 및 전기통신기본법, 전기통신사업법, 독점규제 및 공정거래에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련법령의 규정 또는 상관례에 의합니다.'
 									}
 								</Typography>
								<br/>
 								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
 									{
 										'이 약관과 관련하여 회사의 정책 변경, 법령의 제/개정 또는 공공기관의 고시나 지침 등에 의하여 회사가 서비스의 공지사항 게시판 등을 통해 공지하는 내용도 이용계약의 일부를 구성합니다.'
 									}
 								</Typography>
							</CardContent>
						</Card>
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											'제5조. 서비스 이용계약 체결 등'
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										'서비스 이용계약은 회원이 되고자 하는 자(이하 “이용 신청자”)가 회사가 제공하는 이용 신청 절차와 방법에 따라 서비스 이용신청을 하고, 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.'
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
									    '회사는 이용신청자가 이용신청 시 이 약관에 동의하고, 회사가 정한 신청 절차와 방법을 준수하는 경우 그 이용신청에 대하여 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지 않거나, 사후에 이용계약을 취소할 수 있습니다.'
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										'기재 내용에 허위, 누락, 오기 등이 있는 경우 '
									}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
 									{
 									    '회사로부터 이용정지 당한 회원이 그 이용정지 기간 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우'
 									}
 								</Typography>
 								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
 									{'과거에 이 약관 위반 등의 사유로 서비스 이용계약이 해지 당한 이력이 있는 경우'}
 								</Typography>
 								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
 									{'법령, 공공의 안녕질서 및 미풍양속을 해할 우려가 있는 경우'}
 								</Typography>
 								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
 									{'부정한 용도로 서비스를 이용하고자 하는 경우'}
 								</Typography>
 								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
 									{'회사의 사전 동의 없이 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우'}
 								</Typography>
 								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
 									{'서비스를 제공하지 않는 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우'}
 								</Typography>
 								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
 									{'인증없이 누구나 접근이 가능한 일회용 이메일을 통해 회원가입을 하는 경우'}
 								</Typography>
 								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
 									{'기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우'}
 								</Typography>
 								<br />
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											//classes.padding3X,
										)
									}
								>
								    {'회사는 다음 각 호의 어느 하나에 해당하는 경우 승낙을 유보할 수 있습니다.'}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
								    {'천재지변으로 인한 설비의 장애 상태가 발생한 경우'}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
								    {'서비스를 제공함에 따라 회사의 전체 서비스 품질 유지에 곤란하다고 판단되는 경우'}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
								    {'서비스를 제공하기 위한 설비의 여유가 없거나 기술상 서비스 제공이 곤란한 경우'}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
								    {'회사의 운영상, 사업상 위해 요소 또는 위해 우려가 있는 경우'}
								</Typography>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
								    {'기타 회사의 사정으로 이용 승낙이 곤란한 경우'}
								</Typography>
								<br />
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											//classes.padding3X,
										)
									}
								>
								    {
								        '이 약관에 따른 이용계약의 성립 시기는 서비스 이용신청 절차 상에서 회사가 이용신청 완료를 표시한 시점으로 합니다.'
								    }
								</Typography>
							</CardContent>
						</Card>
						
						<Card 
							className={
								clsx(
									classes.paddingSmall,
									classes.marginX,
									classes.margin2Y,
								)
							}
							
							//style={{
							//	width:"100%"
							//}}
						>
							<CardHeader
								avatar={
									undefined
								}
								
								title={
									<Typography
										variant="h6"
										component="h6"
										className={
											clsx(
												classes.fontWeightBold,
											)
										}
									>
										{
											'제6조. 게시물 게시와 삭제, 숨김처리에 관한 사항'
										}
									</Typography>
								}
								
								//action={}
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingSmall,
									)
								}
							/>
							
							<Divider 
								variant="middle" 
								
								className={
									clsx(
										classes.noMargin,
									)
								}
							/>
							
							<CardContent
								className={
									clsx(
										classes.noMargin,
										classes.padding,
									)
								}
							>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
								>
									{
										'회사는 이 사이트를 이용하는 사용자와 관련자의 요청에 의해 다음과 같은 조치를 할 수 있습니다.'  
									}
								</Typography>
								<br/>
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										'이 사이트의 이용자는 타인의 명예를 훼손하는 글을 게시하는것을 금지하고 있습니다. 또한, 작성된 게시물의 대한 법적책임은 해당 게시물을 게시한 당사자에 있음을 알려드립니다.'
									}
									
								</Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										'게시물에 해당되는 당사자 혹은 기관의 요청에 의해 게시물을 숨김처리할 수 있습니다. 이 경우 이 사이트에 기관의 요청에 의해 게시물이 숨김처리 되었다고 표기합니다.'
									}
                                </Typography>
								
								<Typography
									component="p"
									variant="body2"
									color="inherit"
									
									className={
										clsx(
											classes.padding3X,
										)
									}
								>
									{
										'게시물 작성시 해당 기관과 관련없는 내용을 작성할 경우 사용자에게 고지하지 아니하고, 게시물을 숨김 또는 삭제 처리할 수 있습니다.'}
                                </Typography>
							</CardContent>
						</Card>
					</div>
				{
				//</Container>
				}
				
			</Box>
		</Fragment>
	);
	
}