import React, {
	Fragment
} from 'react';

import clsx from 'clsx';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';


import { 
	ThemeProvider 
} from '@material-ui/styles';


import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';


import {
	Box,
	Container,
	Button,
	IconButton,
	Grid,
	Typography,
	Badge,
	GridList,
	Paper,
	Collapse
} from '@material-ui/core';


//import AddBoxIcon from '@material-ui/icons/AddBox';
//import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
//import CloseIcon from '@material-ui/icons/Close';
//import UnfoldLessIcon	from '@material-ui/icons/UnfoldLess';

import {
	AddBox		as AddBoxIcon,
	AddIndeterminateCheckBoxBox		as IndeterminateCheckBoxIcon,
	Close		as CloseIcon,
	UnfoldLess		as UnfoldLessIcon,
} from '@material-ui/icons';

import IllegalInfoDetailUlzip	from './IllegalInfoDetailUlzip.js';
import IllegalInfoDetailPeople	from './IllegalInfoDetailPeople.js';

const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		third		: {
			main:"#616161"	// lightGrey
		},
		error		: {
			main:"#c62828",
			light:"#f44336",
			contrastText:"#ffffff",
		},
		warning		: {
			main:"#ffa000",
		},
	}
});

const useStyles = makeStyles( (theme) => (
	{
		grow: {
			flexGrow: 1,
		},
		shrink:{
			flexShrink:0
		},
		button:{
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		fab: {
			margin: theme.spacing(0),
			//padding: theme.spacing(1)
			//display: 'flex',
			//alignItems: 'center',
			//justifyContent: 'center'
		},
		noPadding:{
			padding: theme.spacing(0)
		},
		noMargin:{
			margin: theme.spacing(0)
		},
		noGutters:{
			margin: theme.spacing(0),
			padding: theme.spacing(0)
		},
		margin:{
			margin: theme.spacing(1),
		},
		marginX:{
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		marginY:{
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		marginSmall:{
			margin:theme.spacing(0.5),	
		},
		marginSmallY:{
			marginTop: theme.spacing(0.5),
			marginBottom: theme.spacing(0.5),
		},
		padding:{
			padding: theme.spacing(1),
		},
		paddingX:{
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
		padding2X:{
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
		paddingY:{
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		padding1_5Y:{
			paddingTop: theme.spacing(1.5),
			paddingBottom: theme.spacing(1.5),
		},
		padding2Y:{
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
		paddingSmall:{
			padding:theme.spacing(0.5)
		},
		paddingSmallX:{
			paddingLeft:theme.spacing(0.5),
			paddingRight:theme.spacing(0.5),
		},
		paddingSmallY:{
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(0.5),
		},
		textLeft:{
			textAlign:"left"
		},
		textCenter:{
			textAlign:"center"
		},
		textRight:{
			textAlign:"right",	
		},
		fontWeightBold:{
			fontWeight:700
		},
		fontWeightLight:{
			fontWeight:300
		},
		textSmall:{
			fontSize:"80%",
		},
		overflowYScroll:{
			overflowY:"scroll",
		},
		
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: theme.spacing(0),
			padding: theme.spacing(1),
		},
		modalBox: {
			margin: theme.spacing(0),
			padding: theme.spacing(1),
			maxWidth:"95%",
			backgroundColor:"white"
		},
		card:{
			margin: theme.spacing(0),
			padding: theme.spacing(1),
			maxWidth:476
		},
		cardHeader:{
			textAlign:"center",
			margin: theme.spacing(0),
			padding: theme.spacing(1)
		},
		cardContent:{
			margin: theme.spacing(0),
			paddingLeft:theme.spacing(2),
			paddingRight:theme.spacing(2),
			paddingTop:theme.spacing(1),
			paddingBottom:theme.spacing(1),
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1)
		},
		bgWhite:{
			backgroundColor:"white",
		},
		bgLight:{
			backgroundColor:"#f8f9fa !important"
		},
		borderBasic:{
			border:"1"
		},
		
		borderColorError:{
			borderColor:"#d32f2f"
		},
		backgroundColorError:{
			backgroundColor:"#d32f2f"
		},
		textWhite:{
			color:"white"
		}
	}
));



export default function IllegalInfoTitle ( props ) {
	
	const {
		allYN, currentAddressDepth1, currentLocInfoDetail, currentLocInfoTitle, toggleIllegalQueryMode, numOfData
	}	= props;
	
	const classes	= useStyles();
	
	return (
		<Fragment>
			<Container
				maxWidth="sm"
				
				className={
					clsx(
						classes.shrink,
						classes.noMargin,
						classes.paddingSmall,
					)
				}
			>
				<ThemeProvider
					theme={markerInfoTheme}
				>
					<Box
						border={0}
						borderRadius={4}
						
						bgcolor="error.light"
						color="error.contrastText"
						
						
						className={
							clsx(
								//classes.marginSmallY,
								classes.padding2X,
								classes.padding1_5Y,
							)
						}
					>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="flex-start"
							
						>
							<Grid
								item
								container
								direction="row"
								justify="center"
								alignItems="center"
								
							>
								<Grid
									item
									
									xs={8}
									sm={8}
									md={8}
									lg={8}
									xl={8}
									
								>
									
									<Typography
										variant="h6" 
										component="h6"
										
									>
										{
											( true === allYN ) ?
												currentAddressDepth1 + " 위반 정보" : 
												currentLocInfoTitle + " 위반 정보"
										}
									</Typography>
									
									
								</Grid>
								<Grid
									item
									
									xs={4}
									sm={4}
									md={4}
									lg={4}
									xl={4}
									
									className={
										clsx(
											//classes.textCenter,
											classes.textRight,
										)
									}
								>
									<Badge
										badgeContent={
											numOfData
										} 
										showZero
										
										color="error"
										
									>
										
										<Button
											
											variant="contained"
											//color="inherit"
											size="small"
											
											onClick={toggleIllegalQueryMode}
											
											className={
												clsx(
													classes.bgWhite,
												)
											}
										>
											
											<i 
												className="fas fa-filter"
												
												style={{
													marginLeft:"0.25rem",
													marginRight:"0.25rem",
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center'
												}}
											/>
											
											{
												( true === allYN ) ?
													//"시/군/구 조회" : "시/도 조회"
													(
														( undefined === currentLocInfoDetail[2] ) ? 
															(
																' ' + 
																currentLocInfoDetail[1] + 
																" "
															) :
															(
																' ' + 
																currentLocInfoDetail[1] + 
																currentLocInfoDetail[2] + 
																" "
															)
													) :
													(
														' ' + 
														currentLocInfoDetail[0] + 
														" "
													)
													
											}
											
										</Button>
									</Badge>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</ThemeProvider>
			</Container>
		</Fragment>
	);
	
}