import React, {
	Component,
	Fragment
} from 'react';

import {
	isMobileOnly,
	isTablet,
	isIPad13,
} from 'react-device-detect';

import NotFoundDetail	from './NotFound/NotFoundDetail.js';

import {
	AppBar,
	Tabs,
	Tab,
	Paper,
	Typography,
	Box,
	CircularProgress,
	Grid,
	GridList,
	Card,
	CardActions,
	CardActionArea,
	CardContent,
	Button,
	CardMedia,
	Divider,
	Container
} from '@material-ui/core';



class NotFound extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			errorMsg: ''
		};
		
		this.closeAlert		= this.closeAlert.bind(this);
		this.createErrorMsg	= this.createErrorMsg.bind(this);
	}

	closeAlert ( ) {
		this.setState({
			errorMsg: ''
		});
	}

    createErrorMsg( errorMsg, errorMsgType ) {
		this.setState({
			errorMsg	: errorMsg,
			errorMsgType:errorMsgType,
		});
    }
	
	
	render ( ) {
		
		return(
			
			<Fragment>
				
				<
				//Container
				div
					
					//maxWidth="xl"
					
					style={
						(
							true	=== isMobileOnly	||
							true	=== isTablet		||
							true	===	isIPad13	
						) ?
							(
								{
									position:"fixed",
									
									top:0,
									left:0,
									height:"calc( 100vh - 3rem )",
									
									margin:0,
									padding:0,
									
									overflow:"hidden",
								}
							) :
							(
								{
									position:"fixed",
									
									top:0,
									right:0,
									width:"calc( 100vw - 3.75rem )",
									height:"100vh",
									
									margin:0,
									padding:0,
									
									overflow:"hidden",
								}
							)
					}
				>
					<NotFoundDetail
						createErrorMsg={this.createErrorMsg}
					/>
				</div>
			</Fragment>
			
		);
	}
	
}

export default NotFound;