import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';

import { connect } from 'react-redux';
//import AdSense from 'react-adsense';
import {
	increasePageDepth,
	decreasePageDepth,
	setPageDepth,
} from '../../store/modules/currentPageSync';


import Profile				from '../../pages/Profile';

import UserReviewThumbnail	from './UserReviewThumbnail.js';
import UserReviewPage		from './UserReviewPage.js';
import UserReviewStarRatingSection	from './UserReviewStarRatingSection.js';
import UserReviewSubmit		from './UserReviewSubmit.js';
//import UserReviewFormCreator	from './UserReviewFormCreator.js';

import SharingInfoWithFriend	from '../SharingInfo/SharingInfoWithFriend.js';

//import StarRatingContainer	from '../Utility/StarRating/StarRatingContainer.js';


//import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	isIPad13,
	//isAndroid, isBrowser 
} from 'react-device-detect';

//import {
//	Divider,
//	Box,
//	Grid,
//	Button,
//} from '@material-ui/core';

//import { 
//	red 
//} from '@material-ui/core/colors';

//import Icon		from '@material-ui/core/Icon';
//import {
//	Close		as CloseIcon,
//	ChevronLeft		as ChevronLeftIcon,
//	ChevronRight	as ChevronRightIcon,
//	Visibility,
//	VisibilityOff,
//	UnfoldLess	as UnfoldLessIcon,
//	ExpandMore	as ExpandMoreIcon,
//	Favorite		as FavoriteIcon,
//	FavoriteBorder	as FavoriteBorderIcon,
//	ArrowBackIos	as ArrowBackIosIcon
//} from '@material-ui/icons';


class UserReviewDetail extends Component {
	
	constructor ( props ) {
		
		super( props );
		
		this.state	= {
			
			userInfo	:{
				loginYN:this.props.userInfo.loginYN,
				userId:this.props.userInfo.userId,
				userEmail:this.props.userInfo.userEmail,
				userNickname:this.props.userInfo.userNickname,
				friendShareLink:this.props.userInfo.friendShareLink,
				nicknameConfirmYN:this.props.userInfo.nicknameConfirmYN,
			},
			ratingYN	: false,
			currentPage	: this.props.currentPage,
			
			currentView	:"",
			
			isLoginPanelOpen	: false,
			isUserReviewSubmitOpen	: false,
			isUserReviewSubmitOpen2	: false,	
		};
		
		this.setView		= this.setView.bind(this);
		this.showMore		= this.showMore.bind(this);
		this.closeUserReviewPage	= this.closeUserReviewPage.bind(this);
		
		this.toggleLoginPanelOpen		= this.toggleLoginPanelOpen.bind(this);
		this.toggleUserReviewSubmitOpen		= this.toggleUserReviewSubmitOpen.bind(this);
		this.toggleUserReviewSubmitOpen2	= this.toggleUserReviewSubmitOpen2.bind(this);
		
	}
	
	componentDidMount ( ) {
		
	}
	
	componentDidUpdate( prevProps, prevState ) {
		
		if ( prevState.userInfo.loginYN !== this.state.userInfo.loginYN ) {
			
		}
		
		if ( prevState.ratingYN !== this.state.ratingYN ) {
			
		}
		
	}
	
	static getDerivedStateFromProps( nextProps, prevState ) {
		
		if ( 
			prevState.userInfo.loginYN				!== nextProps.userInfo.loginYN		||
			prevState.userInfo.userId				!==	nextProps.userInfo.userId		||
			prevState.userInfo.userEmail			!== nextProps.userInfo.userEmail	||
			prevState.userInfo.userNickname			!== nextProps.userInfo.userNickname	||
			prevState.userInfo.nicknameConfirmYN	!== nextProps.userInfo.nicknameConfirmYN
		) {
			return { 
				userInfo: {
					//...userInfo,
					loginYN	: nextProps.userInfo.loginYN,
					userId	: nextProps.userInfo.userId,
					userEmail: nextProps.userInfo.userEmail,
					userNickname: nextProps.userInfo.userNickname,
					friendShareLink: nextProps.userInfo.friendShareLink,
					nicknameConfirmYN : nextProps.userInfo.nicknameConfirmYN,
				}
			};
		}
		
		if ( 
			prevState.ratingYN	!== nextProps.ratingYN
		) {
			return {
				ratingYN	: nextProps.ratingYN
			};
		}
		
		if ( 
			prevState.currentPage.data	!== nextProps.currentPage.data	||
			prevState.currentPage.depth	!== nextProps.currentPage.depth
		) {
			return {
				currentPage	: nextProps.currentPage
			};
		}
		
		return null;
	}
	
	setView ( view ) {
		
		if (
			""		===	this.state.currentView				||
			"ratingReviews"		===	this.state.currentView	||
			"communityInfo"		===	this.state.currentView
		) {
			
			if ( view !== this.state.currentView ) {
				
				if (
					"ratingReviews"		===	view	||
					"communityInfo"		===	view
				) {
					this.props.setPageDepth(
						{
							data	: this.state.currentPage.data,
							depth	: 2,
						}
					);
				} else {
					this.props.setPageDepth(
						{
							data	: this.state.currentPage.data,
							depth	: 1,
						}
					);
				}
				
				this.setState({
					currentView	: view
				});
				
			}
			
		} else {
			return;
		}
		
	}
	
	closeUserReviewPage ( ) {
		
		this.setView("");
		
	}
	
	showMore ( view ) {
		
		if ( false	=== this.state.userInfo.loginYN ) {
			this.props.createErrorMsg(
				"로그인 후 어린이집 '등원후기' 또는 '상담후기'를 작성하시면 더 많은 후기 및 맘카페글을 보실 수 있습니다.",
				"info"
			);
			
			return;
		} else {
			
			if ( false	=== this.state.ratingYN ) {
				
				let tempStr	= "어린이집 ";
				
				if ( "ulzip" === this.props.type ) {
					tempStr	= "어린이집 ";
				} else if ( "kinder" === this.props.type ) {
					tempStr	= "유치원 ";
				} else {
					
				}
				
				this.props.createErrorMsg(
					tempStr +
					"'등원후기' 또는 '상담후기'를 작성하시면 더 많은 후기 및 맘카페글을 보실 수 있습니다.",
					"info"
				);
			} else {
				this.setView( view );
			}
			
		}
		
	}
	
	
	toggleLoginPanelOpen ( ) {
		this.setState({
			isLoginPanelOpen	: !this.state.isLoginPanelOpen
		});
	}
	
	
	toggleUserReviewSubmitOpen ( ) {
		this.setState({
			isUserReviewSubmitOpen	:!this.state.isUserReviewSubmitOpen
		})
	}
	
	
	toggleUserReviewSubmitOpen2 ( ) {
		this.setState({
			isUserReviewSubmitOpen2	:!this.state.isUserReviewSubmitOpen2
		})
	}
	
	
	render ( ) {
		
		//console.log( this.state.currentPage );
		
		return (
			<Fragment>
				
				{
					( 
						true	===	this.state.isLoginPanelOpen	&&
						false	===	this.state.userInfo.loginYN
					) ?
						(
							<Profile
								isPopUp={this.state.isLoginPanelOpen}
								toggleLoginPanelOpen={this.toggleLoginPanelOpen}
								
								createErrorMsg={this.props.createErrorMsg}
							/>
						) : null
				}
				
				{
					//<AdSense.Google
					//	client='ca-pub-9712191476596348'
					//	slot='1388569643'
					//	style={{ width: 450, height: 50, float:'left',display: 'inline-block' }}
					//	format=''
					///>
				}
				
				
				<UserReviewPage
					userInfo={this.props.userInfo}
					type={this.props.type}	// ulzip, kinder
					
					dataType={this.state.currentView}	// ratingReviews, communityInfo
					data={
						//this.props.ratingReviews
						( "ratingReviews" === this.state.currentView ) ?
							this.props.ratingReviews :
							(
								( "communityInfo" === this.state.currentView ) ?
									this.props.communityInfo : null
							)
					}
					
					title={this.props.title}
					zIndex={2}
					
					headerInfo={
						<Fragment>
							{
								( 
									undefined	===	this.props.ratingInfo.ratingValue	||
									null		===	this.props.ratingInfo.ratingValue	||
									0			>=	this.props.ratingInfo.ratingValue 
								) ?
									null : (
										<UserReviewStarRatingSection
											type={this.props.type}
											ratingInfo={this.props.ratingInfo}
											
											createErrorMsg={this.props.createErrorMsg}
										/>
									)
							}
							
							
							<UserReviewSubmit
								title={this.props.title}
								type={this.props.type}
								userInfo={this.props.userInfo}
								
								ratingYN={this.props.ratingYN}
								ratingThisYN={this.props.ratingThisYN}
								ratingInfo={this.props.ratingInfo}
								
								toggleLoginPanelOpen={this.toggleLoginPanelOpen}
								createErrorMsg={this.props.createErrorMsg}
								
								submitUserRatingInfo={this.props.submitUserRatingInfo}
								submitUserSharingInfo={this.props.submitUserSharingInfo}
								
								isUserReviewSubmitOpen={this.state.isUserReviewSubmitOpen2}
								toggleUserReviewSubmitOpen={this.toggleUserReviewSubmitOpen2}
							/>
						</Fragment>
					}
					headerInfoYN={ "ratingReviews" === this.state.currentView }
					others={
						undefined
					}
					
					closeUserReviewPage={this.closeUserReviewPage}
					createErrorMsg={this.props.createErrorMsg}
					
					
					submitUserRatingInfo	= {this.props.submitUserRatingInfo}
					submitUserSharingInfo	= {this.props.submitUserSharingInfo}
					modifyUserRatingInfo	= {this.props.modifyUserRatingInfo}
					removeUserRatingInfo	= {this.props.removeUserRatingInfo}
				/>
				
				{
					( 
						undefined	===	this.props.ratingInfo.ratingValue	||
						null		===	this.props.ratingInfo.ratingValue	||
						0			>=	this.props.ratingInfo.ratingValue 
					) ?
						null : (
							<UserReviewStarRatingSection
								type={this.props.type}
								ratingInfo={this.props.ratingInfo}
								
								createErrorMsg={this.props.createErrorMsg}
							/>
						)
				}
				
				<UserReviewSubmit
					title={this.props.title}
					type={this.props.type}
					userInfo={this.props.userInfo}
					
					ratingYN={this.props.ratingYN}
					ratingThisYN={this.props.ratingThisYN}
					ratingInfo={this.props.ratingInfo}
					
					toggleLoginPanelOpen={this.toggleLoginPanelOpen}
					createErrorMsg={this.props.createErrorMsg}
					
					submitUserRatingInfo={this.props.submitUserRatingInfo}
					submitUserSharingInfo={this.props.submitUserSharingInfo}
					
					isUserReviewSubmitOpen={this.state.isUserReviewSubmitOpen}
					toggleUserReviewSubmitOpen={this.toggleUserReviewSubmitOpen}
				/>
				
				<SharingInfoWithFriend
					type={this.props.type}	// ulzip, kinder
					userInfo={this.props.userInfo}
					ratingYN={this.props.ratingYN}
					ratingThisYN={this.props.ratingThisYN}
					
					createErrorMsg={this.props.createErrorMsg}
				/>
				
				<UserReviewThumbnail
					type={this.props.type}	// ulzip, kinder
					userInfo={this.props.userInfo}
					
					reviewType={"ratingReviews"}
					userReviews={this.props.ratingReviews}
					
					
					showMore={this.showMore}
					showMoreButtonText={"평가 더보기"}
					
					//submitUserRatingInfo	= {this.props.submitUserRatingInfo}
					//submitUserSharingInfo	= {this.props.submitUserSharingInfo}
					modifyUserRatingInfo	= {this.props.modifyUserRatingInfo}
					removeUserRatingInfo	= {this.props.removeUserRatingInfo}
					
					createErrorMsg={this.props.createErrorMsg}
				/>
				
				<UserReviewThumbnail
					type={this.props.type}	// ulzip, kinder
					userInfo={this.props.userInfo}
					
					reviewType={"communityInfo"}
					userReviews={this.props.communityInfo}
					
					
					showMore={this.showMore}
					showMoreButtonText={"맘카페글 더보기"}
					
					//submitUserRatingInfo	= {this.props.submitUserRatingInfo}
					//submitUserSharingInfo	= {this.props.submitUserSharingInfo}
					modifyUserRatingInfo	= {this.props.modifyUserRatingInfo}
					removeUserRatingInfo	= {this.props.removeUserRatingInfo}
					
					createErrorMsg={this.props.createErrorMsg}
				/>
			</Fragment>
		);
		
	}
	
}


//export default UserReviewDetail;
// props 로 넣어줄 스토어 상태값
const mapStateToProps = state => ({
	currentPage: state.currentPageSync.currentPage
});

// props 로 넣어줄 액션 생성함수
const mapDispatchToProps = dispatch => ({
	increasePageDepth	: ( ) => dispatch( increasePageDepth( ) ),
	decreasePageDepth	: ( ) => dispatch( decreasePageDepth( ) ),
	setPageDepth		: ( currentPage ) => dispatch( setPageDepth( currentPage ) ),
});

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( UserReviewDetail );