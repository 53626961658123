import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';

import { 
	Link,
	withRouter
} from 'react-router-dom';

import { connect } from 'react-redux';

import {
	viewHome,
	viewCOMMUNITY,
	viewITUBE,
	viewPROFILE,
} from '../../store/modules/currentPageSync';

import clsx from 'clsx';

import { 
	//isMobile, 
	isIOS, 
	isMobileOnly, 
	isTablet,
	//isAndroid, 
	//isBrowser,
	isIPad13,
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	
	Button,
	IconButton,
	Box,
	Container,
	Grid,
	BottomNavigation,
	BottomNavigationAction,
	Typography,
	Icon,
	
} from '@material-ui/core';


import {
	Explore		as ExploreIcon,
	YouTube		as YouTubeIcon,
	LiveTv		as LiveTvIcon
} from '@material-ui/icons';



const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});

const StyledBottomNavigationAction = withStyles({
	root: {
		padding:0,
		// Some CSS
		"&:hover": {
			textDecoration:"none",
			padding:0,
			margin:0,
		},
		"&$selected": {
			paddingTop:0,
		},
		"&$iconOnly": {
			paddingTop:0,
		},
	},
	selected: {
		paddingTop:0,
	},
	iconOnly: {
		paddingTop:0,
	},
})(BottomNavigationAction);


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginSmall:{
		margin: theme.spacing(0.5)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textCenter:{
		textAlign:"center"
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	bottomNavBar:{
		position:"fixed",
		bottom:"0",
		width:"100vw",
		zIndex:"11",
	},
}));



function IPickNavBar ( props ) {
	
	const my_str	= String(window.location).split("/");
	
	
	let navbar_open	= false;
	let url_index	= -1;
	let first_index	= 0;
	
	if( 
		null		!== my_str	&&
		undefined	!== my_str	&&
		true		=== Array.isArray( my_str )
	) {
		for( let i = 0 ; i < my_str.length ; i++ ) {
			if( "m" === my_str[i] ) {
				navbar_open	= true;
				url_index	= i;
				break;
			}
		}
	}
	
	//console.log( my_str );
	
	if( url_index < my_str.length ) {
		if ( "Community" === my_str[url_index+1] ) {
			first_index	= 1;
		} else if ( "iTube" === my_str[url_index+1] ) {
			first_index	= 2;
		} else if ( "User" === my_str[url_index+1] ) {
			first_index	= 3;
		} else {
			first_index	= 0;
		}
	}
	
	//console.log( first_index );
	
	const classes	= useStyles();
	
	const [index, setIndex] = React.useState(first_index);
	
	if ( index !== first_index ) {
		
		if ( 1 === first_index ) {
			props.viewCOMMUNITY();
		} else if ( 2 === first_index ) {
			props.viewITUBE();
		} else if ( 3 === first_index ) {
			props.viewPROFILE();
		} else {
			props.viewHome();
		}
		
		setIndex( first_index );
		
	}
	
	
	const handleMenuNavigation	= ( e ) => {
		const newIndex	= e.target.value;
		
		if ( index !== newIndex ) {
			if ( 1 === newIndex ) {
				props.viewCOMMUNITY();
			} else if ( 2 === newIndex ) {
				props.viewITUBE();
			} else if ( 3 === newIndex ) {
				props.viewPROFILE();
			} else {
				props.viewHome();
			}
			
			setIndex(newIndex);
		}
		
	};
	
	//console.log( props.currentPage.data );
	//console.log( index );
	
	return (
		
		<Fragment>
			{
				(
					(
						true	=== isMobileOnly	||
						true	=== isTablet		||
						true	===	isIPad13
					) &&
					true	=== navbar_open
				) ?
					(
						<ThemeProvider
							theme={markerInfoTheme}
						>
							<Box
								bgcolor={
									"fourth.contrastText"
								}
								
								//color={
								//	"fourth.main"
								//}
								
								borderColor={
									"fourth.main"
								}
								
								clone={true}
								
								borderTop={1}
								
								className={
									clsx(
										classes.noGutters,
									)
								}
							>
								<BottomNavigation
									className={
										clsx(
											classes.bottomNavBar,
										)
									}
									
									value={index}
									
									onChange={
										(event, newIndex) => {
											if ( 1 === newIndex ) {
												props.viewCOMMUNITY();
											} else if ( 2 === newIndex ) {
												props.viewITUBE();
											} else if ( 3 === newIndex ) {
												props.viewPROFILE();
											} else {
												props.viewHome();
											}
											
											setIndex(newIndex);
										}
									}
									
									showLabels={false}
									
									style={{
										height:'3rem',
										minHeight:"3rem",
									}}
								>
									<StyledBottomNavigationAction
										
										//label="검색" 
										icon={
											//<Typography
											//	className={
											//		clsx(
											//			
											//		)
											//	}
											//	
											//	variant="h6" 
											//	component="h6"
											//>
												<Icon 
													className={
														clsx(
															"fas fa-map-marker-alt",
															classes.marginSmall,
														)
													}
													
													fontSize="small"
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														//margin:1,
														overflow:"visible",
													}}
												/>
											//</Typography>
										} 
										
										showLabel={false}
										
										component={Link}
										to={
											(
												true	=== isMobileOnly	||
												true	=== isTablet		||
												true	===	isIPad13
											) ? "/m" : "/"
										}
										value={0}
									/>
									
									<StyledBottomNavigationAction
										//label="커뮤니티" 
										icon={
											//<Typography
											//	className={
											//		clsx(
											//			
											//		)
											//	}
											//	
											//	variant="h6" 
											//	component="h6"
											//>
												<Icon 
													className={
														clsx(
															"fas fa-comment",
															classes.marginSmall,
														)
													}
													fontSize="small"
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														//margin:1,
														overflow:"visible",
													}}
												/>
											//</Typography>
										} 
										
										showLabel={false}
										
										component={Link}	
										to={
											(
												true	=== isMobileOnly	||
												true	=== isTablet		||
												true	===	isIPad13
											) ? "/m/Community" : "/Community"
										}
										
										value={1}
									/>
									<StyledBottomNavigationAction
										//label="iTube" 
										icon={
											//<
												//YouTubeIcon
												//LiveTvIcon
												
												//style={{
												//	display: 'flex',
												//	alignItems: 'center',
												//	justifyContent: 'center'
												//}}
											// />
											//<Typography
											//	className={
											//		clsx(
											//			
											//		)
											//	}
											//	
											//	variant="h6" 
											//	component="h6"
											//>
												<Icon 
													className={
														clsx(
															"fab fa-youtube",
															classes.marginSmall,
														)
													}
													fontSize="small"
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														//margin:1,
														overflow:"visible",
													}}
												/>
											//</Typography>
										} 
										
										showLabel={false}
										
										component={Link}
										
										to={
											(
												true	=== isMobileOnly	||
												true	=== isTablet		||
												true	===	isIPad13
											) ? "/m/iTube" : "/iTube"
										}
										
										value={2}
									/>
									<StyledBottomNavigationAction
										//label="프로필" 
										icon={
											//<Typography
											//	className={
											//		clsx(
											//			
											//		)
											//	}
											//	
											//	variant="h6" 
											//	component="h6"
											//>
												<Icon 
													className={
														clsx(
															"fas fa-user",
															classes.marginSmall,
														)
													}
													fontSize="small"
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														//margin:1,
														overflow:"visible",
													}}
												/>
											//</Typography>
										} 
										
										showLabel={false}
										
										component={Link}
										
										to={
											(
												true	=== isMobileOnly	||
												true	=== isTablet		||
												true	===	isIPad13
											) ? "/m/User" : "/User"
										}
										
										value={3}
									/>
								</BottomNavigation>
							</Box>
						</ThemeProvider>
					) :
					(
						(
							true	=== isMobileOnly	||
							true	=== isTablet		||
							true	===	isIPad13
						) ?
							null :
							(
								( 
									-1 !== String(window.location).indexOf( ".io/#/" )	||
									-1 !== String(window.location).indexOf( ".kr/#/" )
								) ?
									null :
									(
										<ThemeProvider
											theme={markerInfoTheme}
										>
											<Box
												borderRight={1}
												
												bgcolor={
													"fourth.contrastText"
												}
												
												borderColor={
													"fourth.main"
												}
												
												clone={true}
												
												className={
													clsx(
														classes.noGutters,
														//classes.grow,
													)
												}
												
												//display="flex"
												//flexDirection="column"
											>
												<Container
													className={
														clsx(
															classes.noGutters,
														)
													}
													
													style={{
														position:"fixed",
														top:"0",
														left:"0",
														width:"3.75rem",
														height:"100vh",
														zIndex:"1",
														
														display:"flex",
														flexDirection:"column",
														
														borderColor:"#e0e0e0",
													}}
												>
													{
													//<Grid
													//	container
													//	direction="column"
													//	justify="flex-start"
													//	alignItems="center"
													//>
													//	<Grid
													//		item
													//	>
													}
															<Button 
																component={"a"}
																
																//to={"/m"}
																href={
																	(
																		true	=== isMobileOnly	||
																		true	=== isTablet		||
																		true	===	isIPad13
																	) ? "/m" : "/"
																}
																
																className={
																	clsx(
																		classes.button,
																		classes.marginBottom,
																	)
																}
																
																style={{
																	minWidth:"3rem",
																}}
															>
																<img
																	className={
																		classes.noGutters
																	}
																	src="/img/ipicklogo.png"
																	alt="아이픽"
																	style={{
																		margin:0,
																		padding:0,
																		width:"3.5rem",
																		//height:"3.25rem",
																		height:"3rem",
																		
																		minWidth:"3rem",
																	}}
																/>
															</Button>
													{
													//	</Grid>
													//</Grid>
													}
													
													{
													//<IconButton
													//	component={Link}
													//	to="/m"
													//	value={0}
													//	
													//	className={
													//		clsx(
													//			classes.smallButton,
													//			classes.margin2Y,
													//		)
													//	}
													//	
													//	onClick={handleMenuNavigation}
													//>
													//	<ExploreIcon
													//	/>
													//</IconButton>
													}
													
													
													<IconButton
														component={Link}
														
														to={
															(
																true	=== isMobileOnly	||
																true	=== isTablet		||
																true	===	isIPad13
															) ? "/m/Community" : "/Community"
														}
														
														value={1}
														
														className={
															clsx(
																classes.smallButton,
																classes.margin2Y,
																
															)
														}
														
														style={{
															textDecoration:"none",
														}}
														
														onClick={handleMenuNavigation}
													>
														<Icon 
															className={
																clsx(
																	"fas fa-comment",
																	classes.marginSmall,
																)
															}
															fontSize="small"
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																//margin:1,
																overflow:"visible",
																textDecoration:"none",
															}}
														/>
													</IconButton>
													
													<IconButton
														component={Link}	
														
														to={
															(
																true	=== isMobileOnly	||
																true	=== isTablet		||
																true	===	isIPad13
															) ? "/m/iTube" : "/iTube"
														}
														
														value={2}
														
														className={
															clsx(
																classes.smallButton,
																classes.margin2Y,
															)
														}
														
														onClick={handleMenuNavigation}
													>
														<
															//YouTubeIcon
															LiveTvIcon
															
															//style={{
															//	display: 'flex',
															//	alignItems: 'center',
															//	justifyContent: 'center'
															//}}
														/>
													</IconButton>
													
													<IconButton
														component={Link}
														to={
															(
																true	=== isMobileOnly	||
																true	=== isTablet		||
																true	===	isIPad13
															) ? "/m/User" : "/User"
														}
														
														value={3}
														
														className={
															clsx(
																classes.smallButton,
																classes.margin2Y,
															)
														}
														
														onClick={handleMenuNavigation}
													>
														{
														//<Typography
														//	className={
														//		clsx(
														//			
														//		)
														//	}
														//	
														//	variant="h5" 
														//	component="h5"
														//>
														}
															<Icon 
																className={
																	clsx(
																		"fas fa-user",
																		classes.marginSmall,
																	)
																}
																fontSize="small"
																
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	//margin:1,
																	overflow:"visible",
																}}
															/>
														{
														//</Typography>
														}
													</IconButton>
													
												</Container>
											</Box>
										</ThemeProvider>
									)
								
							)
						
					)
			}
		</Fragment>
	
	);
	
}


//export default withRouter ( IPickMenu );


// props 로 넣어줄 스토어 상태값
const mapStateToProps = state => ({
	currentPage: state.currentPageSync.currentPage
});

// props 로 넣어줄 액션 생성함수
const mapDispatchToProps = dispatch => ({
	viewHome		: ( ) => dispatch( viewHome( ) ),
	viewCOMMUNITY	: ( ) => dispatch( viewCOMMUNITY( ) ),
	viewITUBE		: ( ) => dispatch( viewITUBE( ) ),
	viewPROFILE		: ( ) => dispatch( viewPROFILE( ) )
});

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( withRouter( IPickNavBar ) );