import 
	React, 
	{ 
		Component,
		Fragment
	} 
from 'react';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

import StarRatingComponent from 'react-star-rating-component';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Box,
	Container,
	Typography,
	Grid,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon		from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
	Favorite		as FavoriteIcon,
	FavoriteBorder	as FavoriteBorderIcon,
	ArrowBackIos	as ArrowBackIosIcon
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
		starColor	: {
			main:"#ffc127",
			contrastText:"#fff",
		}
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
}));

	
//type={this.props.type}
//ratingInfo={
//	{
//		"ratingCount"	: this.state.rating_count,
//		"ratingAvg"		: this.state.rating_avg,
//		"ratingReviews"	: this.state.rating_reviews,
//	}
//}
const MAX_STAR_COUNT	= 5;


export default function StarRatingContainer ( props ) {
	
	const classes	= useStyles();
	
	const [starRate, setStarRate]			= React.useState(0);
	const [starRateInput, setStarRateInput]	= React.useState(0);
	
	const handleStarRate = ( value ) => {
		
		if ( 0 > value ) {
			setStarRate( 0 );
			
			if( 
				undefined	!==	props.handleStarRate	&&
				null		!==	props.handleStarRate	&&
				""			!==	props.handleStarRate
			) {
				props.handleStarRate( 0 );
			}
			
		} else if ( 5 < value ) {
			setStarRate( props.ratingMaxCount );
			
			if( 
				undefined	!==	props.handleStarRate	&&
				null		!==	props.handleStarRate	&&
				""			!==	props.handleStarRate
			) {
				props.handleStarRate( props.ratingMaxCount );
			}
			
		} else {
			setStarRate( value );
			
			if( 
				undefined	!==	props.handleStarRate	&&
				null		!==	props.handleStarRate	&&
				""			!==	props.handleStarRate
			) {
				props.handleStarRate( value );
			}
			
		}
		
	};
	
	const handleStarRateInput = ( value ) => {
		
		if ( 0 > value ) {
			setStarRateInput( 0 );
		} else if ( 5 < value ) {
			setStarRateInput( props.ratingMaxCount );
		} else {
			setStarRateInput( value );
		}
		
	};
	
	const onStarHover	= ( nextValue, prevValue, name ) =>	{
		handleStarRateInput( nextValue );
	};
	
	const onStarHoverOut	= ( nextValue, prevValue, name ) =>	{
		handleStarRateInput( starRate );
	};
	
	const onStarClick	= ( nextValue, prevValue, name ) =>	{
		handleStarRate( nextValue );
	};
	
	
	//console.log( props );
	
	return (
		<Fragment>
			{
				( 
					false		===	props.editable	||
					null		===	props.editable	||
					undefined	===	props.editable	||
					""			===	props.editable
				) ?
					(
						<StarRatingComponent
							name="totalRatingScore"
							starCount={
								props.ratingMaxCount
							}
							value={props.ratingValue}
							starColor={'#ffc127'}
							emptyStarColor={'#FFF'}
							editing={false}
							
							//onStarHover={onStarHover}
							//onStarHoverOut={onStarHoverOut}
							//onStarClick={onStarClick}
							
							renderStarIcon={ (index, value) => {
								//console.log( index );
								return (
									<Icon 
										//fontSize="large"
										fontSize={
											(
												undefined	===	props.fontSize	||
												null		===	props.fontSize	||
												""			===	props.fontSize	||
												"inherit"		===	props.fontSize
											) ?
												"inherit" : props.fontSize
										}
										className={
											clsx(
												( index <= value ) ? 
													'fas fa-star' : 'far fa-star',
												classes.paddingTop, 
												classes.paddingSmallY
											)
										}
										style={{
											//marginTop:"0.5rem",
											marginTop:"0rem",
											marginRight:
												( index < props.ratingMaxCount ) ?
													"0.25rem" : 0,
											overflow	: "visible",
										}}
									/>
								);
							}}
							renderStarIconHalf={ ( index ) => {
								
								//console.log( index );
								
								return (
									<span
									>
										<ThemeProvider
											theme={markerInfoTheme}
										>
											<Box
												color="starColor.main"
												clone={true}
											>
												<Icon 
													color="inherit"
													//fontSize="large"
													fontSize={
														(
															undefined	===	props.fontSize	||
															null		===	props.fontSize	||
															""			===	props.fontSize	||
															"inherit"		===	props.fontSize
														) ?
															"inherit" : props.fontSize
													}
													className={
														clsx(
															"fas fa-star-half", 
															classes.paddingTop, 
															classes.paddingSmallY
														)
													}
													style={{
														position	: "absolute",
														//marginTop	: "0.5rem",
														marginTop:"0rem",
														marginRight:
															( index < props.ratingMaxCount ) ?
																"0.25rem" : 0,
														overflow	: "visible",
													}}
												/>
											</Box>
										</ThemeProvider>
										<Icon 
											//fontSize="large"
											fontSize={
												(
													undefined	===	props.fontSize	||
													null		===	props.fontSize	||
													""			===	props.fontSize	||
													"inherit"		===	props.fontSize
												) ?
													"inherit" : props.fontSize
											}
											
											className={
												clsx(
													"far fa-star",
													classes.paddingTop,
													classes.paddingSmallY,
												)
											}
											
											style={{
												//position	: "absolute",
												//marginTop	: "0.5rem",
												marginTop:"0rem",
												marginRight:
													( index < props.ratingMaxCount ) ?
														"0.25rem" : 0,
												overflow	: "visible",
											}}
										/>
									</span>
								);
							}}
						/>
					) :
					(
						( true === props.editable ) ?
							(
								<StarRatingComponent
									name="totalRatingScore"
									starCount={
										//5
										props.ratingMaxCount
									}
									value={starRateInput}
									starColor={'#ffc127'}
									emptyStarColor={'#FFF'}
									editing={true}
									
									onStarHover={onStarHover}
									onStarHoverOut={onStarHoverOut}
									onStarClick={onStarClick}
									
									renderStarIcon={(index, value) => {
										return (
											<Icon 
												//fontSize="large"
												fontSize={
													(
														undefined	===	props.fontSize	||
														null		===	props.fontSize	||
														""			===	props.fontSize	||
														"inherit"		===	props.fontSize
													) ?
														"inherit" : props.fontSize
												}
												className={
													clsx(
														( index <= value ) ? 
															'fas fa-star' : 'far fa-star',
														classes.paddingTop,
														classes.paddingSmallY,
													)
												}
												style={{
													//marginTop:"0.5rem",
													marginTop:"0rem",
													marginRight:
														( index < props.ratingMaxCount ) ?
															"0.25rem" : 0,
													overflow	: "visible",
												}}
											/>
										);
									}}
									renderStarIconHalf={( index ) => {
										return (
											<span
											>
												<ThemeProvider
													theme={markerInfoTheme}
												>
													<Box
														color="starColor.main"
														clone={true}
													>
														<Icon 
															color="inherit"
															//fontSize="large"
															fontSize={
																(
																	undefined	===	props.fontSize	||
																	null		===	props.fontSize	||
																	""			===	props.fontSize	||
																	"inherit"		===	props.fontSize
																) ?
																	"inherit" : props.fontSize
															}
															className={
																clsx(
																	"fas fa-star-half",
																	classes.paddingTop,
																	classes.paddingSmallY,
																)
															}
															style={{
																position	: "absolute",
																marginTop:"0rem",
																//marginTop	: "0.5rem",
																marginRight:
																	( index < props.ratingMaxCount ) ?
																		"0.25rem" : 0,
																overflow	: "visible",
															}}
														/>
													</Box>
												</ThemeProvider>
												<Icon 
													//fontSize="large"
													fontSize={
														(
															undefined	===	props.fontSize	||
															null		===	props.fontSize	||
															""			===	props.fontSize	||
															"inherit"		===	props.fontSize
														) ?
															"inherit" : props.fontSize
													}
													
													className={
														clsx(
															"far fa-star",
															classes.paddingTop,
															classes.paddingSmallY,
														)
													}
													
													style={{
														//position	: "absolute",
														marginTop:"0rem",
														//marginTop	: "0.5rem",
														marginRight:
															( index < props.ratingMaxCount ) ?
																"0.25rem" : 0,
														overflow	: "visible",
													}}
												/>
											</span>
										);
									}}
									
									className={
										clsx(
											//classes.paddingTop,
										)
									}
								/>
							) : null
					)
			}
		</Fragment>
	);
	
}