import 
	React, { 
	Component,
	Fragment
} from 'react';

import clsx from 'clsx';

import {
	Event,
}	from '../Tracking.js';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

import {
	Box,
	Button,
	Typography,
} from '@material-ui/core';

import Icon		from '@material-ui/core/Icon';

//import {
//	//Close		as CloseIcon,
//	//ChevronLeft		as ChevronLeftIcon,
//	//ChevronRight	as ChevronRightIcon,
//	//Visibility,
//	//VisibilityOff,
//	//UnfoldLess	as UnfoldLessIcon,
//	//ExpandMore	as ExpandMoreIcon,
//	//Favorite		as FavoriteIcon,
//	//FavoriteBorder	as FavoriteBorderIcon,
//	//ArrowBackIos	as ArrowBackIosIcon
//} from '@material-ui/icons';


class KakaoLink extends Component {
	
	constructor( props ) {
		super( props );
		
	}
	
	componentDidMount ( ) {
		window.Kakao.Link.createDefaultButton({
			container: '#kakao-link-btn',
			objectType: 'feed',
			content: {
				title: '아이픽',
				description: '어린이집, 유치원 알아볼땐? #아이픽',
				imageUrl: window.location.protocol + '//' + window.location.host + '/img/share/share.png',
				link: {
					mobileWebUrl: this.props.mobileWebUrl,
					webUrl: this.props.webUrl
				}
			},
			// social: {
			//   likeCount: 286,
			//   commentCount: 45,
			//   sharedCount: 845
			// },
			buttons: [
				{
					title: '웹으로 보기',
					link: {
						mobileWebUrl: this.props.mobileWebUrl,
						webUrl: this.props.webUrl
					}
				}
				// {
				//   title: '앱으로 보기',
				//   link: {
				//     mobileWebUrl: 'https://developers.kakao.com',
				//     webUrl: 'https://developers.kakao.com'
				//   }
				// }
			]
		});
	}
	
	
	render ( ) {
		return (
		
			<Fragment>
				<Button
					id="kakao-link-btn" 
					href="javascript:;"
					component={"a"}
					
					//className={
					//	clsx(
					//		classes.button,
					//	)
					//}
					
					style={{
						margin:0,
						padding:0,
					}}
					
					onClick={
						(e) => {
							Event("ACTION", "Friend Share", "Friend Share Kakao link");
						}
					}
				>
					<img 
						src="//developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png" 
						alt="kakaolink_btn_medium"
					/>
				</Button>
			</Fragment>
			
		);
	}
	
}


export default KakaoLink;

//class KakaoLink extends Component {
//	
//	
//	constructor(props) {
//		super(props);
//		
//		//this.kakaoLogin = this.kakaoLogin.bind(this);
//	}
//
//	componentDidMount() {
//		//console.log(window.location.protocol + '//'+window.location.host + '/img/share/share.png');
//		window.Kakao.Link.createDefaultButton({
//			container: '#kakao-link-btn',
//			objectType: 'feed',
//			content: {
//				title: '아이픽',
//				description: '어린이집, 유치원 알아볼땐? #아이픽',
//				imageUrl: window.location.protocol + '//'+window.location.host + '/img/share/share.png',
//				link: {
//					mobileWebUrl: this.props.mobileWebUrl,
//					webUrl: this.props.webUrl
//				}
//			},
//			// social: {
//			//   likeCount: 286,
//			//   commentCount: 45,
//			//   sharedCount: 845
//			// },
//			buttons: [
//				{
//					title: '웹으로 보기',
//					link: {
//						mobileWebUrl: this.props.mobileWebUrl,
//						webUrl: this.props.webUrl
//					}
//				}
//				// {
//				//   title: '앱으로 보기',
//				//   link: {
//				//     mobileWebUrl: 'https://developers.kakao.com',
//				//     webUrl: 'https://developers.kakao.com'
//				//   }
//				// }
//			]
//		});
//	}
//	
//	
//	render ( ) {
//		return (
//			
//			<a 
//				className={
//					( isMobile ) ?
//						"m-0 py-0 px-2" : "m-0 p-0"
//				}
//				id="kakao-link-btn" 
//				href="javascript:;"
//			>
//				<img 
//					src="//developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png" 
//					alt="kakaolink_btn_medium"
//				/>
//			</a>
//			
//		);
//	}
//}
//
//
//export default KakaoLink;