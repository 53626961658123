import axios from 'axios';


const FAVORITE_ADD			= "FAVORITE_ADD";
const FAVORITE_ADD_SUCCESS	= "FAVORITE_ADD_SUCCESS";
const FAVORITE_ADD_FAILURE	= "FAVORITE_ADD_FAILURE";

const FAVORITE_REMOVE			= "FAVORITE_REMOVE";
const FAVORITE_REMOVE_SUCCESS	= "FAVORITE_REMOVE_SUCCESS";
const FAVORITE_REMOVE_FAILURE	= "FAVORITE_REMOVE_FAILURE";

const FAVORITE_GET			= "FAVORITE_GET";
const FAVORITE_GET_SUCCESS	= "FAVORITE_GET_SUCCESS";
const FAVORITE_GET_FAILURE	= "FAVORITE_GET_FAILURE";




export const getUserFavorite = ( ) => {
	return {
		type: FAVORITE_GET
	};
}

export const getUserFavoriteSuccess = ( favoriteData ) => {
	
	return {
		type			: FAVORITE_GET_SUCCESS,
		favoriteData:{
			favoriteList:favoriteData
		}
	};
	
}

export const getUserFavoriteFailure = ( ) => {
	return {
		type: FAVORITE_GET_FAILURE
	};
}


export const addUserFavorite = ( ) => {
	return {
		type: FAVORITE_ADD
	};
}

//export const addUserFavoriteSuccess = ( favoriteData ) => {
export const addUserFavoriteSuccess = ( ) => {
	
	return {
		type: FAVORITE_ADD_SUCCESS,
		//favoriteData:{
		//	favoriteList:favoriteData
		//}
	};
	
}

export const addUserFavoriteFailure = ( ) => {
	return {
		type: FAVORITE_ADD_FAILURE
	};
}


export const removeUserFavorite = ( ) => {
	return {
		type: FAVORITE_REMOVE
	};
}

export const removeUserFavoriteSuccess = ( favoriteData ) => {
	
	return {
		type: FAVORITE_REMOVE_SUCCESS,
		favoriteData:{
			favoriteList:favoriteData
		}
	};
	
}

export const removeUserFavoriteFailure = ( ) => {
	return {
		type: FAVORITE_REMOVE_FAILURE
	};
}


// **** 초기상태 정의
const initialState = {
	type:FAVORITE_GET_FAILURE,
	favoriteData:{
		favoriteList:[]
	}
};



export default function userFavoriteManager ( state = initialState, action ) {
	
	//console.log( action );
	//console.log( state );
	
	switch ( action.type ) {
		case FAVORITE_GET			:
		case FAVORITE_GET_FAILURE	:
		case FAVORITE_ADD			:
		case FAVORITE_ADD_SUCCESS	:
		case FAVORITE_ADD_FAILURE	:
		case FAVORITE_REMOVE		:
		case FAVORITE_REMOVE_FAILURE:
			return {
				...state,
			};
				break;
		case FAVORITE_GET_SUCCESS	:
		//case FAVORITE_ADD_SUCCESS	:
		case FAVORITE_REMOVE_SUCCESS	:
			return {
				...state,
				favoriteData:{
					...state.favoriteData,
					favoriteList	:	action.favoriteData.favoriteList
				}
			};
			break;
		default :
			return state;
			break;
	}
	
}