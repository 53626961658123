import React, { Component, Fragment } from 'react';

import axios from 'axios';

import { connect } from 'react-redux';

import {
	login,
	loginSuccess,
	loginFailure,
	logout,
	logoutSuccess,
	logoutFailure
} from '../../../store/modules/userAuthentication';


class KakaoLogin extends Component {
	
	constructor( props ) {
		
		super( props );
		
		this.kakaoLogin = this.kakaoLogin.bind(this);
		
	}
	
	
	componentDidMount() {
		window.Kakao.Auth.createLoginButton({
			container: '#kakao-login-btn',
			success: ( authObj ) => {
				// alert('success');
			
				this.kakaoLogin(authObj);
			},
			fail: (err) => {
				//alert(JSON.stringify(err));
				// alert('fail');
				// console.log(err);
				this.props.history.push("/");
			}
		});
	}
	
	
	async kakaoLogin( authObj ) {
		
		let errMsg		= "";
		let errMsgType	= "warning";
		let userInfo	= null;
		
		//const logindata = await fetch('/auth/kakaoAppLoginCallback?access_token=' + authObj.access_token);
		//const logindata_json = await logindata.json();
		
		try {
			
			this.props.login();
			
			const logindata = await axios.get(
				'/auth/kakaoAppLoginCallback?access_token=' + authObj.access_token
			);
			
			if( "success" === logindata.data.result ) {
				//this.props.closeIPickLoginPanel( "success" );
				errMsgType	= "success";
				userInfo	= logindata.data.content;
			} else {
				errMsgType	= "warning";
				errMsg		= "카카오 로그인에 실패했습니다."
			}
			
		} catch ( err ) {
			console.log(err);
			errMsgType	= "error";
			errMsg		= "카카오 로그인 도중 오류가 발생했습니다.";
		} finally {
			
			if ( "" !== errMsg ) {
				this.props.loginSuccess(
					{
						loginYN: true,
						user : userInfo.user,
						userEmail: userInfo.userEmail,
						userNickname: userInfo.userNickname,
						nicknameConfirmYN : userInfo.nicknameConfirmYN,
					}
				);
				this.props.closeIPickLoginPanel( "success" );
			} else {
				this.props.loginFailure();
			}
			
		}
		
		
		
		
		
	}
	
	//const ulzip_list_data = await fetch('/api/getCommonInfoByArcode?arcode=' + area_code);

	render() {
		return (
			<Fragment
			>
				<a 
					//className="m-0 p-0"
					className="w-100 btn font-weight-bold m-0 p-0"
					id="kakao-login-btn" 
				/>
			</Fragment>
		);
	}
}

//export default KakaoLogin;

// props 로 넣어줄 스토어 상태값
const mapStateToProps = state => ({
	userInfo: state.userAuthentication.userInfo
});

// props 로 넣어줄 액션 생성함수
const mapDispatchToProps = dispatch => ({
	//userAuthentication: userInfo => dispatch( userAuthentication( userInfo ) ),
	login			: ( ) => dispatch( login( ) ) ,
	loginSuccess	: userInfo => dispatch( loginSuccess( userInfo ) ) ,
	loginFailure	: ( ) => dispatch( loginFailure( ) ) ,
	logout			: ( ) => dispatch( logout( ) ) ,
	logoutSuccess	: ( ) => dispatch( logoutSuccess( ) ) ,
	logoutFailure	: ( ) => dispatch( logoutFailure( ) ) ,
});

// 컴포넌트에 리덕스 스토어를 연동해줄 때에는 connect 함수 사용
export default connect(
	mapStateToProps,
	mapDispatchToProps
)( KakaoLogin );