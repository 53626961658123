import React, { Component, Fragment } from 'react';

import { isMobileOnly, isTablet } from 'react-device-detect';

import { Container, Row, Col, Card, CardBody, CardText, CardHeader } from 'reactstrap';


import ReactWordcloud from 'react-wordcloud';
import { Resizable } from 're-resizable';



// const {KMR, KKMA} = require('koalanlp/API');
// const {initialize} = require('koalanlp/Util');
// const {Tagger, Parser} = require('koalanlp/proc');
 



const resizeStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	border: 'solid 1px #ddd',
	background: '#f0f0f0'
};

const options_ori = {
	colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
	enableTooltip: true,
	deterministic: false,
	fontFamily: 'impact',
	fontSizes: [5, 80],
	fontStyle: 'normal',
	fontWeight: 'normal',
	padding: 1,
	rotations: 0,
	rotationAngles: [0, 90],
	scale: 'sqrt',
	spiral: 'archimedean',
	transitionDuration: 1000
};

const options = {
	deterministic: false,
	fontFamily: 'impact',
	fontSizes: [20, 80],
	padding: 1,
	rotations: 0,
	scale: 'sqrt',
	spiral: 'archimedean',
	transitionDuration: 1000
};
class Interest extends Component {
	constructor(props) {
		super(props);
		this.words = [ { text:'교원', value:2},
 { text:'한글', value:2},
 { text:'신발', value:2},
 { text:'혼자', value:2},
 { text:'휴대', value:2},
 { text:'수', value:2},
 { text:'걱정', value:2},
 { text:'반찬', value:2},
 { text:'자연', value:2},
 { text:'도레미', value:2},
 { text:'이랑', value:2},
 { text:'고민', value:2},
 { text:'차', value:2},
 { text:'검진', value:2},
 { text:'이', value:2},
 { text:'팬티', value:2},
 { text:'유아', value:2},
 { text:'번', value:2},
 { text:'병원', value:2},
 { text:'치과', value:2},
 { text:'다리', value:2},
 { text:'얼굴', value:2},
 { text:'퍼즐', value:2},
 { text:'방문', value:2},
 { text:'관찰', value:2},
 { text:'곳', value:2},
 { text:'중', value:2},
 { text:'바', value:2},
 { text:'떼', value:2},
 { text:'중이염', value:2},
 { text:'지식', value:2},
 { text:'옷', value:2},
 { text:'비용', value:2},
 { text:'아빠', value:2},
 { text:'분', value:2},
 { text:'뮤지컬', value:2},
 { text:'교육', value:2},
 { text:'건', value:2},
 { text:'안', value:2},
 { text:'어린이집', value:2},
 { text:'하루', value:2},
 { text:'패키지', value:2},
 { text:'인', value:2},
 { text:'저녁', value:2},
 { text:'곰', value:2},
 { text:'밥', value:2},
 { text:'시트', value:3},
 { text:'노출', value:3},
 { text:'엄마', value:3},
 { text:'아들', value:3},
 { text:'몸무게', value:3},
 { text:'질문', value:3},
 { text:'우유', value:3},
 { text:'해주', value:3},
 { text:'자전거', value:3},
 { text:'카', value:3},
 { text:'보드', value:3},
 { text:'학습지', value:3},
 { text:'새벽', value:3},
 { text:'거', value:3},
 { text:'킥', value:3},
 { text:'여행', value:3},
 { text:'발달', value:3},
 { text:'장난감', value:4},
 { text:'수업', value:4},
 { text:'아가', value:4},
 { text:'사이즈', value:4},
 { text:'!!', value:4},
 { text:'여자', value:4},
 { text:'남자', value:4},
 { text:'전집', value:4},
 { text:'유모차', value:4},
 { text:'남', value:4},
 { text:'정도', value:4},
 { text:'딸', value:4},
 { text:'맘', value:4},
 { text:'언어', value:5},
 { text:'조언', value:5},
 { text:'배변훈련', value:5},
 { text:'세', value:6},
 { text:'영어', value:6},
 { text:'것', value:7},
 { text:'선물', value:7},
 { text:'살', value:8},
 { text:'말', value:8},
 { text:'여아', value:9},
 { text:'요', value:11},
 { text:'부탁', value:12},
 { text:'책', value:17},
 { text:'추천', value:24}];
	}
	
	
	
	
	
// 	async  executor(){
//     await initialize({packages: {KMR: '2.0.4', KKMA: '2.0.4'}, verbose: true});
 
//     let tagger = new Tagger(KMR);
//     let tagged = await tagger("안녕하세요. 눈이 오는 설날 아침입니다.");
//     for(const sent of tagged) {
//         console.log(sent.toString());
//     }
 
//     let parser = new Parser(KKMA);
//     let parsed = await parser("안녕하세요. 눈이 오는 설날 아침입니다.");
//     for(const sent of parsed){
//         console.log(sent.toString());
//         for(const dep of sent.dependencies){
//             console.log(dep.toString());
//         }
//     }
// }


	render() {
		let myPageMainClass = '';
		let myPageMainStyle = null;

		if (isMobileOnly) {
			myPageMainClass = 'w-100 m-0 p-0';

			myPageMainStyle = {
				position: 'fixed',
				top: '0',
				left: '0',
				height: 'calc( 100% - 2.75rem )',
				zIndex: '2'
			};
		} else if (isTablet) {
			myPageMainClass = 'w-100 m-0 p-0';

			myPageMainStyle = {
				position: 'fixed',
				top: '0',
				left: '0',
				height: 'calc( 100% - 3.5rem )',
				zIndex: '2'
			};
		} else {
			myPageMainClass = 'h-100 m-0 p-0';

			myPageMainStyle = {
				position: 'fixed',
				width: 'calc( 100% - 3.75rem )',
				right: '0',
				zIndex: '2'
			};
		}

		return (
			<div className={myPageMainClass} style={myPageMainStyle}>
				<Resizable
					defaultSize={{
						width: '100%',
						height: '100%'
					}}
					style={resizeStyle}
				>
					<div>
						<ReactWordcloud options={options} words={this.words} />
					</div>
				</Resizable>
			</div>
		);
	}
}

export default Interest;