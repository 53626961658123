import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	isIPad13,
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Grid,
	FormGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	FormHelperText,
	Checkbox,
	TextField,
	Button,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	padding2Y:{
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
}));



export default function ChangeNickNamePopUpDetail ( props ) {
	
	const classes	= useStyles();
	
	const [values, setValues] = React.useState({
		nickName:"",
	});
	
	const handleChangeValue = prop => event => {
		
		setValues({ 
			...values, 
			[prop]: event.target.value 
		});
		
		
		if ( "nickName" === prop ) {
			props.typeNickName( event );
		} else {
			
		}
		
	};
	
	
	const [checkedValues, setCheckedValues]	= React.useState({
		changeNickNameYN:false,
	});
	
	const handleChangeCheckedValues = prop => event => {
		
		setCheckedValues({ 
			...checkedValues, 
			[prop]: event.target.checked 
		});
		
		//console.log( event.target.checked );
		
		if ( "changeNickNameYN" === prop ) {
			props.toggleNickNameChangeStatus( event );
			
			//console.log(event.target.checked);
			
			if ( false === event.target.checked ) {
				setValues({ 
					...values, 
					nickName:""
				});
				
				//console.log(values);
			}
		} else {
			
		}
		
	};
	
	
	
	return (
		<Fragment>
			<Container
				className={
					clsx(
						//classes.noGutters,
						classes.noMargin,
						classes.padding2Y,
						classes.padding2X,
						//classes.overflowYScroll,
					)
				}
				
				style={{
					height:"100%",
					//width:"100%",
					//maxHeight:"95vh",
				}}
			>
				<form
					autoComplete="off"
				>
					<FormGroup
						row={true}
						
					>
						<FormControlLabel
							control={
								<Checkbox
									checked={checkedValues.changeNickNameYN}
									onChange={handleChangeCheckedValues("changeNickNameYN")}
									value="changeNickNameYN"
									color="primary"
									
									className={
										clsx(
											classes.noPadding,
											classes.marginRight,
										)
									}
								/>
							}
							label={
								<Fragment>
									{
										"닉네임 변경 " 
									}
								</Fragment>
								
							}
							
							className={
								clsx(
									classes.noMargin,
								)
							}
						/>
					</FormGroup>
					<FormGroup
						row={true}
						
						className={
							clsx(
								classes.marginTop,
							)
						}
						
						style={{
							display:"flex",
						}}
					>
						{
							(
								true	=== isMobileOnly	||
								true	===	isTablet		||
								true	===	isIPad13
							) ?
								(
									null
								) :
								(
									<FormControl
										className={
											clsx(
												//classes.formControl,
												classes.padding,
											)
										} 
										
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<FormLabel
											className={
												clsx(
													classes.noGutters,
												)
											}
										>
											{
												"닉네임"
											}
										</FormLabel>
									</FormControl>
								)
						}
						<TextField
							className={
								clsx(
									classes.grow,
								)
							}
							label={
								(
									true	=== isMobileOnly	||
									true	===	isTablet		||
									true	===	isIPad13
								) ?
									(
										"닉네임"
									) : ""
							}
							type="text"
							value={
								( 
									!checkedValues.changeNickNameYN
								) ?
									props.userNickName : values.nickName
							}
							
							placeholder={
								(
									true === checkedValues.changeNickNameYN
								) ?
									"새로운 닉네임 입력" : ""
							}
							
							margin="none"
							variant="outlined"
							disabled={
								!checkedValues.changeNickNameYN
							}
							onChange={handleChangeValue("nickName")}
							
							fullWidth={false}
						/>
						<Button
							className={
								clsx(
									classes.button,
									classes.marginX,
								)
							}
							size="small"
							
							disabled={
								false === props.isValidNickName || 0 >= values.nickName.length
							}
							
							variant="outlined"
							onClick={props.checkNickName}
						>
							{"중복확인"}
						</Button>
					</FormGroup>
					<FormGroup
						row={true}
						
						className={
							clsx(
								classes.marginTop,
							)
						}
						
						style={{
							display:"flex",
						}}
					>
						<FormControl
							className={
								clsx(
									classes.paddingSmall,
								)
							} 
							error={
								"error" === props.userNickNameCheckResult	||
								!props.isValidNickName
							}
						>
							<FormHelperText 
								className={
									clsx(
										classes.formHelperText,
									)
								} 
								
								error={
									"error" === props.userNickNameCheckResult	||
									!props.isValidNickName
								}
							>
								{
									( 0 === values.nickName.length ) ?
										" " :
										(
											//props.msg
											
											( 0 >= props.msg.length ) ?
												(
													( true === props.isValidNickName ) ?
														(
															"사용 가능한 닉네임 형식입니다."
														) :
														(
															//"사용 불가능한 닉네임 형식입니다."
															"사용 불가능한 닉네임 형식입니다. ('문자', '숫자', '밑줄 문자' 포함 2자 ~ 16자 이내)"
														)
												)
												:
												(
													//<FormText 
													//	color={
													//		( "success" === props.userNickNameCheckResult ) ?
													//			"success" : "danger"
													//	}
													//>
													//	{
															props.msg
													//	}
													//</FormText>
												)
											
										)
								}
							</FormHelperText>
						</FormControl>
					</FormGroup>
					
					<FormGroup
						row={true}
						
						className={
							clsx(
								classes.marginTop,
								classes.textCenter,
							)
						}
						
						style={{
							justifyContent:"center",
						}}
					>
						<Button
							className={
								clsx(
									classes.fontWeightBold,
									classes.marginRight,
								)
							}
							size="small"
							variant="outlined"
							//color="dark"
							onClick={
								(e) => {
									props.updateNickNameConfirmYN();
								}
							}
						>
							{'건너뛰기'}
						</Button>
						
						<Button
							className={
								clsx(
									classes.fontWeightBold,
									classes.marginLeft,
								)
							}
							
							size="small"
							variant="outlined"
							//color="dark"
							
							disabled={
								( 
									true === props.isValidNickName		&&
									true === props.confirmnNickName
								) ?
									false : true
							}
							
							onClick={
								(e) => {
									props.changeNickName();
								}
							}
						>
							{'변경하기'}
						</Button>
					</FormGroup>
					
				</form>
			</Container>
		</Fragment>
	);
	
}