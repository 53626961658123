
const PAGE_HOME			= "PAGE_HOME";
const PAGE_COMMUNITY	= "PAGE_COMMUNITY";
const PAGE_ITUBE		= "PAGE_ITUBE";
const PAGE_PROFILE		= "PAGE_PROFILE";

const PAGE_DEPTH_INC	= "PAGE_DEPTH_INC";
const PAGE_DEPTH_DEC	= "PAGE_DEPTH_DEC";
const PAGE_DEPTH_CHANGE	= "PAGE_DEPTH_CHANGE";


export const viewHome = ( ) => {
	return {
		type: PAGE_HOME,
		currentPage:{
			data	: "PAGE_HOME",
			depth	: 1
		}
	};
}

export const viewCOMMUNITY = ( ) => {
	return {
		type: PAGE_COMMUNITY,
		currentPage:{
			data 	: "PAGE_COMMUNITY",
			depth	: 1
		}
	};
}

export const viewITUBE = ( ) => {
	return {
		type: PAGE_ITUBE,
		currentPage:{
			data 	: "PAGE_ITUBE",
			depth	: 1
		}
	};
}

export const viewPROFILE = ( ) => {
	return {
		type: PAGE_PROFILE,
		currentPage:{
			data 	: "PAGE_PROFILE",
			depth	: 1
		}
	};
}

///////////////////////////////////////////////

export const increasePageDepth = ( ) => {
	return {
		type: PAGE_DEPTH_INC
	};
}

export const decreasePageDepth = ( ) => {
	return {
		type: PAGE_DEPTH_DEC
	};
}

export const setPageDepth = ( newPage ) => {
	return {
		type: PAGE_DEPTH_CHANGE,
		currentPage:{
			data	: newPage.data,
			depth	: newPage.depth,
		}
	};
}

///////////////////////////////////////////////

// **** 초기상태 정의
const initialState = {
	type:PAGE_HOME,
	currentPage:{
		data : "PAGE_HOME",
		depth	: 1
	}
};



export default function currentPageSync ( state = initialState, action ) {
	
	//console.log( action );
	//console.log( state );
	
	switch ( action.type ) {
		case PAGE_HOME		:
			return {
				...state,
				currentPage:{
					...state.currentPage,
					data	:	action.currentPage.data,
					depth	:	1,
				}
			};
		case PAGE_COMMUNITY	:
			return {
				...state,
				currentPage:{
					...state.currentPage,
					data	:	action.currentPage.data,
					depth	:	1,
				}
			};
		case PAGE_ITUBE		:
			return {
				...state,
				currentPage:{
					...state.currentPage,
					data	:	action.currentPage.data,
					depth	:	1,
				}
			};
		case PAGE_PROFILE	:
			return {
				...state,
				currentPage:{
					...state.currentPage,
					data	:	action.currentPage.data,
					depth	:	1,
				}
			};
			
		case PAGE_DEPTH_INC	:
			return {
				...state,
				currentPage:{
					...state.currentPage,
					//depth	:	action.currentPage.depth + 1,
					depth	:	state.currentPage.depth + 1,
				}
			};
			
		case PAGE_DEPTH_DEC	:
			//console.log(state.currentPage.depth);
			//console.log(state.currentPage.depth-1);
			return {
				...state,
				currentPage:{
					...state.currentPage,
					//depth	:	action.currentPage.depth - 1,
					depth	:	state.currentPage.depth - 1,
				}
			};
			
		case PAGE_DEPTH_CHANGE	:
			//console.log(action.currentPage);
			return {
				...state,
				currentPage:{
					...state.currentPage,
					depth	:	action.currentPage.depth,
				}
			};
		
		default :
			return state;
	}
	
}

