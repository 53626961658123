import React, { 
	Component,
	Fragment,
} from 'react';

import { //isMobile, 
	//isIOS, 
	isMobileOnly, 
	isTablet,
	//isAndroid, 
	//isBrowser,
	isIPad13, 
} from 'react-device-detect';

import axios from 'axios';

import {
	Container,
	Box,
	Slide,
} from '@material-ui/core';

import AutoSearchKeywordMain	from './AutoSearchKeywordMain.js';

const WAIT_INTERVAL = 500;


class AutoSearchKeyword extends Component {
	
	constructor( props ) {
		
		super( props );
		
		this.state	= {
			searchResult: []
		};
		
		this.getAutoSearchResult	= this.getAutoSearchResult.bind(this);
		this.createUlzipList		= this.createUlzipList.bind(this);
		this.createKinderList		= this.createKinderList.bind(this);
		
		const makeRequestCreator = () => {
			let call = null;
			
			return ( url ) => {
				if ( call ) {
					call.cancel('Only one request allowed at a time.');
				}
				
				call = axios.CancelToken.source();
				return axios.get(url, {
					cancelToken: call.token
				});
			};
		};
		
		const getAutoSearch = makeRequestCreator();
		
		const getSomething = async ( name ) => {
			try {
				const res = await this.getAutoSearch('/api/search?name=' + name);
				
				return res;
			} catch (err) {
				
				console.log( err );
				
				if ( axios.isCancel( err ) ) {
					console.error('Cancelling previous request: ' + err.message);
				}
				
			} finally {
				
			}
		};
		
		
		this.makeRequestCreator = makeRequestCreator;
		this.getAutoSearch		= getAutoSearch;
		this.getSomething		= getSomething;
		this.timer				= null;
		
	}

	
	componentDidUpdate( prevProps, prevState ) {
		
		if (
			prevProps.targetWords !== this.props.targetWords &&
			(
				3	<=	this.props.targetWords.replace(/\s/g, '').length	&&
				3	<=	this.props.targetWords.replace(/[\d\s=|(|)<>!@#$%^&*_-]+/g, '').length
			)
		) {
			if ( null !== this.timer ) {
				clearTimeout( this.timer );
			}
			
			this.timer	= setTimeout(() => {
				this.getAutoSearchResult();
			}, WAIT_INTERVAL);
		} else {
			//console.log( "호출 X" );
		}
	}

	
	componentWillUnmount() {
		this.setState({
			searchResult: []
		});
	}

	
	async createUlzipList( ulzip_list ) {
		
		if ( null === ulzip_list || undefined === ulzip_list || 0 >= ulzip_list.length ) {
			return [];
		} else {
			try {
				const geocoder = this.props.geocoder;
				const daum = this.props.daum;

				const result = await Promise.all(
					ulzip_list.map(data => {
						return new Promise((resolve, reject) => {
							//console.log( data );

							if (
								(
									'undefined' === typeof data.la ||
									'undefined' === typeof data.lo 
								) ||
								(
									0 === data.la && 
									0 === data.lo
								) ||
								(
									'0' === data.la && '0' === data.lo
								) ||
								( 
									'' === data.la && '' === data.lo
								) ||
								(
									'37.566470' === data.la && 
									'126.977963' === data.lo
								) ||
								(
									false === data.gpsupdated
								)
							) {
								geocoder.addressSearch(data.craddr, (result, status) => {
									if (
										daum.maps.services.Status.OK === status // 정상적으로 검색이 완료됐으면
									) {
										let ret_obj = {
											data_type: 'ulzip',
											name: data.crname,
											ulzip_type: data.crtypename,
											postal_code: data.zipcode,
											
											address: data.craddr,
											address_depth_1:
												( '제주도' === data.sidoname ) ? 
													'제주특별자치도' : data.sidoname,
											address_depth_2: data.sigunname,
											
											latitude: result[0].y,
											longitude: result[0].x,
											homepage: data.crhome,
											ulzip_status: data.crstatusname,
											phone: data.crtelno,
											fax: data.crfaxno,
											num_of_playground: data.plgrdco,
											num_of_staffs: data.chcrtescnt,
											area_of_school: data.nrtrroomsize,
											num_of_classroom: data.nrtrroomcnt,
											date_of_accreditation: data.crcnfmdt,
											quota: data.crcapat,
											num_of_students: data.crchcnt,
											school_car_yn:
												(
													'undefined' !== typeof data.crcargbname	&&
													'undefined' !== data.crcargbname		&&
													null		!== data.crcargbname 
												) ? data.crcargbname : '정보 없음',
											abolition_date: data.crabldt,
											closed_duration_start_date: data.crpausebegindt,
											closed_duration_end_date: data.crpauseenddt,
											extra_service: data.crspec,
											ulzip_code: data.stcode,
											child_marker_list: [],
											parent_code: null,
											rating_avg:
												( undefined !== data.rating_avg ) ? data.rating_avg : 0,
											rating_count:
												( undefined !== data.rating_count ) ? data.rating_count : 0
										};

										resolve(ret_obj);
									} else {
										const tmp_address = data.craddr.substring(
											0,
											data.craddr.lastIndexOf(' ') + 1
										);
										
										//console.log( tmp_address );
										
										geocoder.addressSearch(tmp_address, (result, status) => {
											if (
												daum.maps.services.Status.OK === status
												// 정상적으로 검색이 완료됐으면
											) {
												let ret_obj = {
													data_type: 'ulzip',
													name: data.crname,
													ulzip_type: data.crtypename,
													postal_code: data.zipcode,
													
													address: data.craddr,
													address_depth_1:
														( '제주도' === data.sidoname ) ? 
															'제주특별자치도' : data.sidoname,
													address_depth_2: data.sigunname,
													latitude: result[0].y,
													longitude: result[0].x,
													homepage: data.crhome,
													ulzip_status: data.crstatusname,
													phone: data.crtelno,
													fax: data.crfaxno,
													num_of_playground: data.plgrdco,
													num_of_staffs: data.chcrtescnt,
													area_of_school: data.nrtrroomsize,
													num_of_classroom: data.nrtrroomcnt,
													date_of_accreditation: data.crcnfmdt,
													quota: data.crcapat,
													num_of_students: data.crchcnt,
													school_car_yn:
														(
															'undefined' !== typeof data.crcargbname	&&
															'undefined' !== data.crcargbname		&&
															null		!== data.crcargbname 
														) ? data.crcargbname : '정보 없음',
														
													abolition_date: data.crabldt,
													closed_duration_start_date: data.crpausebegindt,
													closed_duration_end_date: data.crpauseenddt,
													extra_service: data.crspec,
													ulzip_code: data.stcode,
													child_marker_list: [],
													parent_code: null,
													rating_avg:
														( undefined !== data.rating_avg ) ? data.rating_avg : 0,
													rating_count:
														( undefined !== data.rating_count ) ? data.rating_count : 0
												};

												resolve(ret_obj);
											} else {
												let ret_obj = {
													data_type: 'ulzip',
													name: data.crname,
													ulzip_type: data.crtypename,
													postal_code: data.zipcode,
													
													address: data.craddr,
													address_depth_1:
														( '제주도' === data.sidoname ) ? 
															'제주특별자치도' : data.sidoname,
													address_depth_2: data.sigunname,
													
													latitude: '0',
													longitude: '0',
													homepage: data.crhome,
													ulzip_status: data.crstatusname,
													phone: data.crtelno,
													fax: data.crfaxno,
													num_of_playground: data.plgrdco,
													num_of_staffs: data.chcrtescnt,
													area_of_school: data.nrtrroomsize,
													num_of_classroom: data.nrtrroomcnt,
													date_of_accreditation: data.crcnfmdt,
													quota: data.crcapat,
													num_of_students: data.crchcnt,
													school_car_yn:
														(
															'undefined'	!== typeof data.crcargbname &&
															null		!== data.crcargbname &&
															'undefined' !== data.crcargbname
														) ? data.crcargbname : '정보 없음',
													abolition_date: data.crabldt,
													closed_duration_start_date: data.crpausebegindt,
													closed_duration_end_date: data.crpauseenddt,
													extra_service: data.crspec,
													ulzip_code: data.stcode,
													child_marker_list: [],
													parent_code: null,
													rating_avg:
														( undefined !== data.rating_avg ) ? data.rating_avg : 0,
													rating_count:
														( undefined !== data.rating_count ) ? data.rating_count : 0
												};

												resolve(ret_obj);
											}
										});
									}
								});
							} else {
								let ret_obj = {
									data_type: 'ulzip',
									name: data.crname,
									ulzip_type: data.crtypename,
									postal_code: data.zipcode,
									
									address: data.craddr,
									address_depth_1:
										( '제주도' === data.sidoname ) ? 
											'제주특별자치도' : data.sidoname,
									address_depth_2: data.sigunname,
									
									latitude: data.la,
									longitude: data.lo,
									homepage: data.crhome,
									ulzip_status: data.crstatusname,
									phone: data.crtelno,
									fax: data.crfaxno,
									num_of_playground: data.plgrdco,
									num_of_staffs: data.chcrtescnt,
									area_of_school: data.nrtrroomsize,
									num_of_classroom: data.nrtrroomcnt,
									date_of_accreditation: data.crcnfmdt,
									quota: data.crcapat,
									num_of_students: data.crchcnt,
									school_car_yn:
										(
											'undefined' !== typeof data.crcargbname &&
											null !== data.crcargbname &&
											'undefined' !== data.crcargbname
										) ? data.crcargbname : '정보 없음',
									abolition_date: data.crabldt,
									closed_duration_start_date: data.crpausebegindt,
									closed_duration_end_date: data.crpauseenddt,
									extra_service: data.crspec,
									ulzip_code: data.stcode,
									child_marker_list: [],
									parent_code: null,
									rating_avg: ( undefined !== data.rating_avg ) ? data.rating_avg : 0,
									rating_count: ( undefined !== data.rating_count ) ? data.rating_count : 0
								};
								
								resolve(ret_obj);
							}
						});
					})
				);

				return result;
			} catch (e) {
				console.log(e);
				return [];
			}
		}
	}

	
	async createKinderList(kinder_list) {
		if ( null === kinder_list || undefined === kinder_list || 0 >= kinder_list.length ) {
			return [];
		} else {
			try {
				const geocoder = this.props.geocoder;
				const daum = this.props.daum;
				
				const result = await Promise.all(
					kinder_list.map(data => {
						return new Promise((resolve, reject) => {
							if (
								(
									'undefined' === typeof data.la ||
									'undefined' === typeof data.lo 
								) ||
								( 
									0 === data.la && 0 === data.lo 
								) ||
								( 
									'0' === data.la && '0' === data.lo 
								) ||
								( 
									'' === data.la && '' === data.lo 
								) ||
								( 
									'37.566470' === data.la && '126.977963' === data.lo 
								)// ||
								// ( 
								// 	false === data.gpsupdated
								// )
							) {
								geocoder.addressSearch(data.addr, (result, status) => {
									if (
										daum.maps.services.Status.OK === status // 정상적으로 검색이 완료됐으면
									) {
										let ret_obj = {
											data_type: 'kinder',
										kinder_code: data.id,
										name: data.kindername,
										//area_code: areaCode,
										address: data.addr,

										latitude: result[0].y,
										longitude: result[0].x,
										
										//address_depth_1: address_depth_1,
										//address_depth_2:
										//	( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,
										
										kinder_status: data.crstatusname,

										kinder_type: data.establish,
										phone: data.telno,
										homepage: data.hpaddr,
										opertime: data.opertime,
										clcnt3: data.clcnt3, // 만3세학급수
										clcnt4: data.clcnt4, // 만4세학급수
										clcnt5: data.clcnt5, // 만5세학급수
										mixclcnt: data.mixclcnt, // 혼합학급수
										shclcnt: data.shclcnt, // 특수학급수

										ppcnt3: data.ppcnt3, // 만3세유아수
										ppcnt4: data.ppcnt4, // 만4세유아수
										ppcnt5: data.ppcnt5, // 만5세유아수
										mixppcnt: data.mixppcnt, // 혼합유아수
										shppcnt: data.shppcnt, // 특수유아수

										child_marker_list: [],
										parent_code: null,

										move_yn: false,
										rating_avg:
											( undefined !== data.rating_avg ) ? data.rating_avg : 0,
										rating_count:
											( undefined !== data.rating_count ) ? data.rating_count : 0
										};

										resolve(ret_obj);
									} else {
										const tmp_address = data.addr.substring(
											0,
											data.addr.lastIndexOf(' ') + 1
										);
										
										//console.log( tmp_address );
										
										geocoder.addressSearch(tmp_address, (result, status) => {
											if (
												daum.maps.services.Status.OK === status
												// 정상적으로 검색이 완료됐으면
											) {
												let ret_obj = {
													data_type: 'kinder',
													kinder_code: data.id,
													name: data.kindername,
													//area_code: areaCode,
													address: data.addr,

													latitude: result[0].y,
													longitude: result[0].x,

													//address_depth_1: address_depth_1,
													//address_depth_2:
													//	( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,

													kinder_status: data.crstatusname,

													kinder_type: data.establish,
													phone: data.telno,
													homepage: data.hpaddr,
													opertime: data.opertime,
													clcnt3: data.clcnt3, // 만3세학급수
													clcnt4: data.clcnt4, // 만4세학급수
													clcnt5: data.clcnt5, // 만5세학급수
													mixclcnt: data.mixclcnt, // 혼합학급수
													shclcnt: data.shclcnt, // 특수학급수

													ppcnt3: data.ppcnt3, // 만3세유아수
													ppcnt4: data.ppcnt4, // 만4세유아수
													ppcnt5: data.ppcnt5, // 만5세유아수
													mixppcnt: data.mixppcnt, // 혼합유아수
													shppcnt: data.shppcnt, // 특수유아수

													child_marker_list: [],
													parent_code: null,

													move_yn: false,
													rating_avg:
														( undefined !== data.rating_avg ) ? data.rating_avg : 0,
													rating_count:
														( undefined !== data.rating_count ) ? data.rating_count : 0
												};

												resolve(ret_obj);
											} else {
												let ret_obj = {
													data_type: 'kinder',
													kinder_code: data.id,
													name: data.kindername,
													//area_code: areaCode,
													address: data.addr,

													latitude: '0',
													longitude: '0',

													//address_depth_1: address_depth_1,
													//address_depth_2:
													//	( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,

													kinder_status: data.crstatusname,

													kinder_type: data.establish,
													phone: data.telno,
													homepage: data.hpaddr,
													opertime: data.opertime,
													clcnt3: data.clcnt3, // 만3세학급수
													clcnt4: data.clcnt4, // 만4세학급수
													clcnt5: data.clcnt5, // 만5세학급수
													mixclcnt: data.mixclcnt, // 혼합학급수
													shclcnt: data.shclcnt, // 특수학급수

													ppcnt3: data.ppcnt3, // 만3세유아수
													ppcnt4: data.ppcnt4, // 만4세유아수
													ppcnt5: data.ppcnt5, // 만5세유아수
													mixppcnt: data.mixppcnt, // 혼합유아수
													shppcnt: data.shppcnt, // 특수유아수

													child_marker_list: [],
													parent_code: null,

													move_yn: false,
													rating_avg:
														( undefined !== data.rating_avg ) ? data.rating_avg : 0,
													rating_count:
														( undefined !== data.rating_count ) ? data.rating_count : 0
												};

												resolve(ret_obj);
											}
										});
									}
								});
							} else {
								let ret_obj = {
									data_type: 'kinder',
									kinder_code: data.id,
									name: data.kindername,
									//area_code: areaCode,
									address: data.addr,
									
									// "81b1e057-d5ce-494e-b387-1e337416892a" : 오즈의마법사판교유치원
									// "b94b2a35-741e-4ded-ac76-226e376d1e94" : 위례한빛초등학교병설유치원
									
									latitude:
										( '81b1e057-d5ce-494e-b387-1e337416892a' === data.id ) ? 
												'37.38834319283787' : 
												(
													( 'b94b2a35-741e-4ded-ac76-226e376d1e94' === data.id ) ? '37.465526' : data.la
												),
									longitude:
										(
											'81b1e057-d5ce-494e-b387-1e337416892a' === data.id
										) ? 
											'127.11151095235391' : 
											(
												( 'b94b2a35-741e-4ded-ac76-226e376d1e94' === data.id ) ? '127.146881' : data.lo
											),
									
									//address_depth_1: address_depth_1,
									//address_depth_2:
									//	( 'EMPTY' === address_depth_2 ) ? '' : address_depth_2,
									
									kinder_status: data.crstatusname,
									kinder_type: data.establish,
									phone: data.telno,
									homepage: data.hpaddr,
									opertime: data.opertime,
									clcnt3: data.clcnt3, // 만3세학급수
									clcnt4: data.clcnt4, // 만4세학급수
									clcnt5: data.clcnt5, // 만5세학급수
									mixclcnt: data.mixclcnt, // 혼합학급수
									shclcnt: data.shclcnt, // 특수학급수
	
									ppcnt3: data.ppcnt3, // 만3세유아수
									ppcnt4: data.ppcnt4, // 만4세유아수
									ppcnt5: data.ppcnt5, // 만5세유아수
									mixppcnt: data.mixppcnt, // 혼합유아수
									shppcnt: data.shppcnt, // 특수유아수
	
									child_marker_list: [],
									parent_code: null,
	
									move_yn: false,
									rating_avg: ( undefined !== data.rating_avg ) ? data.rating_avg : 0,
									rating_count: ( undefined !== data.rating_count ) ? data.rating_count : 0
								};
								
								resolve(ret_obj);
							}
						});
					})
				);
				
				return result;
			} catch (e) {
				console.log(e);
				return [];
			}
		}
	}
	
	
	async getAutoSearchResult() {
		//console.log('getAutoSearchResult called!!');
		
		try {
			if ( this.props.targetWords.length >= 3 ) {
				//console.log( this.props.targetWords );
				
				const autoKeywordSearchResult = this.getSomething(
					encodeURIComponent(this.props.targetWords)
				);
				const retData = await autoKeywordSearchResult;
				
				if ( undefined !== retData ) {
					const autoKeywordSearchResultToJson = retData.data;
					//console.log(autoKeywordSearchResultToJson);
					
					let ulzip_list = autoKeywordSearchResultToJson.ulzip_list.filter(
						data =>
							null !== data &&
							undefined !== data &&
							data.hasOwnProperty('crstatusname') &&
							null !== data.crstatusname &&
							undefined !== data.crstatusname &&
							'폐지' !== data.crstatusname
					);
					
					ulzip_list = await this.createUlzipList(ulzip_list);
					
					//console.log( ulzip_list );
					
					let kinder_list = autoKeywordSearchResultToJson.kinder_list.filter(
						data =>
							data.hasOwnProperty('crstatusname') &&
							null !== data.crstatusname &&
							undefined !== data.crstatusname &&
							'폐원' !== data.crstatusname
					);
					
					kinder_list = await this.createKinderList(kinder_list);
					
					//console.log( kinder_list );
					
					let searchResult = [];
					
					searchResult = searchResult.concat(ulzip_list).concat(kinder_list);
					
					this.setState({
						searchResult: searchResult
					});
					
					//console.log( searchResult );
					
					if ( searchResult.length > 0 ) {
						this.props.toggleAutoSearchExistsYN(true);
					} else {
						this.props.toggleAutoSearchExistsYN(false);
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	
	render() {
		//console.log( this.state.searchResult );
		
		//if (
		//	null		===	this.state.searchResult ||
		//	undefined	===	this.state.searchResult ||
		//	0			>=	this.state.searchResult.length
		//) {
		//	return null;
		//}
		
		return (
			
			<Fragment>
				<Slide
					direction={
						( isMobileOnly ) ?
							"down" : 
							//"right"
							"left"
					}
					in={
						this.props.isOpen	&& 
						(
							null		!==	this.state.searchResult &&
							undefined	!==	this.state.searchResult &&
							true		===	Array.isArray( this.state.searchResult )	&&
							0			<	this.state.searchResult.length
						)
					} 
					//mountOnEnter
					//unmountOnExit
				>
					<Container
						maxWidth="xs"
						
						style={
							( 
								true	=== isMobileOnly	||
								true	=== isTablet		||
								true	===	isIPad13
							) ?
								{
									position:"fixed",
									right:0,
									
									zIndex:"3",
									margin:0,
									padding:0,
									//maxHeight:"calc( 100% - 8rem )",
									maxHeight:"calc( 100% - 6rem )",
									//maxHeight:" 100%",
									
									overflow:"hidden",
									display:"flex",
  									flexDirection:"column",
									
									borderRadius:"0.25rem",
									backgroundColor:"white"
								} :
								{
									position:"relative",
									zIndex:"3",
									margin:0,
									padding:0,
									
									//maxHeight:"calc( 97vh - 16px - 2rem - 3rem )",
									maxHeight:"calc( 97vh - 24px - 2rem - 3rem )",
									
									//overflowY:"scroll",
									overflow:"hidden",
									display:"flex",
  									flexDirection:"column",	
									
									borderRadius:"0.25rem",
									//backgroundColor:"white"
								}
						}
					>
						<AutoSearchKeywordMain
							data={this.state.searchResult}
							moveCenterLoc={this.props.moveCenterLoc}
							openMarkerFromAutoSearchResult={this.props.openMarkerFromAutoSearchResult}
							clearSearchKeyword={this.props.clearSearchKeyword}
						/>
					</Container>
				</Slide>
			</Fragment>
			
		);
	}
}

export default AutoSearchKeyword;