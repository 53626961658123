import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import {
	isMobileOnly,
	isTablet,
	isIPad13,
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Box,
	Modal,
	Backdrop,
	Divider,
	TextField,
	InputAdornment,
	FormControl,
	FormHelperText,
} from '@material-ui/core';

import {
	Close			as CloseIcon,
	Visibility		,
	VisibilityOff	,
} from '@material-ui/icons';

import KakaoLogin		from '../Utility/SNS/KakaoLogin.js';
import naver_login_icon	from '../../images/naver_login_icon.PNG'; // Tell Webpack this JS file uses this image
import kakao_login_icon	from '../../images/kakao_account_login_btn_medium_narrow.png';



const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	inputContainer:{
		display: 'flex',
		flexWrap: 'wrap',	
	},
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding	: theme.spacing(1),
		marginLeft	: theme.spacing(0),
		marginRight	: theme.spacing(0),
		marginTop	: theme.spacing(0),
		marginBottom	: theme.spacing(0.5),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
}));



export default function LoginPanel ( props ) {
	
	const classes	= useStyles();
	const [values, setValues] = React.useState({
		password: "",
		showPassword: false,
		
		email:"",
	});

	const handleChange = prop => event => {
		setValues({ ...values, [prop]: event.target.value });
		
		if ( "email" === prop ) {
			props.typeEmail( event );
		} else if ( "password" === prop ) {
			props.typePassword( event );
		} else {
			
		}
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};
	
	return (
		<Fragment>
			
			<Modal
				className={classes.modal}
				open={props.isOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box 
					className={
						clsx(
							classes.modalBox,
						)
					}
					borderRadius="borderRadius"
					
					style={{
						minWidth:"350px",
					}}
				>
					
					<Container
						maxWidth="xs"
						className={
							clsx(
								classes.noGutters,
								//classes.noMargin,
								//classes.padding2X,
							)
						}
						
					>
						<Grid
							container
							direction="row"
							justify="flex-end"
							alignItems="center"
							
							className={
								clsx(
									classes.paddingSmallX,
								)
							}
						>
							<Grid
								item
								
								style={{
									textAlign:"center"
								}}
							>
								<IconButton
									className={
										clsx(
											classes.button,
											classes.noGutters,
										)
									}
									
									size="small"
									
									onClick={
										(e) => {
											//console.log( props.history );
											props.closeLoginPanel();
										}
									}
								>
									<CloseIcon 
										fontSize="small"
									/>
								</IconButton>
							</Grid>
						</Grid>
						
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									classes.paddingX,
								)
							}
						>
							<Grid
								item
								
								xs={12}	
								sm={12}
								md={12}
								lg={12}
								xl={12}
							>
								<Typography 
									className={
										clsx(
											classes.paddingX,
											classes.noMargin
										)
									}
									variant="h4" 
									
									style={{
										textAlign:"center"
									}}
								>
									{
										//"아 이 픽"
										"아이픽"
									}
								</Typography>
								
							</Grid>
						</Grid>
						
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									classes.margin2Y,
									classes.paddingX,
								)
							}
						>
							<Grid
								item
								
								xs={12}	
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
								className={
									clsx(
										classes.textCenter,
									)
								}
							>
								<Typography 
									className={
										clsx(
											classes.paddingX,
											classes.noMargin,
											classes.textCenter,
										)
									}
									variant="subtitle2" 
									
									//style={{
									//	textAlign:"center"
									//}}
								>
									{
										"회원가입 없이 SNS로 간편 로그인"
									}
								</Typography>
								
							</Grid>
						</Grid>
						
						<form
							className={
								clsx(
									classes.paddingX,
								)
							}
							
							onKeyPress={
								(e) => {
									
									//console.log(e.key);
									//console.log(e.keyCode);
									
									if( "Enter" === e.key ) {
										
										props.login(e);
										
									}
									
								}
							}
						>
							<Grid
								container
								direction="column"
								justify="center"
								alignItems="center"
							>
								
								<Grid
									item
									
									xs={12}	
									sm={12}
									md={12}
									lg={12}
									xl={12}
								>
									<Button
										fullWidth={true}
										href="/auth/login/naver"
									>
										<img
											src={naver_login_icon}
											//src="https://ulziphow-back.run.goorm.io/img/logos/naver_icon.PNG"
											alt="네이버 로그인"
											style={{
												border:"none",
												 width:"222px",
												 height:"49px",
												// maxWidth:"68px",
												// maxHeight:"69px"
											}}
										/>
									</Button>
								</Grid>
								
								<Grid
									item
									
									xs={12}	
									sm={12}
									md={12}
									lg={12}
									xl={12}
								>
									
									
									
										{
											( true )?
												(
													<Button
														fullWidth={true}
														href="/auth/login/kakao"
													>
														<img
															src={kakao_login_icon}
															alt="카카오 로그인"
															style={{
																//border:"none",
																// width:"2.65rem",
																// height:"2.75rem",
																// maxWidth:"68px",
																// maxHeight:"69px"
															}}
														/>
													</Button>
									
												) :
												(
													//앱으로 로그인
													<KakaoLogin
														//closeIPickLoginPanel={this.props.closeIPickLoginPanel}
														closeIPickLoginPanel={props.closeLoginPanel}
														
													/>
												)
										}
								</Grid>
								
							</Grid>
							
							<Divider
								
								variant="middle"
								//data-content="OR"
								className={
									clsx(
										classes.margin2Y,
									)
								}
							/>
							
							<TextField
								className={
									clsx(
										//classes.marginX,
										//classes.textField
									)
								}
								label="이메일"
								type="email"
								name="email"
								autoComplete="email"
								value={values.email}
								onChange={handleChange('email')}
								//margin="dense"
								margin="none"
								variant="outlined"
								fullWidth={true}
							/>
							
							<FormControl
								className={
									clsx(
										classes.formControl,
									)
								} 
								error={
									//true
									!props.isValidEmail
								}
							>
								<FormHelperText 
									className={
										clsx(
											classes.formHelperText,
										)
									} 
								>
									{
										( 0 === values.email.length ) ?
											" " :
											(
												( props.isValidEmail ) ?
													"유효한 이메일 형식입니다." : "이메일 형식이 유효하지 않습니다."
											)
									}
								</FormHelperText>
							</FormControl>
							
							<TextField
								className={
									clsx(
										//classes.marginX,
										//classes.textField
									)
								}
								//margin="dense"
								margin="none"
								variant="outlined"
								type={values.showPassword ? 'text' : 'password'}
								label="비밀번호"
								value={values.password}
								onChange={handleChange('password')}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												edge="end"
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
											>
												{values.showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
								fullWidth={true}
							/>
							
							<FormControl
								className={
									clsx(
										classes.formControl,
									)
								} 
								error={true}
							>
								<FormHelperText 
									className={
										clsx(
											classes.formHelperText,
										)
									} 
								>
									{
										props.errMsg
									}
								</FormHelperText>
							</FormControl>
							
							<Grid
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.noMargin,
									)
								}
							>
								<Grid
									item
									
									//xs={12}	
									//sm={12}
									//md={12}
									//lg={12}
									//xl={12}
								>
									<ThemeProvider
										theme={markerInfoTheme}
									>
										<Box
											color={"primary.main"}
										>
											<Button
												color="primary"
												component={Link}
												to={
													(
														true	=== isMobileOnly	||
														true	===	isTablet		||
														true	===	isIPad13
													) ?
														"/m/ForgetMyPassword" : "/ForgetMyPassword" 
												}
											>
												{ "비밀번호가 기억나지 않으신가요?" }
											</Button>
										</Box>
									</ThemeProvider>
								</Grid>
							</Grid>
							
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.marginTop,
									)
								}
							>
								<Grid
									item
									
									xs={12}	
									sm={12}
									md={12}
									lg={12}
									xl={12}
								>
									<Button
										fullWidth={true}
										variant="outlined"
										disabled={props.loginBtnStyle}
										onClick={props.login}
									>
										{ "이메일로 로그인" }
									</Button>
								</Grid>
							</Grid>
							
							<Grid
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
								
								className={
									clsx(
										classes.padding2X,
										classes.marginTop,
									)
								}
								
								spacing={1}
							>
								<Grid
									item
									
									className={
										clsx(
											classes.textSmall,
										)
									}
								>
									{
										"이메일로"
									}
								</Grid>
								<Grid
									item
									
									//xs={12}	
									//sm={12}
									//md={12}
									//lg={12}
									//xl={12}
								>
									<ThemeProvider
										theme={markerInfoTheme}
									>
										<Box
											color={"primary.main"}
										>
											<Button
												color="primary"
												component={Link}
												
												to={
													(
														true	=== isMobileOnly	||
														true	===	isTablet		||
														true	===	isIPad13
													) ?
														"/m/RegisterUserId" : "/RegisterUserId"
												}
												//variant="outlined"
												
												className={
													clsx(
														classes.smallButton,
													)
												}
											>
												{ "회원가입" }
											</Button>
										</Box>
									</ThemeProvider>
								</Grid>
							</Grid>
							
						</form>
					</Container>
					
				</Box>
			</Modal>
			
		</Fragment>
	);
	
}