import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';

import { Link } from 'react-router-dom';

import clsx		from 'clsx';
import { 
	//isMobile, 
	//isIOS, 
	isMobileOnly, 
	isTablet,
	//isAndroid, 
	//isBrowser,
	isIPad13,
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	withStyles,
	createMuiTheme
} from '@material-ui/core/styles';

import {
	Box,
	AppBar,
	Toolbar,
	Snackbar,
	Button,
	IconButton,
	//Grid,
	//TextField,
	Input,
	//InputBase,
	InputLabel,
	InputAdornment,
	FormControl,
	//FormGroup,
	
	Badge
} from '@material-ui/core';


//import { borders } from '@material-ui/system';


import SearchIcon from '@material-ui/icons/Search';
import BackspaceIcon from '@material-ui/icons/Backspace';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		third		: {
			main:"#616161"	// lightGrey
		},
		error		: {
			main:"#c62828",
			light:"#f44336"
		},
		warning		: {
			main:"#ffa000",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingRight2x:{
		paddingRight: theme.spacing(2),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textCenter:{
		textAlign:"center"
	},
	textField: {
		flexBasis: 200,
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	errorColor:{
		color:
			//theme.palette.error.main,
			theme.palette.secondary.light,
		borderColor:theme.palette.secondary.light,
	}
}));



export default function MenuBarMobile ( props ) {
	
	const classes	= useStyles();
	
	//console.log( props.numOfCurrentLocIllegal );
	//console.log( props.illegalInfoOpen );
	
	return (
		
		<Fragment>
			{
				( 
					true	=== isMobileOnly	||
					true	=== isTablet		||
					true	===	isIPad13
				) ?
					(
						<AppBar
							className={
								clsx(
									classes.topMenuBar,
									classes.bgWhite,
								)
							}
							
							position="relative"
						>
							<Toolbar
								className={
									clsx(
										classes.topMenuBarList,
										classes.noMargin,
										classes.paddingX,
									)
								}
								disableGutters={false}
								style={{
									height:"3rem",
									minHeight:"3rem",
								}}
							>
								<Button 
									className={
										clsx(
											classes.noGutters,
											classes.paddingSmallY,
										)
									}
									
									component={Link}
									to={"/m/"}
									
									style={{
										minWidth:"2.75rem",
									}}
								>
									<img
										className={
											classes.noGutters
										}
										src="/img/ipicklogo.png"
										alt="아이픽"
										style={{
											width: '2.75rem',
											height: '2.75rem'
										}}
									/>
								</Button>
								
								{
									( false === isMobileOnly ) ?
										(
											<div 
												// 공백 넣기
												className={classes.grow} 
											/>
										) : null
								}
								
								<form
									
									noValidate 
									autoComplete="off"
									
									className={
										( true === isMobileOnly ) ?
											(
												clsx(
													classes.paddingSmallX,
													classes.grow,
												)
											) :
											(
												clsx(
													classes.paddingSmallX,
													//classes.grow,
												)
											)
									}
									
									onSubmit={props.searchKeywordSubmit}
								>
									<FormControl 
										className={
											clsx(
												classes.noGutters, 
												classes.textField,
												
											)
										}
										
										style={
											( true === isMobileOnly ) ?
												{
													width:"100%",
												} : null
										}
									>
										{
										//<InputLabel 
										//	htmlFor="adornment-text"
										//	shrink={true}	// placeholder가 Input 위에만 위치하게 함, 
										//					// default 는 false 이며, 이 경우 placeholder가 input 위에 위치하다가
										//					// focus 시에 Input 위에 위치
										//>
										//	{
										//		"어린이집, 유치원, 지역명" 
										//	}
										//</InputLabel>	
										}
										
										<Input
											autoComplete="off"
											type={'text'}
											placeholder="어린이집, 유치원, 지역명" 
											value={props.searchText}
											onChange={props.searchKeywordChange}
											endAdornment={
												<InputAdornment 
													position="end"
												>
													{
														( props.searchText.length > 0 ) ?
															(
																<IconButton
																	aria-label="clear"
																	onClick={props.clearSearchKeyword}
																	size="small"
																>
																	<BackspaceIcon 
																		fontSize="default"
																	/>
																</IconButton>
															) : 
															(
																" "
															)
													}
													
													<IconButton 
														className={classes.button}
														
														aria-label="search"
														//size="medium"
														type="submit"
													>
														<SearchIcon
															fontSize="default"
														/>
													</IconButton>
												</InputAdornment>
											}
											
											style={
												( true === isMobileOnly ) ?
													null :
													(
														{
															minWidth:"40vw"
														}
													)
											}
											
										/>
									</FormControl>
									
								</form>
								
								{
									//( true === isMobileOnly ) ?
									//	(
									//		//<div 
									//		//	// 공백 넣기
									//		//	className={classes.grow} 
									//		///>
									//		null
									//	) : null
								}
								
									<Box
										className={
											clsx(
												classes.paddingTop,
												classes.paddingSmallX,
												classes.noMargin,
											)
										}
										
										bgcolor="error.contrastText"
										color="error"
									>
										<Badge
											badgeContent={
												( 
													null		=== props.numOfCurrentLocIllegal	||
													undefined	=== props.numOfCurrentLocIllegal
												) ?
													0 : props.numOfCurrentLocIllegal
											} 
											
											color={
												"error"
											}
											
											//anchorOrigin={{
											//	vertical: 'top', 
											//	horizontal: 'left',
											//}}
										>
											<Button 
												className={
													( true === props.illegalInfoOpen ) ?
														(
															clsx(
																classes.smallButton,
																classes.textSmall,
																classes.errorColor,
															)
														) :
														(
															clsx(
																classes.smallButton,
																classes.textSmall,
																//classes.errorColor,
															)
														)
												}
												
												variant="outlined"
												//color="secondary"
												//color="error.light"
												//color="inherit"
												//color="default"
												
												onClick={props.toggleIllegalInfo}
												
												size="small"
												
												style={{
													minWidth:"1rem",
													//maxWidth:"4rem"
													width:"3rem"
												}}
											>
												{"위 반"}
											</Button>
										</Badge>
									</Box>
								
							</Toolbar>
							<Snackbar
								message={
									<span 
										id="snackbar-fab-message-id"
									>
										Archived
									</span>
								}
								action={
									<Button 
										color="inherit" 
										size="small"
									>
										Undo
									</Button>
								}
							/>
						</AppBar>
						
					) : null
			}
		</Fragment>
		
	);
	
}
