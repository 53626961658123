import React, { Component, Fragment } from 'react';

import { 
	isMobileOnly, 
	isTablet,
	isIPad13,
} from 'react-device-detect';

import clsx			from "clsx";

import Gesture from 'rc-gesture';

import CustomAlert	from '../../components/Utility/Alert/CustomAlert.js';
import PrivacyDetail	from './PrivacyDetail.js';

import {
	Slide,
	Container,
} from '@material-ui/core';



class Privacy extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			errorMsg		: "",
			errorMsgType	: "warning",
		};

		this.closeAlert		= this.closeAlert.bind(this);
		this.createErrorMsg	= this.createErrorMsg.bind(this);
	}

	closeAlert ( ) {
		this.setState({
			errorMsg: ''
		});
	}

    createErrorMsg( errorMsg, errorMsgType ) {
		this.setState({
			errorMsg	: errorMsg,
			errorMsgType:errorMsgType,
		});
    }

    render ( ) {
		
		
		return(
			
			<Fragment>
				<CustomAlert
					open={
						undefined	!==	this.state.errorMsg		&&
						null		!==	this.state.errorMsg		&&
						0			<	this.state.errorMsg.length
					}
					data={this.state.errorMsg} 
					//option={this.state.errorMsgOption}
					option={this.state.errorMsgType}
					closeAlert={this.closeAlert} 
				/>
				
				<Slide 
					direction={
						( isMobileOnly ) ?
							"left" : "right"
					}
					
					in={true}
					
					//style={{ transitionDelay: '100ms' }}
				>
					<Gesture
						onSwipeRight={
							( gestureStatus ) => {
								if ( 
									true	===	isMobileOnly
								) {
									if ( 1 === this.props.currentPage.depth ) {
										this.props.history.goBack();	
									}
								}
							}
						}
						
						onSwipeLeft={
							( gestureStatus ) => {
								if ( 
									true	!==	isMobileOnly 
								) {
									if ( 1 === this.props.currentPage.depth ) {
										this.props.history.goBack();
									}
								}
							}
						}
						
						direction="horizontal"
					>
						<
						//Container
						div
							
							//maxWidth="xl"
							
							style={
								(
									true	=== isMobileOnly	||
									true	=== isTablet		||
									true	===	isIPad13	
								) ?
									(
										{
											position:"fixed",
											
											top:0,
											left:0,
											height:"calc( 100vh - 3rem )",
											
											margin:0,
											padding:0,
											
											overflow:"hidden",
											
											//backgorundColor:"white",
										}
									) :
									(
										{
											position:"fixed",
											
											top:0,
											right:0,
											width:"calc( 100vw - 3.75rem )",
											height:"100vh",
											
											margin:0,
											padding:0,
											
											overflow:"hidden",
											
											//backgorundColor:"white",
										}
									)
							}
						>
							<PrivacyDetail
								createErrorMsg={this.createErrorMsg}
							/>
						</
							//Container
							div
						>
					</Gesture>
				</Slide>	
			</Fragment>
			
		);
	}
	
}

export default Privacy;