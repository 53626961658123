import 
	React, 
	{ 
		Fragment
	} 
from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	isIPad13,
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Box,
	Container,
	Grid,
	List,
	Card,
	CardHeader,
	CardMedia,
	CardContent,
	Typography,
	Slider,
	Paper,
	Chip,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon		from '@material-ui/core/Icon';

//import {
//	//Close		as CloseIcon,
//	//ChevronLeft		as ChevronLeftIcon,
//	//ChevronRight	as ChevronRightIcon,
//	//Visibility,
//	//VisibilityOff,
//	//UnfoldLess	as UnfoldLessIcon,
//	//ExpandMore	as ExpandMoreIcon,
//	//Favorite		as FavoriteIcon,
//	//FavoriteBorder	as FavoriteBorderIcon,
//	//ArrowBackIos	as ArrowBackIosIcon
//} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	padding3:{
		padding: theme.spacing(3)
	},
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingTop2:{
		paddingTop: theme.spacing(2),
	},
	paddingTop3:{
		paddingTop: theme.spacing(3),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginSmall:{
		margin: theme.spacing(0.5),
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRightSmall:{
		marginRight:theme.spacing(0.5),
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink	: 0,
		backgroundColor	: "#3f51b5",	// primary.main
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	
	dialogPaper: 
		( true === isMobileOnly ) ?
			{
				margin	: theme.spacing(0),
				minWidth: "90vw",
			} :
			{
				margin: theme.spacing(0),
			},
	
}));


const marks = [
	{
		value: 1,
		label: '3개월',
	},
	{
		value: 2,
		label: '6개월',
	},
	{
		value: 3,
		label: '9개월',
	},
	{
		value: 4,
		label: '12개월',
	},
	{
		value: 5,
		label: '18개월',
	},
	{
		value: 6,
		label: '24개월',
	},
	//{
	//	value: 7,
	//	label: '36개월',
	//}
	,
	{
		//value: 8,
		value: 7,
		label: '전체',
	},
];

const PaperWithChips	= withStyles({
	root	: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		//paddingTop: '0.5rem',
	},
})(Paper);


export default function ITubeMainHeader ( props ) {
	
	const classes	= useStyles();
	
	const chipList = ['전체', '이유식', '놀이', '노래', '발달', '동요', '훈육'];
	
	let defaultSliderVal	= 1;
	
	if ( "1개월 2개월 3개월" === props.queryType ) {
		defaultSliderVal	= 1;
	} else if ( "4개월 5개월 6개월" === props.queryType ) {
		defaultSliderVal	= [1,2];
	} else if ( "7개월 8개월 9개월" === props.queryType ) {
		defaultSliderVal	= [2,3];
	} else if ( "10개월 11개월 12개월" === props.queryType ) {
		defaultSliderVal	= [3,4];
	} else if ( "13개월 14개월 15개월 16개월 17개월 18개월" === props.queryType ) {
		defaultSliderVal	= [4,5];
	} else if ( "19개월 20개월 21개월 22개월 23개월 24개월" === props.queryType ) {
		defaultSliderVal	= [5,6];
	} else {
		defaultSliderVal	= [6,7];
	}
	
	const [gaewol, setGaewol]		= React.useState(
		//4
		//[3,4]
		defaultSliderVal
	);
	const [chipVal, setChipVal]	= React.useState(chipList[0]);
	
	const handleChip = data => {
		
		if ( data !== chipVal ) {
			props.chipClickCallback( data );
			setChipVal( data );
		}
		
	};
	
	//const handleDelete = () => {
	//    alert('You clicked the delete icon.');
	//};
	
	return (
		<Fragment>
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<PaperWithChips
						className={
							clsx(
								classes.margin,
								classes.padding,
							)
						}
						
						//style={{margin:10}}
					>
						{
							chipList.map(
								( item, idx ) => (
									<Chip
										key={item + "_" + idx.toString()}
										
										label={item} 
										color={
											( item === chipVal ) ?
												"primary" : "default"
										}
										
										className={
											clsx(
												classes.marginSmall,
											)
										}
										
										onClick={(e) => handleChip(item)} 
									/>	
								)
							)
						}
					</PaperWithChips>
				</Grid>
			</Grid>
			
			
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				
				className={
					clsx(
						//classes.paddingTop,
						//classes.paddingX,
						classes.padding,
					)
				}
			>
				<Grid
					item
					
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					
					className={
						clsx(
							//classes.paddingTop3,
							classes.padding3X,
						)
					}
				>
					<Slider
						//defaultValue={
						//	[3, 4]
						//}
						
						value={gaewol}
						//getAriaValueText={valuetext}
						
						step={1}
						marks={marks}
						min={1}
						max={
							//8
							7
						}
						valueLabelDisplay="off"
						
						onChange={
							(event, newValue) => {
								
								//console.log( newValue );
								
								//console.log( gaewol );
								
								if ( gaewol[0] === newValue[0] ) {
									props.setQuery( newValue[1] );
									setGaewol([newValue[1]-1, newValue[1]]);
								} else {
									if ( 1 < newValue[0] ) {
										props.setQuery( newValue[0] );
										setGaewol([newValue[0]-1, newValue[0]]);
									} else {
										props.setQuery( newValue[0] );
										setGaewol([newValue[0], newValue[0]]);
									}
								}
								
								//console.log( gaewol );
								
							}
						}
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
	
}