import 
	React, 
	{ 
		Fragment
	} 
from 'react';

import StarRatingContainer	from '../Utility/StarRating/StarRatingContainer.js';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Box,
	Grid,
	Button,
	Divider,
	Typography,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

//import Icon		from '@material-ui/core/Icon';
import {
	//Close		as CloseIcon,
	//ChevronLeft		as ChevronLeftIcon,
	ChevronRight	as ChevronRightIcon,
	//Visibility,
	//VisibilityOff,
	//UnfoldLess	as UnfoldLessIcon,
	//ExpandMore	as ExpandMoreIcon,
	//Favorite		as FavoriteIcon,
	//FavoriteBorder	as FavoriteBorderIcon,
	//ArrowBackIos	as ArrowBackIosIcon
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
}));



export default function UserReviewStarRatingSection ( props ) {
	
	const classes	= useStyles();
	
	return (
		<Fragment>
			<ThemeProvider
				theme={markerInfoTheme}
			>
				<Box
					bgcolor={
						( "ulzip" === props.type ) ?
							"secondary.main" :
							(
								( "kinder" === props.type ) ?
									"primary.main" : ""
							)
					}
					
					//borderTop={2}
					
					borderColor={
						( "ulzip" === props.type ) ?
							"secondary.contrastText" :
							(
								( "kinder" === props.type ) ?
									"primary.contrastText" : ""
							)
					}
					
					color={
						( "ulzip" === props.type ) ?
							"secondary.contrastText" :
							(
								( "kinder" === props.type ) ?
									"primary.contrastText" : ""
							)
					}
				>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						
						//className={
						//	clsx(
						//	)
						//}
					>
						
						<Grid
							item
							container
							direction="column"
							justify="center"
							alignItems="center"
							
							xs={3}
							sm={3}
							md={3}
							lg={3}
							xl={3}
							
							className={
								clsx(
									classes.textCenter,
									classes.paddingY,
								)
							}
						>
							
							<Grid
								item
								
							>
								<Typography
									variant="h4"
									color="inherit"
								>
									{
										String(Number(props.ratingInfo.ratingValue).toFixed(1)).replace(
											'.0',
											''
										)
									}
								</Typography>
							</Grid>
							<Grid
								item
								
								className={
									clsx(
										classes.textSmall,
									)
								}
							>
								{ 
									props.ratingInfo.ratingCount + //'건의 후기' 
									"건의 후기"
								}
							</Grid>
							
						</Grid>
						<Grid
							item
							
							xs={9}
							sm={9}
							md={9}
							lg={9}
							xl={9}
							
							className={
								clsx(
									classes.textCenter,
									//classes.paddingY,
									//classes.paddingTop,
								)
							}
						>
							<StarRatingContainer
								fontSize={"large"}
								ratingMaxCount={props.ratingInfo.ratingMaxCount}
								ratingValue={props.ratingInfo.ratingValue}
								
								editable={false}
							/>
						</Grid>
					</Grid>
				</Box>
			</ThemeProvider>
		</Fragment>
	);
	
}