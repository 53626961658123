import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Box,
	ButtonGroup,
	Slide,
} from '@material-ui/core';



const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		third		: {
			main:"#616161"	// lightGrey
		},
		error		: {
			main:"#c62828",
		},
		warning		: {
			main:"#ffa000",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textCenter:{
		textAlign:"center"
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	roundedPill:{
		borderRadius:"50rem !important"
	},
	borderTop:{
		borderTop:"1px solid #dee2e6 !important"
	}, 
	borderBottom:{
		borderBottom:"1px solid #dee2e6 !important"
	}, 
	borderLeft:{
		borderLeft:"1px solid #dee2e6 !important"
	}, 
	borderRight:{
		borderRight:"1px solid #dee2e6 !important"
	},
	border:{
		border:"1px solid #dee2e6 !important"
	},
	//success: {
	//	backgroundColor: green[600],
	//	color:theme.palette.primary.contrastText
	//},
	//successOpposition: {
	//	backgroundColor: theme.palette.primary.contrastText,
	//	color:green[600]
	//},
	//primary:{
	//	backgroundColor: theme.palette.primary.main,
	//	color:theme.palette.primary.contrastText
	//},
	//primaryOpposition:{
	//	backgroundColor: theme.palette.primary.contrastText,
	//	color:theme.palette.primary.main
	//},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
}));


export default function Place ( props ) {
	
	const classes	= useStyles();
	

	return (
		<Grid
			
			container
			direction="row"
			justify="center"
			alignItems="center"
			
			onClick={
				()=>{
					props.moveCenterLoc( props.info.road_address_name, props.info.address_name, props.info.y, props.info.x )
				}
			}
			
			className={
				clsx(
					classes.noGutters,
					classes.paddingY,
				)
			}
		>
			<Grid
				item
				container
				direction="column"
				justify="center"
				alignItems="center"
				
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				
				className={
					clsx(
						classes.noGutters,
						classes.paddingLeft,
					)
				}
			>
				<Grid
					item
					container
					direction="row"
					justify="center"
					alignItems="center"
					
					className={
						clsx(
							classes.noMargin,
							classes.padding,
							//classes.paddingLeft,
							//classes.paddingY,
						)
					}
				>
					<Grid
						item
						
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						
						className={
							clsx(
							//	classes.fontWeightBold,
							)
						}
						
						style={{
							textOverflow: "ellipsis",
						}}
					>
						<Typography variant="h6">
							{props.info.place_name}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					container
					direction="row"
					justify="center"
					alignItems="center"
					
					className={
						clsx(
							classes.noGutters,
							//classes.paddingLeft,
							classes.paddingX,
							classes.textSmall,
						)
					}
				>
					<Grid
						item
						
						xs={2}
						sm={2}
						md={2}
						lg={2}
						xl={2}
						
						className={
							clsx(
								classes.paddingX,
							)
						}
					>
						<Box
							border={1}
							borderRadius={16}
							
							className={
								clsx(
									classes.textCenter,
								)
							}
							
							style={{
								textOverflow: "ellipsis",
							}}
						>
							<Typography variant="caption">
								{
									( 
										null	!== props.info.road_address_name &&
										""		!== props.info.road_address_name
									) ?
										"도로명" : "지번"
								}
							</Typography>
						</Box>
					</Grid>
					<Grid
						item
						
						xs={10}
						sm={10}
						md={10}
						lg={10}
						xl={10}
						
						style={{
							textOverflow: "ellipsis",
						}}
					>
						<ThemeProvider
							theme={markerInfoTheme}
						>
							<Box
								color={
									"third.main"
								}
								clone
								
								className={
									clsx(
										classes.noGutters,
									)
								}
							>
								<Typography variant="caption">
									{
										( 
											null	!== props.info.road_address_name &&
											""		!== props.info.road_address_name
										) ?
											props.info.road_address_name : props.info.address_name
									}
								</Typography>
							</Box>
						</ThemeProvider>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
	
}