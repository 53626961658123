import React, {
	Component,
	Fragment
} from 'react';


import { Link } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';

import { isMobile, isIOS, isMobileOnly, isTablet ,isAndroid, isBrowser } from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	Fab,
	Box,
	Modal,
	Backdrop,
	Divider,
	TextField,
	InputAdornment,
	FormControl,
	FormLabel,
	FormHelperText,
	FormGroup,
	FormControlLabel,
	InputLabel,
	Input,
	Switch,
	Slide,
	Collapse,
	Grow,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
} from '@material-ui/icons';

import CustomAlert				from '../components/Utility/Alert/CustomAlert.js';
import ForgetMyPasswordDetail	from '../components/User/ForgetMyPasswordDetail.js';

class ForgetMyPassword extends Component {
	
	constructor( props ) {
		
		super( props );
		
		this.state	= {
			
			email:"",
			isValidEmail : false,
			
			isSuccessSendingMail:false,
			msg:"",
			errMsg:"",
			errMsgType:"warning",
			
		};
		
		
		this.typeEmail				= this.typeEmail.bind(this);
		this.sendResetPasswordMail	= this.sendResetPasswordMail.bind(this);
		
		this.createErrorMsg			= this.createErrorMsg.bind(this);
		this.setMsg					= this.setMsg.bind(this);
		this.closeAlert				= this.closeAlert.bind(this);
		
	}
	
	
	typeEmail( e ) {
		
		e.preventDefault();
		
		const regexp_for_self	= /[a-zA-Z0-9]+([._-]?[a-zA-Z0-9])*@[a-zA-Z0-9]+[-]?[a-zA-Z0-9]+[.][a-zA-Z]+([.][a-zA-Z]+)?/;
		
		let tmp_email	= e.target.value;
			
		tmp_email	= tmp_email.replace( regexp_for_self, "" );
		
		if( ""  !== tmp_email ) {
			this.setState({
				email: e.target.value,
				isValidEmail : false,
				errMsg:""
			});
		} else {
			this.setState({
				email: e.target.value,
				isValidEmail : true,
				errMsg:""
			});
		}
		
	}
	
	
	async sendResetPasswordMail( e ) {
		
		if( false === this.state.isValidEmail ) {
			return;
		}
		
		let result	= false;
		let errMsg		= "";
		let errMsgType	= "warning";
		let msg			= "";
		
		try {
			
			const passwordResetMailSend	= await axios.post(
				"/passwordResetMailSend",
				{
					email:this.state.email
				}
			);
			
			
			if( "success" === passwordResetMailSend.data.result ) {
				
				result	= true;
				msg		= passwordResetMailSend.data.msg;
				errMsgType	= "success";
				
			} else {
				result	= false;
				errMsg		= passwordResetMailSend.data.msg;
				errMsgType	= "warning";
			}
			
		} catch( err ) {
			
			console.log( err );
			
			result	= false;
			errMsg		= "인증메일 발송 중 오류가 발생했습니다.";
			errMsgType	= "warning";
			
		} finally {
			
			if ( true === result ) {
				this.setMsg( msg );
			} else {
				this.createErrorMsg(
					errMsg,
					errMsgType,
				);
			}
			
			this.setState({
				isSuccessSendingMail:result
			});
		}
		
	}
	
	
	/**
	 *	에러 메시지 닫기 버튼 클릭 시 호출되는 메소드...
	 */
	closeAlert ( ) {
		this.setState({
			errMsg: ''
		});
	}

	/**
	 *	에러 메시지를 띠우는 메소드...
	 */
	createErrorMsg ( 
		errMsg,
		errMsgType, 
	) {
		this.setState({
			errMsg: errMsg,
			errMsgType:errMsgType,
		});
		
		//setTimeout(this.closeAlert, 3000);
	}
	
	
	setMsg ( msg ) {
		this.setState({
			msg: msg
		});
	}
	
	
	render ( ) {
		
		
		return(
			<Fragment>
				<CustomAlert
					open={this.state.errMsg.length > 0}
					data={this.state.errMsg} 
					option={this.state.errMsgType}
					closeAlert={this.closeAlert} 
				/>
				{
				//<Container
				//	fixed={true}
				//	maxWidth="xl"
				//	
				//	style={{
				//		//height:"calc( 100vh - 3.25rem )",
				//		height:"calc( 100vh - 3rem )",
				//		margin:0,
				//		padding:0,
				//		
				//		
				//		display:"flex",
				//		flexDirection:"column",
				//		//overflowY:"hidden",
				//		//zIndex:2,
				//	}}
				//>	
				}
				<Modal
					//className={classes.modal}
					//open={props.isOpen}
					open={true}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
					
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						margin: 0,
						padding: "8px",
					}}
				>
					<Box 
						//className={
						//	clsx(
						//		classes.modalBox,
						//	)
						//}
						display="flex"
						borderRadius="borderRadius"
						//bgcolor="white"
						
						clone={true}
						
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						
						//style={{
						//	minWidth:"350px",
						//	maxHeight:"95vh",
						//}}
					>
						<ForgetMyPasswordDetail
							sendResetPasswordMail={this.sendResetPasswordMail}
							isSuccessSendingMail={this.state.isSuccessSendingMail}
							typeEmail={this.typeEmail}
							
							isValidEmail={this.state.isValidEmail}
							
							errMsg={this.state.errMsg}
							msg={this.state.msg}
						/>
					</Box>
				</Modal>
				{
				//</Container>
				}
			</Fragment>
		);
	}
	
}

export default ForgetMyPassword;