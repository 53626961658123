import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	//withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	//IconButton,
	Box,
	CircularProgress,
} from '@material-ui/core';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),	
	},
	marginLeft2X:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3X:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4X:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	textCenter:{
		textAlign:"center"
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
}));


export default function MarkerInfoHeader ( props ) {
	
	const classes	= useStyles();

	return (
		<Fragment>
			<ThemeProvider
				theme={markerInfoTheme}
			>
				<Box
					bgcolor={
						( "ulzip" === props.type ) ?
							"secondary.main" :
							(
								( "kinder" === props.type ) ?
									"primary.main" : ""
							)
					}
					
					color={
						( "ulzip" === props.type ) ?
							"secondary.contrastText" :
							(
								( "kinder" === props.type ) ?
									"primary.contrastText" : ""
							)
					}
				>
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
					>
						<Grid
							className={
								clsx(
									//classes.noGutters
									classes.marginTop,
									classes.textCenter,
									classes.textSmall,
								)
							}
							
							item
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={3}
								xl={3}
							>
								{'구분'}
							</Grid>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={3}
								xl={3}
							>
								{'인증정보'}
							</Grid>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={3}
								xl={3}
							>
								{'통학차량'}
							</Grid>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={3}
								xl={3}
							>
								{'현원 / 정원'}
							</Grid>
						</Grid>
						
						<Grid
							className={
								clsx(
									//classes.noGutters
									classes.textCenter
								)
							}
							
							item
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={3}
								xl={3}
							>
								{
									props.data.kind
								}
							</Grid>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={3}
								xl={3}
							>
								{
									( 'ulzip' === props.type ) ? 
										(
											(
												null		=== props.data.detailInfo.scoreInfo	||
												undefined	=== props.data.detailInfo.scoreInfo
											) ? 
												(
													<ThemeProvider
														theme={markerInfoTheme}
													>
														<Box
															className={
																clsx(
																	classes.noGutters,
																	
																)
															}
															
															clone={true}
															
															color={
																( "ulzip" === props.type ) ?
																	"secondary.contrastText" : 
																	(
																		( "kinder" === props.type ) ?
																			"primary.contrastText" : "primary.contrastText"
																	)
															}
														>
															<CircularProgress 
																className={
																	clsx(
																		classes.margin,
																	)
																}
																size="1rem"
																color={
																	"inherit"
																	//( "ulzip" === props.type ) ?
																	//	"secondary.contrastText" : 
																	//	(
																	//		( "kinder" === props.type ) ?
																	//			"primary.contrastText" : "primary.contrastText"
																	//	)
																}
															/>
														</Box>
													</ThemeProvider>
												) : 
												(
													(
														null		=== props.data.detailInfo.scoreInfo.grade ||
														undefined	=== props.data.detailInfo.scoreInfo.grade ||
														null		=== props.data.detailInfo.scoreInfo.grade.value ||
														undefined	=== props.data.detailInfo.scoreInfo.grade.value ||
														'-'			=== props.data.detailInfo.scoreInfo.grade.value ||
														''			=== props.data.detailInfo.scoreInfo.grade.value ||
														0			<=	props.data.detailInfo.authInfo.type.search('미인증')
													) ? 
														(
															'미인증'
														) : 
														(
															props.data.detailInfo.scoreInfo.grade.value
														) 
												)
										) : 
										(
											( 'kinder' === props.type ) ? 
												(
													(
														null		=== props.data.detailInfo.authInfo ||
														undefined	=== props.data.detailInfo.authInfo
													) ? 
														(
															<ThemeProvider
																theme={markerInfoTheme}
															>
																<Box
																	className={
																		clsx(
																			classes.noGutters,
																			
																		)
																	}
																	
																	clone={true}
																	
																	color={
																		( "ulzip" === props.type ) ?
																			"secondary.contrastText" : 
																			(
																				( "kinder" === props.type ) ?
																					"primary.contrastText" : "primary.contrastText"
																			)
																	}
																>
																	<CircularProgress 
																		className={
																			clsx(
																				classes.margin,
																			)
																		}
																		size="1rem"
																		color={
																			"inherit"
																			//( "ulzip" === props.type ) ?
																			//	"secondary.contrastText" : 
																			//	(
																			//		( "kinder" === props.type ) ?
																			//			"primary.contrastText" : "primary.contrastText"
																			//	)
																		}
																	/>
																</Box>
															</ThemeProvider>
														) : 
														(
															props.data.detailInfo.authInfo
														)
												) :
												(
													null
												)
										)
								}
							</Grid>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={3}
								xl={3}
							>
								{
									props.data.schoolCarYN
								}
							</Grid>
							<Grid
								item
								
								xs={3}
								sm={3}
								md={3}
								lg={3}
								xl={3}
							>
								{ props.data.numOfStudents } / { props.data.quota }
							</Grid>
						</Grid>
						
						<Grid
							item
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									classes.marginY,
									classes.noPadding,
								)
							}
						>
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
								//className={
								//	clsx(
								//		classes.marginBottom,
								//		
								//	)
								//}
							>
								<Typography
									className={
										clsx(
											classes.noPadding,
											//classes.marginY,
											classes.textCenter,
											classes.fontWeightBold
										)
									}
									
									variant="h5" 
									//component="h5"
								>
									{ props.data.name }
									
									{
										//'(' + props.data.status + ')'
										(
											"정상"		!== props.data.status	&&
											""			 !== props.data.status	 &&
											undefined	 !== props.data.status	 &&
											'undefined'	 !== props.data.status	 &&
											null		 !== props.data.status
										) ?
											(
												'(' + props.data.status + ')'
											) :
											(
												""
											)
									}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							item
							container
							direction="row"
							justify="center"
							alignItems="center"
							
							className={
								clsx(
									//classes.marginTop,
									classes.marginY,
									classes.noPadding,
								)
							}
						>
							<Grid
								item
								
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								
								className={
									clsx(
										classes.textCenter,
										classes.textSmall,
										//classes.marginTop,
									)
								}
							>
								{
									(
										props.data.addressDepth1 ===
										props.data.addressDepth2 
									) ? 
										(
											props.data.addressDepth1
										) : 
										(
											props.data.addressDepth1 +
											' ' +
											props.data.addressDepth2
										)
								}
								<br />
								{
									props.data.address.substring(
										
										(
											-1 ===	props.data.address.indexOf(
													props.data.addressDepth2
												)
										) ? 
											(
												props.data.address.indexOf(
													props.data.addressDepth2.replace(
														' ',
														''
													)
												) +
												props.data.addressDepth2.replace(
													' ',
													''
												).length
											) : 
											(
												props.data.address.indexOf(
													props.data.addressDepth2
												) +
												props.data.addressDepth2.length
											)
											
									)
								}
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</ThemeProvider>
		</Fragment>
	);
	
}