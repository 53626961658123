import 
	React, 
	{ 
		//Component,
		Fragment
	} 
from 'react';


//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { //isMobile, isIOS, 
	isMobileOnly, 
	isTablet, 
	isIPad13,
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	//fade,
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Container,
	Typography,
	Grid,
	Button,
	IconButton,
	//Fab,
	Box,
	//Modal,
	//Backdrop,
	Divider,
	TextField,
	InputAdornment,
	FormControl,
	//FormLabel,
	FormHelperText,
	FormGroup,
	FormControlLabel,
	InputLabel,
	//Input,
	//Switch,
	//Slide,
	//Collapse,
	//Grow,
	Select,
	MenuItem,
	Checkbox,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

import Icon				from '@material-ui/core/Icon';
import {
	Close		as CloseIcon,
	ChevronLeft	as ChevronLeftIcon,
	Visibility,
	VisibilityOff,
	UnfoldLess	as UnfoldLessIcon,
	ExpandMore	as ExpandMoreIcon,
} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			main:"#3f51b5",
		},
		secondary	: {
			//main:"#2e7d32",
			main:"#388e3c",
		},
		third		: {
			main:"#616161",	// lightGrey
			contrastText:"#fff",
		},
		fourth		: {
			main:"rgb(0,0,0,0.54)",
			contrastText:"#fff",
		},
		error		: {
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			main:"#ffa000",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink: 0,
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
}));



export default function RegisterUserIdDetail ( props ) {
	
	const classes	= useStyles();
	
	const [values, setValues] = React.useState({
		email:"",
		emailDomain: "직접 작성",
		
		password: "",
		passwordConfirm: "",
		showPassword: false,
	});
	
	const handleChange = prop => event => {
		
		setValues({ 
			...values, 
			[prop]: event.target.value 
		});
		
		//console.log( event.target.value );
		
		if ( "email" === prop ) {
			props.typeEmail( event );
		} else if ( "password" === prop ) {
			props.typePassword( event );
		} else if ( "passwordConfirm" === prop ) {
			props.typePasswordConfirm( event );
		} else if ( "emailDomain" === prop ) {
			props.selectEmailType( event );
		} else {
			
		}
		
	};
	
	const handleClickShowPassword = () => {
		setValues({ 
			...values, 
			showPassword: !values.showPassword 
		});
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};
	
	
	const [checkedValues, setCheckedValues]	= React.useState({
		termConsentYN:false,
	});
	
	
	const handleChangeCheckedValues = prop => event => {
		
		setCheckedValues({ 
			...checkedValues, 
			[prop]: event.target.checked 
		});
		
		//console.log( event.target.checked );
		
		if ( "termConsentYN" === prop ) {
			props.consentTerms( event );
		} else {
			
		}
		
	};
	
	
	const inputLabel = React.useRef(null);
	const [labelWidth, setLabelWidth] = React.useState(0);
 	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
 	}, []);
	
	//console.log( values.emailDomain );
	// <Grid
	// 						item
							
	// 						//xs={12}	
	// 						//sm={12}
	// 						//md={12}
	// 						//lg={12}
	// 						//xl={12}
	// 					>
							
	// 						<Typography 
	// 							className={
	// 								clsx(
	// 									classes.paddingX,
	// 									classes.marginTop,
	// 									classes.marginBottom2,
	// 									//classes.fontWeightBold,
	// 									classes.textCenter,
	// 									classes.marginBottom,
	// 								)
	// 							}
	// 							variant={
	// 								( true ===  isMobileOnly ) ?
	// 									"h4" : "h3"
	// 							} 
								
	// 						>
	// 							{
	// 								"아이픽 회원가입"
	// 							}
	// 						</Typography>
	// 					 </Grid>
	return (
		<Fragment>
			<Container
				className={
					clsx(
						//classes.noGutters,
						classes.noMargin,
						classes.paddingY,
						classes.padding2X,
						classes.overflowYScroll,
					)
				}
				
				style={{
					//height:"100%",
					
					
					//width:"100%",
					maxHeight:"95vh",
				}}
			>
				<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
						
						//style={{
						//	width:"100%",
						//}}
					>
						
						
						<Grid
							item
							
							//xs={12}	
							//sm={12}
							//md={12}
							//lg={12}
							//xl={12}
						>
							<Typography 
								className={
									clsx(
										classes.paddingX,
										classes.margin2Y,
										classes.textCenter,
										
										classes.fontWeightBold,
									)
								}
								variant="subtitle1" 
								//variant="h6" 
							>
								{
									//"아이를 위해 Pick 하세요. 아이픽 "
								}
								{
									
									// 	<ThemeProvider
									// 	theme={markerInfoTheme}
									// >
									// 	<Box
									// 		color={"fourth.main"}
									// 		clone
									// 	>
									// 		<Icon
									// 			className={"fas fa-hand-point-up"}
									// 			color="inherit" 
									// 			//fontSize="large"

									// 			style={{
									// 				overflow:"visible",
									// 			}}
									// 		/>
									// 	</Box>
									// </ThemeProvider>
								}
								
							</Typography>
						</Grid>
					</Grid>
					
					{
						( true === props.isCompleteYN ) ?
							(
								<Fragment>
									<Grid
										container
										direction="column"
										justify="center"
										alignItems="center"
										
										spacing={2}
									>
										<Grid
											item
										>
											<Typography 
												className={
													clsx(
														classes.paddingX,
														//classes.margin2Y,
														classes.textCenter,
													)
												}
												variant="subtitle1" 
												//variant="h6" 
											>
												{
													props.msg
												}
											</Typography>
										</Grid>
										<Grid
											item
											
											xs={6}	
											sm={6}
											md={6}
											lg={6}
											xl={6}
										>
											<Button
												component={Link}
												to="/m"
												variant="outlined"
												fullWidth={true}
											>
												{ "홈으로" }
											</Button>
										</Grid>
									</Grid>
								</Fragment>
							) : 
							(
								<Fragment>
									<Grid
										container
										direction="column"
										justify="center"
										alignItems="center"
										
										className={
											clsx(
												//classes.margin2Y,
												classes.marginY,
											)
										}
									>
										<Grid
											item
											
											//xs={12}	
											//sm={12}
											//md={12}
											//lg={12}
											//xl={12}
											
											className={
												clsx(
													classes.textCenter,
												)
											}
										>
											<Typography 
												className={
													clsx(
														classes.paddingX,
														classes.noMargin,
														classes.textCenter,
													)
												}
												variant="subtitle1" 
												
												//style={{
												//	textAlign:"center"
												//}}
											>
												{
													"회원가입 없이 SNS로 간편 로그인"
												}
											</Typography>
											
										</Grid>
									</Grid>
									
									<form
										name="frm_register" 
										id="frm_register"
										
										autoComplete="off"
									>
										<Grid
											container
											direction="row"
											justify="center"
											alignItems="center"
										>
											<Grid
												item
												
												xs={2}
												sm={2}
												md={4}
												lg={4}
												xl={4}
											/>
											<Grid
												item
												
												xs={4}
												sm={4}
												md={2}
												lg={2}
												xl={2}
												
												className={
													clsx(
														classes.textRight,
														classes.paddingX,
													)
												}
											>
												<Button
													href="/auth/login/kakao"
													component={"a"}
													
													className={
														clsx(
															classes.smallButton,
														)
													}
												>
													<img
														src="/img/logos/kakaolink_btn_medium.png"
														alt="카카오 로그인"
														style={{
															//border:"none",
															width:"2.65rem",
															height:"2.75rem",
															maxWidth:"68px",
															maxHeight:"69px"
														}}
													/>
												</Button>
											</Grid>
											<Grid
												item
												
												xs={4}
												sm={4}
												md={2}
												lg={2}
												xl={2}
												
												className={
													clsx(
														classes.textLeft,
														classes.paddingX,
													)
												}
											>
												<Button
													href="/auth/login/naver"
													component={"a"}
													
													className={
														clsx(
															classes.smallButton,
														)
													}
												>
													<img
														src="/img/logos/naver_icon.PNG"
														alt="네이버 로그인"
														style={{
															border:"none",
															width:"2.65rem",
															height:"2.75rem",
															maxWidth:"68px",
															maxHeight:"69px"
														}}
													/>
												</Button>
											</Grid>
											<Grid
												item
												
												xs={2}
												sm={2}
												md={4}
												lg={4}
												xl={4}
											/>
										</Grid>
										
										<Divider
											variant="middle"
											//data-content="OR"
											className={
												clsx(
													classes.margin2Y,
												)
											}
										/>
										<Grid
											item
											
											//xs={12}	
											//sm={12}
											//md={12}
											//lg={12}
											//xl={12}
											
											className={
												clsx(
													classes.textCenter,
												)
											}
										>
											<Typography 
												className={
													clsx(
														classes.paddingX,
														classes.noMargin,
														classes.textCenter,
													)
												}
												variant="subtitle1" 
												
												//style={{
												//	textAlign:"center"
												//}}
											>
												{
													"이메일로 회원가입"
												}
											</Typography>
											
										</Grid>
										
										<Grid
										//FormGroup
										//	row={true}
											container
											direction="row"
											justify="center"
											alignItems="center"
										>
											<Grid
												item
												
												xs={7}
												sm={7}
												md={7}
												lg={7}
												xl={7}
											>
												<TextField
													className={
														clsx(
															//classes.marginTop2,
															//classes.textField
															classes.noMargin,
														)
													}
													label="이메일"
													type="email"
													name="email"
													autoComplete="email"
													value={values.email}
													//margin="dense"
													margin="none"
													variant="outlined"
													disabled={"success"	=== props.emailAuthResult}
													fullWidth={
														//false
														true
													}
													
													onChange={handleChange("email")}
												/>
											</Grid>
											
											
											<Grid
												item
												
												xs={5}
												sm={5}
												md={5}
												lg={5}
												xl={5}
												
												className={
													clsx(
														classes.padding,
													)
												}
											>
												<FormControl 
													variant="outlined" 
													className={
														clsx(
															//classes.formControl,
															//classes.margin,
														)
													}
													
													style={{
														minWidth:"120px",
														marginLeft:"8px",
														
														width:"100%",
													}}
													
													disabled={
														(
															0			<	values.email.length		&&
															true		=== props.emailAuthYN		&&
															"success"	=== props.emailAuthResult
														)
													}
												>
													<InputLabel 
														ref={inputLabel} 
														htmlFor="email-domain-select"
													>
														이메일
													</InputLabel>
													<Select
														value={values.emailDomain}
														onChange={handleChange("emailDomain")}
														labelWidth={labelWidth}
														inputProps={{
															//name: 'emailDomain',
															id: "email-domain-select",
														}}
														//autoWidth={true}
														variant="outlined"
													>
														<MenuItem value={"직접 작성"}>직접 작성</MenuItem>
														<MenuItem value={"@naver.com"}>@naver.com</MenuItem>
														<MenuItem value={"@daum.net"}>@daum.net</MenuItem>
														<MenuItem value={"@hanmail.net"}>@hanmail.net</MenuItem>
														<MenuItem value={"@gmail.com"}>@gmail.com</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Grid
											//FormGroup
										>
										
										<FormGroup
											row={true}
										>
											<FormControl
												className={
													clsx(
														classes.paddingSmall,
													)
												} 
												error={
													//true
													!props.isValidEmail
												}
											>
												<FormHelperText 
													className={
														clsx(
															classes.formHelperText,
														)
													} 
												>
													{
														( 0 === values.email.length ) ?
															" " :
															(
																( props.isValidEmail ) ?
																	"유효한 이메일 형식입니다." : "이메일 형식이 유효하지 않습니다."
															)
													}
												</FormHelperText>
											</FormControl>
										</FormGroup>
										
										<FormGroup
											row={true}
											
											className={
												clsx(
													classes.marginTop,
												)
											}
										>
											<ThemeProvider
												theme={markerInfoTheme}
											>
												<Button
													color="secondary"
													fullWidth={true}
													//variant="outlined"
													variant="contained"
													disabled={
														(
															values.email.length <= 0
														)	||
														(
															 0		<	values.email.length	&&
															false	=== props.emailAuthYN	&&
															false	=== props.isValidEmail
														)	||
														(
															0			<	values.email.length	&&
															true		=== props.emailAuthYN	&&
															"success"	=== props.emailAuthResult
														)
													}
													
													onClick={props.sendAuthEmail}
												>
													{
														"인증 메일 보내기"
													}
												</Button>
											</ThemeProvider>
										</FormGroup>
										
										<FormGroup
											row={true}
										>
											<FormControl
												className={
													clsx(
														classes.formControl,
														classes.marginBottom,
													)
												} 
												error={"error" === props.emailAuthResult}
											>
												<FormHelperText 
													className={
														clsx(
															classes.formHelperText,
														)
													} 
													error={"error" === props.emailAuthResult}
												>
													{
														( "" === props.emailAuthResult ) ?
															"회원가입을 위해 이메일 인증이 필요합니다." :
															(
																props.msg
															)
													}
												</FormHelperText>
											</FormControl>
										</FormGroup>
										
										<TextField
											className={
												clsx(
													//classes.marginX,
													//classes.textField
												)
											}
											label="비밀번호"
											value={values.password}
											type={values.showPassword ? 'text' : 'password'}
											//placeholder={"비밀번호"}
											
											//margin="dense"
											margin="none"
											variant="outlined"
											
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															edge="end"
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
														>
															{values.showPassword ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
												),
											}}
											fullWidth={true}
											
											onChange={handleChange("password")}
										/>
										
										{
											( 0 === values.password.length ) ?
												(
													<FormControl
																className={
															clsx(
																classes.formControl,
															)
														} 
														error={false}
													>
														<FormHelperText 
															className={
																clsx(
																	classes.formHelperText,
																)
															} 
															error={false}
														>
															{
																""
															}
														</FormHelperText>
													</FormControl>
												) :
												(
													<FormControl
														className={
															clsx(
																classes.formControl,
															)
														} 
														error={!props.isValidPassword}
													>
														<FormHelperText 
															className={
																clsx(
																	classes.formHelperText,
																)
															} 
															error={!props.isValidPassword}
														>
															{
																( props.isValidPassword )?
																	"유효한 비밀번호 형식입니다." : "영문, 숫자, 특수문자를 조합하여 8-15자리 이내로 입력해주세요."
															}
														</FormHelperText>
													</FormControl>
												)
										}
										
										<TextField
											
											className={
												clsx(
													//classes.marginX,
													//classes.textField
													classes.marginTop,
												)
											}
											label="비밀번호 확인"
											value={values.passwordConfirm}
											type={values.showPassword ? 'text' : 'password'}
											//placeholder={"비밀번호 확인"}
											
											//margin="dense"
											margin="none"
											variant="outlined"
											fullWidth={true}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															edge="end"
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
														>
															{values.showPassword ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
												),
											}}
											
											onChange={handleChange("passwordConfirm")}
										/>
										
										{
											( 0 === values.passwordConfirm.length )?
												(
													<FormControl
														className={
															clsx(
																classes.formControl,
															)
														} 
														error={false}
													>
														<FormHelperText 
															className={
																clsx(
																	classes.formHelperText,
																)
															} 
															error={false}
														>
															{
																""
															}
														</FormHelperText>
													</FormControl>
												) :
												(
													<FormControl
														className={
															clsx(
																classes.formControl,
															)
														} 
														error={
															( !props.isValidPasswordConfirm )
														}
													>
														<FormHelperText 
															className={
																clsx(
																	classes.formHelperText,
																)
															} 
															error={
																( !props.isValidPasswordConfirm )
															}
														>
															{
																( true === props.isValidPasswordConfirm )?
																	"비밀번호와 일치합니다." : "비밀번호와 일치하지 않습니다."
															}
														</FormHelperText>
													</FormControl>
												)
										}
										
										<FormGroup
											row={true}
										>
											<FormControlLabel
												control={
													<Checkbox
														checked={checkedValues.termConsentYN}
														onChange={handleChangeCheckedValues('termConsentYN')}
														value="termConsentYN"
														color="primary"
														
														className={
															clsx(
																classes.noPadding,
																classes.marginRight,
															)
														}
													/>
												}
												label={
													//"아이픽에서 제공하는"
													
													<Fragment>
														{
															"아이픽에서 제공하는"
														}
														<a
															target="_blank"
															href={"/Terms"}
															rel="noopener noreferrer"
															
															className={
																clsx(
																	classes.marginLeftSmall,
																	classes.noPadding,
																)
															}
														>
															{
																"서비스 약관"
															}
														</a>
														
														{
															"에 동의합니다."
														}
													</Fragment>
													
												}
												
												className={
													clsx(
														classes.noMargin,
													)
												}
											/>
											
											{
											//<a
											//	target="_blank"
											//	href={"/Terms"}
											//	rel="noopener noreferrer"
											//	
											//	className={
											//		clsx(
											//			classes.marginLeftSmall,
											//			classes.noPadding,
											//		)
											//	}
											//>
											//	{
											//		"서비스 약관"
											//	}
											//</a>
											//
											//{
											//	"에 동의합니다."
											//}
											}
											
										</FormGroup>
										
										<FormGroup
											row={true}
											
										>
											<FormControl
												error={false}
												
												className={
													clsx(
														classes.formControl,
														
													)
												} 
												
												style={{
													width:"100%",
												}}
											>
												<FormHelperText 
													className={
														clsx(
															classes.formHelperText,
															classes.textCenter,
														)
													} 
													error={false}
												>
													{
														"아이픽에서 제공하는 "
													}
													
													<a
														target="_blank"
														href={"/Privacy"}
														rel="noopener noreferrer"
														
														className={
															clsx(
																classes.marginLeftSmall,
																classes.noPadding,
															)
														}
													>
														{
															"개인정보 처리방침"
														}
													</a>
													
													{
														" 확인."
													}
												</FormHelperText>
											</FormControl>
										</FormGroup>
										
										{
										//<FormControl
										//	error={false}
										//	
										//	className={
										//		clsx(
										//			classes.formControl,
										//			
										//		)
										//	} 
										//>
										//	<FormHelperText 
										//		className={
										//			clsx(
										//				classes.formHelperText,
										//			)
										//		} 
										//		error={false}
										//	>
										//		{
										//			props.msg
										//		}
										//	</FormHelperText>
										//</FormControl>
										}
										
										<Grid
											container
											direction="row"
											justify="space-around"
											alignItems="center"
											
											className={
												clsx(
													classes.marginY,
												)
											}
										>
											<Grid
												item
												
												xs={5}
												sm={5}
												md={5}
												lg={5}
												xl={5}
												
											>
												<Button 
													component={Link}
													to="/m" 
													variant="outlined"
													fullWidth={true}
												>
													{ "홈으로" }
												</Button>
											</Grid>
											<Grid
												item
												
												xs={5}
												sm={5}
												md={5}
												lg={5}
												xl={5}
											>
												<Button 
													disabled={
														false		=== props.isValidEmail				|| 
														false		=== props.isValidPassword			||
														false		=== props.isValidPasswordConfirm	||
														false		=== props.emailAuthYN				||
														"success"	!== props.emailAuthResult			||
														false		=== checkedValues.termConsentYN
													}
													variant="outlined"
													fullWidth={true}
													onClick={props.registerUser}
												>
													{
														"계정 만들기"
													}
												</Button>
											</Grid>
										</Grid>
										
									</form>
									
									{
										(
											true	===	isMobileOnly	||
											true	===	isTablet		||
											true	===	isIPad13
										) ?
											(
												<div
													className={
														clsx(
															classes.noGutters,
														)
													}
													
													style={{
														height:"3rem",
														//height:"6rem",
													}}
												/>
											) : null
									}
									
								</Fragment>
							)
					}
			</Container>
		</Fragment>
	);
	
}