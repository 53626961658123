import 
	React, { Component } 
from 'react';

import clsx from 'clsx';

import axios from 'axios';

import {
	Container,
	Box,
	Modal,
	Backdrop,
} from '@material-ui/core';

import { 
	isMobile, 
	isIOS, 
	isMobileOnly, 
	isTablet, 
	isAndroid, 
	isBrowser,
	isIPad13,
} from 'react-device-detect';


import ChangeNickNamePopUpDetail from './ChangeNickNamePopUpDetail';



class ChangeNickNamePopUp extends Component {
	
	constructor( props ) {
	
		super( props );
		
		
		this.state	= {
			
			changeNickNameYN:false,
			
			userNickName:"",			// 사용자가 입력한 닉네임
			isValidNickName:false,		// 사용자가 입력한 닉네임이 정규식에 유효한 경우 true
			
			userNickNameCheckResult:"",	// 서버에 중복확인 요청 후 중복이 아니면서 유효한 경우 success 리턴
			confirmnNickName:false,		// success 리턴 후 해당 닉네임을 사용하겠다는 사용자의 명시적 확인을 한 번 더 받으면 true로 지정하여
			
			msg:""
			//errorMsg:"",
			//errorMsgType:"warning",
		};
		
		
		this.typeNickName				= this.typeNickName.bind(this);
		this.checkNickName				= this.checkNickName.bind(this);
		this.changeNickName				= this.changeNickName.bind(this);
		this.toggleNickNameChangeStatus	= this.toggleNickNameChangeStatus.bind(this);
		this.updateNickNameConfirmYN	= this.updateNickNameConfirmYN.bind(this);
		
	}
	
	
	async changeNickName( ) {
		
		let changeableYN	= false;
		
		
		if( 
			// 닉네임만 입력한 경우
			this.state.userNickName.length > 0
		) {
			if( true === this.state.confirmnNickName ) {
				changeableYN	= true;
			} else {
				changeableYN	= false;
				
				if(
					true === this.state.isValidNickName
				) {
					this.props.createErrorMsg(
						"닉네임 중복확인이 필요합니다.",
						"warning"
					);
				}
				
			}
		} else {
			changeableYN	= false;
		}
		
		if( false  === changeableYN ) {
			//console.log( "false" );
			return;
		}
		
		
		let errorMsg 		= '';
		let errorMsgType	= "warning";
		
		try {
			
			const saveMyinfoResult	= await axios.post(
				'/user/saveMyinfo',
				{
					nickname:this.state.userNickName,
					nickname_confirm_yn:true
				}
			);
			
			//const saveMyinfoResult	= await fetch(
			//		'/user/saveMyinfo',
			//		{
			//			method: 'POST',
 			//			//mode: 'CORS',
 			//			body : JSON.stringify({
			//				nickname:this.state.userNickName,
			//				nickname_confirm_yn:true
			//			}),
 			//			headers: {
 			//				'Content-Type': 'application/json'
 			//			}
			//		}
			//	);
			
			//const saveMyinfoResultJson	= await saveMyinfoResult.json();
			
			
			if( "success" === saveMyinfoResult.data.result ) {
				
				errorMsg		= saveMyinfoResult.data.msg;
				errorMsgType	= saveMyinfoResult.data.result;
				
				this.props.getLoginInfo();
				
			} else {
				errorMsg		= saveMyinfoResult.data.msg;
				//errorMsgType	= saveMyinfoResult.data.result;
				errorMsgType	= "warning";
			}
			
		} catch( err ) {
			console.log( err );
			errorMsg		= "닉네임 변경에 실패하였습니다.";
			errorMsgType	= "error";
		} finally {
			
			if ( "" !== errorMsg ) {
				this.props.createErrorMsg( 
					errorMsg,
					errorMsgType
				);
			}
			
		}
	}
	
	
	typeNickName(e) {
		
		e.preventDefault();
		
		//const nickNamePattern	= /^[\w가-힣]{2,20}$/;
		const nickNamePattern	= /^[가-힣|a-z|A-Z|0-9][가-힣|a-z|A-Z|0-9|_]{2,15}$/;
		
		//^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9][ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_]+{2,16}$
		
		let tmp_nick_name	= e.target.value;
			
		tmp_nick_name	= tmp_nick_name.replace( nickNamePattern, "" );
		
		
		if( ""  !== tmp_nick_name ) {
			this.setState({
				userNickName: e.target.value,
				isValidNickName : false,
				userNickNameCheckResult:"",
				confirmnNickName:false,
				msg:""
			});
		} else {
			this.setState({
				userNickName: e.target.value,
				isValidNickName : true,
				userNickNameCheckResult:"",
				confirmnNickName:false,
				msg:""
			});
		}
		
		//this.setState({
		//	userNickName: e.target.value,
		//	isValidNickName : true,
		//	userNickNameCheckResult:"",
		//	confirmnNickName:false,
		//	msg:""
		//});
		
	}
	
	
	async checkNickName ( ) {
		
		if( false === this.state.isValidNickName ) {
			return;
		}
		
		let userNickNameCheckResult	= "";
		let confirmnNickName	= false;
		
		let errorMsg		= "";
		let errorMsgType	= "warning";
		
		//console.log( this.state.userNickName );
		
		try {
			
			const nicknameDupCheckResult	= await axios.post(
				'/user/nicknameDupCheck',
				{
					nickname:this.state.userNickName,
				}
			);
			//const nicknameDupCheckResult	= await fetch(
			//		'/user/nicknameDupCheck',
			//		{
			//			method: 'POST',
 			//			//mode: 'CORS',
 			//			body : JSON.stringify({
			//				nickname:this.state.userNickName
			//			}),
 			//			headers: {
 			//				'Content-Type': 'application/json'
 			//			}
			//		}
			//	);
			
			//const nicknameDupCheckResultJson	= await nicknameDupCheckResult.json();
			
			//console.log( nicknameDupCheckResult.data );
			
			if( "success" === nicknameDupCheckResult.data.result ) {
				userNickNameCheckResult	= nicknameDupCheckResult.data.result;
				errorMsg		= nicknameDupCheckResult.data.msg;
				errorMsgType	= nicknameDupCheckResult.data.result;
				confirmnNickName	= true;
			} else {
				userNickNameCheckResult	= nicknameDupCheckResult.data.result;
				errorMsg		= nicknameDupCheckResult.data.msg;
				errorMsgType	= "warning";
				confirmnNickName	= false;
			}
			
		} catch ( err ) {
			
			console.log( err );
			userNickNameCheckResult	= "error";
			errorMsg		= "닉네임 중복확인 중에 에러가 발생했습니다.";
			errorMsgType	= "error";
			confirmnNickName	= false;
			
		} finally {
			
			if ( "" !== errorMsg ) {
				this.props.createErrorMsg(
					errorMsg,
					errorMsgType
				);
			}
			
			this.setState({
				userNickNameCheckResult:userNickNameCheckResult,
				confirmnNickName:confirmnNickName,
				msg:errorMsg,
			});
			
		}
		
	}
	
	
	toggleNickNameChangeStatus( e ) {
		
		let userNickName	= this.state.userNickName;
		let userNickNameCheckResult	= this.state.userNickNameCheckResult;
		let confirmnNickName		= this.state.confirmnNickName;
		
		//console.log(e.target.checked);
		
		if( 
			false === e.target.checked
		) {
			userNickName	= "";
			
			userNickNameCheckResult	= "";
			confirmnNickName		= false;
		}
		
		this.setState({
			changeNickNameYN: e.target.checked,
			userNickName:userNickName,
			
			userNickNameCheckResult:userNickNameCheckResult,
			confirmnNickName:confirmnNickName
		});
		
	}
	
	
	async updateNickNameConfirmYN ( ) {
		
		let errorMsg		= '';
		let errorMsgType	= "warning";
		
		let ret = '';
		
		try {
			
			const saveMyinfoResult	= await axios.post(
				'/user/saveMyinfo',
				{
					nickname:this.props.userNickName,
					nickname_confirm_yn:true
				}
			);
			//const saveMyinfoResult	= await fetch(
			//		'/user/saveMyinfo',
			//		{
			//			method: 'POST',
 			//			//mode: 'CORS',
 			//			body : JSON.stringify({
			//				nickname:this.props.userNickName,
			//				nickname_confirm_yn:true
			//			}),
 			//			headers: {
 			//				'Content-Type': 'application/json'
 			//			}
			//		}
			//	);
			//
			//const saveMyinfoResultJson	= await saveMyinfoResult.json();
			ret = saveMyinfoResult.data.result;
			
			
			if( "success" === saveMyinfoResult.data.result ) {
				
				errorMsg		= saveMyinfoResult.data.msg;
				errorMsgType	= saveMyinfoResult.data.result;
				
				this.props.getLoginInfo();
				
			} else {
				errorMsg		= saveMyinfoResult.data.msg;
				errorMsgType	= "warning";
			}
			
		} catch( err ) {
			console.log( err );
			errorMsg		= "회원정보 저장에 실패하였습니다.";
			errorMsgType	= "error";
		} finally {
			
			if ( "" !== errorMsg ) {
				this.props.createErrorMsg( 
					errorMsg,
					errorMsgType
				);
			}
			
			if( 'error' !== ret ) {
				this.props.closeChangeNickNamePopUp();
			}
			
		}
		
	}
	
	
	
	render ( ) {
		
		//console.log( this.props );
		
		return (
			<Modal
				open={
					//true
					this.props.isOpen
				}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					margin: 0,
					padding: "8px",
				}}
			>
				<Box 
					//className={
					//	clsx(
					//		classes.modalBox,
					//	)
					//}
					display="flex"
					borderRadius="borderRadius"
					//bgcolor="white"
					
					clone={true}
					
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					
					//style={{
					//	minWidth:"350px",
					//	maxHeight:"95vh",
					//}}
				>
					<Container
						maxWidth="xs"
						className={
							clsx(
								//classes.noGutters,
								//classes.noMargin,
								//classes.padding2X,
							)
						}
						
						style={{
							padding:0,
							margin:0,
							backgroundColor:"white",
							minWidth:"350px",
							maxHeight:"95vh",
						}}
					>
						<ChangeNickNamePopUpDetail
							userNickName={
								this.props.userNickName
								//"hey!!!"
							}
							typeNickName={this.typeNickName}
							toggleNickNameChangeStatus={this.toggleNickNameChangeStatus}
							updateNickNameConfirmYN={this.updateNickNameConfirmYN}
							isValidNickName={this.state.isValidNickName}
							checkNickName={this.checkNickName}
							confirmnNickName={this.state.confirmnNickName}
							changeNickName={this.changeNickName}
							msg={this.state.msg}
							
							userNickNameCheckResult={this.state.userNickNameCheckResult}
						/>
					</Container>
				</Box>
			</Modal>
		);
	}
	
}

export default ChangeNickNamePopUp;