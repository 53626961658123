import 
	React, 
	{ 
		Fragment
	} 
from 'react';

import StarRatingContainer	from '../Utility/StarRating/StarRatingContainer.js';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { //isMobile, isIOS, 
	isMobileOnly, 
	//isTablet, 
	//isIPad13,
	//isAndroid, isBrowser 
} from 'react-device-detect';

import { 
	ThemeProvider 
} from '@material-ui/styles';

import { 
	makeStyles,
	withStyles,
	createMuiTheme,
} from '@material-ui/core/styles';

import {
	Box,
	Container,
	Grid,
	Button,
	//Divider,
	//Typography,
	Tabs,
	Tab,
	DialogTitle,
	DialogContent,
	DialogActions,
	AppBar,
	TextField,
} from '@material-ui/core';

import { 
	red 
} from '@material-ui/core/colors';

//import Icon		from '@material-ui/core/Icon';

//import {
//	//Close		as CloseIcon,
//	//ChevronLeft		as ChevronLeftIcon,
//	//ChevronRight	as ChevronRightIcon,
//	//Visibility,
//	//VisibilityOff,
//	//UnfoldLess	as UnfoldLessIcon,
//	//ExpandMore	as ExpandMoreIcon,
//	//Favorite		as FavoriteIcon,
//	//FavoriteBorder	as FavoriteBorderIcon,
//	//ArrowBackIos	as ArrowBackIosIcon
//} from '@material-ui/icons';


const markerInfoTheme	= createMuiTheme({
	palette: {
		primary		: {
			dark:"#2c387e",	
			main:"#3f51b5",
			contrastText:"#ffffff",
		},
		secondary	: {
			dark:"#27632a",
			main:"#388e3c",
			contrastText:"#ffffff",
		},
		third		: {
			dark:"#434343",
			main:"#616161",	// Grey
			light:"#808080",
			contrastText:"#ffffff",
		},
		fourth		: {
			dark:"#9c9c9c",
			main:"#e0e0e0",	// lightGrey
			light:"#e6e6e6",
			contrastText:"#ffffff",
		},
		error		: {
			dark:"#8a1c1c",
			main:"#c62828",
			light:"#f44336",
		},
		warning		: {
			dark:"#ff6f00",
			main:"#ffa000",
			light:"#ffb333",
		},
	}
});


const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	shrink:{
		flexShrink:0
	},
	button:{
		margin: theme.spacing(0),
		padding: theme.spacing(1)
	},
	smallButton:{
		margin: theme.spacing(0),
		padding: theme.spacing(0.5)
	},
	fab: {
		margin: theme.spacing(0),
		//padding: theme.spacing(1)
		//display: 'flex',
		//alignItems: 'center',
		//justifyContent: 'center'
	},
	noPadding:{
		padding: theme.spacing(0)
	},
	noMargin:{
		margin: theme.spacing(0)
	},
	noGutters:{
		margin: theme.spacing(0),
		padding: theme.spacing(0)
	},
	padding:{
		padding: theme.spacing(1)
	},
	padding2:{
		padding: theme.spacing(2)
	},
	paddingLeft:{
		paddingLeft: theme.spacing(1),
	},
	paddingRight:{
		paddingRight: theme.spacing(1),
	},
	paddingX:{
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	padding2X:{
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	
	padding3X:{
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	
	padding4X:{
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paddingY:{
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	paddingSmall:{
		padding:theme.spacing(0.5),
	},
	paddingSmallX:{
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
	},
	paddingSmallY:{
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	paddingTop:{
		paddingTop: theme.spacing(1),
	},
	paddingBottom:{
		paddingBottom: theme.spacing(1),
	},
	margin:{
		margin: theme.spacing(1)
	},
	marginX:{
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	margin2X:{
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	marginY:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	marginSmallY:{
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
	margin2Y:{
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	marginTop:{
		marginTop: theme.spacing(1),
	},
	marginTop2:{
		marginTop: theme.spacing(2),
	},
	marginBottom:{
		marginBottom: theme.spacing(1),
	},
	marginLeftSmall:{
		marginLeft:theme.spacing(0.5),
	},
	marginLeft:{
		marginLeft:theme.spacing(1),
	},
	marginLeft2:{
		marginLeft:theme.spacing(2),	
	},
	marginLeft3:{
		marginLeft:theme.spacing(3),	
	},
	marginLeft4:{
		marginLeft:theme.spacing(4),	
	},
	marginRight:{
		marginRight:theme.spacing(1),	
	},
	marginRightSmall:{
		marginRight:theme.spacing(0.5),
	},
	marginRight2:{
		marginRight:theme.spacing(2),	
	},
	marginRight3:{
		marginRight:theme.spacing(3),	
	},
	marginRight4:{
		marginRight:theme.spacing(4),	
	},
	textLeft:{
		textAlign:"left"
	},
	textCenter:{
		textAlign:"center"
	},
	textRight:{
		textAlign:"right",
	},
	bgWhite:{
		backgroundColor:"white",
	},
	bgLight:{
		backgroundColor:"#f8f9fa !important"
	},
	fontWeightBold:{
		fontWeight:700
	},
	fontWeightLight:{
		fontWeight:300
	},
	textSmall:{
		fontSize:"80%",
	},
	overflowYScroll:{
		overflowY:"scroll",
	},
	topMenuBar:{
		flexShrink	: 0,
		backgroundColor	: "#3f51b5",	// primary.main
	},
	topMenuBarList:{
		height:"3rem",
		minHeight:"3rem",
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(0),
		padding: theme.spacing(1),
	},
	modalBox: {
		margin: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(1),
		//maxWidth:"95%",
		backgroundColor:"white"
	},
	
	textField: {
		flexBasis: 200,
 	},
	formControl:{
		padding:theme.spacing(0.5),
		marginTop:theme.spacing(1),
		//marginBottom:theme.spacing(0),
		//marginLeft:theme.spacing(0),
		//marginRight:theme.spacing(0),
	},
	formHelperText:{
		padding:theme.spacing(0),
		margin:theme.spacing(0),
	},
	
	iconHover: {
		margin: theme.spacing(2),
		'&:hover': {
			color: red[800],
		},
	},
	
	dialogPaper: 
		( true === isMobileOnly ) ?
			{
				margin	: theme.spacing(0),
				minWidth: "90vw",
			} :
			{
				margin: theme.spacing(0),
			},
	
}));


const DialogContentInTab	= withStyles({
	root	: {
		"&:first-child"	: {
			//paddingTop	: "4px",
			paddingTop	: "0px",
		}
	},
})(DialogContent);


const MAX_STAR_COUNT	= 5;

function UserReviewFormDetail ( props ) {
	
	const classes	= useStyles();
	
	const [starRate, setStarRate]			= React.useState(0);
	
	const handleStarRate = ( value ) => {
		
		if ( 0 > value ) {
			setStarRate( 0 );
		} else if ( 5 < value ) {
			setStarRate( MAX_STAR_COUNT );
		} else {
			setStarRate( value );
		}
		
	};
	
	
	const [reviewContent, setReviewContent]			= React.useState("");
	
	const handleReviewContent	= ( e )	=>	{
		e.preventDefault();
		setReviewContent( e.target.value );
	};
	
	//console.log( props );
	//console.log( starRate );
	
	
	return (
		<Fragment>
			
			{
				( true === props.isTabContent ) ?
					(
					<Fragment>
						<DialogContentInTab 
							dividers={true}
							
							className={
								clsx(
									//classes.noMargin,
									//classes.paddingX,
									classes.noGutters,
								)
							}
						>
							{
								( "rating" === props.ratingType ) ?
									(
										<ThemeProvider
											theme={markerInfoTheme}
										>
											<Box
												bgcolor={
													( "ulzip" === props.type ) ?
														"secondary.main" :
														(
															( "kinder" === props.type ) ?
																"primary.main" : ""
														)
												}
												
												//borderTop={2}
												
												borderColor={
													( "ulzip" === props.type ) ?
														"secondary.contrastText" :
														(
															( "kinder" === props.type ) ?
																"primary.contrastText" : ""
														)
												}
												
												color={
													( "ulzip" === props.type ) ?
														"secondary.contrastText" :
														(
															( "kinder" === props.type ) ?
																"primary.contrastText" : ""
														)
												}
											>
												<Grid
													container
													direction="row"
													justify="center"
													alignItems="center"
												>
													<Grid
														item
														
														xs={12}
														sm={12}
														md={12}
														lg={12}
														xl={12}
														
														className={
															clsx(
																classes.textCenter,
															)
														}
													>
														<StarRatingContainer
															fontSize={"large"}
															handleStarRate={handleStarRate}
															editable={true}
															
															ratingMaxCount={MAX_STAR_COUNT}
														/>
													</Grid>
												</Grid>
											</Box>
										</ThemeProvider>
									) : null
							}
							
							<Container
								maxWidth="sm"
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingX,
									)
								}
							>
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid
										item
										
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}
									>
										
										<TextField
											//label="본 문"
											label=""
											multiline
											rows="10"
											rowsMax="100"
											
											placeholder={
												"악의적인 비방이나 욕설, 허위사실을 포함한 후기는 자동으로 삭제됩니다. 다음과 같은 내용으로 작성해주시면 다른 학부모님께 도움이 됩니다. (학부모님의 생생한 후기, 아이의 호감도, 원장 선생님 경력, 부엌/화장실/장난감 위생상태, 커리큘럼, 야외활동, 어린이집 놀이터 보유 여부, 식사분위기, 원장/대표 일치여부, 보육교사 경력/연륜, 조리사 존재여부, 차량운행 여부, 식자재 등)"
											}
											
											value={reviewContent}
											onChange={handleReviewContent}
											
											//margin="normal"
											margin="dense"
											variant="outlined"
											
											className={classes.textField}
											
											style={{
												width:"100%",
											}}
										/>
										
									</Grid>
								</Grid>
							</Container>
							
						</DialogContentInTab>
					</Fragment>
					) :
					(
					<Fragment>
						<DialogTitle
							className={
								clsx(
									classes.noMargin,
									classes.paddingY,
									classes.padding2X,
									//classes.paddingX,
									//classes.paddingSmallY,
								)
							}
						>
							{
								props.title
							}
						</DialogTitle>
						
						<DialogContent 
							dividers={true}
							
							className={
								clsx(
									//classes.noMargin,
									//classes.paddingX,
									classes.noGutters,
								)
							}
						>
							{
								( "rating" === props.ratingType ) ?
									(
										<ThemeProvider
											theme={markerInfoTheme}
										>
											<Box
												bgcolor={
													( "ulzip" === props.type ) ?
														"secondary.main" :
														(
															( "kinder" === props.type ) ?
																"primary.main" : ""
														)
												}
												
												//borderTop={2}
												
												borderColor={
													( "ulzip" === props.type ) ?
														"secondary.contrastText" :
														(
															( "kinder" === props.type ) ?
																"primary.contrastText" : ""
														)
												}
												
												color={
													( "ulzip" === props.type ) ?
														"secondary.contrastText" :
														(
															( "kinder" === props.type ) ?
																"primary.contrastText" : ""
														)
												}
											>
												<Grid
													container
													direction="row"
													justify="center"
													alignItems="center"
												>
													<Grid
														item
														
														xs={12}
														sm={12}
														md={12}
														lg={12}
														xl={12}
														
														className={
															clsx(
																classes.textCenter,
															)
														}
													>
														<StarRatingContainer
															fontSize={"large"}
															handleStarRate={handleStarRate}
															editable={true}
															
															ratingMaxCount={MAX_STAR_COUNT}
														/>
													</Grid>
												</Grid>
											</Box>
										</ThemeProvider>
									) : null
							}
							
							<Container
								maxWidth="sm"
								
								className={
									clsx(
										classes.noMargin,
										classes.paddingX,
									)
								}
							>
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid
										item
										
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}
									>
										
										<TextField
											//label="본 문"
											label=""
											multiline
											rows="10"
											rowsMax="100"
											
											placeholder={
												"악의적인 비방이나 욕설, 허위사실을 포함한 후기는 자동으로 삭제됩니다. 다음과 같은 내용으로 작성해주시면 다른 학부모님께 도움이 됩니다. (학부모님의 생생한 후기, 아이의 호감도, 원장 선생님 경력, 부엌/화장실/장난감 위생상태, 커리큘럼, 야외활동, 어린이집 놀이터 보유 여부, 식사분위기, 원장/대표 일치여부, 보육교사 경력/연륜, 조리사 존재여부, 차량운행 여부, 식자재 등)"
											}
											
											value={reviewContent}
											onChange={handleReviewContent}
											
											//margin="normal"
											margin="dense"
											variant="outlined"
											
											className={classes.textField}
											
											style={{
												width:"100%",
											}}
										/>
										
									</Grid>
								</Grid>
							</Container>
							
						</DialogContent>
					</Fragment>
					)
			}
			
			<DialogActions
				className={
					clsx(
						classes.noMargin,
						classes.paddingX,
						classes.paddingSmallY,
						classes.textRight,
					)
				}
			>
				<ThemeProvider
					theme={markerInfoTheme}
				>
					<Box
						color={
							"third.light"
							//"third.main"
							//"fourth.main"
						}
					>
						<Button 
							color={"inherit"}
							className={
								clsx(
									classes.smallButton,
								)
							}
							onClick={props.others.closeDialog}
						>
							{
								"취 소"
							}
						</Button>
					</Box>
					
					
					<Button 
						//autoFocus 
						
						//variant="contained"
						variant="outlined"
						color="primary"
						
						className={
							clsx(
								classes.smallButton,
								classes.marginLeftSmall,
							)
						}
						onClick={
							async (e)	=> {
								
								//console.log(
								//	{
								//		ratingType		: props.ratingType,
								//		reviewContent	: reviewContent,
								//		starRate		: starRate,
								//	}
								//);
								
								const activeFunctionResult	= await props.activeFunction(  
									{
										ratingType		: props.ratingType,
										reviewContent	: reviewContent,
										starRate		: starRate,
									}
								);
								
								//console.log( activeFunctionResult );
								
								if ( "success" === activeFunctionResult ) {
									props.others.closeDialog();
								}
								
							}
						} 
					>
						{
							( "modify" === props.mode ) ?
								"수 정" :
								(
									( "submit" === props.mode ) ?
										"제 출" : "확 인"
								)
						}
					</Button>
				</ThemeProvider>
			</DialogActions>
			
		</Fragment>
	);
}


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{
				value === index && 
				<Box
				>
					{
						children
					}
				</Box>
			}
		</Box>
	);
}


TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function tabProps( index ) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

function UserReviewFormTabs ( props ) {
	
	const classes	= useStyles();
	
	const { type, userInfo, ratingTypes, activeFunctions, modeList, others }	= props;
	
	const [value, setValue] = React.useState(0);
	
	let tabList			= [];
	let tabContentList	= [];
	
	if(
		false	===	Array.isArray( ratingTypes )		||
		false	===	Array.isArray( activeFunctions )	||
		false	===	Array.isArray( modeList )//	||
	) {
		console.log( "UserReviewFormTabs error" )
		return null;
	}
	
	for ( let idx = 0 ; idx < ratingTypes.length ; idx++ ) {
		
		tabList.push(
			<Tab 
				//value={idx}
				key={ratingTypes[idx].type + "_" + idx.toString()}
				label={ratingTypes[idx].name}	// { type : , name : }
				{...tabProps(idx)}
			/>
		);
		
		tabContentList.push(
			<TabPanel
				key={ratingTypes[idx].type + "_" + idx.toString() + "_tabPanel"}
				
				value={value} 
				index={idx}
			>
				<UserReviewFormDetail
					key={ratingTypes[idx].type + "_" + idx.toString() + "_tabContent"}
					
					isTabContent={true}
					ratingType={ratingTypes[idx].type}
					
					type={type}	// ulzip, kinder
					userInfo={userInfo}
					
					//title={
					//	//"내 글 수정"
					//	( "rating" === props.ratingType ) ?
					//		"등원후기" : (
					//			( "sharing" === props.ratingType ) ?
					//				"상담후기" : "내 글 수정"
					//		)
					//}
					
					activeFunction={activeFunctions[idx]}
					//activeFunctions={undefined}
					
					mode={modeList[idx]}
					
					others={
						{
							closeDialog	: others.closeDialog,
						}
					}
				/>
			</TabPanel>
		);
		
	}
	
	return (
		<Fragment>
			<DialogTitle
				className={
					clsx(
						//classes.noMargin,
						//classes.paddingY,
						//classes.padding2X,
						
						classes.noGutters,
					)
				}
			>
				<AppBar 
					position="relative"
					
					//color="default"
					
					className={
						clsx(
							classes.topMenuBar,
							classes.noGutters,
						)
					}
				>
					<Tabs 
						variant="fullWidth"
						value={value} 
						
						
						//indicatorColor="primary"
          				//textColor="primary"
						
						onChange={
							( event, newValue ) => {
								setValue( newValue );
							}
						}
						aria-label="simple tabs example"
					>
						{
							tabList
						}
					</Tabs>
				</AppBar>
			</DialogTitle>
			
			{
				tabContentList
			}
			
		</Fragment>
	);
}



function UserReviewForm ( props ) {
	
	const classes	= useStyles();
	
	const { isTab }	= props;
	
	return (
		<Fragment>
			{
				( true === isTab ) ?
					(
						<UserReviewFormTabs
							isTabContent={true}
							
							ratingTypes={props.ratingTypes}
							ratingType={
								undefined
								//props.ratingType
							}
							
							type={props.type}	// ulzip, kinder
							userInfo={props.userInfo}
							
							//title={
							//	//"내 글 수정"
							//	( "rating" === props.ratingType ) ?
							//		"등원후기" : (
							//			( "sharing" === props.ratingType ) ?
							//				"상담후기" : "내 글 수정"
							//		)
							//}
							
							activeFunction={undefined}
							activeFunctions={props.activeFunctions}
							modeList={props.modeList}
							others={
								{
									closeDialog	: props.closeDialog,
								}
							}
						/>
					) :
					(
						<UserReviewFormDetail
							isTabContent={false}
							
							ratingTypes={undefined}
							ratingType={
								props.ratingType
							}
							
							type={props.type}	// ulzip, kinder
							userInfo={props.userInfo}
							
							title={
								//"내 글 수정"
								( "rating" === props.ratingType ) ?
									"등원후기" : (
										( "sharing" === props.ratingType ) ?
											"상담후기" : "내 글 수정"
									)
							}
							
							activeFunction={props.activeFunction}
							activeFunctions={undefined}
							mode={props.mode}
							others={
								{
									closeDialog	: props.closeDialog,
								}
							}
						/>
					)
			}
		</Fragment>
	);
	
}


export default function UserReviewFormCreator ( props ) {
	
	const classes	= useStyles();
	
	//const [values, setValues] = React.useState({
	//	isOpen		: false,
	//});

	
	return (
		<Fragment>
			
			<UserReviewForm
				type={props.type}	// ulzip, kinder
				userInfo={props.userInfo}
				
				isTab={props.isTab}
				ratingTypes={props.ratingTypes}		// array, isTab === true
				ratingType={props.ratingType}		// no array, isTab === false
				
				activeFunctions={props.activeFunctions}	// array, isTab === true
				activeFunction={props.activeFunction}	// no array, isTab === false
				
				mode={props.mode}
				modeList={props.modeList}
				
				closeDialog={props.closeDialog}
			/>
			
		</Fragment>
	);
	
}